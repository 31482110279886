import { AkVektarsavgiftPanel } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';

import type { IGrunnlagVektarsavgift, IKjoretoy, IRegistreringSak, RootStateType } from '../../models/types';
import { TilhengervektRegler } from '../../regler/tilhengervekt-regler';
import { finnGrunnlagVektarsavgift, oppdaterRegistreringTilhengerpanel, resetGrunnlagVektarsavgift } from '../../state/actions';
import { erKravOmVektarsavgiftSelector } from '../../state/selectors';
import { utledTilhengerinfo } from '../../utils';

interface ITilhengerpanelStateProps {
    erKravOmVektarsavgift?: boolean;
    registreringSak?: IRegistreringSak;
    kjoretoy?: IKjoretoy;
    vektarsavgiftUrl?: string;
    sakId: string;
    grunnlagVektarsavgift: IGrunnlagVektarsavgift;
}

interface ITilhengerpanelProps {
    lesemodus: boolean;
    submitted: boolean;
    confirmNavigation: (confirm: boolean) => void;
}

interface ITilhengerpanelDispatchProps {
    oppdaterRegistreringTilhengerpanel: (grunnlagVektarsavgift: Partial<IGrunnlagVektarsavgift>) => Promise<any>;
    finnGrunnlagVektarsavgift: (kuid: string) => Promise<IGrunnlagVektarsavgift>;
    resetGrunnlagVektarsavgift: () => void;
}

class Tilhengerpanel extends Component<ITilhengerpanelStateProps & ITilhengerpanelDispatchProps & ITilhengerpanelProps> {

    public componentDidMount(): void {
        this.props.finnGrunnlagVektarsavgift(this.props.kjoretoy.kuid);
    }

    public componentWillUnmount(): void {
        this.props.resetGrunnlagVektarsavgift();
    }

    public render(): React.ReactElement {
        const { kjoretoy, erKravOmVektarsavgift, registreringSak, lesemodus, submitted, vektarsavgiftUrl, sakId } = this.props;
        const tilhengervektOverskriderTillattTilhengervekt = this.getTilhengervektOverskriderTillattTilhengervekt(kjoretoy, registreringSak);

        if (sakId && !registreringSak.sakId || this.props.grunnlagVektarsavgift.isLoading) {
            // unmounter AkVektarsavgiftPanel for å få oppdatert saksinformasjon fra sak
            return null;
        }

        return (
            <AkVektarsavgiftPanel
                grunnlagVektarsavgift={!this.skalViseGrunnlagvektarsavgiftFraRegistreringssak() ? this.props.grunnlagVektarsavgift : registreringSak.grunnlagVektarsavgift}
                erKravTilVektarsavgift={erKravOmVektarsavgift}
                tilhengerinfo={utledTilhengerinfo(kjoretoy.godkjenning)}
                vektarsavgiftUrl={vektarsavgiftUrl}
                tilhengervektOverskriderTillattTilhengervekt={tilhengervektOverskriderTillattTilhengervekt}
                oppdaterGrunnlagVektarsavgift={this.oppdaterVektarsavgift}
                lesemodus={lesemodus} customClassNames="col-12" submitted={submitted} />
        );
    }

    private getTilhengervektOverskriderTillattTilhengervekt(kjoretoy: IKjoretoy, registreringSak: IRegistreringSak) {
        return TilhengervektRegler
            .overskriderOppgittTilhengervektTillattTilhengervekt(kjoretoy.godkjenning,
                !!this.props.grunnlagVektarsavgift?.totalvektTilhenger ?
                    this.props.grunnlagVektarsavgift.totalvektTilhenger :
                    registreringSak.grunnlagVektarsavgift?.totalvektTilhenger);
    }

    private oppdaterVektarsavgift = (grunnlagVektarsavgift: IGrunnlagVektarsavgift) => {
        if (!this.skalIkkeBekrefteNavigasjon(grunnlagVektarsavgift)) {
            this.props.confirmNavigation(true);
        }

        this.props.oppdaterRegistreringTilhengerpanel(grunnlagVektarsavgift);
    };

    private skalIkkeBekrefteNavigasjon = (grunnlag: IGrunnlagVektarsavgift) => {
        return grunnlag.skalTrekkeTilhenger && !grunnlag.antallAkslerTilhenger && !grunnlag.totalvektTilhenger;
    };

    private skalViseGrunnlagvektarsavgiftFraRegistreringssak = () => {
        return !this.props.grunnlagVektarsavgift ||
            this.props.grunnlagVektarsavgift?.antallAkslerTilhenger === null &&
            this.props.grunnlagVektarsavgift?.skalTrekkeTilhenger === null &&
            this.props.grunnlagVektarsavgift?.totalvektTilhenger === null;
    };
}

const mapStateToProps = (state: RootStateType): ITilhengerpanelStateProps => ({
    erKravOmVektarsavgift: erKravOmVektarsavgiftSelector(state),
    registreringSak: state.registreringSak,
    kjoretoy: state.kjoretoy,
    vektarsavgiftUrl: (state.globals.klientKonfigurasjon.linker || {}).vektarsavgift,
    sakId: state.sak.sakId,
    grunnlagVektarsavgift: state.grunnlagVektarsavgift
});

const mapDispatchToProps = (dispatch) => ({
    oppdaterRegistreringTilhengerpanel: (grunnlagVektarsavgift: Partial<IGrunnlagVektarsavgift>) => (dispatch(oppdaterRegistreringTilhengerpanel(grunnlagVektarsavgift))),
    finnGrunnlagVektarsavgift: (kuid: string) => dispatch(finnGrunnlagVektarsavgift(kuid)),
    resetGrunnlagVektarsavgift: () => dispatch(resetGrunnlagVektarsavgift())
});

const TilhengerpanelConnected = connect(mapStateToProps, mapDispatchToProps)(Tilhengerpanel);

export {TilhengerpanelConnected, Tilhengerpanel};
