export enum Kanal {
    SAKSBEHANDLER = 'SAKSBEHANDLER',
    FORHANDLER = 'FORHANDLER',
    SELVBETJENING = 'SELVBETJENING',
    OPPSLAG = 'OPPSLAG',
    AUTOMATISK = 'AUTOMATISK',
    MYNDIGHETSTJENESTE = 'MYNDIGHETSTJENESTE',
    AMV = 'AMV',
    TEKNISK_SAKSBEHANDLER = 'TEKNISK_SAKSBEHANDLER',
    UKJENT = 'UKJENT'
}
