import type { IAkSelectInputOption } from 'svv-tk-akr-common-frontend';
import { AkSelectInput } from 'svv-tk-akr-common-frontend';
import classNames from 'classnames';
import * as React from 'react';
import type { KodeType } from '../../models/kodeverk';

interface IBrukInputProps {
    name?: string;
    lesemodus: boolean;
    label?: string;
    kodeVisning: string;
    koder: KodeType[];
    errors?: string[];
    labelClassName?: string;
    errorClassName?: string;
    selectAndErrorContainerClassNames?: string;
    componentClassNames?: string;
    handleSelectChange?: (kode: string) => void;
}

const BrukInput: React.FC<IBrukInputProps> = (props: IBrukInputProps) => {

    const { label, kodeVisning, koder, lesemodus, errors = [], selectAndErrorContainerClassNames, name } = props;
    const labelClassName = classNames('ak-fet', props.labelClassName);

    return (
        <AkSelectInput
            name={name}
            placeholderIntlKey={'generell.dropdown.velg'}
            componentClassNames={classNames('no-gutters', props.componentClassNames)}
            labelIntlKey={label}
            labelClassNames={labelClassName || 'col-1'}
            selectAndErrorContainerClassNames={selectAndErrorContainerClassNames}
            containerClassNames="no-gutters w-100"
            intialSelectedValue={kodeVisning}
            options={mapKodetypeToSelectOptions(koder)}
            handleSelectChange={props.handleSelectChange}
            errors={errors}
            errorClassNames={props.errorClassName}
            disabled={lesemodus}
            invalid={!!errors.length}
        />
    );
};

const mapKodetypeToSelectOptions = (koder: KodeType[]): IAkSelectInputOption[] => {
    return koder.map(item => ({
        value: item.getKode(),
        description: item.getBeskrivelse(),
        descriptionId: item.getBeskrivelseId(),
        disabled: !item.isGyldig()
    }));
};

export { BrukInput };
