import type { IMessageDescriptor} from 'svv-tk-akr-common-frontend';
import { KjennemerkeSerieEnum, KjennemerkeTypeEnum } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import moment from 'moment';

import type { GodkjenningTekniskKode} from '../models/kodeverk';
import {
    GodkjenningsType, Godkjenningsundertype, RegelmerknaderKoder, RegelmerknaderKontekst, RegelmerknaderType, RegistreringsstatusKode, SaksType
} from '../models/kodeverk';
import type { Brukerprofil, IApentEierskifte, IKjennemerkeserie, IKjoretoy, IMerknad, IRootState, ITilleggsgodkjenning } from '../models/types';

import { akFormatDate, ISO_8601_DATO_FORMAT } from '../utils';
import { BrukerRegler, ForstegangsregistreringRegler } from './index';
import { Merknader } from './merknader';

const MERKNADER_SOM_BARE_ER_TIL_INFO: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.BRUKSFORBUD_KONTROLLFRIST_PKK },
    { kode: RegelmerknaderKoder.KJORETOY_IKKE_BETALT_PASKILTNINGSGEBYR },
    { kode: RegelmerknaderKoder.BRUKSFORBUD_KONTROLLFRIST_TEKNISK_MANGEL },
    { kode: RegelmerknaderKoder.BRUKSFORBUD_TEKNISK_MANGEL_PKK },
    { kode: RegelmerknaderKoder.BRUKSFORBUD_TEKNISK_MANGEL_TEKNISK_KONTROLL },
    { kode: RegelmerknaderKoder.KJORETOY_ER_RALLY_MED_AVGIFTSFRITAK },
    { kode: RegelmerknaderKoder.BRUKSFORBUD_KONTROLLFRIST_OPPMOTE },
    { kode: RegelmerknaderKoder.BRUKSFORBUD_ANNEN_ARSAK },
    { kode: RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_TRIAL_ENDURO },
    { kode: RegelmerknaderKoder.KJORETOY_BEGRAVELSESBIL },
    { kode: RegelmerknaderKoder.KJORETOY_ER_RALLY_UTEN_AVGIFTSFRITAK },
    { kode: RegelmerknaderKoder.KJORETOY_HAR_KONTROLLSEDDEL_UTEN_BRUKSFORBUD },
    { kode: RegelmerknaderKoder.KJORETOY_IKKE_INNLEVERT_VOGNKORT_DEL_2 },
    { kode: RegelmerknaderKoder.KJORETOY_UTRYKNING },
    { kode: RegelmerknaderKoder.KJORETOY_KLASSIFISERING_AMBULANSE },
    { kode: RegelmerknaderKoder.KJORETOY_LAREVOGN },
    { kode: RegelmerknaderKoder.APEN_REGISTRERING },
    { kode: RegelmerknaderKoder.KJENNEMERKETS_TEGNKOMBINASJON_MA_ENDRES },
    { kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_SAMME_EIER },
    { kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER },
    { kode: RegelmerknaderKoder.KJORINGENS_ART_BEGRAVELSESBIL },
    { kode: RegelmerknaderKoder.TEKNISKE_ENDRINGER },
    { type: RegelmerknaderType.INFORMASJON }
];

const MERKNADER_SOM_IKKE_SKAL_VISES_I_SKJERMBILDET: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.AKTOR_ER_MYNDIG },
    { kode: RegelmerknaderKoder.AKTOR_IKKE_MYNDIG_KJORETOY_LETT_HENGER },
    { kode: RegelmerknaderKoder.KJORINGENS_ART_SVALBARD },
    { kode: RegelmerknaderKoder.KJORETOY_KRAV_OM_VEKTARSAVGIFT },
    { kode: RegelmerknaderKoder.KJENNEMERKETYPE_KAN_ENDRES },
    { kode: RegelmerknaderKoder.KJORETOY_IKKE_REGISTRERT_PA_NY_EIER },
    { kode: RegelmerknaderKoder.IKKE_OMFATTET_AV_PKK },
    { kode: RegelmerknaderKoder.PKK_GODKJENT },
    { kode: RegelmerknaderKoder.IKKE_GODKJENT_PKK },
    { kode: RegelmerknaderKoder.KJENNEMERKETS_TEGNKOMBINASJON_KAN_ENDRES },
    { kode: RegelmerknaderKoder.KJORINGENS_ART_MOTORSPORT }
];

const MERKNADER_SOM_KREVER_KJENNEMERKEBYTTE: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.KJENNEMERKETYPE_MA_ENDRES },
    { kode: RegelmerknaderKoder.KJENNEMERKE_MA_BYTTES, kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_SAMME_EIER },
    { kode: RegelmerknaderKoder.KJENNEMERKE_MA_BYTTES, kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER },
    { kode: RegelmerknaderKoder.KJENNEMERKETS_TEGNKOMBINASJON_MA_ENDRES }
];

const KJENNEMERKESERIER_SOM_SKJULER_BESTILL_ERSTATNINGSKJENNEMERKER_KNAPP = [KjennemerkeSerieEnum.FOR1971, KjennemerkeSerieEnum.CD, KjennemerkeSerieEnum.CD_4];
const KJENNEMERKETYPER_SOM_SKJULER_BESTILL_ERSTATNINGSKJENNEMERKER_KNAPP = [KjennemerkeTypeEnum.VETERAN, KjennemerkeTypeEnum.VETERAN_UTENFOR_OFFENTLIG_VEI, KjennemerkeTypeEnum.AMBASSADE];

class KjoretoyRegler {

    private readonly kjoretoy: IKjoretoy;

    public constructor(kjoretoy: IKjoretoy) {
        this.kjoretoy = kjoretoy;
    }

    public static adapter(state: Pick<IRootState, 'kjoretoy'>): KjoretoyRegler{
        return new KjoretoyRegler(state.kjoretoy);
    }

    public kanProdusereMidlertidigVognkort(brukerprofil: Brukerprofil): boolean {
        const hasRolle = new BrukerRegler(brukerprofil).harRolleOmregEllerEierskifte() || new BrukerRegler(brukerprofil).harRolleForstegangsregistrering();
        const hasMidlertidigVognkortMerknader = Merknader.exists(this.kjoretoy.merknader, { kontekst: 'akr.forhandler.midlertidigvognkort' });

        return this.harKjoretoyRegistrering()
            && !hasMidlertidigVognkortMerknader
            && !Merknader.exists(this.kjoretoy, { kode: RegelmerknaderKoder.KJENNEMERKE_MA_BYTTES })
            && !(this.kjoretoy.apneEierskifter || []).length
            && hasRolle;
    }

    public erKjoretoyStjaletMedKontekstEierskifte(): boolean {
        return Merknader.exists(this.kjoretoy, {
            kode: RegelmerknaderKoder.KJORETOY_STJALET,
            kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_START_EIERSKIFTE
        });
    }

    public erKjoretoyPrototype(): boolean {
        return Merknader.exists(this.kjoretoy, {
            kode: RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_PROTOTYPE
        });
    }

    public erKjoretoyParegistrertForMindreEnnDefinertAntallDagerSiden(): boolean {
        return Merknader.exists(this.kjoretoy, {
            kode: RegelmerknaderKoder.KJORETOY_PAREGISTRERT_FOR_MINDRE_ENN_DEFINERT_ANTALL_DAGER_SIDEN
        });
    }

    public harKjoretoyMedeier(): boolean {
        return !!this.kjoretoy.registrering?.medeier;
    }

    public manglerTekniskeData(kjoretoymerknader?: IMerknad[]): string {
        const merknader = kjoretoymerknader || this.kjoretoy.merknader;
        const feiltekst = [];

        _.each(merknader, (merknad: IMerknad) => {
            switch (merknad.kode) {
                case RegelmerknaderKoder.KJORETOY_MANGLER_REGISTRERINGSAR.valueOf():
                    feiltekst.push('registreringsår');
                    break;
                case RegelmerknaderKoder.KJORETOY_MANGLER_TOTALVEKT.valueOf():
                    feiltekst.push('tillatt totalvekt');
                    break;
                case RegelmerknaderKoder.KJORETOY_MANGLER_AVGIFTSKODE.valueOf():
                    feiltekst.push('avgiftskode');
                    break;
                case RegelmerknaderKoder.KJORETOY_MANGLER_TEKNISK_KODE.valueOf():
                    feiltekst.push('teknisk kode');
                    break;
                case RegelmerknaderKoder.KJORETOY_MANGLER_EGENVEKT.valueOf():
                    feiltekst.push('egenvekt');
                    break;
                case RegelmerknaderKoder.KJORETOY_MANGLER_DRIVSTOFFKODE.valueOf():
                    feiltekst.push('drivstoffkode');
            }
        });

        return _.uniq(feiltekst).join(', ').replace(/,(?!.*,)/gmi, ' og');
    }

    public utledKjoretoymeldinger(brukerprofil: Brukerprofil): IMessageDescriptor[] {
        const { registreringsinformasjon } = this.kjoretoy.registrering || {};
        const tekniskeEndringerMeldinger = this.utledTekniskeEndringerMelding(this.kjoretoy.merknader);
        const bruksforbudmeldinger = this.utledBruksforbudmeldinger();

        if (!registreringsinformasjon) {
            return [];
        }

        const meldingKey = 'kjoretoydetaljer.meldinger.';
        const meldinger: IMessageDescriptor[] = [];

        if (registreringsinformasjon.paskiltingsgebyr) {
            meldinger.push({
                id: 'kjoretoydetaljer.meldinger.paskiltingsgebyr'
            });
        }

        if (this.kjoretoy.merknader.length) {

            const vognkortIkkeInnlevert = Merknader.exists(this.kjoretoy, {
                kode: RegelmerknaderKoder.KJORETOY_IKKE_INNLEVERT_VOGNKORT_DEL_2,
                kontekst: RegelmerknaderKontekst.AKR_REGLER_OMREGISTRERING
            });

            if (vognkortIkkeInnlevert && !Merknader.exists(this.kjoretoy, { kode: RegelmerknaderKoder.OMREGISTRERING_KAN_IKKE_STARTES })) {
                meldinger.push({ id: `${meldingKey}vognKortDel2.registrering` });
            }

            if (Merknader.exists(this.kjoretoy, { kode: RegelmerknaderKoder.EIER_UVERIFISERT_KUNDE, kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_KUNDE })) {
                meldinger.push({ id: `${meldingKey + RegelmerknaderKoder.EIER_UVERIFISERT_KUNDE}` });
            }

            if (Merknader.exists(this.kjoretoy, { kode: RegelmerknaderKoder.UVERIFISERT_KUNDE, kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_EIERSKIFTE })) {
                meldinger.push({ id: `${meldingKey + RegelmerknaderKoder.UVERIFISERT_KUNDE}` });
            }

            if (new BrukerRegler(brukerprofil).erForhandlerSisteEier(this.kjoretoy) && Merknader.exists(this.kjoretoy, { kode: RegelmerknaderKoder.KJORETOY_PAREGISTRERT_FOR_MINDRE_ENN_DEFINERT_ANTALL_DAGER_SIDEN })) {
                meldinger.push({ id: `${meldingKey + RegelmerknaderKoder.KJORETOY_PAREGISTRERT_FOR_MINDRE_ENN_DEFINERT_ANTALL_DAGER_SIDEN}` });
            }
        }

        return _.uniq(_.concat(meldinger, bruksforbudmeldinger, tekniskeEndringerMeldinger));
    }

    public utledBruksforbudmeldinger(): IMessageDescriptor[] {
        const merknader = this.kjoretoy.merknader;
        return _.chain(merknader)
            .uniqBy('kode')
            .filter((merknad: IMerknad) => {
                return !!merknad.kode.match('BRUKSFORBUD') && !merknad.kode.match('BRUKSFORBUD_MANGLER_REGISTRERING') && !merknad.kode.match('BRUKSFORBUD_MANGLER_FORSIKRING');
            })
            .map((merknad: any) => ({ id: `kjoretoydetaljer.meldinger.${merknad.kode}` }))
            .value();
    }

    public utledKjennemerkeinformasjon(): IMessageDescriptor {
        const { registreringsinformasjon } = this.kjoretoy.registrering;
        const { kjennemerkerStotteregisterStatus } = registreringsinformasjon || {};

        if (!kjennemerkerStotteregisterStatus || RegistreringsstatusKode.AVREGISTRERT !== registreringsinformasjon.registreringsstatus) {
            return null;
        }

        const oppbevaresHosEnhet = kjennemerkerStotteregisterStatus.oppbevartSted;
        const antallTilOppbevaring = kjennemerkerStotteregisterStatus.antallOppbevart;
        const oppbevartTidspunkt = kjennemerkerStotteregisterStatus.oppbevartTidspunkt;
        const avregistrertHosForhandler = moment(registreringsinformasjon.avregistrertHosForhandler, ISO_8601_DATO_FORMAT, true).isValid();
        const mottattTilOppbevaringDato = moment(oppbevartTidspunkt);
        const oppbevaresTilDato = moment(kjennemerkerStotteregisterStatus.utledetOppbevaresTilDato);
        const antallMakulert = kjennemerkerStotteregisterStatus.antallMakulert;
        const ingenKjennemerkerMottatt = !antallMakulert && !antallTilOppbevaring;
        const avregistrertTekniskKontroll = registreringsinformasjon.midlertidigAvregistreringArsak === 'TVUNGEN';
        const avregistrertAv = avregistrertTekniskKontroll ? 'Teknisk kontroll' : kjennemerkerStotteregisterStatus.oppbevartSted;
        const antallHosBransje = kjennemerkerStotteregisterStatus.antallHosBransje;

        const oppbevaringstidErPassert = oppbevaresTilDato.isBefore(moment());
        const kjennemerkerMakulertt = antallMakulert || oppbevaringstidErPassert;
        const meldinger: IMessageDescriptor[] = [];

        // Ikke avregistrert hos forhandler og ingen oppbevart eller hos bransje
        if (!avregistrertHosForhandler && !antallTilOppbevaring && !antallHosBransje) {
            return null;

            // Avregistrert hos forhandler, kjennemerker ikke innlevert på trafikkstasjon
        } else if (avregistrertHosForhandler && (!oppbevartTidspunkt || !antallTilOppbevaring)) {
            meldinger.push({ id: 'kjoretoydetaljer.kjennemerkeinformasjon.avregistrertHosForhandler' });

            // Avregistrert hos forhandler, kjennemerker innlevert på trafikkstasjon
        } else if (avregistrertHosForhandler && mottattTilOppbevaringDato.isValid() && !kjennemerkerMakulertt) {
            meldinger.push({
                id: 'kjoretoydetaljer.kjennemerkeinformasjon.avregistrertHosForhandlerInnlevert',
                values: {
                    mottattTilOppbevaringDato: akFormatDate(mottattTilOppbevaringDato),
                    oppbevaresHosEnhet,
                    antallTilOppbevaring,
                    oppbevaresTilDato: akFormatDate(oppbevaresTilDato)
                }
            });
        } else if (!avregistrertHosForhandler && avregistrertTekniskKontroll && ingenKjennemerkerMottatt) {
            meldinger.push({
                id: 'kjoretoydetaljer.kjennemerkeinformasjon.avregistrertAvTjeneste',
                values: {
                    avregistrertAv
                }
            });
            // Avregistrert hos forhandler, kjennemerker innlevert på trafikkstasjon og makulert
        } else if (avregistrertHosForhandler && mottattTilOppbevaringDato.isValid() && kjennemerkerMakulertt) {
            meldinger.push({
                id: 'kjoretoydetaljer.kjennemerkeinformasjon.avregistrertHosForhandlerMakulert',
                values: {
                    mottattTilOppbevaringDato: akFormatDate(mottattTilOppbevaringDato),
                    oppbevaresHosEnhet
                }
            });
        } else if (!avregistrertHosForhandler && antallTilOppbevaring > 0 && !oppbevaringstidErPassert) { // Avregistrert på trafikkstasjon
            meldinger.push({
                id: 'kjoretoydetaljer.kjennemerkeinformasjon.avregistrertPaTrafikkstasjon',
                values: {
                    antallTilOppbevaring,
                    oppbevaresTilDato: akFormatDate(oppbevaresTilDato),
                    oppbevaresHosEnhet,
                    avregistrertAv
                }
            });
        } else if (!avregistrertHosForhandler && kjennemerkerMakulertt) { // Avregistrert på trafikkstasjon eller teknisk kontroll, kjennemerker makulert
            meldinger.push({
                id: 'kjoretoydetaljer.kjennemerkeinformasjon.avregistrertPaTrafikkstasjonMakulert',
                values: {
                    avregistrertAv
                }
            });
        }

        return _.head(meldinger);
    }

    public harKjoretoyRegistrering(): boolean {
        return !_.isEmpty(this.kjoretoy.registrering);
    }

    public erMerknadBareTilInfo(merknad: IMerknad): boolean {
        return _.some(MERKNADER_SOM_BARE_ER_TIL_INFO, Merknader.filterForMerknad(merknad));
    }

    public klassifisertSomAmbulanseOgUtryknignskjoretoy(merknader: IMerknad[]): boolean {
        const utrykning = merknader.find(merknad => RegelmerknaderKoder.KJORETOY_UTRYKNING.valueOf() === merknad.kode);
        const ambulanse = merknader.find(merknad => RegelmerknaderKoder.KJORETOY_KLASSIFISERING_AMBULANSE.valueOf() === merknad.kode);
        return (!!utrykning && !!ambulanse);
    }

    public kjennemerkeSerierOgTyperSomSkjulerBestillErstatningskjennmerkeKnapp(kjennemerkeserie: IKjennemerkeserie, kjennemerketype: KjennemerkeTypeEnum): boolean {
        return KJENNEMERKESERIER_SOM_SKJULER_BESTILL_ERSTATNINGSKJENNEMERKER_KNAPP.includes(kjennemerkeserie.kjennemerkeserie)
            || KJENNEMERKETYPER_SOM_SKJULER_BESTILL_ERSTATNINGSKJENNEMERKER_KNAPP.includes(kjennemerketype);
    }

    public meldingFraMerknad(merknad: IMerknad): IMessageDescriptor {
        const isMerknadForUregistrertKjoretoy = merknad.kontekst === RegelmerknaderKontekst.AKR_FORHANDLER_START_FORSTEGANGSREGISTRERING.valueOf();
        return { id: `kjoretoydetaljer.meldinger.${merknad.kode}`, values: { isUregistrert: isMerknadForUregistrertKjoretoy } };
    }

    public utledInfomeldinger(): IMessageDescriptor[] {
        return this.utledMeldinger(this.erMerknadBareTilInfo);
    }

    public utledMeldinger(filter: (IMerknad) => boolean): IMessageDescriptor[] {
        const merknader = Merknader.find(
            this.kjoretoy.merknader || [],
            this.merknadSkalIkkeVisesISkjermbildet
        );
        return _.chain(merknader).filter(filter).map(this.meldingFraMerknad).uniqWith(_.isEqual).value();
    }

    public merknadSkalIkkeVisesISkjermbildet(merknad: IMerknad): boolean {
        return !_.some(MERKNADER_SOM_IKKE_SKAL_VISES_I_SKJERMBILDET, Merknader.filterForMerknad(merknad));
    }

    public erGjortTekniskeEndringer(merknader: IMerknad[]): boolean {
        return _.some(merknader, ['kode', RegelmerknaderKoder.TEKNISKE_ENDRINGER]);
    }

    public finnesEierskifterSomInnloggetForhandlerIkkeKanBehandle(kjoretoyMerknader: IMerknad[], apneEierskifter: IApentEierskifte[]): boolean {
        return (apneEierskifter?.length && !this.getApentEierskifteIdForForhandler(apneEierskifter))
            || (Merknader.exists(kjoretoyMerknader, { kode: RegelmerknaderKoder.KJORETOYET_HAR_APNE_EIERSKIFTER }) && !(apneEierskifter|| []).length);
    }

    public harBruksforbudPkkEllerVadis(): boolean {
        return Merknader.exists(this.kjoretoy.merknader, { kode: RegelmerknaderKoder.KJORETOY_HAR_BRUKSFORBUD });
    }

    public getApentEierskifteIdForForhandler = (apneEierskifter: IApentEierskifte[]): string => {
        const apentEierskifte = _.find(apneEierskifter, (eierskifte: IApentEierskifte) => eierskifte.forhandlerErAktor);
        return apentEierskifte ? apentEierskifte.sakId : null;
    };

    public kanFatteVedtakForstegangsregistrering(merknader: IMerknad[]): boolean {
        return ForstegangsregistreringRegler.kanFatteVedtakForstegangsregistrering(merknader);
    }

    public harKjoretoyFortolling(merknader: IMerknad[]): boolean {
        return ForstegangsregistreringRegler.harKjoretoyFortolling(merknader);
    }

    public erKjoretoyStjalet(merknader: IMerknad[]): boolean {
        return ForstegangsregistreringRegler.erKjoretoyStjalet(merknader);
    }

    public kreverKjennemerkebytte(): boolean {
        return Merknader.exists(this.kjoretoy, (merknad => _.some(MERKNADER_SOM_KREVER_KJENNEMERKEBYTTE, Merknader.filterForMerknad(merknad))));
    }


    public harApenDagsprovekjennemerkesak = (): boolean => (
        _.some((this.kjoretoy.apneSaker || []), sak => sak.saksType === SaksType.DAGSPROVEKJENNEMERKE.valueOf())
    );

    public harKjoretoyTekniskKode(kode: GodkjenningTekniskKode): boolean {
        return this.kjoretoy && this.kjoretoy.godkjenning.tekniskKode === kode.valueOf();
    }

    public erGjenstandForEtterregistrering(): boolean {
        return this.kjoretoy.godkjenning?.forstegangsgodkjenning?.godkjenningsundertype === Godkjenningsundertype.ETTERREGISTRERING;
    }

    public erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt = (): boolean => (
        _.some(this.kjoretoy.godkjenning.tilleggsgodkjenninger, (tilleggsgodkjenning: ITilleggsgodkjenning) =>
            tilleggsgodkjenning.tilleggsgodkjenningtype === GodkjenningsType.LOYVEFUNKSJONSHEMMEDE));

    private utledTekniskeEndringerMelding(merknader: IMerknad[]): IMessageDescriptor[] {
        const tekniskeEndringerMelding: IMessageDescriptor[] = [];

        if (this.erGjortTekniskeEndringer(merknader)) {
            tekniskeEndringerMelding.push({ id: 'kjoretoydetaljer.meldinger.TEKNISKE_ENDRINGER' });
        }

        return tekniskeEndringerMelding;
    }
}

export { KjoretoyRegler };
