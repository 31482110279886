import { compose } from '@reduxjs/toolkit';

import * as React from 'react';
import { Component } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AkModal } from 'svv-tk-akr-common-frontend';

import { RoundedProgressBar } from '../../components';
import { NY_SAK_BULK_VISNINGSTID_MS } from '../../constants';
import { MeldingType } from '../../models/kodeverk';
import type { IEierskifte, IMelding, MeldingInnhold } from '../../models/types';
import { nyMelding, opprettEierskifteBulkAction } from '../../state/actions';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';

interface IEierskifteBulkProgressModalProps {
    submitted: boolean;
    eierskifter: IEierskifte[];
}

interface IEierskifterBulkProgressModalState {
    inProgress: boolean;
    completedCount: number;
    totalCount: number;
    failed: string[];
}

interface IEierskifteDispatchProps {
    opprettEierskifte: (eierskifte: IEierskifte) => Promise<any>;
    nyMelding: (melding: IMelding) => void;
}

type PropsType = IEierskifteDispatchProps & IEierskifteBulkProgressModalProps & WrappedComponentProps & WithRouterProps;

class EierskifterBulkProgressModal extends Component<PropsType, IEierskifterBulkProgressModalState> {

    public state = {
        inProgress: false,
        completedCount: 0,
        totalCount: 0,
        failed: []
    };

    public componentDidUpdate(): void {
        if (this.props.submitted && this.state.totalCount !== this.props.eierskifter.length) {
            this.setState({
                inProgress: true,
                totalCount: this.props.eierskifter.length
            }, this.sendTilGodkjenning);
        }
    }

    public render(): React.ReactElement {
        if (this.state.inProgress) {
            return (
                <AkModal modalClassName="ak-modal-width-auto" showModal={this.state.inProgress}>
                    <RoundedProgressBar completedCount={this.state.completedCount} totalCount={this.state.totalCount}
                        smallText={this.props.intl.formatMessage({id: 'eierskifte.fremdrift.bulkSending'})} />
                </AkModal>
            );
        }

        return null;
    }

    private sendTilGodkjenning = () => {
        const eierskifterPromises = [];
        this.props.eierskifter.forEach((eierskifte: IEierskifte) => {
            eierskifterPromises.push(this.createEierskifteRequest(eierskifte));
        });

        Promise.all(eierskifterPromises).then(this.completeBulk);
    };

    private createEierskifteRequest = (eierskifte: IEierskifte) => {
        return new Promise<void>((resolve) => {
            this.props.opprettEierskifte(eierskifte).then(() => {
                this.setState({
                    completedCount: this.state.completedCount + 1
                }, resolve);
            }, (rejected) => {
                this.setState({
                    completedCount: this.state.completedCount + 1,
                    failed: [rejected, ...this.state.failed]
                }, resolve);
            });
        });
    };

    private completeBulk = () => {
        if (this.state.failed.length) {
            const meldingText: MeldingInnhold = {
                type: 'NESTED',
                tekst: "eierskifte.melding.sendTilGodkjenningBulk.feilet",
                args: this.state.failed
            };

            this.props.nyMelding({
                meldingText,
                meldingType: MeldingType.DANGER,
                meldingId: 'bulkEierskifteFailed'
            });
        }

        const completedCount = this.state.totalCount - this.state.failed.length;
        if (completedCount > 0) {
            const meldingText: MeldingInnhold = {
                type: 'BASIC',
                tekst: "eierskifte.melding.sendTilGodkjenningBulk.suksess",
                args: {count: completedCount}
            };
            this.props.nyMelding({
                meldingText,
                meldingType: MeldingType.SUCCESS,
                meldingId: 'bulkEierskifteCompleted',
                varighetMs: NY_SAK_BULK_VISNINGSTID_MS
            });
        }

        const timeout = 750;

        setTimeout(() => {
            this.setState(
                {inProgress: false},
                () => this.props.navigate('/startside')
            );
        }, timeout);
    };
}

const mapDispatchToProps = (dispatch): IEierskifteDispatchProps => ({
    opprettEierskifte: (eierskifte: IEierskifte) => dispatch(opprettEierskifteBulkAction(eierskifte)),
    nyMelding: (melding: IMelding) => dispatch(nyMelding(melding))
});

const EierskifterBulkProgressModalConnected = compose(
    connect<undefined, IEierskifteDispatchProps, IEierskifteBulkProgressModalProps>(null, mapDispatchToProps),
    injectIntl,
    withRouter
)(EierskifterBulkProgressModal);

export { EierskifterBulkProgressModalConnected };
