import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import type { IAktorPanelAktor, IKunderMap } from 'svv-tk-akr-common-frontend';
import { AkKnapp, AktorKundeinfo, AktorPanel, AktorType } from 'svv-tk-akr-common-frontend';

import type { Saksstatus } from '../../models/kodeverk';
import { RegelmerknaderType } from '../../models/kodeverk';
import type { IAktor, IError, IKjoretoy, IKunde, IRegistreringInformasjon, IRegistreringSak, ISak, RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';
import { getKunde } from '../../state/actions';
import { sakErAvluttetAvbrutt } from '../../utils';

export interface ILeasingtakerOgUnderenhet {
    leasingtaker?: string;
    underenhet?: string;
}

interface ILeasingtakerUnderenhetProps {
    saksinformasjon: Partial<IRegistreringInformasjon>;
    nyEier: IKunde;
    nyMedeier?: IKunde;
    submitted: boolean;
    lesemodus: boolean;
    errors?: string[];
    oppdaterLeasingtakerOgUnderenhet: (endring: ILeasingtakerOgUnderenhet) => void;
    saksstatus: Saksstatus;
}

interface ILeasingtakerUnderenhetStateProps {
    kunder: IKunderMap;
    kjoretoy: IKjoretoy;
    registreringSak: IRegistreringSak;
    sak: ISak<IRegistreringInformasjon>;
    isFlateeier: boolean;
}

interface ILeasingtakerUnderenhetDispatchProps {
    hentKundeMedFnrOrgNr: (orgFnr: string, aktorType: AktorType, kuid: string) => Promise<IAktor | IError>;
    hentAktorMedKundeId: (kundeId: string, aktorType: AktorType, kuid: string) => Promise<IAktor | IError>;
}

interface ILeasingtakerUnderenhetState {
    leasingtaker: IAktorPanelAktor;
    underenhet: IAktorPanelAktor;
    validering: {
        leasingtakerErEier: boolean;
        underenhetErEier: boolean;
        underenhetErUgyldig: boolean;
    };
}

type PropsType = ILeasingtakerUnderenhetProps
    & ILeasingtakerUnderenhetStateProps
    & ILeasingtakerUnderenhetDispatchProps;

class LeasingtakerUnderenhet extends React.Component<PropsType, ILeasingtakerUnderenhetState> {

    public static getDerivedStateFromProps(nextProps: PropsType, prevState: ILeasingtakerUnderenhetState): Partial<ILeasingtakerUnderenhetState> {
        const sakErAvsluttetEllerAvbrutt = sakErAvluttetAvbrutt(nextProps.saksstatus);
        return {
            leasingtaker: {
                aktorType: nextProps.saksinformasjon.leasingtaker ? AktorType.LEASINGTAKER : (prevState.leasingtaker || {} as IAktorPanelAktor).aktorType,
                kundeId: nextProps.saksinformasjon.leasingtaker,
                customError: _.find(nextProps.errors, error => error === 'forstegangsregistrering.valider.manglerLeasingtaker'),
                kanEndreAktor: !sakErAvsluttetEllerAvbrutt
            },
            underenhet: {
                aktorType: nextProps.saksinformasjon.underenhet ? AktorType.UNDERENHET : (prevState.underenhet || {} as IAktorPanelAktor).aktorType,
                kundeId: nextProps.saksinformasjon.underenhet,
                customError: (prevState.underenhet && prevState.underenhet.customError)
                    || _.find(nextProps.errors, error => error === 'forstegangsregistrering.valider.manglerUnderenhet'),
                kanEndreAktor: !sakErAvsluttetEllerAvbrutt && !nextProps.isFlateeier
            }
        };
    }

    public state = {
        leasingtaker: {} as IAktorPanelAktor,
        underenhet: {} as IAktorPanelAktor,
        validering: {
            leasingtakerErEier: false,
            underenhetErEier: false,
            underenhetErUgyldig: false
        }
    };

    public componentDidMount(): void {
        if (this.props.saksinformasjon.leasingtaker) {
            this.props.hentAktorMedKundeId(this.props.saksinformasjon.leasingtaker, AktorType.LEASINGTAKER, this.props.kjoretoy.kuid);
        }

        if (this.props.saksinformasjon.underenhet) {
            this.props.hentAktorMedKundeId(this.props.saksinformasjon.underenhet, AktorType.UNDERENHET, this.props.kjoretoy.kuid);
        }
    }

    public render(): React.ReactElement {
        return (
            <div className="row no-gutters">
                {this.renderLeasingtaker()}
                {this.renderUnderenhet()}
            </div>
        );
    }

    private renderLeasingtaker = () => {
        if (this.kanLeggeTilAktor(this.state.leasingtaker)) {
            return <AkKnapp type="ak-link-knapp ak-ikon-legg-til" intlKey={'sak.knapp.leggTilLeasingtaker'} action={this.leggTilLeasingtaker}/>;
        }
        if (this.harLagtTilAktor(this.state.leasingtaker)) {
            return this.renderAktorPanel(this.state.leasingtaker, this.handleChangeLeasingtaker);
        }
    };

    private renderUnderenhet = () => {
        if (!this.props.nyEier.organisasjonsnummer) {
            return null;
        }
        if (this.kanLeggeTilAktor(this.state.underenhet) && !this.props.isFlateeier) {
            return <AkKnapp type="ak-link-knapp ak-ikon-legg-til" intlKey={'sak.knapp.leggTilUnderenhet'} action={this.leggTilUnderenhet}/>;
        }
        if (this.harLagtTilAktor(this.state.underenhet)) {
            return this.renderAktorPanel(this.state.underenhet, this.handleChangeUnderenhet);
        }
    };

    private utledAktorFeilmelding(aktorType: AktorType) {
        const { underenhetErEier, underenhetErUgyldig, leasingtakerErEier } = this.hentValidering();
        if (aktorType === AktorType.UNDERENHET) {
            return underenhetErEier ? 'sak.validering.UNDERENHET' : underenhetErUgyldig ? 'eierskifte.validering.NY_UNDERENHET_ER_JURIDISKENHET' : null;
        }
        return leasingtakerErEier && 'sak.validering.LEASINGTAKER';
    }

    private renderAktorPanel = (aktor, handleChange) => (
        <AktorPanel
            aktor={{...aktor, customError: this.utledAktorFeilmelding(aktor.aktorType)}}
            kunder={this.props.kunder}
            submitted={this.props.submitted}
            hentAktor={this.hentAktorer}
            handleAktorChange={handleChange}
            panelClassName="col-md-10 mt-3 mb-0"
            panelOverskrift={`kodeverk.aktortype.${aktor.aktorType}`}
            innerPanelClassName="col-12"
            kundeinfoClassName={this.harLagtTilAktor(this.state.underenhet) && this.harLagtTilAktor(this.state.leasingtaker) && 'ak-aktor-dl-grid fast-kolonne'}
            sokLabelClassName="col-lg-2 col-3 mr-3 mt-1"
            sokInputClassName="col-5 col-lg-4 ak-input-fast-bredde">
            {aktor.kundeId && <AktorKundeinfo />}
        </AktorPanel>
    );

    private handleChangeLeasingtaker = (aktor: IAktor, slett?: boolean) => {
        if (slett || !aktor.kundeId || this.oppdatertLeasingtakerErEier(aktor)) {
            this.setState({
                leasingtaker: slett ? {} as IAktor : { aktorType: AktorType.LEASINGTAKER },
                validering: {
                    ...this.hentValidering(),
                    leasingtakerErEier: slett ? false : this.hentValidering().leasingtakerErEier
                }
            });
            return this.props.oppdaterLeasingtakerOgUnderenhet({
                leasingtaker: slett ? null : '',
                underenhet: this.props.saksinformasjon.underenhet
            });
        }
        this.props.oppdaterLeasingtakerOgUnderenhet({
            leasingtaker: aktor.kundeId,
            underenhet: this.props.saksinformasjon.underenhet
        });
    };

    private oppdatertUnderenhetErEierEllerUgyldig(aktor: IAktor) {
        const underenhetErEier = this.nyAktorErEier(aktor, AktorType.NY_UNDERENHET);
        const eier = this.props.kjoretoy.registrering.eier || this.props.nyEier;
        const underenhetErUgyldig = !aktor.juridiskEnhetNummer || (aktor.juridiskEnhetNummer !== _.find(this.props.kunder, ['kundeId', eier.kundeId])?.organisasjonsnummer);
        this.setState({validering: {...this.hentValidering(), underenhetErEier, underenhetErUgyldig}});
        return underenhetErEier || underenhetErUgyldig;
    }

    private oppdatertLeasingtakerErEier(aktor: IAktor) {
        const leasingtakerErEier = this.nyAktorErEier(aktor, AktorType.NY_LEASINGTAKER);
        this.setState({validering: {...this.hentValidering(), leasingtakerErEier}});
        return leasingtakerErEier;
    }

    private nyAktorErEier(nyAktor: IAktor, aktorType: AktorType) {
        const { kjoretoy, sak, registreringSak, nyEier, nyMedeier } = this.props;
        const { saksinformasjon } = sak;
        const eier = kjoretoy.registrering.eier || saksinformasjon.nyEier || nyEier;
        const medeier = kjoretoy.registrering.medeier || saksinformasjon.nyMedeier || nyMedeier;
        const underenhet = {kundeId: registreringSak.underenhet || saksinformasjon.underenhet?.toString()};
        const leasingtaker = {kundeId: registreringSak.leasingtaker || saksinformasjon.leasingtaker?.toString()};

        if (aktorType === AktorType.NY_UNDERENHET) {
            return [eier, medeier, leasingtaker].some(aktor => !!aktor && aktor.kundeId === nyAktor.kundeId);
        }
        if (aktorType === AktorType.NY_LEASINGTAKER) {
            return [eier, medeier, underenhet].some(aktor => !!aktor && aktor.kundeId === nyAktor.kundeId);
        }
    }

    private handleChangeUnderenhet = (aktor: IAktor, slett?: boolean) => {
        if (slett || !aktor.kundeId || this.oppdatertUnderenhetErEierEllerUgyldig(aktor)) {
            this.setState({
                underenhet: slett ? null : { aktorType: AktorType.UNDERENHET },
                validering: {
                    ...this.hentValidering(),
                    underenhetErEier: slett ? false : this.hentValidering().underenhetErEier,
                    underenhetErUgyldig: slett ? false : this.hentValidering().underenhetErUgyldig
                }
            });
            return this.props.oppdaterLeasingtakerOgUnderenhet({
                leasingtaker:  this.props.saksinformasjon.leasingtaker,
                underenhet: slett ? null : ''
            });
        }

        if (aktor.juridiskEnhetNummer !== this.props.nyEier.organisasjonsnummer
            || aktor.organisasjonsnummer === this.props.nyEier.organisasjonsnummer) {
            return this.setState({underenhet: {...this.state.underenhet, customError: 'forstegangsregistrering.valider.ugyldigUnderenhet'}});
        }

        this.setState({underenhet: {...this.state.underenhet, customError: null}});
        this.props.oppdaterLeasingtakerOgUnderenhet({
            leasingtaker: this.props.saksinformasjon.leasingtaker,
            underenhet: aktor.kundeId
        });
    };

    private hentAktorer = (orgFnr: string, aktortype: AktorType) => {
        this.setState({validering: {leasingtakerErEier: false, underenhetErEier: false, underenhetErUgyldig: false}});
        return this.props.hentKundeMedFnrOrgNr(orgFnr, aktortype, this.props.kjoretoy.kuid).then(() => {
            const kunde = _.get(this.props.kunder, orgFnr);
            if (!_.find(kunde && kunde.merknader, {type: RegelmerknaderType.KRITISK})) {
                if (aktortype === AktorType.LEASINGTAKER) {
                    return this.handleChangeLeasingtaker(kunde as IAktor);
                }
                return this.handleChangeUnderenhet(kunde as IAktor);
            }
        });
    };

    private leggTilLeasingtaker = () => {
        this.setState({ leasingtaker: { aktorType: AktorType.LEASINGTAKER }});
        this.props.oppdaterLeasingtakerOgUnderenhet({
            leasingtaker: '',
            underenhet: this.props.saksinformasjon.underenhet
        });
    };

    private leggTilUnderenhet = () => {
        this.setState({ underenhet: { aktorType: AktorType.UNDERENHET }});
        this.props.oppdaterLeasingtakerOgUnderenhet({
            leasingtaker: this.props.saksinformasjon.leasingtaker,
            underenhet: ''
        });
    };

    private kanLeggeTilAktor = (aktor: IAktorPanelAktor) => (
        !this.props.lesemodus && !aktor.aktorType
    );

    private hentValidering = () => this.state.validering;

    private harLagtTilAktor = (aktor: IAktorPanelAktor) => !!aktor.aktorType;
}

const mapStateToProps = (state: RootStateType): ILeasingtakerUnderenhetStateProps => ({
    kunder: state.kunder,
    kjoretoy: state.kjoretoy,
    sak: state.sak,
    registreringSak: state.registreringSak,
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier()
});

const mapDispatchToProps = (dispatch): ILeasingtakerUnderenhetDispatchProps => ({
    hentKundeMedFnrOrgNr: (orgFnr, aktortype: AktorType, kuid) => dispatch(getKunde({orgFnr}, aktortype, kuid)),
    hentAktorMedKundeId: (kundeId, aktortype: AktorType, kuid) => dispatch(getKunde({kundeId}, aktortype, kuid))
});

const LeasingtakerUnderenhetConnected = connect(mapStateToProps, mapDispatchToProps)(LeasingtakerUnderenhet);
export { LeasingtakerUnderenhetConnected, LeasingtakerUnderenhet };
