import { createSelector } from 'reselect';

import type { IKunder, RootStateType } from '../../models/types';

export function kunderSelector(state: RootStateType): IKunder {
    return state.kunder;
}

export const kundenavnSelector = createSelector(
    kunderSelector, kunder => kundeId => {
        return kunder[kundeId]?.navn || '';
    }
);
