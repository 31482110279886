import { AktorType } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';

import { RegelmerknaderKoder } from '../models/kodeverk';
import type { IAktor, Brukerprofil, IKjoretoy, IKunde, IKunder, IRegistrering } from '../models/types';
import { BrukerRegler, Merknader } from '../regler';
import { erUnderenhetInnlogget, utledSkalBenytteRegistrertKontaktinformasjon } from './index';

export const populerNyttEierskifte = (brukerprofil: Brukerprofil, registrering: IRegistrering, kunder: IKunder): IAktor[] => {
    const hovedenhetOrgNummer = brukerprofil.valgtEnhet.hovedenhetOrgNummer;
    const hovedenhetKundeId = brukerprofil.valgtEnhet.hovedenhetKundeId;
    const underenhetOrgNummer = brukerprofil.valgtEnhet.underenhetOrgNummer;
    const underenhetKundeId = brukerprofil.valgtEnhet.underenhetKundeId;
    const eier = registrering.eier;
    const medeier = registrering.medeier;
    const initialUnderenhetKundeId = (registrering.underenhet || ({} as IKunde)).kundeId;
    const erForhandlerEier = new BrukerRegler(brukerprofil).erForhandlerEier(registrering.eier);

    const eierAktor: IAktor = {
        aktorType: AktorType.EIER,
        organisasjonsnummer: eier?.organisasjonsnummer,
        kundeId: eier?.kundeId,
        navn: eier?.navn,
        ...(eier?.kundeId && _.get(kunder, eier.kundeId))
    };
    eierAktor.skalBenytteRegistrertKontaktinformasjon = utledSkalBenytteRegistrertKontaktinformasjon(eierAktor);
    const aktorer: IAktor[] = [eierAktor];

    if (!erForhandlerEier) {
        const nyEier: IAktor = {
            aktorType: AktorType.NY_EIER,
            organisasjonsnummer: hovedenhetOrgNummer,
            kundeId: hovedenhetKundeId,
            ...(hovedenhetKundeId && _.get(kunder, hovedenhetKundeId))
        };
        nyEier.skalBenytteRegistrertKontaktinformasjon = utledSkalBenytteRegistrertKontaktinformasjon(nyEier);
        aktorer.push(nyEier);

        if (underenhetOrgNummer && erUnderenhetInnlogget(brukerprofil.valgtEnhet)) {
            const nyUnderenhet: IAktor = {
                aktorType: AktorType.NY_UNDERENHET,
                organisasjonsnummer: underenhetOrgNummer,
                kundeId: underenhetKundeId,
                ...(underenhetKundeId && _.get(kunder, underenhetKundeId))
            };

            nyUnderenhet.skalBenytteRegistrertKontaktinformasjon = utledSkalBenytteRegistrertKontaktinformasjon(nyUnderenhet);
            aktorer.push(nyUnderenhet);
        }
    } else {
        aktorer.push({
            aktorType: AktorType.NY_EIER
        });
    }

    if (medeier) {
        const medeierAktor: IAktor = {
            aktorType: AktorType.MEDEIER,
            organisasjonsnummer: medeier.organisasjonsnummer,
            kundeId: medeier.kundeId,
            ...(medeier.kundeId && _.get(kunder, medeier.kundeId))
        };
        medeierAktor.skalBenytteRegistrertKontaktinformasjon = utledSkalBenytteRegistrertKontaktinformasjon(medeierAktor);
        aktorer.push(medeierAktor);
    }

    if (forhandlerErUnderenhetOgEierKjoretoyUtenUnderenhet(brukerprofil, registrering)) {
        const underenhet: IAktor = {
            aktorType: AktorType.UNDERENHET,
            organisasjonsnummer: underenhetOrgNummer,
            kundeId: underenhetKundeId,
            ...(underenhetKundeId && _.get(kunder, underenhetKundeId))
        };
        aktorer.push(underenhet);
    } else if (initialUnderenhetKundeId) {
        const underenhet: IAktor = {
            aktorType: AktorType.UNDERENHET,
            kundeId: initialUnderenhetKundeId,
            ...(_.get(kunder, initialUnderenhetKundeId))
        };
        aktorer.push(underenhet);
    }

    return aktorer;
};

export const forhandlerErUnderenhetOgEierKjoretoyUtenUnderenhet = (brukerprofil: Brukerprofil, registrering: IRegistrering): boolean => {
    return new BrukerRegler(brukerprofil).erForhandlerEier(registrering.eier) && !(registrering.underenhet && registrering.underenhet.kundeId) && erUnderenhetInnlogget(brukerprofil.valgtEnhet);
};

export const erKjoretoyAvregistrertHosForhandler = (kjoretoy: IKjoretoy): boolean => {
    return Merknader.exists(kjoretoy.merknader, { kode: RegelmerknaderKoder.KJORETOY_MIDL_AVREG_BRANSJE });
};
