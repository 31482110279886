import { AkTextTruncate, Panel } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import { useState } from 'react';
import * as React from 'react';
import type { IKjoretoy } from '../../models/types';

const KjoretoydetaljerMerknader: React.FC<{kjoretoy: IKjoretoy}> = (props) => {

    const [expanded, setExpanded] = useState<boolean>(false);

    const vognkortmerknader = [];

    const antallTegnPaEnVognkortrad = 70;
    const antallVognkortraderSomSkalForhandsvises = 3;
    const antallTegnSomSkalForhandsvises = antallTegnPaEnVognkortrad * antallVognkortraderSomSkalForhandsvises;

    _.forEach(props.kjoretoy.vognkortmerknader, (vognkortmerknad: string) => {
        vognkortmerknader.push(vognkortmerknad);
    });

    function onExpand() {
        setExpanded(!expanded);
    }

    return (
            <Panel overskrift={'kjoretoydetaljer.merknader.overskrift'}>
                {vognkortmerknader.length > 0 ?
                    <AkTextTruncate truncateTextLengthTrigger={antallTegnSomSkalForhandsvises} showTextLength={true}
                        text={vognkortmerknader.join(' ')} expanded={expanded} onExpandFn={onExpand} />
                    : <p> Ingen merknader </p>}
            </Panel>
    );
};

export { KjoretoydetaljerMerknader };
