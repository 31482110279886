import moment from 'moment';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import { AppConnected } from './containers/app';
import { AkIntlProviderConnected } from './containers/app/ak-intl-provider';
import { appStore, initialState } from './state/store';

import './styles.scss';

// Initialization of Moment
moment.locale('nb');

const renderApp = (RootComponent: any) => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    const store = appStore(initialState);

    const router = createHashRouter([
        { path: '*', Component: RootComponent }
    ]);

    root.render((
            <Provider store={store}>
                <AkIntlProviderConnected locale={'nb'}>
                    <RouterProvider router={router} />
                </AkIntlProviderConnected>
            </Provider>
        )
    );
};

renderApp(AppConnected);
export default AppConnected;
