import type { RowClickHandler} from 'svv-tk-akr-common-frontend';
import { AkColumn, AkTable, Skilt, softHyphen } from 'svv-tk-akr-common-frontend';

import classNames from 'classnames';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import type { ReactNode } from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { WithRouterProps} from '../../utils';
import { withRouter } from '../../utils';
import { compose } from '@reduxjs/toolkit';
import { css } from 'styled-components';

import { RegistreringsstatusKode } from '../../models/kodeverk';
import type { EgneKjoretoyResponse, IKjoretoyKjernedata, RootStateType } from '../../models/types';
import { STANDARD_DATO_FORMAT } from '../../utils';

interface IEgneKjoretoyStateProps {
    kjoretoy: EgneKjoretoyResponse;
}

const antallLoadingRows = 6;

const gridTemplate = css`
    grid-template-columns:
        minmax(120px, 1fr)
        minmax(150px, 3fr)
        minmax(120px, 1fr)
        minmax(100px, 1fr);
`;

class EgneKjoretoy extends Component<IEgneKjoretoyStateProps & WithRouterProps> {

    private static utledKjennemerke = (innslag: IKjoretoyKjernedata): ReactNode => (
        innslag.kjennemerke && <Skilt kjennemerke={innslag.kjennemerke} kjennemerketype={innslag.kjennemerketype} />
    );

    private static utledKjoretoy = (innslag: IKjoretoyKjernedata): ReactNode => (
        <p>{innslag.merke} {innslag.handelsbetegnelse} ({innslag.registreringAr}) {EgneKjoretoy.utledKarosserifarge(innslag)}</p>
    );

    private static utledRegistreringsstatus = (innslag: IKjoretoyKjernedata): ReactNode => (
        innslag.registreringsstatus && (
            <p className={classNames({'ak-text-suksess': EgneKjoretoy.isRegistrert(innslag)})}>
                <FormattedMessage id={`kodeverk.registreringsstatus.${innslag.registreringsstatus}`} />
                {EgneKjoretoy.isRegistrert(innslag) && <i className="fa fa-circle ml-2" aria-hidden="true" />}
            </p>
        )
    );

    private static utledKarosserifarge = (innslag: IKjoretoyKjernedata): string => _.join(innslag.karosserifarge, '/');

    private static utledDatoForrigeEierskifte = (innslag: IKjoretoyKjernedata): ReactNode => <p>{moment(innslag.datoForrigeEierskifte).format(STANDARD_DATO_FORMAT)}</p>;

    private static isRegistrert = (innslag: IKjoretoyKjernedata): boolean => (
        innslag && RegistreringsstatusKode.REGISTRERT === RegistreringsstatusKode[innslag.registreringsstatus]
    );

    public render() {
        return (
            <div className="row">
                <AkTable gridTemplate={gridTemplate} rows={this.props.kjoretoy.content} keyGenerator={this.keyGen} rowClickHandler={this.rowClickHandler}
                    defaultSorting={{property: ['eierskapsform']}} isLoading={this.props.kjoretoy.isLoading} antallLoadingRows={antallLoadingRows}>
                    <AkColumn header={{id: 'kjoretoyliste.innslag.kjennemerke'}} value={EgneKjoretoy.utledKjennemerke}/>
                    <AkColumn header={{id: 'kjoretoyliste.innslag.kjoretoy'}} value={EgneKjoretoy.utledKjoretoy}/>
                    <AkColumn header={{id: 'kjoretoyliste.innslag.registreringsstatus', values: { ...softHyphen }}} value={EgneKjoretoy.utledRegistreringsstatus}/>
                    <AkColumn header={{id: 'kjoretoyliste.innslag.eierskapfom'}} value={EgneKjoretoy.utledDatoForrigeEierskifte}/>
                </AkTable>
            </div>
        );
    }

    private keyGen = (innslag: IKjoretoyKjernedata) => innslag.kuid;

    private rowClickHandler: RowClickHandler<IKjoretoyKjernedata> = (event, innslag): void => {
        event.preventDefault();
        this.props.navigate(`/kjoretoy/${innslag.kuid}`);
    };
}

const mapStateToProps = (state: RootStateType): IEgneKjoretoyStateProps => ({
    kjoretoy: state.egneKjoretoy != null ? state.egneKjoretoy : {}  as EgneKjoretoyResponse
});

const EgneKjoretoyConnected = compose(
    connect(mapStateToProps),
    withRouter
)(EgneKjoretoy);

export { EgneKjoretoyConnected };
