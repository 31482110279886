import * as React from 'react';
import { BulkGodkjennModal } from './bulk-godkjenn-modal';
import { BulkLukkModal } from './bulk-lukk-modal';

import { EierskiteAdvarselAvregistrertModal } from './eierskifte-advarsel-avregistrert-modal';
import { EierskifteAvbrytModal } from './eierskifte-avbryt-modal';
import { EierskifteBekreftAvmonteringModal } from './eierskifte-bekreft-avmontering-modal';
import { EierskifteBekreftAvregistrertModal } from './eierskifte-bekreft-avregistrert-modal';
import { EierskifteFjernMedeierModal } from './eierskifte-fjern-medeier-modal';
import { EierskifteFjernUnderenhetModal } from './eierskifte-fjern-underenhet-modal';
import { EierskifteGodkjennModal } from './eierskifte-godkjenn-modal';
import { EierskifteKjennemerkehandteringAvregistrertModal } from './eierskifte-kjennemerkehandtering-avregistrert-modal';
import { EierskifteKjennemerkehandteringParegistrertModal } from './eierskifte-kjennemerkehandtering-paregistrert-modal';
import { EierskifteLukkModal } from './eierskifte-lukk-modal';
import { EierskifteOversendKjennemerkerModal } from './eierskifte-oversend-kjennemerker-modal';

enum EierskifteModalType {
    GODKJENN = 'GODKJENN',
    AVBRYT = 'AVBRYT',
    LUKK = 'LUKK',
    FJERN_UNDERENHET = 'FJERN_UNDERENHET',
    FJERN_MEDEIER = 'FJERN_MEDEIER',
    KJENNEMERKEHANDTERING_PAREGISTRERT = 'KJENNEMERKEHANDTERING_PAREGISTRERT',
    KJENNEMERKEHANDTERING_AVREGISTRERT = 'KJENNEMERKEHANDTERING_AVREGISTRERT',
    BEKREFTELSE_AVREGISTRERT = 'BEKREFTELSE_AVREGISTRERT',
    OVERSEND_KJENNEMERKER = 'OVERSEND_KJENNEMERKER',
    ADVARSEL_AVREGISTRERT = 'ADVARSEL_AVREGISTRERT',
    BEKREFTELSE_AVMONTERT = 'BEKREFTELSE_AVMONTERT',
    BULK_LUKK = 'BULK_LUKK',
    BULK_GODKJENN = 'BULK_GODKJENN'
}

interface IEierskiftemodalProps {
    lukkModal: () => void;
    modalhandling?: () => void;
}

interface IEierskifteModalerProps {
    modalType: EierskifteModalType;
    lukkModal: () => void;
    modalhandling: () => void;
}

/**
 * Denne komponenten tjener som proxy for de forskjellige modalene som kan vises i eierskifte.
 * Children trenger ikke showModal som prop (den kan alltid være true).
 * modalType fungerer som showModal, har den verdi så skal en modal vises
 * og verdien avgjører hvilken modal denne komponenten er proxy for.
 */

const EierskifteModaler: React.FC<IEierskifteModalerProps> = (props) => {

    switch (props.modalType) {
        case EierskifteModalType.GODKJENN:
            return <EierskifteGodkjennModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.AVBRYT:
            return <EierskifteAvbrytModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.LUKK:
            return <EierskifteLukkModal lukkModal={props.lukkModal} />;
        case EierskifteModalType.FJERN_MEDEIER:
            return <EierskifteFjernMedeierModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.FJERN_UNDERENHET:
            return <EierskifteFjernUnderenhetModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.KJENNEMERKEHANDTERING_PAREGISTRERT:
            return <EierskifteKjennemerkehandteringParegistrertModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.KJENNEMERKEHANDTERING_AVREGISTRERT:
            return <EierskifteKjennemerkehandteringAvregistrertModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.BEKREFTELSE_AVREGISTRERT:
            return <EierskifteBekreftAvregistrertModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.OVERSEND_KJENNEMERKER:
            return <EierskifteOversendKjennemerkerModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.ADVARSEL_AVREGISTRERT:
            return <EierskiteAdvarselAvregistrertModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.BEKREFTELSE_AVMONTERT:
            return <EierskifteBekreftAvmonteringModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        case EierskifteModalType.BULK_LUKK:
            return <BulkLukkModal lukkModal={props.lukkModal} />;
        case EierskifteModalType.BULK_GODKJENN:
            return <BulkGodkjennModal lukkModal={props.lukkModal} modalhandling={props.modalhandling} />;
        default:
            return null;
    }

};

export type { IEierskiftemodalProps };
export { EierskifteModaler, EierskifteModalType };
