import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { MeldingInnhold } from '../../models/types';

const MeldingBasic: React.FC<{ innhold: MeldingInnhold }> = ({innhold}): React.JSX.Element => {
    return (
        <p className="ak-melding-bar-melding-innhold">
            <FormattedMessage id={innhold.tekst} values={(innhold.args)} />
        </p>
    );
};

const MeldingTekst: React.FC<{ innhold: MeldingInnhold }> = ({innhold}): React.JSX.Element => {
    return (
        <>
            {innhold.tekst}
        </>
    );
};

const MeldingNested: React.FC<{ innhold: MeldingInnhold }> = ({innhold}): React.JSX.Element => {
    const linjer = Array.isArray(innhold.args)? innhold.args as []: [innhold.args];
    return (
        <div className="row col-12 ak-melding-bar-melding-innhold py-2">
            <p className="col-12"><FormattedMessage id={innhold.tekst} /></p>
            <ul className="col-12">
                {
                    linjer.map((linje) => <li key={linje}>{linje}</li>)
                }
            </ul>
        </div>
    );
};

const renderMelding = (meldingInnhold: MeldingInnhold): React.JSX.Element => {
    if(!meldingInnhold || !meldingInnhold.type){
        return (<></>);
    }
    switch(meldingInnhold.type){
        case 'BASIC':
            return <MeldingBasic innhold={meldingInnhold} />;
        case 'NESTED':
            return <MeldingNested innhold={meldingInnhold} />;
        case 'TEKST':
            return <MeldingTekst innhold={meldingInnhold} />;
        default:
            throw new Error('Ukjent MeldingInnhold#type: ' + JSON.stringify(meldingInnhold));
    }
};

export { MeldingBasic, MeldingNested, MeldingTekst, renderMelding};
