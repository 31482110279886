import classNames from 'classnames';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import { AkLoading } from 'svv-tk-akr-common-frontend';

interface IExternalLinkProps {
    intlKey: string;
    url?: string;
    param?: string;
    customClassNames?: string;
    loading?: boolean;
}

const ExternalLink: React.FC<IExternalLinkProps & WrappedComponentProps> = (props) => {
    const fallbackUrl = props.intl.messages[ `kjoretoydetaljer.link.${props.intlKey}.url` ]
        ? props.intl.formatMessage({ id: `kjoretoydetaljer.link.${props.intlKey}.url` }, { param: (props.param || '') })
        : '';

    const akloading = <div className={classNames('d-inline-block', props.customClassNames)}><AkLoading extraClassName="ak-spinner-extra-small mt-1"/></div>;

    const link = (
        <a target="_blank" rel="noopener noreferrer" className={props.customClassNames}
           title={props.intl.formatMessage({ id: `kjoretoydetaljer.link.${props.intlKey}.tekst` })}
           href={props.url || fallbackUrl}>
            <FormattedMessage id={`kjoretoydetaljer.link.${props.intlKey}.tekst`} />
        </a>
    );

    return props.loading ? akloading : link;
};

export { ExternalLink };
