import * as _ from 'lodash';
import type { ThunkAction } from 'redux-thunk';
import { MeldingBarActionKey } from '../../constants/action-keys';
import { DEFAULT_MELDING_VISNINGSTID_MS, SYSTEMVARSLER_STORAGE_KEY } from '../../constants/akr-config';
import { Feilkoder } from '../../constants/feilkoder';
import { MeldingType } from '../../models/kodeverk';
import type { IError, IMelding, RootStateType } from '../../models/types';
import { errorChainContainsErrorCode } from '../../utils/akr-error';
import { LocalStorage } from '../../utils/akr-local-storage';

export type MeldingBarActionType = IMeldingBarNyMeldingAction | IMeldingBarFjernMeldingAction;

export interface IMeldingBarNyMeldingAction {
    type: MeldingBarActionKey.MELDING_BAR_NY_MELDING;
    melding: IMelding;
}

export interface IMeldingBarFjernMeldingAction {
    type: MeldingBarActionKey.MELDING_BAR_FJERN_MELDING;
    meldingId: string;
}

export interface IMeldingBarFjernMeldinger {
    type: MeldingBarActionKey.MELDING_BAR_FJERN_MELDINGER;
}

let meldingIdSeq = 0;

export function nyMelding(melding: IMelding): ThunkAction<void, RootStateType, undefined, IMeldingBarNyMeldingAction> {
    return (dispatch, getState) => {
        melding.meldingId = melding.meldingId || `${meldingIdSeq++}`;
        melding.varighetMs = !melding.varighetMs && melding.varighetMs !== 0 ? DEFAULT_MELDING_VISNINGSTID_MS : melding.varighetMs;
        const muligDuplikatMelding = _.find(getState().meldingBar.meldinger, value => value.meldingText === melding.meldingText);

        if (melding.varighetMs > 0 && MeldingType.DANGER !== melding.meldingType) {
            setTimeout(() => dispatch(fjernMelding(melding)), melding.varighetMs);
        }
        if (!!muligDuplikatMelding) {
            dispatch(fjernMelding(muligDuplikatMelding));
        }

        return dispatch({type: MeldingBarActionKey.MELDING_BAR_NY_MELDING, melding});
    };
}

export function fjernMelding(melding: IMelding): ThunkAction<void, RootStateType, undefined, IMeldingBarFjernMeldingAction> {
    const meldingId = melding.meldingId;
    if (melding.meldingType === MeldingType.SYSTEMVARSEL) {
        const kryssedeMeldinger = LocalStorage.getArrayItem(SYSTEMVARSLER_STORAGE_KEY);
        kryssedeMeldinger.push(meldingId);
        LocalStorage.setArrayItem(SYSTEMVARSLER_STORAGE_KEY, kryssedeMeldinger);
    }
    return dispatch => dispatch({type: MeldingBarActionKey.MELDING_BAR_FJERN_MELDING, meldingId});
}

export function fjernAlleMeldinger(): ThunkAction<void, RootStateType, undefined, IMeldingBarFjernMeldinger> {
    return dispatch => dispatch({type: MeldingBarActionKey.MELDING_BAR_FJERN_MELDINGER});
}

export function visTekniskFeil(error?: IError): ThunkAction<void, RootStateType, undefined, IMeldingBarNyMeldingAction> {
    return dispatch => {
        const meldingType = MeldingType.DANGER;
        if (error && error.errorId) {
            const meldingArgs = {errorId: error.errorId};
            if (errorChainContainsErrorCode(error, Feilkoder.APEN_SAK) || errorChainContainsErrorCode(error, Feilkoder.APEN_SAK_FORHANDLER)) {
                return dispatch(nyMelding({meldingIntlId: `feilhandtering.kode.${Feilkoder.APEN_SAK}`, meldingArgs, meldingType}));
            } else if (errorChainContainsErrorCode(error, Feilkoder.KJORETOY_REGISTRERT)) {
                return dispatch(nyMelding({meldingIntlId: `feilhandtering.kode.${Feilkoder.KJORETOY_REGISTRERT}`, meldingArgs, meldingType}));
            } else if(errorChainContainsErrorCode(error, Feilkoder.SAK_KAN_IKKE_TREKKES_TILBAKE)){
                return dispatch(nyMelding({meldingIntlId: `feilhandtering.kode.${Feilkoder.SAK_KAN_IKKE_TREKKES_TILBAKE}`, meldingArgs, meldingType}));
            }
            return dispatch(nyMelding({meldingIntlId: 'feilhandtering.error', meldingArgs, meldingType}));
        } else {
            return dispatch(nyMelding({meldingIntlId: 'feilhandtering.errorNoId', meldingType}));
        }
    };
}
