import type { IKjennemerke, ITekniskeData } from '../models/types/kjoretoy';

export const getFargeFraTekniskeData = (tekniskeData: ITekniskeData): string => {
    const farger = Object.keys(tekniskeData || {}).map((key) => {
        return key.match(/farge/) && tekniskeData[key];
    });

    return farger.filter(i => i).join('/');
};

export const getKjennemerke = (kjennemerke: IKjennemerke): string => {
    return (kjennemerke.tegnkombinasjon || '').replace(' ', '');
};
