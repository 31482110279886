import * as _ from 'lodash';
import { AktorGodkjenningstatus } from 'svv-tk-akr-common-frontend';

import { AkrConfig } from '../../../constants';
import { ArbeidslisteSakstypefilter, MeldingType, SaksType, SamletGodkjenningStatus } from '../../../models/kodeverk';
import type { IAktor, IError, ThunkResult } from '../../../models/types';
import { createRestResource } from '../../store';
import type { ArbeidslisteActionType} from '../arbeidsliste';
import { removeRadSuccess, updateEierskifteOgRegistreringRadStatus, updateForstegangregistreringRadStatus } from '../arbeidsliste';
import { getKunde } from '../kunde';
import type { IMeldingBarNyMeldingAction} from '../melding-bar';
import { nyMelding, visTekniskFeil } from '../melding-bar';
import { hentSak } from './saker-actions';

const aktorApi = createRestResource(AkrConfig.AKTOR_URL);
const aktorGodkjennApi = createRestResource(AkrConfig.AKTOR_GODKJENN_URL);

export const hentAktorer =
    (sakId: string): ThunkResult<Promise<IAktor[]>, IMeldingBarNyMeldingAction> =>
    (dispatch) => {
        return aktorApi.get(sakId).then((response: IAktor[] & IError) => {
            if (response.errorId) {
                dispatch(visTekniskFeil(response));
            } else {
                _.forEach(response, (aktor: IAktor) => {
                    dispatch(getKunde({ kundeId: aktor.kundeId }));
                });
            }
            return response;
        });
    };

export const godkjennForInnloggetEnhet =
    (sakId: string, godkjenningsstatus: AktorGodkjenningstatus, saksType: SaksType): ThunkResult<Promise<IAktor[] | IError>, IMeldingBarNyMeldingAction | ArbeidslisteActionType> =>
    (dispatch, _getState) => {
        return aktorGodkjennApi.put(null, null, { sakId, godkjenningsstatus }).then((response: IAktor & IError) => {
            const bleGodkjenningsstatusOppdatert = response.godkjenningsstatus === godkjenningsstatus;
            if (response.errorId) {
                dispatch(visTekniskFeil(response));
            } else if (!bleGodkjenningsstatusOppdatert) {
                return response;
            } else {
                dispatch(hentSak(sakId));

                if (godkjenningsstatus === AktorGodkjenningstatus.GODKJENT) {
                    switch (saksType) {
                        case SaksType.FORSTEGANGSREGISTRERING:
                            dispatch(updateForstegangregistreringRadStatus(sakId, SamletGodkjenningStatus.GODKJENT));
                            break;
                        case SaksType.EIERSKIFTE:
                        case SaksType.REGISTRERING_SAMME_EIER:
                        case SaksType.REGISTRERING_NY_EIER:
                            dispatch(updateEierskifteOgRegistreringRadStatus(sakId, SamletGodkjenningStatus.GODKJENT));
                    }
                } else {
                    dispatch(removeRadSuccess(sakId, mapSaksTypeTilArbeidslisteSakstypeFilter(saksType)));
                }

                const meldingIntlId = saksType === SaksType.FORSTEGANGSREGISTRERING ? `forstegangsregistrering.melding.${godkjenningsstatus}` : `eierskifte.melding.${godkjenningsstatus}`;
                dispatch(nyMelding({ meldingIntlId: meldingIntlId, meldingType: MeldingType.SUCCESS }));
            }

            return response;
        });
    };

const mapSaksTypeTilArbeidslisteSakstypeFilter = (saksType: SaksType) => {
    switch (saksType) {
        case SaksType.FORSTEGANGSREGISTRERING:
            return ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING;
        case SaksType.EIERSKIFTE:
            return ArbeidslisteSakstypefilter.EIERSKIFTE;
        case SaksType.REGISTRERING_NY_EIER:
        case SaksType.REGISTRERING_SAMME_EIER:
            return ArbeidslisteSakstypefilter.REGISTRERING;
    }
};
