import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Avdeling } from 'svv-tk-akr-common-frontend';
import { AkRadioknapp } from 'svv-tk-akr-common-frontend';

import { EnhetType, RegistreringStatusFilterValg } from '../../constants';
import { KodeType } from '../../models/kodeverk';
import { erHovedenhetInnlogget } from '../../utils';
import { BrukInput } from '../kjoretoy-bruk/bruk-input';

interface IKjoretoylistFilterPanelProps {
    oppdaterRegStatusFilter: (regStatusFilterValg: RegistreringStatusFilterValg) => void;
    handleAvdelingChange: (avdeling: string) => void;
    avdeling: EnhetType;
    valgtEnhet: Avdeling;
    regStatus: RegistreringStatusFilterValg;
}

class KjoretoylisteFilterPanel extends Component<IKjoretoylistFilterPanelProps> {
    public render(): React.ReactElement {
        return (
            <form className="row arbeidsliste-filter-container align-items-baseline mb-3">
                {!erHovedenhetInnlogget(this.props.valgtEnhet) && (
                    <>
                        <label htmlFor="avdeling" className="ak-fet col-auto">
                            <FormattedMessage id="kjoretoyliste.filterpanel.hovedenhetUnderenhet.overskrift" />
                        </label>
                        {this.radioKnapper()}
                    </>
                )}

                <div className="px-3" aria-hidden={true} />

                <label htmlFor="avdeling" className="col-auto ak-fet">
                    <FormattedMessage id="kjoretoyliste.filterpanel.status.overskrift" />
                </label>
                <BrukInput
                    name="avdeling"
                    componentClassNames="col-auto"
                    lesemodus={false}
                    koder={utledRegStatusValg()}
                    kodeVisning={this.props.regStatus}
                    handleSelectChange={this.props.oppdaterRegStatusFilter}
                />
            </form>
        );
    }

    private radioKnapper() {
        return (
            <>
                <AkRadioknapp
                    containerCssClasses="col-auto"
                    inputName="avdeling"
                    checkedValue={this.props.avdeling}
                    value={EnhetType.HOVEDENHET}
                    handleChange={this.props.handleAvdelingChange}
                    labelIntlKey="kjoretoyliste.filterpanel.hovedenhetUnderenhet.valg.hovedenhet"
                />
                <AkRadioknapp
                    containerCssClasses="col-auto"
                    inputName="avdeling"
                    checkedValue={this.props.avdeling}
                    value={EnhetType.UNDERENHET}
                    handleChange={this.props.handleAvdelingChange}
                    labelIntlKey="kjoretoyliste.filterpanel.hovedenhetUnderenhet.valg.underenhet"
                />
            </>
        );
    }
}

const utledRegStatusValg = (): KodeType[] => {
    return Object.keys(RegistreringStatusFilterValg).map(
        (key) => new KodeType({ kode: RegistreringStatusFilterValg[key], beskrivelseId: `kjoretoyliste.filterpanel.status.valg.${RegistreringStatusFilterValg[key]}`, gyldig: true })
    );
};

export { KjoretoylisteFilterPanel };
