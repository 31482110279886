import * as _ from 'lodash';
import { createSelector } from 'reselect';
import { Brukerrolle } from '../../models/kodeverk';
import type { Brukerprofil, RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';

export function brukerprofilSelector(state: RootStateType): Brukerprofil {
    return state.brukerprofil;
}

export const brukerSelector = createSelector(
    brukerprofilSelector,
    (brukerprofil) => (brukerprofil.bruker)
);

export const brukerReglerSelector = createSelector(
    brukerprofilSelector,
    (brukerprofil) => (new BrukerRegler(brukerprofil.bruker))
);


export const valgtEnhetSelector = createSelector(
    brukerprofilSelector,
    (brukerprofil) => (brukerprofil.valgtEnhet)
);

export const harRolleForstegangsregistreringSelector = createSelector(
    brukerSelector,
        (bruker) => bruker && (_.includes(bruker.roller, Brukerrolle.FORSTEGANGSREGISTRERING) || _.includes(bruker.roller, Brukerrolle.FREG))
);

export const harRolleFlateeier = createSelector(
    brukerSelector,
    (bruker) => _.includes(bruker?.roller, Brukerrolle.FLATEEIER)
);

export const harRolleOmregOgSeriekjennemerke = createSelector(
    brukerSelector,
    (bruker) => _.includes(bruker?.roller, Brukerrolle.KJSCM) && _.includes(bruker?.roller, Brukerrolle.OMREG)
);
