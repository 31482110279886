import type { ISystemvarsel } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import { AkrConfig, SYSTEMVARSLER_STORAGE_KEY, SystemvarslerActionKey } from '../../constants';
import { MeldingType } from '../../models/kodeverk';
import type { IError, ThunkResult, IMelding } from '../../models/types';
import { LocalStorage } from '../../utils/akr-local-storage';
import { createRestResource } from '../store';
import { fjernMelding, nyMelding } from './melding-bar';
import type { IOtherAction } from './oversettelser';

export type SystemvarslerActionType = ISystemvarselLoadingAction | ISystemvarselFetchMeldingerSuccessAction | ISystemvarselFetchMeldingerErrorAction | IOtherAction;

export interface ISystemvarselLoadingAction {
    type: SystemvarslerActionKey.SYSTEMVARSLER_IS_LOADING;
    isLoading: boolean;
}

export interface ISystemvarselFetchMeldingerSuccessAction {
    type: SystemvarslerActionKey.SYSTEMVARSLER_FETCH_DATA_SUCCSESS;
    systemvarsler: ISystemvarsel[];
}

export interface ISystemvarselFetchMeldingerErrorAction {
    type: SystemvarslerActionKey.SYSTEMVARSLER_FETCH_DATA_ERROR;
    error: IError;
}

const systemvarslerIsLoading = (isLoading: boolean): ISystemvarselLoadingAction => ({type: SystemvarslerActionKey.SYSTEMVARSLER_IS_LOADING, isLoading});

const systemVarslerFetchSuccess = (systemvarsler: ISystemvarsel[]): ISystemvarselFetchMeldingerSuccessAction => ({
    type: SystemvarslerActionKey.SYSTEMVARSLER_FETCH_DATA_SUCCSESS, systemvarsler
});

const systemVarslerFetchError = (error: IError): ISystemvarselFetchMeldingerErrorAction => ({
    type: SystemvarslerActionKey.SYSTEMVARSLER_FETCH_DATA_ERROR, error
});

const api = createRestResource(AkrConfig.SYSTEMVARSLER_RESOURCE_URL);

export function getSystemvarsler(): ThunkResult<Promise<ISystemvarsel[] | IError | ISystemvarselFetchMeldingerErrorAction>, SystemvarslerActionType> {
    return (dispatch, getState) => {
        dispatch(systemvarslerIsLoading(true));

        return api.get().then(response => {
            if (!!response.errorId) {
                return dispatch(systemVarslerFetchError(response));
            }
            _.map(response, varsel => {
                const eksisterendeVarsel = _.find(getState().systemvarsler.systemvarsler, x => x.id === varsel.id);
                if (!eksisterendeVarsel) {
                    dispatch(nyMelding(createSystemvarsler(varsel)));
                } else if (createHash(eksisterendeVarsel) !== createHash(varsel)) {
                    dispatch(fjernMelding(createSystemvarsler(varsel)));
                    const kryssedeMeldinger = _.filter(LocalStorage.getArrayItem(SYSTEMVARSLER_STORAGE_KEY), varselId => varselId === eksisterendeVarsel.id);
                    LocalStorage.setArrayItem(SYSTEMVARSLER_STORAGE_KEY, kryssedeMeldinger);
                    dispatch(nyMelding(createSystemvarsler(varsel)));
                }
            });
            dispatch(systemVarslerFetchSuccess(_.sortBy(response, 'sistEndretTidspunkt').reverse()));
        });
    };
}

function createHash(systemvarsel: ISystemvarsel): string {
    return `${systemvarsel.overskrift}${systemvarsel.sistEndretTidspunkt}${systemvarsel.id}`;
}

function createSystemvarsler(varsel: ISystemvarsel): IMelding {
    return ({
            meldingType: MeldingType.SYSTEMVARSEL,
            meldingId: varsel.id.toString(),
            varighetMs: 9999999,
            meldingText: {
                type: 'TEKST',
                tekst: varsel.overskrift
            },
            link: '/systemvarsler'
        }
    );
}
