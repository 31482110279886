import { AkKnapp, AkModal, AkModalType } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import type { IRootState } from '../../../models/types';
import type { IEierskiftemodalProps } from './eierskifte-modaler';

export const EierskifteLukkModal: React.FC<IEierskiftemodalProps> = (props) => {

    const kuid = useSelector((state: IRootState) => state.kjoretoy.registrering.kuid);

    return (
        <AkModal showModal={true} modalType={AkModalType.WARN} title="eierskifte.modal.lukkTitle" closeModal={props.lukkModal}>
            <p><FormattedMessage id="eierskifte.modal.lukkBodyText" /></p>
            <div className="ak-knapperad-modal">
                <Link key="lukk" className="ak-knapp ak-hovedknapp" to={`/kjoretoy/${kuid}`}>
                    <FormattedMessage id="eierskifte.modal.lukkEierskifte" />
                </Link>
                <AkKnapp key="fortsett" type="ak-knapp ak-tilleggsknapp" action={props.lukkModal} intlKey="eierskifte.modal.forsetteEierskifte" />
            </div>
        </AkModal>
    );

};