import { VilkarStatus } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';

import { ArbeidslisteActionKey, EierskifterActionKey, SakActionKey } from '../../constants';
import type { RegistreringsstatusKode} from '../../models/kodeverk';
import { ArbeidslisteSakstypefilter, EierskifteStatus, Godkjenningstatus, Saksstatus, SaksType, SamletGodkjenningStatus, SortType } from '../../models/kodeverk';
import type { IArbeidsliste, IArbeidslisteRad, IArbeidslisteState, IArkivdokument, IEierskifte, IMerknad, ISak } from '../../models/types';
import { VilkarRegler } from '../../regler';
import { convertSakstypeToSakstypefilter, mapeSaksdokumenterStatusTilObligatoriskeDokumenter, utledObligatoriskeDokumenter } from '../../utils';
import type { ArbeidslisteActionType, EierskifterActionType, RegistreringerActionType, SakActionType } from '../actions';

type SakstypeTypes = EierskifterActionType | SakActionType | RegistreringerActionType;

const initialState: IArbeidslisteState = {
    skalHenteDokumentasjonsstatus: false,
    eierskifterOgRegistreringer: {
        rader: [],
        sortProp: 'samletGodkjenningStatusSorterbar',
        sortDir: SortType.ASC,
        isLoading: false,
        merknader: []
    },
    forstegangsregistreringer: {
        rader: [],
        sortProp: 'samletGodkjenningStatusSorterbar',
        sortDir: SortType.ASC,
        isLoading: false,
        merknader: []
    },
    nySakIder: [],
    filter: {},
    sakIdGodkjent: ''
};

function handleListAction(state: IArbeidsliste, action: ArbeidslisteActionType | SakstypeTypes) {
    switch (action.type) {
        case ArbeidslisteActionKey.ARBEIDSLISTE_LOADING:
            return {...state, isLoading: action.isLoading, error: action.isLoading ? null : state.error };
        case ArbeidslisteActionKey.ARBEIDSLISTE_FETCH_DATA_ERROR:
            return {...state, isLoading: false, error: action.error};
        case ArbeidslisteActionKey.ARBEIDSLISTE_FETCH_DATA_SUCCESS:
            const nyeRader = action.arbeidslisteRader.map(rad => ({
                ...rad,
                samletGodkjenningStatusSorterbar: sortOrderForSamletGodkjenningStatus(rad.samletGodkjenningStatus),
                kjennemerkeNoSpace: (rad.kjoretoydetaljer.kjennemerke || '').replace(' ', ''),
                merknader: []
            }));

            return {
                ...state,
                rader: sortRader([...state.rader, ...nyeRader], state.sortProp, state.sortDir),
                merknader: [...state.merknader, ...action.merknader],
                error: null
            };

        case ArbeidslisteActionKey.ARBEIDSLISTE_SORTER:
            const sortDir = utledSortDir(action.sortProp, state.sortProp, state.sortDir);
            return {
                ...state, sortProp: action.sortProp, sortDir,
                rader: sortRader(state.rader, action.sortProp, sortDir)
            };
        case ArbeidslisteActionKey.ARBEIDSLISTE_SAK_FJERNET:
            return {...state, rader: state.rader.filter(rad => rad.sakId !== action.sakId)};
        case ArbeidslisteActionKey.ARBEIDSLISTE_NY_MERKNADER:
            return {...state, rader: oppdaterRader(state.rader, action.sakId, rad => ({...rad, merknader: action.merknader}))};
        case EierskifterActionKey.EIERSKIFTER_GODKJENN_SUCCESS:
            return {...state, rader: oppdaterGodkjentEierskifte(state.rader, action.eierskifte)};
        case EierskifterActionKey.EIERSKIFTER_AVVIS_SUCCESS:
            return {...state, rader: oppdaterAvvistEierskifte(state.rader, action.sakId)};
        case SakActionKey.SAK_REQUEST_SUCCESS:
            return {...state, rader: oppdaterSakInformasjon(state.rader, action.response)};
        case ArbeidslisteActionKey.ARBEIDSLISTE_HENT_REGISTRERINGSSTATUSKODE:
            return {...state, rader: oppdaterRegistreringsstatusKode(state.rader, action.sakId, action.registreringsstatusKode)};
        case SakActionKey.SAKER_REMOVE_SUCCESS:
            if (action.response.saksstatus === Saksstatus.AVSLUTTET) {
                return {...state, rader: state.rader.filter(rad => rad.sakId !== String(action.response.sakId))};
            }
            return {...state};
        default:
            return state;
    }
}

function selectListReducer(state: IArbeidslisteState, action): Partial<IArbeidslisteState> {
    const saksType = action.sakstype || convertSakstypeToSakstypefilter((action.response || {} as ISak<any>).saksType);

    switch (saksType) {
        case ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING:
            return { forstegangsregistreringer: handleListAction(state.forstegangsregistreringer, action) };
        case ArbeidslisteSakstypefilter.EIERSKIFTE:
        case ArbeidslisteSakstypefilter.REGISTRERING:
        case ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING:
        default:
            return { eierskifterOgRegistreringer: handleListAction(state.eierskifterOgRegistreringer, action) };
    }
}

function arbeidslisteDokumentstatusReducer(state: IArbeidslisteState, action): Partial<IArbeidslisteState> {
    switch (action.type) {
        case ArbeidslisteActionKey.ARBEIDSLISTE_HENT_ARKIVDOKUMENT_SUCCESS:
        case ArbeidslisteActionKey.ARBEIDSLISTE_HENT_ARKIVDOKUMENT_LOADING:
            const dokumentStatusRegistrering = statusForObligatoriskeDokument(action.arkivdokumenter, action.merknader, action.erTilGodkjenning);
            const sortorderForDokumentRegistrering = sortOrderForDokumentStatus(dokumentStatusRegistrering);

            const oppdaterteEierskifteOgRegRaderMedArkivdokumenter =
                oppdaterRader(state.eierskifterOgRegistreringer.rader, action.sakId,
                        rad => ({...rad, isLoadingArkivdokument: action.isLoading, merknader: [...rad.merknader, action.merknader],
                            samletDokumentStatus: dokumentStatusRegistrering, sortOrderForDokument: sortorderForDokumentRegistrering}));
            const oppdaterteForstegangsregistMedArkivdokumenter =
                oppdaterRader(state.forstegangsregistreringer.rader, action.sakId,
                        rad => ({...rad, isLoadingArkivdokument: action.isLoading, merknader: [...rad.merknader, action.merknader],
                            samletDokumentStatus: dokumentStatusRegistrering, sortOrderForDokument: sortorderForDokumentRegistrering}));
            return {
                ...state,
                skalHenteDokumentasjonsstatus: false,
                eierskifterOgRegistreringer: {...state.eierskifterOgRegistreringer, rader: oppdaterteEierskifteOgRegRaderMedArkivdokumenter},
                forstegangsregistreringer: {...state.forstegangsregistreringer, rader: oppdaterteForstegangsregistMedArkivdokumenter}
            };
        default:
            return state;
    }
}

export function arbeidsliste(state: IArbeidslisteState = initialState, action: ArbeidslisteActionType | SakstypeTypes): IArbeidslisteState {
    switch (action.type) {
        case ArbeidslisteActionKey.ARBEIDSLISTE_LOADING:
            const blirEierskifteOgRegistreringFerdigOgForstegangregistreringErFerdig = state.eierskifterOgRegistreringer.isLoading && !action.isLoading && !state.forstegangsregistreringer.isLoading;
            const blirForstegangregistreringFerdigOgEierskifteOgRegistreringErFerdig = state.forstegangsregistreringer.isLoading && !action.isLoading && !state.eierskifterOgRegistreringer.isLoading;
            const erAlleListerFerdigLastet = blirEierskifteOgRegistreringFerdigOgForstegangregistreringErFerdig || blirForstegangregistreringFerdigOgEierskifteOgRegistreringErFerdig;

            return {...state, ...selectListReducer(state, action), avdelingId: action.avdelingId, skalHenteDokumentasjonsstatus: erAlleListerFerdigLastet};

        case ArbeidslisteActionKey.ARBEIDSLISTE_FETCH_DATA_SUCCESS:
            if (state.avdelingId !== action.avdelingId) {
                return state;
            }

            return {...state, ...selectListReducer(state, action)};

        case ArbeidslisteActionKey.ARBEIDSLISTE_NY_SAK:
            return {...state, nySakIder: [...action.nySakIder]};
        case ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_FORSTEGANGSREGISTRERING_RAD:
            return {...state, forstegangsregistreringer: {
                ...state.forstegangsregistreringer,
                    rader: oppdaterWithNyStatus(state.forstegangsregistreringer.rader, action.sakId, action.nyStatus)
            }};
        case ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_EIERSKIFTE_OG_REGISTRERING_RAD:
            return {...state, eierskifterOgRegistreringer: {
                ...state.eierskifterOgRegistreringer,
                    rader: oppdaterWithNyStatus(state.eierskifterOgRegistreringer.rader, action.sakId, action.nyStatus)
            }};
        case ArbeidslisteActionKey.ARBEIDSLISTE_FETCH_DATA_ERROR:
        case ArbeidslisteActionKey.ARBEIDSLISTE_SORTER:
        case ArbeidslisteActionKey.ARBEIDSLISTE_SAK_FJERNET:
        case ArbeidslisteActionKey.ARBEIDSLISTE_NY_MERKNADER:
        case ArbeidslisteActionKey.ARBEIDSLISTE_HENT_REGISTRERINGSSTATUSKODE:
        case EierskifterActionKey.EIERSKIFTER_GODKJENN_SUCCESS:
        case EierskifterActionKey.EIERSKIFTER_AVVIS_SUCCESS:
        case SakActionKey.SAK_REQUEST_SUCCESS:
        case SakActionKey.SAKER_REMOVE_SUCCESS:
            return {...state, ...selectListReducer(state, action)};
        case ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_ALL:
            return {
                ...state,
                eierskifterOgRegistreringer: initialState.eierskifterOgRegistreringer,
                forstegangsregistreringer: initialState.forstegangsregistreringer
            };
        case ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_EIERSKIFTER_OG_REGISTRERINGER:
            return {
                ...state,
                eierskifterOgRegistreringer: initialState.eierskifterOgRegistreringer
            };
        case ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_FORSTEGANGSREGISTRERINGER:
            return {
                ...state,
                forstegangsregistreringer: initialState.forstegangsregistreringer
            };
        case ArbeidslisteActionKey.ARBEIDSLISTE_HENT_ARKIVDOKUMENT_SUCCESS:
        case ArbeidslisteActionKey.ARBEIDSLISTE_HENT_ARKIVDOKUMENT_LOADING:
            return { ...state, ...arbeidslisteDokumentstatusReducer(state, action) };
        case ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_FILTER:
            return { ...state, filter: action.filter, sakIdGodkjent: '' };
        case ArbeidslisteActionKey.ARBEIDSLISTE_SAK_GODKJENT:
            return {...state, sakIdGodkjent: action.sakIdGodkjent};
        default:
            return state;
    }
}

function oppdaterSakInformasjon(rader: IArbeidslisteRad[], sak: ISak): IArbeidslisteRad[] {
    const saksType = sak.saksType;
    const apenSak = sak.saksstatus === Saksstatus.APEN;
    switch (saksType) {
        case SaksType.REGISTRERING_NY_EIER:
        case SaksType.REGISTRERING_SAMME_EIER:
            if (apenSak) {
                return oppdaterWithDokumentstatus(rader, sak, SamletGodkjenningStatus.PABEGYNT_REGISTRERING);
            } else {
                return oppdaterRader(rader, sak.sakId, rad => {
                    return {
                        ...rad, merknader: sak.merknader || [], samletGodkjenningStatus: SamletGodkjenningStatus.REGISTRERT
                    };
                });
            }
        case SaksType.FORSTEGANGSREGISTRERING:
            if (apenSak) {
                return oppdaterWithDokumentstatus(rader, sak);
            } else {
                return rader;
            }
        case SaksType.EIERSKIFTE:
        default:
            return rader;
    }
}

function oppdaterWithNyStatus(rader: IArbeidslisteRad[], sakId: string, nyStatus: SamletGodkjenningStatus) {
    return oppdaterRader(rader, sakId, rad => ({...rad, samletGodkjenningStatus: nyStatus}));
}

function oppdaterWithDokumentstatus(rader: IArbeidslisteRad[], sak: ISak, samletGodkjenningStatusRegistrering?: SamletGodkjenningStatus): IArbeidslisteRad[] {
    const dokumentStatusRegistrering = statusForObligatoriskeDokument(sak.arkivdokumenter, sak.merknader);
    const sortorderForDokumentRegistrering = sortOrderForDokumentStatus(dokumentStatusRegistrering);
    return oppdaterRader(rader, sak.sakId, rad => {
        return {
            ...rad, samletGodkjenningStatus: samletGodkjenningStatusRegistrering || rad.samletGodkjenningStatus, merknader: sak.merknader || [],
            samletDokumentStatus: dokumentStatusRegistrering, sortOrderForDokument: sortorderForDokumentRegistrering
        };
    });
}

function oppdaterGodkjentEierskifte(rader: IArbeidslisteRad[], eierskifte: IEierskifte): IArbeidslisteRad[] {
    return oppdaterRader(rader, eierskifte.sakId, rad => {
        const aktorerGodkjent = rad.sakAktorInfo.aktorerGodkjent + 1;
        let samletGodkjenningStatus: SamletGodkjenningStatus;
        if (eierskifte.saksstatus === EierskifteStatus.AVBRUTT) {
            samletGodkjenningStatus = SamletGodkjenningStatus.AVVIST;
        } else {
            samletGodkjenningStatus = aktorerGodkjent === rad.sakAktorInfo.aktorer ? SamletGodkjenningStatus.GODKJENT : rad.samletGodkjenningStatus;
        }
        return {...rad, sakAktorInfo: {...rad.sakAktorInfo, godkjenningsstatus: Godkjenningstatus.GODKJENT, aktorerGodkjent}, samletGodkjenningStatus, merknader: eierskifte.merknader || []};
    });
}

function oppdaterAvvistEierskifte(rader: IArbeidslisteRad[], sakId: string): IArbeidslisteRad[] {
    return oppdaterRader(rader, sakId, rad => ({
        ...rad,
        sakAktorInfo: {...rad.sakAktorInfo, godkjenningsstatus: Godkjenningstatus.AVVIST},
        samletGodkjenningStatus: SamletGodkjenningStatus.AVVIST
    }));
}

function oppdaterRegistreringsstatusKode(rader: IArbeidslisteRad[], sakId: string, registreringsstatusKode: RegistreringsstatusKode): IArbeidslisteRad[] {
    return oppdaterRader(rader, sakId, rad => ({
        ...rad,
        kjoretoydetaljer: {...rad.kjoretoydetaljer, registreringsstatusKode}
    }));
}

function oppdaterRader(rader: IArbeidslisteRad[], sakId: string, oppdaterRad: (rad: IArbeidslisteRad) => IArbeidslisteRad): IArbeidslisteRad[] {
    return rader.map(rad => rad.sakId === sakId ? oppdaterRad(rad) : rad);
}

function sortOrderForSamletGodkjenningStatus(samletGodkjenningStatus: SamletGodkjenningStatus): number {

    switch (samletGodkjenningStatus) {
        case SamletGodkjenningStatus.AVVIST:
            return 1;
        case SamletGodkjenningStatus.VENTER:
            return 2;
        case SamletGodkjenningStatus.GODKJENT:
            return 3;
        case SamletGodkjenningStatus.REGISTRERT:
            return 4;
        case SamletGodkjenningStatus.PABEGYNT_REGISTRERING:
            return 5;
        case SamletGodkjenningStatus.FULLFOR_PA_TRAFIKKSTASJON:
            /* eslint-disable @typescript-eslint/no-magic-numbers*/
            return 6;
            /* eslint-ensable @typescript-eslint/no-magic-numbers*/
        default:
            return 0;
    }
}

function statusForObligatoriskeDokument(arkivdokumenter: IArkivdokument[], merknader: IMerknad[], erTilGodkjenning?: boolean): VilkarStatus {
    const obligatoriskeDokumenter = utledObligatoriskeDokumenter(merknader);
    if (!_.isEmpty(obligatoriskeDokumenter)) {
        if (!_.isEmpty(arkivdokumenter)) {
            const saksdokumenter = _.flatMap(arkivdokumenter.map(arkivdokument => arkivdokument.saksdokumenter));
            const saksdokumenterMappingTilobligatoriskeDokumenter = mapeSaksdokumenterStatusTilObligatoriskeDokumenter(saksdokumenter, obligatoriskeDokumenter);
            return VilkarRegler.utledVilkarForDokumenterForArbeidsliste(saksdokumenterMappingTilobligatoriskeDokumenter, erTilGodkjenning).status;
        } else {
            return VilkarRegler.utledVilkarForDokumenterForArbeidsliste(obligatoriskeDokumenter).status;
        }
    }
    return VilkarStatus.IKKE_PAKREVET;
}

function sortOrderForDokumentStatus(vilkarstatus: VilkarStatus): number {
    switch (vilkarstatus) {
        case VilkarStatus.MA_GODKJENNES:
            return 1;
        case VilkarStatus.IKKE_OPPFYLT:
            return 2;
        case VilkarStatus.OPPFYLT:
            return 3;
        case VilkarStatus.IKKE_PAKREVET:
        default:
            return 4;
    }
}

function sortRader(rader: IArbeidslisteRad[], sortProp: string, sortDir: SortType = SortType.ASC): IArbeidslisteRad[] {
    return _.orderBy(rader, [sortProp, 'sistEndret'], [sortDir, SortType.DESC]);
}

function utledSortDir(sortProp: string, currentSortProp: string, currentSortDir: SortType): SortType {
    if (sortProp !== currentSortProp || currentSortDir === SortType.DESC) {
        return SortType.ASC;
    } else {
        return SortType.DESC;
    }
}
