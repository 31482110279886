import type { ISystemvarsler} from 'svv-tk-akr-common-frontend';
import { AkLoading, Systemvarsel } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RootStateType } from '../../models/types';
import { getSystemvarsler } from '../../state/actions';

interface ISystemVarslerStateProps {
    systemvarsler: ISystemvarsler;
}

interface ISystemvarslerDispatchProps {
    getSystemvarsler: () => Promise<any>;
}

class Systemvarsler extends Component<ISystemVarslerStateProps & ISystemvarslerDispatchProps> {

    public componentDidMount() {
        this.props.getSystemvarsler();
    }

    public render() {
        return (
            <div className="container-fluid ak-startside-container">
                <div className={'ak-panel col-12 ak-panel-transparent ak-med-tabell'}>
                    <FormattedMessage tagName="h1" id={'systemvarsler.overskrift'} />
                    {this.renderSystemvarsler()}
                </div>
            </div>
        );
    }

    private renderSystemvarsler = () => {
        const {systemvarsler} = this.props;
        if (systemvarsler.isLoading) {
            return <AkLoading extraClassName="mt-3" />;
        }

        return _.map(systemvarsler.systemvarsler, (systemvarsel) => {
            return <Systemvarsel key={systemvarsel.id} systemvarsel={systemvarsel} />;
        });
    };
}

const mapStateToProps = (state: RootStateType): ISystemVarslerStateProps => ({
    systemvarsler: state.systemvarsler
});

const mapDispatchToProps = (dispatch): ISystemvarslerDispatchProps => ({
    getSystemvarsler: () => dispatch(getSystemvarsler())
});

const SystemvarslerConnected = connect(mapStateToProps, mapDispatchToProps)(Systemvarsler);

export { SystemvarslerConnected };
