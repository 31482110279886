import { AkDropdownKnapp, AkKnapp, AktorGodkjenningstatus } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import type { ReactNode } from 'react';
import { Component } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { ArbeidslisteValg, MeldingType, RegistreringsstatusKode, SaksType, SamletGodkjenningStatus } from '../../models/kodeverk';
import type { Brukerprofil, IArbeidslisteRad, IEierskifte, IMelding, RootStateType } from '../../models/types';
import { finnValgForArbeidslisterad } from '../../regler';
import { avvisEierskifte, endreEierskifte, fjernSak, godkjennEierskifte, hentMidlertidigVognkort, hentRegistreringsstatus, nyMelding, oppdaterRegistreringArbeidsliste } from '../../state/actions';
import { godkjennForInnloggetEnhet } from '../../state/actions/sak/aktor-actions';
import { brukerprofilSelector, brukerReglerSelector } from '../../state/selectors';
import type { WithRouterProps} from '../../utils';
import { forstegangsregSakUrl, nyEierskifteSakUrl, nyOmregistreringSakUrl, withRouter } from '../../utils';
import { ValgButton } from '../startside';
import { ArbeidslisteCardButtons } from './arbeidsliste-card-buttons';

import './arbeidsliste-style';

interface IOppgaveValgProps {
    rad: IArbeidslisteRad;
    openModalForGodkjenEierskifte?: (sakId: string) => void;
    openModalForPurring?: (sak: string) => void;
}

interface IOppgaveValgStateProps {
    isFlateeier: boolean;
    brukerprofil: Brukerprofil;
}

interface IOppgaveValgDispatchProps {
    hentRegistreringsstatus: (kuid: string, sakId: string) => Promise<RegistreringsstatusKode | void>;
    endreEierskifte: (eierskifte: IEierskifte) => Promise<void>;
    fjern: (rad: IArbeidslisteRad) => Promise<void>;
    godkjennEierskifte: (sakId: string) => Promise<void>;
    avvisEierskifte: (sakId: string) => Promise<void>;
    oppdaterRegistreringArbeidsliste: (sakId: string) => Promise<any>;
    nyMelding: (melding: IMelding) => Promise<void>;
    hentMidlertidigVognkort: (kuid: string, sakId: string) => Promise<void>;
    godkjennForInnloggetEnhet: (sakId: string, godkjenningstatus: AktorGodkjenningstatus, saksType: SaksType) => Promise<any>;
}

interface IOppgaveValgState {
    resolvePromise?: () => void;
    isButtonOverlayOpen: boolean;
}

/**
 * Begge oppgavevalg rendres her og visningen bestemmes av CSS og media-queries
 * basert på skjermbredde
 */
class OppgaveValg extends Component<IOppgaveValgProps & IOppgaveValgStateProps & IOppgaveValgDispatchProps & WrappedComponentProps & WithRouterProps, IOppgaveValgState> {

    public state: IOppgaveValgState = {
        isButtonOverlayOpen: false
    };

    public render(): React.ReactElement {
        return (
            <>
                {this.renderStorSkjerm()}
                {this.renderLitenSkjerm()}
            </>
        );
    }

    private renderStorSkjerm = () => {
        const valgListe = finnValgForArbeidslisterad(this.props.brukerprofil, this.props.rad);
        if (valgListe.length > 2) {
            const firstValg = valgListe.shift();
            return (
                <div className="ak-table-knapper arbeidsliste-knapper-large">
                    <ValgButton valg={firstValg} handleAction={this.handleAction} first={true} />
                    <AkDropdownKnapp intlKeyTooltip="sak.valg.mer_tooltip" useDotButton={true} type="ak-tilleggsknapp">
                        {valgListe.map(this.valgMapper)}
                    </AkDropdownKnapp>
                </div>
            );
        }

        return (
            <div className="ak-table-knapper arbeidsliste-knapper-large">
                {valgListe.map(this.valgMapperDefault)}
            </div>
        );
    };

    private renderLitenSkjerm = () => {
        const valgListe = finnValgForArbeidslisterad(this.props.brukerprofil, this.props.rad);

        return (
            <div className="arbeidsliste-knapper-small">
                <span className="arbeidsliste-mer-knapp-clickarea" onClick={this.handleOnClickOpenKnappeVisning} role="button" tabIndex={-1}>
                    <AkKnapp customClassName="arbeidsliste-mer-knapp" title="sak.valg.mer_tooltip" action={this.openKnappevisning} type="ak-tilleggsknapp">
                        <span>&bull;&bull;&bull;</span>
                    </AkKnapp>
                </span>
                <ArbeidslisteCardButtons isOpen={this.state.isButtonOverlayOpen} onClose={this.closeKnappevisning}>
                    {valgListe.map(this.valgMapperDefault)}
                </ArbeidslisteCardButtons>
            </div>
        );
    };

    private closeKnappevisning = () => {
        this.setState({ isButtonOverlayOpen: false });
    };
    private openKnappevisning = (event?: React.SyntheticEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({ isButtonOverlayOpen: true });
    };
    private handleOnClickOpenKnappeVisning = (e: React.SyntheticEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        this.openKnappevisning();
    };

    private valgMapper = (valg: ArbeidslisteValg): ReactNode => (
        <ValgButton key={valg} valg={valg} handleAction={this.handleAction} first={false} />
    );
    private valgMapperDefault = (valg: ArbeidslisteValg, index: number): ReactNode => (
        <ValgButton key={valg} valg={valg} handleAction={this.handleAction} first={index === 0} />
    );
    private handleAction = (valg: ArbeidslisteValg): Promise<void> | void => {
        const {rad, fjern} = this.props;
        switch (valg) {
            case ArbeidslisteValg.NYTT_EIERSKIFTE:
                return this.props.navigate(nyEierskifteSakUrl(rad));
            case ArbeidslisteValg.AVSLUTT:
                return fjern(rad);
            case ArbeidslisteValg.OMREGISTRER:
                return this.props.navigate(nyOmregistreringSakUrl(rad));
            case ArbeidslisteValg.GODKJENN:
                return this.godkjenn(rad);
            case ArbeidslisteValg.AVVIS:
                return this.handleAvvis(rad);
            case ArbeidslisteValg.MIDLERTIDIG_VOGNKORT:
                return this.props.hentMidlertidigVognkort(rad.kuid, rad.sakId);
            case ArbeidslisteValg.OPPDATER_BETALING:
                return this.props.oppdaterRegistreringArbeidsliste(rad.sakId)
                    .then(() => {
                        if (this.props.rad.samletGodkjenningStatus === SamletGodkjenningStatus.REGISTRERT) {
                            this.props.nyMelding({meldingIntlId: 'startside.melding.registrertLinje1', meldingType: MeldingType.SUCCESS});
                        }
                    });
            case ArbeidslisteValg.REGISTRER:
                return this.props.navigate(forstegangsregSakUrl(rad));
            case ArbeidslisteValg.PURRE:
                return this.openModalForPurring(rad.sakId);
            default:
                break;
        }
    };

    private openModalForPurring(sakId: string): Promise<void> {
        this.props.openModalForPurring(sakId);
        return Promise.resolve();
    }

    private godkjenn(rad: IArbeidslisteRad): Promise<void> {
        if (rad.saksType === SaksType.FORSTEGANGSREGISTRERING || (rad.saksType === SaksType.EIERSKIFTE && this.props.isFlateeier)) {
            return this.props.godkjennForInnloggetEnhet(rad.sakId, AktorGodkjenningstatus.GODKJENT, rad.saksType);
        }

        return this.hentRegistreringsKodeFraStore(rad)
            .then((registreringsstatusKode: RegistreringsstatusKode) => {
                if (!registreringsstatusKode) {
                    return Promise.resolve();
                }
                if (registreringsstatusKode === RegistreringsstatusKode.REGISTRERT) {
                    this.props.openModalForGodkjenEierskifte(rad.sakId);
                    return Promise.resolve();
                } else {
                    return this.props.godkjennEierskifte(rad.sakId);
                }
            });
    }

    private handleAvvis = (rad: IArbeidslisteRad) => {
        if (rad.saksType === SaksType.FORSTEGANGSREGISTRERING || (rad.saksType === SaksType.EIERSKIFTE && this.props.isFlateeier)) {
            return this.props.godkjennForInnloggetEnhet(rad.sakId, AktorGodkjenningstatus.AVVIST, rad.saksType);
        }

        return this.props.avvisEierskifte(rad.sakId);
    };

    private hentRegistreringsKodeFraStore(rad: IArbeidslisteRad): Promise<RegistreringsstatusKode | void> {
        const {registreringsstatus} = rad.kjoretoydetaljer;
        if (registreringsstatus) {
            return Promise.resolve(registreringsstatus);
        } else {
            return this.props.hentRegistreringsstatus(rad.kuid, rad.sakId);
        }
    }
}

const mapStateToProps = (state: RootStateType): IOppgaveValgStateProps => ({
    isFlateeier: brukerReglerSelector(state).harRolleFlateeier(),
    brukerprofil: brukerprofilSelector(state)
});

const mapDispatchToProps = (dispatch): IOppgaveValgDispatchProps => ({
    hentRegistreringsstatus: (kuid: string, sakId: string) => dispatch(hentRegistreringsstatus(kuid, sakId)),
    endreEierskifte: (eierskifte: IEierskifte) => dispatch(endreEierskifte(eierskifte)),
    fjern: (rad: IArbeidslisteRad) => dispatch(fjernSak(rad)),
    godkjennEierskifte: (sakId: string) => dispatch(godkjennEierskifte(sakId, true)),
    avvisEierskifte: (sakId: string) => dispatch(avvisEierskifte(sakId)),
    oppdaterRegistreringArbeidsliste: (sakId: string) => dispatch(oppdaterRegistreringArbeidsliste(sakId)),
    nyMelding: (melding: IMelding) => dispatch(nyMelding(melding)),
    hentMidlertidigVognkort: (kuid: string, sakId: string) => dispatch(hentMidlertidigVognkort(kuid, sakId)),
    godkjennForInnloggetEnhet: (sakId: string, godkjenningstatus: AktorGodkjenningstatus, saksType: SaksType) => dispatch(godkjennForInnloggetEnhet(sakId, godkjenningstatus, saksType))
});

const OppgaveValgConnected = compose(
    connect<IOppgaveValgStateProps, IOppgaveValgDispatchProps, IOppgaveValgProps>(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withRouter
)(OppgaveValg);

export { OppgaveValgConnected, OppgaveValg };
