import type { IAktorPanelAktor} from 'svv-tk-akr-common-frontend';
import { AkKnapp, AktorPanel, AktorType, GodkjenningUtfortType, Panel, utledAktor } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { RegelmerknaderType } from '../../models/kodeverk';
import type { Brukerprofil, IAktor, IGetKundeMedKundeId, IGetKundeMedOrgFnr, IKjoretoy, IKunde, IKunder, IRegistrering, RootStateType } from '../../models/types';
import { BrukerRegler, EierskifteRegler } from '../../regler';
import { getKunde, getKundeMedForhandlerInfo } from '../../state/actions';
import { kjoretoySelector } from '../../state/selectors';
import { erAktorMyndig, erUnderenhet } from '../../utils';
import { AktorInfo } from '../felles/aktor-info';

interface IEierskifteAktorerStateProps {
    kunder?: IKunder;
    brukerprofil?: Brukerprofil;
    registrering?: IRegistrering;
    kjoretoy?: IKjoretoy;
}

interface IEierskifteAktorerDispatchProps {
    getKunde?: (nummer: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktortype?: AktorType, kuid?: string) => Promise<any>;
    getKundeMedForhandlerInfo?: (nummer: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktortype?: AktorType, kuid?: string) => Promise<any>;
}

interface IEierskifteAktorerProps {
    aktorer: IAktorPanelAktor[];
    oppdaterAktorer: (aktor: IAktorPanelAktor[]) => void;
    slettAktor: (aktor: IAktor) => void;
    submitted: boolean;
    lesemodus: boolean;
    kjoretoy?: IKjoretoy;
}

interface ICreateAktorPanelProps {
    aktor: IPanelAktor;
    kanEndreAktor: boolean;
    ikkeVisGodkjenning?: boolean;
    visForhandsutfyltValg?: boolean;
}

type IPanelAktor = IAktorPanelAktor & Pick<IKunde, 'ikkeVisKunde'>;

type PropsType = IEierskifteAktorerProps & IEierskifteAktorerStateProps & IEierskifteAktorerDispatchProps;

const EIERSKIFTE_VALIDERING_PREFIX = 'eierskifte.validering';

class EierskifteAktorer extends React.Component<PropsType> {
    public componentDidMount(): void {
        this.oppdaterAktorerMedKundeData();
    }

    public componentDidUpdate(): void {
        this.oppdaterAktorerMedKundeData();
    }

    public render(): React.ReactNode {
        const { aktorer, lesemodus } = this.props;
        if (!aktorer.length) {
            return null;
        }

        return (
            <Panel overskrift={'eierskifte.overskrift.eiere'} panelCssClass="col-12 ak-panel-transparent ak-med-tabell" innholdCssClass="row no-gutters">
                <div className="col-12 col-md">{this.opprettPanelForEksisterendeAktorer()}</div>
                <div className="ak-panel-separator col-md-1 col-12 row justify-content-center no-gutters my-auto">
                    <i className="fa fa-chevron-right d-none d-md-flex" />
                    <i className="fa fa-chevron-down d-flex d-md-none mb-3" />
                </div>
                <div className="col-12 col-md">
                    {this.opprettPanelForNyeAktorer()}
                    {!lesemodus && this.canAddNyMedeier() && <AkKnapp type="ak-link-knapp ak-ikon-legg-til" intlKey={'eierskifte.knapp.leggTilMedeier'} action={this.addMedeierButtonAction} />}
                    {!lesemodus && this.canAddAktorNyUnderenhet() && (
                        <AkKnapp type="ak-link-knapp ak-ikon-legg-til" intlKey={'eierskifte.knapp.leggTilUnderenhet'} action={this.addUnderenhetButtonAction} />
                    )}
                </div>
            </Panel>
        );
    }

    private hasSakAktorType = (type: AktorType): boolean => {
        return _.some(this.props.aktorer, { aktorType: type });
    };

    private canAddNyMedeier = (): boolean => {
        const hasPopulatedEier = _.some(this.props.aktorer, (aktor: IAktorPanelAktor) => aktor.aktorType === AktorType.NY_EIER && !!aktor.navn);
        return hasPopulatedEier && !this.hasSakAktorType(AktorType.NY_MEDEIER);
    };

    private canAddAktorNyUnderenhet = (): boolean => {
        const nyEier = _.find(this.props.aktorer, { aktorType: AktorType.NY_EIER }) || ({} as IAktorPanelAktor);
        const innloggetBrukersHovedenhetOrgNrErLiktNyEierOrgNr = new BrukerRegler(this.props.brukerprofil).erForhandlerEier(nyEier);
        return !this.hasSakAktorType(AktorType.NY_UNDERENHET) && !!nyEier.organisasjonsnummer && !innloggetBrukersHovedenhetOrgNrErLiktNyEierOrgNr;
    };

    private addMedeierButtonAction = () => this.oppdaterAktor({ aktorType: AktorType.NY_MEDEIER });

    private addUnderenhetButtonAction = () => this.oppdaterAktor({ aktorType: AktorType.NY_UNDERENHET });

    private oppdaterAktor = (aktor: IAktorPanelAktor, slett?: boolean) => {
        if (!aktor.aktorType) {
            return null;
        }

        if (slett) {
            return this.props.slettAktor(aktor);
        }

        let aktorer = _.flatten(_.reject(this.props.aktorer, { aktorType: aktor.aktorType }).concat(aktor));

        // Sletter ny underenhet hvis ny eier ikke er en organisasjon
        if (aktor.aktorType === AktorType.NY_EIER && aktor.kundeId && !aktor.organisasjonsnummer) {
            aktorer = _.reject(aktorer, { aktorType: AktorType.NY_UNDERENHET });
        }

        return this.props.oppdaterAktorer(aktorer);
    };

    private lagAktorPanel = ({ aktor, kanEndreAktor, ikkeVisGodkjenning, visForhandsutfyltValg }: ICreateAktorPanelProps) => (
        <AktorPanel
            panelClassName="col-md col-12 no-gutters"
            sokInputClassName="ak-input-fast-bredde"
            panelOverskrift={`eierskifte.overskrift.${aktor.aktorType}`}
            aktor={{
                ...aktor,
                visKontaktinfo: this.skalViseKontaktinfoForAktor(aktor),
                panelLesemodus: this.props.lesemodus,
                kanEndreAktor,
                customError: aktor.ikkeVisKunde ? 'eierskifte.aktorer.informasjonIkkeTilgjengeligIKanal' : null
            }}
            kunder={this.props.kunder}
            hentAktor={this.hentAktor}
            submitted={this.props.submitted || !!aktor?.customError}
            valideringTekstPrefix={EIERSKIFTE_VALIDERING_PREFIX}
            handleAktorChange={this.oppdaterAktor}
            skalIkkeViseGodkjenningsstatus={!this.props.lesemodus || ikkeVisGodkjenning}
            skalViseKundeIkon={true}
        >
            <AktorInfo
                signaturtyper={EierskifteRegler.utledGyldigeSignaturtyper()}
                kanVelgeSignaturtype={EierskifteRegler.kanAktorVelgeSignaturtype(aktor, this.props.kjoretoy)}
                visForhandsutfyltValg={visForhandsutfyltValg}
            />
        </AktorPanel>
    );

    private opprettPanelForEksisterendeAktorer = () => {
        const eier = _.find(this.props.aktorer, { aktorType: AktorType.EIER });
        const medeier = _.find(this.props.aktorer, { aktorType: AktorType.MEDEIER });
        const underenhet = _.find(this.props.aktorer, { aktorType: AktorType.UNDERENHET });
        return (
            <>
                {this.lagAktorPanel({ aktor: eier, kanEndreAktor: false, ikkeVisGodkjenning: false, visForhandsutfyltValg: true })}
                {medeier && this.lagAktorPanel({ aktor: medeier, kanEndreAktor: false, ikkeVisGodkjenning: false, visForhandsutfyltValg: true })}
                {underenhet && this.lagAktorPanel({ aktor: underenhet, kanEndreAktor: false, ikkeVisGodkjenning: true, visForhandsutfyltValg: true })}
            </>
        );
    };

    private opprettPanelForNyeAktorer = () => {
        const nyEier = _.find(this.props.aktorer, { aktorType: AktorType.NY_EIER });
        const nyMedeier = _.find(this.props.aktorer, { aktorType: AktorType.NY_MEDEIER });
        const nyUnderenhet = _.find(this.props.aktorer, { aktorType: AktorType.NY_UNDERENHET });

        return (
            <>
                {this.lagAktorPanel({ aktor: nyEier, kanEndreAktor: this.isEditable(nyEier), visForhandsutfyltValg: true })}
                {nyMedeier && this.lagAktorPanel({ aktor: nyMedeier, kanEndreAktor: this.isEditable(nyMedeier), visForhandsutfyltValg: true })}
                {nyUnderenhet && this.lagAktorPanel({ aktor: nyUnderenhet, kanEndreAktor: this.isEditable(nyUnderenhet), ikkeVisGodkjenning: true, visForhandsutfyltValg: true })}
            </>
        );
    };

    private hentAktor = (orgFnr: string, aktorType: AktorType): Promise<void> => {
        const kundeAction = erUnderenhet({ aktorType }) ? this.props.getKunde : this.props.getKundeMedForhandlerInfo;
        this.oppdaterAktor({ aktorType });
        return kundeAction({ orgFnr }, aktorType, this.props.registrering.kuid).then(() => this.handterHentetKunde(orgFnr, aktorType));
    };

    private oppdaterAktorerMedKundeData = () => {
        _.forEach(this.props.aktorer, (aktor) => {
            if (this.skalOppdatereAktorMedKundedata(aktor)) {
                const kundeAction = erUnderenhet(aktor) ? this.props.getKunde : this.props.getKundeMedForhandlerInfo;
                kundeAction({ kundeId: aktor.kundeId }, aktor.aktorType, this.props.registrering.kuid).then(() => {
                    const kunde = _.get(this.props.kunder, aktor.kundeId);
                    this.oppdaterAktor(utledAktor(aktor, kunde, true));
                });
            }
        });
    };

    private skalOppdatereAktorMedKundedata = (aktor: IAktor) => aktor.kundeId && _.isEmpty(_.get(this.props.kunder, aktor.kundeId));

    private handterHentetKunde = (orgFnr: string, aktorType: AktorType) => {
        const kunde = _.get(this.props.kunder, orgFnr);
        const errors = this.utledCustomKundeError(kunde, aktorType);

        if (errors) {
            return this.oppdaterAktor({ aktorType, customError: errors });
        } else if (!_.find(kunde && kunde.merknader, { type: RegelmerknaderType.KRITISK })) {
            return this.oppdaterAktor(utledAktor({ aktorType }, kunde, true));
        }
    };

    private utledCustomKundeError = (kunde: IKunde, aktorType: AktorType) => {
        const nyEier = _.find(this.props.aktorer, { aktorType: AktorType.NY_EIER });
        const nyUnderenhet = _.find(this.props.aktorer, { aktorType: AktorType.NY_UNDERENHET });
        const nyMedeier = _.find(this.props.aktorer, { aktorType: AktorType.NY_MEDEIER });
        const nyLeasingtaker = _.find(this.props.aktorer, { aktorType: AktorType.NY_LEASINGTAKER });
        if (aktorType === AktorType.NY_UNDERENHET) {
            if ([nyEier, nyMedeier, nyLeasingtaker].some((aktor) => aktor && aktor.organisasjonsnummer === kunde.organisasjonsnummer)) {
                return 'sak.validering.UNDERENHET';
            }
            if (!this.gyldigUnderenhet(kunde, nyEier.organisasjonsnummer)) {
                return `${EIERSKIFTE_VALIDERING_PREFIX}.NY_UNDERENHET_ER_JURIDISKENHET`;
            }
        } else if (aktorType === AktorType.NY_MEDEIER && [nyEier, nyUnderenhet].some((aktor) => aktor && aktor.kundeId === kunde.kundeId)) {
            return `${EIERSKIFTE_VALIDERING_PREFIX}.nyMedeierLikNyEier`;
        } else if (!new BrukerRegler(this.props.brukerprofil).erForhandlerSisteEier(this.props.kjoretoy) && (aktorType === AktorType.NY_EIER || aktorType === AktorType.NY_MEDEIER) && !this.erJuridiskeEnhet(kunde)) {
            return `${EIERSKIFTE_VALIDERING_PREFIX}.${aktorType}_ER_IKKE_JURIDISKENHET`;
        }
    };

    private isEditable = (aktor: IAktor): boolean => {
        if (this.props.lesemodus) {
            return false;
        }

        const forhandlerErEier = new BrukerRegler(this.props.brukerprofil).erForhandlerEier(this.props.registrering.eier);
        if (forhandlerErEier) {
            return (
                aktor.aktorType === AktorType.NY_EIER ||
                aktor.aktorType === AktorType.NY_MEDEIER ||
                (aktor.aktorType === AktorType.NY_UNDERENHET && this.isNyEierOrgNummerForskjelligFraHovedEnhetOrgNummer())
            );
        } else {
            return aktor.aktorType === AktorType.NY_MEDEIER || (aktor.aktorType === AktorType.NY_UNDERENHET && this.isUnderenhetEditable(aktor));
        }
    };

    private gyldigUnderenhet = (kunde: IKunde, juridiskEier: string) => kunde.juridiskEnhetNummer === juridiskEier && kunde.organisasjonsnummer !== juridiskEier;
    private erJuridiskeEnhet = (kunde: IKunde) => kunde.juridiskEnhetNummer === kunde.organisasjonsnummer;
    private isNyEierOrgNummerForskjelligFraHovedEnhetOrgNummer = () => {
        const nyEier = _.find(this.props.aktorer, { aktorType: AktorType.NY_EIER });
        return nyEier.organisasjonsnummer !== this.props.brukerprofil.valgtEnhet.hovedenhetOrgNummer;
    };

    private isUnderenhetEditable = (aktor: IAktorPanelAktor) => {
        return this.isNyEierOrgNummerForskjelligFraHovedEnhetOrgNummer() || !aktor.navn;
    };

    private skalViseKontaktinfoForAktor = (aktor: IAktorPanelAktor): boolean => {
        return this.erAktorIkkePersonOgIkkeUnderenhet(aktor) || this.erAktorPersonSomKanViseKontaktinfo(aktor);
    };

    private erAktorPersonSomKanViseKontaktinfo(aktor: IAktorPanelAktor) {
        return aktor.erPerson && (erAktorMyndig(aktor) || (EierskifteRegler.erLettKjoretoy(this.props.kjoretoy) && aktor.godkjenningUtfort === GodkjenningUtfortType.IKKE_SIGNERT));
    }

    private erAktorIkkePersonOgIkkeUnderenhet(aktor: IAktorPanelAktor) {
        return !aktor.erPerson && !erUnderenhet(aktor);
    }
}

const mapStateToProps = (state: RootStateType): IEierskifteAktorerStateProps => ({
    registrering: state.kjoretoy.registrering,
    kunder: state.kunder,
    brukerprofil: state.brukerprofil,
    kjoretoy: kjoretoySelector(state)
});

const mapDispatchToProps = (dispatch): IEierskifteAktorerDispatchProps => ({
    getKunde: (quary: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktorType?: AktorType, kuid?: string) => dispatch(getKunde(quary, aktorType, kuid)),
    getKundeMedForhandlerInfo: (quary: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktorType?: AktorType, kuid?: string) => dispatch(getKundeMedForhandlerInfo(quary, aktorType, kuid))
});

const EierskifteAktorerConnected = connect(mapStateToProps, mapDispatchToProps)(EierskifteAktorer);

export { EierskifteAktorerConnected, EierskifteAktorer };
