import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import type { IMessageDescriptor} from 'svv-tk-akr-common-frontend';
import { AkMeldingboks, AkMeldingboksType, KjennemerkeTypeEnum } from 'svv-tk-akr-common-frontend';
import { Feilkoder } from '../../constants';
import { GodkjenningUtfortType } from 'svv-tk-akr-common-frontend';

import { AvsluttSakIndikator, RegelmerknaderKoder, RegelmerknaderType, Saksstatus } from '../../models/kodeverk';
import type { Brukerprofil, IAktor, IKjennemerkeserierOgTyper, IKjoretoy, IKunder, IMerknad, IObligatoriskDokument, IRegistreringInformasjon, ISak, IVilkarstatus, RootStateType } from '../../models/types';
import { ForstegangsregistreringRegler, KjoretoyRegler, Merknader, RegistreringRegler } from '../../regler';
import { utledObligatoriskeDokumenter } from '../../utils';

const MERKNADKODE_SOM_IKKE_SKAL_VISES = [RegelmerknaderKoder.KJOPER_IKKE_MYNDIG];

interface IForstegangsregistreringMeldingerProps {
    submitted: boolean;
    submittedType?: AvsluttSakIndikator;
    saksinformasjon: Partial<IRegistreringInformasjon>;
    validationErrors: string[];
    kjennemerkeSerierOgTyper?: IKjennemerkeserierOgTyper;
}

interface IForstegangsregistreringMeldingerStateProps {
    sak?: ISak<IRegistreringInformasjon>;
    kunder?: IKunder;
    kjoretoy?: IKjoretoy;
    vilkarstatus?: IVilkarstatus;
    saksstatus?: Saksstatus;
    gyldigeForhandlereSvalbard?: string[];
    brukerprofil: Brukerprofil;
}

type PropsType = IForstegangsregistreringMeldingerProps & IForstegangsregistreringMeldingerStateProps;

/* eslint sonarjs/cognitive-complexity: "warn" */
class ForstegangsregistreringMeldinger extends React.Component<PropsType> {
    private utledKritiskeMeldinger = (): IMessageDescriptor[] => {
        const meldinger = [
            ...this.meldingerFraSaksinformasjonSomHindrerFattVedtak(),
            ...this.kritiskeMeldingerFraVilkar(),
            ...this.aktorHarUgyldigAdresseMerknader(),
            ...this.kritiskeMeldingerFraSak()
        ];

        if (this.props.submitted) {
            meldinger.push(...this.meldingerFromKunder(RegelmerknaderType.KRITISK), ...this.meldingerFromValidationErrors(), ...this.meldingerFromManglendeObligatoriskeDokumenter());
        }

        return _.uniqBy(meldinger, 'id');
    };

    private utledInformasjonsMeldinger = () => {
        const meldinger = [];

        meldinger.push(...this.meldingerFromKunder(RegelmerknaderType.INFORMASJON), ...this.inforMeldingerFraVilkar(), ...this.infoMeldingerFraSak());

        const manglendeTekniskeData = new KjoretoyRegler(this.props.kjoretoy).manglerTekniskeData(this.props.sak.merknader || []);
        if (manglendeTekniskeData.length) {
            meldinger.push({ id: 'generell.manglertekniskedata', values: { felter: manglendeTekniskeData } });
        }

        if (_.includes(this.props.kjennemerkeSerierOgTyper.kjennemerkeTyper, KjennemerkeTypeEnum.VETERAN)) {
            meldinger.push({ id: 'forstegangsregistrering.melding.veteranKjennemerke' });
        }

        if (this.props.kjoretoy.godkjenning && _.includes(this.props.kjoretoy.godkjenning.unntak, 'KUN_UTENFOR_OFFENTLIG_VEI')) {
            meldinger.push({ id: 'forstegangsregistrering.melding.kjoretoyUtenforOffentligVei' });
        }

        if (this.props.sak.erTilGodkjenning) {
            meldinger.push({ id: 'forstegangsregistrering.melding.sendtTilSaksbForGodkjenning' });
        }

        if (this.enAktorMenIkkeBeggeErUmyndig()) {
            meldinger.push(this.utledInformasjonsmeldingForUmyndigKjoper());
        }

        if (this.props.saksinformasjon.nyEier?.godkjenningUtfort == GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA &&
            this.props.saksinformasjon.nyMedeier?.godkjenningUtfort == GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA) {
            meldinger.push({id: 'forstegangsregistrering.melding.ugyldigSignaturForNyEierOgMedeier'});
        }

        if (Merknader.exists(this.props.kjoretoy.merknader, { kode: RegelmerknaderKoder.KJORETOY_BEGRAVELSESBIL, type: RegelmerknaderType.INFORMASJON })) {
            meldinger.push({ id: 'forstegangsregistrering.validering.KJORETOY_BEGRAVELSESBIL' });
        }

        const kjennemerkeserierOgTyperMerknader = this.props.kjennemerkeSerierOgTyper.merknader || [];
        if (Merknader.exists(kjennemerkeserierOgTyperMerknader, { kode: RegelmerknaderKoder.INGEN_DRIVSTOFFSPESIFIKK_KJENNEMERKESERIE_PA_LAGER, type: RegelmerknaderType.INFORMASJON })) {
            meldinger.push({ id: 'forstegangsregistrering.melding.ingenDrivstoffspesifikkeKjennemerkerPaLager' });
        }

        return _.uniqBy(meldinger, 'id');
    };

    private enAktorMenIkkeBeggeErUmyndig = () => {
        return (
            Merknader.exists(this.props.sak.merknader, { kode: RegelmerknaderKoder.KJOPER_IKKE_MYNDIG }) &&
            !Merknader.exists(this.props.sak.merknader, { kode: RegelmerknaderKoder.NY_EIER_OG_NY_MEDEIER_IKKE_MYNDIG })
        );
    };

    private utledInformasjonsmeldingForUmyndigKjoper = () => {
        return ForstegangsregistreringRegler.erLettKjoretoy(this.props.kjoretoy)
            ? { id: 'forstegangsregistrering.melding.umyndigEierMedMuligEgenSignatur' }
            : { id: 'forstegangsregistrering.validering.KJOPER_IKKE_MYNDIG', values: { type: '' } };
    };

    private meldingerFromValidationErrors = () => {
        const meldinger = [];
        if (this.props.sak && this.props.sak.error && !(this.harSakErrorKode(Feilkoder.TEGNKOMBINASJONEN_FINNES_ALLEREDE) || this.harSakErrorKode(Feilkoder.SAK_KAN_IKKE_TREKKES_TILBAKE))) {
            meldinger.push({
                id: 'forstegangsregistrering.feil.vedtak',
                values: {
                    errorCode: _.replace(this.props.sak.error.errorCode, /\./g, '_'),
                    errorId: this.props.sak.error.errorId
                }
            });
        }

        meldinger.push(..._.map(this.props.validationErrors, (err) => ({ id: err })));

        return meldinger;
    };

    private harSakErrorKode = (feilkode: Feilkoder): boolean => this.props.sak.error.errorCode === feilkode.valueOf();

    private meldingerFromKunder = (merknadType: RegelmerknaderType): IMessageDescriptor[] => {
        const { melder, nyEier, nyMedeier = {} as IAktor } = this.props.saksinformasjon;
        const aktorer = _.compact([melder, nyEier, nyMedeier]);

        return _.chain(aktorer)
            .map((aktor: IAktor) => {
                const kunde = _.get(this.props.kunder, aktor.kundeId);
                return kunde && kunde.kundeId ? kunde.merknader.map((merknad: IMerknad) => ({ merknad, aktorType: aktor.aktorType })) : null;
            })
            .flatMap()
            .reject(_.isNil)
            .filter(({ merknad }) => merknad.type === merknadType)
            .filter(({ merknad }) => !_.includes(MERKNADKODE_SOM_IKKE_SKAL_VISES, merknad.kode))
            .map(({ merknad, aktorType }) => ({ id: `forstegangsregistrering.validering.${merknad.kode}`, values: { type: aktorType } }))
            .value();
    };

    private kritiskeMeldingerFraSak = (): IMessageDescriptor[] => {
        const meldinger = [];

        if (this.props.sak.saksstatus === Saksstatus.APEN && this.props.submittedType === AvsluttSakIndikator.IKKE_AVSLUTT) {
            return meldinger;
        }

        if (this.props.sak && this.props.sak.error && !(this.harSakErrorKode(Feilkoder.TEGNKOMBINASJONEN_FINNES_ALLEREDE) || this.harSakErrorKode(Feilkoder.SAK_KAN_IKKE_TREKKES_TILBAKE))) {
            meldinger.push({
                id: 'forstegangsregistrering.feil.vedtak',
                values: {
                    errorCode: _.replace(this.props.sak.error.errorCode, /\./g, '_'),
                    errorId: this.props.sak.error.errorId
                }
            });
        } else if (this.props.sak?.error?.errorCode === Feilkoder.TEGNKOMBINASJONEN_FINNES_ALLEREDE.valueOf()) {
            meldinger.push({ id: 'feilhandtering.kode.akr.register.app.00022' });
        }

        meldinger.push(
            ...this.props.sak.merknader
                .filter((merknad: IMerknad) => merknad.type === RegelmerknaderType.KRITISK)
                .map((merknad: IMerknad) => ({ id: `forstegangsregistrering.validering.${merknad.kode}`, values: { type: '' } }))
        );

        return meldinger;
    };

    private kritiskeMeldingerFraVilkar = (): IMessageDescriptor[] => {
        return [
            ...this.props.vilkarstatus.merknader
                .filter((merknad: IMerknad) => ForstegangsregistreringRegler.meldingerSomSkalVisesUavhengigAvFattVedtak(merknad))
                .map((merknad: IMerknad) => ({ id: `forstegangsregistrering.validering.${merknad.kode}` }))
        ];
    };

    private infoMeldingerFraSak = (): IMessageDescriptor[] => {
        const meldinger = [];

        meldinger.push(
            ...this.props.sak.merknader
                .filter((merknad: IMerknad) => ForstegangsregistreringRegler.infomerknadSomSkalVisesSomMelding(merknad))
                .map((merknad: IMerknad) => ({ id: `forstegangsregistrering.validering.${merknad.kode}`, values: { type: '' } }))
        );

        return meldinger;
    };

    private inforMeldingerFraVilkar = (): IMessageDescriptor[] => {
        return [
            ...this.props.vilkarstatus.merknader
                .filter((merknad: IMerknad) => ForstegangsregistreringRegler.infomerknadSomSkalVisesSomMeldingFraVilkarstatusMerknader(merknad))
                .map((merknad: IMerknad) => ({ id: `forstegangsregistrering.validering.${merknad.kode}` }))
        ];
    };

    private meldingerFromManglendeObligatoriskeDokumenter = (): IMessageDescriptor[] => {
        if (this.props.submitted && !!this.props.validationErrors.length) {
            return utledObligatoriskeDokumenter(this.props.sak.merknader)
                .filter((dokument: IObligatoriskDokument) => !dokument.innfridd)
                .filter((dokument: IObligatoriskDokument) => !_.includes(MERKNADKODE_SOM_IKKE_SKAL_VISES, dokument.gjelderForMerknadskode))
                .map((dokument: IObligatoriskDokument) => ({ id: `forstegangsregistrering.validering.${dokument.gjelderForMerknadskode}`, values: { type: '' } }));
        }

        return [];
    };

    private meldingerFraSaksinformasjonSomHindrerFattVedtak = (): IMessageDescriptor[] =>
        ForstegangsregistreringRegler.utledKritiskeMeldingerMaFullforesPaTrafikkstasjon(
            this.props.saksinformasjon,
            this.props.gyldigeForhandlereSvalbard,
            this.props.brukerprofil,
            this.props.kjoretoy
        );

    private aktorHarUgyldigAdresseMerknader = (): IMessageDescriptor[] => RegistreringRegler.utledMeldingerUgyldigAdresse(this.props.saksinformasjon);

    public render(): React.ReactElement {
        const kritiskeMeldinger = this.utledKritiskeMeldinger();
        const informasjonsMeldinger = this.utledInformasjonsMeldinger();
        if ((!kritiskeMeldinger.length && !informasjonsMeldinger.length) || Saksstatus.AVSLUTTET === this.props.saksstatus || this.props.sak.isLoadingModalOpen) {
            return null;
        }

        return (
            <div className="col-12 mb-4">
                {!!kritiskeMeldinger.length && <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mb-1" messageKey={kritiskeMeldinger} />}
                {!!informasjonsMeldinger.length && <AkMeldingboks meldingType={AkMeldingboksType.INFO} messageKey={informasjonsMeldinger} />}
            </div>
        );
    }
}

const mapStateToProps = (state: RootStateType): IForstegangsregistreringMeldingerStateProps => ({
    sak: state.sak,
    saksstatus: state.sak.saksstatus,
    kjoretoy: state.kjoretoy,
    vilkarstatus: state.vilkarstatus,
    kunder: state.kunder,
    gyldigeForhandlereSvalbard: state.globals.klientKonfigurasjon.orgNrForhandlereSomKanHandtereSvalbard,
    brukerprofil: state.brukerprofil
});

const ForstegangsregistreringMeldingerConnected = connect(mapStateToProps)(ForstegangsregistreringMeldinger);

export { ForstegangsregistreringMeldingerConnected, ForstegangsregistreringMeldinger };
