import { ForhandlereActionKey } from '../../constants/action-keys';
import type { IForhandlereResponse } from '../../models/types';
import type { ForhandlerActionType } from '../actions';

const initialState: IForhandlereResponse = {
    forhandlere: [],
    isLoading: false,
    error: null
};

export function forhandlere(state: IForhandlereResponse = initialState, action: ForhandlerActionType): IForhandlereResponse {
    switch (action.type) {
        case ForhandlereActionKey.FORHANDLER_LOADING:
            return {...state, isLoading: action.isLoading};
        case ForhandlereActionKey.FORHANDLER_FETCH_DATA_SUCCESS:
            return {...state, forhandlere: [...action.forhandlere], isLoading: false};
        default:
            return state;
    }
}
