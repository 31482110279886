import type { ThunkAction } from 'redux-thunk';
import type { IMerknad } from 'svv-tk-akr-common-frontend';

import { AkrConfig, EierskifterActionKey } from '../../constants';
import { MeldingType, SaksType } from '../../models/kodeverk';
import type { IAktor, IEierskifte, IError, ISak, ISaksinformasjon, NavigateType, RootStateType, ThunkResult } from '../../models/types';
import { inneholderIkkeKritiskMerknad } from '../../utils';
import { createRestResource } from '../store';
import { arbeidsListeNySak } from './arbeidsliste';
import { addGlobalErrors } from './globals';
import { kjoretoyListAddItemLocallyAction } from './kjoretoy';
import { nyMelding, visTekniskFeil } from './melding-bar';
import type { SakActionType} from './sak';
import { sakLoadingModalAction, sakRequestSuccessAction } from './sak';

export type EierskifterActionType =
    IEierskifterLoadingAction
    | IEierskifterGetLoadingAction
    | IEierskifterNotFoundAction
    | IEierskifterGetNotFoundAction
    | IEierskifterHasErrorAction
    | IEierskifterGetHasErrorAction
    | IEierskifterGetDataSuccessAction
    | IEierskifterCreateSuccessAction
    | IEierskifterUpdateLoadingAction
    | IEierskifterUpdateSuccessAction
    | IEierskifterGodkjennSuccessAction
    | IEierskifterAvvisSuccessAction
    | IEierskifterAvbrytSuccessAction
    | IEierskifterOtherAction;

export interface IEierskifterLoadingAction {
    type: EierskifterActionKey.EIERSKIFTER_LOADING;
    isLoading: boolean;
}

export interface IEierskifterGetLoadingAction {
    type: EierskifterActionKey.EIERSKIFTER_GET_LOADING;
    isLoading: boolean;
}

export interface IEierskifterUpdateLoadingAction {
    type: EierskifterActionKey.EIERSKIFTER_UPDATE_LOADING;
    isLoading: boolean;
}

export interface IEierskifterNotFoundAction {
    type: EierskifterActionKey.EIERSKIFTER_GET_NOT_FOUND;
}

export interface IEierskifterGetNotFoundAction {
    type: EierskifterActionKey.EIERSKIFTER_GET_NOT_FOUND;
}

export interface IEierskifterHasErrorAction {
    type: EierskifterActionKey.EIERSKIFTER_HAS_ERROR;
    error: IError;
}

export interface IEierskifterGetHasErrorAction {
    type: EierskifterActionKey.EIERSKIFTER_GET_HAS_ERROR;
    error: IError;
}

export interface IEierskifterGetDataSuccessAction {
    type: EierskifterActionKey.EIERSKIFTER_GET_DATA_SUCCESS;
    eierskifte: IEierskifte;
}

export interface IEierskifterCreateSuccessAction {
    type: EierskifterActionKey.EIERSKIFTER_CREATE_SUCCESS;
    eierskifte: IEierskifte;
}

interface IEierskifterUpdateSuccessAction {
    type: EierskifterActionKey.EIERSKIFTER_UPDATE_SUCCESS;
    eierskifte: IEierskifte;
}

export interface IEierskifterGodkjennSuccessAction {
    type: EierskifterActionKey.EIERSKIFTER_GODKJENN_SUCCESS;
    eierskifte: IEierskifte;
}

export interface IEierskifterAvvisSuccessAction {
    type: EierskifterActionKey.EIERSKIFTER_AVVIS_SUCCESS;
    sakId: string;
}

export interface IEierskifterAvbrytSuccessAction {
    type: EierskifterActionKey.EIERSKIFTER_AVBRYT_SUCCESS;
    sakId: string;
}

export interface IEierskifterOtherAction {
    type: EierskifterActionKey.OTHER_ACTION;
}

const eierskifterIsLoading = (isLoading: boolean): IEierskifterLoadingAction => ({type: EierskifterActionKey.EIERSKIFTER_LOADING, isLoading});
const eierskifterGetIsLoading = (isLoading: boolean): IEierskifterGetLoadingAction => ({type: EierskifterActionKey.EIERSKIFTER_GET_LOADING, isLoading});
const eierskifterUpdateIsLoading = (isLoading: boolean): IEierskifterUpdateLoadingAction => ({type: EierskifterActionKey.EIERSKIFTER_UPDATE_LOADING, isLoading});
const eierskifterHasError = (error: any): IEierskifterHasErrorAction => ({type: EierskifterActionKey.EIERSKIFTER_HAS_ERROR, error});
const eierskifterGetHasError = (error: any): IEierskifterGetHasErrorAction => ({type: EierskifterActionKey.EIERSKIFTER_GET_HAS_ERROR, error});
const eierskifteGodkjennSuccess = (eierskifte: IEierskifte): IEierskifterGodkjennSuccessAction => ({type: EierskifterActionKey.EIERSKIFTER_GODKJENN_SUCCESS, eierskifte});
const eierskifteAvvisSuccess = (sakId: string): IEierskifterAvvisSuccessAction => ({type: EierskifterActionKey.EIERSKIFTER_AVVIS_SUCCESS, sakId});
const eierskifteAvbrytSuccess = (sakId: string): IEierskifterAvbrytSuccessAction => ({type: EierskifterActionKey.EIERSKIFTER_AVBRYT_SUCCESS, sakId});

const eierskifterGetDataSuccess = (eierskifte: IEierskifte): IEierskifterGetDataSuccessAction => ({
    type: EierskifterActionKey.EIERSKIFTER_GET_DATA_SUCCESS,
    eierskifte
});

const eierskifteGetHasError = (error: any): IEierskifterGetHasErrorAction => ({type: EierskifterActionKey.EIERSKIFTER_GET_HAS_ERROR, error});

const eierskifteCreateSuccess = (eierskifte: IEierskifte): IEierskifterCreateSuccessAction => ({
    type: EierskifterActionKey.EIERSKIFTER_CREATE_SUCCESS,
    eierskifte
});

export const eierskifteUpdateSuccess = (eierskifte: IEierskifte): IEierskifterUpdateSuccessAction => ({
    type: EierskifterActionKey.EIERSKIFTER_UPDATE_SUCCESS,
    eierskifte
});

const api = createRestResource(AkrConfig.EIERSKIFTER_RESOURCE_URL);

export function getEierskifte(saksId: string): ThunkResult<Promise<IEierskifte | IEierskifterGetLoadingAction>, EierskifterActionType> {
    return dispatch => {
        dispatch(eierskifterGetIsLoading(true));
        return api.get(saksId)
            .then(response => {
                if (response.errorCode) {
                    dispatch(eierskifterGetHasError(true));
                    dispatch(addGlobalErrors(response));
                } else {
                    dispatch(eierskifterGetDataSuccess(response));
                }
                return dispatch(eierskifterGetIsLoading(false));
            });
    };
}

export function endreEierskifte(eierskifte: IEierskifte): ThunkResult<Promise<IEierskifte | EierskifterActionType>, EierskifterActionType | SakActionType> {
    return dispatch => {
        dispatch(sakLoadingModalAction(true));
        dispatch(eierskifterUpdateIsLoading(true));
        return api.put(eierskifte, eierskifte.sakId)
            .then(response => {
                if (response.errorCode) {
                    dispatch(eierskifteUpdateError(response));
                } else {
                    dispatch(eierskifteUpdateSuccess(response));
                }
                dispatch(sakLoadingModalAction(false));
                dispatch(eierskifterIsLoading(false));
                return response;
            })
            .catch(() => {
                dispatch(eierskifterIsLoading(false));
                return dispatch(eierskifterHasError(true));
            });
    };
}

function eierskifteUpdateError(error: IError) {
    return dispatch => {
            dispatch(addGlobalErrors(error));
    };
}

export function opprettEierskifte(navigate: NavigateType, eierskifte: IEierskifte): ThunkResult<void, EierskifterActionType | SakActionType> {
    return dispatch => {
        dispatch(sakLoadingModalAction(true));
        dispatch(eierskifterGetIsLoading(true));
        return api.post(eierskifte)
            .then(response => {
                if (response.errorCode) {
                    dispatch(eierskifteGetHasError(response));
                    dispatch(sakLoadingModalAction(false));
                } else {
                    const merknader: IMerknad[] = response.merknader ? response.merknader.slice() : [];
                    dispatch(eierskifteCreateSuccess({...response, merknader}));

                    if (response.sakId && inneholderIkkeKritiskMerknad(merknader)) {
                        dispatch(arbeidsListeNySak(navigate, [response.sakId], SaksType.EIERSKIFTE));
                    } else if (response.sakId) {
                        navigate(`/kjoretoy/${eierskifte.kuid}/eierskifte/${response.sakId}`, { replace: true });
                    }
                    dispatch(sakLoadingModalAction(false));
                }
            });
    };
}

export function opprettEierskifteBulkAction(eierskifte: IEierskifte): ThunkResult<void, EierskifterActionType | SakActionType> {
    return () => {
        return api.post(eierskifte)
            .then(response => {
                if (response.errorCode || !response.sakId) {
                    return Promise.reject(eierskifte.kjennemerke);
                } else {
                    return Promise.resolve(response.sakId);
                }
            });
    };
}

export function godkjennEierskifte(sakId: string, withNotification?: boolean): ThunkResult<void, EierskifterActionType | SakActionType> {
    return dispatch => {
        dispatch(eierskifterGetIsLoading(true));
        return api.get(null, null, `${AkrConfig.EIERSKIFTER_RESOURCE_URL}/${sakId}${AkrConfig.EIERSKIFTER_GODKJENN_RESOURCE_URL}`)
            .then((response: IEierskifte & IError) => {
                if (response.errorId) {
                    dispatch(eierskifterGetIsLoading(false));
                    dispatch(visTekniskFeil(response));
                } else {
                    dispatch(eierskifteGodkjennSuccess(response));
                    if (withNotification) {
                        dispatch(nyMelding({meldingIntlId: 'eierskifte.melding.godkjent.godkjentAvPart', meldingType: MeldingType.SUCCESS, varighetMs: 8000}));
                    }
                }
            });
    };
}

export function avvisEierskifte(sakId: string): ThunkResult<void, EierskifterActionType | SakActionType> {
    return dispatch => {
        return api.get(null, null, `${AkrConfig.EIERSKIFTER_RESOURCE_URL}/${sakId}${AkrConfig.EIERSKIFTER_AVVIS_RESOURCE_URL}`)
            .then((error: IError) => error && error.errorId ? dispatch(visTekniskFeil(error)) : dispatch(eierskifteAvvisSuccess(sakId)));
    };
}

export function avbrytEierskifte(sakId: string): ThunkResult<void, EierskifterActionType | SakActionType> {
    return dispatch => {
        return api.remove(sakId)
            .then((response) =>
                response && response.errorCode ?
                    dispatch(eierskifterHasError(response)) :
                    dispatch(eierskifteAvbrytSuccess(sakId)));
    };
}

export function resetEierskifte(): ThunkResult<void, EierskifterActionType | SakActionType> {
    return dispatch => {
        dispatch(eierskifteUpdateSuccess(null));
        return Promise.resolve();
    };
}

export const endreEierskifteTilGodkjenningHosSaksbehandler = (sak: ISak<ISaksinformasjon>):
    ThunkAction<void, RootStateType, undefined, EierskifterActionType | SakActionType> => dispatch => {
    dispatch(eierskifterIsLoading(true));
    const queryParams = {erTilGodkjenning: !sak.erTilGodkjenning};
    return api.put(null, null, queryParams, `${AkrConfig.EIERSKIFTER_RESOURCE_URL}/${sak.sakId}${AkrConfig.ENDRE_ER_TIL_GODKJENNING_URL}`)
        .then(response => {
            if (response.error) {
                dispatch(eierskifterHasError(response));
            }
            dispatch(sakRequestSuccessAction(response));
            dispatch(eierskifterIsLoading(false));
        });
};

export function initierEierskifteForFlereKjoretoy(aktorer: IAktor[]): ThunkResult<void, EierskifterActionType> {
    return dispatch => {
        dispatch(kjoretoyListAddItemLocallyAction());
        return dispatch(eierskifteUpdateSuccess({ aktorer }));
    };
}
