export class LocalStorage {

    public static getItem(key: string): string {
        if (this.doesBrowserSupportLocalStorage()) {
            return window.localStorage[key];
        } else {
            return this.getCookie(key).value;
        }
    }

    public static setItem(key: string, value: string): void {
        if (this.doesBrowserSupportLocalStorage()) {
            window.localStorage[key] = value;
        } else {
            return this.setCookie(key, value);
        }
    }

    public static getArrayItem(key: string): any[] {
        const array = this.getItem(key);
        if (array != null) {
            return JSON.parse(array);
        }
        return [];
    }

    public static setArrayItem(key: string, value: string[]): void {
        this.setItem(key, JSON.stringify(value));
    }

    private static doesBrowserSupportLocalStorage() {
        const test = 'testStorage';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }

    private static getCookie(key: string): IItem {
        const cookies = LocalStorage.getCookies();
        const filtered = cookies.filter(cookie => key === cookie.key);
        return filtered.pop() || {} as IItem;
    }

    private static setCookie(key: string, value: string) {
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        const yearInMillis = 365 * 24 * 60 * 60 * 1000;
        /* eslint-enable @typescript-eslint/no-magic-numbers */
        const expires = this.createExpires(yearInMillis);
        document.cookie = `${key}=${value}; ${expires}; path=/;`;
    }

    private static getCookies(): IItem[] {
        const decodedCookie = decodeURIComponent(document.cookie);
        return decodedCookie.split('; ')
            .map(cookieString => {
                const parts = cookieString.split('=');
                return {key: parts[0], value: parts[1]};
            });
    }

    private static createExpires(milliseconds: number) {
        const d = new Date();
        d.setTime(d.getTime() + milliseconds);
        return `expires=${d.toUTCString()}`;
    }
}

interface IItem {
    key: string;
    value: string;
}
