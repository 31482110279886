import type { ReactNode } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';

import type { IArbeidslisteRad, RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';
import { kundenavnSelector } from '../../state/selectors';

interface IEierLeasingtakerNavnProps {
    rad?: IArbeidslisteRad;
    isForstegangreg?: boolean;
    isFlateeier?: boolean;
    leasingtakerNavn?: string;
}

const EierLeasingtakerNavnCompnent: React.FC<IEierLeasingtakerNavnProps> = props => {
    if (props.isForstegangreg && props.isFlateeier) {
        return (
            <p>{props.leasingtakerNavn}</p>
        );
    }

    const eierMapper = (eier: string, index: number): ReactNode => (
        <li key={index}>
            <p>{eier}</p>
        </li>
    );

    return (
        <ul className="ak-liste ak-liste-kompakt">
            { props.rad.eiere.map(eierMapper) }
        </ul>
    );
};

const mapStateToProps = (state: RootStateType, ownProps: IEierLeasingtakerNavnProps): IEierLeasingtakerNavnProps => ({
    leasingtakerNavn: kundenavnSelector(state)(ownProps.rad.leasingtakerKundeId),
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier()
});

const EierLeasingtakerNavn = connect(mapStateToProps)(EierLeasingtakerNavnCompnent);

export { EierLeasingtakerNavn, EierLeasingtakerNavnCompnent };
