import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, matchPath } from 'react-router-dom';
import type { ThunkDispatch } from 'redux-thunk';

import type { IError, RootStateType } from '../../models/types';
import type { GlobalErrorActionType} from '../../state/actions';
import { resetGlobalErrors } from '../../state/actions';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';

const tekniskFeilSvg = require('../../styles/images/teknisk-feil.svg');
const ikkeFunnet = require('../../styles/images/ikke-funnet.svg');

interface IInternFeilStateProps {
    errors: IError[];
    globalState: RootStateType;
    updateParent?: () => void;
    intlMessages: Record<string, any>;
}

interface IInternFeilProps {
    updateParent?: any;
    goto?: string;
}

interface IInternFeilDispatchProps {
    resetGlobalErrors: () => void;
}

interface IInternFeilState {
    error: IError;
    headerKey: string;
    bodyKeys: string[];
    img: any;
    errorType: any;
}

type PropsType = IInternFeilStateProps & IInternFeilProps & IInternFeilDispatchProps & WithRouterProps;

const ERROR_CODE_IKKE_TILGJENGELIG = 'akr.forhandler.app.00007';

class InternFeil extends React.Component<PropsType, IInternFeilState> {

    /* eslint-disable */
    public state: IInternFeilState = {
        error: {
            errorId: null,
            errorCode: 'ingen.kode'
        } as IError,
        headerKey: 'generell',
        bodyKeys: ['feilhandtering.kode.generell'],
        img: tekniskFeilSvg,
        errorType: null
    };

    public constructor(props: PropsType) {
        super(props);
        if (!(props.errors || []).length && matchPath( { path: '/ikkefunnet' }, props.location.pathname)) {
            this.state.error.errorCode = 'ikke.funnet';
        } else if (!(props.errors || []).length && props.params.type) {
            const type = this.props.params.type;
            this.state.errorType = props.params.type;
            const errorObj = (this.props.globalState[type] || {}).error || this.state.error;
            this.state.error = errorObj;
        } else if ((props.errors || []).length) {
            let error = props.errors[0];
            while (error && error.errorResponseCause) {
                error = error.errorResponseCause;
            }
            this.state.error = error;
        }
    }

    private errorData = {
        'for.mange.kjoretoy': { headerKey: 'for.mange.kjoretoy', bodyKeys: ['feilhandtering.kode.for.mange.kjoretoy'], img: null, link: 'generell' },
        'ingen.kode': { headerKey: 'generell', bodyKeys: ['feilhandtering.kode.ingen.kode'], img: tekniskFeilSvg, link: 'generell' },
        'ikke.funnet': { headerKey: 'ikke.funnet', bodyKeys: ['feilhandtering.kode.ikke.funnet'], img: null, link: 'generell' },
        'ukjent': { headerKey: 'generell', bodyKeys: ['feilhandtering.kode.ukjent.kode'], img: tekniskFeilSvg, link: 'generell' },
        'akr.forhandler.app.00007': {
            headerKey: ERROR_CODE_IKKE_TILGJENGELIG,
            bodyKeys: [`feilhandtering.kode.${ERROR_CODE_IKKE_TILGJENGELIG}`],
            img: ikkeFunnet,
            link: 'generell'
        }
    };

    public componentWillUnmount(): void {
        this.props.resetGlobalErrors();
        this.props.updateParent();
    }

    public render(): React.ReactNode {

        const errorData = this.getErrorData();
        const { errorId } = this.state.error;

        return (
            <div className="container py-4">
                <div className="row">
                    <div className="col-md-7 col-sm-12 pb-5">
                        <h1><FormattedMessage id={`feilhandtering.${errorData.headerKey}.overskrift`} /></h1>
                        {
                            errorData.bodyKeys.length && errorData.bodyKeys.map((key) => (
                                <p key={key}>
                                    <FormattedMessage id={key}
                                        values={{ errorId: errorId || <span className="text-nowrap">-ukjent-</span> }} />
                                </p>
                            ))
                        }

                        <Link className="ak-knapp ak-hovedknapp mt-3" role="button" to={this.props.goto || '/'}>
                            <FormattedMessage id={`feilhandtering.${errorData.link}.link.gaatil`} />
                        </Link>
                    </div>
                    <div className="col-md-5 d-sm-none d-md-flex row justify-content-center">
                        {errorData.img ? <img className="ml-4 h-100" src={errorData.img} alt="feilbilde" /> : <i className="ak-ikon-ikke-tilgjengelig" />}
                    </div>
                </div>
            </div>
        );
    }

    private getErrorData = () => {
        const errorKey = `feilhandtering.kode.${this.state.error.errorCode}`;

        if (this.state.error.errorCode && this.props.intlMessages[errorKey]) {
            return this.errorData[this.state.error.errorCode] || { ..._.get(this.errorData, 'ukjent'), bodyKeys: [errorKey] };
        }

        if (!this.state.error.errorCode) {
            return this.errorData['ingen.kode'];
        }

        return _.get(this.errorData, 'ukjent');
    };
}

const mapStateToProps = (state: RootStateType): IInternFeilStateProps => ({
    errors: state.globals.globalErrors,
    globalState: state,
    intlMessages: state.intl.messages
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootStateType, undefined, GlobalErrorActionType>): IInternFeilDispatchProps => ({
    resetGlobalErrors: () => dispatch(resetGlobalErrors())
});

const InternFeilConnected = compose(
    connect<IInternFeilStateProps, IInternFeilDispatchProps, IInternFeilProps>(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withRouter
)(InternFeil);

export { InternFeilConnected, InternFeil };
