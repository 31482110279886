import { formatStoreBokstaverOgUtenWhitespace } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { AkrConfig, KjoretoyActionKey, MidlertidigVognkortActionKey } from '../../constants';
import type { RegistreringsstatusKode } from '../../models/kodeverk';
import { ArbeidslisteSakstypefilter, MeldingType } from '../../models/kodeverk';
import type { IError, IKjoretoy, IKjoretoyError, IMidlertidigVognkort, NavigateType, RootStateType, ThunkResult } from '../../models/types';
import { BrukerRegler } from '../../regler';
import { openFile } from '../../utils';
import type { TypedDispatch } from '../store';
import { createRestResource } from '../store';
import type { IArbeidlisteHentRegistreringsstatus} from './arbeidsliste';
import { registreringsstatusHentet } from './arbeidsliste';
import type { GlobalErrorActionType } from './globals';
import { addGlobalErrors } from './globals';
import { nyMelding, visTekniskFeil } from './melding-bar';
import type { IRegistreringerClearStateAction} from './registreringer';
import { registreringSakClearState } from './registreringer';
import { isError } from './registreringer-arkivdokumenter';

const api = createRestResource(AkrConfig.KJORETOY_RESOURCE_URL);

export type KjoretoyActionType =
    IKjoretoyLoadingAction
    | IKjoretoyNotFoundAction
    | IKjoretoyNotShowAction
    | IKjoretoyHasErrorAction
    | IKjoretoyFetchDataSuccessAction
    | IKjoretoyClearStateAction
    | IKjoretoyOtherAction;

export type KjoretoyListActionType =
    IKjoretoyListIsLoadingAction
    | IKjoretoyListHasErrorAction
    | IKjoretoyListClearAction
    | IKjoretoyListClearFailedAction
    | IKjoretoyListAddAction
    | IKjoretoyListRemoveAction
    | IKjoretoyOtherAction;

export type MidlertidigVognkortActionType =
    IGetMidlertidigVognkortLoadingAction
    | IGetMidlertidigVognkortSuccessAction
    | IGetMidlertidigVognkortHasErrorAction
    | IGetMidlertidigVognkortResetAction;

export interface IKjoretoyListClearAction {
    type: KjoretoyActionKey.KJORETOY_LIST_CLEAR;
}

export interface IKjoretoyListClearFailedAction {
    type: KjoretoyActionKey.KJORETOY_LIST_CLEAR_FAILED;
}

export interface IKjoretoyListHasErrorAction {
    type: KjoretoyActionKey.KJORETOY_LIST_HAS_ERROR;
    error: IKjoretoyError;
}

export interface IKjoretoyListRemoveAction {
    type: KjoretoyActionKey.KJORETOY_LIST_REMOVE;
    kjennemerke: string;
    kjennemerkeIRegistrering?: boolean;
}

export interface IKjoretoyListAddAction {
    type: KjoretoyActionKey.KJORETOY_LIST_ADD;
    response: IKjoretoy;
}

export interface IKjoretoyListIsLoadingAction {
    type: KjoretoyActionKey.KJORETOY_LIST_IS_LOADING;
    isLoading: boolean;
}

export interface IKjoretoyLoadingAction {
    type: KjoretoyActionKey.KJORETOY_LOADING;
    isLoading: boolean;
}

export interface IKjoretoyNotFoundAction {
    type: KjoretoyActionKey.KJORETOY_NOT_FOUND;
}

export interface IKjoretoyNotShowAction {
    type: KjoretoyActionKey.KJORETOY_NOT_SHOW;
    kjoretoy: IKjoretoy;
}

export interface IKjoretoyHasErrorAction {
    type: KjoretoyActionKey.KJORETOY_HAS_ERROR;
    error: any;
}

export interface IKjoretoyFetchDataSuccessAction {
    type: KjoretoyActionKey.KJORETOY_FETCH_DATA_SUCCESS;
    kjoretoy: IKjoretoy;
}

export interface IKjoretoyClearStateAction {
    type: KjoretoyActionKey.KJORETOY_CLEAR_STATE;
}

export interface IKjoretoyOtherAction {
    type: KjoretoyActionKey.OTHER_ACTION;
}

export interface IGetMidlertidigVognkortLoadingAction {
    type: MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_LOADING;
    isLoading: boolean;
}

export interface IGetMidlertidigVognkortSuccessAction {
    type: MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_SUCCESS;
    vognkort: IMidlertidigVognkort;
}

export interface IGetMidlertidigVognkortHasErrorAction {
    type: MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_ERROR;
    error: IError;
}

export interface IGetMidlertidigVognkortResetAction {
    type: MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_RESET;
}

export const kjoretoyIsLoadingAction = (isLoading: boolean): IKjoretoyLoadingAction => ({type: KjoretoyActionKey.KJORETOY_LOADING, isLoading});
export const kjoretoyNotFoundAction = (): IKjoretoyNotFoundAction => ({type: KjoretoyActionKey.KJORETOY_NOT_FOUND});
export const kjoretoyNotShowAction = (kjoretoy: IKjoretoy): IKjoretoyNotShowAction => ({type: KjoretoyActionKey.KJORETOY_NOT_SHOW, kjoretoy});
export const kjoretoyHasErrorAction = (error: IError | string): IKjoretoyHasErrorAction => ({type: KjoretoyActionKey.KJORETOY_HAS_ERROR, error});
export const kjoretoyFetchDataSuccessAction = (kjoretoy: IKjoretoy): IKjoretoyFetchDataSuccessAction => ({type: KjoretoyActionKey.KJORETOY_FETCH_DATA_SUCCESS, kjoretoy});
export const kjoretoyClearStateAction = (): IKjoretoyClearStateAction => ({type: KjoretoyActionKey.KJORETOY_CLEAR_STATE});

export const kjoretoyListAddItemAction = (response: IKjoretoy): IKjoretoyListAddAction => ({type: KjoretoyActionKey.KJORETOY_LIST_ADD, response});
export const kjoretoyListHasErrorAction = (error: IKjoretoyError): IKjoretoyListHasErrorAction => ({type: KjoretoyActionKey.KJORETOY_LIST_HAS_ERROR, error});
export const kjoretoyListRemoveItemAction = (kjennemerke: string, kjennemerkeIRegistrering?: boolean): IKjoretoyListRemoveAction => ({
    type: KjoretoyActionKey.KJORETOY_LIST_REMOVE,
    kjennemerke,
    kjennemerkeIRegistrering
});
export const kjoretoyListIsLoadingAction = (isLoading: boolean): IKjoretoyListIsLoadingAction => ({type: KjoretoyActionKey.KJORETOY_LIST_IS_LOADING, isLoading});
export const kjoretoyListClearAction = (): IKjoretoyListClearAction => ({type: KjoretoyActionKey.KJORETOY_LIST_CLEAR});
export const kjoretoyListClearFailedAction = (): IKjoretoyListClearFailedAction => ({type: KjoretoyActionKey.KJORETOY_LIST_CLEAR_FAILED});

export const midlertidigVognkortIsLoadingAction = (isLoading: boolean): IGetMidlertidigVognkortLoadingAction => ({type: MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_LOADING, isLoading});
export const midlertidigVognkortHasErrorAction = (error: IError): IGetMidlertidigVognkortHasErrorAction => ({type: MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_ERROR, error});

export function kjoretoyGetDataFromKuidAction(kuid: string): ThunkAction<void, RootStateType, undefined, KjoretoyActionType | GlobalErrorActionType> {
    return (dispatch, getState) => {
        dispatch(kjoretoyIsLoadingAction(true));

        if (!(BrukerRegler.adapter(getState()).harRolleForSok() || BrukerRegler.adapter(getState()).harRolleFlateeier())) {
            dispatch(kjoretoyIsLoadingAction(false));
            return dispatch(addGlobalErrors({errorCode: 'akr.common.app.00013'}));
        }

        return handleKjoretoyResponse(
            api.get(null, null, `${AkrConfig.KJORETOY_RESOURCE_URL}/${kuid}`),
            dispatch
        );
    };
}

export function kjoretoyGetDataAction(q: string): ThunkResult<Promise<HandleKjoretoyResponseActionType>, KjoretoyActionType> {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(kjoretoyIsLoadingAction(true));
            if (!BrukerRegler.adapter(getState()).harRolleForSok()) {
                dispatch(kjoretoyIsLoadingAction(false));
                dispatch(addGlobalErrors({errorCode: 'akr.common.app.00013'}));
                return reject();
            }

            return resolve(
                handleKjoretoyResponse(
                    api.get(null, {q}),
                    dispatch
                )
            );
        });
    };
}

type HandleKjoretoyResponseActionType = IKjoretoyFetchDataSuccessAction | IKjoretoyNotFoundAction | IKjoretoyNotShowAction | GlobalErrorActionType | IKjoretoyLoadingAction;

function handleKjoretoyResponse(req: Promise<IKjoretoy>, dispatch: TypedDispatch<RootStateType>):
    Promise<HandleKjoretoyResponseActionType> {
    return req.then(response => {

        if (_.isEmpty(response)) {
            return dispatch(kjoretoyNotFoundAction());
        }

        if (isError(response) && (response.error || response.errorId || !response.kuid)) {
            dispatch(kjoretoyHasErrorAction(response));
            return dispatch(addGlobalErrors(response));
        }

        if (response.ikkeVisKjoretoy) {
            return dispatch(kjoretoyNotShowAction(response));
        }

        return dispatch(kjoretoyFetchDataSuccessAction(response));
    }).then(() => dispatch(kjoretoyIsLoadingAction(false)));
}

export function kjoretoyGetDataAndClearRegSakAction(navigate: NavigateType, q: string): ThunkAction<void, RootStateType, undefined, KjoretoyActionType | IRegistreringerClearStateAction> {
    return (dispatch, getState) => {
        dispatch(registreringSakClearState());
        return dispatch(kjoretoyGetDataAction(q)).then(() => {
            const {kuid} = getState().kjoretoy;
            if (kuid) {
                return navigate(`/kjoretoy/${kuid}`);
            } else {
                const {merknader} = getState().kjoretoy;
                if (!merknader.length) {
                    return null;
                }
                const feilkode = merknader.length && merknader[0].kode.replace(/_/g, '.').toLowerCase();
                const feilbeskjed = merknader.length && merknader[0].kontekst;

                return dispatch(addGlobalErrors({errorCode: feilkode, errorMessage: feilbeskjed}));
            }
        });
    };
}

export function kjoretoyListGetItemAction(id: string): ThunkAction<void | Promise<KjoretoyListActionType> | IKjoretoyListIsLoadingAction, RootStateType, undefined, KjoretoyListActionType> {
    const kjennemerke = (id || '').toUpperCase();
    return (dispatch, getState) => {

        dispatch(kjoretoyListClearFailedAction());
        dispatch(kjoretoyListIsLoadingAction(true));

        if (!BrukerRegler.adapter(getState()).harRolleForSok()) {
            return dispatch(addGlobalErrors({errorCode: 'akr.common.app.00013'}));
        }

        if (shouldGetKjoretoy(getState(), kjennemerke)) {
            return api.fetch({q: kjennemerke, merknader: true})
                .then((responseBody) => {
                    if (_.isEmpty(responseBody)) {
                        return dispatch(kjoretoyListHasErrorAction({...{errorId: 'NOT_FOUND'}, kjennemerke}));
                    }

                    if (responseBody.errorId) {
                        return dispatch(kjoretoyListHasErrorAction({...responseBody, kjennemerke}));
                    }

                    return dispatch(kjoretoyListAddItemAction({kjennemerke, ...responseBody}));

                }).then(() => (dispatch(kjoretoyListIsLoadingAction(false))));
        } else {
            return dispatch(kjoretoyListIsLoadingAction(false));
        }
    };
}

export function kjoretoyListAddItemLocallyAction(): ThunkAction<IKjoretoyListAddAction, RootStateType, undefined, KjoretoyListActionType> {
    return (dispatch, getState) => {
        const kjennemerke = formatStoreBokstaverOgUtenWhitespace(getState().kjoretoy.registrering?.kjennemerke?.tegnkombinasjon);
        dispatch(kjoretoyListClearAction());
        return dispatch(kjoretoyListAddItemAction({ kjennemerke, ...getState().kjoretoy }));
    };
}

export function hentMidlertidigVognkort(kuid: string, arbeidslisteSakId?: string): ThunkAction<void, RootStateType, undefined, MidlertidigVognkortActionType> {
    return dispatch => {
        dispatch(midlertidigVognkortIsLoadingAction(true));
        return api.get({kuid}, null, AkrConfig.KJORETOY_MIDLERTIDIGVOGNKORT_RESOURCE_URL)
            .then((response: IError & IMidlertidigVognkort) => {
                dispatch(midlertidigVognkortIsLoadingAction(false));
                if (response.errorId) {
                    dispatch(midlertidigVognkortHasErrorAction(response));
                    return dispatch(visTekniskFeil(response));
                }
                if (response.merknader && response.merknader.length) {
                    if (arbeidslisteSakId) {
                        dispatch(nyMelding({meldingIntlId: 'kjoretoydetaljer.meldinger.midlertidigVognkortKanIkkeVisesArbeidsliste', meldingType: MeldingType.DANGER}));
                    } else {
                        dispatch(nyMelding({meldingIntlId: 'kjoretoydetaljer.meldinger.midlertidigVognkortKanIkkeVises', meldingType: MeldingType.DANGER}));
                    }
                } else if (!response.midlertidigVognkort) {
                    return dispatch(nyMelding({meldingIntlId: 'kjoretoydetaljer.meldinger.kjoretoyIkkeFunnet', meldingType: MeldingType.DANGER}));
                } else {
                    openFile(response.midlertidigVognkort, 'midlertidigvognkort.pdf');
                }
            })
            .catch(() => dispatch(visTekniskFeil()));
    };
}

function shouldGetKjoretoy(state: RootStateType, kjennemerke: string): boolean {
    return !_.find(state.kjoretoyList.kjoretoy, ['kjennemerke', kjennemerke]);
}

function isRegistreringsstatusHentetAction(arg: void | IArbeidlisteHentRegistreringsstatus): arg is IArbeidlisteHentRegistreringsstatus {
    return (arg as IArbeidlisteHentRegistreringsstatus).registreringsstatusKode !== undefined;
}

export function hentRegistreringsstatus(kuid: string, sakId: string):
    (dispatch: ThunkDispatch<RootStateType, undefined, IArbeidlisteHentRegistreringsstatus>) => Promise<RegistreringsstatusKode | void> {
    return dispatch => api.get({kuid}, null, AkrConfig.KJORETOY_REGISTRERINGSSTATUS_RESOURCE_URL)
        .then((response) => response.errorId ? dispatch(visTekniskFeil(response)) : dispatch(registreringsstatusHentet(response, sakId, ArbeidslisteSakstypefilter.REGISTRERING)))
        .then((action: void | IArbeidlisteHentRegistreringsstatus) => isRegistreringsstatusHentetAction(action) && action.registreringsstatusKode)
        .catch(() => dispatch(visTekniskFeil()));
}
