import type { ISystemvarsler } from 'svv-tk-akr-common-frontend';
import { SystemvarslerActionKey } from '../../constants';
import type { SystemvarslerActionType } from '../actions';

const initialState: ISystemvarsler = {
    systemvarsler: [],
    isLoading: false,
    error: null
};

export function systemvarsler(state: ISystemvarsler = initialState, action: SystemvarslerActionType): ISystemvarsler {
    switch (action.type) {
        case SystemvarslerActionKey.SYSTEMVARSLER_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case SystemvarslerActionKey.SYSTEMVARSLER_FETCH_DATA_SUCCSESS:
            return {...initialState, systemvarsler: action.systemvarsler};
        case SystemvarslerActionKey.SYSTEMVARSLER_FETCH_DATA_ERROR:
            return {...state, isLoading: false, error: action.error};
        default:
            return state;
    }
}
