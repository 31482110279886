import * as _ from 'lodash';

import * as React from 'react';
import type { ReactNode } from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { Avdeling, IAkSelectInputOption } from 'svv-tk-akr-common-frontend';
import { AkDownloadButton as AcfAkDownloadButton, AkKnapp, AkPagination, AkSelectInput, AkTooltip } from 'svv-tk-akr-common-frontend';

import { EnhetType, RegistreringStatusFilterValg, SorteringRetning, SorteringType } from '../../constants';
import type { EgneKjoretoyResponse, RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';
import { hentEgneKjoretoy } from '../../state/actions';
import { EgneKjoretoyConnected } from './egne-kjoretoy';
import { KjoretoylisteFilterPanel } from './kjoretoyliste-filter-panel';

import './kjoretoyliste-style';

interface IKjoretoylisteDispatchProps {
    hentKjoretoy: (avdeling: EnhetType, regStatus: RegistreringStatusFilterValg, isCountOnly: boolean, side: number, pagineringAntall: number) => Promise<void>;
}

interface IKjoretoylisteStateProps {
    kjennemerkeRapportEnabled: boolean;
    egneKjoretoy: EgneKjoretoyResponse;
    isLoading: boolean;
    valgtEnhet: Avdeling;
    isFlateeier: boolean;
}

interface IKjoretoylisteState {
    avdelingFilter: EnhetType;
    regStatusFilter: RegistreringStatusFilterValg;
    pagineringAntall: number;
    side: number;
}

const DEFAULT_PAGINERING_ANT = 10;
const MEDIUM_PAGINERING_ANT = 20;
const STOR_PAGINERING_ANT = 40;

class Kjoretoyliste extends Component<IKjoretoylisteStateProps & IKjoretoylisteDispatchProps, IKjoretoylisteState> {

    public constructor(props: IKjoretoylisteStateProps & IKjoretoylisteDispatchProps) {
        super(props);
        this.state = {
            avdelingFilter: EnhetType.UNDERENHET,
            regStatusFilter: RegistreringStatusFilterValg.ALLE,
            pagineringAntall: DEFAULT_PAGINERING_ANT,
            side: 1
        };
    }

    public componentDidMount(): void {
        if (!this.props.isLoading) {
            this.props.hentKjoretoy(this.state.avdelingFilter, this.state.regStatusFilter, false, this.state.side, this.state.pagineringAntall);
        }
    }

    public componentDidUpdate(prevProps: Readonly<IKjoretoylisteStateProps & IKjoretoylisteDispatchProps>, prevState: Readonly<IKjoretoylisteState>): void {
        if (prevState.avdelingFilter !== this.state.avdelingFilter
            || prevState.regStatusFilter !== this.state.regStatusFilter
            || prevState.pagineringAntall !== this.state.pagineringAntall
            || prevState.side !== this.state.side) {
            this.props.hentKjoretoy(this.state.avdelingFilter, this.state.regStatusFilter, false, this.state.side, this.state.pagineringAntall);
        }
    }

    public render(): ReactNode {
        const { egneKjoretoy } = this.props;

        return (
            <section className="container">
                <header className="row align-items-center">
                    <div className="col-12 col-sm-auto mr-auto">
                        <FormattedMessage id="kjoretoyliste.overskrift" tagName="h1" />
                    </div>
                    {this.props.kjennemerkeRapportEnabled && this.renderRapportKnapper()}
                </header>
                {egneKjoretoy.error ? this.renderErrorOgRetryKnapp() : this.renderFilterbarOgKjoretoyliste()}
            </section>
        );
    }

    private renderErrorOgRetryKnapp = () => {
        return (
            <div className="row">
                <div className="col-auto col-md-4">
                    <FormattedMessage tagName="p" id="kjoretoyliste.feilside.info" />
                    <FormattedMessage tagName="p" id="kjoretoyliste.feilside.rapportEllerProvPaNytt" />
                    <AkKnapp intlKey="kjoretoyliste.knapp.provPaNytt" customClassName="mt-4" action={this.handleProvPaNytt} loading={this.props.isLoading} />
                </div>
                <div className="col-auto">
                    <i className="ak-ikon-ikke-tilgjengelig" />
                </div>
            </div>
        );
    };

    private renderFilterbarOgKjoretoyliste = () => {
        const { side, pagineringAntall } = this.state;
        const { totalCount } = this.props.egneKjoretoy;

        return (
            <>
                <KjoretoylisteFilterPanel avdeling={this.state.avdelingFilter} regStatus={this.state.regStatusFilter} handleAvdelingChange={this.handleAvdelingChange}
                    valgtEnhet={this.props.valgtEnhet}
                    oppdaterRegStatusFilter={this.oppdaterRegStatusFilter} />
                <EgneKjoretoyConnected />
                {!this.props.isLoading && totalCount > DEFAULT_PAGINERING_ANT &&
                <div className="row pb-5 pt-3">
                    <FormattedMessage
                        id="kunde.sok.viserTreffAvTotal"
                        values={{
                            antall: this.pagineringAntallSelectInput(),
                            total: totalCount,
                            t: chunk => <span className="col-auto">{chunk}</span>
                        }}
                    >
                        {chunk =>
                            <div className="col-auto">
                                <div className="row align-items-baseline">{chunk}</div>
                            </div>
                        }
                    </FormattedMessage>
                    <AkPagination customWrapperClassName="ak-pagination col-auto m-0" page={side} nextPage={this.oppdaterSide}
                        size={pagineringAntall} total={totalCount} />
                </div>
                }
            </>
        );
    };

    private pagineringAntallSelectInput() {
        return (
            <AkSelectInput
                componentClassNames="no-gutters"
                options={getPagineringAntallOptions(this.props.egneKjoretoy.totalCount)}
                handleSelectChange={this.oppdaterPagineringAntall}
                intialSelectedValue={_.toString(this.state.pagineringAntall)}
            />
        );
    }

    private renderRapportKnapper = () => {
        return (
            <div className="d-flex flex-sm-row flex-column">
                { !this.props.isFlateeier &&
                <>
                    <div className="col-auto d-flex mb-3 mb-sm-0">
                        <AkDownloadButton downloadUrl="/kjennemerkerapporter" intlKey="kjoretoyliste.knapp.kjennemerkerapport" />
                            <i className="ak-severity-icon ak-ikon-tooltip ml-2 my-auto fa fa-info-circle">
                                <AkTooltip containerClassNames="ak-tooltip-shorter-text" placement="below" intlKey="kjoretoyliste.tooltip.kjennemerkerapport" />
                            </i>
                    </div>
                </>
                }
                <div className="col-auto d-flex mb-3 mb-sm-0">
                    <AkDownloadButton downloadUrl="/kjoretoyrapporter" intlKey="kjoretoyliste.knapp.kjoretoyrapport" />
                    <i className="ak-severity-icon ak-ikon-tooltip my-auto ml-2 fa fa-info-circle">
                        <AkTooltip containerClassNames="ak-tooltip-shorter-text d-none d-sm-block" placement="left" intlKey="kjoretoyliste.tooltip.kjoretoyrapport" />
                        <AkTooltip containerClassNames="ak-tooltip-shorter-text d-sm-none " placement="below" intlKey="kjoretoyliste.tooltip.kjoretoyrapport" />
                    </i>
                </div>
            </div>
        );
    };

    private oppdaterRegStatusFilter = (regStatus: RegistreringStatusFilterValg) => {
        this.setState({ regStatusFilter: regStatus, side: 1 });
    };

    private oppdaterSide = (nesteSide: number) => {
        this.setState({ side: nesteSide });
    };

    private oppdaterPagineringAntall = (pagineringAntall: string) => {
        this.setState({ pagineringAntall: _.toNumber(pagineringAntall) });
    };

    private handleAvdelingChange = (avdeling: string) => {
        this.setState({ avdelingFilter: EnhetType[avdeling], side: 1 });
    };

    private handleProvPaNytt = () => {
        const { avdelingFilter, regStatusFilter, side, pagineringAntall } = this.state;
        this.props.hentKjoretoy(avdelingFilter, regStatusFilter, false, side, pagineringAntall);
    };
}

const getPagineringAntallOptions = (antallTreff: number) => {
    const antOptions = [] as IAkSelectInputOption[];
    antOptions.push({ value: DEFAULT_PAGINERING_ANT.toString() });
    if (antallTreff >= MEDIUM_PAGINERING_ANT) {
        antOptions.push({ value: MEDIUM_PAGINERING_ANT.toString() });
    }
    if (antallTreff >= STOR_PAGINERING_ANT) {
        antOptions.push({ value: STOR_PAGINERING_ANT.toString() });
    }
    return antOptions;
};

const mapDispatchToProps = (dispatch): IKjoretoylisteDispatchProps => ({
    hentKjoretoy: (avdeling: EnhetType, regStatus: RegistreringStatusFilterValg, isCountOnly: boolean, side: number, pagineringAntall: number) =>
        dispatch(hentEgneKjoretoy(avdeling, regStatus, isCountOnly, side, pagineringAntall, SorteringType.SISTEEIERSKIFTEDATO, SorteringRetning.DESC))
});

const mapStateToProps = (state: RootStateType): IKjoretoylisteStateProps => ({
    kjennemerkeRapportEnabled: state.globals.klientKonfigurasjon.kjennemerkeRapportEnabled,
    egneKjoretoy: state.egneKjoretoy,
    isLoading: { ...state.egneKjoretoy }.isLoading,
    valgtEnhet: !!state.brukerprofil ? state.brukerprofil.valgtEnhet : null,
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier()
});

const AkDownloadButton = (props: Omit<React.ComponentProps<typeof AcfAkDownloadButton>, 'baseUrl'>) => {
    return (
        <AcfAkDownloadButton baseUrl={window.env.serviceUrl} downloadUrl={props.downloadUrl} intlKey={props.intlKey} canDownload={props.canDownload} disabled={props.disabled}
            handleError={props.handleError}/>
    );
};

const KjoretoylisteConnected = connect(mapStateToProps, mapDispatchToProps)(Kjoretoyliste);

export { KjoretoylisteConnected, Kjoretoyliste };
