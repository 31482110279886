import { AkSelectInput } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { WithRouterProps} from '../../utils';
import { withRouter } from '../../utils';
import { compose } from '@reduxjs/toolkit';

import { ArbeidslisteSakstypefilter, SamletGodkjenningStatus } from '../../models/kodeverk';
import type { Brukerprofil, IArbeidslisteFilter, IKunder } from '../../models/types';
import { BrukerRegler } from '../../regler';
import { arbeidslisteFetchAll, arbeidslisteFetchEierskifterOgRegistreringer, arbeidslisteFetchForstegangsregistreringer, arbeidslisteUpdateFilterAction } from '../../state/actions';
import { unikeMeldereIForstegangsregistreringsraderSelector } from '../../state/selectors';
import { LocalStorage } from '../../utils/akr-local-storage';

import './arbeidsliste-style';

enum ArbeidslisteFilterEnum {
    VIS_GODKJENTE = 'VIS_GODKJENTE',
    VENTER_PA_GODKJENNING = 'VENTER_PA_GODKJENNING',
    VIS_ALLE = 'VIS_ALLE',
    VIS_REGISTRERTE = 'VIS_REGISTRERTE',
    VIS_AVVISTE = 'VIS_AVVISTE',
    VIS_PABEGYNT_REGISTRERING = 'VIS_PABEGYNT_REGISTRERING'
}

interface IArbeidslisteFilterbarProps {
    sakstype: ArbeidslisteSakstypefilter;
}

interface IArbeidslisteFilterbarStateProps {
    brukerprofil: Brukerprofil;
    isFlateeier: boolean;
    kunder: IKunder;
    meldereIForstegangsregistreringssaker: string[];
    arbeidslisteFilter: IArbeidslisteFilter;
}

interface IArbeidslisteFilterbarDispatchProps {
    hentForstegangsregistreringSaker?: (filter: IArbeidslisteFilter, resetListe?: boolean) => Promise<any>;
    hentEierskifterOgRegistreringerSaker: (dager: string, withClear: boolean, godkjenningStatus?: SamletGodkjenningStatus) => Promise<any>;
    arbeidslisteFetchAll: (filter: IArbeidslisteFilter) => Promise<any>;
    updaterFilterIStore: (filter: Partial<IArbeidslisteFilter>) => Promise<void>;
}

interface IArbeidslisteFilterbarState {
    dager: string;
    statusfilter: ArbeidslisteFilterEnum;
}

type ArbeidslisteFilterbarPropsType = IArbeidslisteFilterbarProps & IArbeidslisteFilterbarStateProps & IArbeidslisteFilterbarDispatchProps & WrappedComponentProps & WithRouterProps;

class ArbeidslisteFilterbar extends React.Component<ArbeidslisteFilterbarPropsType, IArbeidslisteFilterbarState> {

    public state = {
        dager: LocalStorage.getItem('arbeidslisteDager') || '5',
        statusfilter: this.props.isFlateeier ? ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING : ArbeidslisteFilterEnum.VIS_ALLE
    };

    private DAGER_VALG = ['1', '5', '30'].map(dager => ({
        value: dager,
        description: this.props.intl.formatMessage(
            { id: 'startside.arbeidsliste.dagerSiden' },
            { dager })
    }));

    public componentDidMount(): Promise<any> {
        if (this.props.isFlateeier && this.props.sakstype === ArbeidslisteSakstypefilter.EIERSKIFTE) {
           return this.props.arbeidslisteFetchAll({ dager: this.state.dager, godkjenningStatus: SamletGodkjenningStatus.VENTER });
        }
        return this.props.arbeidslisteFetchAll({ dager: this.state.dager, venterPaGodkjenningFraInnloggetEnhet: this.props.isFlateeier });
    }

    public render(): React.ReactElement {
        const visMelderFilterbar = this.props.isFlateeier && this.props.sakstype === ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING;
        return (
            <div className="ak-div-table arbeidsliste-filter-container">
                <div className="arbeidsliste-filter-row">
                    <b className="pr-3"><FormattedMessage id="startside.arbeidsliste.dagerTilbake" /></b>
                    <AkSelectInput
                        intialSelectedValue={this.state.dager}
                        options={this.DAGER_VALG}
                        handleSelectChange={this.handleDagerChange} />
                </div>
                <div className="arbeidsliste-filter-row">
                    <b className="pr-3"><FormattedMessage id="startside.arbeidsliste.filter.visStatus" /></b>
                    <AkSelectInput options={this.mapStatusfiltervalgToSelectOptions()}
                                   handleSelectChange={this.handleStatusfilterChange}
                                   intialSelectedValue={this.state.statusfilter}/>
                </div>
                {visMelderFilterbar &&
                <div className="arbeidsliste-filter-row">
                    <b className="pr-3"><FormattedMessage id="startside.arbeidsliste.filter.melder" /></b>
                    <AkSelectInput options={this.mapMelderfiltervalgToSelectOptions()}
                                   handleSelectChange={this.handleMelderfilterChange}
                                   intialSelectedValue={this.props.arbeidslisteFilter.melderKundeId || 'ALLE'}/>
                </div>}
            </div>
        );
    }

    private handleStatusfilterChange = (value: ArbeidslisteFilterEnum) => {
        this.setState({ statusfilter: value });

        const filter: IArbeidslisteFilter = {
            dager: LocalStorage.getItem('arbeidslisteDager') || '5',
            venterPaGodkjenningFraInnloggetEnhet: value === ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING,
            godkjenningStatus: this.mapFilterTilGodkjenningsstatus(value)
        };
        if (this.props.sakstype === ArbeidslisteSakstypefilter.EIERSKIFTE || this.props.sakstype === ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING) {
            if (value === ArbeidslisteFilterEnum.VIS_ALLE || null) {
                this.props.hentEierskifterOgRegistreringerSaker(filter.dager, true);
            } else {
                this.props.hentEierskifterOgRegistreringerSaker(filter.dager, true, filter.godkjenningStatus);
            }
        } else {
            this.props.hentForstegangsregistreringSaker(_.omitBy(filter, _.isNull) as IArbeidslisteFilter, true);
        }
    };

    private handleDagerChange = (dager: string) => {
        LocalStorage.setItem('arbeidslisteDager', dager);
        this.setState({ dager });

        const filter = {
            dager,
            venterPaGodkjenningFraInnloggetEnhet: this.state.statusfilter === ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING,
            godkjenningStatus: this.mapFilterTilGodkjenningsstatus(this.state.statusfilter)
        };
        this.props.arbeidslisteFetchAll(_.omitBy(filter, _.isNull) as IArbeidslisteFilter);
    };

    private handleMelderfilterChange = (newValue: string) => {
        this.props.updaterFilterIStore({ melderKundeId: newValue === 'ALLE' ? '' : newValue });
    };

    private mapFilterTilGodkjenningsstatus = (status: ArbeidslisteFilterEnum) => {
        switch (status) {
            case ArbeidslisteFilterEnum.VIS_GODKJENTE:
                return SamletGodkjenningStatus.GODKJENT;
            case ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING:
                return SamletGodkjenningStatus.VENTER;
            case ArbeidslisteFilterEnum.VIS_AVVISTE:
                return SamletGodkjenningStatus.AVVIST;
            case ArbeidslisteFilterEnum.VIS_REGISTRERTE:
                return SamletGodkjenningStatus.REGISTRERT;
            case ArbeidslisteFilterEnum.VIS_PABEGYNT_REGISTRERING:
                return SamletGodkjenningStatus.PABEGYNT_REGISTRERING;
            default:
                return null;
        }
    };

    private mapStatusfiltervalgToSelectOptions = () => {
        if (!this.props.isFlateeier && this.props.sakstype === ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING) {
            return this.mapStatusfiltervalgForEierskifteOgRegistreringToSelectOptions();
        }
        if (this.props.isFlateeier && this.props.sakstype === ArbeidslisteSakstypefilter.EIERSKIFTE) {
            return this.mapStatusfiltervalgForEierskifteFlateeierToSelectOptions();
        } else if (this.props.isFlateeier && this.props.sakstype === ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING) {
            return this.mapStatusfiltervalgForForstegangsregistreringFlateeierToSelectOptions();
        }
        return this.mapStatusfiltervalgForForstegangsregistreringToSelectOptions();
    };

    private mapStatusfiltervalgForEierskifteOgRegistreringToSelectOptions = () => {
        return [{
            description: ArbeidslisteFilterEnum.VIS_ALLE,
            value: ArbeidslisteFilterEnum.VIS_ALLE,
            descriptionId: 'startside.arbeidsliste.filter.visAlle'
        },{
            description: ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING,
            value: ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING,
            descriptionId: 'startside.arbeidsliste.filter.venter'
        }, {
            description: ArbeidslisteFilterEnum.VIS_GODKJENTE,
            value: ArbeidslisteFilterEnum.VIS_GODKJENTE,
            descriptionId: 'startside.arbeidsliste.filter.godkjent'
        }, {
            description: ArbeidslisteFilterEnum.VIS_AVVISTE,
            value: ArbeidslisteFilterEnum.VIS_AVVISTE,
            descriptionId: 'startside.arbeidsliste.filter.avviste'
        }, {
            description: ArbeidslisteFilterEnum.VIS_REGISTRERTE,
            value: ArbeidslisteFilterEnum.VIS_REGISTRERTE,
            descriptionId: 'startside.arbeidsliste.filter.registrerte'
        }, {
            description: ArbeidslisteFilterEnum.VIS_PABEGYNT_REGISTRERING,
            value: ArbeidslisteFilterEnum.VIS_PABEGYNT_REGISTRERING,
            descriptionId: 'startside.arbeidsliste.filter.pabegyntRegistrering'
        }];
    };

    private mapStatusfiltervalgForEierskifteFlateeierToSelectOptions = () => {
        return [{
            description: ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING,
            value: ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING,
            descriptionId: 'startside.arbeidsliste.filter.venter'
        }, {
            description: ArbeidslisteFilterEnum.VIS_GODKJENTE,
            value: ArbeidslisteFilterEnum.VIS_GODKJENTE,
            descriptionId: 'startside.arbeidsliste.filter.godkjent'
        }, {
            description: ArbeidslisteFilterEnum.VIS_AVVISTE,
            value: ArbeidslisteFilterEnum.VIS_AVVISTE,
            descriptionId: 'startside.arbeidsliste.filter.avviste'
        }];
    };

    private mapStatusfiltervalgForForstegangsregistreringToSelectOptions = () => {
        return [{
            description: ArbeidslisteFilterEnum.VIS_ALLE,
            value: ArbeidslisteFilterEnum.VIS_ALLE,
            descriptionId: 'startside.arbeidsliste.filter.visAlle'
        }, {
            description: ArbeidslisteFilterEnum.VIS_GODKJENTE,
            value: ArbeidslisteFilterEnum.VIS_GODKJENTE,
            descriptionId: 'startside.arbeidsliste.filter.godkjent'
        }, {
            description: ArbeidslisteFilterEnum.VIS_AVVISTE,
            value: ArbeidslisteFilterEnum.VIS_AVVISTE,
            descriptionId: 'startside.arbeidsliste.filter.avviste'
        }, {
            description: ArbeidslisteFilterEnum.VIS_REGISTRERTE,
            value: ArbeidslisteFilterEnum.VIS_REGISTRERTE,
            descriptionId: 'startside.arbeidsliste.filter.registrerte'
        }];
    };

    private mapStatusfiltervalgForForstegangsregistreringFlateeierToSelectOptions = () => {
        return [{
            description: ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING,
            value: ArbeidslisteFilterEnum.VENTER_PA_GODKJENNING,
            descriptionId: 'startside.arbeidsliste.filter.venter'
        }, {
            description: ArbeidslisteFilterEnum.VIS_GODKJENTE,
            value: ArbeidslisteFilterEnum.VIS_GODKJENTE,
            descriptionId: 'startside.arbeidsliste.filter.godkjent'
        }];
    };

    private mapMelderfiltervalgToSelectOptions = () => {
        const valgliste = [{
            description: this.props.intl.formatMessage({ id: 'startside.arbeidsliste.filter.alle' }),
            value: 'ALLE'
        }];

        _.map(this.props.meldereIForstegangsregistreringssaker, melderKundeId => {
            const navn = this.props.kunder[melderKundeId]?.navn;
            if (navn) {
                valgliste.push({
                    description: navn,
                    value: melderKundeId
                });
            }
        });

        return valgliste;
    };
}

const mapStateToProps = (state): IArbeidslisteFilterbarStateProps => ({
    brukerprofil: state.brukerprofil || {},
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    kunder: state.kunder,
    meldereIForstegangsregistreringssaker: unikeMeldereIForstegangsregistreringsraderSelector(state),
    arbeidslisteFilter: state.arbeidsliste.filter
});

const mapDispatchToProps = (dispatch): IArbeidslisteFilterbarDispatchProps => ({
    arbeidslisteFetchAll: (filter: IArbeidslisteFilter) => dispatch(arbeidslisteFetchAll(filter)),
    hentForstegangsregistreringSaker: (filter: IArbeidslisteFilter, resetListe: boolean) =>
        dispatch(arbeidslisteFetchForstegangsregistreringer(filter, resetListe)),
    hentEierskifterOgRegistreringerSaker: (dager: string, withClear: boolean, godkjenningStatus?: SamletGodkjenningStatus) =>
        dispatch(arbeidslisteFetchEierskifterOgRegistreringer(dager, withClear, godkjenningStatus)),
    updaterFilterIStore: filter => dispatch(arbeidslisteUpdateFilterAction(filter))
});

const ArbeidslisteFilterbarConnected = compose(
    connect<IArbeidslisteFilterbarStateProps, IArbeidslisteFilterbarDispatchProps, IArbeidslisteFilterbarProps>(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withRouter
)(ArbeidslisteFilterbar);

export type { ArbeidslisteFilterbarPropsType };
export { ArbeidslisteFilterbarConnected, ArbeidslisteFilterbar, ArbeidslisteFilterEnum };
