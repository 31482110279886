import { compose } from '@reduxjs/toolkit';

import * as _ from 'lodash';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { IAkConfirmNavigationProps, IAktorPanelAktor} from 'svv-tk-akr-common-frontend';
import {
    AkConfirmNavigation, AkFremdriftsindikator, AkKnapp, AkLoading, AktorType, GodkjenningUtfortType, KjoretoyInfoPanel
} from 'svv-tk-akr-common-frontend';
import { IngenTreff } from '../../components';

import { EierskifteStatus, RegelmerknaderType } from '../../models/kodeverk';
import type {
    IAktor, IArkivdokument, IEierskifte, IEierskifteDispatchProps, IEierskifteState, IEierskifteStateProps, IGodkjenning, IKjennemerke, ISak, ISaksinformasjon, NavigateType, RootStateType
} from '../../models/types';
import { BrukerRegler, EierskifteRegler } from '../../regler';
import {
    avbrytEierskifte, endreEierskifte, endreEierskifteArkivdokument, endreEierskifteTilGodkjenningHosSaksbehandler, getEierskifte, hentSak, initierEierskifteForFlereKjoretoy, kjoretoyGetDataAction,
    lastOppEierskifteArkivdokument, opprettEierskifte, resetEierskifte, resetSak, slettEierskifteArkivdokument
} from '../../state/actions';
import { harRolleFlateeier } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { erAktorMyndig, erAvsluttetEllerAvbrutt, erMedeier, erUnderenhet, getKjennemerke, populerNyttEierskifte, utledObligatoriskeDokumenter, withRouter } from '../../utils';
import {
    EierskifteAktorerConnected, EierskifteInfoMeldingerConnected, EierskifteStatusMeldingerConnected, erAlleObligatoriskeDokumenterLastetOpp, INITIAL_STATE, skalViseModalForKjennemerkehandtering,
    utledEkstraInfomeldinger, utledEkstraKritiskeMerknader, utledModaltypeForKjennemerkehandtering
} from '../eierskifte';
import DokumenterPanel from '../felles/dokumenter-panel';
import { EierskifteKnapperad } from './eierskifte-knapperad';
import { EierskifteModaler, EierskifteModalType } from './modaler';

type PropsType = IEierskifteStateProps & IEierskifteDispatchProps & WrappedComponentProps & IAkConfirmNavigationProps & WithRouterProps;

class Eierskifte extends React.Component<PropsType, IEierskifteState> {

    public state = INITIAL_STATE;

    public componentDidMount() {
        const sakId = this.props.params.sakId;

        if (!EierskifteRegler.kanBehandleEierskifte(this.props.kjoretoy)) {
            this.props.navigate(`/kjoretoy/${this.props.kjoretoy.registrering.kuid}`, { replace: true });
        }

        if (this.props.eierskifte.isLoading) {
            return;
        }

        if (sakId) {
            this.props.getEierskifte(sakId);
            this.props.hentSak(sakId);
        } else {
            this.setState({ aktorer: populerNyttEierskifte(this.props.brukerprofil, this.props.registrering, this.props.kunder) });
        }
    }

    public componentDidUpdate(prevProps: Readonly<PropsType>) {
        const sakId = this.props.params.sakId;
        if (!!sakId && prevProps.params.sakId !== sakId) {
            this.props.hentSak(sakId);
            this.props.getEierskifte(sakId);
        }

        if (!prevProps.eierskifte.sakId && this.props.eierskifte.sakId) {
            this.populerEksisterendeEierskifte();
        }

        if (prevProps.sak?.erTilGodkjenning !== this.props.sak?.erTilGodkjenning) {
            this.setState({ readingMode: this.utledLesemodus() });
        }

        // FIXME Lars Henrik gjort i sammenheng med AUTKJOR-5821 for å vise lastOppDokumenter som blir skjult på grunn av feil utledning av lesemodus
        if (prevProps.sak?.sakId !== this.props.sak?.sakId) {
            this.setState({ readingMode: this.utledLesemodus() });
        }

        if (this.harOppdatertEierskifteMedEndredeMerknaderEllerFeil(prevProps)) {
            this.oppdaterEierskifteValidationEtterEierskifteoppdatering();
        }
    }

    private harOppdatertEierskifteMedEndredeMerknaderEllerFeil(prevProps: PropsType) {
        return !_.isEqual(prevProps.eierskifte.merknader, this.props.eierskifte.merknader) || !_.isEqual(prevProps.eierskifte.error, this.props.eierskifte.error);
    }

    public componentWillUnmount() {
        this.props.resetEierskifte();
        this.props.resetSak();
    }

    private erForhandlerEierForEierskifte(): boolean {
        const { eierskifte, brukerprofil, kjoretoy } = this.props;
        if (eierskifte && eierskifte.sakId) {
            const nyEierAktor = _.find(eierskifte.aktorer, (aktor: IAktor) => {
                return aktor.aktorType === AktorType.NY_EIER;
            });

            return !nyEierAktor || nyEierAktor.organisasjonsnummer !== brukerprofil.valgtEnhet.hovedenhetOrgNummer;

        } else {
            return new BrukerRegler(brukerprofil).erForhandlerEier(kjoretoy.registrering.eier);
        }
    }

    public render(): React.JSX.Element {
        const { aktorer } = this.state;
        const erForhandlerEier = this.erForhandlerEierForEierskifte();

        if (this.props.eierskifte.isLoading && !this.props.sak.isLoadingModalOpen) {
            return <AkLoading extraClassName="mt-5" />;
        }

        if (this.props.eierskifte.ikkeVisEierskifte) {
            return <IngenTreff informasjonIkkeTilgjengeligIBransje={true} />;
        }

        return (
            <div className="container row no-gutters mx-auto">
                <header className="row col-12 align-items-end mb-3 no-gutters">
                    <h1 className="col-12 col-lg-auto">
                        <FormattedMessage id={erForhandlerEier ? 'eierskifte.overskrift.selgeKjoretoy' : 'eierskifte.overskrift.kjopeKjoretoy'} />
                    </h1>
                    <AkFremdriftsindikator fremdriftsStatusIndex={EierskifteRegler.getProgressStatusEierskifte(this.state.eierskifte)}
                        tekstKeys={EierskifteRegler.getProgressStatusEierskifteTekster()} fremdriftsindikatorStyleClassName="col-auto col-sm-7 col-lg-5 mx-lg-auto" />
                    <div className="ak-knapperad col-auto ml-auto">
                        {this.renderKnapperad()}
                    </div>
                    {this.skalViseEierskifteStatusMeldinger() &&
                        <EierskifteStatusMeldingerConnected openModalForGodkjennEierskifte={this.apneModalForGodkjennEierskifte}
                            eierskifte={this.state.eierskifte}
                            opprettNyttEierskifte={this.opprettNyttEierskifte}
                            endreEierskifte={this.endreEierskifte}
                            openModalForAvbryt={this.apneModalForAvbryt}
                            erForhandlerEier={erForhandlerEier} />
                    }
                </header>
                <EierskifteInfoMeldingerConnected eierskifteValidation={this.state.eierskiftevalidering} ekstraInfomeldinger={utledEkstraInfomeldinger(this.props.sak, this.props.kjoretoy)}
                    ekstraVarselmeldinger={utledEkstraKritiskeMerknader(this.props.sak, this.state.eierskiftevalidering)} />

                <EierskifteAktorerConnected lesemodus={!!this.props.eierskifte.sakId} submitted={this.state.eierskiftevalidering.submitted}
                    aktorer={aktorer} oppdaterAktorer={this.oppdaterAktorer} slettAktor={this.slettAktor}  />

                <KjoretoyInfoPanel kjoretoy={this.props.kjoretoy} />

                {!this.harSakId() && !this.props.erFlateeier &&
                    <AkKnapp type="ak-link-knapp ak-ikon-legg-til mb-3" intlKey="eierskifte.knapp.leggTilFlereKjoretoy" action={this.addFlereKjoretoy} />
                }

                {this.skalViseDokumenterPanel() &&
                    <DokumenterPanel getSakId={this.getSakid} arkivdokumenter={this.props.sak.arkivdokumenter || []}
                        kunder={this.props.kunder}
                        obligatoriskeDokumenter={utledObligatoriskeDokumenter(this.props.sak.merknader)} lesemodus={this.state.readingMode}
                        lastOppFil={this.props.lastOppArkivdokument}
                        endreArkivdokument={this.props.endreArkivdokument}
                        slettArkivdokument={this.props.slettArkivdokument} />
                }

                <div className="ak-knapperad col-auto ml-auto d-md-none">
                    {this.renderKnapperad()}
                </div>
                <EierskifteModaler modalType={this.state.modaltype} lukkModal={this.closeModal} modalhandling={this.state.modalhandling} />
            </div>
        );
    }

    private skalViseEierskifteStatusMeldinger() {
        return this.erOpprettetSakOgIkkeTilDokumentgodkjenningHosSaksbehandler() || this.props.erFlateeier;
    }

    private erOpprettetSakOgIkkeTilDokumentgodkjenningHosSaksbehandler() {
        return this.harSakId() && !this.props.sak.erTilGodkjenning;
    }

    private skalViseDokumenterPanel(): boolean {
        return this.props.eierskifte.sakId && !this.props.erFlateeier && EierskifteRegler.finnesObligatoriskeDokumenter(this.props.sak?.merknader);
    }

    private getSakid = () => Promise.resolve(this.props.eierskifte.sakId);

    private addFlereKjoretoy = () => {
        this.props.initierEierskifteForFlereKjoretoy([...this.state.aktorer]);
        this.props.confirmNavigation(false);
        this.props.navigate('/kjoretoy/eierskifter');
    };

    private oppdaterAktorer = (aktorer: IAktor[]) => {
        if (!this.props.eierskifte.sakId) {
            this.props.confirmNavigation(this.state.modaltype !== null);
        }
        this.setState({
            aktorer,
            eierskiftevalidering: {
                merknader: [],
                gyldig: true,
                submitted: false
            }
        });
    };

    private slettAktor = (aktor: IAktor) => {
        const slett = () => {
            const aktorer = _.filter(this.state.aktorer, (eksistrendeAktor: IAktor) => {
                return eksistrendeAktor.aktorType !== aktor.aktorType;
            });

            this.props.confirmNavigation(true);
            this.setState({
                aktorer,
                eierskiftevalidering: {
                    merknader: [],
                    gyldig: true,
                    submitted: false
                }
            });
            this.closeModal();
        };

        this.setState({
            modalhandling: slett,
            modaltype: erUnderenhet(aktor) ? EierskifteModalType.FJERN_UNDERENHET : EierskifteModalType.FJERN_MEDEIER
        });
    };

    private closeModal = () => {
        this.props.confirmNavigation(false);
        this.setState({ modaltype: null, modalhandling: null });
    };

    private apneModalForLukk = () => {
        this.props.confirmNavigation(false);
        return this.setState({
            modaltype: EierskifteModalType.LUKK
        });
    };

    private apneModalForAvbryt = () => {
        return this.setState({
            modalhandling: this.avbrytEierskifte,
            modaltype: EierskifteModalType.AVBRYT
        });
    };

    private getKjennemerkeLink = () => {
        const { kjennemerke = {} as IKjennemerke } = this.props.registrering;
        return getKjennemerke(kjennemerke);
    };

    private oppdaterEierskifteValidationEtterEierskifteoppdatering() {
        const merknader = _.filter(this.props.eierskifte.merknader || [], m => m.type === RegelmerknaderType.KRITISK);
        this.setState(prevState => ({
            eierskiftevalidering: {
                ...prevState.eierskiftevalidering,
                errorId: this.props.eierskifte.error || null,
                merknader,
                gyldig: !(this.props.eierskifte.error || merknader.length)
            }
        }));
    }

    private validerOgSendTilGodkjenning = () => {
        _.remove(this.state.aktorer, (aktor: IAktor) => {
            return !aktor.navn && (erMedeier(aktor) || erUnderenhet(aktor));
        });

        const aktorer = this.state.aktorer.map(aktor => {
            return { ...aktor, godkjenningUtfort: this.utledGodkjenningUtfortTypeForAktor(aktor) };
        });
        const nyttEierskifte = { ...this.state.eierskifte, aktorer: aktorer, kuid: this.props.registrering.kuid, kjennemerke: this.getKjennemerkeLink() };
        const merknader = EierskifteRegler.validerEierskifte(nyttEierskifte, this.props.brukerprofil.bruker);

        if (!merknader.length) {
            this.setState({ eierskifte: nyttEierskifte }, () => {
                return skalViseModalForKjennemerkehandtering(nyttEierskifte.aktorer, this.props.kjoretoy, this.props.brukerprofil)
                       ? this.apneModalForKjennemerkehandtering(nyttEierskifte)
                       : this.sendTilGodkjenning();
            });
        } else {
            this.setState({
                eierskiftevalidering: {
                    merknader,
                    gyldig: false,
                    submitted: true
                }
            });
        }
    };

    private utledGodkjenningUtfortTypeForAktor = (aktor: IAktorPanelAktor): GodkjenningUtfortType => {
        if (aktor.erPerson && !erAktorMyndig(aktor) && !aktor.godkjenningUtfort) {
            return GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA;
        }
        return aktor.godkjenningUtfort;
    };

    private apneModalForKjennemerkehandtering = (eierskifte: IEierskifte) => {
        this.setState({
            eierskifte: { ...eierskifte },
            modalhandling: this.sendTilGodkjenning,
            modaltype: utledModaltypeForKjennemerkehandtering(this.state.aktorer, this.props.kjoretoy, this.props.brukerprofil),
            eierskiftevalidering: {
                gyldig: true,
                merknader: []
            }
        });
    };

    private apneModalForGodkjennEierskifte = (godkjennEierskifte: () => void) => {
        this.setState({
            modaltype: EierskifteModalType.GODKJENN,
            modalhandling: this.godkjennEierskifteMedCallback(godkjennEierskifte)
        });
    };

    private sendTilGodkjenning = () => {
        const { eierskifte } = this.state;
        this.props.confirmNavigation(false);
        this.closeModal();
        this.props.opprettEierskifte(this.props.navigate, eierskifte);
        this.setState(prevState => ({ eierskiftevalidering: { ...prevState.eierskiftevalidering, submitted: true } }));
    };

    private populerEksisterendeEierskifte = () => {
        const aktorer = [];
        this.props.eierskifte.aktorer.forEach((aktor: IAktor) => {
            const eksisterendeAktor = _.find(this.state.aktorer, { kundeId: aktor.kundeId });
            aktorer.push({ ...eksisterendeAktor, ...aktor });
        });

        this.setState({
            readingMode: this.utledLesemodus(),
            eierskifte: { ...this.props.eierskifte, kjennemerke: this.getKjennemerkeLink() },
            aktorer
        });
    };

    private utledLesemodus = () => erAvsluttetEllerAvbrutt(this.props.sak) || this.erOpprettetSakSomIkkeKanEndres();

    private erOpprettetSakSomIkkeKanEndres = () => this.harSakId() && (!EierskifteRegler.finnesObligatoriskeDokumenter(this.props.sak?.merknader) || this.props.sak.erTilGodkjenning);

    private harSakId = () => !!this.props.params.sakId;

    private renderKnapperad = () => {
        return (
            <EierskifteKnapperad
                lesemodus={this.state.readingMode}
                apneModalForLukk={this.apneModalForLukk}
                validerOgSendTilGodkjenning={this.validerOgSendTilGodkjenning}
                eierskiftevalidering={this.state.eierskiftevalidering.gyldig}
                endreSakErTilGodkjenningHosSaksbehandler={this.sendTilSaksbEllerTrekkTilbake}
                sakErTilGodkjenning={this.props.sak.erTilGodkjenning}
                sakMaGodkjennesHosSaksbehandler={!erAvsluttetEllerAvbrutt(this.props.sak) && EierskifteRegler.finnesObligatoriskeDokumenter(this.props.sak?.merknader)} />
        );
    };

    private sendTilSaksbEllerTrekkTilbake = () => {
        this.setState({
            eierskiftevalidering: {
                ...this.state.eierskiftevalidering,
                submitted: true
            }
        });
        if (erAlleObligatoriskeDokumenterLastetOpp(this.props.sak) || this.props.sak.erTilGodkjenning) {
            this.props.sendTilSaksbEllerTrekkTilbake(this.props.sak);
        }
    };

    private opprettNyttEierskifte = () => {
        this.props.resetEierskifte().then(() => {
            this.setState({
                readingMode: false,
                eierskifte: {
                    saksstatus: EierskifteStatus.UNDER_OPPRETTING,
                    kjennemerke: '',
                    kuid: '',
                    aktorer: []
                },
                aktorer: populerNyttEierskifte(this.props.brukerprofil, this.props.registrering, this.props.kunder)
            });
        });
    };

    private endreEierskifte = (eierskifte: IEierskifte) => {
        this.props.endreEierskifte(eierskifte).then((oppdatertEierskifte: IEierskifte) => {
            if (oppdatertEierskifte.saksstatus === EierskifteStatus.AVSLUTTET) {
                this.props.kjoretoyGetDataAction(eierskifte.kjennemerke).then(() => {
                    this.populerEksisterendeEierskifte();
                });
            } else {
                this.populerEksisterendeEierskifte();
                this.setState({
                    eierskiftevalidering: {
                        gyldig: false,
                        merknader: oppdatertEierskifte.merknader,
                        submitted: true
                    }
                });
            }
        });
    };

    private avbrytEierskifte = () => {
        this.props.avbrytEierskifte(this.props.eierskifte.sakId).then(() => {
            const { error } = this.props.eierskifte;
            if (error) {
                const eierskifteError = error.errorResponseCause ? error.errorResponseCause : error;
                this.setState(oldState => ({
                    eierskiftevalidering: {
                        ...oldState.eierskiftevalidering,
                        errorId: eierskifteError || null,
                        gyldig: false,
                        submitted: true
                    }
                }));
                this.closeModal();
            } else {
                this.props.navigate('/startside');
            }
        });
    };

    private godkjennEierskifteMedCallback = (bekreftEierskifteCallback?: () => void) => () => {
        if (bekreftEierskifteCallback) {
            bekreftEierskifteCallback();
        }
        this.closeModal();
    };

}

const mapStateToProps = (state: RootStateType): IEierskifteStateProps => ({
    registrering: state.kjoretoy.registrering,
    godkjenning: state.kjoretoy.godkjenning || {} as IGodkjenning,
    brukerprofil: state.brukerprofil,
    eierskifte: state.eierskifte,
    kjoretoy: state.kjoretoy,
    kunder: state.kunder,
    sak: state.sak,
    erFlateeier: harRolleFlateeier(state)
});

const mapDispatchToProps = (dispatch): IEierskifteDispatchProps => ({
    opprettEierskifte: (navigate: NavigateType, eierskifte: IEierskifte) => dispatch(opprettEierskifte(navigate, eierskifte)),
    getEierskifte: (saksId: string) => dispatch(getEierskifte(saksId)),
    avbrytEierskifte: (sakId: string) => dispatch(avbrytEierskifte(sakId)),
    endreEierskifte: (eierskifte: IEierskifte) => dispatch(endreEierskifte(eierskifte)),
    initierEierskifteForFlereKjoretoy: (aktorer: IAktor[]) => dispatch(initierEierskifteForFlereKjoretoy(aktorer)),
    kjoretoyGetDataAction: (kjennemerke: string) => dispatch(kjoretoyGetDataAction(kjennemerke)),
    resetEierskifte: () => dispatch(resetEierskifte()),
    resetSak: () => dispatch(resetSak()),
    hentSak: (sakId: string) => dispatch(hentSak(sakId)),
    lastOppArkivdokument: (sakId: string, fileWithMeta: IArkivdokument) => dispatch(lastOppEierskifteArkivdokument(sakId, fileWithMeta)),
    endreArkivdokument: (sakId: string, fileWithMeta: IArkivdokument) => dispatch(endreEierskifteArkivdokument(sakId, fileWithMeta)),
    slettArkivdokument: (sakId: string, dokumentId: number) => dispatch(slettEierskifteArkivdokument(sakId, dokumentId)),
    sendTilSaksbEllerTrekkTilbake: (sak: ISak<ISaksinformasjon>) => dispatch(endreEierskifteTilGodkjenningHosSaksbehandler(sak))
});

const EierskifteConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    AkConfirmNavigation,
    withRouter
)(Eierskifte);

export { EierskifteConnected };
