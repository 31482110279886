import type { AkModalType } from 'svv-tk-akr-common-frontend';
import { AkKnapp, AkModal } from 'svv-tk-akr-common-frontend';
import * as React from 'react';

interface IAvbrytSakModalProps {
    showModal: boolean;
    title: string;
    bodyTextKey: string;
    avbryt: () => void;
    close: () => void;
    isLoading?: boolean;
    bekreftIntlKey?: string;
    avbrytIntlKey?: string;
}

const AvbrytSakModal: React.FC<IAvbrytSakModalProps> = (props) => {
    const { avbryt, bodyTextKey, isLoading, close, showModal, title, bekreftIntlKey = 'generell.modal.avbryt.bekreft', avbrytIntlKey = 'generell.modal.avbryt.angre' } = props;

    if (!showModal) {
        return null;
    }

    return (
        <AkModal
            showModal={showModal}
            title={title}
            bodyTextKey={bodyTextKey}
            modalType={'warn' as AkModalType}
            closeModal={close}>
            <div className="ak-knapperad col row justify-content-end no-gutters">
                <AkKnapp type="ak-knapp ak-hovedknapp" action={avbryt} intlKey={bekreftIntlKey} loading={isLoading} />
                <AkKnapp type="ak-knapp ak-tilleggsknapp" action={close} intlKey={avbrytIntlKey} disabled={isLoading} />
            </div>
        </AkModal>
    );
};

export { AvbrytSakModal };
