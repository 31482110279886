import { AkKnapp, AkModal } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface IUtlevereUtenRegistreringModalProps {
    visModal: boolean;
    lukkModal: () => void;
    utleverKjennemerkerUtenRegistrering: () => void;
}

export const UtlevereUtenRegistreringModal: React.FC<IUtlevereUtenRegistreringModalProps> = (props) => {

    return (
        <AkModal showModal={props.visModal} title="utlevereUtenRegistrering.tittel" closeModal={props.lukkModal} >
            <div className="row no-gutters mb-4">
                <div className="mb-3">
                    <FormattedMessage id="utlevereUtenRegistrering.kropp" />
                </div>
                <FormattedMessage id="utlevereUtenRegistrering.kroppTillegg" />
            </div>
            <div className="ak-knapperad row">
                <AkKnapp customClassName="mr-2" type="ak-hovedknapp" action={props.utleverKjennemerkerUtenRegistrering} intlKey={'utlevereUtenRegistrering.godkjenn'} />
                <AkKnapp type="ak-tilleggsknapp" action={props.lukkModal} intlKey={'utlevereUtenRegistrering.avbryt'} />
            </div>
        </AkModal>
    );
};
