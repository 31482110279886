const FEILHANDTERING_MELDING_PREFIX = 'Feilen er logget med id:';

const INITIAL_STATE_MESSAGES = {
    'generell.loading': 'Loading...',
    'generell.ja': 'Ja',
    'generell.nei': 'Nei',
    'generell.visMer': 'Vis mer',
    'generell.visMindre': 'Vis mindre',
    'generell.avbryt': 'Avbryt',
    'generell.lukk': 'Lukk',
    'generell.advarsler.plural': '{ antallAdvarsler, plural, =0 {Ingen advarsler} one {# Advarsel} other {# Advarsler}}',
    'generell.nav.advarsel': 'Om du navigerer vekk fra siden mister du informasjonen du har lagt inn.',

    'toppmeny.seksjon.brukerprofil.overskrift': 'Velg avdeling',
    'toppmeny.seksjon.brukerprofil.logg-ut': 'Logg ut',
    'toppmeny.link.startside': 'STARTSIDE',
    'toppmeny.link.kjoretoyliste': 'KJØRETØYLISTE',
    'toppmeny.link.kjoreseddel': 'KJØRESEDDEL',
    'toppmeny.link.kjennemerke': 'KJENNEMERKER',
    'toppmeny.responsive-menu.toggler.hidden': 'Meny',
    'toppmeny.responsive-menu.toggler.visible': 'Lukk',
    'toppmeny.sokefelt.sok': 'Søk',
    'toppmeny.sokefelt.tomtFelt': 'Søkeinformasjon ikke oppgitt',

    'footer.vegvesen.epost': 'brukerstotte@vegvesen.no',
    'footer.vegvesen.funksjonsbrytere': 'Vis funksjonsbrytere',
    'footer.vegvesen.telefon': 'Telefon: 74 12 20 18',
    'footer.melding': 'For brukerstøtte - ta kontakt med Statens vegvesen: ',
    'footer.vegvesen.epost.melding': 'Epost: ',

    'startside.overskrift': 'Arbeidsliste',
    'startside.link.eierskifte': 'EIERSKIFTE',
    'startside.brukerveiledning.tooltip': 'tooltip',

    'generell.sokefelt.kjoretoy.label': 'Kjøretøy',
    'generell.sokefelt.kjoretoy.placeholder': 'Søk på kjennemerke eller understellsnummer',
    'generell.sokefelt.kjoretoy.ingenTreff': 'Ingen treff',

    'feilhandtering.for.mange.kjoretoy.overskrift': 'For mange treff',
    'feilhandtering.generell.ikke.kontakt': 'Oops.. fikk ikke kontakt med server',
    'feilhandtering.generell.overskrift': 'Teknisk feil',
    'feilhandtering.ikke.funnet.overskrift': 'Ingen treff',
    'feilhandtering.akr.forhandler.app.00007.overskrift': 'Ikke tilgjengelig',
    'feilhandtering.generell.link.gaatil': 'Gå til startside',
    'feilhandtering.kode.for.mange.kjoretoy': 'Søket gir flere treff. Vennligst endre søkekriterier og prøv igjen.',
    'feilhandtering.kode.ingen.kode': 'Au da, dette gikk ikke så bra. En ukjent feil har oppstått. Sjekk internettforbindelsen og prøv igjen!',
    /* eslint-disable max-len */
    'feilhandtering.kode.ikke.funnet': 'Søket gav ingen treff. Årsaken kan være at COC (Samsvarssertifikat)/enkeltgodkjenning ikke er innmeldt, eller at understellsnummeret / kjennemerket er skrevet feil.',
    /* eslint-enable max-len */
    'feilhandtering.kode.ukjent.kode': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00000': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00001': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00002': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00003': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00004': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00005': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00006': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00007': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00008': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00009': 'Ingen registrerte arbeidsteder funnet på din bruker. Kontakt brukerstøtte i Statens Vegvesen.',
    'feilhandtering.kode.akr.common.sys.00001': 'Integrasjon med en eller flere systemer feilet og din registrerte bruker kan ikke hentes. Feilen er logget med id: {errorId}',
    'feilhandtering.kode.akr.integration.sys.00001': 'Integrasjon med en eller flere systemer feilet og din registrerte bruker kan ikke hentes. Feilen er logget med id: {errorId}',
    'feilhandtering.kode.akr.forhandler.app.00007': 'Opplysninger om kjøretøyet er ikke tilgjengelig - eierskifte/omregistrering må gjennomføres ved trafikkstasjonen',
    'feilhandtering.kode.akr.common.app.00013': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`
};

export default INITIAL_STATE_MESSAGES;
