import type { Avdeling, IAkTextInputWithErrorState} from 'svv-tk-akr-common-frontend';
import { AkLoading, AkTextInputWithError, KjoretoyInfoPanel, Panel } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { RegelmerknaderKoder } from '../../models/kodeverk';
import type { IKjoretoy, IKjoretoyList, RootStateType } from '../../models/types';
import { EierskifteRegler, KjennemerkeRegler, Merknader } from '../../regler';
import { kjoretoyListGetItemAction, kjoretoyListRemoveItemAction } from '../../state/actions';
import { BOKSTAVER_OG_TALL, formatStoreBokstaverOgUtenWhitespace, ORG_NUMMER_LENGTH } from '../../utils';

interface IKjoretoyInformasjonStateProps {
    kjoretoyList?: IKjoretoyList;
}

interface IKjoretoyDispatchProps {
    getKjoretoy: (kjennemerke: string) => Promise<any>;
    kjoretoyListRemoveItemAction: (kjennemerke: string, kjennemerkeIRegistrering?: boolean) => Promise<any>;
}

interface IKjoretoyProps {
    initialAvdeling?: Avdeling;
    canDelete?: boolean;
}

interface IKjoretoyState {
    kjennemerke?: string;
    touched?: boolean;
    kjoretoyList?: IKjoretoyList;
    initialKjoretoy: IKjoretoy;
    kjoretoyValidering?: {
        submitted?: boolean;
        errorMessageKey?: string;
    };
}

type KjoretoyInformasjonBulkPropsType = IKjoretoyInformasjonStateProps & IKjoretoyDispatchProps & IKjoretoyProps;

class KjoretoyInformationBulk extends React.Component<KjoretoyInformasjonBulkPropsType, IKjoretoyState> {

    public state: IKjoretoyState = {
        kjennemerke: '',
        touched: false,
        initialKjoretoy: null,
        kjoretoyValidering: {
            submitted: false,
            errorMessageKey: null
        }
    };

    public componentDidUpdate(prevProps: KjoretoyInformasjonBulkPropsType): void {
        if (this.props.kjoretoyList?.kjoretoy.length > 0 && (this.state.initialKjoretoy == null || !_.find(this.props.kjoretoyList.kjoretoy, this.state.initialKjoretoy))) {
            this.setState({initialKjoretoy: this.props.kjoretoyList.kjoretoy[0]});
        }
        if (!_.isEqual(this.props.kjoretoyList, prevProps.kjoretoyList)) {
            this.evaluateKjoretoy(this.props.kjoretoyList);
        }
    }

    public render(): React.ReactNode {
        const {kjoretoy} = this.props.kjoretoyList;

        return (
            <Panel overskrift={'kjoretoydetaljer.generelt.kjoretoy'}
                panelCssClass={'col-12 ak-panel-transparent ak-med-tabell mb-5'}>
                <form className="row col-sm-6 m-3" onSubmit={this.submitKjennemerke}>
                    {/* eslint jsx-a11y/label-has-associated-control: "off" */}
                    <label htmlFor={"kjennemerkeInput"} className="col-sm-3 pt-1 font-weight-bold">
                        <FormattedMessage id="generell.label.kjennemerke" />
                    </label>
                    <AkTextInputWithError id={"kjennemerkeInput"} touched={this.state.touched} customClassName="col-sm-3 col-8"
                        onChange={this.inputOnChange}
                        stripWhitespace={true} autoFocus={true} validRegExp={BOKSTAVER_OG_TALL} />
                    <button className="ak-knapp ak-hovedknapp" type="submit"
                        disabled={this.state.kjoretoyValidering.submitted}>
                        <FormattedMessage id="generell.knapp.leggTil" />
                    </button>
                </form>
                {!this.props.kjoretoyList.isLoading && this.state.kjoretoyValidering.errorMessageKey &&
                <p className="row col-12 m-2 ak-input-error-text">
                    <FormattedMessage id={this.state.kjoretoyValidering.errorMessageKey} />
                </p>
                }
                {this.props.kjoretoyList.isLoading && <AkLoading extraClassName="col-12" />}

                {!!kjoretoy.length && kjoretoy.map((item) => {
                        if (item && EierskifteRegler.erGyldigKjoretoyForEierskifte(item) && this.kjoretoyHasSameOwner(item)) {
                            return <KjoretoyInfoPanel key={`${item.kuid}-${item.kjennemerke}`} kjoretoy={item} showAsIndrePanel={true} customInnholdCss="mb-2"
                                onDelete={this.props.canDelete && this.props.kjoretoyList.kjoretoy.length > 1 && this.slettKjoretoy} />;
                        }

                        return null;
                    }
                )}
            </Panel>
        );
    }

    private kjoretoyHasSameOwner = (kjoretoy: IKjoretoy): boolean => {

        if (!this.state.initialKjoretoy || !kjoretoy.registrering) {
            return true;
        }

        const newKjoretoyEier = {
            eier: kjoretoy.registrering.eier,
            medeier: kjoretoy.registrering.medeier,
            underenhet: kjoretoy.registrering.underenhet
        };

        const eksistingKjoretoyEier = {
            eier: (this.state.initialKjoretoy.registrering || {}).eier,
            medeier: (this.state.initialKjoretoy.registrering || {}).medeier,
            underenhet: (this.state.initialKjoretoy.registrering || {}).underenhet
        };

        return !eksistingKjoretoyEier.eier || _.isEqual(newKjoretoyEier, eksistingKjoretoyEier);
    };

    private evaluateKjoretoy = (kjoretoyList: IKjoretoyList) => {

        if (kjoretoyList.failedKjoretoy) {
            this.setState({
                kjoretoyValidering: {
                    submitted: false,
                    errorMessageKey: `eierskifte.label.${kjoretoyList.failedKjoretoy.errorId === 'NOT_FOUND' ? 'kjoretoyNotFound' : 'failHenterKjoretoy'}`
                }
            });
            return;
        }

        const invalidKjoretoy = _.find(kjoretoyList.kjoretoy, (item) => {
            return !EierskifteRegler.erGyldigKjoretoyForEierskifte(item);
        });

        if (invalidKjoretoy) {
            const errorKey = this.props.initialAvdeling ? 'flyttkjoretoy.validering.ugyldigKjoretoyForFlytting' : 'eierskifte.validering.ugyldigKjoretoyForStartEierskifte';
            this.setState({
                kjoretoyValidering: {
                    submitted: false,
                    errorMessageKey: errorKey
                }
            });
            this.props.kjoretoyListRemoveItemAction(invalidKjoretoy.kjennemerke);
        }

        const notSameAvdeling = _.find(kjoretoyList.kjoretoy, (item) => {
            if (this.props.initialAvdeling) {

                const merknader = Merknader.anyExists(item.merknader, [
                    {
                        kode: RegelmerknaderKoder.FLYTTING_IKKE_MULIG,
                        kontekst: 'akr.forhandler.flytting'
                    },
                    {
                        kode: RegelmerknaderKoder.FLYTTING_KAN_IKKE_STARTES,
                        kontekst: 'akr.forhandler.flytting'
                    }]);

                if (merknader) {
                    return true;

                }
                /* eslint @typescript-eslint/prefer-string-starts-ends-with: "warn" */
                else if (this.props.initialAvdeling.avdelingId.slice(ORG_NUMMER_LENGTH) === '000000000' &&
                    item.registrering.eier.organisasjonsnummer === this.props.initialAvdeling.hovedenhetOrgNummer) {
                    return false;
                } else if (item.registrering.underenhet && item.registrering.underenhet.organisasjonsnummer !== this.props.initialAvdeling.underenhetOrgNummer) {
                    return true;
                } else if (item.registrering.eier.organisasjonsnummer !== this.props.initialAvdeling.hovedenhetOrgNummer) {
                    return true;
                }
            }
        });

        if (notSameAvdeling) {
            this.setState({
                kjoretoyValidering: {
                    submitted: false,
                    errorMessageKey: 'flyttkjoretoy.validering.ikkeLikAvdeling'
                }
            });
            this.props.kjoretoyListRemoveItemAction(notSameAvdeling.kjennemerke);
            return;
        }

        const notSameOwnerKjoretoy = _.find(kjoretoyList.kjoretoy, (item) => {
            return !this.kjoretoyHasSameOwner(item);
        });

        if (notSameOwnerKjoretoy) {
            this.setState({
                kjoretoyValidering: {
                    submitted: false,
                    errorMessageKey: 'eierskifte.label.ikkeIdentiskEier'
                }
            });
            this.props.kjoretoyListRemoveItemAction(notSameOwnerKjoretoy.kjennemerke);
            return;
        }

        const apentEierskifte = _.find(kjoretoyList.kjoretoy, (item) => {
            return Merknader.anyExists(item.merknader, [
                {
                    kode: RegelmerknaderKoder.FLYTTING_KAN_IKKE_STARTES_PGA_APENT_EIERSKIFTE,
                    kontekst: 'akr.forhandler.flytting'
                }]);
        });

        const apenRegistrering = _.find(kjoretoyList.kjoretoy, (item) => {
            return Merknader.anyExists(item.merknader, [
                {
                    kode: RegelmerknaderKoder.FLYTTING_KAN_IKKE_STARTES_PGA_APEN_REGISTRERING,
                    kontekst: 'akr.forhandler.flytting'
                }]);
        });

        if (apentEierskifte || apenRegistrering) {
            this.setState({
                kjoretoyValidering: {
                    submitted: false,
                    errorMessageKey: `flyttkjoretoy.validering.${apentEierskifte ? 'harPabegyntEierskifte' : 'harPabegyntRegistreringssak'}`
                }
            });
            return this.props.kjoretoyListRemoveItemAction((apenRegistrering || apentEierskifte).kjennemerke);
        }

        const kjoretoyMedErstatningskjennemerkeIBestilling = _.find(kjoretoyList.kjoretoy, (kjoretoy: IKjoretoy) =>
            KjennemerkeRegler.erErstatningsKjennemerkeIBestilling(kjoretoy?.merknader)
        );

        if(kjoretoyMedErstatningskjennemerkeIBestilling){
            this.visFeilmelding('flyttkjoretoy.validering.bestillingIProsess');
            return this.props.kjoretoyListRemoveItemAction((kjoretoyMedErstatningskjennemerkeIBestilling).kjennemerke);
        }
    };

    private slettKjoretoy = (kjoretoy: IKjoretoy): void => {
        const kjennemerkeIRegistrering = !kjoretoy?.kjennemerke;
        const kjennemerke = kjoretoy?.kjennemerke || kjoretoy.registrering.kjennemerke.tegnkombinasjon;
        this.props.kjoretoyListRemoveItemAction(kjennemerke.toLocaleUpperCase(), kjennemerkeIRegistrering);
    };

    private visFeilmelding = (errorMessageKey: string) => {
        this.setState({
            kjoretoyValidering: {
                submitted: false,
                errorMessageKey: errorMessageKey
            }
        });
    };

    private submitKjennemerke = (event?) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({touched: true});

        if (!this.state.kjennemerke) {
            return;
        }

        if (this.erKjoretoyAlleredeIListen()) {
            this.visFeilmelding('eierskifte.validering.kjoretoyetErAlleredeLagtTil');
            return;
        }

        this.setState({kjoretoyValidering: {submitted: true, errorMessageKey: null}});
        const maxNumberIKjoretoyList = 49;

        if (this.props.kjoretoyList.kjoretoy.length <= maxNumberIKjoretoyList) {
            this.props.getKjoretoy(this.state.kjennemerke).then(() => {
                this.setState({
                    kjennemerke: '',
                    kjoretoyValidering: {...this.state.kjoretoyValidering, submitted: false}
                });
            });
        } else {
            this.setState({
                kjoretoyValidering: {
                    submitted: false,
                    errorMessageKey: 'eierskifte.label.maksimaltKjoretoyPaEierskifter'
                }
            });
        }
    };

    private erKjoretoyAlleredeIListen = (): boolean => {
        return _.some(this.props.kjoretoyList.kjoretoy, (kjoretoy: IKjoretoy) =>
            formatStoreBokstaverOgUtenWhitespace(kjoretoy.registrering.kjennemerke.tegnkombinasjon) === this.state.kjennemerke.toUpperCase());
    };

    private inputOnChange = (input: IAkTextInputWithErrorState) => {
        if (!input.errors.length) {
            return this.setState({kjennemerke: input.value});
        } else {
            this.setState({kjennemerke: null});
        }
    };
}

const mapStateToProps = (state: RootStateType): IKjoretoyInformasjonStateProps => ({
    kjoretoyList: state.kjoretoyList
});

const mapDispatchToProps = (dispatch): IKjoretoyDispatchProps => ({
    getKjoretoy: (kjennemerke: string) => dispatch(kjoretoyListGetItemAction(kjennemerke)),
    kjoretoyListRemoveItemAction: (kjennemerke: string, kjennemerkeIRegistrering: boolean) => dispatch(kjoretoyListRemoveItemAction(kjennemerke, kjennemerkeIRegistrering))
});

const KjoretoyInformationBulkConnected = connect(mapStateToProps, mapDispatchToProps)(KjoretoyInformationBulk);

export { KjoretoyInformationBulkConnected, KjoretoyInformationBulk };
