import { createSelector } from 'reselect';

import type { IKjoretoy, IKodeType, IKunde, IKunder, IRootState } from '../../models/types';
import { kunderSelector } from './kunde-selectors';

function finnKunde(kunde: IKunde, kunder: IKunder): IKunde {
    if (!kunde) {
        return null;
    }

    if (!kunder) {
        return kunde;
    }

    if (kunde.kundeId in kunder) {
        return kunder[kunde.kundeId];
    }

    if (kunde.organisasjonsnummer in kunder) {
        return kunder[kunde.organisasjonsnummer];
    }

    return kunde;
}

export function kjoretoySelector(state: Pick<IRootState, 'kjoretoy'>): IKjoretoy {
    return state.kjoretoy;
}

export const kjennemerkeSelector = createSelector(
    kjoretoySelector,
    (kjoretoy): string => (kjoretoy.kjennemerke)
);

export const kuidSelector = createSelector(
    kjoretoySelector,
    kjoretoy => (kjoretoy.registrering && kjoretoy.registrering.kuid)
        || (kjoretoy.godkjenning && kjoretoy.godkjenning.kuid)
        || null
);

export const kjoretoyMerknaderSelector = createSelector(
    kjoretoySelector,
    (kjoretoy) => (kjoretoy.merknader)
);

export const registreringSelector = createSelector(
    kjoretoySelector,
    (kjoretoy) => (kjoretoy.registrering || {})
);

export const registreringsinformasjonSelector = createSelector(
    registreringSelector,
    (registrering) => (registrering.registreringsinformasjon)
);

export const registrertEierSelector = createSelector(
    registreringsinformasjonSelector,
    (registreringsinformasjon): boolean => (registreringsinformasjon && registreringsinformasjon.paregistrertInneverendeEier)
);

export const gjeldendeEierSelector = createSelector(
    registreringSelector,
    kunderSelector,
    (registrering, kunder) => (finnKunde(registrering.eier, kunder))
);

export const gjeldendeMedeierSelector = createSelector(
    registreringSelector,
    kunderSelector,
    (registrering, kunder) => (finnKunde(registrering.medeier, kunder))
);

export const gjeldendeLeasingtakerSelector = createSelector(
    registreringSelector,
    kunderSelector,
    (registrering, kunder) => (finnKunde(registrering.leietaker, kunder))
);

export const gjeldendeUnderenhetSelector = createSelector(
    registreringSelector,
    kunderSelector,
    (registrering, kunder) => (finnKunde(registrering.underenhet, kunder))
);

export const neringskoderSelector = createSelector(
    gjeldendeEierSelector,
    (eier): IKodeType[] => {
        if (!(eier && eier.organisasjonsnummer && eier.neringskoder && eier.neringskoder.length)) {
            return [];
        }

        return eier.neringskoder;
    }
);
