import { createSelector } from 'reselect';
import { kjoretoySelector } from './kjoretoy-selectors';

export const godkjenningSelector = createSelector(
    kjoretoySelector,
    (kjoretoy) => (kjoretoy.godkjenning)
);

export const tillattTotalvektSelector = createSelector(
    godkjenningSelector,
    (godkjenning) => (godkjenning.tillattTotalvekt)
);

export const forstegangsgodkjenningSelector = createSelector(
    godkjenningSelector,
    (godkjenning) => (godkjenning.forstegangsgodkjenning)
);

export const understellsnummerSelector = createSelector(
    godkjenningSelector,
    (godkjenning) => (godkjenning.understellsnummer)
);
