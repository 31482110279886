import * as React from 'react';
import { type ChangeEventHandler, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AkFileUtil, type IMessageDescriptor } from 'svv-tk-akr-common-frontend';
import type { IArkivdokument } from '../../../models/types';

interface IFilvelger {
    nyFil: IArkivdokument;
    setNyFil: (doc: IArkivdokument | null) => void;
}

const Filvelger: React.FC<IFilvelger> = ({ nyFil, setNyFil }) => {
    const [filError, setFilError] = useState<IMessageDescriptor | null>();

    const filId = `fileinput_${Math.random().toString()}`;

    const leggTilFil: ChangeEventHandler<HTMLInputElement> = (evt) => {
        const inputFil = AkFileUtil.extractFilesFromInputEvent(evt)[0];
        if (inputFil) {
            AkFileUtil.validateFile(inputFil).then(
                () => {
                    setNyFil({
                        filnavn: inputFil.name,
                        fil: inputFil
                    });
                    setFilError(null);
                },
                (validationError: IMessageDescriptor) => {
                    setFilError(validationError);
                }
            );
        }
    };

    return <>
        <div className="row align-items-center pl-3">
            {!nyFil?.id && (
                <div className="ak-filvalg-knapp">
                    <input data-testid="filopplastningInput" id={filId} className="ak-visually-hidden" type="file" onChange={leggTilFil} multiple={false} />
                    <label htmlFor={filId} className="ak-knapp ak-tilleggsknapp mr-3">
                        <FormattedMessage id="dokumenterpanel.modal.kropp.velgFil" />
                    </label>
                </div>
            )}
            <FormattedMessage id="dokumenterpanel.modal.kropp.valgtFil" values={{ count: nyFil?.filnavn ? 1 : 0, filename: <b className="ml-3">{nyFil?.filnavn}</b> }} />
        </div>
        {filError &&
            <p className="ak-text-error ml-2">
                <FormattedMessage {...filError} />
            </p>}
    </>;
};

export default Filvelger;