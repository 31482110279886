import type { MessageDescriptorOrStringType, IMessageDescriptor, Avdeling
} from 'svv-tk-akr-common-frontend';
import {
    AkKnapp, AkMeldingboks, AkMeldingboksType, AktorGodkjenningstatus, ISO_8601_DATO_TID_FORMAT, VilkarStatus
} from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { valgtEnhetSelector } from '../../state/selectors';
import type { WithRouterProps} from '../../utils';
import { withRouter } from '../../utils';
import { compose } from '@reduxjs/toolkit';

import { AvsluttSakIndikator, Saksstatus, SaksType } from '../../models/kodeverk';
import type { IRegistreringInformasjon, ISak, IVilkar, IVilkarstatus, NavigateType, RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';
import { gaTilBetalingSkatt, hentMidlertidigVognkort, submitForstegangsregistrering } from '../../state/actions';
import { godkjennForInnloggetEnhet } from '../../state/actions/sak/aktor-actions';
import { aktorerHarGodkjent, STANDARD_DATO_FORMAT, STANDARD_KLOKKESLETT_FORMAT, venterPaGodkjenningFraInnloggetBrukerSomErNyEierEllerNyMedeier } from '../../utils';

interface IForstegangsregistreringStatusbarProps {
    sak: ISak<IRegistreringInformasjon>;
    saksinformasjon?: IRegistreringInformasjon;
    confirmNavigation?: (shouldConfirm: boolean) => boolean;
}

interface IForstegangsregistreringStatusbarStateProps {
    vognkortIsLoading: boolean;
    vilkarstatus: IVilkarstatus;
    isFlateeier: boolean;
    valgtEnhet: Avdeling;
}

interface IForstegangsregistreringStatusbarDispatchProps {
    hentMidlertidigVognkort?: (kuid) => void;
    gaTilBetaling: (betalingsreferanse: string) => void;
    godkjenn: (sakId: string, godkjenningsstatus: AktorGodkjenningstatus) => any;
    lagreSak?: (navigate: NavigateType, saksinformasjon: IRegistreringInformasjon) => Promise<unknown>;
}

type ForstegangsregistreringStatusbarPropsType = IForstegangsregistreringStatusbarProps
    & IForstegangsregistreringStatusbarDispatchProps
    & IForstegangsregistreringStatusbarStateProps
    & WithRouterProps;

class ForstegangsregistreringStatusbar extends Component<ForstegangsregistreringStatusbarPropsType> {

    public render(): React.ReactElement {
        if (!this.skalViseStatusbar() || this.props.sak.isLoadingModalOpen) {
            return null;
        }

        return (
            <AkMeldingboks meldingType={AkMeldingboksType.INFO} headingKey={this.utledHeaderTekst()} messageKey={this.utledMessageTekst()}
                customClassName="row col-12 mb-4 pl-5 no-gutters" knapperClassName="align-self-center ml-auto mr-5">
                {this.renderKnapper()}
            </AkMeldingboks>
        );
    }

    private renderKnapper() {
        if (this.sakErAvsluttet()) {
            return !this.props.isFlateeier && (
                <AkKnapp type="ak-hovedknapp" intlKey="generell.knapp.skrivUtMidlVognkort" customClassName="ml-auto"
                    action={this.skrivUtMidlVognkort} loading={this.props.vognkortIsLoading} />
            );
        }

        if (this.sakSendtTilGodkjenning()) {
            const avgiftVilkar = this.finnAvgiftVilarIkkeOppfylt();
            if (avgiftVilkar && avgiftVilkar.tilleggsinformasjon) {
                return (
                    <>
                        { this.renderKnapperForGodkjennAvvis() }
                        <AkKnapp type="ak-hovedknapp" intlKey="generell.knapp.gaTilSkatteetaten" customClassName="mr-2" action={this.handleGaTilBetaling} />
                    </>
                );
            }
        }

        return this.renderKnapperForGodkjennAvvis();
    }

    private renderKnapperForGodkjennAvvis() {
        if (this.props.isFlateeier && venterPaGodkjenningFraInnloggetBrukerSomErNyEierEllerNyMedeier(this.props.valgtEnhet, this.props.sak)) {
            return (
                <>
                    <AkKnapp type="ak-suksessknapp" intlKey="generell.knapp.godkjenn" customClassName="mr-2" action={this.handleGodkjenn} />
                    <AkKnapp type="ak-tilleggsknapp" intlKey="generell.knapp.avvis" customClassName="mr-2" action={this.handleAvvis} />
                </>
            );
        }

        return null;
    }

    private handleGodkjenn = () => {
        const { sak, saksinformasjon } = this.props;

        // Hvis leasingtaker er lagt til uten å lagre så må saken lagres først for å ikke miste leasingtakeren
        if (sak.saksinformasjon.leasingtaker !== saksinformasjon.leasingtaker) {
            return this.props.lagreSak(this.props.navigate, this.props.saksinformasjon).then(() => {
                this.props.godkjenn(this.props.sak.sakId, AktorGodkjenningstatus.GODKJENT);
            });
        }

        this.props.godkjenn(this.props.sak.sakId, AktorGodkjenningstatus.GODKJENT);
    };

    private handleAvvis = () => {
        this.props.confirmNavigation(false);
        this.props.godkjenn(this.props.sak.sakId, AktorGodkjenningstatus.AVVIST);
    };

    private handleGaTilBetaling = () => {
        const avgiftvilkar = this.finnAvgiftVilarIkkeOppfylt();
        this.props.gaTilBetaling(avgiftvilkar.tilleggsinformasjon as string);
    };

    private skrivUtMidlVognkort = () => {
        this.props.hentMidlertidigVognkort(this.props.sak.kuid);
    };

    private utledHeaderTekst = (): IMessageDescriptor => {
        if (this.sakErAvsluttet()) {
            return { id: 'forstegangsregistrering.melding.overskrift.registrert' };
        } else if (this.sakSendtTilGodkjenning()) {
            return {
                id: 'forstegangsregistrering.melding.overskrift.sendtTilGodkjenning',
                values: {
                    dato: moment(this.props.sak.opprettetDato, ISO_8601_DATO_TID_FORMAT).format(STANDARD_DATO_FORMAT),
                    tid: moment(this.props.sak.opprettetDato, ISO_8601_DATO_TID_FORMAT).format(STANDARD_KLOKKESLETT_FORMAT)
                }
            };
        }
        return null;
    };

    private utledMessageTekst = (): MessageDescriptorOrStringType[] => {

        if (this.sakErAvsluttet()) {
            return [{ id: 'forstegangsregistrering.melding.montereKjennemerke' }];
        }

        if (this.sakSendtTilGodkjenning()) {
            const meldinger = [{ id: 'forstegangsregistrering.melding.sendtTilGodkjenning' }];
            const avgiftvilkar = this.finnAvgiftVilarIkkeOppfylt();

            if ( avgiftvilkar && avgiftvilkar.status === VilkarStatus.IKKE_OPPFYLT) {
                meldinger.push({ id: 'forstegangsregistrering.melding.utestaendeAvgifter' });
            }

            return meldinger;
        }

        return [];
    };

    private finnAvgiftVilarIkkeOppfylt = (): IVilkar => _.find(this.props.vilkarstatus.vilkar, { navn: 'AVGIFT', status: VilkarStatus.IKKE_OPPFYLT });
    private sakSendtTilGodkjenning = () => this.props.sak.saksstatus === Saksstatus.APEN && !aktorerHarGodkjent(this.finnAktorerPaSak());
    private sakErAvsluttet = () => this.props.sak.saksstatus === Saksstatus.AVSLUTTET;
    private skalViseStatusbar = () => this.sakErAvsluttet() || this.sakSendtTilGodkjenning();
    private finnAktorerPaSak = () => [this.props.sak.saksinformasjon.nyEier, this.props.sak.saksinformasjon.nyMedeier, this.props.sak.saksinformasjon.melder];
}

const mapDispatchToPros = (dispatch): IForstegangsregistreringStatusbarDispatchProps => ({
    hentMidlertidigVognkort: kuid => dispatch(hentMidlertidigVognkort(kuid)),
    gaTilBetaling: (betalingsreferanse: string) => dispatch(gaTilBetalingSkatt(betalingsreferanse)),
    godkjenn: (sakId, godkjenningssatus) => dispatch(godkjennForInnloggetEnhet(sakId, godkjenningssatus, SaksType.FORSTEGANGSREGISTRERING)),
    lagreSak: (history, saksinformasjon) => dispatch(submitForstegangsregistrering(history, saksinformasjon, AvsluttSakIndikator.IKKE_AVSLUTT))
});

const mapStateToProps = (state: RootStateType): IForstegangsregistreringStatusbarStateProps => ({
    vognkortIsLoading: state.midlertidigVognkort && state.midlertidigVognkort.isLoading,
    vilkarstatus: state.vilkarstatus,
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    valgtEnhet: valgtEnhetSelector(state)
});

const ForstegangsregistreringStatusbarConnected = compose(
    connect<IForstegangsregistreringStatusbarStateProps, IForstegangsregistreringStatusbarDispatchProps, IForstegangsregistreringStatusbarProps>(mapStateToProps, mapDispatchToPros),
    withRouter
)(ForstegangsregistreringStatusbar);

export type { ForstegangsregistreringStatusbarPropsType };
export { ForstegangsregistreringStatusbarConnected, ForstegangsregistreringStatusbar };
