export enum KjoringensArt {
    EGENTRANSPORT = 'EGENTRANSPORT',
    DIPLOMAT = 'DIPLOMAT',
    FORSVARET = 'FORSVARET',
    EGENTRANSP_POLITIET = 'EGENTRANSP_POLITIET',
    NATO = 'NATO',
    EGENTRANSP_KONGEHUSET = 'EGENTRANSP_KONGEHUSET',
    HANDICAP = 'HANDICAP',
    KUN_SVALBARD = 'KUN_SVALBARD',
    NORD_INVEST_BANK = 'NORD_INVEST_BANK',
    EGENTRANSP_ANNEN = 'EGENTRANSP_ANNEN',
    RUTEVOGN = 'RUTEVOGN',
    RUTEV_LP_PTRANS_EIER = 'RUTEV_LP_PTRANS_EIER',
    RUTEV_LP_PTRANS_LEASING = 'RUTEV_LP_PTRANS_LEASING',
    RUTEV_LP_KOMB_EIER = 'RUTEV_LP_KOMB_EIER',
    RUTEV_LP_KOMB_LEASING = 'RUTEV_LP_KOMB_LEASING',
    RUTEV_DEMONSTR_BUSS = 'RUTEV_DEMONSTR_BUSS',
    RUTEV_RESERVE_BUSS = 'RUTEV_RESERVE_BUSS',
    DROSJE_SELSKAPSVOGN = 'DROSJE_SELSKAPSVOGN',
    DROSJE_EIER = 'DROSJE_EIER',
    DROSJE_LEASING = 'DROSJE_LEASING',
    DROSJE_RESERVE = 'DROSJE_RESERVE',
    PERSONTRANS_LP_SELSKAPSVOGN = 'PERSONTRANS_LP_SELSKAPSVOGN',
    GODSTRANS_LP_LEASING = 'GODSTRANS_LP_LEASING',
    UTLEIEVOGN = 'UTLEIEVOGN',
    LAREVOGN = 'LAREVOGN',
    HOTELLVOGN = 'HOTELLVOGN',
    TURVOGN = 'TURVOGN',
    TURVOGN_EIER = 'TURVOGN_EIER',
    TURVOGN_LEASING = 'TURVOGN_LEASING',
    TRAKTOR_MED_BRUKSBEGRENSNING = 'TRAKTOR_MED_BRUKSBEGRENSNING',
    ERVM_KJOERING_TIDLIGERE = 'ERVM_KJOERING_TIDLIGERE',
    SPESINNR_HCP_LP = 'SPESINNR_HCP_LP',
    ANNEN_ERV_KJ_BEGRAVELSESBIL = 'ANNEN_ERV_KJ_BEGRAVELSESBIL',
    ANNEN_ERVM_KJORING = 'ANNEN_ERVM_KJORING',
    MOTORSPORT = 'MOTORSPORT',
    UTENFOR_OFF_VEI = 'UTENFOR_OFF_VEI'
}
