import type { IKunderMap} from 'svv-tk-akr-common-frontend';
import { Kanal, RegelmerknaderType, VilkarstatusSaksdokument } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import type { SaksType } from '../models/kodeverk';
import { Saksstatus } from '../models/kodeverk';

import type { IArkivdokument, IKjoretoy, IMerknad, IObligatoriskDokument, ISak, ISaksdokument } from '../models/types';

import { getKundeNavn } from './aktor-utils';

export const utledObligatoriskeDokumenter = (merknader: IMerknad[] = []): IObligatoriskDokument[] =>
    _.chain(merknader)
        .map(merknad => merknad.obligatoriskeDokumenter)
        .flatMap()
        .uniqBy(idForDokument)
        .value();

export const harApenSakAvType = (sakType: SaksType, kjoretoy: IKjoretoy): boolean =>
    _.some(kjoretoy.apneSaker, sak => sak.saksType === sakType.valueOf());

export const harAvvistDokumentTilKontrollpanel = (saksdokument: ISaksdokument): boolean =>
    (saksdokument.godkjentStatus === VilkarstatusSaksdokument.AVVIST) && !_.isEmpty(saksdokument.avvistArsak);

export const skalVisesIKontrollpanel = (saksdokument: ISaksdokument): boolean => {
    const lastetOppKanal : Kanal = saksdokument.lastetOppFraKanal;
    return Kanal.FORHANDLER === lastetOppKanal &&
        (harAvvistDokumentTilKontrollpanel(saksdokument) || saksdokument.godkjentStatus === VilkarstatusSaksdokument.GODKJENT);
};

export const mapeSaksdokumenterStatusTilObligatoriskeDokumenter = (saksdokumenter: ISaksdokument[], obligatoriskeDokumenter: IObligatoriskDokument[]): IObligatoriskDokument[] =>
     _.map(obligatoriskeDokumenter, oblDok => ({
    gjelderForMerknadskode: oblDok.gjelderForMerknadskode,
    saksdokumentType: oblDok.saksdokumentType,
    innfridd: oblDok.innfridd,
    aktor: oblDok.aktor,
    godkjentStatus: saksdokumenter.find(dok => dok.saksdokumentType === oblDok.saksdokumentType)?.godkjentStatus}));

export const utledVisningsdokumenterTilKontrollisteFraArkivdokumenter = (arkivdokumenter: IArkivdokument[]): IObligatoriskDokument[] =>
    _.chain(arkivdokumenter)
        .flatMap('saksdokumenter')
        .filter(skalVisesIKontrollpanel)
        .uniqBy(idForSaksdokument)
        .map(convertSaksdokumentTilKontrollistedokument)
        .value();

const convertSaksdokumentTilKontrollistedokument = (sakDok: ISaksdokument, kunder: IKunderMap): IObligatoriskDokument => {
    return {
        ...sakDok,
        aktor: { ...sakDok.aktor, navn: getKundeNavn(sakDok.aktor?.kundeId, kunder) },
        saksdokumentType: sakDok.godkjentStatus === VilkarstatusSaksdokument.AVVIST ? sakDok.saksdokumentTypeForAvvisning : sakDok.saksdokumentType,
        gjelderForMerknadskode: '',
        innfridd: sakDok.godkjentStatus === VilkarstatusSaksdokument.GODKJENT
    };
};

export const inneholderIkkeKritiskMerknad = (merknader: IMerknad[]): boolean => {
    return _.isEmpty(merknader.filter(m => {
        return m.type as RegelmerknaderType === RegelmerknaderType.KRITISK;
    }));
};

export const erAvsluttetEllerAvbrutt = (sak: ISak): boolean => {
    return _.includes([Saksstatus.AVSLUTTET, Saksstatus.AVBRUTT], sak.saksstatus);
};

const idForDokument = (dok: ISaksdokument | IObligatoriskDokument) => JSON.stringify([dok.saksdokumentType, dok.aktor, dok.avvistArsak, dok.godkjentStatus]);
const idForSaksdokument = (dok: ISaksdokument) => JSON.stringify([dok.saksdokumentType, dok.saksdokumentTypeForAvvisning, dok.aktor, dok.avvistArsak, dok.godkjentStatus]);
