import { KontrollerVilkarActionKey, SakActionKey } from '../../constants';
import type { IVilkarstatus } from '../../models/types';
import type { KontrollerVilkarActionType } from '../actions';

const initialState: IVilkarstatus = {
    kuid: null,
    validertKjennmerke: null,
    vilkar: [],
    merknader: [],
    isLoading: false,
    isWaiting: false,
    waitingSaksinformasjon: {}
};

export function vilkarstatus(state: IVilkarstatus = initialState, action: KontrollerVilkarActionType): IVilkarstatus {
    switch (action.type) {
        case KontrollerVilkarActionKey.KONTROLLER_VILKAR_LOADING:
            return {...state, isLoading: true, error: null};
        case KontrollerVilkarActionKey.KONTROLLER_VILKAR_ERROR:
            return {...initialState, isLoading: false, error: action.error};
        case KontrollerVilkarActionKey.KONTROLLER_VILKAR_SUCCESS:
            return {...initialState, ...action.vilkarstatus, isLoading: false};
        case SakActionKey.SAK_RESET:
            return {...initialState, isLoading: false};
        case KontrollerVilkarActionKey.KONTROLLER_VILKAR_UPDATE_WAITING:
            return { ...state, isWaiting: action.vilkarstatus.isWaiting, waitingSaksinformasjon: action.vilkarstatus.waitingSaksinformasjon };
        default:
            return state;
    }
}
