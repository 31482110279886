import { KjoringensArtListeActionKey } from '../../constants/action-keys';
import type { IKjoringensArtListe } from '../../models/types';
import type { KjoringensArtActionType } from '../actions/kjoringensArt';

const initialState: IKjoringensArtListe = {
    innslag: [],
    isLoading: false,
    error: null
};

export function kjoringensArtListe(state: IKjoringensArtListe = initialState, action: KjoringensArtActionType): IKjoringensArtListe {
    switch (action.type) {
        case KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_IS_LOADING:
            return action.isLoading ? { ...initialState, isLoading: action.isLoading } : { ...state, isLoading: action.isLoading };
        case KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_HAS_ERROR:
            return { ...state, error: action.error };
        case KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_FETCH_DATA_SUCCESS:
            return { ...state, innslag: action.innslag };
        default:
            return state;
    }
}
