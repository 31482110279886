import { RegelmerknaderKoder, RegelmerknaderKontekst } from '../models/kodeverk';
import type { IMerknad } from '../models/types';

export enum RegistreringKundeType {
    NY_LEASINGTAKER = 'NY_LEASINGTAKER',
    NY_UNDERENHET = 'NY_UNDERENHET'
}

export enum RegistreringStatus {
    GA_TIL_BETALING = 'GA_TIL_BETALING',
    OPPDATER = 'OPPDATER',
    REGISTRER = 'REGISTRER',
    VARSLER = 'VARSLER',
    REGISTRERT = 'REGISTRERT'
}

export enum RegistreringStatusKode {
    REGISTRERT = 'REGISTRERT',
    AVREGISTRERT = 'AVREGISTRERT',
    UTFORT = 'UTFORT',
    VRAKET = 'VRAKET'
}

export enum RegistreringStatusFilterValg {
    ALLE = 'ALLE',
    AVREGISTRERT = 'AVREGISTRERT',
    REGISTRERT = 'REGISTRERT'
}

export enum EnhetType {
    HOVEDENHET = 'HOVEDENHET',
    UNDERENHET = 'UNDERENHET'
}

export enum SorteringType {
    KJENNEMERKE = 'KJENNEMERKE',
    EIERFORHOLD = 'EIERFORHOLD',
    REGISTRERINGSAR = 'REGISTRERINGSAR',
    REGISTRERINGSSTATUS = 'REGISTRERINGSSTATUS',
    SISTEEIERSKIFTEDATO = 'SISTEEIERSKIFTEDATO',
    REGISTRERTPAEIERDATO = 'REGISTRERTPAEIERDATO'
}

export enum SorteringRetning {
    ASC = 'ASC',
    DESC = 'DESC'
}

export const AKR_KJENNEMERKE = 'AKR-Kjennemerke';
export const APPLIKASJON_UTILGJENGELIG = 'En feil oppstod i kall mot eksternt system';
export const CONSUMERKALL_FEILET = 'Feilet under kall til consumere';

export const MERKNAD_FOR_FULLFOR_PA_TRAFIKKSTASJON = [
    RegelmerknaderKoder.KJORINGENS_ART_LOYVEPLIKTIG,
    RegelmerknaderKoder.KJORINGENS_ART_NORD_INVEST_BANK,
    RegelmerknaderKoder.KJORINGENS_ART_KONGEHUSET,
    RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_MILITAERTAKTISK,
    RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_PROTOTYPE,
    RegelmerknaderKoder.KJORETOY_KJENNEMERKER_IKKE_TILGJENGELIGE,
    RegelmerknaderKoder.ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING,
    RegelmerknaderKoder.KJENNEMERKE_VETERAN_FUNNET_TAPT_INNLEVERT,
    RegelmerknaderKoder.TEKNISKE_ENDRINGER
];

export const MERKNADER_SOM_HINDRER_START_OMREGISTRERING: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.OMREGISTRERING_KAN_IKKE_STARTES },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_REGISTRERINGSAR },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_TOTALVEKT },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_AVGIFTSKODE },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_TEKNISK_KODE },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_EGENVEKT },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_DRIVSTOFFKODE },
    { kode: RegelmerknaderKoder.KJORETOY_AMBASSADE },
    { kode: RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_MILITAERTAKTISK },
    { kode: RegelmerknaderKoder.KJORINGENS_ART_NORD_INVEST_BANK },
    { kode: RegelmerknaderKoder.KJORETOY_UTFORT }
];

export const MERKNADER_SOM_HINDRER_START_PAREGISTRERING: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.PAREGISTRERING_KAN_IKKE_STARTES },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_REGISTRERINGSAR },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_TOTALVEKT },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_AVGIFTSKODE },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_TEKNISK_KODE },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_EGENVEKT },
    { kode: RegelmerknaderKoder.KJORETOY_MANGLER_DRIVSTOFFKODE },
    { kode: RegelmerknaderKoder.KJORETOY_AMBASSADE },
    { kode: RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_MILITAERTAKTISK },
    { kode: RegelmerknaderKoder.KJORINGENS_ART_NORD_INVEST_BANK },
    { kode: RegelmerknaderKoder.KJORETOY_UTFORT }
];

export const MERKNADER_SOM_IKKE_SKAL_VISES_I_SKJERMBILDET: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.AKTOR_ER_MYNDIG },
    { kode: RegelmerknaderKoder.AKTOR_IKKE_MYNDIG_KJORETOY_LETT_HENGER },
    { kode: RegelmerknaderKoder.KJORINGENS_ART_SVALBARD, kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_START_EIERSKIFTE },
    { kode: RegelmerknaderKoder.KJORETOY_ER_RALLY_MED_AVGIFTSFRITAK, kontekst: RegelmerknaderKontekst.AKR_REGLER_START_REGISTRERING_SAMME_EIER },
    { kode: RegelmerknaderKoder.KJORETOY_ER_RALLY_MED_AVGIFTSFRITAK, kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_START_EIERSKIFTE },
    { kode: RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_TRIAL_ENDURO, kontekst: RegelmerknaderKontekst.AKR_REGLER_START_REGISTRERING_SAMME_EIER },
    { kode: RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_TRIAL_ENDURO, kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_START_EIERSKIFTE },
    { kode: RegelmerknaderKoder.KJORETOY_MIDL_AVREGISTRERT },
    { kode: RegelmerknaderKoder.KJORETOY_MIDL_AVREG_BRANSJE },
    { kode: RegelmerknaderKoder.KJORETOY_KRAV_OM_VEKTARSAVGIFT },
    { kode: RegelmerknaderKoder.IKKE_GODKJENT_PKK },
    { kode: RegelmerknaderKoder.PKK_GODKJENT },
    { kode: RegelmerknaderKoder.IKKE_OMFATTET_AV_PKK },
    { kode: RegelmerknaderKoder.KJENNEMERKETS_TEGNKOMBINASJON_KAN_ENDRES },
    { kode: RegelmerknaderKoder.KJENNEMERKETYPE_KAN_ENDRES },
    { kode: RegelmerknaderKoder.KJORETOY_IKKE_REGISTRERT_PA_NY_EIER },
    { kode: RegelmerknaderKoder.KJENNEMERKE_MA_BYTTES },
    { kode: RegelmerknaderKoder.ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING }, // Vises via Registering-status-meldinger
    { kode: RegelmerknaderKoder.KJENNEMERKE_VETERAN_FUNNET_TAPT_INNLEVERT }, // Vises via MERKNAD_FOR_FULLFOR_PA_TRAFIKKSTASJON
    { kode: RegelmerknaderKoder.KJORINGENS_ART_BEGRAVELSESBIL }, // Vises via MERKNAD_FOR_FULLFOR_PA_TRAFIKKSTASJON
    { kode: RegelmerknaderKoder.KJORINGENS_ART_KONGEHUSET }, // Vises via MERKNAD_FOR_FULLFOR_PA_TRAFIKKSTASJON
    { kode: RegelmerknaderKoder.KJORINGENS_ART_NORD_INVEST_BANK }, // Vises via MERKNAD_FOR_FULLFOR_PA_TRAFIKKSTASJON
    { kode: RegelmerknaderKoder.KJORINGENS_ART_MOTORSPORT } // Vises via MERKNAD_FOR_FULLFOR_PA_TRAFIKKSTASJON
];
