import * as _ from 'lodash';

import { NERINGSKODER_FOR_KJORINGENS_ART_12_ELLER_13, NERINGSKODER_FOR_KJORINGENS_ART_50, NERINGSKODER_FOR_KJORINGENS_ART_50_ELLER_70 } from '../constants';
import { GodkjenningTekniskKode, KjoringensArt } from '../models/kodeverk';
import type { IKjoretoy, IKjoringensArt, IKodeType, IKunde } from '../models/types';
import { KjoretoyRegler, RegistreringRegler } from '../regler';
import {
    erKlassifisertSomBegravelsesbil, erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt, erKlassifisertSomLarevognEllerKjoringensArtListeInneholderKunLarevogn,
    erKlassifisertSomRallyMedAvgiftsfritak, erKlassifisertSomTrialEnduro
} from './kjoretoy-utils';

export const utledGyldigeKjoringensArt = (eier: IKunde, neringskode: string, kjoretoy: IKjoretoy, alleKjoringensArt: IKjoringensArt[]): IKjoringensArt[] => {
    if (!eier) {
        return [];
    }

    if (RegistreringRegler.skalKunBrukesUtenforOffentligVeg(kjoretoy)) {
        return [_.find(alleKjoringensArt, {name: KjoringensArt.UTENFOR_OFF_VEI})];
    }

    if (eier.organisasjonsnummer) {
        return utledGyldigeKjoringensArtForOrganisasjon(alleKjoringensArt, neringskode);
    }

    return [
        _.find(alleKjoringensArt, {name: KjoringensArt.EGENTRANSP_ANNEN}),
        _.find(alleKjoringensArt, {name: KjoringensArt.UTLEIEVOGN})
    ];
};

export const utledGyldigeKjoringensArtForOrganisasjon = (alleKjoringensArt: IKjoringensArt[], valgtNeringskode: string): IKjoringensArt[] => {
    const arter: IKjoringensArt[] = [];
    const kode = utledKode(valgtNeringskode);
    arter.push(_.find(alleKjoringensArt, {name: KjoringensArt.EGENTRANSP_ANNEN}));
    arter.push(_.find(alleKjoringensArt, {name: KjoringensArt.DROSJE_EIER}));

    if (_.includes(NERINGSKODER_FOR_KJORINGENS_ART_50, kode)) {
        arter.push(_.find(alleKjoringensArt, {name: KjoringensArt.UTLEIEVOGN}));
    } else if (_.includes(NERINGSKODER_FOR_KJORINGENS_ART_12_ELLER_13, kode)) {
        arter.push(_.find(alleKjoringensArt, {name: KjoringensArt.FORSVARET}));
        arter.push(_.find(alleKjoringensArt, {name: KjoringensArt.EGENTRANSP_POLITIET}));
    } else if (_.includes(NERINGSKODER_FOR_KJORINGENS_ART_50_ELLER_70, kode)) {
        arter.push(_.find(alleKjoringensArt, {name: KjoringensArt.UTLEIEVOGN}));
        arter.push(_.find(alleKjoringensArt, {name: KjoringensArt.HOTELLVOGN}));
    }

    return _.uniqBy(arter, 'name');
};

export const utledValgtKjoringensArt = (kjoringensArt: string, eier: IKunde, kjoretoy: IKjoretoy, kjoringensArtListeInnslag: IKjoringensArt[]): string => {

    if (kjoringensArt) {
        return kjoringensArt;
    }

    if (RegistreringRegler.skalKunBrukesUtenforOffentligVeg(kjoretoy) || new KjoretoyRegler(kjoretoy).harKjoretoyTekniskKode(GodkjenningTekniskKode.BELTEMOTORSYKKEL)) {
        return KjoringensArt.UTENFOR_OFF_VEI;

    }
    if (erKlassifisertSomBegravelsesbil(kjoretoy)) {
        return KjoringensArt.ANNEN_ERV_KJ_BEGRAVELSESBIL;

    }
    if (erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt(kjoretoy)) {
        return KjoringensArt.SPESINNR_HCP_LP;

    }
    if (erKlassifisertSomRallyMedAvgiftsfritak(kjoretoy) || erKlassifisertSomTrialEnduro(kjoretoy)) {
        return KjoringensArt.MOTORSPORT;

    }
    if (erKlassifisertSomLarevognEllerKjoringensArtListeInneholderKunLarevogn(kjoretoy, kjoringensArtListeInnslag)) {
        return KjoringensArt.LAREVOGN;

    }

    return eier && eier.erPerson ? KjoringensArt.EGENTRANSP_ANNEN : null;
};

export const utledValgtNeringskode = (neringskode: string, neringskoder: IKodeType[]): string => {
    if (neringskode) {
        return neringskode;
    } else if (neringskoder && neringskoder.length === 1) {
        return neringskoder[0].kode;
    }

    return null;
};

export const utledNeringskodeErrors = (neringskode: string, eier: IKunde): string[] => {
    const errors: string[] = [];

    if (!neringskode && eier.organisasjonsnummer && !_.isEmpty(eier.neringskoder)) {
        errors.push('sak.validering.neringskodeErPakrevet');
    }

    return errors;
};

export const utledNeringskodebeskrivelse = (neringskode: string, neringskoder: IKodeType[]): string => {
    return (neringskoder || [])
        .filter(neringskoden => neringskoden.kode === neringskode)
        .map(neringskoden => neringskoden.beskrivelse).join('');
};

export const harEndretKjoringensArt = (kjoringesArt: string, nyKjoringensArt: string, eier: IKunde, kjoretoy: IKjoretoy, kjoringensArtListeInnslag: IKjoringensArt[]): boolean => {
    const valgtKjoringensArt = utledValgtKjoringensArt(kjoringesArt, eier, kjoretoy, kjoringensArtListeInnslag);
    return !_.isEqual(valgtKjoringensArt, nyKjoringensArt);
};

const utledKode = (kode: string): string => {
    if (!kode) {
        return '';
    }

    const index = kode.indexOf('.');
    if (index > -1) {
        kode = kode.substring(0, index);
    }
    return kode;
};
