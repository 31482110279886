import type { ThunkAction } from 'redux-thunk';
import { AkrConfig, KjennemerkeActionKey } from '../../constants';
import type {IKjennemerkeserierOgTyper, IKjennemerkeserie, RootStateType, IError, ThunkResult} from '../../models/types';
import { createRestResource } from '../store';

export type KjennemerkeserierOgTyperActionType =
    IKjennemerkeserierOgTyperFetchDataSuccessAction
    | IKjennemerkeserierOgTyperFetchDataErrorAction
    | IKjennemerkeserierOgTyperFetchDataLoadingAction
    | IKjennemerkeserierOgTyperResetAction;


export type KjennemerkeserieActionType =
    IKjennemerkeserieFetchDataSuccessAction
    | IKjennemerkeserieFetchDataErrorAction
    | IKjennemerkeserieFetchDataLoadingAction;


interface IKjennemerkeserieFetchDataLoadingAction {
    type: KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_LOADING;
    isLoading: boolean;
}

interface IKjennemerkeserieFetchDataSuccessAction {
    type: KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_SUCCESS;
    kjennemerkeserie: IKjennemerkeserie;
}

interface IKjennemerkeserieFetchDataErrorAction {
    type: KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_ERROR;
    error: IError;
}

interface IKjennemerkeserierOgTyperFetchDataLoadingAction {
    type: KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_LOADING;
    isLoading: boolean;
}

interface IKjennemerkeserierOgTyperFetchDataSuccessAction {
    type: KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_SUCCESS;
    kjennemerkeserierOgTyper: IKjennemerkeserierOgTyper;
}

interface IKjennemerkeserierOgTyperFetchDataErrorAction {
    type: KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_ERROR;
    error: any;
}

interface IKjennemerkeserierOgTyperResetAction {
    type: KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_RESET;
}

export const kjennemerkeserieFetchDataSuccessAction = (kjennemerkeserie: IKjennemerkeserie): IKjennemerkeserieFetchDataSuccessAction => ({
    type: KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_SUCCESS, kjennemerkeserie
});

export const kjennemerkeserieLoadingAction = (isLoading: boolean): IKjennemerkeserieFetchDataLoadingAction => ({
    type: KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_LOADING, isLoading
});

export const kjennemerkeserieFetchErrorAction = (error: IError): IKjennemerkeserieFetchDataErrorAction => ({
    type: KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_ERROR, error
});

export const kjennemerkeserierOgTyperFetchDataSuccessAction = (kjennemerkeserierOgTyper: IKjennemerkeserierOgTyper): IKjennemerkeserierOgTyperFetchDataSuccessAction => ({
    type: KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_SUCCESS, kjennemerkeserierOgTyper
});

export const kjennemerkeserierOgTyperLoadingAction = (isLoading: boolean): IKjennemerkeserierOgTyperFetchDataLoadingAction => ({
    type: KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_LOADING, isLoading
});

export const kjennemerkeserierOgTyperFetchErrorAction = (error: IError): IKjennemerkeserierOgTyperFetchDataErrorAction => ({
    type: KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_ERROR, error
});

export const kjennemerkeserierOgTyperResetAction = (): IKjennemerkeserierOgTyperResetAction => ({
    type: KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_RESET
});

const api = createRestResource(AkrConfig.KJENNEMERKE_SERIER_OG_TYPER_RESOURCE_URL);

export function hentKjennemerkeserierOgTyper(kuid: string, kjoringensArt: string): ThunkResult<Promise<KjennemerkeserierOgTyperActionType>, KjennemerkeserierOgTyperActionType> {
    return dispatch => {

        dispatch(kjennemerkeserierOgTyperLoadingAction(true));

        const requestParams: any = { kuid, kjoringensArt };

        return api.get(null, requestParams)
            .then((response) => {
                if (response === null || response.errorId) {
                    return dispatch(kjennemerkeserierOgTyperFetchErrorAction(response));
                }

                return dispatch(kjennemerkeserierOgTyperFetchDataSuccessAction(response));
            });
    };
}
const serieApi = createRestResource(AkrConfig.KJENNEMERKESERIE_RESOURCE_URL);

export function finnKjennemerkeserie(tegnkombinasjon: string): ThunkResult<Promise<KjennemerkeserieActionType>, KjennemerkeserieActionType> {
    return dispatch => {

        dispatch(kjennemerkeserieLoadingAction(true));

        return serieApi.get(null, {tegnkombinasjon})
            .then((response) => {
                if (response.errorId) {
                    return dispatch(kjennemerkeserieFetchErrorAction(response));
                }
                return dispatch(kjennemerkeserieFetchDataSuccessAction(response));
            });
    };
}

export function resetKjennemerkeserierOgTyper(): ThunkAction<void, RootStateType, undefined, IKjennemerkeserierOgTyperResetAction> {
    return dispatch => {
        dispatch(kjennemerkeserierOgTyperResetAction());
    };
}
