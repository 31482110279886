import { AkRestApi } from 'svv-tk-akr-common-frontend';
export function createRestResource(resourceUrl: string): AkRestApi {
    return new AkRestApi({
        baseUrl: `${window.env.serviceUrl}`,
        resourceUrl: resourceUrl,
        redirectHandler: (response) => {
            const redirectUrl = new URL(response.url);
            window.location.replace(redirectUrl.pathname);
            return Promise.resolve(response);
        }
    });
}

export * from './store-config';
export * from './hooks';
export * from './initial-state';
