import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Panel } from 'svv-tk-akr-common-frontend';
import type { ITilleggsgodkjenning } from '../../models/types';
import { akFormatDate } from '../../utils';

const KjoretoydetaljerTilleggsgodkjenninger: React.FC<{tilleggsgodkjenninger: ITilleggsgodkjenning[]}> = (props) => {
    if (!props.tilleggsgodkjenninger.length) {
        return null;
    }

    return (
        <Panel overskrift={'kjoretoydetaljer.tilleggsgodkjenninger.overskrift'}>
            <ul className="ak-liste">
                {props.tilleggsgodkjenninger.map(tilleggsgodkjenning => (
                    <li key={tilleggsgodkjenning.tilleggsgodkjenningtype}>
                        <FormattedMessage id={`kodeverk.godkjenningstype.${tilleggsgodkjenning.tilleggsgodkjenningtype}`} />
                        {tilleggsgodkjenning.godkjentFra && <span> {akFormatDate(tilleggsgodkjenning.godkjentFra)} </span>}
                        {tilleggsgodkjenning.godkjentTil && <span> - {akFormatDate(tilleggsgodkjenning.godkjentTil)} </span>}
                    </li>
                ))}
            </ul>
        </Panel>
    );
};

export { KjoretoydetaljerTilleggsgodkjenninger };
