export enum RegelmerknaderKoder {
    AKTOR_ER_MYNDIG = 'AKTOR_ER_MYNDIG',
    AKTOR_IKKE_MYNDIG_KJORETOY_LETT_HENGER = 'AKTOR_IKKE_MYNDIG_KJORETOY_LETT_HENGER',
    APEN_REGISTRERING = 'APEN_REGISTRERING',
    AVDELING_HAR_IKKE_RETTIGHETER = 'AVDELING_HAR_IKKE_RETTIGHETER',
    BRUKER_HAR_IKKE_RETTIGHETER = 'BRUKER_HAR_IKKE_RETTIGHETER',
    BRUKSFORBUD_ANNEN_ARSAK = 'BRUKSFORBUD_ANNEN_ARSAK',
    BRUKSFORBUD_KONTROLLFRIST_OPPMOTE = 'BRUKSFORBUD_KONTROLLFRIST_OPPMOTE',
    BRUKSFORBUD_KONTROLLFRIST_PKK = 'BRUKSFORBUD_KONTROLLFRIST_PKK',
    BRUKSFORBUD_KONTROLLFRIST_TEKNISK_MANGEL = 'BRUKSFORBUD_KONTROLLFRIST_TEKNISK_MANGEL',
    BRUKSFORBUD_MANGLER_REGISTRERING = 'BRUKSFORBUD_MANGLER_REGISTRERING',
    BRUKSFORBUD_MANGLER_FORSIKRING = 'BRUKSFORBUD_MANGLER_FORSIKRING',
    BRUKSFORBUD_TEKNISK_MANGEL_PKK = 'BRUKSFORBUD_TEKNISK_MANGEL_PKK',
    BRUKSFORBUD_TEKNISK_MANGEL_TEKNISK_KONTROLL = 'BRUKSFORBUD_TEKNISK_MANGEL_TEKNISK_KONTROLL',
    DIPLOMAT_DOK = 'DIPLOMAT_DOK',
    EIER_MANGLER_SAKSADRESSE = 'EIER_MANGLER_SAKSADRESSE',
    EIER_MELDT_FORSVUNNET = 'EIER_MELDT_FORSVUNNET',
    EIER_MELDT_FORSVUNNET_DNUMMER = 'EIER_MELDT_FORSVUNNET_DNUMMER',
    EIER_STATUS_KONKURS = 'EIER_STATUS_KONKURS',
    EIER_UVERIFISERT_KUNDE = 'EIER_UVERIFISERT_KUNDE',
    EIER_UGYLDIG_PERSONSTATUS = 'EIER_UGYLDIG_PERSONSTATUS',
    EIERE_I_EIERSKIFTE_SAMSVARER_IKKE_MED_EIERE_PA_KJORETOY = 'EIERE_I_EIERSKIFTE_SAMSVARER_IKKE_MED_EIERE_PA_KJORETOY',
    ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING = 'ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING',
    FLYTTING_IKKE_MULIG = 'FLYTTING_IKKE_MULIG',
    FLYTTING_KAN_IKKE_STARTES = 'FLYTTING_KAN_IKKE_STARTES',
    FLYTTING_KAN_IKKE_STARTES_PGA_APEN_REGISTRERING = 'FLYTTING_KAN_IKKE_STARTES_PGA_APEN_REGISTRERING',
    FLYTTING_KAN_IKKE_STARTES_PGA_APENT_EIERSKIFTE = 'FLYTTING_KAN_IKKE_STARTES_PGA_APENT_EIERSKIFTE',
    FORHANDLER_ER_INHABIL = 'FORHANDLER_ER_INHABIL',
    FOR_MANGE_KJORETOY = 'FOR_MANGE_KJORETOY',
    IKKE_GODKJENT_PKK = 'IKKE_GODKJENT_PKK',
    IKKE_OMFATTET_AV_PKK = 'IKKE_OMFATTET_AV_PKK',
    INGEN_DRIVSTOFFSPESIFIKK_KJENNEMERKESERIE_PA_LAGER = 'INGEN_DRIVSTOFFSPESIFIKK_KJENNEMERKESERIE_PA_LAGER',
    INGEN_FORSENDELSESADRESSE_BESTILLER = 'INGEN_FORSENDELSESADRESSE_BESTILLER',
    KAN_IKKE_REGISTRERES_I_NORGE = 'KAN_IKKE_REGISTRERES_I_NORGE',
    KAN_IKKE_SKRIVE_UT_MIDLERTIDIG_VOGNKORT = 'KAN_IKKE_SKRIVE_UT_MIDLERTIDIG_VOGNKORT',
    KAN_UTLEVERE_UTEN_REGISTRERING = 'KAN_UTLEVERE_UTEN_REGISTRERING',
    KJENNEMERKE_IKKE_PA_LAGER = 'KJENNEMERKE_IKKE_PA_LAGER',
    KJENNEMERKE_MA_BYTTES = 'KJENNEMERKE_MA_BYTTES',
    KJENNEMERKE_VETERAN_FUNNET_TAPT_INNLEVERT = 'KJENNEMERKE_VETERAN_FUNNET_TAPT_INNLEVERT',
    KJENNEMERKETS_TEGNKOMBINASJON_KAN_ENDRES = 'KJENNEMERKETS_TEGNKOMBINASJON_KAN_ENDRES',
    KJENNEMERKETS_TEGNKOMBINASJON_MA_ENDRES = 'KJENNEMERKETS_TEGNKOMBINASJON_MA_ENDRES',
    KJENNEMERKETYPE_KAN_ENDRES = 'KJENNEMERKETYPE_KAN_ENDRES',
    KJENNEMERKETYPE_MA_ENDRES = 'KJENNEMERKETYPE_MA_ENDRES',
    KJENNENMERKETYPE_VALIDERER_IKKE = 'KJENNENMERKETYPE_VALIDERER_IKKE',
    KJOPER_MELDT_DOD = 'KJOPER_MELDT_DOD',
    KJOPER_IKKE_MYNDIG = 'KJOPER_IKKE_MYNDIG',
    KJOPER_MELDT_FORSVUNNET = 'KJOPER_MELDT_FORSVUNNET',
    KJOPER_MELDT_FORSVUNNET_DNUMMER = 'KJOPER_MELDT_FORSVUNNET_DNUMMER',
    KJORETOYET_HAR_APNE_EIERSKIFTER = 'KJORETOYET_HAR_APNE_EIERSKIFTER',
    KJORETOY_AMBASSADE = 'KJORETOY_AMBASSADE',
    KJORETOY_BEGRAVELSESBIL = 'KJORETOY_BEGRAVELSESBIL',
    KJORETOY_EIERSKIFTE_FUNNET = 'KJORETOY_EIERSKIFTE_FUNNET',
    KJORETOY_EIERSKIFTE_HISTORIKK_HAR_MANGLER = 'KJORETOY_EIERSKIFTE_HISTORIKK_HAR_MANGLER',
    KJORETOY_ER_ETTERLYST = 'KJORETOY_ER_ETTERLYST',
    KJORETOY_ER_RALLY_MED_AVGIFTSFRITAK = 'KJORETOY_ER_RALLY_MED_AVGIFTSFRITAK',
    KJORETOY_ER_RALLY_UTEN_AVGIFTSFRITAK = 'KJORETOY_ER_RALLY_UTEN_AVGIFTSFRITAK',
    KJORETOY_HAR_BRUKSFORBUD = 'KJORETOY_HAR_BRUKSFORBUD',
    KJORETOY_HAR_IKKE_KRAV_TIL_FORSIKRING = 'KJORETOY_HAR_IKKE_KRAV_TIL_FORSIKRING',
    KJORETOY_HAR_KONTROLLSEDDEL_MED_BRUKSFORBUD = 'KJORETOY_HAR_KONTROLLSEDDEL_MED_BRUKSFORBUD',
    KJORETOY_HAR_KONTROLLSEDDEL_UTEN_BRUKSFORBUD = 'KJORETOY_HAR_KONTROLLSEDDEL_UTEN_BRUKSFORBUD',
    KJORETOY_HAR_MEDEIER = 'KJORETOY_HAR_MEDEIER',
    KJORETOY_HAR_MEDEIER_AVREGISTRERT = 'KJORETOY_HAR_MEDEIER_AVREGISTRERT',
    KJORETOY_HAR_UNNTAK_MILITAERTAKTISK = 'KJORETOY_HAR_UNNTAK_MILITAERTAKTISK',
    KJORETOY_HAR_UNNTAK_PROTOTYPE = 'KJORETOY_HAR_UNNTAK_PROTOTYPE',
    KJORETOY_HAR_UNNTAK_TRIAL_ENDURO = 'KJORETOY_HAR_UNNTAK_TRIAL_ENDURO',
    KJORETOY_IKKE_BETALT_PASKILTNINGSGEBYR = 'KJORETOY_IKKE_BETALT_PASKILTNINGSGEBYR',
    KJORETOY_IKKE_INNLEVERT_VOGNKORT_DEL_2 = 'KJORETOY_IKKE_INNLEVERT_VOGNKORT_DEL_2',
    KJORETOY_IKKE_NYLIG_REGISTRERT = 'KJORETOY_IKKE_NYLIG_REGISTRERT',
    KJORETOY_IKKE_REGISTRERT_PA_NY_EIER = 'KJORETOY_IKKE_REGISTRERT_PA_NY_EIER',
    KJORETOY_KJENNEMERKER_IKKE_TILGJENGELIGE = 'KJORETOY_KJENNEMERKER_IKKE_TILGJENGELIGE',
    KJORETOY_KLASSIFISERING_AMBULANSE = 'KJORETOY_KLASSIFISERING_AMBULANSE',
    KJORETOY_KLASSIFISERING_AMBULANSE_DOK = 'KJORETOY_KLASSIFISERING_AMBULANSE_DOK',
    KJORETOY_KRAV_OM_VEKTARSAVGIFT = 'KJORETOY_KRAV_OM_VEKTARSAVGIFT',
    KJORETOY_LAREVOGN = 'KJORETOY_LAREVOGN',
    KJORETOY_MANGLER_GYLDIG_FORSIKRING = 'KJORETOY_MANGLER_GYLDIG_FORSIKRING',
    KJORETOY_MANGLER_REGISTRERINGSAR = 'KJORETOY_MANGLER_REGISTRERINGSAR',
    KJORETOY_MANGLER_FORTOLLING = 'KJORETOY_MANGLER_FORTOLLING',
    KJORETOY_MANGLER_TOTALVEKT = 'KJORETOY_MANGLER_TOTALVEKT',
    KJORETOY_MANGLER_AVGIFTSKODE = 'KJORETOY_MANGLER_AVGIFTSKODE',
    KJORETOY_MANGLER_TEKNISK_KODE = 'KJORETOY_MANGLER_TEKNISK_KODE',
    KJORETOY_MANGLER_EGENVEKT = 'KJORETOY_MANGLER_EGENVEKT',
    KJORETOY_MANGLER_DRIVSTOFFKODE = 'KJORETOY_MANGLER_DRIVSTOFFKODE',
    KJORETOY_MIDL_AVREGISTRERT = 'KJORETOY_MIDL_AVREGISTRERT',
    KJORETOY_MIDL_AVREG_BRANSJE = 'KJORETOY_MIDL_AVREG_BRANSJE',
    KJORETOY_PAREGISTRERT_FOR_MINDRE_ENN_DEFINERT_ANTALL_DAGER_SIDEN = 'KJORETOY_PAREGISTRERT_FOR_MINDRE_ENN_DEFINERT_ANTALL_DAGER_SIDEN',
    KJORETOY_STJALET = 'KJORETOY_STJALET',
    KJORETOY_TRAKTOR_MED_BRUKSBEGRENSNING = 'KJORETOY_TRAKTOR_MED_BRUKSBEGRENSNING',
    KJORETOY_UTRYKNING = 'KJORETOY_UTRYKNING',
    KJORETOY_UTRYKNING_DOK = 'KJORETOY_UTRYKNING_DOK',
    KJORETOY_TRANSPORTFUNKSJONSHEMMET = 'KJORETOY_TRANSPORTFUNKSJONSHEMMET',
    KJORETOY_UNDERLAGT_PKK = 'KJORETOY_UNDERLAGT_PKK',
    KJORETOY_UTFORT = 'KJORETOY_UTFORT',
    KJORINGENS_ART_BEGRAVELSESBIL = 'KJORINGENS_ART_BEGRAVELSESBIL',
    KJORINGENS_ART_KONGEHUSET = 'KJORINGENS_ART_KONGEHUSET',
    KJORINGENS_ART_LOYVEPLIKTIG = 'KJORINGENS_ART_LOYVEPLIKTIG',
    KJORINGENS_ART_MOTORSPORT = 'KJORINGENS_ART_MOTORSPORT',
    KJORINGENS_ART_NORD_INVEST_BANK = 'KJORINGENS_ART_NORD_INVEST_BANK',
    KJORINGENS_ART_SVALBARD = 'KJORINGENS_ART_SVALBARD',
    KOMMUNIKASJON_MED_TFFAUTO_FEILET = 'KOMMUNIKASJON_MED_TFFAUTO_FEILET',
    KRAV_TIL_ERSTATNINGSKJENNEMERKE_I_POSTFORSENDELSE_IKKE_OPPFYLT = 'KRAV_TIL_ERSTATNINGSKJENNEMERKE_I_POSTFORSENDELSE_IKKE_OPPFYLT',
    ERSTATNINGSKJENNEMERKE_ALLE_KJENNEMERKER_IKKE_INNLEVERT = 'ERSTATNINGSKJENNEMERKE_ALLE_KJENNEMERKER_IKKE_INNLEVERT',
    KUNDE_STATUS_KONKURS = 'KUNDE_STATUS_KONKURS',
    MEDEIER_MELDT_FORSVUNNET = 'MEDEIER_MELDT_FORSVUNNET',
    MEDEIER_MELDT_FORSVUNNET_DNUMMER = 'MEDEIER_MELDT_FORSVUNNET_DNUMMER',
    NATO_DOK = 'NATO_DOK',
    NY_EIER_STATUS_KONKURS = 'NY_EIER_STATUS_KONKURS',
    NY_EIER_STATUS_SLETTET = 'NY_EIER_STATUS_SLETTET',
    NY_EIER_OG_NY_MEDEIER_IKKE_MYNDIG = 'NY_EIER_OG_NY_MEDEIER_IKKE_MYNDIG',
    TIDLIGERE_EIER_STATUS_SLETTET = 'TIDLIGERE_EIER_STATUS_SLETTET',
    OMREGISTRERING_KAN_IKKE_STARTES = 'OMREGISTRERING_KAN_IKKE_STARTES',
    ORGANISASJON_MANGLER_NORSK_ADRESSE = 'ORGANISASJON_MANGLER_NORSK_ADRESSE',
    PAGAENDE_ERSTATNINGKJM_BESTILLING_OPPRETTET_BRANSJE = 'PAGAENDE_ERSTATNINGKJM_BESTILLING_OPPRETTET_BRANSJE',
    PAREGISTRERING_KAN_IKKE_STARTES = 'PAREGISTRERING_KAN_IKKE_STARTES',
    PKK_GODKJENT = 'PKK_GODKJENT',
    TEKNISKE_ENDRINGER = 'TEKNISKE_ENDRINGER',
    TRAKTOR_FJERNET_BRUKSBEGRENSNING = 'TRAKTOR_FJERNET_BRUKSBEGRENSNING',
    TYPEGODKJENNING_IKKE_LENGER_GYLDIG = 'TYPEGODKJENNING_IKKE_LENGER_GYLDIG',
    UTENLANDSKE_KJENNEMERKER_IKKE_UTFYLT = 'UTENLANDSKE_KJENNEMERKER_IKKE_UTFYLT',
    UTENLANDSK_VOGNKORT_INFO_MANGLER = 'UTENLANDSK_VOGNKORT_INFO_MANGLER',
    UTENLANDSK_VOGNKORT_MANGLER = 'UTENLANDSK_VOGNKORT_MANGLER',
    UVERIFISERT_KUNDE = 'UVERIFISERT_KUNDE',
    VEDTAK_KAN_IKKE_FATTES = 'VEDTAK_KAN_IKKE_FATTES',
    MA_FJERNE_KNYTNING_TIL_PERSONLIG_KJENNEMERKE = 'MA_FJERNE_KNYTNING_TIL_PERSONLIG_KJENNEMERKE',
    ANTALL_KJENNEMERKER_HOS_BRANSJE_OPPFYLLER_IKKE_KRAV = 'ANTALL_KJENNEMERKER_HOS_BRANSJE_OPPFYLLER_IKKE_KRAV'
}

export enum RegelmerknaderType {
    INFORMASJON = 'INFORMASJON',
    KRITISK = 'KRITISK'
}
