import { AktorType } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';

import { Brukerrolle } from '../models/kodeverk';

import type { Brukerprofil, IAktor, IEierskifte, IKjoretoy, IKunde, RootStateType } from '../models/types';

export class BrukerRegler {
    private brukerprofil: Brukerprofil;
    public constructor(brukerprofil: Brukerprofil) {
        this.brukerprofil = brukerprofil;
    }

    public static adapter(state: Pick<RootStateType, 'brukerprofil'>): BrukerRegler {
        return new BrukerRegler(state.brukerprofil);
    }

    public harRolleEierskifte(): boolean {
        return this.brukerprofil.bruker && _.includes(this.brukerprofil.bruker.roller, Brukerrolle.EIERSKIFTE);
    }

    public harRolleOmreg(): boolean {
        return this.brukerprofil.bruker && _.includes(this.brukerprofil.bruker.roller, Brukerrolle.OMREG);
    }

    public harRolleForstegangsregistrering(): boolean {
        return this.brukerprofil.bruker && !_.isEmpty(_.intersection(this.brukerprofil.bruker?.roller, [Brukerrolle.FORSTEGANGSREGISTRERING, Brukerrolle.FREG]));
    }

    public harRolleForTilgangTilForstegangsregistrering(): boolean {
        return this.harRolleForstegangsregistrering() || this.harRolleFlateeier();
    }

    public harKjennemerkeroller(): boolean {
        return this.brukerprofil.bruker && !_.isEmpty(_.intersection(this.brukerprofil?.bruker?.roller, [Brukerrolle.KJSCM, Brukerrolle.OMREG]));
    }

    public harRolleOmregEllerEierskifte(): boolean {
        return this.harRolleEierskifte() || this.harRolleOmreg();
    }

    public harRolleOmregEierskifteEllerFlateeier(): boolean {
        return this.harRolleEierskifte() || this.harRolleOmreg() || this.harRolleFlateeier();
    }

    public harRolleForSok(): boolean {
        return this.harRolleEierskifte() || this.harRolleOmreg() || this.harRolleForstegangsregistrering() || this.harRolleFlateeier();
    }

    public harRolleFlateeier(): boolean {
        return this.brukerprofil.bruker && _.includes(this.brukerprofil.bruker.roller, Brukerrolle.FLATEEIER);
    }

    public erBrukerForhandler(): boolean {
        return this.brukerprofil.bruker && !_.isEmpty(
            _.intersection(this.brukerprofil.bruker.roller, [Brukerrolle.OMREG, Brukerrolle.AVSTEM, Brukerrolle.FORSTEGANGSREGISTRERING, Brukerrolle.KJSCM])
        );
    }

    public erForhandlerEier = (aktor: IKunde): boolean => {
        return (aktor && this.brukerprofil.valgtEnhet && aktor.organisasjonsnummer === this.brukerprofil.valgtEnhet.hovedenhetOrgNummer);
    };

    public erForhandlerSisteEier = (kjoretoy: IKjoretoy): boolean => {
        const { registrering } = kjoretoy;
        const { hovedenhetKundeId, underenhetKundeId } = this.brukerprofil.valgtEnhet;

        return registrering && (registrering.kundeIdRegistrertEier === hovedenhetKundeId || registrering.kundeIdRegistrertEier === underenhetKundeId);
    };

    public erForhandlerEierForEierskifte = (eierskifteFraState: IEierskifte, kjoretoy: IKjoretoy): boolean => {

        if (eierskifteFraState && eierskifteFraState.sakId) {
            const nyEierAktor = _.find(eierskifteFraState.aktorer, (aktor: IAktor) => {
                return aktor.aktorType === AktorType.NY_EIER;
            });

            return !nyEierAktor || nyEierAktor.organisasjonsnummer !== this.brukerprofil.valgtEnhet.hovedenhetOrgNummer;

        } else {
            return this.erForhandlerEier(kjoretoy.registrering.eier);
        }
    };

    public erEnAvEiereForhandler = (kjoretoy: IKjoretoy): boolean => {
        if (!kjoretoy.registrering || !this.brukerprofil.valgtEnhet) {
            return false;
        }
        const { eier, medeier } = kjoretoy.registrering;
        const { hovedenhetOrgNummer, underenhetOrgNummer } = this.brukerprofil.valgtEnhet;
        const eiere = [eier, medeier];

        return eiere.some((aktor: IKunde) =>
            aktor.organisasjonsnummer === hovedenhetOrgNummer || aktor.organisasjonsnummer === underenhetOrgNummer
        );
    };

    public erEierUnderenhet(kjoretoy: IKjoretoy): boolean {
        const { registrering } = kjoretoy;
        return registrering
            && registrering.eier
            && registrering.eier.organisasjonsnummer !== registrering.eier.juridiskEnhetNummer;
    }
}
