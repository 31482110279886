/* eslint max-lines: ['off'] */
import * as _ from 'lodash';
import type {
    IMessageDescriptor,
    IVilkar} from 'svv-tk-akr-common-frontend';
import {
    DROSJELOYVENUMMER,
    KjennemerkeTypeEnum,
    SaksdokumentType,
    VilkarStatus
} from 'svv-tk-akr-common-frontend';

import {
    Feilkoder,
    MERKNAD_FOR_FULLFOR_PA_TRAFIKKSTASJON,
    MERKNADER_SOM_HINDRER_START_OMREGISTRERING,
    MERKNADER_SOM_HINDRER_START_PAREGISTRERING,
    MERKNADER_SOM_IKKE_SKAL_VISES_I_SKJERMBILDET,
    RegistreringKundeType,
    RegistreringStatus
} from '../constants';
import {
    KjoringensArt,
    KodeType,
    RegelmerknaderKoder,
    RegelmerknaderKontekst,
    RegelmerknaderType,
    RegistreringsstatusKode,
    Saksstatus,
    SaksType,
    Vilkarsnavn
} from '../models/kodeverk';
import type {
    Brukerprofil,
    IAktor,
    IFullforPaTrafikkstasjonGrunnlag,
    IKjoretoy,
    IKjoringensArt,
    IKunde,
    IMerknad,
    IObligatoriskDokument,
    IRegistreringInformasjon,
    IRegistreringSak,
    IRootState,
    ISak,
    IVilkarstatus
} from '../models/types';
import {vilkarForRegistreringSelector} from '../state/selectors';
import {
    erKlassifisertSomLarevognEllerKjoringensArtListeInneholderKunLarevogn,
    erUnderenhetInnlogget,
    mapeSaksdokumenterStatusTilObligatoriskeDokumenter,
    TALL_FRA_OG_MED_NULL,
    utledValgtKjoringensArt
} from '../utils';
import {BrukerRegler} from './bruker-regler';
import {KjennemerkeRegler} from './kjennemerke-regler';
import {KjoretoyRegler} from './kjoretoy-regler';
import {Merknader} from './merknader';
import {TilhengervektRegler} from './tilhengervekt-regler';
import {VilkarRegler} from './vilkar-regler';

const MERKNADER_SOM_SKAL_VISES_SOM_MELDING_FRA_VILKARSTATUS_MERKNADER: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.KJORETOY_LAREVOGN, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_TRAKTOR_MED_BRUKSBEGRENSNING },
    { kode: RegelmerknaderKoder.TRAKTOR_FJERNET_BRUKSBEGRENSNING }
];

const MERKNADER_SOM_SKAL_GI_BETALINGSMULIGHET = [
    { kode: RegelmerknaderKoder.KJENNEMERKETYPE_MA_ENDRES },
    { kode: RegelmerknaderKoder.KJENNEMERKETS_TEGNKOMBINASJON_MA_ENDRES }
];

const DOKUMENTTYPER_SOM_MA_LASTES_OPP_I_ANNEN_KANAL = [SaksdokumentType.EGENERKLARING_OPPRETTE_BRUKSBEGRENSNING, SaksdokumentType.EGENERKLARING_FJERNE_BRUKSBEGRENSNING];

export class RegistreringRegler {
    public static vilkarForRegistrering = (kjoretoy: IKjoretoy, vilkarstatus: IVilkarstatus, registreringSak: IRegistreringSak): IVilkar[] => {
        const vilkar: IVilkar[] = [];
        const forsikringsvilkar = _.find(vilkarstatus.vilkar, (item) => item.navn === 'FORSIKRING');
        const avgiftsvilkar = _.find(vilkarstatus.vilkar, (item) => item.navn === 'AVGIFT');
        const kjennemerkevilkar = _.find(vilkarstatus.vilkar, (item) => item.navn === 'KJENNEMERKE');
        const obligatoriskeDokumenter = !!registreringSak.obligatoriskeDokumenter ? registreringSak.obligatoriskeDokumenter : [];

        if (registreringSak.saksstatus !== Saksstatus.AVSLUTTET && vilkarstatus && !_.isEmpty(avgiftsvilkar)) {
            vilkar.push(VilkarRegler.utledVilkar(avgiftsvilkar));
        }

        if (VilkarRegler.kjoretoyHarKravTilForsikring(kjoretoy.merknader) && !_.isEmpty(forsikringsvilkar)) {
            vilkar.push(VilkarRegler.utledVilkar(forsikringsvilkar));
        }

        if (new KjoretoyRegler(kjoretoy).kreverKjennemerkebytte() && !_.isEmpty(kjennemerkevilkar)) {
            vilkar.push(VilkarRegler.utledVilkar(kjennemerkevilkar));
        }

        if (VilkarRegler.erKjoretoyValidertAvGjennomfortPkk(vilkarstatus)) {
            vilkar.push(VilkarRegler.utledVilkarForPkk(vilkarstatus));
        }

        if (registreringSak.saksstatus !== Saksstatus.AVSLUTTET && registreringSak.saksstatus !== Saksstatus.AVBRUTT) {
            if (obligatoriskeDokumenter.length && registreringSak.arkivdokumenter) {
                const saksdokumenter = _.flatMap(registreringSak.arkivdokumenter.map((arkivdokument) => arkivdokument.saksdokumenter));
                const saksdokumenterMappingTilobligatoriskeDokumenter = mapeSaksdokumenterStatusTilObligatoriskeDokumenter(saksdokumenter, obligatoriskeDokumenter);
                vilkar.push(VilkarRegler.utledVilkarForDokumenter(saksdokumenterMappingTilobligatoriskeDokumenter));
            } else {
                vilkar.push(VilkarRegler.utledVilkarForDokumenter(obligatoriskeDokumenter));
            }
        }

        vilkar.push(VilkarRegler.utledVilkarForDrosjeloyvenummer(registreringSak.kjoringensArt, registreringSak.drosjeloyvenummer, vilkar));

        return _.compact(vilkar);
    };

    public static utledRegistreringStatus = (
        kjoretoy: IKjoretoy,
        sak: ISak<IRegistreringInformasjon>,
        vilkarstatus: IVilkarstatus,
        registreringSak: IRegistreringSak,
        kanStarteOmregistreringssak: boolean
    ): RegistreringStatus => {
        const vilkar = RegistreringRegler.vilkarForRegistrering(kjoretoy, vilkarstatus, registreringSak);

        const omregistrering = kanStarteOmregistreringssak || sak.saksType === SaksType.REGISTRERING_NY_EIER;
        const harUtestaendeAvgifter = _.some(vilkar, (item) => item.navn === Vilkarsnavn.AVGIFT && item.status === VilkarStatus.IKKE_OPPFYLT);
        const forsikringsstatus = _.find(vilkar, (item) => item.navn === Vilkarsnavn.FORSIKRING);
        const kjennemerkestatus = _.find(vilkar, (item) => item.navn === Vilkarsnavn.KJENNEMERKE);
        const drosjeLoyvenummerStatus = _.find(vilkar, (item) => item.navn === Vilkarsnavn.DROSJELOYVE);
        const dokumentstatus = _.find(vilkar, (item) => item.navn === Vilkarsnavn.DOKUMENTASJON);
        const sakOpprettet = !!sak.sakId;
        const merknader = _.filter(
            kjoretoy.merknader,
            (merknad) => {
                return merknad.kode !== RegelmerknaderKoder.KJORETOY_MANGLER_GYLDIG_FORSIKRING.valueOf()
                    && merknad.kode !== RegelmerknaderKoder.PAGAENDE_ERSTATNINGKJM_BESTILLING_OPPRETTET_BRANSJE.valueOf();
            }
        );

        const kanIkkeStarteBetalingOmregistrering =
            omregistrering && Merknader.exists(merknader, { kontekst: RegelmerknaderKontekst.AKR_REGLER_START_REGISTRERING_NY_EIER, type: RegelmerknaderType.KRITISK });
        const kanIkkeStarteBetalingParegistrering =
            !omregistrering && Merknader.exists(merknader, { kontekst: RegelmerknaderKontekst.AKR_REGLER_START_REGISTRERING_SAMME_EIER, type: RegelmerknaderType.KRITISK });

        const harMerkanderForBetaling = Merknader.anyMatch(merknader, MERKNADER_SOM_SKAL_GI_BETALINGSMULIGHET);

        if (
            VilkarRegler.kjoretoyManglerGyldigVilkar(forsikringsstatus) ||
            VilkarRegler.kjoretoyManglerGyldigVilkar(dokumentstatus) ||
            VilkarRegler.kjoretoyManglerGyldigVilkar(drosjeLoyvenummerStatus) ||
            (VilkarRegler.kjoretoyManglerGyldigVilkar(kjennemerkestatus) && !harMerkanderForBetaling) ||
            kanIkkeStarteBetalingOmregistrering ||
            kanIkkeStarteBetalingParegistrering
        ) {
            return RegistreringStatus.VARSLER;
        }

        if (sak.saksstatus === Saksstatus.AVSLUTTET) {
            return RegistreringStatus.REGISTRERT;
        }

        if (harUtestaendeAvgifter && !sakOpprettet) {
            return RegistreringStatus.GA_TIL_BETALING;
        }

        if (harUtestaendeAvgifter && sakOpprettet) {
            return RegistreringStatus.OPPDATER;
        }

        return RegistreringStatus.REGISTRER;
    };

    public static kanStarteUtleveringUtenRegistrering(brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): boolean {
        return (
            new BrukerRegler(brukerprofil).harRolleOmreg() &&
            Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.KAN_UTLEVERE_UTEN_REGISTRERING
            })
        );
    }

    public static kanStarteMidlertidigAvregistrering(brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): boolean {
        const registreringsstatus = kjoretoy.registrering.registreringsinformasjon.registreringsstatus;
        const kjoretoyRegler = new KjoretoyRegler(kjoretoy);
        const brukerRegler = new BrukerRegler(brukerprofil);
        return (
            brukerRegler.erForhandlerEier(kjoretoy.registrering.eier) &&
            registreringsstatus === RegistreringsstatusKode.REGISTRERT &&
            !kjoretoyRegler.erKjoretoyStjaletMedKontekstEierskifte() &&
            brukerRegler.harRolleOmreg() &&
            !kjoretoyRegler.erKjoretoyPrototype()
        );
    }

    public static hindrerMerknadStartAvOmregistrering(merknad: IMerknad): boolean {
        return _.some(MERKNADER_SOM_HINDRER_START_OMREGISTRERING, Merknader.filterForMerknad(merknad));
    }

    public static kanStarteOmregistreringssak = (
        kjoretoy: IKjoretoy,
        merknader = kjoretoy.merknader,
        registrertEier: boolean = RegistreringRegler.erRegistrertPaEier(kjoretoy)
    ): boolean => {
        return !Merknader.exists(merknader, RegistreringRegler.hindrerMerknadStartAvOmregistrering) && !registrertEier;
    };

    public static hindrerMerknadStartAvParegistrering(merknad: IMerknad): boolean {
        return _.some(MERKNADER_SOM_HINDRER_START_PAREGISTRERING, Merknader.filterForMerknad(merknad));
    }

    public static kanStarteParegistreringssak = (
        kjoretoy: IKjoretoy,
        merknader = kjoretoy.merknader,
        registrertEier: boolean = RegistreringRegler.erRegistrertPaEier(kjoretoy)
    ): boolean => {
        return !Merknader.exists(merknader, RegistreringRegler.hindrerMerknadStartAvParegistrering) && registrertEier;
    };

    public static erOmregistrering = (kjoretoy: IKjoretoy): boolean => {
        return !Merknader.exists(kjoretoy, { kode: RegelmerknaderKoder.OMREGISTRERING_KAN_IKKE_STARTES });
    };

    public static utledFeilmeldinger = (merknader: IMerknad[]): IMessageDescriptor[] => {
        return KjennemerkeRegler.kjennemerkeTypeMaEndresPaTrafikkstasjon(merknader)
            ? [{ id: 'registrering.melding.KJENNEMERKETYPE_MA_ENDRES' }]
            : [];
    };

    public static utledInfomeldinger(kjoretoy: IKjoretoy, sak: ISak, vilkarstatus: IVilkarstatus): IMessageDescriptor[] {
        return RegistreringRegler.utledMeldinger(new KjoretoyRegler(kjoretoy).erMerknadBareTilInfo, kjoretoy, sak, vilkarstatus);
    }

    public static utledMeldinger(filter: (IMerknad) => boolean, kjoretoy: IKjoretoy, sak: ISak, vilkarstatus: IVilkarstatus): IMessageDescriptor[] {
        const kjoretoyRegler = new KjoretoyRegler(kjoretoy);
        const merknader = Merknader.find(kjoretoy.merknader || [], RegistreringRegler.merknadSkalIkkeVisesISkjermbildet);
        const meldinger = _.chain(merknader).filter(filter).map(kjoretoyRegler.meldingFraMerknad).uniqWith(_.isEqual).value() || [];

        if (RegistreringRegler.skalKunBrukesUtenforOffentligVeg(kjoretoy)) {
            meldinger.push({ id: 'registrering.melding.kunUtenforOffentligVei' });
        }

        if (kjoretoyRegler.kreverKjennemerkebytte() && !KjennemerkeRegler.erAlleKjennemerkerGjortRedeFor(kjoretoy)) {
            meldinger.push({ id: 'registrering.melding.kjennemerkerMaGjoresRedeFor' });
        }

        if (RegistreringRegler.skalKunVisesOmSakErTilGodkjenning(sak)) {
            meldinger.push({ id: 'registrering.melding.sendTilSaksb' });
        }

        if (kjoretoyRegler.erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt()) {
            meldinger.push({ id: 'registrering.melding.KJORINGENS_ART_LOYVEPLIKTIG' });
        }

        meldinger.push(...RegistreringRegler.infoMeldingerFraVilkar(vilkarstatus));

        return meldinger;
    }

    public static harMerknadForKjennemerkerBestilt(kjoretoy: IKjoretoy): boolean {
        return Merknader.exists(kjoretoy.merknader, { kode: RegelmerknaderKoder.ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING });
    }

    public static merknadSkalIkkeVisesISkjermbildet(merknad: IMerknad): boolean {
        return !_.some(MERKNADER_SOM_IKKE_SKAL_VISES_I_SKJERMBILDET, Merknader.filterForMerknad(merknad));
    }

    public static erKjennemerkerUtilgjengelige = (kjoretoy: IKjoretoy): boolean => {
        const omregistreringKjennemerkUtilgjengelig = Merknader.exists(kjoretoy, {
            kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER,
            kode: RegelmerknaderKoder.KJORETOY_KJENNEMERKER_IKKE_TILGJENGELIGE
        });

        const paregistreringKjennemerkeUtilgjengelig = Merknader.exists(kjoretoy, {
            kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_SAMME_EIER,
            kode: RegelmerknaderKoder.KJORETOY_KJENNEMERKER_IKKE_TILGJENGELIGE
        });

        return omregistreringKjennemerkUtilgjengelig || paregistreringKjennemerkeUtilgjengelig;
    };

    public static erSkyldigPaskiltningsgebyr = (kjoretoy: IKjoretoy): boolean => {
        const omregistreringIkkeBetaltPaskiltningsgebyr = Merknader.exists(kjoretoy, {
            kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER,
            kode: RegelmerknaderKoder.KJORETOY_IKKE_BETALT_PASKILTNINGSGEBYR
        });

        const paregistreringIkkeBetaltPaskiltningsgebyr = Merknader.exists(kjoretoy, {
            kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_SAMME_EIER,
            kode: RegelmerknaderKoder.KJORETOY_IKKE_BETALT_PASKILTNINGSGEBYR
        });

        return omregistreringIkkeBetaltPaskiltningsgebyr || paregistreringIkkeBetaltPaskiltningsgebyr;
    };

    public static utledMeldingerForKjennemerkerSomErInnlevert(kjoretoy: IKjoretoy): IMessageDescriptor[] {
        const meldinger = [];

        if (RegistreringRegler.erKjennemerkerUtilgjengelige(kjoretoy)) {
            meldinger.push(new KjoretoyRegler(kjoretoy).utledKjennemerkeinformasjon());

            if (RegistreringRegler.erSkyldigPaskiltningsgebyr(kjoretoy)) {
                meldinger.push({ id: 'registrering.modal.paskiltingsgebyr' });
            }
            meldinger.push(RegistreringRegler.utledUtforesPaTrafikkstasjonMelding(kjoretoy));
        }
        return meldinger;
    }

    public static skalViseRegistreringsKnapper(brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): boolean {
        const apneEierskifter = kjoretoy.apneEierskifter || [];

        return new KjoretoyRegler(kjoretoy).harKjoretoyRegistrering() && kjoretoy.registrering.kuid && !apneEierskifter.length && new BrukerRegler(brukerprofil).harRolleOmreg();
    }

    public static kanBehandleRegistrering(brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): boolean {
        const kjoretoyRegler = new KjoretoyRegler(kjoretoy);
        const brukerRegler = new BrukerRegler(brukerprofil);
        return (
            !brukerRegler.erEierUnderenhet(kjoretoy) &&
            kjoretoyRegler.harKjoretoyRegistrering() &&
            !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.AVDELING_HAR_IKKE_RETTIGHETER,
                kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_REGISTRERING
            }) &&
            !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.KJORETOY_IKKE_INNLEVERT_VOGNKORT_DEL_2,
                kontekst: RegelmerknaderKontekst.AKR_REGLER_OMREGISTRERING
            }) &&
            !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.KJORETOY_UTFORT
            }) &&
            !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.EIER_UVERIFISERT_KUNDE,
                kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_KUNDE
            }) &&
            !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.FORHANDLER_ER_INHABIL,
                kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_TILGANG
            }) &&
            !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.UVERIFISERT_KUNDE,
                kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_REGISTRERING
            }) &&
            !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.EIER_UGYLDIG_PERSONSTATUS,
                kontekst: RegelmerknaderKontekst.AKR_REGLER_START_REGISTRERING_SAMME_EIER
            })
        );
    }

    public static erSlettetOrganisasjonLeasingtaker(feilkode: Feilkoder, kundeType: RegistreringKundeType): boolean {
        return feilkode === Feilkoder.ORGANISASJON_SLETTET && kundeType === RegistreringKundeType.NY_LEASINGTAKER;
    }

    public static utledSkjemaErrorsInkluderKjoringensArt(sak: IRegistreringSak, eier: IKunde, kjoretoy: IKjoretoy): string[] {
        const errors = this.utledSkjemaErrorsIgnorerKjoringensArtHvisIkkeNull(sak, eier, kjoretoy);

        if (_.some(sak.merknader, (merknad) => merknad.kode === 'KJORINGENS_ART_UGYLDIG')) {
            errors.push('registrering.validering.ugyldigKjoringensArt');
        }
        return errors;
    }

    public static utledSkjemaErrorsIgnorerKjoringensArtHvisIkkeNull(sak: IRegistreringSak, eier: IKunde, kjoretoy: IKjoretoy): string[] {
        const errors = [];
        const grunnlagVektarsavgift = sak.grunnlagVektarsavgift || {};

        if (!sak.kjoringensArt) {
            errors.push('registrering.validering.manglerKjoringensArt');
        }

        if (
            grunnlagVektarsavgift.skalTrekkeTilhenger &&
            !(this.validateAntallAksler(grunnlagVektarsavgift.antallAkslerTilhenger || '') && this.validateTotalvektTilhenger(kjoretoy, grunnlagVektarsavgift.totalvektTilhenger || ''))
        ) {
            errors.push('registrering.validering.ikkeTattStilling');
        }

        if (sak.saksType === SaksType.REGISTRERING_NY_EIER && !sak.kjoringensArt) {
            errors.push('registrering.validering.velgKjoringensArt');
        }

        // Tom string indikerer at panelet for leasingtaker/underenhet er åpent (mulighet til å søke på kunde),
        // men at leasingtaker/underenhet ikke er valgt
        if (
            sak.leasingtaker === '' ||
            sak.underenhet === '' ||
            RegistreringRegler.erDrosjeLoyvenummerUgyldig(sak) ||
            (this.erKravOmVektarsavgift(kjoretoy) &&
                TilhengervektRegler.overskriderOppgittTilhengervektTillattTilhengervekt(kjoretoy.godkjenning, grunnlagVektarsavgift.totalvektTilhenger))
        ) {
            errors.push('registrering.validering.skjemafeil');
        }

        // Andre delen av uttryket indikerer at adresse ikke er fylt inn i skjermbildet
        if (RegistreringRegler.aktorHarUgyldigAdresse(eier as IAktor) && !sak?.vognkortsaksinformasjon?.forsendelsesadresse?.adresselinje1) {
            errors.push('registrering.validering.ugyldigAdresse');
        }

        return errors;
    }

    public static erDrosjeLoyvenummerUgyldig = (updatedSak: IRegistreringSak): boolean => {
        return updatedSak.kjoringensArt === KjoringensArt.DROSJE_EIER.valueOf() && !RegistreringRegler.validateDrosjeLoyvenummer(updatedSak.drosjeloyvenummer);
    };

    public static erKravOmVektarsavgift = (kjoretoy: IKjoretoy, merknader = kjoretoy.merknader): boolean => Merknader.exists(merknader, { kode: RegelmerknaderKoder.KJORETOY_KRAV_OM_VEKTARSAVGIFT });

    public static erReglerbruddForLeasingtaker(merknad: IMerknad): boolean {
        return (
            merknad.kode === RegelmerknaderKoder.NY_EIER_STATUS_KONKURS.valueOf() ||
            merknad.kode === RegelmerknaderKoder.KJOPER_MELDT_DOD.valueOf() ||
            merknad.kode === RegelmerknaderKoder.KJOPER_MELDT_FORSVUNNET.valueOf() ||
            merknad.kode === RegelmerknaderKoder.KJOPER_MELDT_FORSVUNNET_DNUMMER.valueOf() ||
            merknad.kode === RegelmerknaderKoder.NY_EIER_STATUS_SLETTET.valueOf()
        );
    }

    public static finnKritiskeSaksmerknader(merknader: IMerknad[], kjoringensArt?: string): IMerknad[] {
        const merknadskoderSak = [
            RegelmerknaderKoder.KJENNEMERKE_MA_BYTTES,
            RegelmerknaderKoder.MA_FJERNE_KNYTNING_TIL_PERSONLIG_KJENNEMERKE,
            RegelmerknaderKoder.VEDTAK_KAN_IKKE_FATTES,
            RegelmerknaderKoder.ANTALL_KJENNEMERKER_HOS_BRANSJE_OPPFYLLER_IKKE_KRAV
        ];

        if (kjoringensArt === KjoringensArt.UTENFOR_OFF_VEI.valueOf()) {
            merknadskoderSak.push(RegelmerknaderKoder.KJENNENMERKETYPE_VALIDERER_IKKE);
        }

        const kritiskeMerknader = _.filter(merknader, (merknad) => merknad.type === RegelmerknaderType.KRITISK && _.isEmpty(merknad.obligatoriskeDokumenter));

        return Merknader.find(kritiskeMerknader, (merknad) => _.includes(merknadskoderSak, merknad.kode));
    }

    public static utledMerknaderForLeasingtakerEndring(merknad: IMerknad): IMessageDescriptor {
        return { id: `registrering.validering.leasingtaker.${merknad?.kode}` };
    }

    public static utledMerknaderForRegistrering(kjoretoy: IKjoretoy, sak: ISak<IRegistreringInformasjon>): IMerknad[] {
        const koder = [
            RegelmerknaderKoder.KJOPER_MELDT_DOD,
            RegelmerknaderKoder.KJORETOY_ER_ETTERLYST,
            RegelmerknaderKoder.KJORETOY_EIERSKIFTE_HISTORIKK_HAR_MANGLER,
            RegelmerknaderKoder.KUNDE_STATUS_KONKURS,
            RegelmerknaderKoder.KJORETOY_STJALET,
            RegelmerknaderKoder.EIER_MELDT_FORSVUNNET,
            RegelmerknaderKoder.MEDEIER_MELDT_FORSVUNNET,
            RegelmerknaderKoder.MEDEIER_MELDT_FORSVUNNET_DNUMMER,
            RegelmerknaderKoder.EIER_MELDT_FORSVUNNET_DNUMMER,
            RegelmerknaderKoder.ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING
        ];

        const filterFn = (merknad: IMerknad) => (kode: RegelmerknaderKoder) => kode.toString() === merknad.kode;

        if (RegistreringRegler.kanStarteOmregistreringssak(kjoretoy) || sak.saksType === SaksType.REGISTRERING_NY_EIER) {
            return Merknader.find(kjoretoy, (merknad) => {
                return Merknader.inContext(merknad, RegelmerknaderKontekst.AKR_REGLER_START_REGISTRERING_NY_EIER) && koder.filter(filterFn(merknad)).length > 0;
            });
        } else if (RegistreringRegler.kanStarteParegistreringssak(kjoretoy) || sak.saksType === SaksType.REGISTRERING_SAMME_EIER) {
            return Merknader.find(kjoretoy, (merknad) => {
                return Merknader.inContext(merknad, RegelmerknaderKontekst.AKR_REGLER_START_REGISTRERING_SAMME_EIER) && koder.filter(filterFn(merknad)).length > 0;
            });
        }
    }

    public static utledMeldingerUgyldigAdresse(saksinformasjon: Partial<IRegistreringInformasjon>): IMessageDescriptor[] {
        const merknader: IMessageDescriptor[] = [];
        const aktor = saksinformasjon.nyEier;
        const aktorHarUgyldigAdresse = this.aktorHarUgyldigAdresse(aktor);
        const manglerForsendelseAdresse = saksinformasjon.vognkortsaksinformasjon && !saksinformasjon.vognkortsaksinformasjon.forsendelsesadresse;

        if (aktorHarUgyldigAdresse && manglerForsendelseAdresse) {
            merknader.push({ id: 'registrering.melding.EIER_MANGLER_SAKSADRESSE' });
        }

        return merknader;
    }

    public static utledGrunnlagForAFullforeRegistreringPaTrafikkstasjon(kjoretoy: IKjoretoy, sak: ISak<IRegistreringInformasjon>, vilkarstatus: IVilkarstatus): IFullforPaTrafikkstasjonGrunnlag {
        const merknader = this.hentMerknaderForAFullforeRegistreringPaTrafikkstasjon(kjoretoy, sak)
            .filter((melding) => melding.kode !== RegelmerknaderKoder.KJORETOY_KJENNEMERKER_IKKE_TILGJENGELIGE.valueOf());
        const meldinger = RegistreringRegler.utledMeldingerForKjennemerkerSomErInnlevert(kjoretoy);
        const vilkar = RegistreringRegler.vilkarForRegistrering(kjoretoy, vilkarstatus, sak);
        const manglerDokumentasjon = VilkarRegler.kjoretoyManglerGyldigVilkar(_.find(vilkar, (item) => item.navn === Vilkarsnavn.DOKUMENTASJON));

        if (manglerDokumentasjon) {
            return {
                merknader: [],
                meldinger: [],
                erFullforPaTrafikkstasjon: false
            };
        }

        return {
            merknader,
            meldinger,
            erFullforPaTrafikkstasjon: merknader.length > 0 || meldinger.length > 0 || sak.internSaksbehandlingPakrevet
        };
    }

    public static aktorHarUgyldigAdresse(aktor: IAktor): boolean {
        return aktor?.kundeId && typeof aktor.harGyldigAdresse === 'boolean' && !aktor.harGyldigAdresse;
    }

    public static forhandlerErUnderenhetOgEierKjoretoyUtenUnderenhet(brukerprofil: Brukerprofil, eier: IKunde, underenhet?: IKunde): boolean {
        return new BrukerRegler(brukerprofil).erForhandlerEier(eier) && !(underenhet && underenhet.kundeId) && erUnderenhetInnlogget(brukerprofil.valgtEnhet);
    }

    public static utledDefaultNeringskode(kunde: IKunde): KodeType | null {
        if (kunde.organisasjonsnummer && kunde.neringskoder && kunde.neringskoder.length) {
            return new KodeType(kunde.neringskoder[0]);
        }
        return null;
    }

    private static finnesEgentransportIKjoringensArtListe(listeOverKjoringensArt: IKjoringensArt[]): boolean {
        return (listeOverKjoringensArt || []).some((ka) => ka.name === KjoringensArt.EGENTRANSP_ANNEN.valueOf());
    }

    public static utledDefaultKjoringensArt(kunde: IKunde, saksType: SaksType, gjeldendeKjoringensArtListe: IKjoringensArt[], kjoretoy: IKjoretoy): KjoringensArt | null {
        if (gjeldendeKjoringensArtListe?.length === 1 && gjeldendeKjoringensArtListe[0]?.name === KjoringensArt.KUN_SVALBARD.valueOf()) {
            return KjoringensArt.KUN_SVALBARD;
        }

        if (RegistreringRegler.skalKunBrukesUtenforOffentligVeg(kjoretoy)) {
            return KjoringensArt.UTENFOR_OFF_VEI;
        }

        if (erKlassifisertSomLarevognEllerKjoringensArtListeInneholderKunLarevogn(kjoretoy, gjeldendeKjoringensArtListe)) {
            return KjoringensArt.LAREVOGN;
        }

        if (saksType === SaksType.REGISTRERING_NY_EIER && this.finnesEgentransportIKjoringensArtListe(gjeldendeKjoringensArtListe)) {
            return KjoringensArt.EGENTRANSP_ANNEN;
        }

        if (saksType === SaksType.REGISTRERING_SAMME_EIER) {
            const sisteKjoringensArt = kjoretoy.registrering.bruksinformasjon.kjoringensArt;

            if (sisteKjoringensArt.kode === KjoringensArt.DROSJE_RESERVE.valueOf()) {
                return KjoringensArt.DROSJE_EIER;
            } else if (_.some(gjeldendeKjoringensArtListe, (kjoringensArt) => kjoringensArt.name === sisteKjoringensArt.kode)) {
                return KjoringensArt[sisteKjoringensArt.kode];
            } else {
                return null;
            }
        }

        return utledValgtKjoringensArt(null, kunde, kjoretoy, gjeldendeKjoringensArtListe) as KjoringensArt;
    }

    public static utledDefaultNyUnderenhet(eier: IKunde, underenhet: IKunde, brukerprofil: Brukerprofil): string {
        if (RegistreringRegler.forhandlerErUnderenhetOgEierKjoretoyUtenUnderenhet(brukerprofil, eier)) {
            return brukerprofil.valgtEnhet.underenhetKundeId;
        }
        if (underenhet) {
            return underenhet.kundeId;
        }
        return null;
    }

    public static nyUnderenhetUlikInnloggetAvdeling(brukerprofil: Brukerprofil, underenhet: string): boolean {
        const { valgtEnhet } = brukerprofil;
        return erUnderenhetInnlogget(valgtEnhet) && valgtEnhet.underenhetKundeId !== (underenhet || '').toString();
    }

    public static nyEierErInnloggetForhandlerSinHovedenhet(brukerprofil: Brukerprofil, eier: IKunde): boolean {
        return brukerprofil.valgtEnhet.hovedenhetOrgNummer === eier.organisasjonsnummer;
    }

    public static erRegistrertPaEier(kjoretoy: IKjoretoy): boolean {
        return kjoretoy && kjoretoy.registrering && kjoretoy.registrering.eier && kjoretoy.registrering.registreringsinformasjon.paregistrertInneverendeEier;
    }

    public static harMerknadSomKreverFullforingPaTrafikkstasjon = (kjoretoy: IKjoretoy, merknader?: IMerknad[]): boolean =>
        Merknader.exists(merknader || kjoretoy.merknader, RegistreringRegler.erMerknadForAFullforeRegistreringPaTrafikkstasjon);

    public static skalKunBrukesUtenforOffentligVeg = (kjoretoy: IKjoretoy): boolean =>
        _.includes(kjoretoy.godkjenning.unntak, 'KUN_UTENFOR_OFFENTLIG_VEI') ||
        (new KjoretoyRegler(kjoretoy).harKjoretoyRegistrering() && kjoretoy.registrering.kjennemerke.kjennemerkeType === KjennemerkeTypeEnum.UTENFOR_OFFENTLIG_VEG);

    public static skalKunVisesOmSakErTilGodkjenning = (sak: ISak): boolean => {
        return sak?.erTilGodkjenning;
    };

    public static enEllerFlerObliDokErIkkeOpplastet(vilkarListe: IVilkar[]): boolean {
        return _.some(vilkarListe, (vilkar) => vilkar.navn === Vilkarsnavn.DOKUMENTASJON && vilkar.status === VilkarStatus.IKKE_OPPFYLT);
    }

    public static alleObliDokErOppfylt(state: IRootState): boolean {
        const vilkarListe = vilkarForRegistreringSelector(state);
        return _.some(vilkarListe, (vilkar) => vilkar.navn === Vilkarsnavn.DOKUMENTASJON && vilkar.status === VilkarStatus.OPPFYLT);
    }

    private static infoMeldingerFraVilkar(vilkarstatus: IVilkarstatus): IMessageDescriptor[] {
        return [
            ...vilkarstatus.merknader
                .filter((merknad: IMerknad) => RegistreringRegler.infomerknadSomSkalVisesSomMeldingFraVilkarstatusMerknader(merknad))
                .map((merknad: IMerknad) => ({ id: `registrering.melding.${merknad.kode}` }))
        ];
    }

    private static infomerknadSomSkalVisesSomMeldingFraVilkarstatusMerknader(merknad: IMerknad): boolean {
        return _.some(MERKNADER_SOM_SKAL_VISES_SOM_MELDING_FRA_VILKARSTATUS_MERKNADER, Merknader.filterForMerknad(merknad));
    }

    public static dokumenterSomKanLastesOpp(dok: IObligatoriskDokument[]): IObligatoriskDokument[] {
        return !dok ? [] : dok.filter((value) => !RegistreringRegler.dokumentMaLastesOppIAnnenKanal(value));
    }

    private static dokumentMaLastesOppIAnnenKanal(dok: IObligatoriskDokument): boolean {
        return _.some(DOKUMENTTYPER_SOM_MA_LASTES_OPP_I_ANNEN_KANAL, (type) => dok.saksdokumentType === type.valueOf());
    }

    private static hentMerknaderForAFullforeRegistreringPaTrafikkstasjon(kjoretoy: IKjoretoy, sak: ISak<IRegistreringInformasjon>): IMerknad[] {
        const kjoretoyRegler = new KjoretoyRegler(kjoretoy);
        const merknader = Merknader.find(kjoretoy, (merknad) => {
            return (
                (Merknader.inContext(merknad, RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_SAMME_EIER) ||
                    Merknader.inContext(merknad, RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER)) &&
                this.erMerknadForAFullforeRegistreringPaTrafikkstasjon(merknad)
            );
        });

        if (RegistreringRegler.kanStarteOmregistreringssak(kjoretoy) || sak.saksType === SaksType.REGISTRERING_NY_EIER) {
            const saksmerknader = Merknader.find(sak.merknader, (merknad) => {
                return (
                    Merknader.inContext(merknad, RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER) &&
                    this.erMerknadForAFullforeRegistreringPaTrafikkstasjon(merknad)
                );
            });

            const kjoretoymerknader = Merknader.find(kjoretoy, (merknad) => {
                return (
                    (Merknader.inContext(merknad, RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER) ||
                        kjoretoyRegler.kreverKjennemerkebytte()) &&
                    this.erMerknadForAFullforeRegistreringPaTrafikkstasjon(merknad)
                );
            });

            if (sak.saksstatus === Saksstatus.APEN) {
                return _.uniqBy(saksmerknader.concat(kjoretoymerknader), 'kode');
            }

            return _.uniqBy(kjoretoymerknader.concat(merknader), 'kode');
        }

        if (RegistreringRegler.kanStarteParegistreringssak(kjoretoy, merknader) || sak.saksType === SaksType.REGISTRERING_SAMME_EIER) {
            const kjoretoyMerknader = Merknader.find(kjoretoy, (merknad) => {
                return (
                    (Merknader.inContext(merknad, RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_SAMME_EIER) ||
                        kjoretoyRegler.kreverKjennemerkebytte()) &&
                    this.erMerknadForAFullforeRegistreringPaTrafikkstasjon(merknad)
                );
            });

            return _.uniqBy(kjoretoyMerknader.concat(merknader), 'kode');
        }

        return [];
    }

    private static erMerknadForAFullforeRegistreringPaTrafikkstasjon(merknad: IMerknad): boolean {
        return MERKNAD_FOR_FULLFOR_PA_TRAFIKKSTASJON.filter((kode) => kode.toString() === merknad.kode).length > 0;
    }

    private static validateDrosjeLoyvenummer(drosjeloyvenummer: string) {
        return !!drosjeloyvenummer && drosjeloyvenummer.match(DROSJELOYVENUMMER);
    }

    private static validateAntallAksler(aksler): boolean {
        const MIN_ANTALL_AKSLER = 1;
        const akslerInput = aksler.toString();

        return akslerInput.length && akslerInput.length < 3 && !!akslerInput.match(TALL_FRA_OG_MED_NULL) && Number(akslerInput) >= MIN_ANTALL_AKSLER;
    }

    private static validateTotalvektTilhenger(kjoretoy: IKjoretoy, totalvekt): boolean {
        const TOTALVEKT_MAXLENGTH = 7;
        const MIN_TOTALVEKT_TILHENGER = 1000;
        const totalvektInput = totalvekt.toString();
        const tillattVogntogvekt = kjoretoy.godkjenning.tillattVogntogvekt;

        return (
            totalvektInput.length &&
            totalvektInput.length < TOTALVEKT_MAXLENGTH &&
            (tillattVogntogvekt ? totalvektInput <= tillattVogntogvekt : true) &&
            !!totalvektInput.match(TALL_FRA_OG_MED_NULL) &&
            Number(totalvektInput) >= MIN_TOTALVEKT_TILHENGER
        );
    }

    private static utledUtforesPaTrafikkstasjonMelding(kjoretoy: IKjoretoy): IMessageDescriptor {
        const registreringType = RegistreringRegler.erOmregistrering(kjoretoy) ? 'omregistreringen' : 'paregistreringen';

        return {
            id: `registrering.modal.${registreringType}.utforesPaTrafikkstasjon`
        };
    }
}
