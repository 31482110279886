/* eslint-disable max-lines */
import * as _ from 'lodash';
import moment from 'moment';
import type { MessageDescriptor } from 'react-intl';
import { DROSJELOYVENUMMER, VilkarStatus, VilkarstatusSaksdokument } from 'svv-tk-akr-common-frontend';

import { KjoringensArt, MeldingType, RegelmerknaderKoder, Vilkarsnavn, VilkarType } from '../models/kodeverk';
import type { IMelding, IObligatoriskDokument, IPkkFrist, IRegistreringInformasjon, IVilkar, IVilkarstatus } from '../models/types';

import { aktorerHarGodkjent } from '../utils';
import { Merknader } from './merknader';

class VilkarRegler {

    public static populerVilkarFraVilkarstatus(vilkar: IVilkar, vilkarsliste: IVilkar[], saksinformasjon: IRegistreringInformasjon): void {
        if (vilkar.status !== VilkarStatus.IKKE_PAKREVET && !VilkarRegler.skalIkkeViseKjennemerkevilkar(vilkar, saksinformasjon)) {
            const newVilkar: IVilkar = {
                navn: { id: Vilkarsnavn[vilkar.navn as keyof Vilkarsnavn] },
                status: vilkar.status,
                statusbeskrivelse: {id: `sak.vilkar.${VilkarRegler.utledStatustekst(vilkar.status)}`}
            };

            if (vilkar.tilleggsinformasjon) {
                newVilkar.tilleggsinformasjon = {id: 'generell.knapp.gaTilSkatteetaten'};
            }

            vilkarsliste.push(newVilkar);
        }
    }

    public static kjoretoyHarKravTilForsikring = (merknader: Merknader): boolean => !Merknader.exists(merknader, {kode: RegelmerknaderKoder.KJORETOY_HAR_IKKE_KRAV_TIL_FORSIKRING});

    public static utledVilkarForDokumenter = (obligatoriskeDokumenter: IObligatoriskDokument[] = []): IVilkar => {
        if (obligatoriskeDokumenter.length) {
            const vilkarSaksdokument = VilkarRegler.utledVilkarSaksdokument(obligatoriskeDokumenter);
            return VilkarRegler.makeVilkarForDokument(Vilkarsnavn.DOKUMENTASJON, vilkarSaksdokument);
        }

        return null;
    };

    public static utledVilkarForDokumenterForArbeidsliste = (obligatoriskeDokumenter: IObligatoriskDokument[] = [], erTilGodkjenning?: boolean): IVilkar => {
        if (obligatoriskeDokumenter.length) {
            const vilkarSaksdokument = VilkarRegler.utledVilkarSaksdokument(obligatoriskeDokumenter);
            return VilkarRegler.makeVilkarForDokumentForArbeidsliste(Vilkarsnavn.DOKUMENTASJON, vilkarSaksdokument, erTilGodkjenning);
        }

        return null;
    };

    private static utledVilkarSaksdokument = (obligatoriskeDokumenter: IObligatoriskDokument[]): VilkarstatusSaksdokument => {
        const alleDokumentErGodkjent = _.every(obligatoriskeDokumenter, (dokument: IObligatoriskDokument) => dokument.innfridd);
        const noeDokumentErAvvistEllerMangler = _.find(obligatoriskeDokumenter, (dokument: IObligatoriskDokument) =>
            (dokument.godkjentStatus === VilkarstatusSaksdokument.AVVIST || _.isUndefined(dokument.godkjentStatus)));
        if (alleDokumentErGodkjent) {
            return VilkarstatusSaksdokument.GODKJENT;
        }

        if (noeDokumentErAvvistEllerMangler) {
            return VilkarstatusSaksdokument.AVVIST;
        }

        return VilkarstatusSaksdokument.TIL_VURDERING;
    };

    public static utledVilkarForPkk(vilkarstatus: IVilkarstatus): IVilkar {

        const kjoretoyHarPkkGodkjent = VilkarRegler.kjoretoyPkkGodkjent(vilkarstatus);
        return {
            navn: 'sak.vilkar.pkk',
            status: kjoretoyHarPkkGodkjent ? VilkarStatus.OPPFYLT : VilkarStatus.IKKE_OPPFYLT,
            statusbeskrivelse: `sak.vilkar.${kjoretoyHarPkkGodkjent ? 'ok' : 'mangler'}`
        };
    }

    public static utledVilkar(vilkar: IVilkar): IVilkar {
        return {
            navn: Vilkarsnavn[vilkar.navn as string],
            status: VilkarStatus[vilkar.status as string],
            statusbeskrivelse: `sak.vilkar.${this.utledStatustekst(vilkar.status)}`
        };
    }

    public static utledVilkarForDrosjeloyvenummer = (kjoringensArt: string, loyvenummer = '', vilkar: IVilkar[]): IVilkar => {

        if (kjoringensArt === KjoringensArt.DROSJE_EIER.valueOf() || kjoringensArt === KjoringensArt.SPESINNR_HCP_LP.valueOf() ) {
            _.remove(vilkar, v => v?.navn === Vilkarsnavn.DROSJELOYVE || (v?.navn as MessageDescriptor)?.id === Vilkarsnavn.DROSJELOYVE.valueOf());
            return VilkarRegler.makeVilkarForDrosjeloyvenummer(Vilkarsnavn.DROSJELOYVE, !!(loyvenummer || '').match(DROSJELOYVENUMMER));
        }

        return null;
    };

    public static meldingForKommunikasjonFeilVedKontrollAvVilkar = (vilkar: IVilkar[]): IMelding[] => {
        const meldinger: IMelding[] = [];

        if (VilkarRegler.kommunikasjonFeilet(vilkar, VilkarType.FORSIKRING)) {
            meldinger.push({meldingIntlId: 'sak.vilkar.feilmeldinger.forsikring', meldingType: MeldingType.DANGER});
        }

        if (VilkarRegler.kommunikasjonFeilet(vilkar, VilkarType.AVGIFT)) {
            meldinger.push({meldingIntlId: 'sak.vilkar.feilmeldinger.skatt', meldingType: MeldingType.DANGER});
        }

        return meldinger;
    };

    public static isPkkGyldig = (pkkFrist: IPkkFrist): boolean => (
        pkkFrist.pkkFristForGodkjenning ? moment().isSameOrBefore(pkkFrist.pkkFristForGodkjenning, 'day') : true
    );

    public static erKjoretoyValidertAvGjennomfortPkk = (vilkarstatus: IVilkarstatus): boolean =>
        Merknader.exists(vilkarstatus, {kode: RegelmerknaderKoder.IKKE_GODKJENT_PKK})
        || Merknader.exists(vilkarstatus, {kode: RegelmerknaderKoder.PKK_GODKJENT});

    public static kjoretoyPkkGodkjent = (vilkarstatus: IVilkarstatus): boolean => Merknader.exists(vilkarstatus, {kode: RegelmerknaderKoder.PKK_GODKJENT});

    public static kjoretoyManglerGyldigVilkar = (vilkar: IVilkar): boolean => vilkar
        && _.includes([VilkarStatus.IKKE_OPPFYLT, VilkarStatus.UKJENT, VilkarStatus.MA_GODKJENNES], vilkar.status);

    private static kommunikasjonFeilet = (vilkarliste: IVilkar[], vilkarnavn: VilkarType): boolean => (
        !!_.find(vilkarliste, {navn: vilkarnavn, status: VilkarStatus.UKJENT})
    );

    private static makeVilkarForDrosjeloyvenummer = (id: string, isVilkarOk: boolean): IVilkar => ({
        navn: {id},
        status: isVilkarOk ? VilkarStatus.OPPFYLT : VilkarStatus.IKKE_OPPFYLT,
        statusbeskrivelse: {id: isVilkarOk ? 'sak.vilkar.ok' : 'sak.vilkar.mangler'}
    });

    private static makeVilkarForDokument = (id: string, vilkarStatus: VilkarstatusSaksdokument): IVilkar => ({
        navn: id,
        status: VilkarRegler.utledVilkarStatus(vilkarStatus),
        statusbeskrivelse: {
            id: VilkarRegler.utledVilkarStatusBeskrivelse(vilkarStatus)
        }
    });

    private static utledVilkarStatus = (vilkarStatus: VilkarstatusSaksdokument) => {
        if (vilkarStatus === VilkarstatusSaksdokument.GODKJENT) {
            return VilkarStatus.OPPFYLT;
        }

        if (vilkarStatus === VilkarstatusSaksdokument.TIL_VURDERING) {
            return VilkarStatus.MA_GODKJENNES;
        }

        return VilkarStatus.IKKE_OPPFYLT;
    };

    private static utledVilkarStatusBeskrivelse = (vilkarStatus: VilkarstatusSaksdokument) => {
        if (vilkarStatus === VilkarstatusSaksdokument.GODKJENT) {
            return 'sak.vilkar.ok';
        }

        if (vilkarStatus === VilkarstatusSaksdokument.TIL_VURDERING) {
            return 'sak.vilkar.maGodkjennes';
        }

        return 'sak.vilkar.mangler';
    };

    private static makeVilkarForDokumentForArbeidsliste = (id: string, vilkarStatus: VilkarstatusSaksdokument, erTilGodkjenning: boolean): IVilkar => ({
        navn: id,
        status: VilkarRegler.utledVilkarStatusForArbeidsliste(vilkarStatus, erTilGodkjenning),
        statusbeskrivelse: {
            id: VilkarRegler.utledVilkarStatusBeskrivelseForArbeidsliste(vilkarStatus, erTilGodkjenning)
        }
    });

    private static utledVilkarStatusForArbeidsliste = (vilkarStatus: VilkarstatusSaksdokument, erTilGodkjenning?: boolean) => {
        if (vilkarStatus === VilkarstatusSaksdokument.GODKJENT) {
            return VilkarStatus.OPPFYLT;
        }

        if (vilkarStatus === VilkarstatusSaksdokument.TIL_VURDERING && erTilGodkjenning) {
            return VilkarStatus.MA_GODKJENNES;
        }

        return VilkarStatus.IKKE_OPPFYLT;
    };

    private static utledVilkarStatusBeskrivelseForArbeidsliste = (vilkarStatus: VilkarstatusSaksdokument, erTilGodkjenning: boolean) => {
        if (vilkarStatus === VilkarstatusSaksdokument.GODKJENT) {
            return 'sak.vilkar.ok';
        }

        if (vilkarStatus === VilkarstatusSaksdokument.TIL_VURDERING && erTilGodkjenning) {
            return 'sak.vilkar.maGodkjennes';
        }

        return 'sak.vilkar.mangler';
    };

    private static utledStatustekst = (vilkarstatus: VilkarStatus) => {
        if (vilkarstatus === VilkarStatus.OPPFYLT) {
            return 'ok';
        }

        if (vilkarstatus === VilkarStatus.IKKE_OPPFYLT) {
            return 'mangler';
        }

        return vilkarstatus.toLowerCase();
    };

    private static skalIkkeViseKjennemerkevilkar = (vilkar: IVilkar, saksinformasjon: IRegistreringInformasjon) => {
        const {nyEier, nyMedeier, melder} = saksinformasjon;
        return vilkar.navn === VilkarType.KJENNEMERKE && (!nyEier || !aktorerHarGodkjent([nyEier, nyMedeier, melder]));
    };
}

export { VilkarRegler };
