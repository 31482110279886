import type { ValgtKontaktform } from 'svv-tk-akr-common-frontend';

import { RegistreringKundeType } from '../constants';
import type { IKodeType } from './types';

export class Kunde {
    public type: RegistreringKundeType;
    public kundeId?: string;
    public fodselsdato: string;
    public organisasjonsnummer: string;
    public navn: string;
    public mobiltelefon: string;
    public epost: string;
    public valgtKontaktform: ValgtKontaktform;
    public neringskoder: IKodeType[];
    public forhandler: boolean;

    public constructor(kunde: Partial<Kunde>) {
        this.type = kunde.type;
        this.kundeId = kunde.kundeId || '';
        this.fodselsdato = kunde.fodselsdato || '';
        this.organisasjonsnummer = kunde.organisasjonsnummer || '';
        this.navn = kunde.navn || '';
        this.mobiltelefon = kunde.mobiltelefon || '';
        this.epost = kunde.epost || '';
        this.neringskoder = kunde.neringskoder || [];
        this.forhandler = kunde.forhandler;
    }

    public getKundeId(): string {
        return this.kundeId;
    }

    public erEiertype(type: RegistreringKundeType): boolean {
        return this.type === type;
    }

    public erEksisterendeEierType(): boolean {
        return !this.type.match(/NY/);
    }

    public erForhandler(): boolean {
        return this.forhandler;
    }

    public erNyEierType(): boolean {
        return !!this.type.match(/NY/);
    }

    public erUnderenhet(): boolean {
        return this.type === RegistreringKundeType.NY_UNDERENHET;
    }

    public erLeasingtaker(): boolean {
        return this.type === RegistreringKundeType.NY_LEASINGTAKER;
    }

    public erOrganisasjon(): boolean {
        return !!this.organisasjonsnummer;
    }
}
