import * as React from 'react';
import { AkKnapp } from 'svv-tk-akr-common-frontend';

interface IModalKnapper {
    id: number;
    submit: () => void;
    avbryt: () => void;
}

const ModalKnapper: React.FC<IModalKnapper> = ({ id, submit, avbryt }) => {

    return <div className="ak-knapperad text-left">
        {!!id
         ? <AkKnapp intlKey={'dokumenterpanel.knapper.endre'} action={submit} />
         : <AkKnapp dataTestId="leggTilDokumenterButton" intlKey={'dokumenterpanel.knapper.leggTil'}
             action={submit} />}
        <AkKnapp intlKey={'dokumenterpanel.knapper.avbryt'} action={avbryt} type={'ak-tilleggsknapp'} />
    </div>;
};

export default ModalKnapper;