import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AkRadioknapp, type IKunderMap } from 'svv-tk-akr-common-frontend';
import type { ISaksdokument } from '../../../models/types';
import { erDokumenterLike } from './dokumenter-panel-util';

interface IFiltypeVelgerProps {
    valgbareTyper: ISaksdokument[],
    saksdokumenter: ISaksdokument[],
    skalViseFeil: boolean,
    kunder: IKunderMap;
    setSaksdokumenter: (value: (((prevState: any[]) => any[]) | any[])) => void;
}

const RADIOKNAPP_INPUT_NAME = 'FILTYPE';

const Filtypevelger: React.FC<IFiltypeVelgerProps> = ({ valgbareTyper, saksdokumenter, kunder, skalViseFeil, setSaksdokumenter }) => {
    const intl = useIntl();

    const utledLabel = (dok: ISaksdokument) => {
        const { aktor, saksdokumentType } = dok;
        return intl.formatMessage({ id: `kodeverk.saksdokumentType.${saksdokumentType}` }, { navn: kunder[aktor?.kundeId]?.navn });
    };

    const erDokumentValgt = (type: ISaksdokument) => !!saksdokumenter.some(dok => erDokumenterLike(dok, type));
    const finnValgtType = () => valgbareTyper.find(erDokumentValgt);

    const endreValgtTypeForRadioknapp = (destrukturertDokument: string) => {
        const valgtType = JSON.parse(destrukturertDokument) as ISaksdokument;
        setSaksdokumenter([valgtType]); //Forhandler kan bare ha ett dokument
    };

    const renderRadioknappForType = (type: ISaksdokument) =>
        <AkRadioknapp key={type.saksdokumentType + type.aktor?.kundeId}
            label={utledLabel(type)}
            inputName={RADIOKNAPP_INPUT_NAME}
            checkedValue={JSON.stringify(finnValgtType())}
            handleChange={endreValgtTypeForRadioknapp}
            value={JSON.stringify(type)} />;

    return <div className="mx-2 my-4">
        <h3 className="mt-3"><FormattedMessage id="dokumenterpanel.modal.kropp.dokumentTyperLabel" /></h3>
        {valgbareTyper.map(renderRadioknappForType)}
        {skalViseFeil && !saksdokumenter?.length && <p className="ak-text-error col-12"><FormattedMessage id={'dokumenterpanel.modal.feil.valideringType'} /></p>}
    </div>;
};

export default Filtypevelger;