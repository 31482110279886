import { AkLoading, KjennemerkeSettEnum } from 'svv-tk-akr-common-frontend';

import classNames from 'classnames';
import * as React from 'react';
import type { IKjoretoy } from '../../models/types';
import { KjennemerkeInfo, MerkeInfo, TekniskInfo } from './erstatningskjennemerke-infokomponenter';

interface IErstatningskjennemerkeInfoPanelProps {
    kjoretoy: IKjoretoy;
    containerClasses?: string;
}

type KjoretoyInfoPanelPropsType = IErstatningskjennemerkeInfoPanelProps;

const ErstatningskjennemerkeInfoPanel: React.FC<KjoretoyInfoPanelPropsType> = (props: KjoretoyInfoPanelPropsType) => {
    const {kjoretoy = {}, containerClasses} = props;
    const {storrelseForan, storrelseBak} = kjoretoy.registrering.kjennemerke;
    const {registrering, godkjenning} = kjoretoy;
    const regKjennemerke = registrering?.kjennemerke;
    const kjennemerkeStorrelse = !!storrelseForan ? storrelseForan.concat(storrelseBak) : storrelseBak;
    const kjennemerkeSett = KjennemerkeSettEnum[kjennemerkeStorrelse];
    const kjennemerkeType = regKjennemerke?.kjennemerkeType;

    return (
        <div className={classNames('ak-panel-innhold', 'kjoretoy-info-panel', 'ak-panel-lysegra', containerClasses)}>
            {kjoretoy.isLoading ? <AkLoading /> :
             <>
                 <KjennemerkeInfo godkjenning={godkjenning} registrering={registrering} kjennemerkeType={kjennemerkeType} kjennemerkeSett={kjennemerkeSett} />
                 <MerkeInfo godkjenning={godkjenning} registrering={registrering} kjennemerkeSett={kjennemerkeSett}  />
                 <TekniskInfo godkjenning={godkjenning} registrering={registrering} kjennemerkeType={kjennemerkeType}/>
             </>
            }
        </div>
    );
};

export { ErstatningskjennemerkeInfoPanel };
