import { AkKnapp, AkModal, AkModalType } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { IEierskiftemodalProps } from './eierskifte-modaler';

export const EierskifteAvbrytModal: React.FC<IEierskiftemodalProps> = (props) => {

    return (
        <AkModal showModal={true} modalType={AkModalType.WARN} title="eierskifte.modal.avbrytTitle" closeModal={props.lukkModal}>
            <p><FormattedMessage id="eierskifte.modal.avbrytBodyText" /></p>
            <div className="ak-knapperad-modal">
                <AkKnapp key="lukk" type="ak-knapp ak-hovedknapp" action={props.modalhandling} intlKey="eierskifte.modal.lukkEierskifte" />
                <AkKnapp key="fortsett" type="ak-knapp ak-tilleggsknapp" action={props.lukkModal} intlKey="eierskifte.modal.forsetteEierskifte" />
            </div>
        </AkModal>
    );

};