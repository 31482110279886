import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const ikkeFunnetSvg = require('../../styles/images/ikke-funnet.svg');

interface IIkkeFunnetProps {
    goto?: string;
}

class IkkeFunnet extends React.Component<IIkkeFunnetProps> {

    public render(): React.ReactNode {
        return (
            <div className="container row no-gutters mx-auto py-4">
                <div className="col-md-6 col-sm-12 pb-5">
                    <FormattedMessage tagName="h1" id={'feilhandtering.ikkefunnet.overskrift'} />
                    <FormattedMessage tagName="p" id={'feilhandtering.ikkefunnet.bodytext'} />
                    <Link className="ak-knapp ak-hovedknapp mt-4" role="button" to={this.props.goto || '/startside'}>
                        <FormattedMessage id={'feilhandtering.ikkefunnet.link.gaatil'} />
                    </Link>
                </div>
                <div className="col-6 d-sm-none d-md-flex row justify-content-center">
                    <img className="d-block h-100" src={ikkeFunnetSvg} alt="Teknisk feil bilde" />
                </div>
            </div>
        );
    }
}

export { IkkeFunnet };
