export enum EierskifteStatus {
    APEN = 'APEN',
    AVBRUTT = 'AVBRUTT',
    AVSLUTTET = 'AVSLUTTET',
    UNDER_OPPRETTING = ''
}

export enum AvsluttetAvbruttArsak {
    AVBRUTT_AV_AKTOR = 'AVBRUTT_AV_AKTOR',
    AVVIST_AV_AKTOR = 'AVVIST_AV_AKTOR',
    VILKAR_IKKE_TILFREDSSTILT = 'VILKAR_IKKE_TILFREDSSTILT',
    UTGATT_TIDSFRIST = 'UTGATT_TIDSFRIST'
}
