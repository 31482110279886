import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import type { AkSearchBarErrorTypes } from 'svv-tk-akr-common-frontend';
import { AkSearchBar } from 'svv-tk-akr-common-frontend';

import type { RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';
import { gjorOppslag } from '../../state/actions';
import { useAppDispatch, useAppSelector } from '../../state/store';
import { WHITESPACE } from '../../utils';

import './search-bar-style';

export const SearchBar: React.FC = () => {
    const harRolleForSok = useAppSelector((state: RootStateType) => BrukerRegler.adapter(state).harRolleForSok());

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [errorType, setErrorType] = useState<AkSearchBarErrorTypes>(undefined);

    const onSearchSubmit = (query: string) => {
        const q = query.replace(WHITESPACE, '');
        dispatch(gjorOppslag(q));
        navigate(`/oppslag?q=${q}`);
    };

    const onSearchError = (error: AkSearchBarErrorTypes) => {
        setErrorType(error);
    };

    if (!harRolleForSok) {
        return null;
    }

    return (
        <div className="search-bar">
            <AkSearchBar
                onSubmit={onSearchSubmit}
                onError={onSearchError}
                placeholderIntlKey="generell.sokefelt.kjoretoy.placeholder"
            />
            {errorType &&
                <p>
                    <FormattedMessage id="toppmeny.sokefelt.tomtFelt"/>
                </p>
            }
        </div>
    );
};
