import * as _ from 'lodash';


import type { IArbeidsliste, IArbeidslisteState, IRootState } from '../../models/types';
import { createSelector } from "reselect";

export const arbeidslisteForstegangsregistreringerRaderSelector = (state: IRootState): IArbeidsliste => state.arbeidsliste.forstegangsregistreringer;
export const arbeidslisteSelector = (state: IRootState): IArbeidslisteState => state.arbeidsliste;

export const unikeMeldereIForstegangsregistreringsraderSelector = createSelector(
    arbeidslisteForstegangsregistreringerRaderSelector, fregRader => {
        return _.map(_.uniqBy(fregRader.rader, rad => rad.melderKundeId), rad => rad.melderKundeId);
    }
);

export const filtrertForstegangsregistreringradeSelector = createSelector(
    arbeidslisteSelector, (arbeidslisteState) => {
        const { forstegangsregistreringer , filter, sakIdGodkjent } = arbeidslisteState;

        let rader = _.chain(forstegangsregistreringer.rader)
            .reject(rad => sakIdGodkjent === rad.sakId);

        if(filter?.melderKundeId){
            rader = rader.filter(rad => filter?.melderKundeId === rad.melderKundeId);
        }

        return { ...forstegangsregistreringer, rader: rader.value() };
    }
);

