import * as _ from 'lodash';

import { ForstegangsregistreringArkivdokumentActionKey } from '../../constants';
import type { ISak } from '../../models/types';
import type { ForstegangsregistreringArkivdokumenterActionType } from '../actions';

const sakInitialState: ISak = {

};

export function forstegangsregistreringDokument(state: ISak = sakInitialState, action: ForstegangsregistreringArkivdokumenterActionType): ISak {
    switch (action.type) {
        case ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_LOADING:
            return {...state, isLoading: action.isLoading};
        case ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_ADD:
            return {...state, arkivdokumenter: [...state.arkivdokumenter, action.arkivdokument]};
        case ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_DELETE:
            const filteredList = _.reject(state.arkivdokumenter, {id: _.toNumber(action.id)});
            return {...state, arkivdokumenter: filteredList};
        case ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_EDIT:
            const otherDocuments = _.reject(state.arkivdokumenter, {id: action.arkivdokument.id});
            return {...state, arkivdokumenter: [...otherDocuments, action.arkivdokument]};
        case ForstegangsregistreringArkivdokumentActionKey.OTHER_ACTION:
        default:
            return state;
    }
}
