import { AkKnapp, AkModal, AkModalType } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { IEierskiftemodalProps } from './eierskifte-modaler';

export const BulkGodkjennModal: React.FC<IEierskiftemodalProps> = (props) => {

    return (
        <AkModal showModal={true} modalType={AkModalType.INFO} title="eierskifte.modal.bekreftTitle" closeModal={props.lukkModal}>
            <p><FormattedMessage id="eierskifte.modal.bekreftelseFlereEierskifter" /></p>
            <p><FormattedMessage id="eierskifte.modal.kjennemerkeneOppbevartFlereEierskifter" /></p>
            <p><FormattedMessage id="eierskifte.modal.bruddPaAvtaleFlereEierskifter" /></p>
            <div className="ak-knapperad-modal">
                <AkKnapp key="godkjenn" type="ak-knapp ak-hovedknapp" action={props.modalhandling} intlKey={'eierskifte.modal.bekreftEierskifte'} />
                <AkKnapp key="lukk" type="ak-knapp ak-tilleggsknapp" action={props.lukkModal} intlKey={'generell.avbryt'} />
            </div>
        </AkModal>
    );

};