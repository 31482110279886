import * as _ from 'lodash';
import { RegelmerknaderKoder } from '../models/kodeverk';
import type { IKjennemerkerStotteregisterStatus, IKjoretoy, IMerknad, ITekniskeData } from '../models/types';
import { Merknader } from './merknader';

class KjennemerkeRegler {

    public static erErstatningsKjennemerkeIBestilling = (merknader: IMerknad[]): boolean => {
        return Merknader.exists(merknader, {kode: RegelmerknaderKoder.ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING});
    };

    public static erKjoretoyKravTilErstatningskjennemerkeOppfylt(merknader: IMerknad[]): boolean {
        return !Merknader.exists(merknader, { kode: RegelmerknaderKoder.KRAV_TIL_ERSTATNINGSKJENNEMERKE_I_POSTFORSENDELSE_IKKE_OPPFYLT });
    }

    public static erKjennemerkekravTilErstatningskjennemerkerOppfylt(merknader: IMerknad[]): boolean {
        return !Merknader.exists(merknader, { kode: RegelmerknaderKoder.ERSTATNINGSKJENNEMERKE_ALLE_KJENNEMERKER_IKKE_INNLEVERT });
    }

    public static kjennemerkeTypeMaEndresPaTrafikkstasjon(merknader: IMerknad[]): boolean {
        return Merknader.exists(merknader, { kode: RegelmerknaderKoder.KJENNEMERKETYPE_MA_ENDRES });
    }

    public static manglerBestillerForsendelsesadresse(merknader: IMerknad[]): boolean {
        return Merknader.exists(merknader, { kode: RegelmerknaderKoder.INGEN_FORSENDELSESADRESSE_BESTILLER });
    }

    public static erAlleKjennemerkerGjortRedeFor = (kjoretoy: IKjoretoy): boolean => {
        const kjennemerkerStotteregisterStatus = kjoretoy.registrering?.registreringsinformasjon?.kjennemerkerStotteregisterStatus;
        const tekniskeData = kjoretoy?.godkjenning?.tekniskeData;

        return KjennemerkeRegler.erGamleKjennemerkerGjortRedeFor(kjennemerkerStotteregisterStatus, tekniskeData);
    };

    private static erGamleKjennemerkerGjortRedeFor = (kjennemerkerStotteregisterStatus: IKjennemerkerStotteregisterStatus, tekniskeData: ITekniskeData): boolean => {
        let antallGamleKjennemerker = 0;
        if (!tekniskeData) {
            return false;
        }

        if (!!kjennemerkerStotteregisterStatus) {
            const antallMakulert = kjennemerkerStotteregisterStatus.antallMakulert || 0;
            const antallTapt = kjennemerkerStotteregisterStatus.antallTapt || 0;
            const antallOppbevart = kjennemerkerStotteregisterStatus.antallOppbevart || 0;

            antallGamleKjennemerker = antallMakulert + antallTapt + antallOppbevart;
        }

        const { kjennemerkestorrelseForan, kjennemerkestorrelseBak, kjennemerkestorrelseVenstre } = tekniskeData;
        const definerteStorrelser = _.reject([kjennemerkestorrelseForan, kjennemerkestorrelseBak, kjennemerkestorrelseVenstre], _.isNil);
        const antallKjennemerkerTotalt = definerteStorrelser.length;

        return antallGamleKjennemerker >= antallKjennemerkerTotalt;
    };
}

export { KjennemerkeRegler };