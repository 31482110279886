import * as _ from 'lodash';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { KjoringensArt} from '../../models/kodeverk';
import { KodeType } from '../../models/kodeverk';

import type { IKjoretoy, IKjoringensArtListe, IKodeType, IKunde, IRegistreringInformasjon, RootStateType } from '../../models/types';
import { harInnfriddTraktorMedBruksbegrensningMerknadSelector } from '../../state/selectors';
import { utledNeringskodebeskrivelse, utledValgtKjoringensArt, utledValgtNeringskode } from '../../utils';
import { BrukInput } from './bruk-input';

interface IKjoretoyBrukProps {
    ugyldigeKjoringensArt?: KjoringensArt[],
    lesemodus: boolean;
    neringskode: string;
    kjoringensArt: string;
    oppdaterBruk: (bruk: Partial<IRegistreringInformasjon>) => void;
    erForstegangsregistrering: boolean; // Midlertidig løsning inntil behov har fastsatt utledning av kjøringens art for 1G og registrering.
    inputContainerClassName?: string;
    errors?: string[];
    eier?: IKunde;
}

interface IKjoretoyBrukStateProps {
    kjoringensArtListe: IKjoringensArtListe;
    kjoretoy: IKjoretoy;
    harInnfriddTraktorMedBruksbegrensningMerknad: boolean;
}

class KjoretoyBruk extends React.Component<IKjoretoyBrukProps & IKjoretoyBrukStateProps & WrappedComponentProps> {

    public componentDidMount(): void {
        const { kjoringensArt, neringskode, eier, kjoretoy, kjoringensArtListe } = this.props;

        const valgtKjoringensArt = utledValgtKjoringensArt(kjoringensArt, eier, kjoretoy, kjoringensArtListe.innslag);
        const valgtNeringskode = utledValgtNeringskode(neringskode, eier.neringskoder);

        this.props.oppdaterBruk({
            kjoringensArt: valgtKjoringensArt,
            neringskode: valgtNeringskode,
            neringskodeBeskrivelse: utledNeringskodebeskrivelse(valgtNeringskode, eier.neringskoder)

        });
    }

    public render(): React.ReactElement {
        return (
            <dl className="ak-dl-liste ak-dl-liste-grid-kompakt">
                {this.props.eier.organisasjonsnummer && !!this.props.eier.neringskoder.length && this.createSelectInputForNeringskoder()}
                {this.createSelectInputForKjoringensArt()}
            </dl>
        );
    }

    private createSelectInputForNeringskoder = () => {
        return (
            <>
                <dt>
                    <FormattedMessage id="sak.label.neringskode" tagName="p" />
                </dt>
                <dd>
                    <BrukInput lesemodus={this.props.lesemodus} koder={this.utledNeringskoderTekst(this.props.eier.neringskoder)}
                        kodeVisning={this.utledValgtNeringskode()} errors={this.neringskodeErrors()} handleSelectChange={this.oppdaterNeringskode} />
                </dd>
            </>

        );
    };

    private createSelectInputForKjoringensArt = () => {
        const gyldigeKjoringensArt = _.orderBy(this.utledKjoringensArtTekst(), k => k.getBeskrivelse(), 'asc');

        return (
            <>
                <dt>
                    <FormattedMessage id="sak.label.kjoringensArt" tagName="p" />
                </dt>
                <dl className="mb-0">
                    <BrukInput
                        lesemodus={this.props.lesemodus || this.props.harInnfriddTraktorMedBruksbegrensningMerknad}
                        koder={gyldigeKjoringensArt}
                        kodeVisning={this.props.kjoringensArt}
                        errors={this.kjoringensArtErrors()}
                        handleSelectChange={this.oppdaterKjoringensArt}
                    />
                </dl>
            </>

        );
    };

    private oppdaterKjoringensArt = (kjoringensArt: string) => {
        this.props.oppdaterBruk({ kjoringensArt });
    };

    private oppdaterNeringskode = (neringskode: string) => {
        this.props.oppdaterBruk({
            neringskode,
            neringskodeBeskrivelse: utledNeringskodebeskrivelse(neringskode, this.props.eier.neringskoder)
        });
    };

    private utledNeringskoderTekst = (neringskoder: IKodeType[]): KodeType[] => {
        return (neringskoder || [])
            .map((neringskode: IKodeType) => new KodeType({ kode: neringskode.kode, beskrivelse: `${neringskode.kode} ${neringskode.beskrivelse}`, gyldig: true }));
    };

    private utledValgtNeringskode = (): string => {
        const neringskodeKode = _.find(this.props.eier.neringskoder, ['kode', this.props.neringskode]);
        if (neringskodeKode) {
            return neringskodeKode.kode;
        }

        return this.props.eier.neringskoder.length === 1 ? this.props.eier.neringskoder[0].kode : null;
    };

    private neringskodeErrors = () => {
        return _.filter(this.props.errors, error => error === 'sak.validering.neringskodeErPakrevet');
    };

    private kjoringensArtErrors = () => {
        return _.filter(this.props.errors, error => error === 'sak.validering.kjoringensArtErPakrevet');
    };

    private utledKjoringensArtTekst = (): KodeType[] => {
        return _.chain(this.props.kjoringensArtListe.innslag)
            .map(art => {
                const artId = `kodeverk.kjoringens-art.${art.name}.tittel`;
                return new KodeType({
                    kode: art.name,
                    beskrivelse: `${this.props.intl.formatMessage({ id: artId })}`,
                    gyldig: _.includes(this.props.ugyldigeKjoringensArt, art.name) ? false : art.gyldig
                });
            })
            .uniqBy('kode')
            .value();
    };
}

const mapStateToProps = (state: RootStateType): IKjoretoyBrukStateProps => ({
    kjoringensArtListe: state.kjoringensArtListe,
    kjoretoy: state.kjoretoy,
    harInnfriddTraktorMedBruksbegrensningMerknad: harInnfriddTraktorMedBruksbegrensningMerknadSelector(state)
});

const KjoretoyBrukConnected = connect(mapStateToProps)(injectIntl(KjoretoyBruk));

export { KjoretoyBrukConnected, KjoretoyBruk };
