import * as _ from 'lodash';
import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { ValgtKontaktform } from 'svv-tk-akr-common-frontend';

import { AkrConfig, ArbeidslisteActionKey, NY_SAK_VISNINGSTID_MS } from '../../constants';
import type { RegistreringsstatusKode, SamletGodkjenningStatus } from '../../models/kodeverk';
import { ArbeidslisteSakstypefilter, MeldingType, SaksType } from '../../models/kodeverk';
import type { Varseltype } from '../../models/kodeverk/varseltype';
import type {
    Brukerprofil,
    HentArbeidsliste, HentArbeidslisteAction, IAktor, IArbeidslisteDokumentasjonsstatus, IArbeidslisteDokumentasjonsstatusRequest, IArbeidslisteFilter, IArbeidslisteRad, IArbeidslisteRadApi,
    IArkivdokument, IBaseApiResponsePageObject, IError, IGetKundeMedKundeId, IMerknad, ISak, ISaksinformasjon, NavigateType, RootStateType
} from '../../models/types';
import { BrukerRegler } from '../../regler';
import { convertSakstypeToSakstypefilter } from '../../utils';
import { createRestResource } from '../store';
import type { GlobalErrorActionType } from './globals';
import { addGlobalErrors } from './globals';
import type { KundeActionType } from './kunde';
import { getKunde } from './kunde';
import type { IMeldingBarNyMeldingAction} from './melding-bar';
import { nyMelding } from './melding-bar';

const api = createRestResource(AkrConfig.ARBEIDSLISTE_RESOURCE_URL);
const varselApi = createRestResource(AkrConfig.VARSEL_URL);
const apiDokumentstatus = createRestResource(AkrConfig.ARBEIDSLISTE_DOKUMENTASJONSSTATUS_RESOURCE_URL);

export type ArbeidslisteActionType = IArbeidslisteLoadingAction
    | IArbeidslisteFetchDataSuccessAction
    | IArbeidslisteClearAllAction
    | IArbeidslisteClearEierskifterOgRegistreringerAction
    | IArbeidslisteClearForstegangsregistreringAction
    | IArbeidslisteFetchDataErrorAction
    | IArbeidlisteHentRegistreringsstatus
    | IArbeidslisteNySakAction
    | IArbeidslisteSakFjernSuccessAction
    | IArbeidslisteUpdateForstegangsregistreringRadAction
    | IArbeidslisteUpdateEierskifteOgRegisteringRadAction
    | IArbeidslisteSorterAction
    | IArbeidslisteNyMerknaderAction
    | IArbeidslisteOtherAction
    | IArbeidslisteHentArkivdokumentLoadingAction
    | IArbeidslisteHentArkivdokumentSuccessAction
    | IArbeidslisteUpdateFilterAction
    | IArbeidslisteSakGodkjentAction;

interface IArbeidslisteAction {
    sakstype: ArbeidslisteSakstypefilter;
}

export interface IArbeidslisteLoadingAction extends IArbeidslisteAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_LOADING;
    isLoading: boolean;
    avdelingId: string;
}

export interface IArbeidslisteFetchDataErrorAction extends IArbeidslisteAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_FETCH_DATA_ERROR;
    error: IError;
}

export interface IArbeidslisteFetchDataSuccessAction extends IArbeidslisteAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_FETCH_DATA_SUCCESS;
    arbeidslisteRader: IArbeidslisteRadApi[];
    merknader: IMerknad[];
    avdelingId: string;
}

export interface IArbeidslisteNySakAction extends IArbeidslisteAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_NY_SAK;
    nySakIder: string[];
}

export interface IArbeidslisteSakFjernSuccessAction extends IArbeidslisteAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_SAK_FJERNET;
    sakId: string;
}

export interface IArbeidslisteSakGodkjentAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_SAK_GODKJENT;
    sakIdGodkjent: string;
}

export interface IArbeidslisteUpdateForstegangsregistreringRadAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_FORSTEGANGSREGISTRERING_RAD;
    sakId: string;
    nyStatus: SamletGodkjenningStatus;
}

export interface IArbeidslisteUpdateEierskifteOgRegisteringRadAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_EIERSKIFTE_OG_REGISTRERING_RAD;
    sakId: string;
    nyStatus: SamletGodkjenningStatus;
}

export interface IArbeidslisteSorterAction extends IArbeidslisteAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_SORTER;
    sortProp: string;
}

export interface IArbeidslisteNyMerknaderAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_NY_MERKNADER;
    sakId: string;
    merknader: IMerknad[];
}

export interface IArbeidlisteHentRegistreringsstatus extends IArbeidslisteAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_HENT_REGISTRERINGSSTATUSKODE;
    registreringsstatusKode: RegistreringsstatusKode;
    sakId: string;
}

export interface IArbeidslisteClearAllAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_ALL;
}

export interface IArbeidslisteClearEierskifterOgRegistreringerAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_EIERSKIFTER_OG_REGISTRERINGER;
}

export interface IArbeidslisteClearForstegangsregistreringAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_FORSTEGANGSREGISTRERINGER;
}

export interface IArbeidslisteOtherAction {
    type: ArbeidslisteActionKey.OTHER_ACTION;
}

export interface IArbeidslisteHentArkivdokumentLoadingAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_HENT_ARKIVDOKUMENT_LOADING;
    isLoading: boolean;
    sakId: string;
}

export interface IArbeidslisteHentArkivdokumentSuccessAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_HENT_ARKIVDOKUMENT_SUCCESS;
    isLoading: boolean;
    sakId: string;
    merknader: IMerknad[];
    arkivdokumenter: IArkivdokument[];
    erTilGodkjenning: boolean;
}

export interface IArbeidslisteUpdateFilterAction {
    type: ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_FILTER;
    filter: Partial<IArbeidslisteFilter>;
}

const arbeidslisteIsLoading = (isLoading: boolean, avdelingId: string, sakstype: ArbeidslisteSakstypefilter): IArbeidslisteLoadingAction => ({
    type: ArbeidslisteActionKey.ARBEIDSLISTE_LOADING,
    isLoading,
    avdelingId,
    sakstype
});

const arbeidslisteFetchDataSuccess = (
    arbeidslisteRader: IArbeidslisteRad[],
    merknader: IMerknad[],
    avdelingId: string,
    sakstype: ArbeidslisteSakstypefilter
): IArbeidslisteFetchDataSuccessAction => ({
    type: ArbeidslisteActionKey.ARBEIDSLISTE_FETCH_DATA_SUCCESS,
    arbeidslisteRader,
    merknader,
    avdelingId,
    sakstype
});

const arbeidslisteClearAll = (): IArbeidslisteClearAllAction => ({
    type: ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_ALL
});

const arbeidslisteClearEierskiferOgRegistreringer = (): IArbeidslisteClearEierskifterOgRegistreringerAction => ({
    type: ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_EIERSKIFTER_OG_REGISTRERINGER
});

const arbeidslisteClearForstegangsregistrering = (): IArbeidslisteClearForstegangsregistreringAction => ({
    type: ArbeidslisteActionKey.ARBEIDSLISTE_CLEAR_FORSTEGANGSREGISTRERINGER
});

const arbeidslisteGetError = (error: IError, sakstype: ArbeidslisteSakstypefilter): IArbeidslisteFetchDataErrorAction => ({
    type: ArbeidslisteActionKey.ARBEIDSLISTE_FETCH_DATA_ERROR,
    sakstype,
    error
});

const sortArbeidslisteAction = (sortProp: string, sakstype: ArbeidslisteSakstypefilter): IArbeidslisteSorterAction =>
    ({ type: ArbeidslisteActionKey.ARBEIDSLISTE_SORTER, sortProp, sakstype });

export const updateForstegangregistreringRadStatus = (sakId: string, nyStatus: SamletGodkjenningStatus): IArbeidslisteUpdateForstegangsregistreringRadAction =>
    ({ type: ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_FORSTEGANGSREGISTRERING_RAD, sakId, nyStatus });

export const updateEierskifteOgRegistreringRadStatus = (sakId: string, nyStatus: SamletGodkjenningStatus): IArbeidslisteUpdateEierskifteOgRegisteringRadAction =>
    ({ type: ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_EIERSKIFTE_OG_REGISTRERING_RAD, sakId, nyStatus });

export const removeRadSuccess = (sakId: string, sakstype: ArbeidslisteSakstypefilter): IArbeidslisteSakFjernSuccessAction =>
    ({ type: ArbeidslisteActionKey.ARBEIDSLISTE_SAK_FJERNET, sakId, sakstype });

export const lagreSakIdTilGodkjentSak = (sakIdGodkjent: string): IArbeidslisteSakGodkjentAction =>
    ({type: ArbeidslisteActionKey.ARBEIDSLISTE_SAK_GODKJENT, sakIdGodkjent});

export const registreringsstatusHentet = (registreringsstatusKode: RegistreringsstatusKode, sakId: string, sakstype: ArbeidslisteSakstypefilter): IArbeidlisteHentRegistreringsstatus =>
    ({ type: ArbeidslisteActionKey.ARBEIDSLISTE_HENT_REGISTRERINGSSTATUSKODE, registreringsstatusKode, sakId, sakstype });

export const arbeidslisteHentArkivdokumentLoadingAction = (sakId: string): IArbeidslisteHentArkivdokumentLoadingAction =>
    ({ type: ArbeidslisteActionKey.ARBEIDSLISTE_HENT_ARKIVDOKUMENT_LOADING, isLoading: true, sakId });

export const arbeidslisteHentArkivdokumentSuccessAction = (sakId: string, arkivdokumenter: IArkivdokument[], merknader: IMerknad[], erTilGodkjenning: boolean)
    : IArbeidslisteHentArkivdokumentSuccessAction =>
    ({ type: ArbeidslisteActionKey.ARBEIDSLISTE_HENT_ARKIVDOKUMENT_SUCCESS, isLoading: false, sakId, merknader, arkivdokumenter, erTilGodkjenning });

export const arbeidslisteUpdateFilterAction = (filter: Partial<IArbeidslisteFilter>): IArbeidslisteUpdateFilterAction =>
    ({ type: ArbeidslisteActionKey.ARBEIDSLISTE_UPDATE_FILTER, filter });


const arbeidslisteFetchAllControllers: AbortController[] = [];
const arbeidslisteFetchKall: Promise<(void | IArbeidslisteAction)>[] = [];

function avbrytAktiveArbeidslisteKall(arbeidslisteControllers: AbortController[]): void {
    arbeidslisteControllers.forEach(controller => controller.abort());
}

function leggControllerIListe (controller: AbortController) {
    arbeidslisteFetchAllControllers.push(controller);
}

export function arbeidslisteFetchAll(filter: IArbeidslisteFilter): ThunkAction<Promise<(void | IArbeidslisteAction)[]>, RootStateType, undefined, ArbeidslisteActionType> {
    return (dispatch) => {
        avbrytAktiveArbeidslisteKall(arbeidslisteFetchAllControllers);

        dispatch(arbeidslisteClearAll());

        const arbeidslisteController = new AbortController();
        leggControllerIListe(arbeidslisteController);

        const hentArbeidslisteCancellable: HentArbeidsliste = (f, avdelingsId) => arbeidslisteGetDataCancellable(f, avdelingsId, arbeidslisteController.signal);

        arbeidslisteFetchKall.push(dispatch(arbeidslisteFetchForstegangsregistreringer(filter, false, hentArbeidslisteCancellable)));
        arbeidslisteFetchKall.push(dispatch(arbeidslisteFetchEierskifterOgRegistreringer(filter.dager, false, filter?.godkjenningStatus, filter?.venterPaGodkjenningFraInnloggetEnhet,
            hentArbeidslisteCancellable)));

        return Promise.all(arbeidslisteFetchKall);
    };
}

export function arbeidslisteFetchEierskifterOgRegistreringer(dager: string, withClear: boolean, godkjenningStatus?: SamletGodkjenningStatus, venterPaGodkjenningFraInnloggetEnhet?: boolean,
                                                             hentArbeidsliste: HentArbeidsliste = arbeidslisteGetData ):
    ThunkAction<Promise<void | IArbeidslisteAction>, RootStateType, undefined, ArbeidslisteActionType> {
    return (dispatch, getState) => {
        if (withClear) {
            dispatch(arbeidslisteClearEierskiferOgRegistreringer());
        }
        const avdelingId = getState().brukerprofil.valgtEnhet.avdelingId;
        return Promise.all(
            [ArbeidslisteSakstypefilter.EIERSKIFTE, ArbeidslisteSakstypefilter.REGISTRERING]
                .map((sakstype) => dispatch(hentArbeidsliste({ dager, sakstype, godkjenningStatus, venterPaGodkjenningFraInnloggetEnhet }, avdelingId)))
        ).then(() => dispatch(arbeidslisteIsLoading(false, avdelingId, ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING)));
    };
}

export function arbeidslisteFetchForstegangsregistreringer(filter: Partial<IArbeidslisteFilter>, resetListe = false, hentArbeidsliste: HentArbeidsliste = arbeidslisteGetData):
    ThunkAction<Promise<IArbeidslisteAction | void>, RootStateType, undefined, ArbeidslisteActionType> {
    return (dispatch, getState) => {
        const avdelingId = getState().brukerprofil.valgtEnhet.avdelingId;

        const query: IArbeidslisteFilter = {
            dager: filter.dager,
            venterPaGodkjenningFraInnloggetEnhet: filter.venterPaGodkjenningFraInnloggetEnhet,
            sakstype: ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING,
            godkjenningStatus: filter.godkjenningStatus
        };

        if (resetListe) {
            dispatch(arbeidslisteClearForstegangsregistrering());
        }

        return dispatch(hentArbeidsliste(query, avdelingId)).then(() => {
            dispatch(arbeidslisteIsLoading(false, avdelingId, ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING));

            if (BrukerRegler.adapter(getState()).harRolleFlateeier()) {
                dispatch(arbeidslisteHentLeasingtakerOgMedeierForRader());

            }
        });
    };
}

function handleArbeidslisteGetDataResponse (response, avdelingId: string, sakstype: ArbeidslisteSakstypefilter):
    ThunkAction<IArbeidslisteFetchDataSuccessAction | IArbeidslisteFetchDataErrorAction, RootStateType, undefined, IArbeidslisteFetchDataSuccessAction | IArbeidslisteFetchDataErrorAction> {
    return (dispatch) => {
        if (response.errorCode) {
            return dispatch(arbeidslisteGetError(response, sakstype));
        }

        return dispatch(arbeidslisteFetchDataSuccess(response.arbeidsliste, response.merknader, avdelingId, sakstype));
    };
}

function arbeidslisteGetDataCancellable(filter: IArbeidslisteFilter, avdelingId: string, signal: AbortSignal): HentArbeidslisteAction {
    return dispatch => {
        dispatch(arbeidslisteIsLoading(true, avdelingId, filter.sakstype));
        return api.fetch({ ...filter }, null, {signal})
            .then(response => {
                return dispatch(handleArbeidslisteGetDataResponse(response, avdelingId, filter.sakstype));
            })
            .catch(() => {
                if (signal.aborted) {
                    return dispatch(markerArbeidslistekallSomAvbrutt(avdelingId, filter));
                }
            });
    };
}

function markerArbeidslistekallSomAvbrutt(avdelingId: string, filter: IArbeidslisteFilter): ThunkAction<IArbeidslisteLoadingAction, RootStateType, undefined, IArbeidslisteLoadingAction> {
    return dispatch => {
        return dispatch(arbeidslisteIsLoading(false, avdelingId, filter.sakstype));
    };
}

function arbeidslisteGetData(filter: IArbeidslisteFilter, avdelingId: string): HentArbeidslisteAction {
    return (dispatch) => {
        dispatch(arbeidslisteIsLoading(true, avdelingId, filter.sakstype));
        return api.fetch({ ...filter })
            .then(response => {
                return dispatch(handleArbeidslisteGetDataResponse(response, avdelingId, filter.sakstype));
            });
    };
}

export function finnDokumentasjonsstatus(arbeidsliste: IArbeidslisteRad[]): ThunkAction<void, RootStateType, undefined, Action> {
    return dispatch => {
        dispatch(finnOgPopulerDokumentStatus(arbeidsliste));
    };
}

function arbeidslisteHentLeasingtakerOgMedeierForRader(): ThunkAction<void, RootStateType, undefined, KundeActionType> {
    return (dispatch, getState) => {
        const forstegangsregistreringrader: IArbeidslisteRad[] = getState().arbeidsliste.forstegangsregistreringer.rader;
        const raderMedLeasingtaker = _.filter(forstegangsregistreringrader, rad => !!rad.leasingtakerKundeId);
        _.forEach(raderMedLeasingtaker, rad => {
            dispatch(getKunde({ kundeId: rad.leasingtakerKundeId } as IGetKundeMedKundeId));
        });

        const unikeMedeiere = _.uniqBy(forstegangsregistreringrader, rad => rad.melderKundeId);
        _.forEach(unikeMedeiere, (rad: IArbeidslisteRad) => {
            dispatch(getKunde({ kundeId: rad.melderKundeId } as IGetKundeMedKundeId));
        });
    };
}

export function arbeidsListeNySak(navigate: NavigateType, nySakIder: string[], saksType: SaksType): ThunkAction<void, RootStateType, undefined, IArbeidslisteNySakAction> {
    return (dispatch, getState) => {
        dispatch({ type: ArbeidslisteActionKey.ARBEIDSLISTE_NY_SAK, nySakIder, sakstype: null });
        if (saksType) {
            const filter = utledFilter(saksType, getState().brukerprofil);
            navigate(`/startside?fane=${filter}`);
            dispatch(nyMelding({
                meldingIntlId: `melding.${saksType.toLowerCase()}.opprettet`,
                meldingType: MeldingType.SUCCESS,
                meldingId: 'arbeidslisteNySakMelding',
                varighetMs: NY_SAK_VISNINGSTID_MS
            }));
        }
    };
}

export function fjernSak<T extends ISaksinformasjon>(rad: ISak<T> & IError):
    ThunkAction<Promise<IArbeidslisteSakFjernSuccessAction | void>, RootStateType, undefined, IArbeidslisteSakFjernSuccessAction> {
    return dispatch =>
        api.remove(rad.sakId)
            .then(response =>
                response && response.errorId ? dispatch(addGlobalErrors(response)) : dispatch(removeRadSuccess(rad.sakId, convertSakstypeToSakstypefilter(rad.saksType)))
            );
}

export function purre(sakId: string, aktor: IAktor, varseltype: Varseltype): ThunkAction<void, RootStateType, undefined, GlobalErrorActionType | IMeldingBarNyMeldingAction> {
    return dispatch => {
        const body = aktor.valgtKontaktform === ValgtKontaktform.MOBIL ?
            {
                kundeId: aktor.kundeId,
                sakId,
                telefonnummer: aktor.mobiltelefon,
                meldingType: varseltype,
                valgtKontaktform: aktor.valgtKontaktform,
                skalBenytteRegistrertKontaktinformasjon: aktor.skalBenytteRegistrertKontaktinformasjon
            } :
            {
                kundeId: aktor.kundeId,
                sakId,
                epost: aktor.epost,
                meldingType: varseltype,
                valgtKontaktform: aktor.valgtKontaktform,
                skalBenytteRegistrertKontaktinformasjon: aktor.skalBenytteRegistrertKontaktinformasjon
            };
        varselApi.post(body)
            .then(response => {
                if (response && response.errorId) {
                    return dispatch(addGlobalErrors(response));
                }
                return dispatch(nyMelding({
                    meldingIntlId: 'sak.melding.purringSendt',
                    meldingType: MeldingType.SUCCESS
                }));
            });
    };
}

export function sortArbeidsliste(sortProp: string, sakstype: ArbeidslisteSakstypefilter): ThunkAction<void, RootStateType, undefined, ArbeidslisteActionType> {
    return dispatch => dispatch(sortArbeidslisteAction(sortProp, sakstype));
}

export function nyMerknader(sakId: string, merknader: IMerknad[]): ThunkAction<void, RootStateType, undefined, ArbeidslisteActionType> {
    return dispatch => dispatch({ type: ArbeidslisteActionKey.ARBEIDSLISTE_NY_MERKNADER, sakId, merknader });
}

function finnOgPopulerDokumentStatus(arbeidslisteRader: IArbeidslisteRad[]): ThunkAction<void, RootStateType, undefined, ArbeidslisteActionType> {
    return dispatch => {
        _.each(arbeidslisteRader, (arbeidsListeapi: IArbeidslisteRadApi) => {
            if (arbeidsListeapi?.sakId) {
                dispatch(arbeidslisteHentArkivdokumentLoadingAction(arbeidsListeapi.sakId));
            }
        });

        const request = { arbeidsliste: arbeidslisteRader } as IArbeidslisteDokumentasjonsstatusRequest;

        apiDokumentstatus.post(request)
            .then((response: IBaseApiResponsePageObject<IArbeidslisteDokumentasjonsstatus>) => {
                _.each(response.content, (rad: IArbeidslisteDokumentasjonsstatus) => {
                    dispatch(arbeidslisteHentArkivdokumentSuccessAction(rad.sakId, rad.arkivdokumenter, rad.merknader, rad.erTilGodkjenning));
                });
            });

        return Promise.resolve();
    };
}

const utledFilter = (sakstype: SaksType, brukerprofil: Brukerprofil): ArbeidslisteSakstypefilter => {
    const erFlateeier = new BrukerRegler(brukerprofil).harRolleFlateeier();
    if (_.isEqual(sakstype, SaksType.FORSTEGANGSREGISTRERING)) {
        return ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING;
    } else if (_.isEqual(sakstype, SaksType.EIERSKIFTE) && erFlateeier) {
        return ArbeidslisteSakstypefilter.EIERSKIFTE;
    } else {
        return ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING;
    }
};
