import * as React from 'react';
import styled from 'styled-components';

import type { IArbeidslisteRad } from '../../models/types';
import { CardCell } from './arbeidsliste-card-cell';
import type { IRadConfigProps} from './arbeidsliste-config';
import { RadConfigOrder } from './arbeidsliste-config';

interface IArbeidslisteCardContentSmallProps {
    rad: IArbeidslisteRad;
    radConfig: IRadConfigProps[];
    openModalForGodkjennEierskifte?: (sakId: string) => void;
    openModalForPurring?: (sakId: string) => void;
    isForstegangsregistrering: boolean;
    className?: string;
}

const Container = styled.div`
  position: relative;
`;

const FirstRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(90px, 1fr) 1fr 1fr 2fr;
  margin-bottom: 0.75rem;
`;

const SecondRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.75rem;
`;

const ThirdRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.5rem;
`;

const ArbeidslisteCardContentSmall: React.FC<IArbeidslisteCardContentSmallProps> = props => {
    const { className, rad, radConfig, openModalForGodkjennEierskifte, openModalForPurring, isForstegangsregistrering } = props;
    const commonProps = { rad, openModalForGodkjennEierskifte, openModalForPurring };

    return (
        <Container className={className}>
            <FirstRow>
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.SIST_ENDRET]} />
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.STATUS]} />
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.DOKUMENTASJONSSTATUS]} useAltLabel={true} />
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.KNAPPER]} className="align-items-end" hasLabel={false} />
            </FirstRow>
            <SecondRow>
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.KJENNEMERKE]} hasLabel={false} />
            </SecondRow>
            <ThirdRow>
                { !isForstegangsregistrering && <CardCell {...commonProps} config={radConfig[RadConfigOrder.GJELDER]} className="ak-fet" hasLabel={false} /> }
                { isForstegangsregistrering && <CardCell {...commonProps} config={radConfig[RadConfigOrder.ORDRENR]} /> }
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.EIER]}/>
            </ThirdRow>
        </Container>
    );
};

export { ArbeidslisteCardContentSmall };
