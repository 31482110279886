import { createSelector } from 'reselect';

import { RegelmerknaderKoder } from '../../models/kodeverk';
import { Merknader } from '../../regler';
import { sakSelector } from './registrering-selectors';

const traktorMedBruksbegrensningMerknadSelector = createSelector(
    sakSelector,
    (sak => Merknader.find(sak.merknader, { kode: RegelmerknaderKoder.KJORETOY_TRAKTOR_MED_BRUKSBEGRENSNING }).pop())
);

const fjerneTraktorMedBruksbegrensningMerknadSelector = createSelector(
    sakSelector,
    (sak => Merknader.find(sak.merknader, { kode: RegelmerknaderKoder.TRAKTOR_FJERNET_BRUKSBEGRENSNING }).pop())
);

export const harInnfriddTraktorMedBruksbegrensningMerknadSelector = createSelector(
    traktorMedBruksbegrensningMerknadSelector,
    (merknad => merknad?.obligatoriskeDokumenter?.every(dokument => dokument.innfridd))
);

export const harInnfriddFjerneTraktorMedBruksbegrensningMerknadSelector = createSelector(
    fjerneTraktorMedBruksbegrensningMerknadSelector,
    (merknad => merknad?.obligatoriskeDokumenter?.every(dokument => dokument.innfridd))
);