import { compose } from '@reduxjs/toolkit';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate, Routes, Route } from 'react-router-dom';
import { AkLoading } from 'svv-tk-akr-common-frontend';
import { IngenTreff } from '../../components';

import type { Brukerprofil, IKjoretoy, RootStateType } from '../../models/types';
import { BrukerRegler, KjoretoyRegler } from '../../regler/';
import { hentKjoringensArtListe, kjoretoyClearStateAction, kjoretoyGetDataFromKuidAction } from '../../state/actions';
import { kanStarteOgBehandleForstegangsregistreringSelector, kuidSelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';
import { EierskifteConnected } from '../eierskifte';
import { ErstatningskjennemerkebestillingConnected } from '../erstatningskjennemerker';
import { ForstegangsregistreringConnected } from '../forstegangsregistrering';
import { KjoretoydetaljerConnected } from '../kjoretoydetaljer';
import { LeasingtakerConnected } from '../leasingtaker/leasingtaker';
import { RegistreringWrapperConnected } from '../registrering';

interface IKjoretoyStateProps {
    kuid: string;
    kjoretoy: IKjoretoy;
    isLoading: boolean;
    notFound: boolean;
    error: any;
    harRolleEierskifte: boolean;
    harRolleOmreg: boolean;
    harRolleForstegangsregistrering: boolean;
    hasRolleForTilgangTilForstegangsregistrering: boolean;
    kanStarteOgBehandleForstegangsregistreringSelector: boolean;
    harRolleFlateeier: boolean;
    brukerprofil: Brukerprofil;
}

interface IKjoretoyDispatchProps {
    getKjoretoyFromKuid: (kuid: string) => Promise<any>;
    clearKjoretoy: () => void;
    hentKjoringensArtListe: (kuid?: string, kundeId?: string) => void;
}

interface IKjoretoyState {
    kuid: string;
    sakId: string;
}

type PropsType = IKjoretoyStateProps & IKjoretoyDispatchProps & WithRouterProps;

class Kjoretoy extends React.Component<PropsType, IKjoretoyState> {

    public state: IKjoretoyState = {
        kuid: '',
        sakId: ''
    };

    public componentDidMount() {
        this.hentKjoretoy();
    }

    public componentDidUpdate(prevProps: PropsType) {
        this.hentKjoretoy();

        if (this.props.kjoretoy.registrering?.eier?.kundeId && !prevProps.kjoretoy.kuid && this.props.kjoretoy.kuid) {
            this.props.hentKjoringensArtListe(this.props.kuid, this.props.kjoretoy.registrering.eier.kundeId);
        }
    }

    public componentWillUnmount() {
        this.props.clearKjoretoy();
    }

    public render() {
        if (this.props.notFound) {
            return <Navigate to="/feil/ikkefunnet" />;
        }

        if (this.props.kjoretoy.ikkeVisKjoretoy) {
            return <IngenTreff informasjonIkkeTilgjengeligIBransje={true} />;
        }

        if (this.isLoading()) {
            return <AkLoading isLarge={true} displayTextKey="generell.loading.kan-ta-tid" displayTextKeyLarge="generell.loading.henter-kjoretoyet" />;
        }

        return (
            <Routes>
                <Route index element={<KjoretoydetaljerConnected />} />
                <Route path="eierskifte/:sakId?"
                    element={this.harValgtEnhet() ?
                             (this.harRollenEierskifte() ? <EierskifteConnected /> : <Navigate to="/ikkefunnet" />) : null} />
                <Route path="registrering/:sakId?"
                    element={this.harValgtEnhet() ?
                             (this.harRollenOmreg() ? <RegistreringWrapperConnected /> : <Navigate to="/ikkefunnet" />) : null} />
                <Route path="forstegangsregistrering"
                    element={this.harValgtEnhet() ?
                             (this.kanStarteOgBehandleForstegangsregistreringSelector() ? <ForstegangsregistreringConnected /> : <Navigate to="/ikkefunnet" />) : null} />
                <Route path="forstegangsregistrering/:sakId?"
                    element={this.harValgtEnhet() ?
                             (this.harTilgangTilForstegangsregistreringssak() ? <ForstegangsregistreringConnected /> : <Navigate to="/ikkefunnet" />) : null} />
                <Route path="leasingtaker/:sakId?"
                    element={this.harValgtEnhet() ?
                             (this.kanEndreLeasingtaker() ? <LeasingtakerConnected /> : <Navigate to="/ikkefunnet" />) : null} />
                <Route path="erstatningskjennemerker/bestille"
                    element={this.harValgtEnhet() ?
                             (this.harRollenOmreg() ? <ErstatningskjennemerkebestillingConnected /> : <Navigate to="/ikkefunnet" />) : null} />

            </Routes>
        );
    }

    private harRollenEierskifte = () => this.props.harRolleEierskifte || this.props.harRolleFlateeier;
    private harRollenOmreg = () => this.props.harRolleOmreg;
    private harTilgangTilForstegangsregistreringssak = () => this.props.hasRolleForTilgangTilForstegangsregistrering && !new KjoretoyRegler(this.props.kjoretoy).erGjenstandForEtterregistrering();
    private kanStarteOgBehandleForstegangsregistreringSelector = () => this.props.kanStarteOgBehandleForstegangsregistreringSelector;
    private harValgtEnhet = () => this.props.brukerprofil.valgtEnhet;
    private kanEndreLeasingtaker = () => this.props.harRolleFlateeier || this.props.harRolleEierskifte || this.props.harRolleForstegangsregistrering;

    private isLoading = (): boolean => {
        const { kuid } = this.props.params;
        return this.props.isLoading || (this.props.kuid && this.props.kuid.toString() !== kuid || !this.props.kuid);
    };

    private hentKjoretoy() {
        if (this.props.kuid && this.props.kuid === this.props.params.kuid) {
            return;
        }

        const { kuid: previousKuid, sakId: previousSakId } = this.state;
        const { kuid, sakId } = this.props.params;

        if (kuid !== previousKuid) {
            // Lagre kuid og sakId hvis verdiene er endret
            this.setState({ kuid, sakId }, () => {
                if (kuid && (kuid !== previousKuid || (sakId && sakId !== previousSakId))) {
                    // Hent kjøretøy hvis kuid er endret eller isExact er endret fra false til true (=> man går fra sak til kjøretøydetaljer)
                    this.props.getKjoretoyFromKuid(kuid);
                }
            });

        } else if (!kuid) {
            this.props.navigate('/startside');
        }
    }
}

const mapStateToProps = (state: RootStateType): IKjoretoyStateProps => ({
    kuid: kuidSelector(state),
    isLoading: state.kjoretoy.isLoading,
    notFound: state.kjoretoy.notFound,
    error: state.kjoretoy.error,
    harRolleEierskifte: BrukerRegler.adapter(state).harRolleEierskifte(),
    harRolleOmreg: BrukerRegler.adapter(state).harRolleOmreg(),
    harRolleForstegangsregistrering: BrukerRegler.adapter(state).harRolleForstegangsregistrering(),
    hasRolleForTilgangTilForstegangsregistrering: BrukerRegler.adapter(state).harRolleForTilgangTilForstegangsregistrering(),
    kanStarteOgBehandleForstegangsregistreringSelector: kanStarteOgBehandleForstegangsregistreringSelector(state),
    harRolleFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    kjoretoy: state.kjoretoy,
    brukerprofil: state.brukerprofil
});

const mapDispatchToProps = (dispatch): IKjoretoyDispatchProps => ({
    getKjoretoyFromKuid: (kuid: string) => dispatch(kjoretoyGetDataFromKuidAction(kuid)),
    clearKjoretoy: () => dispatch(kjoretoyClearStateAction()),
    hentKjoringensArtListe: (kuid?: string, kundeId?: string) => dispatch(hentKjoringensArtListe(kuid, kundeId))
});

const KjoretoyConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)
(Kjoretoy);

export { KjoretoyConnected };
