import type { EnhetType, SorteringRetning, SorteringType } from '../../constants';
import { AkrConfig, EgneKjoretoyActionKey, RegistreringStatusFilterValg, RegistreringStatusKode } from '../../constants';
import type { EgneKjoretoyResponse, IBaseApiResponseObject, IError, ThunkResult } from '../../models/types';
import { createRestResource } from '../store';

export type EgneKjoretoyActionType = IHentKjoretoyEgneStartAction | IHentEgneKjoretoySuccessAction | IHentEgneKjoretoyErrorAction;

interface IHentKjoretoyEgneStartAction extends IBaseApiResponseObject {
    type: EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_KUNDE_START;
}

interface IHentEgneKjoretoySuccessAction extends IBaseApiResponseObject {
    type: EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_SUCCESS;
    egneKjoretoy: EgneKjoretoyResponse;
}

interface IHentEgneKjoretoyErrorAction extends IBaseApiResponseObject {
    type: EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_ERROR;
}

const hentEgneKjoretoyStart = (): IHentKjoretoyEgneStartAction => ({
    type: EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_KUNDE_START
});

const hentEgneKjoretoySuccess = (egneKjoretoy: EgneKjoretoyResponse): IHentEgneKjoretoySuccessAction => ({
    type: EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_SUCCESS,
    egneKjoretoy
});

const hentEgneKjoretoyError = (error: IError): IHentEgneKjoretoyErrorAction => ({
    type: EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_ERROR,
    error
});

const api = createRestResource(AkrConfig.KUNDE_KJORETOY_RESOURCE_URL);

export function hentEgneKjoretoy(enhetType: EnhetType,
                                 registreringstatusFilterValg: RegistreringStatusFilterValg,
                                 isCountOnly: boolean,
                                 side: number,
                                 pagineringAntall: number,
                                 sorteringType: SorteringType,
                                 sorteringRetning: SorteringRetning): ThunkResult<Promise<EgneKjoretoyActionType | void>, EgneKjoretoyActionType> {
    return dispatch => {
        dispatch(hentEgneKjoretoyStart());
        const registreringsstatuser = registreringstatusConverter(registreringstatusFilterValg);
        return api.fetch({enhetType, registreringsstatuser, isCountOnly, side, pagineringAntall, sorteringType, sorteringRetning})
            .then((response) => {
                if (response.errorId || response.errorCode) {
                    dispatch(hentEgneKjoretoyError(response));
                } else {
                    dispatch(hentEgneKjoretoySuccess(response));
                }
            });
    };
}

const registreringstatusConverter = (registreringstatuser: RegistreringStatusFilterValg): RegistreringStatusKode[] => {
    if (registreringstatuser === RegistreringStatusFilterValg.ALLE) {
        return [RegistreringStatusKode.REGISTRERT, RegistreringStatusKode.AVREGISTRERT];
    } else if (registreringstatuser === RegistreringStatusFilterValg.AVREGISTRERT) {
        return [RegistreringStatusKode.AVREGISTRERT];
    } else {
        return [RegistreringStatusKode.REGISTRERT];
    }
};
