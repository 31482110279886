import * as _ from 'lodash';
import { AkrConfig, GrunnlagVektarsavgiftActionKey } from '../../constants';
import type { IError, IGrunnlagVektarsavgift, ThunkResult } from '../../models/types';
import { createRestResource } from '../store';
import { isError } from './registreringer-arkivdokumenter';

const api = createRestResource(AkrConfig.GRUNNLAG_VEKTARSAVGIFT_RESOURCE_URL);

interface IGrunnlagVektarsavgiftSuccessAction {
    type: GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_SUCCESS;
    grunnlag: IGrunnlagVektarsavgift;
}

interface IGrunnlagVektarsavgiftLoadingAction {
    type: GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_LOADING;
    isLoading: boolean;
}

interface IGrunnlagVektarsavgiftErrorAction {
    type: GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_ERROR;
    error: IError;
}

interface IGrunnlagVektarsavgiftResetAction {
    type: GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_RESET;
}

export type GrunnlagVektarsavgiftType =
    IGrunnlagVektarsavgiftSuccessAction
    | IGrunnlagVektarsavgiftLoadingAction
    | IGrunnlagVektarsavgiftErrorAction
    | IGrunnlagVektarsavgiftResetAction;

export const grunnlagVektarsavgiftSuccessAction = (grunnlagVektarsavgift: IGrunnlagVektarsavgift): IGrunnlagVektarsavgiftSuccessAction => ({
    type: GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_SUCCESS,
    grunnlag: grunnlagVektarsavgift
});

export const grunnlagVektarsavgiftLoadingAction = (isLoading: boolean): IGrunnlagVektarsavgiftLoadingAction => ({
    type: GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_LOADING,
    isLoading
});

export const grunnlagVektarsavgiftErrorAction = (error: IError): IGrunnlagVektarsavgiftErrorAction => ({
    type: GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_ERROR,
    error
});

export const grunnlagVektarsavgiftResetAction = (): IGrunnlagVektarsavgiftResetAction => ({
    type: GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_RESET
});

export function finnGrunnlagVektarsavgift(kuid: string): ThunkResult<void | Promise<GrunnlagVektarsavgiftType | void>, GrunnlagVektarsavgiftType> {
    return (dispatch) => {
        dispatch(grunnlagVektarsavgiftLoadingAction(true));
        api.get(null, {kuid}).then(response => {
                if (_.isEmpty(response)) {
                    dispatch(grunnlagVektarsavgiftSuccessAction(response));
                } else if (isError(response)) {
                    dispatch(grunnlagVektarsavgiftErrorAction(response));
                } else {
                    dispatch(grunnlagVektarsavgiftSuccessAction(response));
                }
                return dispatch(grunnlagVektarsavgiftLoadingAction(false));
            }
        );
    };
}

export function resetGrunnlagVektarsavgift(): ThunkResult<void | Promise<GrunnlagVektarsavgiftType | void>, GrunnlagVektarsavgiftType> {
    return (dispatch) => {
        dispatch(grunnlagVektarsavgiftResetAction());
    };
}