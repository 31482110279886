import { AkKnapp, AkModal, AkModalType } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import type { IEierskiftemodalProps } from './eierskifte-modaler';

export const BulkLukkModal: React.FC<IEierskiftemodalProps> = (props) => {

    return (
        <AkModal showModal={true} modalType={AkModalType.WARN} title="eierskifte.modal.lukkTitle" closeModal={props.lukkModal}>
            <p><FormattedMessage id="eierskifte.modal.lukkBodyText" /></p>
            <div className="ak-knapperad-modal">
                <Link key="lukk" className="ak-knapp ak-hovedknapp" to="/startside">
                    <FormattedMessage id="eierskifte.modal.lukkEierskifte" />
                </Link>
                <AkKnapp key="fortsett" type="ak-knapp ak-tilleggsknapp" action={props.lukkModal} intlKey="eierskifte.modal.forsetteEierskifte" />
            </div>
        </AkModal>
    );

};