import type { Bruker, Brukerprofil, RootStateType } from '../../models/types';


const brukerprofil: Brukerprofil = {
    bruker: {} as Bruker,
    valgtEnhet: null
};

const initialState: Partial<RootStateType> = {
    brukerprofil,
    globals: {
        hovedmeny: null,
        globalErrors: [],
        klientKonfigurasjon: {}
    }
};

export { initialState };
