import * as React from 'react';

interface ITextInputGruppeRadProps {
    id?: string;
    name: string;
    label?: string;
    value: string;
    changeHandler: (event: React.SyntheticEvent<any>) => void;
}

class TextInputGruppeRad extends React.Component<ITextInputGruppeRadProps & React.PropsWithChildren> {

    public static defaultProps = {
        label: '',
        value: ''
    };

    public render(): React.ReactNode {
        const {id, name, label, value, changeHandler} = this.props;
        return (
            <div className="ak-input-gruppe row">
                <label htmlFor={id || name} className="col-3">{label}</label>
                <input id={id || name} type="text" name={name} value={value}
                    onChange={changeHandler} className="col-5" />
                {this.props.children}
            </div>
        );
    }
}

export { TextInputGruppeRad };
