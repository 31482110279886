import type { IMessageDescriptor } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface IMeldingslisteProps {
    meldinger: IMessageDescriptor[];
}

interface MeldingProps {
    melding: IMessageDescriptor
}

const Melding: React.FC<MeldingProps> = (props) => {
    return <FormattedMessage {...props.melding} />;
};

const MeldingListItem: React.FC<MeldingProps> = (props) => {
    return (
        <li>
            <Melding melding={props.melding}/>
        </li>
    );
};

const Meldingsliste: React.FC<IMeldingslisteProps> = props => {
    if (!props.meldinger || !props.meldinger.length) {
        return null;
    }

    if (props.meldinger.length === 1) {
        return <Melding melding={props.meldinger[0]} />;
    }

    return (
        <ul className="ak-melding-liste">
            {props.meldinger.map((melding: IMessageDescriptor, i: number) =>
                <MeldingListItem key={`melding-${i}`} melding={melding}/>)
            }
        </ul>
    );
};

export type { IMeldingslisteProps };
export { Meldingsliste };
