import { AkKnapp, AkModal, AkModalType } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { IEierskiftemodalProps } from '../eierskifte/modaler';
import { useFlyttmodaltekst } from './use-flyttmodaltekst';

interface IFlyttBekreftModalProps {
    tilAktorNavn: string;
}

export const FlyttBekreftModal: React.FC<IEierskiftemodalProps & IFlyttBekreftModalProps> = (props) => {

    const tekster = useFlyttmodaltekst();

    return (
        <AkModal showModal={true} modalType={AkModalType.INFO} title="flyttkjoretoy.overskrift.flyttkjoretoy" closeModal={props.lukkModal}>
            <p><FormattedMessage id={tekster.bodytekst1} values={{ navn: props.tilAktorNavn }} /></p>
            {tekster.visBodytekst2 && <p><FormattedMessage id="eierskifte.modal.bruddPaAvtaleFlereEierskifter" /></p>}
            <div className="ak-knapperad-modal">
                <AkKnapp key="bekreft" type="ak-knapp ak-hovedknapp" action={props.modalhandling} intlKey="flyttkjoretoy.modal.bekreftOgFlytt" />
                <AkKnapp key="fortsett" type="ak-knapp ak-tilleggsknapp" action={props.lukkModal} intlKey="flyttkjoretoy.modal.avbryt" />
            </div>
        </AkModal>
    );

};