import * as React from 'react';
import type { IntlShape } from 'react-intl';
import { IntlProvider } from 'react-intl';
import type { IntlConfig } from 'react-intl/src/types';
import { connect } from 'react-redux';
import type { RootStateType } from '../../models/types';

interface AkIntlProviderProps {
    intl?: IntlShape;
}

const AkIntlProvider = (props: AkIntlProviderProps & React.PropsWithChildren<IntlConfig>) => {
    return (
        <IntlProvider locale={props.locale} messages={props.intl.messages} textComponent="span">
            {props.children}
        </IntlProvider>
    );
};

const mapStateToProps = (state: RootStateType): AkIntlProviderProps => ({
    intl: state.intl
});

const AkIntlProviderConnected = connect(mapStateToProps)(AkIntlProvider);
export { AkIntlProviderConnected };
