import type { IGodkjenning } from '../models/types';

class TilhengervektRegler {

    public static overskriderOppgittTilhengervektTillattTilhengervekt(godkjenning: IGodkjenning, totalvektTilhenger: string): boolean {
        const tillattVogntogvekt = godkjenning.tillattVogntogvekt;
        const egenvekt = godkjenning.egenvekt;

        return !!totalvektTilhenger && !!tillattVogntogvekt && !!egenvekt
            && Number(totalvektTilhenger) > this.rundOppTilNermesteTusen(Number(tillattVogntogvekt) - Number(egenvekt));
    }

    private static rundOppTilNermesteTusen(vekt: number): number {
        const tusen = 1000;
        return Math.ceil(vekt / tusen) * tusen;
    }

}

export { TilhengervektRegler };
