import classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { MeldingType } from '../../models/kodeverk';
import type { IMelding } from '../../models/types';
import { renderMelding } from './melding-bar-melding-format';

interface IMeldingProps {
    melding: IMelding;
    fjernAlleMeldingerAvType: (meldingType: MeldingType) => any;
    fjern: (melding: IMelding) => any;
}

class MeldingBarMelding extends Component<IMeldingProps & WrappedComponentProps, {animateCSS: string}> {

    public state = {
        animateCSS: ''
    };

    public componentDidMount() {
        const timeout = 50;
        setTimeout(() => {
            if (this.props.melding.meldingType === MeldingType.WARN || this.props.melding.meldingType === MeldingType.DANGER) {
                window.scrollTo(0, 0);
            }
            this.setState({animateCSS: 'ak-melding-bar-melding-aktiv'});
        }, timeout);
    }

    public render() {
        const {melding} = this.props;

        const buttonLabel = this.props.intl.formatMessage({id: 'melding.lukk'});
        const meldingCss = classNames('ak-melding-bar-melding', `ak-melding-bar-${melding.meldingType}`, this.state.animateCSS);

        return (
            <li className={meldingCss}>
                <i className="ak-melding-ikon fa mr-3" />
                {melding.meldingIntlId &&
                <p className="ak-melding-bar-melding-innhold">
                    <FormattedMessage id={melding.meldingIntlId} values={{...melding.meldingArgs}} />
                </p>
                }
                {renderMelding(melding.meldingText)}
                {melding.link &&
                <Link className="ml-auto" to={melding.link} onClick={this.fjernAlleMeldingerAvType}>Les mer</Link>
                }
                <button aria-label={buttonLabel} onClick={this.slett}
                    className="ak-melding-bar-melding-fjern ak-knapp fa fa-lg fa-times" />
            </li>
        );
    }

    private fjernAlleMeldingerAvType = () => {
        this.props.fjernAlleMeldingerAvType(this.props.melding.meldingType);
    };

    private slett = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        event.preventDefault();
        this.props.fjern(this.props.melding);
    };
}

const MeldingBarMeldingConnected = injectIntl(MeldingBarMelding);

export { MeldingBarMeldingConnected };
