import type { IAkConfirmNavigationProps} from 'svv-tk-akr-common-frontend';
import { AkConfirmNavigation, AkKnapp, AkLoading, AkMeldingboks, AkMeldingboksType, Panel } from 'svv-tk-akr-common-frontend';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { AvbrytSakModal } from '../../components/modal';
import type { IErstatningskjennemerkerSaksinformasjon, IKjoretoy, ISak, RootStateType } from '../../models/types';
import { BrukerRegler, KjennemerkeRegler } from '../../regler';
import { kjoretoyGetDataAction, opprettErstatningskjennemerkerSak } from '../../state/actions';
import { ErstatningskjennemerkeInfoPanel } from './erstatningskjennemerke-info';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';

interface IErstatningskjennemerkerStateProps {
    kjoretoy: IKjoretoy;
    sak: ISak<IErstatningskjennemerkerSaksinformasjon>;
    harRolleOmreg: boolean;
}

interface IErstatningskjennemerkerDispatchProps {
    opprettErstatningskjennemerkerSak: (kuid: string) => void;
    kjoretoyGetDataAction?: (kjennemerke: string) => Promise<any>;
}

interface IErstatningskjennemerkerState {
    showAvbrytModal?: boolean;
}

type ErstatningskjennemerkeBestillingProps = IErstatningskjennemerkerDispatchProps & WrappedComponentProps & IAkConfirmNavigationProps & IErstatningskjennemerkerStateProps &
    WithRouterProps;

interface KjennemerkeLinkProps {
    tekst: string;
}

const KjennemerkeLink: React.FC<KjennemerkeLinkProps> = (props) => {
    const lenkeTilKjennemerkeapplikasjonen = useSelector((state: RootStateType) => state.globals.klientKonfigurasjon.linker.kjennemerkeKlient);

    return (
        <a href={`${lenkeTilKjennemerkeapplikasjonen}#/innstillinger`} target="_blank" rel="noopener noreferrer">
            {props.tekst}
        </a>
    );
};

const kjennemerkeLink = (tekst) => <KjennemerkeLink tekst={tekst}/>;

const ForsendelsesadresseManglerMelding: React.FC = () => {
    const sak = useSelector((state: RootStateType) => state.sak);

    return (
        <AkMeldingboks meldingType={AkMeldingboksType.ERROR}>
            {sak.merknader.map(merknad =>
                <FormattedMessage key={merknad.kode} id={`erstatningskjennemerker.merknad.${merknad.kode}`} values={{
                    kjennemerkeLink
                }}/>
            )}
        </AkMeldingboks>
    );
};

class Erstatningskjennemerkebestilling extends React.Component<ErstatningskjennemerkeBestillingProps, IErstatningskjennemerkerState> {

    public initialState: IErstatningskjennemerkerState = {
        showAvbrytModal: false
    };

    public componentDidUpdate(prevProps: Readonly<ErstatningskjennemerkeBestillingProps>): void {
        if(!prevProps.sak.sakId && !!this.props.sak.sakId){
            this.lukkSak();
        }
    }

    public render(): React.ReactElement {
        if (this.props.kjoretoy.isLoading) {
            return <AkLoading />;
        }

        return (
            <div className="container no-gutters mx-auto">
                <div className="row">
                    {this.renderHeader()}
                    <div className="col-12">
                        <Panel overskrift="erstatningskjennemerker.label.bestilling">
                            <ErstatningskjennemerkeInfoPanel
                                kjoretoy={this.props.kjoretoy}
                                containerClasses="mb-3" />
                        </Panel>
                    </div>
                    <AvbrytSakModal
                        isLoading={this.props.kjoretoy.isLoading}
                        title="erstatningskjennemerker.modal.avbrytBekreftelseTitle"
                        bodyTextKey="erstatningskjennemerker.modal.avbrytBekreftelseBodyText"
                        avbrytIntlKey="erstatningskjennemerker.modal.knapp.nei"
                        showModal={this.state?.showAvbrytModal}
                        avbryt={this.avbrytSak}
                        close={this.lukkModal}
                    />
                </div>
            </div>
        );
    }

    private lukkModal = () => {
        this.props.confirmNavigation(false);
        this.setState({showAvbrytModal: false});
    };

    private avbrytSak = () => {
        this.lukkModal();
        this.lukkSak();
    };

    private lukkSak = () => {
        this.props.kjoretoyGetDataAction(this.props.kjoretoy.registrering.kjennemerke.tegnkombinasjon);
        this.props.navigate(`/kjoretoy/${this.props.kjoretoy.kuid}`);
    };

    private renderHeader = () => {

        return (
            <header className="col-12">
                <div className="row no-gutters align-items-baseline">
                    <h1><FormattedMessage id={'erstatningskjennemerker.overskrift.bestilling'} /></h1>
                    {this.renderKnapperad()}
                </div>

                {KjennemerkeRegler.manglerBestillerForsendelsesadresse(this.props.sak.merknader) &&
                    <div className="mb-3">
                        <ForsendelsesadresseManglerMelding />
                    </div>
                }
            </header>
        );
    };

    private renderKnapperad = () => (
        <div className="ak-knapperad row ml-auto no-gutters">
            <AkKnapp type="ak-tilleggsknapp" intlKey="generell.knapp.avbryt" action={this.apneAvbrytModal} disabled={this.props.sak.isLoading}/>
            {
                this.props.harRolleOmreg &&
                <AkKnapp intlKey="erstatningskjennemerker.knapp.bestillKjennemerker" action={this.submit} loading={this.props.sak.isLoading}/>
            }
        </div>
    );

    private submit = () => {
        this.props.confirmNavigation(false);
        this.props.opprettErstatningskjennemerkerSak(this.props.params.kuid);
    };

    private apneAvbrytModal = () => {
        this.setState({showAvbrytModal: true});
    };

}

const mapStateToProps = (state: RootStateType): IErstatningskjennemerkerStateProps => ({
    kjoretoy: state.kjoretoy,
    sak: state.sak,
    harRolleOmreg: BrukerRegler.adapter(state).harRolleOmreg()
});

const mapDispatchToProps = (dispatch): IErstatningskjennemerkerDispatchProps => ({
    opprettErstatningskjennemerkerSak: (kuid: string) => dispatch(opprettErstatningskjennemerkerSak(kuid)),
    kjoretoyGetDataAction: (kjennemerke: string) => dispatch(kjoretoyGetDataAction(kjennemerke))
});

const ErstatningskjennemerkebestillingConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withRouter,
    AkConfirmNavigation
)(Erstatningskjennemerkebestilling);

export type { ErstatningskjennemerkeBestillingProps, KjennemerkeLinkProps };
export { ErstatningskjennemerkebestillingConnected, Erstatningskjennemerkebestilling, ForsendelsesadresseManglerMelding, KjennemerkeLink };
