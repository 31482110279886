import { AkrConfig, OppslagActionKey } from '../../constants';
import type { IError, IOppslagTreff, ThunkResult } from '../../models/types';
import { createRestResource } from '../store';
import { addGlobalErrors, globalErrors } from './globals';
import { fjernAlleMeldinger } from './melding-bar';

export type OppslagActionType =
    IOppslagIsLoadingAction
    | IOppslagFetchDataSuccessAction
    | IOppslagHasServerErrorAction
    | IOppslagHasErrorAction
    | IOppslagResetAction
    | IOppslagOppdaterSokeFeltAction;

export interface IOppslagIsLoadingAction {
    type: OppslagActionKey.OPPSLAG_IS_LOADING;
    isLoading: boolean;
}

export interface IOppslagFetchDataSuccessAction {
    type: OppslagActionKey.OPPSLAG_FETCH_DATA_SUCCESS;
    treff: IOppslagTreff[];
    antallTreff: number;
    informasjonIkkeTilgjengeligIBransje: boolean;
}

export interface IOppslagHasServerErrorAction {
    type: OppslagActionKey.OPPSLAG_HAS_SERVER_ERROR;
    error: IError;
}

export interface IOppslagHasErrorAction {
    type: OppslagActionKey.OPPSLAG_HAS_ERROR;
    hasError: boolean;
}

export interface IOppslagResetAction {
    type: OppslagActionKey.OPPSLAG_RESET_SOKE_FELT;
}

export interface IOppslagOppdaterSokeFeltAction {
    type: OppslagActionKey.OPPSLAG_OPPDATER_SOKE_FELT;
    query: string;
}

export const oppslagHasServerError = (error: IError): IOppslagHasServerErrorAction => ({ type: OppslagActionKey.OPPSLAG_HAS_SERVER_ERROR, error });
export const oppslagHasError = (hasError: boolean): IOppslagHasErrorAction => ({ type: OppslagActionKey.OPPSLAG_HAS_ERROR, hasError });
export const oppslagReset = (): IOppslagResetAction => ({ type: OppslagActionKey.OPPSLAG_RESET_SOKE_FELT });
export const oppslagOppdaterSokeFeltAction = (query: string): IOppslagOppdaterSokeFeltAction => ({ type: OppslagActionKey.OPPSLAG_OPPDATER_SOKE_FELT, query });
export const oppslagIsLoading = (isLoading: boolean): IOppslagIsLoadingAction => ({ type: OppslagActionKey.OPPSLAG_IS_LOADING, isLoading });
export const oppslagFetchDataSuccess = (treff: IOppslagTreff[], antallTreff: number, informasjonIkkeTilgjengeligIBransje: boolean): IOppslagFetchDataSuccessAction => ({
    type: OppslagActionKey.OPPSLAG_FETCH_DATA_SUCCESS,
    treff,
    antallTreff,
    informasjonIkkeTilgjengeligIBransje
});

const api = createRestResource(AkrConfig.OPPSLAG_RESOURCE_URL);

export function gjorOppslag(query: string, kunUregistrerte = false): ThunkResult<Promise<void | OppslagActionType>, OppslagActionType> {
    return dispatch => {
        dispatch(globalErrors('RESET_ERRORS', null));
        dispatch(oppslagOppdaterSokeFeltAction(query));
        dispatch(oppslagIsLoading(true));
        dispatch(fjernAlleMeldinger());

        return api.fetch({q: query, kunUregistrerte})
            .then(response => {
                if (response.errorId) {
                    dispatch(addGlobalErrors(response));
                    dispatch(oppslagHasServerError(response));
                } else {
                    dispatch(oppslagFetchDataSuccess(response.kjoretoy, response.antallTreff, response.informasjonIkkeTilgjengeligIBransje));

                    if (response.antallTreff === 1) {
                        dispatch(oppslagReset());
                    }
                }
            })
            .catch((error) => {
                dispatch(addGlobalErrors(error.response || error));
                dispatch(oppslagIsLoading(false));
                return dispatch(oppslagHasError(true));
            });
    };
}
