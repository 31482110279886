import * as React from 'react';
import { Component } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
    AkCopyToClipboardSymbol, AkKnapp, AkModal, AkModalType, DisplayMode, KjennemerkeKategoriEnum, KjennemerkeSettEnum, KjennemerkeTypeEnum, Skiltsett, utledKjennemerketsett, velgKjennemerkeSett
} from 'svv-tk-akr-common-frontend';
import { skalForstorres } from 'svv-tk-akr-common-frontend/dist/components/kjoretoy-info-panel/kjoretoy-info-utils';
import { SaksType } from '../../models/kodeverk';
import type {
    Brukerprofil, IGodkjenning, IKjennemerke, IKjoretoy, IMelding, IPkkFrist, IRegistrering, IRegistreringSak, IRegistreringsinformasjon, ITekniskeData, ITilleggsgodkjenning, RootStateType
} from '../../models/types';
import { BrukerRegler, KjoretoyRegler, Merknader } from '../../regler';
import { hentMidlertidigVognkort, nyMelding, opprettMidlertidigAvregistrering } from '../../state/actions';
import { utleverKjennemerkerUtenRegistreringAction } from '../../state/actions/utlevere-uten-registrering';
import { godkjenningSelector, kjennemerkeSelector, kjoretoySelector, registreringSakSelector, registreringSelector } from '../../state/selectors';
import { getFargeFraTekniskeData } from '../../utils';
import { UtlevereUtenRegistreringModal } from '../kjennemerkeutlevering/utlevere-uten-registrering-modal';
import {
    KjoretoydetaljerGenereltConnected, KjoretoydetaljerKnapperadConnected, KjoretoydetaljerMeldinger, KjoretoydetaljerMerknader, KjoretoydetaljerPkk, KjoretoydetaljerRegistrering,
    KjoretoydetaljerTilleggsgodkjenninger
} from '../kjoretoydetaljer';
import { KjoretoydetaljerKjennemerkerConnected } from './kjoretoydetaljer-kjennemerker';

interface IKjoretoydetaljerStateProps {
    godkjenning?: IGodkjenning;
    registrering?: IRegistrering;
    kjoretoy?: IKjoretoy;
    brukerprofil?: Brukerprofil;
    registreringSak?: IRegistreringSak;
    kjennemerke?: string;
    match?: any;
    isFlateeier?: boolean;
}

interface IKjoretoydetaljerDispatchProps {
    opprettMidlertidigAvregistrering: (registreringSak: IRegistreringSak) => Promise<any>;
    nyMelding: (melding: IMelding) => Promise<any>;
    hentMidlertidigVognkort: (kuid: string) => void;
    utleverKjennemerkerUtenRegistrering: (kjoretoy: IKjoretoy) => void;
}

type ModalType = 'info' | 'warn';

interface IAkModalContent {
    showModal: boolean;
    title?: string;
    bodyText?: string;
    meldingModalType?: ModalType;
}

interface IKjoretoydetaljerState {
    modalInfo: IAkModalContent;
    loadingAvregistrerKjoretoy: boolean;
    visUtlevereUtenRegistreringModal: boolean;
}

const renderModaltext = (messages) => {
    return messages.map((message, index) => <div key={index}>{message}</div>);
};

const formaterModalBodyText = (id: string) => {
    const prefix = 'kjoretoydetaljer.modal.';
    return <p key={id}><FormattedMessage id={prefix.concat(id)} /></p>;
};

const utledModalBodyText = () => {
    const messages = [];
    messages.push(
        formaterModalBodyText('advarselKjoretoyetErAvregistrert'),
        formaterModalBodyText('advarselBrukAvregistrertKjoretoyStraffbartBody'),
        <br />,
        formaterModalBodyText('bekreftelseOppbevaring'),
        formaterModalBodyText('advarselAvregistrertKjoretoyBody')
    );
    return renderModaltext(messages);
};

const modalInformasjon = {
    midlertidigAvregistrering: {
        title: 'kjoretoydetaljer.modal.avregistrerMidlertidigTitle',
        bodyText: utledModalBodyText(),
        meldingModalType: 'info'
    }
};

class Kjoretoydetaljer extends Component<IKjoretoydetaljerStateProps & IKjoretoydetaljerDispatchProps & WrappedComponentProps, IKjoretoydetaljerState> {

    public state = {
        modalInfo: {
            showModal: false,
            title: '',
            bodyText: '',
            meldingModalType: AkModalType.INFO
        },
        loadingAvregistrerKjoretoy: false,
        visUtlevereUtenRegistreringModal: false
    };

    public render(): React.ReactElement {

        if (Merknader.exists(this.props.kjoretoy.merknader, {
            kode: 'BRUKER_HAR_IKKE_RETTIGHETER',
            kontekst: 'akr.forhandler.kjoretoy'
        })) {
            return <Navigate to="/feil/ikkefunnet" />;
        }

        const {
            merke = '',
            tekniskeData = {} as ITekniskeData,
            tilleggsgodkjenninger = [] as ITilleggsgodkjenning[],
            understellsnummer = ''
        } = this.props.godkjenning;
        const {
            registreringsinformasjon = {} as IRegistreringsinformasjon,
            kjennemerke = {} as IKjennemerke,
            personligKjennemerke = {} as IKjennemerke,
            pkkFrist = {} as IPkkFrist
        } = this.props.registrering;

        const modell = !!tekniskeData.handelsbetegnelse ? ` ${tekniskeData.handelsbetegnelse}` : '';
        const farge = getFargeFraTekniskeData(tekniskeData);
        const registrertForsteGangAr = registreringsinformasjon.registrertForsteGangAr ? ` (${registreringsinformasjon.registrertForsteGangAr})` : ', ';
        const kjennemerkeSett = utledKjennemerketsett(this.props.registrering?.kjennemerke, this.props.kjoretoy?.godkjenning.tekniskeData);
        const stort = skalForstorres(kjennemerkeSett);

        return (
            <div className="container">
                <header className="row align-items-baseline mb-3">
                    <h1 className="col-12">{`${merke}${modell}${registrertForsteGangAr} ${farge}`}</h1>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {!kjennemerke.tegnkombinasjon && <Skiltsett tegnkombinasjon={understellsnummer} kjennemerkeSett={KjennemerkeSettEnum.UN} kjennemerkeType={KjennemerkeTypeEnum.ORDINART} />}
                        {kjennemerke.tegnkombinasjon &&
                            <>
                                <Skiltsett
                                    tegnkombinasjon={kjennemerke.tegnkombinasjon}
                                    kjennemerkeSett={velgKjennemerkeSett(kjennemerkeSett)}
                                    kjennemerkeType={kjennemerke.kjennemerkeType}
                                    stort={stort}
                                />
                                <AkCopyToClipboardSymbol displayMode={DisplayMode.SYMBOL_ONLY} text={kjennemerke.tegnkombinasjon} />
                            </>
                        }
                        {personligKjennemerke.tegnkombinasjon &&
                            <>
                                <Skiltsett
                                    tegnkombinasjon={personligKjennemerke.tegnkombinasjon}
                                    kjennemerkeSett={velgKjennemerkeSett(kjennemerkeSett)}
                                    kjennemerkeType={personligKjennemerke.kjennemerkeType}
                                    kjennemerkekategori={KjennemerkeKategoriEnum.PERSONLIG}
                                    stort={stort}
                                />
                                <AkCopyToClipboardSymbol displayMode={DisplayMode.SYMBOL_ONLY} text={personligKjennemerke.tegnkombinasjon} />
                            </>
                        }
                    </div>
                    <KjoretoydetaljerKnapperadConnected
                        openModalForMidlertidigAvregistrering={this.openModalForMidlertidigAvregistrering}
                        hentMidlertidigVognkort={this.hentMidlertidigVognkort}
                        apneUtlevereUtenRegistreringModal={this.apneUtlevereUtenRegistreringModal} />
                </header>
                <div className="row">
                    <KjoretoydetaljerMeldinger kjoretoy={this.props.kjoretoy} merknader={this.props.kjoretoy.merknader} isFlateeier={this.props.isFlateeier}
                        apneSaker={this.props.kjoretoy.apneSaker} apneEierskifter={this.props.kjoretoy.apneEierskifter} brukerprofil={this.props.brukerprofil} />
                </div>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <KjoretoydetaljerGenereltConnected
                            registrering={this.props.registrering}
                            godkjenning={this.props.godkjenning}
                            kjennemerke={this.props.kjennemerke} />
                        <KjoretoydetaljerRegistrering registrering={this.props.registrering} intl={this.props.intl} kjoretoy={this.props.kjoretoy} />
                    </div>
                    <div className="col-12 col-md-4">
                        {new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering() && <KjoretoydetaljerPkk pkkFrist={pkkFrist} />}
                        {new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering() && <KjoretoydetaljerTilleggsgodkjenninger tilleggsgodkjenninger={tilleggsgodkjenninger} />}
                        <KjoretoydetaljerKjennemerkerConnected />
                        <KjoretoydetaljerMerknader kjoretoy={this.props.kjoretoy} />
                    </div>
                </div>
                {this.getModalForMidlertidigAvregistrering()}
                <UtlevereUtenRegistreringModal
                    visModal={this.state.visUtlevereUtenRegistreringModal}
                    lukkModal={this.lukkUtlevereUtenRegistreringModal}
                    utleverKjennemerkerUtenRegistrering={this.utleverKjennemerkerUtenRegistrering} />
            </div>
        );
    }

    private close = () => {
        const modalInfoState = { ...this.state.modalInfo, showModal: false };
        this.setState({ modalInfo: modalInfoState as IAkModalContent });
    };

    private hentMidlertidigVognkort = () => {
        this.props.hentMidlertidigVognkort(this.props.kjoretoy.registrering.kuid);
    };

    private openModalForMidlertidigAvregistrering = () => {
        return this.setState({
            modalInfo: {
                showModal: true,
                title: modalInformasjon.midlertidigAvregistrering.title,
                bodyText: modalInformasjon.midlertidigAvregistrering.bodyText,
                meldingModalType: modalInformasjon.midlertidigAvregistrering.meldingModalType as ModalType
            }
        });
    };

    private getModalForMidlertidigAvregistrering = () => {
        return (
            <AkModal
                showModal={this.state.modalInfo.showModal && this.state.modalInfo.title === modalInformasjon.midlertidigAvregistrering.title}
                title={this.state.modalInfo.title}
                modalType={this.state.modalInfo.meldingModalType}
                closeModal={this.close}>
                <>
                    {this.state.modalInfo.bodyText}
                    <div className="ak-knapperad row">
                        <AkKnapp type="ak-knapp ak-hovedknapp" action={this.bekreftAvregistrerMidlertidig} intlKey={'kjoretoydetaljer.modal.forsetteAvregistrerMidlertidig'}
                            loading={this.state.loadingAvregistrerKjoretoy} />
                        <AkKnapp type="ak-knapp ak-tilleggsknapp" action={this.close} intlKey={'kjoretoydetaljer.modal.lukkAvregistrerMidlertidig'}
                            disabled={this.state.loadingAvregistrerKjoretoy} />
                    </div>
                </>
            </AkModal>
        );
    };

    private bekreftAvregistrerMidlertidig = () => {
        this.close();
        this.setState({ loadingAvregistrerKjoretoy: true });
        this.props.opprettMidlertidigAvregistrering({ kuid: this.props.godkjenning.kuid, saksType: SaksType.MIDLERTIDIG_AVREGISTRERING })
            .then(() => this.setState({ loadingAvregistrerKjoretoy: false }));
    };

    private apneUtlevereUtenRegistreringModal = () => {
        this.setState({ visUtlevereUtenRegistreringModal: true });
    };

    private lukkUtlevereUtenRegistreringModal = () => {
        this.setState({ visUtlevereUtenRegistreringModal: false });
    };

    private utleverKjennemerkerUtenRegistrering = () => {
        this.props.utleverKjennemerkerUtenRegistrering(this.props.kjoretoy);
        this.lukkUtlevereUtenRegistreringModal();
    };
}

const mapStateToProps = (state: RootStateType): IKjoretoydetaljerStateProps => ({
    registrering: registreringSelector(state),
    godkjenning: godkjenningSelector(state),
    kjoretoy: kjoretoySelector(state),
    kjennemerke: kjennemerkeSelector(state),
    brukerprofil: state.brukerprofil,
    registreringSak: registreringSakSelector(state),
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier()
});

const mapDispatchToProps = (dispatch): IKjoretoydetaljerDispatchProps => ({
    opprettMidlertidigAvregistrering: (registreringSak: IRegistreringSak) => dispatch(opprettMidlertidigAvregistrering(registreringSak)),
    nyMelding: (melding: IMelding) => dispatch(nyMelding(melding)),
    hentMidlertidigVognkort: (kuid: string) => dispatch(hentMidlertidigVognkort(kuid)),
    utleverKjennemerkerUtenRegistrering: (kjoretoy: IKjoretoy) => dispatch(utleverKjennemerkerUtenRegistreringAction(kjoretoy))
});

const KjoretoydetaljerConnected = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Kjoretoydetaljer));

export { KjoretoydetaljerConnected, Kjoretoydetaljer };
