import * as _ from 'lodash';
import { GodkjenningsType, RegelmerknaderKoder } from '../models/kodeverk';
import type { IGodkjenning, IKjoretoy, IKjoringensArt, IMerknad, ITilhengerinfoFraKjoretoy, ITilleggsgodkjenning } from '../models/types';

export const erKlassifisertSomBegravelsesbil = (kjoretoy: IKjoretoy): boolean => {
    const regelmerknadkoderSomKlassifisererKjoretoySomBegravelsesbil = [
        RegelmerknaderKoder.KJORINGENS_ART_BEGRAVELSESBIL,
        RegelmerknaderKoder.KJORETOY_BEGRAVELSESBIL
    ];

    return _.some(kjoretoy.merknader, (kjoretoyMerknad: IMerknad) => {
        return _.includes(regelmerknadkoderSomKlassifisererKjoretoySomBegravelsesbil, kjoretoyMerknad.kode);
    });
};

export const erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt = (kjoretoy: IKjoretoy): boolean =>
    _.some(kjoretoy.godkjenning.tilleggsgodkjenninger, (tilleggsgodkjenning: ITilleggsgodkjenning) => tilleggsgodkjenning.tilleggsgodkjenningtype === GodkjenningsType.LOYVEFUNKSJONSHEMMEDE);

export const erKlassifisertSomRallyMedAvgiftsfritak = (kjoretoy: IKjoretoy): boolean =>
    _.some(kjoretoy.merknader, (merknad: IMerknad) => merknad.kode === RegelmerknaderKoder.KJORETOY_ER_RALLY_MED_AVGIFTSFRITAK.valueOf());

export const erKlassifisertSomRallyUtenAvgiftsfritak = (kjoretoy: IKjoretoy): boolean =>
    _.some(kjoretoy.merknader, (merknad: IMerknad) => merknad.kode === RegelmerknaderKoder.KJORETOY_ER_RALLY_UTEN_AVGIFTSFRITAK.valueOf());

export const erKlassifisertSomTrialEnduro = (kjoretoy: IKjoretoy): boolean =>
    _.some(kjoretoy.merknader, (merknad: IMerknad) => merknad.kode === RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_TRIAL_ENDURO.valueOf());

export const erKlassifisertSomLarevognEllerKjoringensArtListeInneholderKunLarevogn = (kjoretoy: IKjoretoy, kjoringensArtListeInnslag: IKjoringensArt[]): boolean =>
    _.some(kjoretoy.merknader, (merknad: IMerknad) => merknad.kode === RegelmerknaderKoder.KJORETOY_LAREVOGN.valueOf()) || kjoringensArtListeInneholderKunLarevogn(kjoringensArtListeInnslag);

const kjoringensArtListeInneholderKunLarevogn = (kjoringensArtListeInnslag: IKjoringensArt[]): boolean =>
    _.some(kjoringensArtListeInnslag, kjoringensArtInnslag => kjoringensArtInnslag.name === 'LAREVOGN') && kjoringensArtListeInnslag.length === 1;

export function utledTilhengerinfo(godkjenning: IGodkjenning): ITilhengerinfoFraKjoretoy {
    return {
        kjortoyTillattTotalvekt: godkjenning.tillattTotalvekt,
        kjortoyEgenvekt: godkjenning.egenvekt,
        maksTillattTilhengervekt: godkjenning.tillattTilhengervektMedBrems,
        maksTillattVogntokvekt: godkjenning.tillattVogntogvekt
    };
}