import * as React from 'react';
import { useEffect } from 'react';

import { AkUserSelect } from 'svv-tk-akr-common-frontend';
import { AkrConfig } from '../../constants';
import type { RootStateType } from '../../models/types';
import { hovedmenySeksjonEkspandert } from '../../state/actions';
import { useAppDispatch, useAppSelector } from "../../state/store";

export const UserSelect: React.FC = () => {
    const brukerprofil = useAppSelector((state: RootStateType) => state.brukerprofil);
    const isWorkplacesOpen = useAppSelector((state: RootStateType) => state.hovedmenyEkspandert === 'brukerprofil');
    const logoutUrl = `${window.env.serviceUrl}/sikkerhet/brukerprofil/logout`;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!brukerprofil?.valgtEnhet) {
            dispatch(hovedmenySeksjonEkspandert('brukerprofil'));
            return;
        }
        dispatch(hovedmenySeksjonEkspandert(''));

    }, [brukerprofil?.valgtEnhet, brukerprofil?.bruker]);

    const openWorkplaces = () => {
        if (isWorkplacesOpen) {
            return dispatch(hovedmenySeksjonEkspandert(''));
        }

        dispatch(hovedmenySeksjonEkspandert('brukerprofil'));
    };

    const selectWorkplaceBaseUrl = `${window.env.serviceUrl}${AkrConfig.BRUKERPROFIL_AVDELING_RESOURCE_URL}/`;
    return (
        <AkUserSelect
            user={brukerprofil}
            isOpen={isWorkplacesOpen}
            selectWorkplaceBaseUrl={selectWorkplaceBaseUrl}
            openWorkplaces={openWorkplaces}
            workplaces={brukerprofil?.bruker?.avdelinger}
            logoutUrl={logoutUrl}
        />
    );
};
