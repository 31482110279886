import * as _ from 'lodash';
import type { Avdeling, IKunderMap, KjennemerkeSerieEnum } from 'svv-tk-akr-common-frontend';
import { AktorType } from 'svv-tk-akr-common-frontend';

import { DRIVSTOFFSPESIFIKKE_KJENNEMERKESERIER, FremdriftsindikatorStatus } from '../constants';
import { KjoringensArt, Saksstatus } from '../models/kodeverk';
import type { IAktor, IKjennemerkeserierOgTyper, IKunde, IRegistreringInformasjon, ISak } from '../models/types';

import { aktorerHarGodkjent, isInnloggetBrukerIAktorliste } from './aktor-utils';

export function utledFremdriftsstatus(sak: ISak<IRegistreringInformasjon>): FremdriftsindikatorStatus {
    if (sak?.saksinformasjon) {
        const alleAktorerHarGodkjent = aktorerHarGodkjent([sak.saksinformasjon.nyEier, sak.saksinformasjon.nyMedeier]);

        if (sak.saksstatus === Saksstatus.AVSLUTTET) {
            return FremdriftsindikatorStatus.REGISTRERT;
        } else if (sak.saksstatus === Saksstatus.APEN && alleAktorerHarGodkjent) {
            return FremdriftsindikatorStatus.GODKJENT;
        } else if (sak.saksstatus === Saksstatus.APEN) {
            return FremdriftsindikatorStatus.TIL_GODKJENNING;
        } else if (sak.saksstatus === Saksstatus.AVBRUTT) {
            return FremdriftsindikatorStatus.AVBRUTT;
        }
    }

    return FremdriftsindikatorStatus.UNDER_OPPRETTING;
}

export function utledDefaultKjennemerkeserie(kjennemerkeserierOgTyper: IKjennemerkeserierOgTyper): KjennemerkeSerieEnum {
    return utledDrivstoffSpesifikkeSerier(kjennemerkeserierOgTyper.kjennemerkeserier)
        || kjennemerkeserierOgTyper.kjennemerkeserier.length === 1 && !kjennemerkeserierOgTyper.merknader.length && kjennemerkeserierOgTyper.kjennemerkeserier[0]
        || null;
}

export function utledDrivstoffSpesifikkeSerier(kjennemerkeserier: KjennemerkeSerieEnum[]): KjennemerkeSerieEnum {
    return _.find(kjennemerkeserier, (item: KjennemerkeSerieEnum) =>
        _.includes(DRIVSTOFFSPESIFIKKE_KJENNEMERKESERIER, item));
}

export function sakErAvluttetAvbrutt(saksstatus: Saksstatus): boolean {
    return saksstatus === Saksstatus.AVSLUTTET || saksstatus === Saksstatus.AVBRUTT;
}

export function leggTilDataFraKundePaAktor(aktor: IAktor, kunder: IKunderMap): IAktor {
    const kunde = _.get(kunder, aktor.kundeId) as IKunde;

    if (!kunde) {
        return aktor;
    }

    return {
        ...aktor,
        organisasjonsnummer: kunde.organisasjonsnummer,
        fodselsdato: kunde.fodselsdato,
        neringskoder: kunde.neringskoder,
        harGyldigAdresse: kunde.harGyldigAdresse
    };
}

export function utledOgSettDrosjeloyvenummer(brukPartial: Partial<IRegistreringInformasjon>): void {
    if (brukPartial.kjoringensArt === KjoringensArt.DROSJE_EIER.valueOf()) {
        brukPartial.drosjeloyvenummer = '';
    } else {
        brukPartial.drosjeloyvenummer = null;
    }
}

export function venterPaGodkjenningFraInnloggetBrukerSomErNyEierEllerNyMedeier(valgtEnhet: Avdeling, sak: ISak<IRegistreringInformasjon>, aktorer?: IAktor[]): boolean {
    const { saksinformasjon, saksstatus } = sak;
    const nyEier = _.find(aktorer, aktor => aktor.aktorType === AktorType.NY_EIER) || saksinformasjon.nyEier;
    const nyMedeier = _.find(aktorer, aktor => aktor.aktorType === AktorType.NY_MEDEIER) || saksinformasjon.nyMedeier;

    return saksstatus === Saksstatus.APEN && (
        (isInnloggetBrukerIAktorliste(valgtEnhet, [nyEier]) && !aktorerHarGodkjent([nyEier]))
        || (isInnloggetBrukerIAktorliste(valgtEnhet, [nyMedeier]) && !aktorerHarGodkjent([nyMedeier]))
    );
}
