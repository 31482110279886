import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface IIngenTreffProps {
    customMessageKey?: string;
    hideLink?: boolean;
    informasjonIkkeTilgjengeligIBransje?: boolean;
    customLocationLink?: {
        to: string;
        textKey: string;
    };
}

const IngenTreff: React.FC<IIngenTreffProps> = (props) => (
    <div className="row justify-content-center py-4">
        <div className="col-12 col-md-4">
            <h1>
                <FormattedMessage id="oppslag.ingenTreff.overskrift" />
            </h1>
            <p>
                {props.informasjonIkkeTilgjengeligIBransje ? (
                    <FormattedMessage id={props.customMessageKey || 'oppslag.ingenTreff.informasjonIkkeTilgjengeligIBransje'} />
                ) : (<FormattedMessage id={props.customMessageKey || 'oppslag.ingenTreff.forklaring'} />)}
            </p>
            {!props.hideLink &&
            <Link className="ak-knapp ak-hovedknapp mt-4" role="button"
                to={props.customLocationLink ? props.customLocationLink.to : '/startside'}>
                <FormattedMessage id={props.customLocationLink ? props.customLocationLink.textKey : 'oppslag.ingenTreff.gaTil'} />
            </Link>
            }
        </div>
        <div className="col-12 col-md-4 text-nowrap text-md-right">
            <i className="ak-ikon-ikke-tilgjengelig" />
        </div>
    </div>
);

export { IngenTreff };
