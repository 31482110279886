/* eslint-disable max-lines */
import * as _ from 'lodash';
import type { Action } from 'redux';

import {
    AKR_KJENNEMERKE,
    AkrConfig,
    APPLIKASJON_UTILGJENGELIG,
    CONSUMERKALL_FEILET,
    DEFAULT_MELDING_VISNINGSTID_MS,
    KJENNEMERKEMELDING_IKKE_PA_LAGER_VISNINGSTID_MS,
    NY_SAK_VISNINGSTID_MS,
    RegistreringerActionKey
} from '../../constants';
import { AvsluttSakIndikator, MeldingType, RegelmerknaderKoder, Saksstatus, SaksType } from '../../models/kodeverk';
import type {
    IError,
    IGrunnlagVektarsavgift,
    IKjoringensArt,
    IRegistreringInformasjon,
    IRegistreringSak,
    ISak,
    ISakMinimalResponse,
    RootStateType,
    ThunkResult
} from '../../models/types';
import { RegistreringRegler } from '../../regler';
import { WHITESPACE } from '../../utils';
import type {
    MeldingBarActionType,
    SakActionType} from '../actions';
import {
    addGlobalErrors,
    avbrytSak,
    endreDokumentSendTilSaksbehandlingRegistrering,
    endreSak,
    hentSak,
    hentSaker,
    hentSakPromise,
    kjoretoyGetDataFromKuidAction,
    nyMelding,
    opprettSak,
    resetSak,
    sakerRemoveSuccessAction,
    sakIsLoadingAction,
    sakRequestErrorAction,
    sakRequestSuccessAction,
    visTekniskFeil
} from '../actions';
import { createRestResource } from '../store';
import { nyMerknader } from './arbeidsliste';
import type { AvgiftsstatusActionType } from './avgiftsstatus';
import type { ThunkAction } from 'redux-thunk';

export type RegistreringerActionType =
    | SakActionType
    | IRegistreringerAvbrytSuccessAction
    | IRegistreringerLoadingAction
    | IRegistreringerHasErrorAction
    | IRegistreringVedtakHasErrorAction
    | IRegistreringerGetSuccessAction
    | IRegistreringerCreateSuccessAction
    | IRegistreringerClearStateAction
    | IRegistreringerUpdateSuccessAction
    | IRegistreringerOppdaterSkjema
    | IRegistreringerOppdaterTilhenger
    | IRegistreringerPopulerNySak
    | IRegistreringerOtherAction
    | AvgiftsstatusActionType;

export interface IRegistreringerLoadingAction {
    type: RegistreringerActionKey.REGISTRERINGER_LOADING;
    isLoading: boolean;
}

export interface IRegistreringerClearStateAction {
    type: RegistreringerActionKey.REGISTRERINGER_CLEAR_STATE;
}

interface IRegistreringerAvbrytSuccessAction {
    type: RegistreringerActionKey.REGISTRERINGER_AVBRYT_SUCCESS;
    sakId: string;
}

interface IRegistreringerHasErrorAction {
    type: RegistreringerActionKey.REGISTRERINGER_HAS_ERROR;
    error: IError;
}

interface IRegistreringVedtakHasErrorAction {
    type: RegistreringerActionKey.REGISTRERINGER_VEDTAK_HAS_ERROR;
    error: IError;
}

interface IRegistreringerCreateSuccessAction {
    type: RegistreringerActionKey.REGISTRERINGER_CREATE_SUCCESS;
    registreringSak: IRegistreringSak;
}

interface IRegistreringerUpdateSuccessAction {
    type: RegistreringerActionKey.REGISTRERINGER_UPDATE_SUCCESS;
    registreringSak: IRegistreringSak;
}

interface IRegistreringerGetSuccessAction {
    type: RegistreringerActionKey.REGISTRERINGER_GET_SUCCESS;
    registreringSak: IRegistreringSak;
}

interface IRegistreringerOppdaterSkjema {
    type: RegistreringerActionKey.REGISTRERINGER_OPPDATER_SKJEMA;
    registreringSak: Partial<IRegistreringSak>;
}

interface IRegistreringerOppdaterTilhenger {
    type: RegistreringerActionKey.REGISTRERINGER_OPPDATER_TILHENGERPANEL;
    registreringSak: Partial<IRegistreringSak>;
}

interface IRegistreringerPopulerNySak {
    type: RegistreringerActionKey.REGISTRERINGER_POPULER_NY_SAK;
    registreringSak: Partial<IRegistreringSak>;
}

interface IRegistreringerOtherAction {
    type: RegistreringerActionKey.OTHER_ACTION;
}

const registreringHasError = (error: any): IRegistreringerHasErrorAction => ({ type: RegistreringerActionKey.REGISTRERINGER_HAS_ERROR, error });
const registreringAvbrytSuccsess = (sakId: string): IRegistreringerAvbrytSuccessAction => ({ type: RegistreringerActionKey.REGISTRERINGER_AVBRYT_SUCCESS, sakId });
const registereringSakCreateSuccess = (registreringSak: IRegistreringSak): IRegistreringerCreateSuccessAction => ({
    type: RegistreringerActionKey.REGISTRERINGER_CREATE_SUCCESS,
    registreringSak
});

const registreringSakUpdateSuccess = (registreringSak: IRegistreringSak): IRegistreringerUpdateSuccessAction => ({
    type: RegistreringerActionKey.REGISTRERINGER_UPDATE_SUCCESS,
    registreringSak
});

const registreringPopulerNySakAction = (registreringSak: Partial<IRegistreringSak>): IRegistreringerPopulerNySak => ({
    type: RegistreringerActionKey.REGISTRERINGER_POPULER_NY_SAK,
    registreringSak
});

const registreringOppdaterTilhengerpanelAction = (registreringSak: Partial<IRegistreringSak>): IRegistreringerOppdaterTilhenger => ({
    type: RegistreringerActionKey.REGISTRERINGER_OPPDATER_TILHENGERPANEL,
    registreringSak
});

const registreringerGetSuccess = (registreringSak: IRegistreringSak): IRegistreringerGetSuccessAction => ({
    type: RegistreringerActionKey.REGISTRERINGER_GET_SUCCESS,
    registreringSak
});

export const registreringSakClearState = (): IRegistreringerClearStateAction => ({ type: RegistreringerActionKey.REGISTRERINGER_CLEAR_STATE });

const api = createRestResource(AkrConfig.REGISTRERINGSAKER_RESOURCE_URL);

export function opprettRegistreringSak(
    sak: IRegistreringSak,
    avsluttSakIndicator?: AvsluttSakIndikator
): ThunkAction<Promise<ISak<IRegistreringInformasjon>>, RootStateType, undefined, MeldingBarActionType | IRegistreringerHasErrorAction | RegistreringerActionType> {
    return (dispatch, getState) => {
        return dispatch(opprettSak(createSak(sak, avsluttSakIndicator), sak.saksType !== SaksType.MIDLERTIDIG_AVREGISTRERING)).then((sakResponse) => {
            if (sakResponse) {
                if (sakResponse.error) {
                    dispatch(registreringHasError(sakResponse));
                    dispatch(registreringSakUpdateError(sakResponse.error));
                    return sakResponse;
                } else {
                    if (sakResponse.saksstatus === Saksstatus.APEN) {
                        dispatch(
                            nyMelding({
                                meldingIntlId: 'sak.melding.opprett',
                                meldingType: MeldingType.SUCCESS,
                                varighetMs: NY_SAK_VISNINGSTID_MS
                            })
                        );
                    }

                    if (sakResponse.error && erKjennemerkeApplikasjonUtilgjengelig(sakResponse.error.errorCode)) {
                        visSakIkkeFullfortGrunnetConsumer(dispatch);
                    }

                    if (finnesKjennemerkeIkkePaLagerMerknad(sakResponse)) {
                        visKjennemerkeIkkePaLagerMelding(dispatch);
                    }

                    if (sakResponse.saksstatus === Saksstatus.AVSLUTTET) {
                        dispatch(
                            nyMelding({
                                meldingIntlId: 'sak.melding.avsluttet',
                                meldingType: MeldingType.SUCCESS,
                                varighetMs: NY_SAK_VISNINGSTID_MS
                            })
                        );
                        return sakResponse;
                    }
                    dispatch(registereringSakCreateSuccess(createRegistreringSak(getState().registreringSak, sakResponse)));
                    return sakResponse;
                }
            } else {
                dispatch(
                    nyMelding({
                        meldingIntlId: 'forstegangsregistrering.melding.ikkeOpprettet',
                        meldingType: MeldingType.DANGER
                    })
                );
                dispatch(registreringHasError(true));
                return sakResponse;
            }
        });
    };
}

export function opprettMidlertidigAvregistrering(
    sak: IRegistreringSak
): ThunkAction<Promise<ISak<IRegistreringInformasjon> | void>, RootStateType, undefined, MeldingBarActionType | IRegistreringerHasErrorAction | RegistreringerActionType> {
    return (dispatch, getState) => {
        return dispatch(opprettRegistreringSak(sak)).then(() => {
            const sakResponse = getState().sak;
            if (sakResponse.sakId) {
                dispatch(registreringSakClearState());
                dispatch(
                    nyMelding({
                        meldingIntlId: 'kjoretoydetaljer.meldinger.opprettetAvregistrerMidlertidig',
                        meldingType: MeldingType.SUCCESS,
                        meldingId: 'opprettetAvregistrerMidlertidigId'
                    })
                );
                dispatch(resetSak());
                dispatch(kjoretoyGetDataFromKuidAction(getState().kjoretoy.kuid));
            } else {
                if (sakResponse.merknader && sakResponse.merknader.length) {
                    sakResponse.merknader.map((merknad) => {
                        dispatch(
                            nyMelding({
                                meldingIntlId: `kjoretoydetaljer.meldinger.${merknad.kode}`,
                                meldingType: MeldingType.DANGER,
                                meldingId: 'midlertidigAvregistreringErrorId'
                            })
                        );
                    });
                } else {
                    dispatch(
                        nyMelding({
                            meldingIntlId: 'kjoretoydetaljer.meldinger.midlertidigAvregistreringError',
                            meldingType: MeldingType.ERROR,
                            meldingId: 'midlertidigAvregistreringErrorId'
                        })
                    );
                }
            }
        });
    };
}

export function oppdaterRegistreringSak(
    sak: IRegistreringSak,
    avsluttSakIndikator: AvsluttSakIndikator
): ThunkAction<Promise<ISak<IRegistreringInformasjon> | void>, RootStateType, undefined, MeldingBarActionType | IRegistreringerHasErrorAction | RegistreringerActionType> {
    return (dispatch, getState) => {
        return dispatch(endreSak(createSak(sak, avsluttSakIndikator), true)).then((sakResponse) => {
            if (sakResponse) {
                if (sakResponse.error) {
                    dispatch(registreringHasError(sakResponse));
                    dispatch(sakRequestErrorAction(sakResponse.error));
                    return dispatch(registreringSakUpdateError(sakResponse.error));
                } else {
                    dispatch(sakRequestSuccessAction(sakResponse));
                    return dispatch(registreringSakUpdateSuccess(createRegistreringSak(getState().registreringSak, sakResponse, avsluttSakIndikator)));
                }
            } else {
                dispatch(sakRequestErrorAction(sakResponse.error));
                return dispatch(registreringHasError(true));
            }
        }) as Promise<ISak<IRegistreringInformasjon> | void>;
    };
}

export function endreErTilGodkjenningStatus(
    sak: IRegistreringSak
): ThunkAction<Promise<ISak<IRegistreringInformasjon> | void>, RootStateType, undefined, MeldingBarActionType | IRegistreringerHasErrorAction | RegistreringerActionType> {
    return (dispatch, getState) => {
        const erTilGodkjenning = getState().sak.erTilGodkjenning;
        return dispatch(endreSak(endreErTilGodkjenningSak(sak, erTilGodkjenning), true)).then((sakResponse) => {
            if (sakResponse) {
                if (sakResponse.error) {
                    dispatch(registreringHasError(sakResponse));
                    dispatch(sakRequestErrorAction(sakResponse.error));
                    return dispatch(registreringSakUpdateError(sakResponse.error));
                } else {
                    dispatch(sakRequestSuccessAction(sakResponse));
                    if (!erTilGodkjenning) {
                        dispatch(registreringSakUpdateSuccess(createRegistreringSak(getState().registreringSak, sakResponse)));
                        return dispatch(endreDokumentSendTilSaksbehandlingRegistrering(sakResponse.sakId, sakResponse.arkivdokumenter));
                    } else {
                        return dispatch(registreringSakUpdateSuccess(createRegistreringSak(getState().registreringSak, sakResponse)));
                    }
                }
            } else {
                dispatch(sakRequestErrorAction(sakResponse.error));
                return dispatch(registreringHasError(true));
            }
        }) as Promise<ISak<IRegistreringInformasjon> | void>;
    };
}

export function oppdaterRegistreringSkjema(sak: Partial<IRegistreringSak>): ThunkResult<any, IRegistreringerOppdaterSkjema> {
    return (dispatch) => {
        return dispatch({
            type: RegistreringerActionKey.REGISTRERINGER_OPPDATER_SKJEMA,
            registreringSak: sak
        });
    };
}

export function oppdaterRegistreringTilhengerpanel(grunnlagVektarsavgift: Partial<IGrunnlagVektarsavgift>): ThunkResult<any, IRegistreringerOppdaterTilhenger> {
    return (dispatch, getState) => {
        return dispatch(
            registreringOppdaterTilhengerpanelAction({
                ...getState().registreringSak,
                grunnlagVektarsavgift: { ...getState().registreringSak.grunnlagVektarsavgift, ...grunnlagVektarsavgift }
            })
        );
    };
}

export function getRegistreringSak(
    sakId: string
): ThunkResult<Promise<IRegistreringerHasErrorAction | IRegistreringerGetSuccessAction | void>, IRegistreringerGetSuccessAction | IRegistreringerHasErrorAction> {
    return (dispatch, getState) => {
        return dispatch(hentSak(sakId)).then((sakResponse) => {
            if (sakResponse) {
                if (sakResponse.error) {
                    return dispatch(registreringHasError(true));
                } else {
                    return dispatch(registreringerGetSuccess(createRegistreringSak(getState().registreringSak, sakResponse)));
                }
            } else {
                return dispatch(registreringHasError(true));
            }
        });
    };
}

export function getRegistreringSakFattVedtak(
    sakId: string
): ThunkResult<Promise<IRegistreringerHasErrorAction | IRegistreringerGetSuccessAction | void>, IRegistreringerGetSuccessAction | IRegistreringerHasErrorAction | SakActionType> {
    return (dispatch, getState) => {
        dispatch(sakIsLoadingAction(true));
        return api.get(null, null, `${AkrConfig.REGISTRERINGSAKER_RESOURCE_URL}/${sakId}/vedtak`).then((sakResponse: ISak<IRegistreringInformasjon> & IError) => {
            if (sakResponse.errorCode) {
                if (erKjennemerkeApplikasjonUtilgjengelig(sakResponse.errorCode)) {
                    return visSakIkkeFullfortGrunnetConsumer(dispatch);
                }

                dispatch(sakRequestErrorAction(sakResponse));
                return dispatch(registreringSakUpdateError(sakResponse));
            } else {
                dispatch(sakRequestSuccessAction(sakResponse));
                if (finnesKjennemerkeIkkePaLagerMerknad(sakResponse)) {
                    return visKjennemerkeIkkePaLagerMelding(dispatch);
                }

                if (sakResponse.saksstatus === Saksstatus.APEN) {
                    return dispatch(
                        nyMelding({
                            meldingIntlId: 'sak.melding.lagre',
                            meldingType: MeldingType.SUCCESS,
                            varighetMs: NY_SAK_VISNINGSTID_MS
                        })
                    );
                }

                if (sakResponse.saksstatus === Saksstatus.AVSLUTTET) {
                    return dispatch(
                        nyMelding({
                            meldingIntlId: 'sak.melding.avsluttet',
                            meldingType: MeldingType.SUCCESS,
                            varighetMs: NY_SAK_VISNINGSTID_MS
                        })
                    );
                }
                return dispatch(registreringerGetSuccess(createRegistreringSak(getState().registreringSak, sakResponse)));
            }
        });
    };
}

export function oppdaterRegistreringArbeidsliste(sakId: string): ThunkResult<Promise<void>, SakActionType | RegistreringerActionType> {
    return (dispatch, getState) => {
        return api.get(null, null, `${AkrConfig.REGISTRERINGSAKER_RESOURCE_URL}/${sakId}/vedtak`).then((response: ISak<IRegistreringInformasjon> & IError) => {
            if (response.errorId) {
                dispatch(sakRequestErrorAction(response.error));
                return dispatch(visTekniskFeil(response));
            } else {
                dispatch(sakRequestSuccessAction(response));
                dispatch(registreringerGetSuccess(createRegistreringSak(getState().registreringSak, response)));
            }
        });
    };
}

export function henteDrosjeloyvenummer(kuid: string): ThunkResult<void, SakActionType | RegistreringerActionType> {
    return (dispatch) => {
        const finnSakerFilter = {
            kuid,
            sakstyper: [SaksType.REGISTRERING_SAMME_EIER, SaksType.REGISTRERING_NY_EIER],
            saksstatus: [Saksstatus.AVSLUTTET]
        };
        dispatch(hentSaker(finnSakerFilter)).then((sakerResponse: ISakMinimalResponse) => {
            const sisteSakId = _.orderBy(sakerResponse.content, 'vedtakFattetDato').pop().sakId;
            hentSakPromise(sisteSakId).then((response: ISak<IRegistreringSak> & IError) => {
                const drosjeloyvenummer = response.saksinformasjon.drosjeloyvenummer || '';
                dispatch(oppdaterRegistreringSkjema({ drosjeloyvenummer }));
            });
        });
    };
}

export function avbrytRegistrering(sakId: string): ThunkResult<Promise<ISak<IRegistreringInformasjon> | void>, Action> {
    return (dispatch) => {
        return dispatch(avbrytSak(sakId)).then((sakResponse: ISak<IRegistreringInformasjon>) => {
            if (sakResponse.error) {
                return dispatch(registreringHasError(true));
            }
            return dispatch(registreringAvbrytSuccsess(sakId));
        }) as Promise<ISak<IRegistreringInformasjon> | void>;
    };
}

function registreringSakUpdateError(error: IError) {
    return (dispatch) => {
        dispatch(addGlobalErrors(error));
    };
}

export function fjernRegistreringArbeidsliste(sakId: string): ThunkResult<any, Action> {
    return (dispatch) => {
        return api
            .post(null, null, null, `${AkrConfig.REGISTRERINGSAKER_RESOURCE_URL}/${sakId}/fjern`)
            .then((response: ISak<IRegistreringInformasjon> & IError) => {
                /* eslint-disable sonarjs/no-collapsible-if */
                if (response.merknader && response.merknader.length) {
                    if (sakId) {
                        dispatch(nyMerknader(sakId, response.merknader));
                    }
                }
                if (response.errorId) {
                    dispatch(visTekniskFeil(response));
                } else {
                    if (response.saksstatus === Saksstatus.AVSLUTTET) {
                        dispatch(nyMelding({ meldingIntlId: 'registrering.melding.sakFullfort', meldingType: MeldingType.SUCCESS }));
                    }

                    return dispatch(sakerRemoveSuccessAction(response));
                }
                /* eslint-enable sonarjs/no-collapsible-if */
            })
            .catch(() => dispatch(visTekniskFeil()));
    };
}

export function populerNySak(alleKjoringensArt: IKjoringensArt[]): ThunkResult<Promise<Action>, IRegistreringerPopulerNySak> {
    return (dispatch, getState) => {
        const state = getState();
        const kjoretoy = state.kjoretoy;
        const merknader = kjoretoy.merknader;
        const eier = kjoretoy.registrering.eier;
        const underenhet = kjoretoy.registrering.underenhet;
        const brukerprofil = state.brukerprofil;

        const neringskode = RegistreringRegler.utledDefaultNeringskode(eier);

        let valgtNeringskode = null;
        let valgtNeringskodebeskrivelse = null;

        if (neringskode) {
            valgtNeringskode = neringskode.getKode();
            valgtNeringskodebeskrivelse = neringskode.getBeskrivelse();
        }

        const kanStarteOmregistreringssak = RegistreringRegler.kanStarteOmregistreringssak(kjoretoy, merknader, RegistreringRegler.erRegistrertPaEier(state.kjoretoy));
        const erKravOmVektarsavgift = RegistreringRegler.erKravOmVektarsavgift(kjoretoy, merknader);
        const saksType = kanStarteOmregistreringssak ? SaksType.REGISTRERING_NY_EIER : SaksType.REGISTRERING_SAMME_EIER;
        const kjoringensArt = RegistreringRegler.utledDefaultKjoringensArt(eier, saksType, alleKjoringensArt, kjoretoy);
        const nyUnderenhet = RegistreringRegler.utledDefaultNyUnderenhet(eier, underenhet, brukerprofil);

        const registreringSak = {
            kuid: state.kjoretoy.registrering.kuid,
            saksType,
            saksstatus: Saksstatus.APEN,
            kjoringensArt,
            neringskode: valgtNeringskode,
            neringskodeBeskrivelse: valgtNeringskodebeskrivelse,
            skalTrekkeTilhenger: erKravOmVektarsavgift ? erKravOmVektarsavgift : null,
            underenhet: nyUnderenhet
        };

        return new Promise((resolve) => resolve(dispatch(registreringPopulerNySakAction(registreringSak))));
    };
}

function visKjennemerkeIkkePaLagerMelding(dispatch) {
    return dispatch(
        nyMelding({
            meldingIntlId: 'sak.melding.kjennemerkeIkkePaLager',
            meldingType: MeldingType.ERROR,
            varighetMs: KJENNEMERKEMELDING_IKKE_PA_LAGER_VISNINGSTID_MS
        })
    );
}

function visSakIkkeFullfortGrunnetConsumer(dispatch) {
    return dispatch(
        nyMelding({
            meldingIntlId: 'sak.melding.sakIkkeFullfort',
            meldingType: MeldingType.ERROR,
            varighetMs: DEFAULT_MELDING_VISNINGSTID_MS
        })
    );
}

export const createSak = (registreringSak: IRegistreringSak, avsluttSakIndicator = AvsluttSakIndikator.AVSLUTT_MED_VEDTAK): ISak<IRegistreringInformasjon> => {
    const {
        kuid,
        imid,
        saksadresseId,
        sakId,
        leasingtaker,
        underenhet,
        neringskode,
        neringskodeBeskrivelse,
        kjoringensArt,
        saksType,
        drosjeloyvenummer,
        internSaksbehandlingPakrevet,
        vognkortsaksinformasjon,
        kjennemerke
    } = registreringSak;

    return {
        kuid,
        imid,
        saksadresseId,
        sakId,
        saksType,
        internSaksbehandlingPakrevet,
        avsluttSakIndikator: avsluttSakIndicator,
        saksinformasjon:
            saksType === SaksType.MIDLERTIDIG_AVREGISTRERING
                ? null
                : {
                      saksType,
                      kjoringensArt,
                      neringskode,
                      neringskodeBeskrivelse,
                      leasingtaker,
                      underenhet,
                      vognkortsaksinformasjon,
                      kjennemerke,
                      drosjeloyvenummer: drosjeloyvenummer ? drosjeloyvenummer.replace(WHITESPACE, '') : null,
                      grunnlagVektarsavgift: createGrunnlagVektarsavgift(registreringSak)
                  }
    };
};

const endreErTilGodkjenningSak = (registreringSak: IRegistreringSak, erTilGodkjenning: boolean): ISak<IRegistreringInformasjon> => {
    const { kuid, imid, saksadresseId, sakId, leasingtaker, underenhet, neringskode, neringskodeBeskrivelse, kjoringensArt, saksType, internSaksbehandlingPakrevet, vognkortsaksinformasjon } =
        registreringSak;

    return {
        kuid,
        imid,
        saksadresseId,
        sakId,
        erTilGodkjenning: !erTilGodkjenning,
        saksType,
        internSaksbehandlingPakrevet,
        saksstatus: Saksstatus.APEN,
        trekkTilbake: erTilGodkjenning,
        saksinformasjon:
            saksType === SaksType.MIDLERTIDIG_AVREGISTRERING
                ? null
                : {
                      saksType,
                      kjoringensArt,
                      neringskode,
                      neringskodeBeskrivelse,
                      leasingtaker,
                      underenhet,
                      vognkortsaksinformasjon,
                      grunnlagVektarsavgift: createGrunnlagVektarsavgift(registreringSak)
                  }
    };
};

const createRegistreringSak = (originalSak: IRegistreringSak, updatedSak: ISak<IRegistreringInformasjon>, avsluttSakIndikator?: AvsluttSakIndikator): IRegistreringSak => {
    if (updatedSak && updatedSak.saksinformasjon) {
        return { ...originalSak, ...(_.omit(updatedSak, 'saksinformasjon') as IRegistreringSak), ...updatedSak.saksinformasjon, avsluttSakIndikator };
    }
};

const finnesKjennemerkeIkkePaLagerMerknad = (sakResponse: ISak<any>): boolean => {
    return (sakResponse.merknader || []).filter((merknad) => RegelmerknaderKoder.KJENNEMERKE_IKKE_PA_LAGER.valueOf() === merknad.kode).length > 0;
};

const erKjennemerkeApplikasjonUtilgjengelig = (errorCode: string): boolean => {
    return (errorCode?.includes(AKR_KJENNEMERKE) && errorCode?.includes(APPLIKASJON_UTILGJENGELIG)) || errorCode?.includes(CONSUMERKALL_FEILET);
};

const createGrunnlagVektarsavgift = (sak: IRegistreringSak) => {
    const grunnlagVektarsavgift = sak.grunnlagVektarsavgift;

    if (grunnlagVektarsavgift) {
        const { antallAkslerTilhenger, totalvektTilhenger } = grunnlagVektarsavgift;
        const skalTrekkeTilhenger = !!(antallAkslerTilhenger || totalvektTilhenger);

        return {
            antallAkslerTilhenger,
            totalvektTilhenger,
            skalTrekkeTilhenger
        } as IGrunnlagVektarsavgift;
    }
    return null;
};
