import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { KjennemerkeSerieEnum } from 'svv-tk-akr-common-frontend';
import { AkLoading, KjennemerkePanel, KjennemerkeSerier, KjennemerkeTypeEnum, Panel, utledKjennemerketsett } from 'svv-tk-akr-common-frontend';

import { KjoringensArt } from '../../models/kodeverk';
import type { IKjennemerkeserierOgTyper, IKjoretoy, INyttKjennemerke, RootStateType } from '../../models/types';

export enum OppdaterKjennemerkeCallSourceComponent {
    KJENNEMERKE = 'KJENNEMERKE',
    FORSTEGANGSREGISTRERING = 'FORSTEGANGSREGISTRERING'
}

interface IRegistreringKjennemerkePanelProps {
    kjennemerke: INyttKjennemerke;
    updateKjennemerke: (kjennemerke: INyttKjennemerke, callSource: OppdaterKjennemerkeCallSourceComponent) => void;
    kjoringensArt: string;
    hentKjennemerkeserierOgTyper: (kuid: string, kjoringensArt: string) => Promise<any>;
}

interface IRegistreringKjennemerkePanelStateProps {
    kjoretoy: IKjoretoy;
    kjennemerkeserierOgTyper: IKjennemerkeserierOgTyper;
}

type PropsType = IRegistreringKjennemerkePanelProps & IRegistreringKjennemerkePanelStateProps;

const KJENNEMERKETYPER_IKKE_FRA_AUTOREG = [KjennemerkeTypeEnum.VETERAN, KjennemerkeTypeEnum.AMBASSADE, KjennemerkeTypeEnum.SVALBARD];

class RegistreringKjennemerkePanel extends React.Component<PropsType> {

    public componentDidMount(): void {
        if (!this.props.kjennemerkeserierOgTyper.kjennemerkeserier.length) {
            this.props.hentKjennemerkeserierOgTyper(this.props.kjoretoy.kuid, this.props.kjoringensArt || KjoringensArt.EGENTRANSP_ANNEN);
        }
    }

    public componentDidUpdate(prevProps: PropsType): void {
        if (this.props.kjoringensArt && this.props.kjoringensArt !== prevProps.kjoringensArt) {
            this.props.hentKjennemerkeserierOgTyper(this.props.kjoretoy.kuid, this.props.kjoringensArt);
        }

        const tillatteKjennemerketyper = this.filtrerKjennemerketyper(this.props.kjennemerkeserierOgTyper.kjennemerkeTyper);
        if (tillatteKjennemerketyper.length && !_.includes(tillatteKjennemerketyper, this.props.kjennemerke.kjennemerkeType)) {
            const kjennemerkeType = tillatteKjennemerketyper[0];
            this.props.updateKjennemerke({
                ...this.props.kjennemerke,
                kjennemerkeType
            }, OppdaterKjennemerkeCallSourceComponent.KJENNEMERKE);
        }
    }

    public render(): React.ReactElement {
        return (
            <Panel overskrift="sak.kjennemerke.overskrift.tildeleKjennemerker" panelCssClass="col-12 mt-4">
                {this.renderAntallKjennemerker()}
                {this.renderBody()}
            </Panel>
        );
    }

    private renderBody = () => {
        const { kjennemerkeType, kjennemerkeserie } = this.props.kjennemerke;
        const kjennemerkeSett = utledKjennemerketsett(this.props.kjoretoy.registrering.kjennemerke, this.props.kjoretoy.godkjenning.tekniskeData);

        if (this.props.kjennemerkeserierOgTyper.isLoading) {
            return <AkLoading />;
        }

        return (
            <KjennemerkePanel valgtKjennemerkeType={kjennemerkeType} handleKjennemerkeTypeEndring={this.handleKjennemerkeTypeEndring}
                kjennemerkeTyper={this.filtrerKjennemerketyper(this.props.kjennemerkeserierOgTyper.kjennemerkeTyper)} customClassName="col-12 col-sm-6 col-lg-4 mt-2 pt-4">
                 <KjennemerkeSerier
                    kjennemerkeType={kjennemerkeType}
                    valgtKjennemerkeserie={kjennemerkeserie}
                    kjennemerkeserier={this.props.kjennemerkeserierOgTyper.kjennemerkeserier}
                    kjennemerkeSett={kjennemerkeSett}
                    handleKjennemerkeserieEndring={this.handleKjennemerkeserieEndring} />
            </KjennemerkePanel>
        );
    };

    private renderAntallKjennemerker = () => {
        const { kjennemerkestorrelseForan, kjennemerkestorrelseBak, kjennemerkestorrelseVenstre } = this.props.kjoretoy.godkjenning.tekniskeData;
        const definerteStorrelser = _.reject([kjennemerkestorrelseForan, kjennemerkestorrelseBak, kjennemerkestorrelseVenstre], _.isNil);
        const antallKjennemerker = definerteStorrelser.length;
        const kjennemerkeStorrelser = definerteStorrelser.join(' / ');

        return <FormattedMessage id={'sak.kjennemerke.antall.tekst'} values={{ totalt: antallKjennemerker, storrelser: kjennemerkeStorrelser }} />;
    };

    private handleKjennemerkeserieEndring = (kjennemerkeserie: KjennemerkeSerieEnum) => {
        this.props.updateKjennemerke({
            ...this.props.kjennemerke,
            kjennemerkeserie
        }, OppdaterKjennemerkeCallSourceComponent.KJENNEMERKE);
    };

    private handleKjennemerkeTypeEndring = (kjennemerkeType: KjennemerkeTypeEnum) => {
        this.props.updateKjennemerke({
            ...this.props.kjennemerke,
            kjennemerkeType
        }, OppdaterKjennemerkeCallSourceComponent.KJENNEMERKE);
    };

    private filtrerKjennemerketyper = (kjennemerketyper: KjennemerkeTypeEnum[]): KjennemerkeTypeEnum[] => {
        return _.filter(kjennemerketyper, kjennemerketype => !_.includes(KJENNEMERKETYPER_IKKE_FRA_AUTOREG, kjennemerketype));
    };
}

const mapStateToProps = (state: RootStateType): IRegistreringKjennemerkePanelStateProps => ({
    kjoretoy: state.kjoretoy,
    kjennemerkeserierOgTyper: state.kjennemerkeserierOgTyper
});

const RegistreringKjennemerkePanelConnected = connect(mapStateToProps)(RegistreringKjennemerkePanel);
export type { PropsType as RegistreringKjennemerkePanelPropsType };
export { RegistreringKjennemerkePanelConnected, RegistreringKjennemerkePanel };
