import type { IAkConfirmNavigationProps, IAktorPanelAktor, IKunderMap} from 'svv-tk-akr-common-frontend';
import {
    AkConfirmNavigation, AkKnapp, AkLoading, AkMeldingboks, AkMeldingboksType, AktorKundeinfo, AktorPanel, AktorType, KjoretoyInfoPanel, Panel
} from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { WithRouterProps} from '../../utils';
import { withRouter } from '../../utils';
import { compose } from '@reduxjs/toolkit';

import { RegelmerknaderType, Saksstatus, SaksType } from '../../models/kodeverk';
import type { IAktor, IError, IKjoretoy, IRegistreringSak, ISak, RootStateType } from '../../models/types';
import { getKunde, getRegistreringSak, kjoretoyGetDataFromKuidAction, resetSak } from '../../state/actions';
import { opprettLeasingtakerEndreSak } from '../../state/actions/leasingtaker';
import { RegistreringRegler } from '../../regler';

interface ILeasingtakerStateProps {
    kjoretoy: IKjoretoy;
    kunder: IKunderMap;
    sak: ISak<IRegistreringSak>;
}

interface ILeasingtakerState {
    leasingtaker: IAktorPanelAktor;
    submitted: boolean;
    visEksisterendeLeasingtaker: boolean;
    slettetLeasingtaker: boolean;
    ugyldigKundeInput: boolean;
}

interface ILeasingtakerDispatchProps {
    hentKundeMedKundeId: (kundeId: string) => Promise<IAktor | IError>;
    hentKundeMedOrgnr: (orgFnr: string) => Promise<IAktor | IError>;
    opprettLeasingtaker: (sak: ISak) => Promise<ISak | IError>;
    hentSak: (sakId: string) => Promise<ISak | IError>;
    resetSak: () => Promise<any>;
    getKjoretoyFromKuid: (kuid: string) => Promise<any>;
}

type ILeasingtakerProps = ILeasingtakerStateProps & ILeasingtakerDispatchProps & IAkConfirmNavigationProps & WithRouterProps;

class Leasingtaker extends React.Component<ILeasingtakerProps, ILeasingtakerState> {

    public state: ILeasingtakerState = {
        leasingtaker: {
            aktorType: AktorType.LEASINGTAKER
        },
        submitted: false,
        visEksisterendeLeasingtaker: false,
        slettetLeasingtaker: false,
        ugyldigKundeInput: false
    };

    public componentDidMount(): void {
        const { kuid, sakId } = this.props.params;

        this.props.setCustomConfirmText({
            titleKey: 'registrering.modal.avbrytTitleLeasingtaker'
        });

        if (!this.props.kjoretoy.isLoading && kuid) {
            if (this.props.kjoretoy.registrering?.leietaker) {
                this.props.hentKundeMedKundeId(this.props.kjoretoy.registrering?.leietaker?.kundeId);
            }
            return;
        }
        if (sakId && !this.props.sak.sakId && !this.props.sak.isLoading) {
            this.props.hentSak(sakId);
        }
    }

    public componentDidUpdate(prevprops: ILeasingtakerProps): void {
        const { sakId } = this.props.params;
        const erSakHentet = sakId && this.props.sak.sakId && this.props.sak?.saksinformasjon?.leasingtaker;
        const kundeIkkeHentet = !this.props.kunder[this.props.sak?.saksinformasjon?.leasingtaker] && !this.props.kunder.isLoading;


        if (sakId && this.props.sak.sakId !== sakId && !this.props.sak.isLoading) {
            this.props.hentSak(sakId);
        }

        if (erSakHentet && kundeIkkeHentet) {
            this.props.hentKundeMedKundeId(this.props.sak?.saksinformasjon?.leasingtaker);
        }

        // Oppdaterer url etter sak er fullført
        if (!sakId && this.props.sak?.sakId && !this.props.sak.isLoading && !prevprops.sak.sakId) {
            this.props.navigate(`/kjoretoy/${this.props.kjoretoy.kuid}/leasingtaker/${this.props.sak.sakId}`);
        }
    }

    public componentWillUnmount(): void {
        this.props.resetSak();
    }

    public render(): React.ReactElement {
        if (this.props.kjoretoy.isLoading) {
            return <AkLoading />;
        }

        const { kjoretoy, kunder } = this.props;
        const erSakAvsluttet = this.props.sak.saksstatus === Saksstatus.AVSLUTTET;

        const aktor = erSakAvsluttet ? {
            ...kunder[this.props.sak.saksinformasjon?.leasingtaker],
            aktorType: AktorType.LEASINGTAKER,
            kanEndreAktor: false} :
            {
                ...kunder[kjoretoy.registrering?.leietaker?.kundeId],
                aktorType: AktorType.LEASINGTAKER,
                kanEndreAktor: true
            };

        return (
            <div className="container">
                <header className="row align-items-center">
                    <h1 className="col-auto">
                        <FormattedMessage id={'registrering.overskrift.ny_leasingtaker'}/>
                    </h1>
                    {this.renderKnapperad()}
                </header>
                {this.renderInfomeldinger()}
                {this.renderAktorPanel(aktor)}
                <div className="row no-gutters">
                    <KjoretoyInfoPanel kjoretoy={kjoretoy}/>
                </div>
            </div>
        );
    }

    private renderKnapperad = () => {
        if (this.props.sak?.saksstatus !== Saksstatus.AVSLUTTET) {
            return (
                <div className="ak-knapperad col-auto ml-auto d-flex justify-content-end">
                    <AkKnapp type="ak-knapp ak-tilleggsknapp" intlKey="generell.knapp.avbryt"
                             action={this.navigerTilKjoretoy}/>
                    <AkKnapp type="ak-hovedknapp"
                        intlKey="registrering.knapp.oppdaterLeasingtaker"
                        loading={this.props.sak.isLoading}
                        action={this.opprettEndreLeasingtakerSak}/>
                </div>
            );
        }
        return (
            <div className="ak-knapperad col-auto ml-auto d-flex justify-content-end">
                <AkKnapp type="ak-knapp ak-tilleggsknapp" intlKey="generell.knapp.lukk" action={this.lukkSak}/>
            </div>
        );
    };

    private renderInfomeldinger = () => {
        const leasingtakerHarMerknader = this.props.sak?.merknader?.length > 0;
        const merknadMeldinger = _.map(this.props.sak?.merknader, RegistreringRegler.utledMerknaderForLeasingtakerEndring);
        const erKundeUgyldig = !this.state.slettetLeasingtaker && !this.state.leasingtaker?.kundeId && this.state.ugyldigKundeInput;

        const infomelding = this.props.sak.saksinformasjon?.leasingtaker ? 'registrering.melding.leasingtakerEndret' : 'registrering.melding.leasingtakerFjernet';

        return (
            <div className="col-12 mb-4">
                {leasingtakerHarMerknader &&
                <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mb-4"
                               messageKey={merknadMeldinger}>
                    {this.utledMerknader() && <li>{this.utledMerknader()}</li>}
                </AkMeldingboks>}
                {this.props.sak?.saksstatus === Saksstatus.AVSLUTTET && !this.props.sak?.error &&
                <AkMeldingboks meldingType={AkMeldingboksType.INFO} messageKey={infomelding}/>}
                {erKundeUgyldig &&
                <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mb-4"
                               messageKey={'registrering.validering.skjemafeil'}>
                </AkMeldingboks>}
            </div>
        );
    };

    private utledMerknader = () => {
        const error = this.props.sak.error;
        if (error) {
            return (
                <FormattedMessage
                    id={`registrering.validering.leasingtaker.${error.errorId ? 'error' : error.errorCode}`}
                    values={{ errorId: <span className="text-nowrap">{error.errorId}</span> }}/>
            );
        }
        return null;
    };

    private renderAktorPanel = (aktor: IAktor) => {
        const sakErAvsluttet = this.props.sak?.saksstatus === Saksstatus.AVSLUTTET;
        const sakHarFeilEllerMerknader = !!this.props.sak?.error || this.props.sak?.merknader.length > 0;
        const leasingtakerErFjernet = !this.state.leasingtaker.kundeId && this.state.slettetLeasingtaker;

        const visLeasingtakerErFjernetPanel = (!sakErAvsluttet && leasingtakerErFjernet && !sakHarFeilEllerMerknader)
            || (sakErAvsluttet && !this.props.sak.saksinformasjon?.leasingtaker);
        const visRedigerbarAktorPanel = sakHarFeilEllerMerknader || (!this.state.submitted && !this.state.slettetLeasingtaker && this.state.visEksisterendeLeasingtaker);

        return (
            <>
                <div className="row">
                    <Panel overskrift="registrering.overskrift.ny_leasingtaker"
                           panelCssClass="col-12 ak-panel-transparent"
                           innholdCssClass="ak-panel-innhold row p-0 no-gutters">
                        {!visRedigerbarAktorPanel && !visLeasingtakerErFjernetPanel && this.renderPanel(aktor)}
                        {visRedigerbarAktorPanel && this.renderPanel(this.state.leasingtaker)}
                        {visLeasingtakerErFjernetPanel &&
                        <Panel panelCssClass="ak-panel ak-panel-innhold col-md col-12"
                               innholdCssClass="ak-panel ak-panel-innhold row p-0 no-gutters">
                            <h3 className="col-auto ak-fet">
                                <FormattedMessage id={'registrering.melding.ingenLeasingtaker'}/>
                            </h3>
                        </Panel>}
                    </Panel>
                </div>
            </>
        );
    };

    private renderPanel = (aktor: IAktorPanelAktor) => {
        if (this.props.sak?.saksstatus === Saksstatus.AVSLUTTET) {
            aktor.kanEndreAktor = false;
        }

        return (
            <AktorPanel
                aktor={aktor}
                kunder={this.props.kunder}
                hentAktor={this.hentAktorer}
                submitted={this.state.submitted}
                handleAktorChange={this.handleChangeLeasingtaker}
                panelOverskrift="kodeverk.aktortype.LEASINGTAKER"
                panelClassName="col-md col-12"
                sokLabelClassName="ak-fet"
                sokInputClassName="ak-input-fast-bredde"
                kundeinfoClassName="ak-dl-liste ak-dl-liste-grid-kompakt"
                skalIkkeViseGodkjenningsstatus={true}
                skalViseKundeIkon={true}>
                {aktor?.kundeId && <AktorKundeinfo/>}
            </AktorPanel>
        );
    };

    private hentAktorer = (orgnr: string) => {
        return this.props.hentKundeMedOrgnr(orgnr).then(() => {
            const kunde = _.get(this.props.kunder, orgnr);
            if (!_.some(kunde && kunde.merknader, { type: RegelmerknaderType.KRITISK })) {
                return this.handleChangeLeasingtaker(kunde as IAktor);
            }
        });
    };

    private handleChangeLeasingtaker = (aktor: IAktor, slett?: boolean) => {
        this.props.confirmNavigation(true);
        const harSakMerknader = this.props.sak?.merknader.length > 0;
        if (harSakMerknader) {
            this.props.resetSak();
            this.setState({
                leasingtaker: { ...aktor, kanEndreAktor: true },
                visEksisterendeLeasingtaker: true,
                slettetLeasingtaker: false,
                submitted: false
            });
        }
        if (slett) {
            this.setState({
                leasingtaker: {
                    aktorType: AktorType.LEASINGTAKER
                },
                slettetLeasingtaker: true
            });
        }
        if (!harSakMerknader) {
            this.setState({
                leasingtaker: { ...aktor, kanEndreAktor: true },
                visEksisterendeLeasingtaker: true
            });
        }
    };

    private opprettEndreLeasingtakerSak = () => {
        this.props.confirmNavigation(false);
        const ugyldigKundeInput = !this.state.leasingtaker?.kundeId && !this.state.slettetLeasingtaker;

        if (ugyldigKundeInput) {
            this.setState({ugyldigKundeInput: true});
        }

        if (!ugyldigKundeInput) {
            const sak: IRegistreringSak = {
                saksType: SaksType.LEASINGTAKER_ENDRE,
                kuid: this.props.kjoretoy.kuid,
                leasingtaker: this.state.leasingtaker.kundeId
            };
            return this.props.opprettLeasingtaker(sak).then(() => {
                if (!_.find(sak.merknader, { type: RegelmerknaderType.KRITISK })) {
                    this.setState({ submitted: true });
                }
                this.setState({ slettetLeasingtaker: true });
            });
        }
    };

    private navigerTilKjoretoy = () => {
        this.props.navigate(`/kjoretoy/${this.props.kjoretoy.kuid}`);
        this.props.resetSak();
    };

    private lukkSak = () => {
        this.props.getKjoretoyFromKuid(this.props.kjoretoy.kuid);
        this.navigerTilKjoretoy();
    };
}

const mapStateToProps = (state: RootStateType): ILeasingtakerStateProps => ({
    kjoretoy: state.kjoretoy,
    kunder: state.kunder,
    sak: state.sak
});

const mapDispatchToProps = (dispatch): ILeasingtakerDispatchProps => ({
    hentKundeMedKundeId: (kundeId) => dispatch(getKunde({ kundeId })),
    hentKundeMedOrgnr: (orgFnr) => dispatch(getKunde({ orgFnr })),
    getKjoretoyFromKuid: (kuid: string) => dispatch(kjoretoyGetDataFromKuidAction(kuid)),
    opprettLeasingtaker: (sak) => dispatch(opprettLeasingtakerEndreSak(sak)),
    hentSak: (sakId) => dispatch(getRegistreringSak(sakId)),
    resetSak: () => dispatch(resetSak())
});

const LeasingtakerConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    AkConfirmNavigation,
    withRouter
)(Leasingtaker);

export type { ILeasingtakerProps };
export { LeasingtakerConnected, Leasingtaker };
