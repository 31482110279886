import type { ThunkResult } from '../../models/types';
import { KlientkonfigurasjonActionKey } from '../../constants';
import { AkrConfig } from '../../constants';
import { createRestResource } from '../store';
import { addGlobalErrors } from './globals';

export type KlientkonfigurasjonActionType =
    IKlientkonfigurasjonLoadingAction
    | IKlientkonfigurasjonHasErrorAction
    | IKlientkonfigurasjonFetchDataSuccessAction
    | IKlientkonfigurasjonOtherAction;

export interface IKlientkonfigurasjonLoadingAction {
    type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_IS_LOADING;
    isLoading: boolean;
}

export interface IKlientkonfigurasjonFetchDataSuccessAction {
    type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS;
    konfigurasjon: Record<string, any>;
}

export interface IKlientkonfigurasjonOtherAction {
    type: KlientkonfigurasjonActionKey.OTHER_ACTION;
}

export interface IKlientkonfigurasjonHasErrorAction {
    type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_HAS_ERROR;
}

const klientkonfigurasjonIsLoading = (isLoading: boolean): IKlientkonfigurasjonLoadingAction => ({type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_IS_LOADING, isLoading});
const klientkonfigurasjonHasError = (): IKlientkonfigurasjonHasErrorAction => ({type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_HAS_ERROR});
const klientkonfigurasjonFetchDataSuccess = (konfigurasjon: Record<string, any>): IKlientkonfigurasjonFetchDataSuccessAction =>
    ({type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS, konfigurasjon});

const api = createRestResource(AkrConfig.KLIENTKONFIGURASJON_RESOURCE_URL);

export function klientkonfigurasjonGetData(): ThunkResult<Promise<any>, KlientkonfigurasjonActionType> {
    return dispatch => {
        dispatch(klientkonfigurasjonIsLoading(true));
        return api.get()
            .then(response => {
                if (response.errorCode) {
                    dispatch(klientkonfigurasjonHasError());
                    return dispatch(addGlobalErrors(response));
                }
                return dispatch(klientkonfigurasjonFetchDataSuccess(response));
            })
            .then(() => dispatch(klientkonfigurasjonIsLoading(false)))
            .catch(() => {
                dispatch(klientkonfigurasjonHasError());
                return dispatch(klientkonfigurasjonIsLoading(false));
            });
    };
}
