import * as _ from 'lodash';
import type { ThunkAction } from 'redux-thunk';
import { VilkarstatusSaksdokument } from 'svv-tk-akr-common-frontend';

import { ForstegangsregistreringArkivdokumentActionKey } from '../../constants';
import { MeldingType } from '../../models/kodeverk';
import type { IArkivdokument, IError, ISaksdokument, RootStateType } from '../../models/types';
import { harAvvistDokumentTilKontrollpanel } from '../../utils';
import { hentForstegangsregistrering } from './forstegangsregistrering-action';
import { nyMelding } from './melding-bar';
import type { SakActionType} from './sak';
import { deleteDocument, editDocument, sakUpdateMerknaderAction, uploadDocument } from './sak';

export type ForstegangsregistreringArkivdokumenterActionType =
    IForstegangsregistreringArkivdokumenterAddAction
    | IForstegangsregistreringArkivdokumenterDeleteAction
    | IForstegangsregistreringArkivdokumenterEditAction
    | IForstegangsregistreringArkivdokumenterLoadingAction
    | IForstegangsregistreringArkivdokumenterSuccessAction
    | IForstegangsregistreringArkivdokumenterOtherAction;

export interface IForstegangsregistreringArkivdokumenterAddAction {
    type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_ADD;
    arkivdokument: IArkivdokument;
}

export interface IForstegangsregistreringArkivdokumenterDeleteAction {
    type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_DELETE;
    id: number;
}

export interface IForstegangsregistreringArkivdokumenterEditAction {
    type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_EDIT;
    arkivdokument: IArkivdokument;
}

export interface IForstegangsregistreringArkivdokumenterLoadingAction {
    type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_LOADING;
    isLoading: boolean;
}

export interface IForstegangsregistreringArkivdokumenterSuccessAction {
    type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENTER_SUCCESS;
}

export interface IForstegangsregistreringArkivdokumenterOtherAction {
    type: ForstegangsregistreringArkivdokumentActionKey.OTHER_ACTION;
}

export const forstegangsregistreringArkivdokumenterEditAction = (arkivdokument: IArkivdokument): IForstegangsregistreringArkivdokumenterEditAction =>
    ({type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_EDIT, arkivdokument});

export const forstegangsregistreringArkivdokumenterDeleteAction = (id: number): IForstegangsregistreringArkivdokumenterDeleteAction =>
    ({type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_DELETE, id});

export const forstegangsregistreringArkivdokumenterLoadingAction = (): IForstegangsregistreringArkivdokumenterLoadingAction =>
    ({type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_LOADING, isLoading: true});

export const forstegangsregistreringArkivdokumenterSuccessAction = (): IForstegangsregistreringArkivdokumenterSuccessAction =>
    ({type: ForstegangsregistreringArkivdokumentActionKey.FORSTEGANGSREGISTRERING_ARKIVDOKUMENTER_SUCCESS});

export const forstegangsregistreringArkivdokumenterUnchangedAction = (): IForstegangsregistreringArkivdokumenterOtherAction =>
    ({type: ForstegangsregistreringArkivdokumentActionKey.OTHER_ACTION});

export const uploadForstegangsregistreringArkivdokument = (sakId: string, fileWithMeta: IArkivdokument):
    ThunkAction<Promise<any>, RootStateType, void, ForstegangsregistreringArkivdokumenterActionType | SakActionType> => (dispatch) => {
    dispatch(forstegangsregistreringArkivdokumenterLoadingAction());
    return dispatch(uploadDocument(sakId, fileWithMeta)).then(response => {
                if (isError(response)) {
                    return response;
                }
                dispatch(hentForstegangsregistrering(sakId));
                dispatch(forstegangsregistreringArkivdokumenterSuccessAction());
    });
};

export const deleteForstegangsregistreringArkivdokument = (sakId: string, dokumentId: number):
    ThunkAction<void, RootStateType, undefined, ForstegangsregistreringArkivdokumenterActionType | SakActionType> => {
    return dispatch => {
        dispatch(forstegangsregistreringArkivdokumenterLoadingAction());

        deleteDocument(sakId, dokumentId)
            .then((response) => {
                if (response.errorId) {
                    return dispatch(nyMelding({meldingIntlId: 'registrering.validering.dokumentasjonsstatusFeilet', meldingType: MeldingType.DANGER}));
                }

                dispatch(hentForstegangsregistrering(sakId));
                dispatch(sakUpdateMerknaderAction(response.merknader, false));
                return dispatch(forstegangsregistreringArkivdokumenterDeleteAction(response));
            });
    };
};

export const editForstegangsregistreringArkivdokument = (sakId: string, dokument: IArkivdokument):
    ThunkAction<void, RootStateType, undefined, SakActionType | ForstegangsregistreringArkivdokumenterActionType> => {
    return dispatch => {
        dispatch(forstegangsregistreringArkivdokumenterLoadingAction());

        editDocument(sakId, dokument)
            .then((response) => {
                if (isError(response)) {
                    return dispatch(nyMelding({meldingIntlId: 'registrering.validering.dokumentasjonsstatusFeilet', meldingType: MeldingType.DANGER}));
                }

                dispatch(hentForstegangsregistrering(sakId));
                dispatch(sakUpdateMerknaderAction(response.merknader, false));
                return dispatch(forstegangsregistreringArkivdokumenterEditAction(response));
            });
    };
};

function endreForstegangsregistreringAkrivdokumentUtenAtHenteStatus(sakId: string, dokument: IArkivdokument):
    ThunkAction<void, RootStateType, undefined, ForstegangsregistreringArkivdokumenterActionType> {
    return dispatch => {
        dispatch(forstegangsregistreringArkivdokumenterLoadingAction());

        editDocument(sakId, dokument).then((response) => {
            if (isError(response)) {
                return dispatch(nyMelding({meldingIntlId: 'registrering.validering.dokumentasjonsstatusFeilet', meldingType: MeldingType.DANGER}));
            }
            return dispatch(forstegangsregistreringArkivdokumenterEditAction(response));
        });
    };
}

export function endreDokumentSendTilSaksbehandlingForstegansregistrering(sakId: string, arkivdokumenter: IArkivdokument[]):
    ThunkAction<void, RootStateType, undefined, ForstegangsregistreringArkivdokumenterActionType> {
    return dispatch => {
        _.forEach(arkivdokumenter, arkivdokument => {
            const saksdokumenterFjernetAvvistArsak = [] as ISaksdokument[];
            _.forEach(arkivdokument.saksdokumenter, saksdokument => {
                if (harAvvistDokumentTilKontrollpanel(saksdokument)) {
                    const oppdatertSakdok = {...saksdokument, avvistArsak: saksdokument.godkjentStatus === VilkarstatusSaksdokument.TIL_VURDERING ? null : saksdokument.avvistArsak};
                    saksdokumenterFjernetAvvistArsak.push(oppdatertSakdok);
                }
            });
            if (!_.isEmpty(saksdokumenterFjernetAvvistArsak)) {
                const oppdatertAkrivdokument = {
                    ...arkivdokument,
                    saksdokumenter:
                        _.concat(_.reject(arkivdokument.saksdokumenter, (saksdokument: ISaksdokument) => !_.isEmpty(saksdokument.avvistArsak)),
                            saksdokumenterFjernetAvvistArsak)
                };
                return dispatch(endreForstegangsregistreringAkrivdokumentUtenAtHenteStatus(sakId, oppdatertAkrivdokument));
            } else {
                return dispatch(forstegangsregistreringArkivdokumenterUnchangedAction());
            }
        });
    };
}

const isError = (response): boolean => !!(response as IError).errorId || !!(response as IError).errorCode;