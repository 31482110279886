import * as React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { AkLoadingList } from 'svv-tk-akr-common-frontend';

import type { IArbeidsliste } from '../../models/types';

const antallLoadingRader = 6;
const wrapper = (content: ReactNode) => (<div className="ak-table-row ak-table-row-empty py-3">{content}</div>);

const ArbeidslisteFeilhandtering: React.FC<{liste: IArbeidsliste}> = (props) => {
    const {error, isLoading, rader} = props.liste;

    if (isLoading) {
        return <>
            <table>
                <tbody>
                    <AkLoadingList antallRader={antallLoadingRader} />
                </tbody>
            </table>
            </>;
    }

    if (error && error.errorId) {
        return wrapper(<p className="ak-text-error"><FormattedMessage id="startside.arbeidsliste.error" values={{errorId: error.errorId}}/></p>);
    }

    return !rader.length && wrapper(<FormattedMessage tagName="p" id="startside.arbeidsliste.tom" />);

};

export {ArbeidslisteFeilhandtering};

