import * as React from 'react';
import './aktor-style';

export const TwoColumnSpan: React.FC<React.PropsWithChildren> = props => {
    return (
        <div className="span-col-2 align-self-center d-flex">
            {props.children}
        </div>
    );
};
