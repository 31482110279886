/* eslint-disable max-lines */
import * as _ from 'lodash';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { MapStateToProps } from 'react-redux';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { compose } from '@reduxjs/toolkit';
import type { IAkConfirmNavigationProps, IKundeAdresse, IKunderMap,
    IMessageDescriptor, IVilkar} from 'svv-tk-akr-common-frontend';
import {
    AdressePanel, AkConfirmNavigation, AkDrosjePanel, AkKnapp, AkKontrollistePanel, AkLoading, AkModal, AkModalType, AktorType, AkVilkarPanel, KjoretoyInfoPanel, Panel, VilkarStatus
} from 'svv-tk-akr-common-frontend';

import { RegistreringStatus } from '../../constants';
import { ArbeidslisteSakstypefilter, AvsluttSakIndikator, Kanal, KjoringensArt, RegelmerknaderKoder, Saksstatus, SaksType, Vilkarsnavn } from '../../models/kodeverk';
import type {
    Brukerprofil,
    IAvgiftsstatusResultat, IError, IGetKundeMedKundeId, IGetKundeMedOrgFnr, IKjoretoy, IKjoringensArt, IKodeType, IKunde, INyttKjennemerke, IRegistrering, IRegistreringInformasjon, IRegistreringSak,
    ISak, ISakListe, IVilkarstatus, RootStateType
} from '../../models/types';
import { KjennemerkeRegler, KjoretoyRegler, Merknader, RegistreringRegler } from '../../regler';
import {
    avbrytRegistrering, endreErTilGodkjenningStatus, getKunde, getRegistreringSak, getRegistreringSakFattVedtak, hentDokumentasjonsstatusRegistrering, henteDrosjeloyvenummer,
    hentKjennemerkeserierOgTyper, hentKjoringensArtListe, hentMidlertidigVognkort, kjoretoyGetDataFromKuidAction, kontrollerVilkar, oppdaterRegistreringSak, oppdaterRegistreringSkjema,
    opprettRegistreringSak, populerNySak, registreringSakClearState, resetSak, utledAvgiftsstatus
} from '../../state/actions';
import {
    alleKjoringensArtSelector, avgiftsstatusSelector, brukerprofilSelector, erKjennemerkerUtilgjengeligeSelector, erKravOmVektarsavgiftSelector, gjeldendeEierSelector, gjeldendeMedeierSelector,
    gyldigeKjoringensArtSelector, harInnfriddFjerneTraktorMedBruksbegrensningMerknadSelector, harRolleOmregOgSeriekjennemerke, kanBehandleRegistreringSelector, kanStarteOmregistreringssakSelector,
    kanStarteParegistreringssakSelector, kjoretoySelector, kunderSelector, neringskoderSelector, registreringSakSelector, registreringSelector, sakSelector, statusForRegistreringSelector,
    vilkarForRegistreringSelector, vilkarstatusSelector
} from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { skalVisesIKontrollpanel, utledVisningsdokumenterTilKontrollisteFraArkivdokumenter, withRouter } from '../../utils';
import { RegistreringKjennemerkePanelConnected } from '../felles-registrering';
import { KjoretoyBrukConnected } from '../kjoretoy-bruk';
import {
    RegistreringInfoMeldingerConnected, RegistreringKnapperadConnected, RegistreringLeasingtakerOgUnderenhetConnected, RegistreringStatusMeldingerConnected, TilhengerpanelConnected
} from '../registrering';
import { RegistreringDokumentasjonConnected } from './registrering-dokumentasjon';
import { VilkarstatusHenterConnected } from './vilkarstatus-henter';

interface IRegistreringProps {
    sakId?: string;
}

interface IRegistreringStateProps {
    kjoretoy?: IKjoretoy;
    brukerprofil?: Brukerprofil;
    registrering?: IRegistrering;
    registreringSakRequest?: IRegistreringSak;
    sakResponse?: ISak<IRegistreringInformasjon>;
    gyldigeKjoringsensArt?: IKjoringensArt[];
    alleKjoringensArt: IKjoringensArt[];
    neringskoder?: IKodeType[];
    vilkar: IVilkar[];
    eier: IKunde;
    sakliste: ISakListe;
    medeier?: IKunde;
    status: RegistreringStatus;
    erKjennemerkerUtilgjengelige: boolean;
    kanStarteOmregistreringssak: boolean;
    kanStarteParegistreringssak: boolean;
    kanBehandleRegistrering: boolean;
    erKravOmVektarsavgift: boolean;
    vilkarstatus?: IVilkarstatus;
    avgiftsstatus: IAvgiftsstatusResultat;
    harRolleOmregOgSeriekjennemerke: boolean;
    harInnfriddFjerneTraktorMedBruksbegrensningMerknad: boolean;
    kunder: IKunderMap;
}

interface IRegistreringDispatchProps {
    getKunde: (fnrOnr: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktorType?: AktorType, kuid?: string) => Promise<any>;
    avbrytSak: (sakId: string) => Promise<any>;
    opprettSak: (sak: IRegistreringSak, avsluttSakIndikator?: AvsluttSakIndikator) => Promise<any>;
    oppdaterSak: (sak: IRegistreringSak, avsluttSakIndicator: AvsluttSakIndikator) => Promise<any>;
    endreErTilGodkjenningStatus: (sak: IRegistreringSak) => Promise<any>;
    getSak: (sakId: string) => Promise<any>;
    henteDrosjeloyvenummer: (kuid: string) => Promise<any>;
    getSakFattVedtak: (sakId: string) => Promise<any>;
    resetSak: () => Promise<any>;
    hentMidlertidigVognkort: (kuid: string) => void;
    kjoretoyGetDataAction: (kuid: string) => void;
    oppdaterRegistreringSkjema: (sak: Partial<IRegistreringSak>) => Promise<any>;
    populerNySak: (alleKjoringensArt: IKjoringensArt[]) => Promise<any>;
    hentVilkarstatus?: (saksinformasjon: Partial<IRegistreringInformasjon>) => Promise<IVilkarstatus>;
    utledAvgiftsstatus: (callbackUrl: string) => Promise<IAvgiftsstatusResultat | void>;
    clearRegistreringSak: () => void;
    hentDokumentstatus: () => Promise<any>;
    hentKjennemerkeserierOgTyper?: (kuid: string, kjoringensArt: string) => Promise<any>;
    hentKjoringensArt: (kuid: string, kundeId: string) => void;
}

interface IRegistreringState {
    modalInfo: {
        showModal: boolean;
        title?: string;
        bodyText?: string;
        meldingModalType?: AkModalType;
        bodyChildrenKeys?: IMessageDescriptor[];
    };
    validation: {
        submitted?: boolean;
        errors?: string[];
        gyldig?: boolean;
        errorId?: IError;
    };
    adressepanel: {
        showAdressepanel: boolean;
        adresseSubmitted: boolean;
    };
}

const modalInformasjon = {
    lukk: {
        title: 'registrering.modal.lukkTitle',
        bodyText: 'registrering.modal.lukkBodyText',
        meldingModalType: 'info' as AkModalType
    },
    avbryt: {
        title: 'registrering.modal.avbrytTitle',
        bodyText: 'registrering.modal.avbrytBodyText',
        meldingModalType: 'warn' as AkModalType
    },
    innleverteKjennemerker: {
        title: 'registrering.modal.innleverteKjennemerkerTitle',
        bodyText: '',
        meldingModalType: 'info' as AkModalType
    }
};

type BaseRegistreringProps = IRegistreringProps & IRegistreringStateProps & IRegistreringDispatchProps;
type RegistreringProps = BaseRegistreringProps & WrappedComponentProps & IAkConfirmNavigationProps & WithRouterProps;

class Registrering extends React.Component<RegistreringProps, IRegistreringState> {

    public state: IRegistreringState = {
        modalInfo: {
            showModal: false,
            title: '',
            bodyText: '',
            meldingModalType: AkModalType.INFO,
            bodyChildrenKeys: []
        },
        validation: {
            gyldig: true,
            submitted: false,
            errors: [],
            errorId: null
        },
        adressepanel: {
            showAdressepanel: false,
            adresseSubmitted: false
        }
    };

    public componentDidUpdate(prevProps: IRegistreringStateProps) {
        if (!_.isEqual(prevProps.registreringSakRequest, this.props.registreringSakRequest) &&
            !this.props.registrering.eier.harGyldigAdresse) {
            this.setUgyldigAdresseState();
        }

        if (this.skalPopulereNySak(prevProps)) {
            this.props.populerNySak(this.props.alleKjoringensArt);
        }

        if (harPopulertNySak(prevProps, this.props)) {
            this.props.hentDokumentstatus();
            const registrertKjoringensArt = this.props.kjoretoy.registrering.bruksinformasjon.kjoringensArt.kode;

            if (this.erKjoringensArtDrosjeEllerSpesinnrHcpLp(registrertKjoringensArt) && this.props.registreringSakRequest.saksType === SaksType.REGISTRERING_SAMME_EIER) {
                this.props.henteDrosjeloyvenummer(this.props.kjoretoy.kuid);
            }
        }

        if (!prevProps.registreringSakRequest?.saksType && !!this.props.registreringSakRequest?.saksType) {
            if (erRegistreringAvsluttet(this.props.sakResponse)) {
                this.hentEierOgMedeierFraKjoretoyOgLastKundedata();
            } else {
                this.props.hentDokumentstatus();
            }
            this.props.confirmNavigation(false);
        }

        if (harForsoktFatteVedtak(prevProps, this.props) && this.props.sakResponse.saksstatus === Saksstatus.APEN) {
            this.fattVedtak();
        }

        if (prevProps.sakResponse.saksstatus === Saksstatus.APEN && this.props.sakResponse.saksstatus === Saksstatus.AVSLUTTET) {
            this.oppdaterKjoretoyOmSaksstatusErAvsluttet();
        }
    }

    public componentDidMount() {
        if (this.props.kanStarteOmregistreringssak || this.props.kanStarteParegistreringssak) {
            this.hentEierOgMedeierFraKjoretoyOgLastKundedata();
            if (this.props.erKjennemerkerUtilgjengelige) {
                this.openMeldingForInnleverteKjennemerkerModal();
            }
            if (this.props.registrering.eier) {
                this.props.hentKjoringensArt(this.props.kjoretoy.kuid, this.props.kjoretoy.registrering.eier.kundeId);
            }
        }

        const sakId = this.props.sakId;

        if (sakId && !this.props.sakResponse.isLoading) {
            this.props.getSak(sakId);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearRegistreringSak();
        this.props.resetSak();
    }

    public render() {
        const { registreringSakRequest, sakResponse, kjoretoy } = this.props;

        if (this.isLoading() && !sakResponse.isLoadingModalOpen) {
            return <AkLoading />;
        }

        const erLesemodus = this.erLesemodus();
        const skalViseTilhengerpanelILesemodus = erLesemodus && registreringSakRequest.grunnlagVektarsavgift && registreringSakRequest.grunnlagVektarsavgift.skalTrekkeTilhenger;
        const kjennemerkeType = this.props.kjoretoy.registrering.kjennemerke.kjennemerkeType;

        return (
            <div className="container d-flex flex-column">
                <VilkarstatusHenterConnected skalHenteVilkarstatus={this.skalHenteVilkarStatus()}>
                    {this.renderHeader()}
                    {this.state.adressepanel.showAdressepanel &&
                    <AdressePanel customPanelClassNames="row no-gutters" customInnholdClassNames="col-6" customContainerClassNames="col-auto" required={true}
                        onSubmitFn={this.handleKundeadresseSubmit} onReadModeToggleFn={this.setUgyldigAdresseState} notEditable={sakResponse.saksstatus === Saksstatus.AVSLUTTET}
                        kundeadresse={_.get(sakResponse, 'saksinformasjon.vognkortsaksinformasjon.forsendelsesadresse', null)} onErrorFn={this.handleKundeadresseErrors} />
                    }
                    {this.harKundeFeilet() ? <Navigate to="/feil/kjoretoy" /> : <KjoretoyInfoPanel kjoretoy={kjoretoy} customPanelCss="col-12 px-0" />}
                    {this.renderBruksinformasjonOgVilkar()}
                    {this.kanViseInformasjon() && (this.props.erKravOmVektarsavgift || (erLesemodus && skalViseTilhengerpanelILesemodus)) &&
                    <TilhengerpanelConnected lesemodus={erLesemodus} submitted={this.state.validation.submitted} confirmNavigation={this.props.confirmNavigation} />
                    }
                    {this.skalViseKontrolliste() && this.renderKontrolliste()}
                    <RegistreringDokumentasjonConnected isLesemodus={erLesemodus} sakId={this.props.sakId}
                        confirmNavigation={this.props.confirmNavigation} setCustomConfirmText={this.props.setCustomConfirmText} />
                    {this.skalViseKjennemerkePanel() &&
                    <RegistreringKjennemerkePanelConnected
                        kjennemerke={registreringSakRequest?.kjennemerke ?? { kjennemerkeType } as INyttKjennemerke}
                        updateKjennemerke={this.oppdaterKjennemerke}
                        hentKjennemerkeserierOgTyper={this.hentKjennemerkeserierOgTyper}
                        kjoringensArt={registreringSakRequest?.kjoringensArt} />
                    }
                    {this.getModalForLukk()}
                    {this.getModalForAvbrytRegistrering()}
                    {this.getMeldingForInnleverteKjennemerkerModal()}
                    <div className="ak-knapperad ml-auto col-md-6 col-lg-auto col-12 mt-2 mt-md-0 d-md-none">
                        {this.renderKnapperad()}
                    </div>
                </VilkarstatusHenterConnected>
            </div>
        );
    }


    private skalPopulereNySak(prevProps: IRegistreringStateProps) {
        return _.isEmpty(prevProps.alleKjoringensArt) && !_.isEmpty(this.props.alleKjoringensArt) && !this.props.sakId;
    }

    private erLesemodus() {
        const sakResponse = this.props.sakResponse;
        if (sakResponse && this.props.sakId !== sakResponse.sakId) {
            return false;
        }

        return sakResponse.saksstatus === Saksstatus.AVSLUTTET || sakResponse.saksstatus === Saksstatus.AVBRUTT || sakResponse.erTilGodkjenning;
    }

    private skalHenteVilkarStatus() {
        return this.props.sakId && this.props.sakResponse.sakId && !(this.props.sakResponse.saksstatus === Saksstatus.AVSLUTTET || this.props.sakResponse.saksstatus === Saksstatus.AVBRUTT);
    }

    private renderHeader = () => {
        const erLesemodus = this.erLesemodus();
        return (
            <header className="row align-items-end mb-3 no-gutters">
                <h1 className="col-12 col-lg-5">
                    <FormattedMessage id={`registrering.overskrift.${this.erOmregistrering() ? 'omregistrereKjoretoy' : 'paregistrereKjoretoy'}`} />
                </h1>
                <div className="ak-knapperad ml-auto col-md-6 col-lg-auto col-12">
                    {this.renderKnapperad()}
                </div>
                {this.kanViseInformasjon() &&
                <RegistreringStatusMeldingerConnected
                    opprettSak={this.opprettSak}
                    erLesemodus={erLesemodus}
                    hentMidlertidigVognkort={this.hentMidlertidigVognkort}
                    omregistrering={this.erOmregistrering()}
                    submitted={this.state.validation.submitted}
                />
                }
                <RegistreringInfoMeldingerConnected registreringValidation={this.state.validation} vilkar={this.props.vilkar} saksstatus={this.props.registreringSakRequest.saksstatus} />
            </header>
        );
    };

    private renderBruksinformasjonOgVilkar = () => {
        const { registreringSakRequest, vilkarstatus, sakliste } = this.props;
        const { neringskode, kjoringensArt, drosjeloyvenummer } = registreringSakRequest;
        const erLesemodus = this.erLesemodus();

        return (
            <div className="d-flex no-gutters flex-column flex-md-row">
                <div className="col-md-7 pr-md-4 mb-3">
                    <Panel panelCssClass="w-100 mb-0 pb-2">
                        <FormattedMessage id={'sak.overskrift.bruk'} tagName="h2" />
                        {this.kanViseElement &&
                        <KjoretoyBrukConnected erForstegangsregistrering={false} lesemodus={erLesemodus} neringskode={neringskode} eier={this.props.eier}
                            kjoringensArt={kjoringensArt} errors={this.state.validation.errors} oppdaterBruk={this.oppdaterBruk}
                            ugyldigeKjoringensArt={this.utledUgyldigeKjoringesArt()} inputContainerClassName="col-12 col-lg-7 flex-nowrap" />
                        }
                    </Panel>
                    <RegistreringLeasingtakerOgUnderenhetConnected validationErrors={this.state.validation.errors} submitted={this.state.validation.submitted} />
                    <AkDrosjePanel
                        panelCssClass="mt-4 mb-0"
                        showPanel={this.skalViseDrosjePanel()}
                        lesemodus={erLesemodus}
                        loading={sakliste.isLoading}
                        drosjeloyvenummer={this.erValgtKjorigensArtLikRegistrertKjoringensArt(kjoringensArt) ? drosjeloyvenummer : ''}
                        updateDrosjeloyvenummer={this.oppdatereDrosjeloyvenummer} />
                </div>
                <AkVilkarPanel overskriftOppfylt={{ id: 'sak.vilkar.overskriftOppfylt' }} overskriftIkkeOppfylt={{ id: 'sak.vilkar.overskriftIkkeOppfylt' }}
                    vilkar={this.props.vilkar} loading={vilkarstatus.isLoading} error={vilkarstatus.error} containerClassName="col col-md-5 mb-auto" />
            </div>
        );
    };

    private erKjoringensArtDrosjeEllerSpesinnrHcpLp = (kjoringensArt: string) => {
        return kjoringensArt === KjoringensArt.DROSJE_EIER.valueOf() || kjoringensArt === KjoringensArt.SPESINNR_HCP_LP.valueOf();
    };

    private erValgtKjorigensArtLikRegistrertKjoringensArt = (kjoringensArt: string) => {
        return kjoringensArt === this.props.kjoretoy.registrering.bruksinformasjon.kjoringensArt.kode;
    };

    private skalViseDrosjePanel = () => {
        const { kjoringensArt, saksType} = this.props.registreringSakRequest;
        return this.erKjoringensArtDrosjeEllerSpesinnrHcpLp(kjoringensArt) && this.erSaksTypeRegistreringSammeEierOgKjoringensArtEndret(kjoringensArt, saksType);
    };

    private erSaksTypeRegistreringSammeEierOgKjoringensArtEndret = (kjoringensArt: string, saksType: SaksType) => {
        return saksType === SaksType.REGISTRERING_SAMME_EIER ? (!this.erValgtKjorigensArtLikRegistrertKjoringensArt(kjoringensArt)) : true;
    };

    private renderKnapperad = () => {
        return (
            <RegistreringKnapperadConnected
                opprettSak={this.opprettSak}
                oppdater={this.oppdater}
                oppdaterOgRegistrer={this.oppdaterOgRegistrer}
                erLesemodus={this.erLesemodus()}
                kanRendreKnappForAvbrytRegistrering={this.kanRendreKnappForAvbrytRegistrering()}
                openModalForAvbrytRegistrering={this.openModalForAvbrytRegistrering}
                lukk={this.lukk}
                omregistrering={this.erOmregistrering()}
                finnesObligatoriskeDokumenterSomKanLastesOpp={this.finnesObligatoriskeDokumenterSomKanLastesOpp()}
                sendTilSaksbehandler={this.sendTilSaksbehandler}
                kanViseInformasjon={this.kanViseInformasjon()}
            />
        );
    };

    private skalViseKontrolliste(): boolean {
        return _.some(this.props.registreringSakRequest.arkivdokumenter,
            arkDok => _.some(arkDok.saksdokumenter, skalVisesIKontrollpanel));
    }

    private renderKontrolliste = () => {
        const kontrollistedokumenter = utledVisningsdokumenterTilKontrollisteFraArkivdokumenter(this.props.registreringSakRequest.arkivdokumenter);
        return <AkKontrollistePanel dokumenterSomErTilGodkjenning={kontrollistedokumenter} erLesemodus={true} />;
    };

    private sendTilSaksbehandler = () => {
        const registreringSak = this.props.registreringSakRequest;
        const errors = this.getErrors();

        if (errors.length) {
            this.setValidationState(false, errors);
            return;
        }

        this.props.confirmNavigation(false);
        this.props.setCustomConfirmText({ bodyKey: undefined });

        this.setValidationState(true, errors);

        if (this.finnesObligatoriskeDokumenterSomKanLastesOpp() && registreringSak.sakId) {
            const erSendtTilsaksbehandling = this.props.sakResponse.erTilGodkjenning;
            if (!erSendtTilsaksbehandling && RegistreringRegler.enEllerFlerObliDokErIkkeOpplastet(this.props.vilkar)) {
                this.setValidationState(false, ['registrering.validering.manglerObligatoriskeDokumenter']);
            } else {
                this.props.endreErTilGodkjenningStatus(registreringSak);
            }
        }
    };

    private oppdaterKjennemerke = (kjennemerke: INyttKjennemerke) => {
        const harByttetKjennemerkeserie = !_.isEqual(this.props.registreringSakRequest.kjennemerke?.kjennemerkeserie, kjennemerke.kjennemerkeserie);

        if (harByttetKjennemerkeserie) {
            this.props.confirmNavigation(true);
        }

        const oppdatertSkjema = { ...this.props.registreringSakRequest, kjennemerke };
        this.props.oppdaterRegistreringSkjema(oppdatertSkjema);

        if (!this.erLesemodus() && !this.props.registreringSakRequest.sakId) {
            this.props.hentVilkarstatus(oppdatertSkjema);
        }
    };

    private skalViseKjennemerkePanel = () => !this.erLesemodus()
        && this.props.harRolleOmregOgSeriekjennemerke
        && new KjoretoyRegler(this.props.kjoretoy).kreverKjennemerkebytte()
        && KjennemerkeRegler.erAlleKjennemerkerGjortRedeFor(this.props.kjoretoy)
        && !KjennemerkeRegler.kjennemerkeTypeMaEndresPaTrafikkstasjon(this.props.kjoretoy.merknader);

    private oppdatereDrosjeloyvenummer = (drosjeloyvenummer: string): void => {
        const oppdatertSkjema = { ...this.props.registreringSakRequest, drosjeloyvenummer };
        this.props.confirmNavigation(true);
        this.props.oppdaterRegistreringSkjema(oppdatertSkjema);
    };

    private opprettSak = (avsluttSakIndikator: AvsluttSakIndikator = AvsluttSakIndikator.AVSLUTT_MED_VEDTAK) => {
        this.props.confirmNavigation(false);

        const registreringSak = this.props.registreringSakRequest;
        const errors = this.getErrors();

        if (errors.length) {
            this.setValidationState(false, errors);
            return;
        }

        this.setValidationState(true, errors);

        if (registreringSak.sakId) {
            this.oppdaterOgRegistrer();
        } else {
            return this.props.opprettSak(registreringSak, avsluttSakIndikator).then(this.handterOpprettetSak);
        }
    };

    private handterOpprettetSak = (sakResponse: ISak<IRegistreringInformasjon>) => {
        if (sakResponse.sakId && sakResponse.saksstatus === Saksstatus.APEN) {
            return this.props.utledAvgiftsstatus(`${window.location.href}/${sakResponse.sakId}`).then(() => {
                const { urlTilAvgiftsportalen } = this.props.avgiftsstatus;
                const harUtestaendeAvgifter = _.some(this.props.vilkar, vilkar => vilkar.navn === Vilkarsnavn.AVGIFT && vilkar.status !== VilkarStatus.OPPFYLT);

                if (urlTilAvgiftsportalen && harUtestaendeAvgifter) {
                    window.location.replace(urlTilAvgiftsportalen);
                } else {
                    this.props.confirmNavigation(false);
                    this.props.navigate(`/kjoretoy/${sakResponse.kuid}/registrering/${sakResponse.sakId}`);
                }
                return sakResponse;
            });
        } else if (sakResponse.sakId) {
            this.oppdaterKjoretoyOmSaksstatusErAvsluttet();
            this.props.navigate(`/kjoretoy/${sakResponse.kuid}/registrering/${sakResponse.sakId}`);
        }
        return sakResponse;
    };

    private oppdaterOgRegistrer = () => {
        this.props.confirmNavigation(false);

        const errors = this.getErrors();

        if (!errors.length) {
            this.setValidationState(true, errors);
            const sak = this.props.registreringSakRequest;

            this.props.oppdaterSak(sak, AvsluttSakIndikator.AVSLUTT_MED_VEDTAK);
        } else {
            this.setValidationState(false, errors);
        }
    };

    private getErrors() {
        return this.erKjoringensArtEndret() ?
               RegistreringRegler.utledSkjemaErrorsIgnorerKjoringensArtHvisIkkeNull(this.props.registreringSakRequest, this.props.registrering.eier, this.props.kjoretoy) :
               RegistreringRegler.utledSkjemaErrorsInkluderKjoringensArt(this.props.registreringSakRequest, this.props.registrering.eier, this.props.kjoretoy);
    }

    private fattVedtak = () => {
        const { sakId } = this.props.registreringSakRequest;

        if (sakId) {
            return this.props.getSakFattVedtak(sakId).then(() => {
                this.oppdaterKjoretoyOmSaksstatusErAvsluttet();
                this.hentKjennemerkeserierOgTyper(this.props.kjoretoy.kuid, this.props.registreringSakRequest.kjoringensArt);
            });
        }
    };

    private oppdaterKjoretoyOmSaksstatusErAvsluttet = () => {
        if (this.props.sakResponse.saksstatus === Saksstatus.AVSLUTTET) {
            this.props.kjoretoyGetDataAction(this.props.kjoretoy.kuid);
        }
    };

    private finnesObligatoriskeDokumenterSomKanLastesOpp = (): boolean => {
        return _.some(this.props.vilkar, vilkar => vilkar.navn === Vilkarsnavn.DOKUMENTASJON)
            && !!RegistreringRegler.dokumenterSomKanLastesOpp(this.props.registreringSakRequest.obligatoriskeDokumenter).length;
    };

    private oppdater = () => {
        this.props.confirmNavigation(false);
        const sak = this.props.registreringSakRequest;

        if (sak.saksType === SaksType.REGISTRERING_NY_EIER) {
            this.props.oppdaterSak(sak, AvsluttSakIndikator.AVSLUTT_UTEN_VEDTAK).then(() => {
                this.props.hentDokumentstatus();
                this.props.hentVilkarstatus(this.props.registreringSakRequest);
            });
        } else {
            this.props.getSak(sak.sakId).then(() => {
                this.props.hentDokumentstatus();
                this.props.hentVilkarstatus(this.props.registreringSakRequest);
            });
        }
    };

    private handleKundeadresseErrors = (errors: (IMessageDescriptor | string)[]) => {
        if (!!errors.length) {
            this.setValidationState(false, [...this.state.validation.errors, 'registrering.validering.skjemafeil']);
        }
    };

    private handleKundeadresseSubmit = (kundeadresse: IKundeAdresse) => {
        this.setState({
            adressepanel: {
                showAdressepanel: true,
                adresseSubmitted: true
            }
        }, () => this.setValidationState(true, []));

        return this.props.oppdaterRegistreringSkjema({
            vognkortsaksinformasjon: {
                forsendelsesadresse: kundeadresse
            }
        });
    };

    private kanViseElement = (): boolean => {
        return Merknader.exists(this.props.kjoretoy.merknader, { kode: RegelmerknaderKoder.PAREGISTRERING_KAN_IKKE_STARTES });
    };

    private hentMidlertidigVognkort = () => {
        this.props.confirmNavigation(false);
        this.props.hentMidlertidigVognkort(this.props.kjoretoy.registrering.kuid);
    };

    private oppdaterBruk = (bruk: Partial<IRegistreringInformasjon>): void => {
        this.props.oppdaterRegistreringSkjema({
            ...bruk,
            kjennemerke: {...this.props.registreringSakRequest, kjennemerkeType: this.props.registreringSakRequest.kjennemerke?.kjennemerkeType, kjennemerkeserie: null},
            drosjeloyvenummer: bruk.kjoringensArt === this.props.registreringSakRequest.kjoringensArt ? this.props.registreringSakRequest.drosjeloyvenummer : null
        });

        if (!!bruk.kjoringensArt) {
            if (!this.props.registreringSakRequest.kjoringensArt) {
                const validation = this.state.validation;
                this.setValidationState(validation.gyldig, _.without(validation.errors, 'registrering.validering.manglerKjoringensArt'));
            }

            this.props.confirmNavigation(true);
            this.props.hentDokumentstatus();
        }
    };

    private getModalForLukk = () => {
        return (
            <AkModal showModal={this.state.modalInfo.showModal && (this.state.modalInfo.title === modalInformasjon.lukk.title)} title={this.state.modalInfo.title}
                bodyTextKey={this.state.modalInfo.bodyText} modalType={this.state.modalInfo.meldingModalType} closeModal={this.close}>
                <div className="ak-knapperad-modal">
                    <Link className="ak-knapp ak-hovedknapp" to={'/startside'}><FormattedMessage id="registrering.modal.bekreft" /></Link>
                    <AkKnapp type="ak-knapp ak-tilleggsknapp" action={this.close} intlKey={'registrering.modal.angre'} />
                </div>
            </AkModal>
        );
    };

    private getModalForAvbrytRegistrering = () => {
        return (
            <AkModal showModal={this.state.modalInfo.showModal && (this.state.modalInfo.title === modalInformasjon.avbryt.title)} title={this.state.modalInfo.title}
                bodyTextKey={this.state.modalInfo.bodyText} modalType={this.state.modalInfo.meldingModalType} closeModal={this.close}>
                <div className="ak-knapperad-modal">
                    <AkKnapp type="ak-knapp ak-hovedknapp" action={this.avbrytRegistrering} intlKey={'registrering.modal.bekreft'} />
                    <AkKnapp type="ak-knapp ak-tilleggsknapp" action={this.close} intlKey={'registrering.modal.angre'} />
                </div>
            </AkModal>
        );
    };

    private getMeldingForInnleverteKjennemerkerModal = () => {
        return (
            <AkModal showModal={this.state.modalInfo.showModal && (this.state.modalInfo.title === modalInformasjon.innleverteKjennemerker.title)}
                title={this.state.modalInfo.title} modalType={this.state.modalInfo.meldingModalType} closeModal={this.close}>
                <>
                    {this.meldingForInnleverteKjennemerkerTekster(this.state.modalInfo.bodyChildrenKeys)}
                    <div className="ak-knapperad-modal">
                        <AkKnapp type="ak-knapp ak-hovedknapp" action={this.close} intlKey={'registrering.modal.lukkInnleverteKjennemerker'} />
                    </div>
                </>
            </AkModal>
        );
    };

    private meldingForInnleverteKjennemerkerTekster = (meldingKeys: IMessageDescriptor[]) => {
        const messages = [] as {key: string; values?: Record<string, any>}[];

        if (meldingKeys) {
            meldingKeys.forEach((key) => {
                messages.push({ key: key.id, values: key.values });
            });
        }

        return messages.map((message) => <p className="ak-modal-tekst" key={message.key}><FormattedMessage id={message.key} values={message.values} /></p>);
    };

    private openModalForAvbrytRegistrering = () => {
        return this.setState({
            modalInfo: {
                showModal: true,
                title: modalInformasjon.avbryt.title,
                bodyText: modalInformasjon.avbryt.bodyText,
                meldingModalType: modalInformasjon.avbryt.meldingModalType
            }
        });
    };

    private openMeldingForInnleverteKjennemerkerModal = () => {
        return this.setState({
            modalInfo: {
                showModal: true,
                title: modalInformasjon.innleverteKjennemerker.title,
                bodyText: '',
                meldingModalType: modalInformasjon.innleverteKjennemerker.meldingModalType,
                bodyChildrenKeys: RegistreringRegler.utledMeldingerForKjennemerkerSomErInnlevert(this.props.kjoretoy)
            }
        });
    };

    private avbrytRegistrering = () => {
        this.props.avbrytSak(this.props.sakResponse.sakId).then(() => {
            this.props.confirmNavigation(false);
            this.props.navigate('/startside');
        });
    };

    private isLoading = (): boolean => (
        this.props.sakResponse?.isLoading || this.props.eier?.isLoading || this.props.medeier?.isLoading
    );

    private hentKunde = (kundeId: string, isEier: boolean) => {
        this.props.getKunde({ kundeId }, isEier ? AktorType.NY_EIER : AktorType.NY_MEDEIER, this.props.kjoretoy.registrering.kuid);
    };

    private close = () => {
        this.setState({ modalInfo: { ...this.state.modalInfo, showModal: false } });
    };

    private lukk = () => {
        this.props.navigate(`/startside?fane=${ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING}`);
    };

    private erOmregistrering() {
        return this.props.kanStarteOmregistreringssak
            || (this.props.sakResponse && this.props.sakResponse.saksType === SaksType.REGISTRERING_NY_EIER);
    }

    private erKjoringensArtEndret() {
        return this.props.registreringSakRequest.kjoringensArt !== this.props.sakResponse?.saksinformasjon?.kjoringensArt;
    }

    private harKundeFeilet(): boolean {
        return (!!this.props.eier && !!this.props.eier.error) || (!!this.props.medeier && !!this.props.medeier.error);
    }

    private harSkattFeilet(): boolean {
        const sak = this.props.registreringSakRequest;
        return sak && sak.avgiftsstatus && !!sak.avgiftsstatus.error;
    }

    private kanViseInformasjon(): boolean {
        return !this.harKundeFeilet() && !this.harSkattFeilet() && !this.isLoading();
    }

    private hentKjennemerkeserierOgTyper = (kuid: string, kjoringensArt: string) => {
        return this.props.hentKjennemerkeserierOgTyper(kuid, kjoringensArt);
    };

    private hentEierOgMedeierFraKjoretoyOgLastKundedata() {
        const {
            eier,
            medeier
        } = this.props;

        this.lastKundeData(eier.kundeId, medeier);
    }

    private lastKundeData(eierKundeId: string, medeier?: IKunde) {
        this.hentKunde(eierKundeId, true);

        if (medeier) {
            this.hentKunde(medeier.kundeId, false);
        }
    }

    private kanRendreKnappForAvbrytRegistrering(): boolean {
        const innloggetUnderenhetIkkeNyEier = RegistreringRegler.nyEierErInnloggetForhandlerSinHovedenhet(this.props.brukerprofil, this.props.eier)
            || RegistreringRegler.nyUnderenhetUlikInnloggetAvdeling(this.props.brukerprofil, this.props.registreringSakRequest.underenhet);

        return this.props.status === RegistreringStatus.VARSLER && !!this.props.sakId
            && (this.props.sakResponse.opprettetIKanal === Kanal.AUTOMATISK || innloggetUnderenhetIkkeNyEier);
    }

    private utledUgyldigeKjoringesArt(): KjoringensArt[] {
        return this.props.harInnfriddFjerneTraktorMedBruksbegrensningMerknad ? [KjoringensArt.TRAKTOR_MED_BRUKSBEGRENSNING] : [];
    }

    private setValidationState(valid: boolean, errors: string[]) {
        this.setState({
            validation: {
                errors,
                gyldig: valid,
                submitted: true
            }
        });
    }

    private setUgyldigAdresseState = () => {
        this.setState({
                adressepanel: {
                    showAdressepanel: true,
                    adresseSubmitted: false
                }
            }, () => this.setValidationState(!!this.props.sakResponse.saksadresseId,
            [...this.state.validation.errors, 'registrering.validering.ugyldigAdresse'])
        );
    };
}

export const erRegistreringAvsluttet = (sakResponse: ISak<IRegistreringInformasjon>): boolean => {
    return sakResponse.saksstatus === Saksstatus.AVSLUTTET;
};

export function harForsoktFatteVedtak(prevProps: Partial<IRegistreringStateProps>, props: Partial<IRegistreringStateProps>): boolean {
    return !prevProps.registreringSakRequest.avsluttSakIndikator &&
        props.registreringSakRequest.avsluttSakIndikator === AvsluttSakIndikator.AVSLUTT_MED_VEDTAK;
}

export function harPopulertNySak(prevProps: Partial<IRegistreringStateProps>, props: Partial<IRegistreringStateProps>): boolean {
    return !prevProps.registreringSakRequest.kuid && !!props.registreringSakRequest.kuid && !props.registreringSakRequest.sakId;
}

const mapStateToProps: MapStateToProps<IRegistreringStateProps, IRegistreringProps, RootStateType> = (state) => ({
    kjoretoy: kjoretoySelector(state),
    registrering: registreringSelector(state),
    registreringSakRequest: registreringSakSelector(state),
    sakResponse: sakSelector(state),
    gyldigeKjoringsensArt: gyldigeKjoringensArtSelector(state),
    alleKjoringensArt: alleKjoringensArtSelector(state),
    neringskoder: neringskoderSelector(state),
    vilkar: vilkarForRegistreringSelector(state),
    avgiftsstatus: avgiftsstatusSelector(state),
    eier: gjeldendeEierSelector(state),
    sakliste: state.sakListe,
    medeier: gjeldendeMedeierSelector(state),
    status: statusForRegistreringSelector(state),
    erKjennemerkerUtilgjengelige: erKjennemerkerUtilgjengeligeSelector(state),
    kanStarteOmregistreringssak: kanStarteOmregistreringssakSelector(state),
    kanStarteParegistreringssak: kanStarteParegistreringssakSelector(state),
    kanBehandleRegistrering: kanBehandleRegistreringSelector(state),
    erKravOmVektarsavgift: erKravOmVektarsavgiftSelector(state),
    vilkarstatus: vilkarstatusSelector(state),
    harRolleOmregOgSeriekjennemerke: harRolleOmregOgSeriekjennemerke(state),
    brukerprofil: brukerprofilSelector(state),
    kunder: kunderSelector(state),
    harInnfriddFjerneTraktorMedBruksbegrensningMerknad: harInnfriddFjerneTraktorMedBruksbegrensningMerknadSelector(state)
});

const mapDispatchToProps = (dispatch): IRegistreringDispatchProps => ({
    getKunde: (fnrOnr: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktorType?: AktorType, kuid?: string) => dispatch(getKunde(fnrOnr, aktorType, kuid)),
    avbrytSak: (sakId) => dispatch(avbrytRegistrering(sakId)),
    opprettSak: (registreringSak, avsluttSakIndikator) => dispatch(opprettRegistreringSak(registreringSak, avsluttSakIndikator)),
    oppdaterSak: (registreringSak, avsluttSakIndicator) => dispatch(oppdaterRegistreringSak(registreringSak, avsluttSakIndicator)),
    endreErTilGodkjenningStatus: registreringSak => dispatch(endreErTilGodkjenningStatus(registreringSak)),
    getSak: (sakId) => dispatch(getRegistreringSak(sakId)),
    henteDrosjeloyvenummer: (kuid: string) => (dispatch(henteDrosjeloyvenummer(kuid))),
    getSakFattVedtak: (sakId) => dispatch(getRegistreringSakFattVedtak(sakId)),
    resetSak: () => dispatch(resetSak()),
    hentMidlertidigVognkort: (kuid: string) => dispatch(hentMidlertidigVognkort(kuid)),
    kjoretoyGetDataAction: (kuid: string) => dispatch(kjoretoyGetDataFromKuidAction(kuid)),
    oppdaterRegistreringSkjema: (sak: Partial<IRegistreringSak>) => (dispatch(oppdaterRegistreringSkjema(sak))),
    populerNySak: (grunnlag) => (dispatch(populerNySak(grunnlag))),
    hentVilkarstatus: (saksinformasjon) => dispatch(kontrollerVilkar(saksinformasjon)),
    hentDokumentstatus: () => dispatch(hentDokumentasjonsstatusRegistrering()),
    utledAvgiftsstatus: (callbackUrl: string) => (dispatch(utledAvgiftsstatus(callbackUrl))),
    clearRegistreringSak: () => dispatch(registreringSakClearState()),
    hentKjennemerkeserierOgTyper: (kuid, kjoringensArt) => dispatch(hentKjennemerkeserierOgTyper(kuid, kjoringensArt)),
    hentKjoringensArt: (kuid: string, kundeId: string) => dispatch(hentKjoringensArtListe(kuid, kundeId))
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    AkConfirmNavigation,
    withRouter
);

const RegistreringConnected = enhance(Registrering);

export { RegistreringConnected };
