import { ValgtKontaktform, GodkjenningUtfortType, VALID_EMAIL_REGEX, VilkarStatus} from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import type { IAktor, IGrunnlagVektarsavgift, IKjoretoy, INyttKjennemerke, IObligatoriskDokument, IRegistreringInformasjon, ISak, ISaksdokument, IVilkarstatus } from '../models/types';
import { mapeSaksdokumenterStatusTilObligatoriskeDokumenter, utledNeringskodeErrors, utledObligatoriskeDokumenter } from '../utils';
import { ForstegangsregistreringRegler } from './forstegangsregistrering-regler';
import { TilhengervektRegler } from './tilhengervekt-regler';

export const VALID_TLF_MASKERINGS_REGEX = /^(?:\*{5}[0-9]{3}|(?:[/\s]?[0-9]){8})$/;

export class ForstegangsregistreringValidator {

    public static validerOpprettSak = (saksinformasjon: Partial<IRegistreringInformasjon>, kjoretoy: IKjoretoy): string[] => {
        const errors = [];
        const { melder, nyEier, nyMedeier, leasingtaker, underenhet } = saksinformasjon;

        errors.push(...ForstegangsregistreringValidator.validerAktorer(nyEier, nyMedeier));
        errors.push(...ForstegangsregistreringValidator.manglerEiere(nyEier, melder, nyMedeier));
        errors.push(...ForstegangsregistreringValidator.manglerLeasingtakerEllerUnderenhet(leasingtaker, underenhet));

        if (ForstegangsregistreringRegler.erKravTilVektarsavgift(kjoretoy)) {
            errors.push(...ForstegangsregistreringValidator.validerVektarsavgift(saksinformasjon.grunnlagVektarsavgift));
        }

        return _.compact(errors);
    };

    public static getErrorsOgValiderAktorer = (saksinformasjon: Partial<IRegistreringInformasjon>): string[] => {
        const errors = [];
        const { nyEier, nyMedeier } = saksinformasjon;

        errors.push(...ForstegangsregistreringValidator.validerAktorer(nyEier, nyMedeier));

        return _.compact(errors);
    };

    public static  validerDokumenterTilSaksbehandler = (sak: ISak<IRegistreringInformasjon>): string[] => {
        const errors = [];
        const obligatoriskeDokumenter = utledObligatoriskeDokumenter(sak.merknader) || [];

        if (!!obligatoriskeDokumenter.length) {
            if (!!sak.arkivdokumenter) {
                const saksdokumenter: ISaksdokument[] = _.flatMap(sak.arkivdokumenter.map(arkivdokument => arkivdokument.saksdokumenter));
                const saksdokumenterMappingTilobligatoriskeDokumenter = mapeSaksdokumenterStatusTilObligatoriskeDokumenter(saksdokumenter, obligatoriskeDokumenter);
                if (_.find(saksdokumenterMappingTilobligatoriskeDokumenter, (dokument: IObligatoriskDokument) => _.isUndefined(dokument.godkjentStatus))) {
                    errors.push('dokumenterpanel.overskrift.manglerDokumenter');
                }
            } else {
                errors.push('dokumenterpanel.overskrift.manglerDokumenter');
            }
        }
        return _.compact(errors);
    };

    public static validerFattVedtak = (sak: ISak<IRegistreringInformasjon>, vilkarstatus: IVilkarstatus, saksinformasjon: Partial<IRegistreringInformasjon>, kjoretoy: IKjoretoy): string[] => {
        const errors = [];
        const { neringskode, kjoringensArt, melder, nyEier, leasingtaker, underenhet, nyMedeier, kjennemerke } = saksinformasjon;

        errors.push(
            ...utledNeringskodeErrors(neringskode, nyEier),
            ...ForstegangsregistreringRegler.utledUgyldigKjoringensArtFeilmeldinger(kjoringensArt, kjoretoy)
        );

        errors.push(...ForstegangsregistreringValidator.manglerEiere(nyEier, melder, nyMedeier));
        errors.push(...ForstegangsregistreringValidator.manglerLeasingtakerEllerUnderenhet(leasingtaker, underenhet));

        if (ForstegangsregistreringValidator.harManglendeVilkar(sak, vilkarstatus, saksinformasjon)) {
            errors.push('sak.validering.vilkarIkkeOppfylt');
        }

        if (ForstegangsregistreringRegler.erKravTilVektarsavgift(kjoretoy)) {
            errors.push(...ForstegangsregistreringValidator.validerVektarsavgift(saksinformasjon.grunnlagVektarsavgift));
        }

        if (ForstegangsregistreringRegler.erKravTilVektarsavgift(kjoretoy)
            && TilhengervektRegler.overskriderOppgittTilhengervektTillattTilhengervekt(kjoretoy.godkjenning, saksinformasjon.grunnlagVektarsavgift.totalvektTilhenger)) {
            errors.push('forstegangsregistrering.valider.skjemaInneholderFeil');
        }

        errors.push(ForstegangsregistreringValidator.validerKjennemerke(kjennemerke));

        return _.compact(errors);
    };

    private static validerVektarsavgift = (grunnlagVektarsavgift: IGrunnlagVektarsavgift) => {
        const errors = [];

        if (grunnlagVektarsavgift
            && grunnlagVektarsavgift.skalTrekkeTilhenger
            && (!grunnlagVektarsavgift.totalvektTilhenger || !grunnlagVektarsavgift.antallAkslerTilhenger)) {
            errors.push('sak.vektarsavgift.validering.beggeFelterRequired');
        }

        return errors;
    };

    private static validerAktorer = (nyEier: IAktor, nyMedeier: IAktor) => {
        const errors = [];

        if (ForstegangsregistreringValidator.organisasjonMedEgensignaturHarIkkeValgtSignaturkunde(nyEier) ||
            ForstegangsregistreringValidator.organisasjonMedEgensignaturHarIkkeValgtSignaturkunde(nyMedeier)) {
            errors.push( 'forstegangsregistrering.valider.manglerSignaturkunde');
        }

        if (ForstegangsregistreringValidator.harUgyldigKontaktInfo(nyEier) || ForstegangsregistreringValidator.harUgyldigKontaktInfo(nyMedeier)) {
            errors.push('forstegangsregistrering.valider.skjemaInneholderFeil');
        }

        return errors;
    };

    private static harUgyldigKontaktInfo = (aktor: IAktor) => {
        return ForstegangsregistreringValidator.harUgyldigEpost(aktor) || ForstegangsregistreringValidator.harUgyldigMobil(aktor);
    };

    private static harUgyldigEpost = (aktor: IAktor) => {
        return aktor && aktor.valgtKontaktform === ValgtKontaktform.EPOST && !aktor.skalBenytteRegistrertKontaktinformasjon && !aktor.epost?.match(VALID_EMAIL_REGEX);
    };

    private static harUgyldigMobil = (aktor: IAktor) => {
        return aktor && aktor.valgtKontaktform === ValgtKontaktform.MOBIL && !aktor.skalBenytteRegistrertKontaktinformasjon && !aktor.mobiltelefon?.match(VALID_TLF_MASKERINGS_REGEX);
    };

    private static organisasjonMedEgensignaturHarIkkeValgtSignaturkunde = (aktor: IAktor) => (
        aktor?.godkjenningUtfort === GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA
        && !!aktor.organisasjonsnummer
        && _.isEmpty(aktor.signaturKundeId)
    );

    private static manglerEiere = (nyEier: IAktor, melder: IAktor, nyMedeier: IAktor) => {
        const errors = [];

        if (!melder.kundeId) {
            errors.push('forstegangsregistrering.valider.manglerMelder');
        }

        if (!nyEier.kundeId) {
            errors.push('forstegangsregistrering.valider.manglerNyEier');
        }

        if (nyMedeier && !nyMedeier.kundeId) {
            errors.push('forstegangsregistrering.valider.manglerNyMedeier');
        }

        return errors;
    };

    private static manglerLeasingtakerEllerUnderenhet = (leasingtaker: string, underenhet: string) => {
        const errors = [];

        // Tom string indikerer at panelet for leasingtaker/underenhet er åpent (mulighet til å søke på kunde),
        // men at leasingtaker/underenhet ikke er valgt
        if (leasingtaker === '') {
            errors.push('forstegangsregistrering.valider.manglerLeasingtaker');
        }

        if (underenhet === '') {
            errors.push('forstegangsregistrering.valider.manglerUnderenhet');
        }

        return errors;
    };

    private static validerKjennemerke = (kjennemerke: INyttKjennemerke) => {
        if (!(kjennemerke && kjennemerke.kjennemerkeserie && kjennemerke.kjennemerkeType)) {
            return 'sak.validering.manglerKjennemerke';
        }

        return kjennemerke.validated && 'sak.validering.ugyldigKjennemerke';
    };

    private static harManglendeVilkar = (sak: ISak<IRegistreringInformasjon>, vilkarstatus: IVilkarstatus, saksinformasjon: Partial<IRegistreringInformasjon>) =>
        _.some(ForstegangsregistreringRegler.utledVilkar(sak, vilkarstatus, saksinformasjon), v => v.status !== VilkarStatus.OPPFYLT);

}
