import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';

import { connect } from 'react-redux';
import { SYSTEMVARSLER_STORAGE_KEY } from '../../constants';
import type { MeldingType } from '../../models/kodeverk';

import type { IMelding, IMeldingBar, RootStateType } from '../../models/types';
import { fjernMelding } from '../../state/actions';
import { LocalStorage } from '../../utils/akr-local-storage';

import { MeldingBarMeldingConnected } from './melding-bar-melding';

interface IMeldingBarStateProps {
    meldingBar: IMeldingBar;
}

interface IMeldingBarDispatchProps {
    fjernMelding: (melding: IMelding) => any;
}

class MeldingBar extends Component<IMeldingBarStateProps & IMeldingBarDispatchProps> {

    public render() {

        return (
            <ul className="ak-melding-bar" id="melding-bar">
                {this.lagMeldinger(this.props.meldingBar.meldinger)}
            </ul>
        );
    }

    private lagMeldinger = (meldinger: IMelding[]) => {
        const kryssedeMeldinger = LocalStorage.getArrayItem(SYSTEMVARSLER_STORAGE_KEY);
        return _.chain(meldinger).filter((melding) =>
            !_.includes(kryssedeMeldinger, melding.meldingId))
            .map(
                (melding) => (
                    <MeldingBarMeldingConnected
                        key={melding.meldingId}
                        melding={melding}
                        fjernAlleMeldingerAvType={this.fjernAlleMeldingerAvType}
                        fjern={this.props.fjernMelding}
                    />
                )
            ).value();
    };

    private fjernAlleMeldingerAvType = (meldingType: MeldingType) => {
        _.chain(this.props.meldingBar.meldinger)
            .filter((melding) =>
                melding.meldingType === meldingType
            ).map((melding) =>
            this.props.fjernMelding(melding)
        ).value();
    };
}

const mapDispatchToProps = dispatch => ({
    fjernMelding: (melding: IMelding) => dispatch(fjernMelding(melding))
});

const mapStateToProps = (state: RootStateType) => ({
    meldingBar: state.meldingBar || {} as IMeldingBar
});

const MeldingBarConnected = connect(mapStateToProps, mapDispatchToProps)(MeldingBar);

export { MeldingBarConnected };
