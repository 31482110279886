export enum GodkjenningsType {
    TEKNISK = 'TEKNISK',
    ADR = 'ADR',
    LAREVOGN = 'LAREVOGN',
    UTRYKNING = 'UTRYKNING',
    DYRETRANSPORT = 'DYRETRANSPORT',
    LOYVEFUNKSJONSHEMMEDE = 'LOYVEFUNKSJONSHEMMEDE'
}

export enum StatusInnlevertUtenlandskKjennemerke {
    INNLEVERT = 'INNLEVERT',
    IKKE_INNLEVERT = 'IKKE_INNLEVERT',
    IKKE_KRAV_TIL_INNLEVERING = 'IKKE_KRAV_TIL_INNLEVERING'
}

export enum StatusInnlevertUtenlandskVognkort {
    IKKE_ANGITT = 'IKKE_ANGITT',
    INNLEVERT = 'INNLEVERT',
    IKKE_INNLEVERT = 'IKKE_INNLEVERT',
    DISPENSASJON = 'DISPENSASJON',
    OK_MYNDIGHET_UTLAND = 'OK_MYNDIGHET_UTLAND',
    IKKE_TIDLIGERE_REG = 'IKKE_TIDLIGERE_REG',
    IKKE_UTSTEDT = 'IKKE_UTSTEDT'
}

export enum GodkjenningTekniskKode {
    BELTEMOTORSYKKEL = 'BM'
}

export enum Godkjenningsundertype {
    ETTERREGISTRERING = 'ETTERREGISTRERING'
}
