import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import moment from 'moment';
import type { ReactNode } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AkKnapp, AkMeldingboks, AkMeldingboksType, AktorGodkjenningstatus, AktorType } from 'svv-tk-akr-common-frontend';

import { AvsluttetAvbruttArsak, EierskifteStatus, RegelmerknaderKoder, RegistreringsstatusKode, SaksType } from '../../models/kodeverk';
import { Varseltype } from '../../models/kodeverk/varseltype';
import type { Brukerprofil, IAktor, IApenSak, IEierskifte, IKjoretoy, IKlientKonfigurasjonState, IMerknad, ISak, ISaksinformasjon, RootStateType } from '../../models/types';
import { BrukerRegler, EierskifteRegler, RegistreringRegler } from '../../regler';
import { fjernSak } from '../../state/actions';
import { kjoretoySelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { STANDARD_DATO_FORMAT, STANDARD_KLOKKESLETT_FORMAT, withRouter } from '../../utils';
import { PurreAktorModalConnected } from '../startside';

interface IEierskifteStatusMeldingerProps {
    opprettNyttEierskifte: () => void;
    endreEierskifte: (eierskifte: IEierskifte) => void;
    openModalForAvbryt: () => void;
    eierskifte?: IEierskifte;
    openModalForGodkjennEierskifte?: (godkjennEierskifte: () => void) => void;
    erForhandlerEier: boolean;
}

interface IEierskifteStatusMeldingerStateProps {
    kjoretoy?: IKjoretoy;
    brukerprofil?: Brukerprofil;
    registreringsstatus?: RegistreringsstatusKode;
    klientKonfigurasjon: IKlientKonfigurasjonState;
    merknader: IMerknad[];
    apneSaker?: IApenSak[];
    harRolleOmreg: boolean;
    harRolleEierskifte: boolean;
    harRolleFlateeier: boolean;
}

interface IEierskifteStatusMeldingerDispatchProps {
    fjernEierskifte: (sak: ISak<ISaksinformasjon>) => Promise<any>;
}

interface IEierskifteStatusMeldingerState {
    showPurringModal: boolean;
    purringSakId: string;

}

class EierskifteStatusMeldinger extends React.Component<IEierskifteStatusMeldingerProps & IEierskifteStatusMeldingerStateProps & IEierskifteStatusMeldingerDispatchProps & WithRouterProps> {

    public state: IEierskifteStatusMeldingerState = {
        showPurringModal: false,
        purringSakId: null
    };

    public render(): React.ReactNode {
        switch (this.props.eierskifte.saksstatus) {
            case EierskifteStatus.APEN:
                return this.renderApentEierskifte(this.props.eierskifte);
            case EierskifteStatus.AVBRUTT:
                return this.renderAvbruttEierskifte(this.props.eierskifte);
            case EierskifteStatus.AVSLUTTET:
                return this.renderAvsluttetEierskifte();
            default:
                return null;
        }
    }

    private renderApentEierskifte = (eierskifte: IEierskifte) => {
        const forhandler = _.find(eierskifte.aktorer, (aktor) =>
            aktor.kundeId === this.props.brukerprofil.valgtEnhet.hovedenhetKundeId || aktor.kundeId === this.props.brukerprofil.valgtEnhet.underenhetKundeId
        );

        if (!forhandler || !forhandler.godkjenningsstatus) {
            const { aktorer } = eierskifte;
            const initiator = (
                _.find(aktorer, (aktor) => aktor.eierskifteInitiertAv) ||
                _.find(aktorer, (aktor) => aktor.aktorType === (this.props.erForhandlerEier ? AktorType.NY_EIER : AktorType.EIER))
            );

            return (
                <AkMeldingboks meldingType={AkMeldingboksType.INFO}>
                    <div className="row">
                        <div className="col">
                            <h3 className="ak-melding-overskrift">
                                <FormattedMessage id={'eierskifte.melding.sendtTilGodkjenning.overskrift'}
                                    values={{
                                        dato: moment(eierskifte.opprettetDato).format(STANDARD_DATO_FORMAT),
                                        tid: moment(eierskifte.opprettetDato).format(STANDARD_KLOKKESLETT_FORMAT)
                                    }} />
                            </h3>
                            <p className="ak-melding-tekst">
                                <FormattedMessage id={'eierskifte.melding.sendtTilGodkjenning.linje1'} values={{ forhandler: initiator ? initiator.navn : '' }} />
                            </p>
                            <p className="ak-melding-tekst">
                                <FormattedMessage id={'eierskifte.melding.sendtTilGodkjenning.linje2'} />
                            </p>
                        </div>
                        {this.renderTilGodkjenningKnapper()}
                    </div>
                </AkMeldingboks>
            );
        } else {
            return (
                <AkMeldingboks meldingType={AkMeldingboksType.INFO}>
                    <div className="row">
                        <div className="col-8">
                            <h3 className="ak-melding-overskrift">
                                <FormattedMessage id={'eierskifte.melding.sendtTilGodkjenning.overskrift'}
                                    values={{
                                        dato: moment(eierskifte.opprettetDato).format(STANDARD_DATO_FORMAT),
                                        tid: moment(eierskifte.opprettetDato).format(STANDARD_KLOKKESLETT_FORMAT)
                                    }} />
                            </h3>
                            <p className="ak-melding-tekst">
                                <FormattedMessage id={'eierskifte.melding.sendtTilGodkjenning.tekst'} />
                            </p>
                        </div>
                        {this.renderAvbrytOgPaminnelseKnapp(eierskifte.sakId)}
                        <PurreAktorModalConnected showModal={this.state.showPurringModal} closeModal={this.closeModalForPurring} sakId={this.state.purringSakId}
                            varseltype={Varseltype.PURRING_EIERSKIFTE} />
                    </div>
                </AkMeldingboks>
            );
        }
    };
    private openModalForPurring = (sakId: string) => {
        this.setState({
            showPurringModal: true,
            purringSakId: sakId
        });
    };

    private closeModalForPurring = () => {
        this.setState({
            showPurringModal: false,
            purringSakId: null
        });
    };

    private renderAvbruttEierskifte = (eierskifte: IEierskifte) => {

        let aktorerText: React.JSX.Element;

        const avbruttAktor = _.find(
            eierskifte.aktorer,
            (a: IAktor) => a.godkjenningsstatus === AktorGodkjenningstatus.AVVIST
        );

        if (!avbruttAktor && eierskifte.saksstatus === EierskifteStatus.AVBRUTT) {
            const venterAktorer = _.filter(
                eierskifte.aktorer,
                (a: IAktor) => !a.godkjenningsstatus && !_.includes([AktorType.NY_UNDERENHET, AktorType.UNDERENHET], a.aktorType)
            );

            aktorerText = (
                <span>
                    {venterAktorer.length
                     ? _.chain(venterAktorer).map((aktor: IAktor) => aktor.navn).join(', ').replace(/,(?!.*,)/gmi, ' og').value()
                     : 'Alle aktører'
                    }
                </span>
            );

        } else {
            aktorerText = <span>{avbruttAktor ? avbruttAktor.navn : 'Ukjent'}</span>;
        }

        return (
            <AkMeldingboks meldingType={AkMeldingboksType.ABORT}>
                <div className="row">
                    <div className="col-8">
                        <h3 className="ak-melding-overskrift">
                            {this.isEierskifteAvbruttBrutteVilkar(eierskifte)
                             ? <FormattedMessage id={'eierskifte.melding.avbrutt.overskrift'} />
                             : <FormattedMessage id={'eierskifte.melding.avvist.overskrift'} />
                            }
                        </h3>
                        <p className="ak-melding-tekst mb-0">
                            <FormattedMessage id={
                                this.isEierskifteAvvistAvAktor(eierskifte) ? 'eierskifte.melding.avvist.avvistAvAktor'
                                                                           : this.isEierskifteAvbruttBrutteVilkar(eierskifte) ? 'eierskifte.melding.avbrutt.avbruttBrutteVilkar'
                                                                                                                              : 'eierskifte.melding.avvist.avvistAvSystem'
                            } values={{ aktorer: aktorerText, kjennemerke: eierskifte.kjennemerke }} />
                        </p>
                    </div>
                    {this.renderAvvistKnapper()}
                </div>
            </AkMeldingboks>
        );
    };

    private renderAvsluttetEierskifte = () => {
        const eierskifte = this.props.eierskifte;
        const avvistAktor = _.find(
            eierskifte.aktorer,
            (a: IAktor) => a.godkjenningsstatus === AktorGodkjenningstatus.AVVIST
        );
        const aktorerText = <span>{avvistAktor ? avvistAktor.navn : 'Ukjent'}</span>;

        return (
            !avvistAktor
            ? (
                <AkMeldingboks meldingType={AkMeldingboksType.INFO}>
                    <div className="row">
                        <div className="col-7">
                            {this.isForhandlerNyEier()
                             ? <h3 className="ak-melding-overskrift">
                                 <FormattedMessage id={'eierskifte.melding.godkjent.tidligereEier'} />
                             </h3>
                             : <h3 className="ak-melding-overskrift">
                                 <FormattedMessage id={'eierskifte.melding.godkjent.nyEier'} />
                             </h3>
                            }
                            <p className="ak-melding-tekst">
                                <FormattedMessage id={'eierskifte.melding.godkjent.vedtak'} />
                            </p>
                            {this.isForhandlerNyEier() && this.isKjoretoyAvregistrert() &&
                                <p className="ak-melding-tekst">
                                    <FormattedMessage id={'eierskifte.melding.sendtTilGodkjenning.opprettetAvregistrerMidlertidig'} />
                                </p>
                            }
                        </div>
                        {this.renderBesvartKnapper()}
                    </div>
                </AkMeldingboks>
            )
            : (
                <AkMeldingboks meldingType={AkMeldingboksType.ABORT}>
                    <div className="row">
                        <div className="col-8">
                            <h3 className="ak-melding-overskrift">
                                <FormattedMessage id={'eierskifte.melding.avvist.overskrift'} />
                            </h3>
                            <p className="ak-melding-tekst mb-0">
                                <FormattedMessage id={'eierskifte.melding.avvist.avvistAvAktor'} values={{ aktorer: aktorerText }} />
                            </p>
                        </div>
                        {this.renderAvvistKnapper()}
                    </div>
                </AkMeldingboks>
            )
        );
    };

    private makeKnapperad = (children: ReactNode[] | ReactNode) => (
        <div className="ak-knapperad col mb-0 justify-content-end row align-items-center">
            {children}
        </div>
    );

    private renderBesvartKnapper = () => {
        return this.props.harRolleOmreg && this.makeKnapperad([
            (RegistreringRegler.kanStarteOmregistreringssak(this.props.kjoretoy, this.props.merknader) && (
                <AkKnapp key="omreg" type="ak-knapp ak-hovedknapp" action={this.tilOmregistrering} customClassName="mb-2 mb-lg-0"
                    intlKey="eierskifte.knapp.besvart.fortsettTilOmregistrering"
                    disabled={this.isNyEierUverifisert()} />
            )),
            <AkKnapp key="avslutt" type="ak-tilleggsknapp" action={this.avslutt} intlKey="eierskifte.knapp.besvart.avsluttUtenOmregistrering" />
        ]);
    };

    private renderTilGodkjenningKnapper = () => (this.makeKnapperad([
        <AkKnapp key="godkjenn" type="ak-suksessknapp" icon="fa-check" action={this.godkjennEierskifte} intlKey="generell.knapp.godkjenn" customClassName="mb-2 mb-lg-0" />,
        <AkKnapp key="avvis" type="ak-avvisningsknapp" icon="fa-times" action={this.avvis} intlKey="generell.knapp.avvis" />
    ]));

    private renderAvvistKnapper = () => (this.props.harRolleEierskifte && this.makeKnapperad([
        <AkKnapp key="startNyttKnapp" type="ak-knapp ak-hovedknapp" action={this.startNyttEierskifte} intlKey="eierskifte.melding.avvist.startNyttKnapp" customClassName="mb-2 mb-lg-0" />,
        <AkKnapp key="avslutt" type="ak-tilleggsknapp" action={this.avslutt} intlKey="generell.knapp.avslutt" />
    ]));

    private renderAvbrytOgPaminnelseKnapp = (sakId) => {
        return this.skalViseAvbrytKnapp() && this.makeKnapperad([
            <AkKnapp key="avbryt" type={'ak-tilleggsknapp'} action={this.props.openModalForAvbryt} intlKey={'eierskifte.knapp.slettEierskifte'} />,
            <AkKnapp key="paminnelse" customClassName={'ak-melding-overskrift'} intlKey={'sak.valg.PURRE'} action={() => this.openModalForPurring(sakId)} />
        ]);
    };

    private skalViseAvbrytKnapp = (): boolean => {
        return !EierskifteRegler.harAlleAktorerGodkjent(this.props.eierskifte.aktorer) && (this.props.harRolleEierskifte || this.props.harRolleFlateeier);
    };

    private godkjennEierskifte = () => {
        const { registreringsstatus } = this.props;
        if (!this.props.harRolleFlateeier && registreringsstatus && registreringsstatus === RegistreringsstatusKode.REGISTRERT) {
            this.props.openModalForGodkjennEierskifte(this.godkjenn);
        } else {
            this.godkjenn();
        }
    };

    private godkjenn = () => {
        const eierskifte = _.cloneDeep(this.props.eierskifte);
        _.find(eierskifte.aktorer, this.aktorErHovedenhetEllerUnderenhet).godkjenningsstatus = AktorGodkjenningstatus.GODKJENT;

        this.props.endreEierskifte(eierskifte);
    };

    private avvis = () => {
        const eierskifte = _.cloneDeep(this.props.eierskifte);

        _.find(eierskifte.aktorer, this.aktorErHovedenhetEllerUnderenhet).godkjenningsstatus = AktorGodkjenningstatus.AVVIST;

        this.props.endreEierskifte(eierskifte);
    };

    private aktorErHovedenhetEllerUnderenhet = (kunde: IAktor): boolean => {
        return kunde.kundeId === this.props.brukerprofil.valgtEnhet.hovedenhetKundeId || kunde.kundeId === this.props.brukerprofil.valgtEnhet.underenhetKundeId;
    };

    private avslutt = () => {
        this.props.fjernEierskifte({ sakId: this.props.eierskifte.sakId, saksType: SaksType.EIERSKIFTE }).then(() => this.props.navigate('/startside'));
    };

    private startNyttEierskifte = () => {
        this.props.opprettNyttEierskifte();
        this.props.navigate(`/kjoretoy/${this.props.eierskifte.kuid}/eierskifte/`);
    };

    private tilOmregistrering = () => {
        const apenRegistreringssak = _.find(this.props.apneSaker, sak => sak.saksType === SaksType.REGISTRERING_NY_EIER.valueOf());

        if (apenRegistreringssak) {
            this.props.navigate(`/kjoretoy/${this.props.eierskifte.kuid}/registrering/${apenRegistreringssak.sakId}`);
        } else {
            this.props.navigate(`/kjoretoy/${this.props.eierskifte.kuid}/registrering/`);
        }
    };

    private isForhandlerNyEier = (): boolean => {
        const orgnummer = this.props.brukerprofil.valgtEnhet.hovedenhetOrgNummer;
        return !!_.find(this.props.eierskifte.aktorer, (aktor) => aktor.aktorType === AktorType.NY_EIER && aktor.organisasjonsnummer === orgnummer);
    };

    private isKjoretoyAvregistrert = (): boolean => {
        return this.props.registreringsstatus === RegistreringsstatusKode.AVREGISTRERT;
    };

    /**
     * Hvis eierskiftet er avbrutt av aktør vil godkjenningstatus ha value AVVIST.Hvis det er avbrutt av batch vil godkjenningstatus ikke endre
     *
     * @param {IEierskifte} eierskifte
     * @returns {boolean} true hvis eierskiftet er avbrutt av aktør
     */
    private isEierskifteAvvistAvAktor = (eierskifte: IEierskifte): boolean => {
        return eierskifte.saksstatus === EierskifteStatus.AVBRUTT && _.some(eierskifte.aktorer, (aktor: IAktor) => {
            return this.hasAktorAbortedEierskifte(aktor);
        });
    };

    private hasAktorAbortedEierskifte = (aktor: IAktor): boolean => {
        const aktorTyper = [AktorType.NY_UNDERENHET, AktorType.UNDERENHET];
        return !_.includes(aktorTyper, aktor.aktorType) && aktor.godkjenningsstatus && aktor.godkjenningsstatus !== AktorGodkjenningstatus.GODKJENT;
    };

    private isEierskifteAvbruttBrutteVilkar = (eierskifte: IEierskifte): boolean => {
        return eierskifte.avsluttetAvbruttArsak
            && (eierskifte.avsluttetAvbruttArsak === AvsluttetAvbruttArsak.VILKAR_IKKE_TILFREDSSTILT
                || eierskifte.avsluttetAvbruttArsak === AvsluttetAvbruttArsak.AVVIST_AV_AKTOR);
    };

    private isNyEierUverifisert = (): boolean => {
        return this.props.merknader.filter(merknad => {
            return merknad.kode === RegelmerknaderKoder.UVERIFISERT_KUNDE.valueOf();
        }).length > 0;
    };
}

const mapStateToProps = (state: RootStateType): IEierskifteStatusMeldingerStateProps => ({
    brukerprofil: state.brukerprofil || {} as Brukerprofil,
    kjoretoy: kjoretoySelector(state),
    registreringsstatus: state.kjoretoy.registrering.registreringsinformasjon.registreringsstatus,
    klientKonfigurasjon: state.globals.klientKonfigurasjon,
    merknader: state.kjoretoy.merknader,
    apneSaker: state.kjoretoy.apneSaker || [],
    harRolleOmreg: BrukerRegler.adapter(state).harRolleOmreg(),
    harRolleEierskifte: BrukerRegler.adapter(state).harRolleEierskifte(),
    harRolleFlateeier: BrukerRegler.adapter(state).harRolleFlateeier()
});

const mapDispatchToProps = (dispatch): IEierskifteStatusMeldingerDispatchProps => ({
    fjernEierskifte: (sak: ISak<ISaksinformasjon>) => dispatch(fjernSak(sak))
});

const EierskifteStatusMeldingerConnected = compose(
    connect<IEierskifteStatusMeldingerStateProps, IEierskifteStatusMeldingerDispatchProps, IEierskifteStatusMeldingerProps>(mapStateToProps, mapDispatchToProps),
    withRouter
)(EierskifteStatusMeldinger);

export { EierskifteStatusMeldingerConnected, EierskifteStatusMeldinger };
