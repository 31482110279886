import type { IntlShape } from 'react-intl';
import { type IKunderMap, SaksdokumentType, VilkarStatus, VilkarstatusSaksdokument } from 'svv-tk-akr-common-frontend';
import type { IObligatoriskDokument, ISaksdokument } from '../../../models/types';

interface Status {
    status: VilkarStatus,
    forklaring: string
}

interface IIntlKey {
    id: string,
    values: {
        navn: string
    }
}

const getIntlKeyValueForDokument = (doc: Partial<IObligatoriskDokument & ISaksdokument>, forVilkar: boolean, kunder: IKunderMap, intl: IntlShape): IIntlKey => {
    const { aktor, beskrivelse } = doc;
    const id = hentDokumentId(doc, forVilkar);

    if (!aktor) {
        return { id, values: { navn: (beskrivelse || intl.formatMessage({ id: 'dokumenterpanel.kropp.ukjent' })) } };
    }

    return { id, values: { navn: kunder[aktor.kundeId]?.navn ?? '' } };
};

const hentDokumentId = (doc: Partial<IObligatoriskDokument & ISaksdokument>, forVilkar: boolean) => {
    if (!forVilkar || doc.saksdokumentType !== SaksdokumentType.ANNET.valueOf()) {
        return `kodeverk.saksdokumentType.${doc.saksdokumentType}`;
    }
    return `kodeverk.saksdokumentType.ANNET_MERKNAD.${doc.gjelderForMerknadskode}`;
};

const utledVilkarStatus = (oblDok: IObligatoriskDokument, saksdokumenter: ISaksdokument[]): Status => {
    const vilkarsstatusSaksdokument = saksdokumenter.find(dok => erDokumenterLike(dok, oblDok))?.godkjentStatus;
    const godkjentStatus = vilkarsstatusSaksdokument ? vilkarsstatusSaksdokument : VilkarstatusSaksdokument.MANGLER;
    switch (godkjentStatus) {
        case VilkarstatusSaksdokument.TIL_VURDERING:
            return { status: VilkarStatus.MA_GODKJENNES, forklaring: 'dokumenterpanel.vilkar.maGodkjennes' };
        case VilkarstatusSaksdokument.GODKJENT:
            return { status: VilkarStatus.OPPFYLT, forklaring: 'dokumenterpanel.vilkar.ok' };
        case VilkarstatusSaksdokument.AVVIST:
            return { status: VilkarStatus.IKKE_OPPFYLT, forklaring: 'dokumenterpanel.vilkar.avvist' };
        default:
            return { status: VilkarStatus.IKKE_OPPFYLT, forklaring: 'dokumenterpanel.vilkar.mangler' };
    }
};

const erDokumenterLike = (doc1: ISaksdokument, doc2: ISaksdokument): boolean => {
    const erAktorerEqual = doc1?.aktor && doc2?.aktor ? doc1.aktor.kundeId === doc2.aktor.kundeId : true;
    return doc1?.saksdokumentType === doc2?.saksdokumentType && erAktorerEqual;
};

export {
    getIntlKeyValueForDokument, utledVilkarStatus, erDokumenterLike
};