import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import type { IAkConfirmNavigationProps } from 'svv-tk-akr-common-frontend';
import { AkConfirmNavigation, AkKnapp, GodkjenningUtfortType } from 'svv-tk-akr-common-frontend';

import { AvbrytSakModal } from '../../components';
import { FullforRegistreringModal } from '../../components/modal/fullfor-registrering-modal';
import { FremdriftsindikatorStatus } from '../../constants';
import { AvsluttSakIndikator, Saksstatus, SaksType } from '../../models/kodeverk';
import type { Brukerprofil, IKjoretoy, IRegistreringInformasjon, ISak, RootStateType } from '../../models/types';
import { BrukerRegler, ForstegangsregistreringRegler } from '../../regler';
import { kanStarteOgBehandleForstegangsregistreringSelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { utledFremdriftsstatus, withRouter } from '../../utils';

interface IForstegangsregistreringKnapperadStateProps {
    kuid: string;
    sakId: string;
    sak: ISak<IRegistreringInformasjon>;
    kjoretoy: IKjoretoy;
    kanStarteOgBehandleForstegangsregistrering: boolean;
    erTilGodkjenning: boolean;
    isFlateeier: boolean;
    gyldigeForhandlereSvalbard?: string[];
    brukerprofil: Brukerprofil;
}

interface IForstegangsregistreringKnapperadProps {
    oppdaterError: (error: string[]) => void;
    endreSak: (avsluttSakIndikator: AvsluttSakIndikator) => void;
    lagreSak: () => void;
    saksinformasjon: Partial<IRegistreringInformasjon>;
    loading: boolean;
    obligatoriskDokumenterKrav?: boolean;
    avbrytSak: () => void;
    submitted?: boolean;
    alleDokumenterErTilVurdering?: boolean;
}

interface IForstegangsregistreringKnapperadState {
    showFullforRegistreringModal: boolean;
    showAvbrytModal: boolean;
    lagreLoading: boolean;
    fremdriftsstatus: FremdriftsindikatorStatus;
}

type ForstegangsregistreringKnapperadPropsType = IForstegangsregistreringKnapperadStateProps
    & IForstegangsregistreringKnapperadProps
    & IAkConfirmNavigationProps
    & WithRouterProps;

const MARGIN_TOP = 'mt-3';

class ForstegangsregistreringKnapperad extends Component<ForstegangsregistreringKnapperadPropsType, IForstegangsregistreringKnapperadState> {

    public static getDerivedStateFromProps(nextProps: ForstegangsregistreringKnapperadPropsType, prevState: IForstegangsregistreringKnapperadState): Partial<IForstegangsregistreringKnapperadState> {
        return { lagreLoading: prevState.lagreLoading && nextProps.loading };
    }

    public state = {
        showAvbrytModal: false,
        showFullforRegistreringModal: false,
        lagreLoading: false,
        fremdriftsstatus: FremdriftsindikatorStatus.UNDER_OPPRETTING
    };

    public componentDidMount(): void {
        this.setState({ fremdriftsstatus: utledFremdriftsstatus(this.props.sak) });
    }

    public componentDidUpdate(prevProps: ForstegangsregistreringKnapperadPropsType): void {
        if (!_.isEqual(prevProps.sak, this.props.sak)) {
            this.setState({ fremdriftsstatus: utledFremdriftsstatus(this.props.sak) });
        }
    }

    public render(): React.ReactElement {
        const erFullfort = this.state.fremdriftsstatus === (FremdriftsindikatorStatus.REGISTRERT || FremdriftsindikatorStatus.AVBRUTT);
        const ikkeOpprettet = this.state.fremdriftsstatus === FremdriftsindikatorStatus.UNDER_OPPRETTING;
        const showFullfortModal = this.isBruktimport();
        return (
            <>
                <AkKnapp type={erFullfort ? 'ak-hovedknapp' : 'ak-tilleggsknapp'}
                    intlKey={ikkeOpprettet ? 'generell.knapp.avbrytRegistrering' : 'generell.knapp.lukk'}
                    intlKeyTooltip={ikkeOpprettet ? 'generell.knapp.avbryt_toolTip' : 'generell.knapp.lukk_toolTip'}
                    action={this.lukkForstegangsregistrering}
                    disabled={this.props.loading}
                    customClassName={MARGIN_TOP} />
                {this.renderKnapperForSak()}
                {showFullfortModal &&
                    <FullforRegistreringModal
                        title="forstegangsregistrering.modal.fullforRegistrering.tittel"
                        bodyTextKey="forstegangsregistrering.modal.fullforRegistrering.beskrivelse"
                        showModal={this.state.showFullforRegistreringModal}
                        fullfor={this.fullfor}
                        close={this.close}
                    />}
                <AvbrytSakModal isLoading={this.props.loading}
                    title="forstegangsregistrering.modal.avbrytSak.tittel"
                    bodyTextKey="forstegangsregistrering.modal.avbrytSak.beskrivelse"
                    showModal={this.state.showAvbrytModal}
                    avbryt={this.props.avbrytSak}
                    close={this.close} />
            </>
        );
    }

    private renderKnapperForSak = () => {
        const { isFlateeier, obligatoriskDokumenterKrav, erTilGodkjenning, sak } = this.props;

        if (sak.saksstatus === Saksstatus.AVBRUTT || (isFlateeier && !sak.saksstatus)) {
            return null;
        }

        const kanFullfore = this.state.fremdriftsstatus === FremdriftsindikatorStatus.GODKJENT && !isFlateeier;
        const maFullforesPaTrafikkstasjon = !_.isEmpty(ForstegangsregistreringRegler
            .utledKritiskeMeldingerMaFullforesPaTrafikkstasjon(this.props.saksinformasjon, this.props.gyldigeForhandlereSvalbard, this.props.brukerprofil, this.props.kjoretoy));
        const visOpprettKnapp = this.state.fremdriftsstatus === FremdriftsindikatorStatus.UNDER_OPPRETTING && !isFlateeier;
        const visAvbrytKnapp = this.state.fremdriftsstatus !== FremdriftsindikatorStatus.REGISTRERT && !isFlateeier;
        const visLagreKnapp = this.state.fremdriftsstatus !== FremdriftsindikatorStatus.REGISTRERT && (!erTilGodkjenning || this.props.isFlateeier);
        const visFullforKnapp = kanFullfore && !obligatoriskDokumenterKrav;
        const visSendTilSaksbehandlerKnapp = obligatoriskDokumenterKrav && !isFlateeier && !erTilGodkjenning;
        const visTrekkTilbakeKnapp = !!erTilGodkjenning && !!this.props.alleDokumenterErTilVurdering;

        const showFullfortModal = this.isBruktimport();

        if (visOpprettKnapp) {
            return (
                <AkKnapp type="ak-knapp ak-hovedknapp"
                    intlKeyTooltip="generell.knapp.sendTilGodkjenning_toolTip"
                    intlKey="generell.knapp.sendTilGodkjenning"
                    action={this.opprett}
                    loading={this.props.loading}
                    disabled={this.props.loading || !this.props.kanStarteOgBehandleForstegangsregistrering || this.props.submitted || this.erSignaturPaSamtykkeSkjemaForNyEierOgMedeier()}
                    customClassName={MARGIN_TOP} />
            );
        }

        return (
            <>
                {visAvbrytKnapp &&
                    <AkKnapp type="ak-tilleggsknapp"
                        intlKey="generell.knapp.avbrytRegistrering"
                        intlKeyTooltip="generell.knapp.avbryt_toolTip"
                        action={this.openModalForAvbryt}
                        disabled={this.props.loading}
                        customClassName={MARGIN_TOP} />
                }
                {visLagreKnapp &&
                    <AkKnapp type={`${(kanFullfore || obligatoriskDokumenterKrav) && !isFlateeier ? 'ak-tilleggsknapp' : 'ak-hovedknapp'}`}
                        intlKeyTooltip="generell.knapp.lagre_toolTip"
                        intlKey="generell.knapp.lagre"
                        action={this.lagre}
                        disabled={this.props.loading || this.erSignaturPaSamtykkeSkjemaForNyEierOgMedeier()}
                        loading={this.state.lagreLoading && this.props.loading}
                        customClassName={MARGIN_TOP} />
                }
                {visFullforKnapp && !erTilGodkjenning &&
                    <AkKnapp type="ak-hovedknapp"
                        intlKey="generell.knapp.fullforForstegangsregistrering"
                        action={showFullfortModal ? this.openModalForFullforRegistrering : this.fullfor}
                        disabled={this.props.loading || maFullforesPaTrafikkstasjon}
                        loading={!this.state.lagreLoading && this.props.loading}
                        customClassName={MARGIN_TOP} />
                }
                {visSendTilSaksbehandlerKnapp && !erTilGodkjenning &&
                    <AkKnapp type="ak-hovedknapp"
                        intlKey={'generell.knapp.sendTilSaksbehandler'}
                        action={this.sendeTilSaksbehandler}
                        disabled={this.props.loading}
                        loading={!this.state.lagreLoading && this.props.loading}
                        customClassName={MARGIN_TOP} />
                }
                {!!visTrekkTilbakeKnapp &&
                    <AkKnapp type="ak-hovedknapp"
                        intlKey={'generell.knapp.trekkTilbake'}
                        action={this.sendeTilSaksbehandler}
                        disabled={this.props.loading}
                        loading={!this.state.lagreLoading && this.props.loading}
                        customClassName={MARGIN_TOP} />
                }
            </>
        );
    };

    private erSignaturPaSamtykkeSkjemaForNyEierOgMedeier = () => {
        return this.props.saksinformasjon.nyEier?.godkjenningUtfort == GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA
            && this.props.saksinformasjon.nyMedeier?.godkjenningUtfort == GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA;
    };

    private isBruktimport = (): boolean => {
        return !_.isEmpty(this.props?.kjoretoy?.godkjenning?.forstegangsgodkjenning?.bruktimport);
    };

    private lagre = () => {
        this.props.confirmNavigation(false);
        this.setState({ lagreLoading: true });
        this.props.lagreSak();
    };

    private opprett = () => {
        this.props.endreSak(AvsluttSakIndikator.IKKE_AVSLUTT);
    };

    private sendeTilSaksbehandler = () => {
        this.props.endreSak(AvsluttSakIndikator.AVSLUTT_UTEN_VEDTAK);
    };

    private fullfor = () => {
        this.setState({ showFullforRegistreringModal: false });
        this.props.endreSak(AvsluttSakIndikator.AVSLUTT_MED_VEDTAK);
    };

    private lukkForstegangsregistrering = () => {
        if (this.state.fremdriftsstatus === FremdriftsindikatorStatus.REGISTRERT || this.props.isFlateeier) {
            this.props.navigate(`/startside?fane=${SaksType.FORSTEGANGSREGISTRERING}`);
        } else {
            this.props.navigate(`/kjoretoy/${this.props.kuid}`);
        }
    };

    private openModalForAvbryt = () => {
        this.setState({
            showAvbrytModal: true
        });
    };
    private openModalForFullforRegistrering = () => {
        this.setState({
            showFullforRegistreringModal: true
        });
    };

    private close = () => {
        this.props.confirmNavigation(false);
        this.setState({ showFullforRegistreringModal: false, showAvbrytModal: false });
    };
}

const mapStateToProps = (state: RootStateType): IForstegangsregistreringKnapperadStateProps => ({
    kuid: state.kjoretoy.kuid,
    sakId: state.sak.sakId,
    sak: state.sak,
    kjoretoy: state.kjoretoy,
    erTilGodkjenning: state.sak.erTilGodkjenning,
    kanStarteOgBehandleForstegangsregistrering: kanStarteOgBehandleForstegangsregistreringSelector(state),
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    gyldigeForhandlereSvalbard: state.globals.klientKonfigurasjon.orgNrForhandlereSomKanHandtereSvalbard,
    brukerprofil: state.brukerprofil
});

const ForstegangsregistreringKnapperadConnected = compose(
    connect<IForstegangsregistreringKnapperadStateProps, undefined, IForstegangsregistreringKnapperadProps>(mapStateToProps),
    AkConfirmNavigation,
    withRouter
)(ForstegangsregistreringKnapperad);

export type { ForstegangsregistreringKnapperadPropsType };
export { ForstegangsregistreringKnapperadConnected, ForstegangsregistreringKnapperad };
