import type { IKodeType } from '../types';

export class KodeType {
    private readonly kode: string;
    private readonly beskrivelse?: string;
    private readonly beskrivelseId?: string;
    private readonly gyldig: boolean;

    public constructor(kodeType: IKodeType) {
        this.kode = kodeType.kode;
        this.beskrivelse = kodeType.beskrivelse;
        this.beskrivelseId = kodeType.beskrivelseId;
        this.gyldig = kodeType.gyldig;
    }

    public getKode(): string {
        return this.kode;
    }

    public getBeskrivelse(): string {
        return this.beskrivelse;
    }

    public getBeskrivelseId(): string {
        return this.beskrivelseId;
    }

    public isGyldig(): boolean {
        return this.gyldig;
    }
}
