import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import type { KjennemerkeTypeEnum } from 'svv-tk-akr-common-frontend';
import { AkKnapp, AkLink } from 'svv-tk-akr-common-frontend';

import { RegelmerknaderKoder, RegelmerknaderKontekst, RegelmerknaderType, RegistreringsstatusKode, SaksType } from '../../models/kodeverk';
import type { Brukerprofil, IApenSak, IApentEierskifte, IKjennemerkeserie, IKjoretoy, IMerknad, RootStateType } from '../../models/types';
import { BrukerRegler, EierskifteRegler, FlyttingRegler, KjennemerkeRegler, KjoretoyRegler, Merknader, RegistreringRegler } from '../../regler';
import { finnKjennemerkeserie, kjoretoyListAddItemLocallyAction } from '../../state/actions';
import {
    brukerprofilSelector, kanBehandleRegistreringSelector, kanStarteOgBehandleForstegangsregistreringSelector, kanStarteOmregistreringssakSelector, kanStarteParegistreringssakSelector,
    kjoretoySelector
} from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';

interface IKjoretoydetaljerKnapperadProps {
    openModalForMidlertidigAvregistrering: () => void;
    hentMidlertidigVognkort: () => void;
    apneUtlevereUtenRegistreringModal: () => void;
}

interface IKjoretoydetaljerKnapperadDispatchProps {
    kjoretoyListAddItemLocallyAction: () => void;
    finnKjennemerkeserie?: (tegnkombinasjon: string) => Promise<any>;

}

interface IKjoretoydetaljerKnapperadStateProps {
    kjoretoy?: IKjoretoy;
    brukerprofil?: Brukerprofil;
    harTilgangTilForstegangsregistrering: boolean;
    kanStarteOmregistreringssak: boolean;
    kanStarteParegistreringssak: boolean;
    kanBehandleRegistrering: boolean;
    vognkortIsLoading: boolean;
    kanStarteOgBehandleForstegangsregistrering: boolean;
    isFlateeier: boolean;
    hasRolleOmreg: boolean;
    hasRolleEierskifte: boolean;
    hasRolleForstegangsregistrering: boolean;
    kjennemerkeserie: IKjennemerkeserie;
}

class KjoretoydetaljerKnapperad extends Component<IKjoretoydetaljerKnapperadProps & IKjoretoydetaljerKnapperadStateProps & IKjoretoydetaljerKnapperadDispatchProps & WithRouterProps> {

    public componentDidMount(): void {
        const erKjoretoyetRegistrert = !_.isEmpty(this.props.kjoretoy.registrering);
        if (!this.props.kjennemerkeserie.kjennemerkeserie && erKjoretoyetRegistrert) {
            this.props.finnKjennemerkeserie(this.props.kjoretoy.registrering?.kjennemerke?.tegnkombinasjon);
        }
    }

    public render(): React.ReactElement {
        return (
            <div className="ak-knapperad col-auto ml-auto">
                {this.rendreErstatningskjennemerkerKnapp()}
                {this.forstegangsregistreringKnapp()}
                {this.midlertidigVognkortKnapp()}
                {this.midlertidigAvregistreringKnapp()}
                {this.flyttKjoretoyKnapp()}
                {this.utleveringUtenRegistreringKnapp()}
                {this.eierskifteLink()}
                {this.registreringLink()}
                {this.redigereLeasingtakerKnapp()}
            </div>
        );
    }

    private rendreErstatningskjennemerkerKnapp = () => {
        const { godkjenning } = this.props.kjoretoy;
        const kjennemerkeType = this.props.kjoretoy.registrering?.kjennemerke?.kjennemerkeType;
        const kjennemerkeserie = this.props.kjennemerkeserie;

        return this.skalRendreErstaningskjennemerkerKnapp(kjennemerkeserie, kjennemerkeType)

            && <AkLink to={`/kjoretoy/${godkjenning.kuid}/erstatningskjennemerker/bestille`} customClassNames="ak-tilleggsknapp"
                text="kjoretoydetaljer.knapp.erstatningskjennemerker" />;
    };

    private skalRendreErstaningskjennemerkerKnapp(kjennemerkeserie: IKjennemerkeserie, kjennemerkeType: KjennemerkeTypeEnum) {

        return this.props.hasRolleOmreg
            && KjennemerkeRegler.erKjoretoyKravTilErstatningskjennemerkeOppfylt(this.props.kjoretoy.merknader)
            && KjennemerkeRegler.erKjennemerkekravTilErstatningskjennemerkerOppfylt(this.props.kjoretoy.merknader)
            && new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering()
            && !new KjoretoyRegler(this.props.kjoretoy).kjennemerkeSerierOgTyperSomSkjulerBestillErstatningskjennmerkeKnapp(kjennemerkeserie, kjennemerkeType);
    }

    private forstegangsregistreringKnapp = () => {
        const { godkjenning, merknader, apneSaker } = this.props.kjoretoy;

        if (new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering() || Merknader.exists(merknader, { kode: RegelmerknaderKoder.BRUKER_HAR_IKKE_RETTIGHETER })) {
            return null;
        }

        if (this.hasApenForstegangsregistreringSak(apneSaker)) {
            const { sakId } = this.getApenForstegangsregistreringSak(apneSaker);
            return (
                <AkLink to={`/kjoretoy/${godkjenning.kuid}/forstegangsregistrering/${sakId}`} customClassNames="ak-hovedknapp"
                    text="kjoretoydetaljer.knapp.gaTil.FORSTEGANGSREGISTRERING" disabled={!this.props.harTilgangTilForstegangsregistrering} />
            );
        }

        return !this.props.isFlateeier && (
            <AkLink to={`/kjoretoy/${godkjenning.kuid}/forstegangsregistrering`} customClassNames="ak-hovedknapp"
                text="kjoretoydetaljer.knapp.forstegangsregistrering" disabled={!this.props.kanStarteOgBehandleForstegangsregistrering} />
        );
    };

    private midlertidigAvregistreringKnapp = () => {
        if (!RegistreringRegler.kanBehandleRegistrering(this.props.brukerprofil, this.props.kjoretoy)) {
            return null;
        }

        const isDisabled = new KjoretoyRegler(this.props.kjoretoy).erKjoretoyParegistrertForMindreEnnDefinertAntallDagerSiden() || !!this.hasApenRegistreringEllerMidlertidigAvregistreringSak(this.props.kjoretoy.apneSaker)
            || new KjoretoyRegler(this.props.kjoretoy).harKjoretoyMedeier();

        return (
            RegistreringRegler.kanStarteMidlertidigAvregistrering(this.props.brukerprofil, this.props.kjoretoy) && (
                <AkKnapp type="ak-tilleggsknapp" disabled={isDisabled}
                    title={`kjoretoydetaljer.knapp.${!isDisabled ? 'midlertidigAvregistrering' : 'midlertidigAvregistreringDisabled'}`}
                    action={this.props.openModalForMidlertidigAvregistrering}
                    intlKey={'kjoretoydetaljer.knapp.midlertidigAvregistrering'} />
            )
        );
    };

    private midlertidigVognkortKnapp = () => {
        return (
            new KjoretoyRegler(this.props.kjoretoy).kanProdusereMidlertidigVognkort(this.props.brukerprofil) &&
            <AkKnapp type="ak-tilleggsknapp" action={this.props.hentMidlertidigVognkort} intlKey={'kjoretoydetaljer.knapp.midlertidigVognkort'} loading={this.props.vognkortIsLoading} />
        );
    };

    private flyttKjoretoyKnapp = () => {
        if (!FlyttingRegler.kjoretoyKanFlyttes(this.props.brukerprofil, this.props.kjoretoy)) {
            return null;
        }

        const disabled = !FlyttingRegler.kanBehandleFlytting(this.props.brukerprofil, this.props.kjoretoy);
        const harApneSakerEllerErstatningskjennemerkeBestilit = !FlyttingRegler.kanKjoretoyFlyttesIngenApneSaker(this.props.kjoretoy)
            || KjennemerkeRegler.erErstatningsKjennemerkeIBestilling(this.props.kjoretoy?.merknader);

        return (
            <AkKnapp type="ak-tilleggsknapp" action={this.flyttKjoretoy} disabled={disabled || harApneSakerEllerErstatningskjennemerkeBestilit}
                intlKey={harApneSakerEllerErstatningskjennemerkeBestilit ? 'kjoretoydetaljer.knapp.apneSaker' : 'kjoretoydetaljer.knapp.flyttkjoretoy'} />
        );
    };

    private eierskifteLink = () => {
        const kjoretoy = this.props.kjoretoy;

        if (!EierskifteRegler.skalViseEierskifteKnapper(this.props.brukerprofil, kjoretoy)) {
            return null;
        } else {
            const kuid = kjoretoy.registrering.kuid;
            const apneEierskifter = kjoretoy.apneEierskifter || [];
            const apentEierskifteId = this.getApentEierskifteIdForForhandler(apneEierskifter);

            if (!EierskifteRegler.kanBehandleEierskifte(kjoretoy)) {
                return (
                    <AkLink to={`/kjoretoy/${kuid}/eierskifte`} customClassNames="ak-hovedknapp" disabled={true}
                        text={apneEierskifter.length ? 'kjoretoydetaljer.knapp.gaTil.EIERSKIFTE' : 'kjoretoydetaljer.knapp.eierskifteDeaktivert'} />
                );
            } else if (apentEierskifteId) {
                return (
                    <AkLink to={`/kjoretoy/${kuid}/eierskifte/${apentEierskifteId}`} customClassNames="ak-hovedknapp"
                        text="kjoretoydetaljer.knapp.gaTil.EIERSKIFTE" />
                );
            } else {
                return (
                    <AkLink to={`/kjoretoy/${kuid}/eierskifte`} customClassNames="ak-hovedknapp" disabled={!this.eierskifteEnabled()}
                        text={this.eierskifteEnabled() ? 'kjoretoydetaljer.knapp.startEierskifte' : 'kjoretoydetaljer.knapp.eierskifteDeaktivert'} />
                );
            }
        }
    };

    private eierskifteEnabled = () => {
        return !this.getMerknader().length;
    };

    private getMerknader = (): IMerknad[] => {
        const kjoretoymerknader = Merknader.find(this.props.kjoretoy, { kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_START_EIERSKIFTE });
        kjoretoymerknader.push(...Merknader.find(this.props.kjoretoy, { kode: RegelmerknaderKoder.UVERIFISERT_KUNDE, kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_EIERSKIFTE }));
        const merknader = _.reject(kjoretoymerknader || [], EierskifteRegler.erMerknadOkForStartEierskifte);

        if (new KjoretoyRegler(this.props.kjoretoy).finnesEierskifterSomInnloggetForhandlerIkkeKanBehandle(this.props.kjoretoy.merknader, this.props.kjoretoy.apneEierskifter)) {
            merknader.push({
                kode: 'APENT_EIERSKIFTE',
                kontekst: ''
            });
        }
        const { eier, medeier } = this.props.kjoretoy.registrering;

        if (!EierskifteRegler.erGyldigEierForStartEierskifte(eier) || (medeier && !EierskifteRegler.erGyldigEierForStartEierskifte(medeier))) {
            merknader.push({
                kode: 'uidentifisertKunde',
                kontekst: ''
            });
        }

        return merknader;
    };

    private utleveringUtenRegistreringKnapp = () => {
        return (
            RegistreringRegler.kanStarteUtleveringUtenRegistrering(this.props.brukerprofil, this.props.kjoretoy) && (
                <AkKnapp type="ak-hovedknapp"
                    title={'kjoretoydetaljer.knapp.utleverUtenRegistrering'}
                    action={this.props.apneUtlevereUtenRegistreringModal}
                    intlKey={'kjoretoydetaljer.knapp.utleverUtenRegistrering'} />
            )
        );
    };

    private registreringLink = () => {

        if (!RegistreringRegler.skalViseRegistreringsKnapper(this.props.brukerprofil, this.props.kjoretoy)) {
            return null;
        }

        const kuid = this.props.kjoretoy.registrering.kuid;
        const apneSaker = this.props.kjoretoy.apneSaker || [];
        const apenRegistreringsak = this.getApenRegistreringsak(apneSaker);

        if (apenRegistreringsak) {
            const sakId = apenRegistreringsak.sakId || '0';
            const knappDisabled = !RegistreringRegler.kanBehandleRegistrering(this.props.brukerprofil, this.props.kjoretoy);

            return (
                <AkLink to={`/kjoretoy/${kuid}/registrering/${sakId}`} customClassNames="ak-hovedknapp" disabled={knappDisabled}
                    text={`kjoretoydetaljer.knapp.gaTil.${apenRegistreringsak.saksType}`} />
            );
        }

        if (this.props.kanStarteOmregistreringssak) {
            const knappDisabled = !this.props.kanBehandleRegistrering || this.erOmregistreringDisabled()
                || new KjoretoyRegler(this.props.kjoretoy).erGjortTekniskeEndringer(this.props.kjoretoy.merknader);

            return (
                <AkLink to={`/kjoretoy/${kuid}/registrering`} customClassNames="ak-hovedknapp" disabled={knappDisabled}
                    text="kjoretoydetaljer.knapp.omregistrering" />
            );
        }

        if (this.props.kanStarteParegistreringssak) {
            const knappDisabled = !this.props.kanBehandleRegistrering;

            return (
                <AkLink to={`/kjoretoy/${kuid}/registrering`} customClassNames="ak-hovedknapp" disabled={knappDisabled}
                    text="kjoretoydetaljer.knapp.paregistrering" />
            );
        }

        return null;
    };

    private redigereLeasingtakerKnapp = () => {
        const { apneSaker } = this.props.kjoretoy;
        const { brukerprofil, isFlateeier, hasRolleEierskifte, hasRolleForstegangsregistrering } = this.props;

        if (isFlateeier || hasRolleEierskifte || hasRolleForstegangsregistrering) {
            const { kuid } = this.props.kjoretoy.godkjenning;
            const kundeId = this.props.kjoretoy?.registrering?.eier?.kundeId;
            const disable = this.kanIkkeLeggeTilLeasingtaker(apneSaker);
            if (brukerprofil.valgtEnhet.hovedenhetKundeId === kundeId) {
                return <AkLink to={`/kjoretoy/${kuid}/leasingtaker`} customClassNames="ak-hovedknapp" text="kjoretoydetaljer.knapp.leasingtaker"
                    disabled={disable} />;
            }
        }
        return null;
    };

    private erKjoretoyRegistrertUtfortEllerVraket = (): boolean => {
        const registreringsstatus = this.props.kjoretoy.registrering?.registreringsinformasjon?.registreringsstatus;
        return registreringsstatus === RegistreringsstatusKode.UTFORT || registreringsstatus === RegistreringsstatusKode.VRAKET;
    };

    private erKjoretoyEtterlyst = (): boolean => {
        return Merknader.exists(this.props.kjoretoy.merknader, { kode: RegelmerknaderKoder.KJORETOY_STJALET, type: RegelmerknaderType.KRITISK });
    };

    private erOmregistreringDisabled = (): boolean => {
        return !!Merknader.find(this.props.kjoretoy.merknader, { kode: RegelmerknaderKoder.UVERIFISERT_KUNDE, kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_REGISTRERING_SAMME_EIER_OPPRETT }).length;
    };

    private kanIkkeLeggeTilLeasingtaker = (apneSaker: IApenSak[]): boolean => {
        return this.harApenRseRneEllerEierskiftesak(apneSaker)
            || this.erKjoretoyRegistrertUtfortEllerVraket()
            || this.erKjoretoyEtterlyst()
            || this.erKjoretoyIkkeRegistrertPaNyEier();
    };

    private harApenRseRneEllerEierskiftesak = (apneSaker: IApenSak[]): boolean => {
        const sakstyper = [SaksType.EIERSKIFTE, SaksType.REGISTRERING_NY_EIER, SaksType.REGISTRERING_SAMME_EIER];
        return _.some(apneSaker, sak => _.includes(sakstyper, sak.saksType));
    };

    private erKjoretoyIkkeRegistrertPaNyEier = (): boolean => {
        return Merknader.exists(this.props.kjoretoy.merknader, { kode: RegelmerknaderKoder.KJORETOY_IKKE_REGISTRERT_PA_NY_EIER, type: RegelmerknaderType.INFORMASJON });
    };

    private hasApenRegistreringEllerMidlertidigAvregistreringSak = (apneSaker: IApenSak[]): boolean => {
        const registreringssakstyper = [SaksType.REGISTRERING_SAMME_EIER, SaksType.REGISTRERING_NY_EIER, SaksType.MIDLERTIDIG_AVREGISTRERING];
        return _.some(apneSaker, sak => _.includes(registreringssakstyper, sak.saksType));
    };

    private hasApenForstegangsregistreringSak = (apneSaker: IApenSak[]): boolean => {
        return _.some(apneSaker, sak => _.includes(SaksType.FORSTEGANGSREGISTRERING, sak.saksType));
    };

    private getApenForstegangsregistreringSak = (apneSaker: IApenSak[]): IApenSak => {
        return _.find(apneSaker, sak => _.includes(SaksType.FORSTEGANGSREGISTRERING, sak.saksType));
    };

    private getApenRegistreringsak = (apneSaker: IApenSak[]): IApenSak => {
        const registreringssakstyper = [SaksType.REGISTRERING_SAMME_EIER, SaksType.REGISTRERING_NY_EIER];
        return _.find(apneSaker, sak => _.includes(registreringssakstyper, sak.saksType));
    };

    private getApentEierskifteIdForForhandler = (apneEierskifter: IApentEierskifte[]): string => {
        const apentEierskifte = _.find(apneEierskifter, (eierskifte: IApentEierskifte) => eierskifte.forhandlerErAktor);
        return apentEierskifte ? apentEierskifte.sakId : null;
    };

    private flyttKjoretoy = () => {
        this.props.kjoretoyListAddItemLocallyAction();
        this.props.navigate('/flytting');
    };

}

const mapDispatchToProps = (dispatch) => ({
    kjoretoyListAddItemLocallyAction: () => dispatch(kjoretoyListAddItemLocallyAction()),
    finnKjennemerkeserie: (tegnkombinasjon) => dispatch(finnKjennemerkeserie(tegnkombinasjon))

});

const mapStateToProps = (state: RootStateType) => ({
    kjoretoy: kjoretoySelector(state),
    brukerprofil: brukerprofilSelector(state),
    harTilgangTilForstegangsregistrering: BrukerRegler.adapter(state).harRolleForTilgangTilForstegangsregistrering() && !new KjoretoyRegler(state.kjoretoy).erGjenstandForEtterregistrering(),
    kanStarteOmregistreringssak: kanStarteOmregistreringssakSelector(state),
    kanStarteParegistreringssak: kanStarteParegistreringssakSelector(state),
    kanBehandleRegistrering: kanBehandleRegistreringSelector(state),
    vognkortIsLoading: state.midlertidigVognkort && state.midlertidigVognkort.isLoading,
    kanStarteOgBehandleForstegangsregistrering: kanStarteOgBehandleForstegangsregistreringSelector(state),
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    hasRolleOmreg: BrukerRegler.adapter(state).harRolleOmreg(),
    hasRolleEierskifte: BrukerRegler.adapter(state).harRolleEierskifte(),
    hasRolleForstegangsregistrering: BrukerRegler.adapter(state).harRolleForstegangsregistrering(),
    kjennemerkeserie: state.kjennemerkeserie
});

const KjoretoydetaljerKnapperadConnected = compose(
    connect<IKjoretoydetaljerKnapperadStateProps, IKjoretoydetaljerKnapperadDispatchProps, IKjoretoydetaljerKnapperadProps>(mapStateToProps, mapDispatchToProps),
    withRouter
)(KjoretoydetaljerKnapperad);

export { KjoretoydetaljerKnapperadConnected, KjoretoydetaljerKnapperad };
