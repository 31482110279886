import type { IError } from 'svv-tk-akr-common-frontend';
import { AktorType } from 'svv-tk-akr-common-frontend';
import { AkrConfig, KontrollerVilkarActionKey } from '../../constants';
import { SaksType } from '../../models/kodeverk';
import type { IKjoretoy, IRegistreringInformasjon, ISak, IVilkarstatus, ThunkResult } from '../../models/types';
import { KjoretoyRegler, RegistreringRegler, VilkarRegler } from '../../regler';
import { kjoretoySelector, sakSelector } from '../selectors';
import { createRestResource } from '../store';
import { nyMelding } from './melding-bar';
import type { SakActionType } from './sak';

export type KontrollerVilkarActionType = IKontrollerVilkarLoadingAction | IKontrollerVilkarErrorAction | IKontrollerVilkarSuccessAction | IKontrollerVilkarUpdateWaitintAction | SakActionType;

export interface IKontrollerVilkarLoadingAction {
    type: KontrollerVilkarActionKey.KONTROLLER_VILKAR_LOADING;
}

export interface IKontrollerVilkarErrorAction {
    type: KontrollerVilkarActionKey.KONTROLLER_VILKAR_ERROR;
    error: IError;
}

export interface IKontrollerVilkarSuccessAction {
    type: KontrollerVilkarActionKey.KONTROLLER_VILKAR_SUCCESS;
    vilkarstatus: IVilkarstatus;
}

export interface IKontrollerVilkarUpdateWaitintAction {
    type: KontrollerVilkarActionKey.KONTROLLER_VILKAR_UPDATE_WAITING;
    vilkarstatus: Partial<IVilkarstatus>;
}

export const kontrollerVilkarIsLoadingAction = (): IKontrollerVilkarLoadingAction => ({ type: KontrollerVilkarActionKey.KONTROLLER_VILKAR_LOADING });
export const kontrollerVilkarHasError = (error: IError): IKontrollerVilkarErrorAction => ({ type: KontrollerVilkarActionKey.KONTROLLER_VILKAR_ERROR, error });
export const kontrollerVilkarSuccessAction = (vilkarstatus: IVilkarstatus): IKontrollerVilkarSuccessAction => ({ type: KontrollerVilkarActionKey.KONTROLLER_VILKAR_SUCCESS, vilkarstatus });
export const kontrollerVilkarUpdateWaitingAction = (vilkarstatus: Partial<IVilkarstatus>): IKontrollerVilkarUpdateWaitintAction => ({
    type: KontrollerVilkarActionKey.KONTROLLER_VILKAR_UPDATE_WAITING,
    vilkarstatus
});

const api = createRestResource(AkrConfig.KONTROLLER_VILKAR_URL);

export function kontrollerVilkar(
    saksinformasjon: Partial<IRegistreringInformasjon>,
    skipWaitUpdate = false
): ThunkResult<Promise<Partial<IVilkarstatus> | IKontrollerVilkarSuccessAction | void>, KontrollerVilkarActionType> {

    return (dispatch, getState) => {
        const kjoretoy = kjoretoySelector(getState());
        const sak = sakSelector(getState());
        if (!skipWaitUpdate && (getState().vilkarstatus.isLoading || kjoretoy.isLoading || sak.isLoading)) {
            dispatch(kontrollerVilkarUpdateWaitingAction({ isWaiting: true, waitingSaksinformasjon: saksinformasjon }));
            return null;
        }

        const grunnlag = byggGrunnlagKontrollerVilkar(kjoretoy, sak, saksinformasjon);

        dispatch(kontrollerVilkarIsLoadingAction());
        const vilkarstatus = getState().vilkarstatus;
        return api.post(grunnlag).then((response) => {
            if (vilkarstatus.isWaiting) {
                const waitingSaksinformasjon = vilkarstatus.waitingSaksinformasjon;
                dispatch(kontrollerVilkarUpdateWaitingAction({ isWaiting: false, waitingSaksinformasjon }));
                dispatch(kontrollerVilkar(waitingSaksinformasjon, true));
                return null;
            }

            if (response.errorCode) {
                return dispatch(kontrollerVilkarHasError(response));
            }

            const feilmeldinger = VilkarRegler.meldingForKommunikasjonFeilVedKontrollAvVilkar(response.vilkar);
            if (feilmeldinger.length) {
                feilmeldinger.forEach((melding) => dispatch(nyMelding(melding)));
            }

            return dispatch(kontrollerVilkarSuccessAction(response));
        });
    };
}

function byggGrunnlagKontrollerVilkar(kjoretoy: IKjoretoy, sak: ISak<IRegistreringInformasjon>, saksinformasjon: Partial<IRegistreringInformasjon>): ISak<IRegistreringInformasjon> {
    const saksType = saksinformasjon.saksType || sak.saksType || sakstypeUopprettetSak(kjoretoy);
    if (saksType !== SaksType.FORSTEGANGSREGISTRERING) {
        return {
            kuid: sak.kuid ? sak.kuid : kjoretoy.kuid,
            saksType,
            sakId: sak.sakId,
            saksinformasjon: {
                ...saksinformasjon,
                nyEier: { aktorType: AktorType.NY_EIER, kundeId: kjoretoy.registrering.eier?.kundeId },
                nyMedeier: kjoretoy.registrering.medeier ? { aktorType: AktorType.NY_MEDEIER, kundeId: kjoretoy.registrering.medeier?.kundeId } : null,
                saksType
            }
        };
    } else {
        return {
            kuid: kjoretoy.kuid,
            saksType,
            sakId: sak.sakId,
            saksinformasjon: {
                ...saksinformasjon,
                saksType
            }
        };
    }
}

const sakstypeUopprettetSak = (kjoretoy: IKjoretoy) =>
    new KjoretoyRegler(kjoretoy).harKjoretoyRegistrering() ? (RegistreringRegler.erRegistrertPaEier(kjoretoy) ? SaksType.REGISTRERING_SAMME_EIER : SaksType.REGISTRERING_NY_EIER)
                                                           : SaksType.FORSTEGANGSREGISTRERING;
