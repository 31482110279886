import classNames from 'classnames';
import * as React from 'react';
import type { ReactNode } from 'react';
import { Component } from 'react';

import type { IArbeidslisteRad } from '../../models/types';
import { urlForArbeidslisteRad } from '../../utils';
import type { IRadConfigProps } from '../startside';
import { ArbeidslisteCell } from '../startside';

interface IArbeidslisteRowProps {
    rad: IArbeidslisteRad;
    radConfig: IRadConfigProps[];
    nyRad: boolean;
    openModalForGodkjennEierskifte?: (sakId: string) => void;
    openModalForPurring?: (sakId: string) => void;
}

class ArbeidslisteRow extends Component<IArbeidslisteRowProps> {

    public render(): ReactNode {
        const {nyRad} = this.props;
        const config = this.props.radConfig || [];
        const rowClass = classNames(
            'ak-table-row',
            {'ak-ny-rad': nyRad}
        );

        return (
            <div className={rowClass} data-at="arbeidsliste-rad">
                {config.map(this.cellMapper)}
            </div>
        );
    }

    private cellMapper = (radConfig: IRadConfigProps): ReactNode => {
        const {rad, openModalForGodkjennEierskifte, openModalForPurring} = this.props;

        return (
            <ArbeidslisteCell key={radConfig.headerIntlKey}
                disabled={radConfig.disabled(rad)}
                size={radConfig.cellSize}
                link={!radConfig.excludeLink && !radConfig.disabled(rad) && urlForArbeidslisteRad(rad)}>
                {radConfig.content(rad, openModalForGodkjennEierskifte, openModalForPurring)}
            </ArbeidslisteCell>
        );
    };
}

export { ArbeidslisteRow };
