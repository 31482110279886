import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import type { IArbeidslisteRad } from '../../models/types';
import type { IRadConfigProps } from './arbeidsliste-config';

interface ICellProps {
    rad: IArbeidslisteRad;
    config: IRadConfigProps;
    openModalForGodkjennEierskifte?: (sakId: string) => void;
    openModalForPurring?: (sakId: string) => void;
    hasLabel?: boolean;
    useAltLabel?: boolean;
    className?: string;
}

const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  
  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }
  
  & > * {
    margin-left: 0;
  }
`;

const CardCell: React.FC<ICellProps> = props => {
    const { className, hasLabel = true, rad, config, openModalForPurring, openModalForGodkjennEierskifte, useAltLabel } = props;

    if (!config) {
        return null;
    }

    return (
        <StyledCell className={className}>
            { hasLabel && <p className="card-label">
                <FormattedMessage id={useAltLabel ? config.headerAltIntlKey : config.headerIntlKey} />:</p>}
            {config.content(rad, openModalForGodkjennEierskifte, openModalForPurring)}
        </StyledCell>
    );
};

export { CardCell };
