import * as React from 'react';
import type { IKunderMap } from 'svv-tk-akr-common-frontend';
import type { IArkivdokument, IError, IObligatoriskDokument } from '../../../models/types';
import Saksdokmodal from './saksdokument-modal';
import SlettModal from './slett-modal';

interface IDokumentModal {
    getSakId: () => Promise<string>;
    slettArkivdokument?: (sakId: string, dokumentId: number) => Promise<any>;
    filSomSlettes: IArkivdokument;
    setFilSomSlettes: (doc: IArkivdokument | null) => void;
    setAktiveFiler: (doc: IArkivdokument[] | []) => void;
    aktiveFiler: IArkivdokument[];
    lastOppFil?: (sakId: string, file: IArkivdokument) => Promise<any>;
    endreArkivdokument?: (sakId: string, dokument: IArkivdokument) => Promise<any>;
    kunder: IKunderMap;
    obligatoriskeDokumenter?: IObligatoriskDokument[];
}

const DokumentModal: React.FC<IDokumentModal> = (props) => {
    const {
        obligatoriskeDokumenter,
        kunder,
        aktiveFiler,
        getSakId,
        setAktiveFiler,
        endreArkivdokument,
        lastOppFil,
        ...slettModalProps
    } = props;

    const lagreEndretFil = (arkivdokument: IArkivdokument): Promise<string> => {
        return new Promise((resolve, reject) => {
            getSakId()
                .then(lastOppEllerEndre(arkivdokument))
                .then((result: IError) => result.errorCode ? reject(result) : setAktiveFiler([]), () => resolve(''))
                .catch(reject);
        });
    };

    const lastOppEllerEndre = (arkivdokument: IArkivdokument) => (sakId: string): Promise<any> => {
        if (!sakId) {
            setAktiveFiler([]);
            return Promise.reject(null);
        }

        return !!arkivdokument.id ? endreArkivdokument(sakId, arkivdokument) : lastOppFil(sakId, arkivdokument);
    };

    const lagre = (doc: IArkivdokument): Promise<string> => {
        return new Promise((resolve, reject) => {
            getSakId()
                .then(lastOppEllerEndre(doc))
                .then((result: IError) => {
                    if (!!result.errorCode) {
                        return reject();
                    }

                    setAktiveFiler(aktiveFiler.length === 1
                                   ? []
                                   : aktiveFiler.filter(fil => fil.filnavn !== doc.filnavn));
                    resolve('');
                })
                .catch(reject);
        });
    };

    return (
        <>
            <Saksdokmodal
                fil={aktiveFiler[0]}
                submit={lagre}
                endre={lagreEndretFil}
                avbryt={() => setAktiveFiler([])}
                valgbareTyper={obligatoriskeDokumenter}
                kunder={kunder}
            />
            <SlettModal getSakId={getSakId} {...slettModalProps} />
        </>
    );
};

export default DokumentModal;