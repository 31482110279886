import { compose } from '@reduxjs/toolkit';
import * as React from 'react';
import type { MapStateToProps } from 'react-redux';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import type { IAkConfirmNavigationProps, IMessageDescriptor } from 'svv-tk-akr-common-frontend';

import { MeldingType } from '../../models/kodeverk';
import type { IArkivdokument, IKunder, IMelding, IRegistreringSak, RootStateType } from '../../models/types';
import { RegistreringRegler } from '../../regler';
import { deleteDocumentRegistrering, editDocumentRegistrering, nyMelding, opprettRegistreringSakVedDokumentopplasting, uploadDocumentRegistrering } from '../../state/actions';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';
import DokumenterPanel from '../felles/dokumenter-panel';

interface IRegistreringDokumentasjonProps extends IAkConfirmNavigationProps {
    isLesemodus: boolean;
    sakId: string;
}

interface IRegistreringDokumentasjonStateProps {
    registreringSak: IRegistreringSak;
    kunderByKundeId: IKunder;
}

interface IRegistreringDokumentasjonDispatchProps {
    opprettSak: (regSak: IRegistreringSak) => Promise<any>;
    uploadArkivdokument: (sakId: string, dokument: IArkivdokument) => Promise<any>;
    deleteArkivdokument: (sakId: string, dokumentId: number) => Promise<any>;
    endreArkivdokument: (sakId: string, dokument: IArkivdokument) => Promise<any>;
    nyMelding?: (melding: IMelding) => Promise<any>;
}

type PropsType = IRegistreringDokumentasjonProps & IRegistreringDokumentasjonStateProps & IRegistreringDokumentasjonDispatchProps;

class RegistreringDokumentasjon extends React.Component<PropsType & WithRouterProps> {

    public componentWillUnmount() {
        this.props.setCustomConfirmText({ bodyKey: undefined });
    }

    public render() {
        const { sakId, registreringSak } = this.props;

        if (!sakId && registreringSak.sakId) {
            return <Navigate to={`${this.props.location.pathname}/${this.props.registreringSak.sakId}`} />;
        }

        const dokumenterSomKanLastesOpp = RegistreringRegler.dokumenterSomKanLastesOpp(registreringSak.obligatoriskeDokumenter);

        if (!dokumenterSomKanLastesOpp.length) {
            return null;
        }

        return (
            <DokumenterPanel lesemodus={this.props.isLesemodus}
                getSakId={this.getSakId}
                arkivdokumenter={registreringSak.arkivdokumenter || []}
                obligatoriskeDokumenter={dokumenterSomKanLastesOpp}
                lastOppFil={this.uploadArkivdokument}
                kunder={this.props.kunderByKundeId}
                lastOppFilError={this.viseFilErrormessage}
                endreArkivdokument={this.props.endreArkivdokument}
                slettArkivdokument={this.props.deleteArkivdokument}/>
        );
    }

    private getSakId = (): Promise<string> => {
        const sakId = this.props.sakId;
        return sakId ? Promise.resolve(sakId) : this.opprettSak();
    };

    private opprettSak = () => {
        this.props.confirmNavigation(false);
        return this.props.opprettSak(this.props.registreringSak);
    };

    private uploadArkivdokument = (sakId: string, file: IArkivdokument) => {
        if (this.props.sakId) {
            this.props.setCustomConfirmText({ bodyKey: 'registrering.modal.avbrytSakMedDokumenter.beskrivelse' });
            this.props.confirmNavigation(true);
        }

        return this.props.uploadArkivdokument(sakId, file);
    };

    private viseFilErrormessage = (error: IMessageDescriptor) => {
        return this.props.nyMelding({ meldingIntlId: error.id, meldingType: MeldingType.DANGER });
    };
}

const mapStateToProps: MapStateToProps<IRegistreringDokumentasjonStateProps, IRegistreringDokumentasjonProps, RootStateType> = (state) => ({
    registreringSak: state.registreringSak,
    kunderByKundeId: state.kunder
});

const mapDispatchToProps = (dispatch): IRegistreringDokumentasjonDispatchProps => ({
    opprettSak: (registreringSak) => dispatch(opprettRegistreringSakVedDokumentopplasting(registreringSak)),
    uploadArkivdokument: (sakId: string, dokument: IArkivdokument) => dispatch(uploadDocumentRegistrering(sakId, dokument)),
    deleteArkivdokument: (sakId: string, dokumentId: number) => dispatch(deleteDocumentRegistrering(sakId, dokumentId)),
    endreArkivdokument: (sakId, dokument: IArkivdokument) => dispatch(editDocumentRegistrering(sakId, dokument)),
    nyMelding: (melding: IMelding) => dispatch(nyMelding(melding))
});

const RegistreringDokumentasjonConnected = compose(
    connect<IRegistreringDokumentasjonStateProps, IRegistreringDokumentasjonDispatchProps, IRegistreringDokumentasjonProps>(mapStateToProps, mapDispatchToProps),
    withRouter
)(RegistreringDokumentasjon);

export { RegistreringDokumentasjonConnected };
