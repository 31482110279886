import type { IMessageDescriptor} from 'svv-tk-akr-common-frontend';
import { AktorGodkjenningstatus, AktorType, ValgtKontaktform, GodkjenningUtfortType, VALID_EMAIL_REGEX, VALID_TLF_REGEX } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';

import { EierskifteStatus, RegelmerknaderKoder, RegelmerknaderKontekst, RegelmerknaderType } from '../models/kodeverk';
import type { Bruker, Brukerprofil, IAktor, IEierskifte, IKjoretoy, IKunde, IMerknad } from '../models/types';

import { erAktorMyndig, erKjoper, erUnderenhet, VALID_ORG_NUMMER } from '../utils';
import { BrukerRegler } from './bruker-regler';
import { KjoretoyRegler } from './kjoretoy-regler';
import { Merknader } from './merknader';

const MERKNADER_SOM_ER_OK_FOR_START_EIERSKIFTE: Partial<IMerknad>[] = [
    {kode: RegelmerknaderKoder.KJORETOY_HAR_KONTROLLSEDDEL_UTEN_BRUKSFORBUD},
    {kode: RegelmerknaderKoder.KJORETOY_IKKE_INNLEVERT_VOGNKORT_DEL_2},
    {type: RegelmerknaderType.INFORMASJON}
];

const MAKS_EGENVEKT_FOR_LETT_TILHENGER = 350;
const TEKNISKE_KODER_FOR_LETT_TILHENGER = ['O1', 'R1', 'TT'];

export class EierskifteRegler {

    public static getProgressStatusEierskifte(eierskifte: IEierskifte): number {
        switch (eierskifte.saksstatus) {
            case EierskifteStatus.UNDER_OPPRETTING:
                return 0;
            case EierskifteStatus.APEN:
                return 1;
            case EierskifteStatus.AVSLUTTET:
            case EierskifteStatus.AVBRUTT:
                return 2;
            default:
                return 0;
        }
    }

    public static getProgressStatusEierskifteTekster(): string[] {
        return ['eierskifte.fremdrift.underOppretting', 'eierskifte.fremdrift.tilGodkjenning', 'eierskifte.fremdrift.besvart'];
    }

    public static validerEierskifte(sak: IEierskifte, bruker: Bruker): IMerknad[] {
        const merknader = [];
        const {aktorer} = sak;
        if (!aktorer.length) {
            merknader.push({kode: 'skjemaInneholderFeil'});
        } else if (this.nyeEiereLikGamleEiere(aktorer)) {
            merknader.push({kode: 'KJOPER_ALLEREDE_EIER'});
        } else if (this.nyeEiereLikInnloggetBruker(aktorer, bruker)) {
            merknader.push({kode: 'KJOPER_INLOGGET_BRUKER'});
        } else {
            aktorer.forEach((aktor) => merknader.push(...this.validateAktor(aktor)));
        }

        return merknader;
    }

    public static erGyldigEierForStartEierskifte(eier: IKunde): boolean {
        return eier && (!eier.organisasjonsnummer || this.erGyldigOrgNr(eier));
    }

    public static erGyldigOrgNr(eier: IKunde): boolean {
        return eier.organisasjonsnummer ? !!eier.organisasjonsnummer.match(VALID_ORG_NUMMER) : false;
    }

    public static erGyldigKjoretoyForEierskifte(kjoretoy: IKjoretoy): boolean {
        const merknader = _.reject(kjoretoy.merknader || [], this.erMerknadOkForStartEierskifte);
        return !Merknader.exists(merknader, {kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_START_EIERSKIFTE});
    }

    public static kanBehandleEierskifte(kjoretoy: IKjoretoy): boolean {
        return !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.AVDELING_HAR_IKKE_RETTIGHETER,
                kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_EIERSKIFTE
            })
            && !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.EIER_UVERIFISERT_KUNDE,
                kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_KUNDE
            })
            && !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.FORHANDLER_ER_INHABIL,
                kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_TILGANG
            });
    }

    public static harAlleAktorerGodkjent(aktorer: IAktor[]): boolean {
        const aktorerEierMedeier = _.filter(aktorer, aktor => !this.harAktorInitiertEllerErUnderenhet(aktor));
        const aktorerSomHarGodkjent = _.filter(aktorer,
            aktor => aktor.godkjenningsstatus === AktorGodkjenningstatus.GODKJENT
                && !this.harAktorInitiertEllerErUnderenhet(aktor));

        return aktorerSomHarGodkjent.length === aktorerEierMedeier.length;
    }

    public static skalViseEierskifteKnapper(brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): boolean {
        return new KjoretoyRegler(kjoretoy).harKjoretoyRegistrering()
            && kjoretoy.registrering.kjennemerke
            && (new BrukerRegler(brukerprofil).harRolleEierskifte() || new BrukerRegler(brukerprofil).harRolleFlateeier());
    }

    public static erMerknadOkForStartEierskifte(merknad: IMerknad): boolean {
        return _.some(MERKNADER_SOM_ER_OK_FOR_START_EIERSKIFTE, Merknader.filterForMerknad(merknad));
    }

    public static meldingFraMerknad(merknad: IMerknad): IMessageDescriptor {
        return {id: `eierskifte.validering.${merknad.kode}`};
    }

    public static kanAktorVelgeSignaturtype = (aktor: IAktor, kjoretoy: IKjoretoy): boolean => {
        return EierskifteRegler.erMindrearigOgLettKjoretoy(aktor, kjoretoy) && erKjoper(aktor);
    };

    public static erMindrearigOgLettKjoretoy = (aktor: IAktor, kjoretoy: IKjoretoy): boolean => {
        return aktor.erPerson && !erAktorMyndig(aktor) && EierskifteRegler.erLettKjoretoy(kjoretoy);
    };

    public static erLettKjoretoy = (kjoretoy: IKjoretoy): boolean => {
        return kjoretoy?.godkjenning?.egenvekt <= MAKS_EGENVEKT_FOR_LETT_TILHENGER && TEKNISKE_KODER_FOR_LETT_TILHENGER.includes(kjoretoy?.godkjenning?.tekniskKode);
    };

    public static utledGyldigeSignaturtyper = (): GodkjenningUtfortType[] => {
        return [GodkjenningUtfortType.IKKE_SIGNERT, GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA];
    };

    private static harAktorInitiertEllerErUnderenhet(aktor: IAktor): boolean {
        return aktor.eierskifteInitiertAv
            || aktor.aktorType === AktorType.NY_UNDERENHET
            || aktor.aktorType === AktorType.UNDERENHET;
    }

    private static nyeEiereLikGamleEiere(aktorer: IAktor[]): boolean {
        const aktorerEtterType = _.keyBy(aktorer, aktor => aktor.aktorType);
        return (aktorerEtterType[AktorType.EIER] || {kundeId: null}).kundeId === (aktorerEtterType[AktorType.NY_EIER] || {kundeId: null}).kundeId
            && (aktorerEtterType[AktorType.MEDEIER] || {kundeId: null}).kundeId === (aktorerEtterType[AktorType.NY_MEDEIER] || {kundeId: null}).kundeId;
    }

    private static nyeEiereLikInnloggetBruker(aktorer: IAktor[], bruker: Bruker): boolean {
        if (!bruker) {
            return false;
        }
        const aktorerEtterType = _.keyBy(aktorer, aktor => aktor.aktorType);
        return aktorerEtterType[AktorType.NY_EIER]?.kundeId === bruker?.kundeId
            || aktorerEtterType[AktorType.NY_MEDEIER]?.kundeId === bruker?.kundeId;
    }

    private static validateAktor(aktor: IAktor): IMerknad[] {
        const merknader = [];

        if (!aktor.navn) {
            merknader.push({kode: 'skjemaInneholderFeil'});
        }

        if (!erUnderenhet(aktor)) {
            if (!aktor.skalBenytteRegistrertKontaktinformasjon && aktor.valgtKontaktform === ValgtKontaktform.EPOST) {
                if (!aktor.epost) {
                    merknader.push({kode: 'manglerEpost'});
                } else if (!VALID_EMAIL_REGEX.test(aktor.epost)) {
                    merknader.push({kode: 'skjemaInneholderFeil'});
                }
            } else if (!aktor.skalBenytteRegistrertKontaktinformasjon && aktor.valgtKontaktform === ValgtKontaktform.MOBIL) {
                if (!aktor.mobiltelefon) {
                    merknader.push({kode: 'manglerMobiltelefon'});
                } else if (!VALID_TLF_REGEX.test(aktor.mobiltelefon)) {
                    merknader.push({kode: 'skjemaInneholderFeil'});
                }
            }
        }

        return _.uniqBy(merknader, 'kode');
    }

    private static erKjoperIkkeMyndigMerknad(merknad: IMerknad): boolean {
        return merknad.kode === RegelmerknaderKoder.KJOPER_IKKE_MYNDIG.valueOf();
    }

    private static harObligatoriskeDokumenter(merknad: IMerknad): boolean {
        return merknad.obligatoriskeDokumenter.length > 0;
    }

    public static finnesObligatoriskeDokumenter = (merknader: IMerknad[]): boolean => {
        return _.some(merknader, merknad => EierskifteRegler.erKjoperIkkeMyndigMerknad(merknad) && EierskifteRegler.harObligatoriskeDokumenter(merknad));
    };

    public static enAktorMenIkkeBeggeErUmyndig(merknader: IMerknad[]): boolean {
        return Merknader.exists(merknader, { kode: RegelmerknaderKoder.KJOPER_IKKE_MYNDIG }) &&
            !Merknader.exists(merknader, { kode: RegelmerknaderKoder.NY_EIER_OG_NY_MEDEIER_IKKE_MYNDIG });
    }

    public static utledKritiskeMeldinger(kjoretoy: IKjoretoy): IMessageDescriptor[] {
        const { merknader } = kjoretoy;
        return _.chain(merknader)
            .uniqBy('kode')
            .filter((merknad: IMerknad) => {
                return !!merknad.kode.match('BRUKSFORBUD') && !new KjoretoyRegler(kjoretoy).erMerknadBareTilInfo(merknad);
            })
            .map((merknad: any) => ({ id: `kjoretoydetaljer.meldinger.${merknad.kode}` }))
            .value();
    }

}
