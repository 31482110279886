import { KjennemerkeSerieEnum } from 'svv-tk-akr-common-frontend';

export enum FremdriftsindikatorStatus {
    UNDER_OPPRETTING = 0,
    TIL_GODKJENNING = 1,
    GODKJENT = 2,
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    AVBRUTT = 2,
    REGISTRERT = 3
}

export const FREMDRIFTSSTATUS_TEKSTER: string[] = [
    'forstegangsregistrering.fremdrift.underOppretting',
    'forstegangsregistrering.fremdrift.tilGodkjenning',
    'forstegangsregistrering.fremdrift.godkjent',
    'forstegangsregistrering.fremdrift.registrert'
];

export const DRIVSTOFFSPESIFIKKE_KJENNEMERKESERIER = [
    KjennemerkeSerieEnum.ELEKTRISK,
    KjennemerkeSerieEnum.ELEKTRISK_4,
    KjennemerkeSerieEnum.GASS,
    KjennemerkeSerieEnum.GASS_4,
    KjennemerkeSerieEnum.HYDROGEN,
    KjennemerkeSerieEnum.HYDROGEN_4
];

export const AKTOR_VALIDER_PREFIX = 'forstegangsregistrering.validering';
