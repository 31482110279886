import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import type { IMessageDescriptor} from 'svv-tk-akr-common-frontend';
import { AkMeldingboks, AkMeldingboksType, KjennemerkeTypeEnum } from 'svv-tk-akr-common-frontend';

import { KjoringensArt, RegelmerknaderKoder, RegelmerknaderKontekst, RegistreringsstatusKode, SaksType } from '../../models/kodeverk';
import type { Brukerprofil, IApenSak, IApentEierskifte, IKjoretoy, IMerknad, IPkkFrist, IRegistrering, IRootState } from '../../models/types';
import { BrukerRegler, ForstegangsregistreringRegler, KjennemerkeRegler, KjoretoyRegler, Merknader } from '../../regler';
import { brukerprofilSelector } from '../../state/selectors';

interface IKjoretoydetaljerProps {
    kjoretoy: IKjoretoy;
    brukerprofil?: Brukerprofil;
    merknader: IMerknad[];
    apneEierskifter: IApentEierskifte[];
    apneSaker: IApenSak[];
    isFlateeier: boolean;
}

class KjoretoydetaljerMeldinger extends Component<IKjoretoydetaljerProps> {

    public render(): React.ReactElement {

        const merknader = this.getMerknader();
        const feilmeldinger = this.getFeilmeldinger(merknader);
        const infomeldinger = this.getInfomeldinger(this.props.kjoretoy.registrering, merknader, feilmeldinger);

        if (!feilmeldinger.length && !infomeldinger.length) {
            return null;
        }

        return (
            <div className="col-12 mb-4">
                {!!feilmeldinger.length &&
                    <AkMeldingboks meldingType={AkMeldingboksType.ERROR} messageKey={feilmeldinger} />
                }
                {!!infomeldinger.length &&
                    <AkMeldingboks meldingType={AkMeldingboksType.INFO} messageKey={infomeldinger} customClassName={!!feilmeldinger.length && 'mt-3'} />
                }
            </div>
        );
    }

    private getFeilmeldinger = (merknader: IMerknad[]): IMessageDescriptor[] => {
        const feilmeldinger = [];

        if (merknader.length) {
            const feilmerknader = _.filter(merknader, (merknad) => !new KjoretoyRegler(this.props.kjoretoy).erMerknadBareTilInfo(merknad));
            feilmeldinger.push(...feilmerknader.map(new KjoretoyRegler(this.props.kjoretoy).meldingFraMerknad));
        }

        feilmeldinger.push(...new KjoretoyRegler(this.props.kjoretoy).utledKjoretoymeldinger(this.props.brukerprofil));

        return _.uniqBy(feilmeldinger, 'id');
    };

    private getInfomeldinger = (registrering: IRegistrering, merknader: IMerknad[], feilmeldinger: IMessageDescriptor[] = []): IMessageDescriptor[] => {
        const infomeldinger = [];

        if (merknader.length) {
            let infomerknader = _.filter(merknader, (merknad) => !!new KjoretoyRegler(this.props.kjoretoy).erMerknadBareTilInfo(merknad));
            if (!!new KjoretoyRegler(this.props.kjoretoy).klassifisertSomAmbulanseOgUtryknignskjoretoy(infomerknader)) {
                infomerknader = infomerknader.filter(merknad => RegelmerknaderKoder.KJORETOY_UTRYKNING.valueOf() === merknad.kode);
            }
            infomeldinger.push(...infomerknader.filter(new KjoretoyRegler(this.props.kjoretoy).merknadSkalIkkeVisesISkjermbildet).map(new KjoretoyRegler(this.props.kjoretoy).meldingFraMerknad));
        }

        this.leggTilInfomeldingForPkkFrist(infomeldinger);

        if (new KjoretoyRegler(this.props.kjoretoy).kreverKjennemerkebytte() && !KjennemerkeRegler.erAlleKjennemerkerGjortRedeFor(this.props.kjoretoy)) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.KJENNEMERKER_MA_GJORES_REDE_FOR' });
        }

        this.leggTilInfomeldingerForApneSaker(infomeldinger);

        this.leggTilInfomeldingerForTekniskGodkjenning(infomeldinger);

        this.leggTilInfomeldingerForRegistrering(infomeldinger, registrering);

        return _.uniqBy(infomeldinger, 'id')
            .filter((melding: IMessageDescriptor) => !_.some(feilmeldinger, (feilmelding: IMessageDescriptor) => feilmelding.id === melding.id));
    };

    private leggTilInfomeldingerForApneSaker = (infomeldinger: IMessageDescriptor[]) => {
        if (this.props.apneEierskifter?.length && this.getApentEierskifteIdForForhandler(this.props.apneEierskifter)) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.APENT_EIERSKIFTE' });
        }

        if (_.some(this.props.apneSaker, sak => sak.saksType === SaksType.MIDLERTIDIG_AVREGISTRERING.valueOf())) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.KJORETOY_APEN_MIDLERTIDIG_AVREGISTRERING_SAK' });
        }

        if (_.some(this.props.apneSaker, sak => sak.saksType === SaksType.FORSTEGANGSREGISTRERING.valueOf())) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.APEN_FORSTEGANGSREGISTRERING' });
        }
    };

    private leggTilInfomeldingerForRegistrering = (infomeldinger: IMessageDescriptor[], registrering: IRegistrering, ) => {
        if (new KjoretoyRegler(this.props.kjoretoy).erGjenstandForEtterregistrering() && !new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering()) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.etterregistrering' });
        }

        if (registrering?.kjennemerke?.kjennemerkeType === KjennemerkeTypeEnum.SVALBARD || registrering?.bruksinformasjon?.kjoringensArt.kode === KjoringensArt.KUN_SVALBARD.valueOf()) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.registrertPaSvalbard' });
        }
    };

    private leggTilInfomeldingerForTekniskGodkjenning = (infomeldinger: IMessageDescriptor[]) => {
        if (this.maFullforesPaTrafikkstasjonTekniskUtenforOffentligVeg()) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.utenforOffentligVegFullforPaTrafikkstasjon' });
        }

        const manglendeTekniskeData = new KjoretoyRegler(this.props.kjoretoy).manglerTekniskeData(this.props.merknader);
        if (manglendeTekniskeData) {
            infomeldinger.push({ id: 'generell.manglertekniskedata', values: { felter: manglendeTekniskeData } });
        }

        if (new KjoretoyRegler(this.props.kjoretoy).erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt()) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.KJORINGENS_ART_LOYVEPLIKTIG' });
        }
    };

    private leggTilInfomeldingForPkkFrist = (infomeldinger: IMessageDescriptor[]) => {
        const pkkFrist = this.props.kjoretoy.registrering?.pkkFrist || {} as IPkkFrist;
        const { pkkFristForGodkjenning, pkkUtloper } = pkkFrist;

        if (pkkFristForGodkjenning && pkkUtloper && moment(pkkFristForGodkjenning).isSameOrAfter(moment(), 'd')) {
            infomeldinger.push({ id: 'kjoretoydetaljer.meldinger.pkkUtloper' });
        }
    };

    private getMerknader = (): IMerknad[] => {
        const eierskifteMerknader = Merknader.find(this.props.merknader, { kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_START_EIERSKIFTE });
        const startForstegangsregistreringMerknader = Merknader.find(this.props.merknader, { kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_START_FORSTEGANGSREGISTRERING })
            .filter((merknad: IMerknad) => !this.erMerknadRelatertTilManglendeData(merknad.kode));

        const bruksforbudManglerForsikringMerknad = Merknader.find(this.props.kjoretoy.merknader, { kode: RegelmerknaderKoder.BRUKSFORBUD_MANGLER_FORSIKRING });

        const startEierskifteMerknader = eierskifteMerknader.filter(m => m.kode !== RegelmerknaderKoder.KJORETOY_IKKE_INNLEVERT_VOGNKORT_DEL_2.valueOf());

        const innhabilForhandlerMerknader = Merknader.find(this.props.merknader, { kode: RegelmerknaderKoder.FORHANDLER_ER_INHABIL });

        const maFullforePaTrafikkstasjonMerknader = Merknader.find(this.props.kjoretoy.merknader, { kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_SAMME_EIER }) ||
            Merknader.find(this.props.kjoretoy.merknader, { kontekst: RegelmerknaderKontekst.AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER });

        const registreringKjennemerkeMerknader = Merknader.find(this.props.merknader, { kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_REGISTRERING_ENDRE_KJENNEMERKE })
            .filter((merknad: IMerknad) => !this.erMerknadRelatertTilPk(merknad.kode));

        const erstatningskjennemerkeAlleredeIBestilling = Merknader.find(this.props.merknader, { kode: RegelmerknaderKoder.ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING });

        const merknader = [].concat(startEierskifteMerknader, innhabilForhandlerMerknader, startForstegangsregistreringMerknader,
            maFullforePaTrafikkstasjonMerknader, bruksforbudManglerForsikringMerknad, erstatningskjennemerkeAlleredeIBestilling, registreringKjennemerkeMerknader);

        if (new KjoretoyRegler(this.props.kjoretoy).finnesEierskifterSomInnloggetForhandlerIkkeKanBehandle(this.props.merknader, this.props.apneEierskifter || [])) {
            merknader.push({
                kode: 'APENT_EIERSKIFTE'
            });
        }

        const apneSaker = this.props.apneSaker || [];
        if (apneSaker.length && this.hasApenRegistreringSak(apneSaker)) {
            merknader.push({
                kode: 'APEN_REGISTRERING'
            });
        }

        if (apneSaker.length
            && this.hasApenForstegangsregistreringSak(apneSaker)
            && !ForstegangsregistreringRegler.kanBehandleForstegangsregistrering(this.props.brukerprofil, this.props.kjoretoy) && !this.props.isFlateeier) {
            merknader.push({
                kode: 'APEN_FORSTEGANGSREGISTRERING'
            });
        }

        if (new BrukerRegler(this.props.brukerprofil).erEierUnderenhet(this.props.kjoretoy)) {
            merknader.push({
                kode: 'eierErUnderenhet'
            });
        }

        if (new KjoretoyRegler(this.props.kjoretoy).harKjoretoyMedeier() && !!new BrukerRegler(this.props.brukerprofil).erEnAvEiereForhandler(this.props.kjoretoy)) {
            const { registreringsstatus } = this.props.kjoretoy.registrering.registreringsinformasjon;
                if(registreringsstatus === RegistreringsstatusKode.AVREGISTRERT) {
                    merknader.push({
                        kode: 'KJORETOY_HAR_MEDEIER_AVREGISTRERT'
                    });
                } else if (registreringsstatus === RegistreringsstatusKode.REGISTRERT) {
                    merknader.push({
                        kode: 'KJORETOY_HAR_MEDEIER'
                    });
                }
            }

        if (!new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering() && new KjoretoyRegler(this.props.kjoretoy).harApenDagsprovekjennemerkesak()) {
            merknader.push({
                kode: 'apenDagsprovekjennemerkesak'
            });
        }

        return merknader;
    };

    private erMerknadRelatertTilPk = (merknadskode: string): boolean => {
        const merknaderRelatertTilPk = [
            'MA_FJERNE_KNYTNING_TIL_PERSONLIG_KJENNEMERKE'
        ];

        return merknaderRelatertTilPk.includes(merknadskode);
    };

    private erMerknadRelatertTilManglendeData = (merknadskode: string): boolean => {
        const merknaderRelatertTilManglendeData = [
            'KJORETOY_MANGLER_REGISTRERINGSAR',
            'KJORETOY_MANGLER_DRIVSTOFFKODE',
            'KJORETOY_MANGLER_EGENVEKT',
            'KJORETOY_MANGLER_TOTALVEKT',
            'KJORETOY_MANGLER_AVGIFTSKODE',
            'KJORETOY_MANGLER_TEKNISK_KODE'
        ];

        return merknaderRelatertTilManglendeData.indexOf(merknadskode) > -1;
    };

    private hasApenRegistreringSak = (apneSaker: IApenSak[]): boolean => {
        const registreringssakstyper = [SaksType.REGISTRERING_SAMME_EIER, SaksType.REGISTRERING_NY_EIER];
        return _.some(apneSaker, sak => _.includes(registreringssakstyper, sak.saksType));
    };

    private hasApenForstegangsregistreringSak = (apneSaker: IApenSak[]): boolean => {
        return _.some(apneSaker, sak => _.includes(SaksType.FORSTEGANGSREGISTRERING, sak.saksType));
    };

    private getApentEierskifteIdForForhandler = (apneEierskifter: IApentEierskifte[]): string => {
        const apentEierskifte = _.find(apneEierskifter, (eierskifte: IApentEierskifte) => eierskifte.forhandlerErAktor);
        return apentEierskifte ? apentEierskifte.sakId : null;
    };

    private maFullforesPaTrafikkstasjonTekniskUtenforOffentligVeg = (): boolean => {
        return _.includes(this.props.kjoretoy.godkjenning.unntak, 'KUN_UTENFOR_OFFENTLIG_VEI')
            && new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering()
            && !this.harKjennemerkeTypeUtenforOffentligVegSvalbardVeteranUtenforOffentligVeg(this.props.kjoretoy);
    };

    private harKjennemerkeTypeUtenforOffentligVegSvalbardVeteranUtenforOffentligVeg = (kjoretoy: IKjoretoy): boolean => {
        const kjennemerkeTypeForKunUtenforOffentligVeg = [KjennemerkeTypeEnum.UTENFOR_OFFENTLIG_VEG, KjennemerkeTypeEnum.SVALBARD, KjennemerkeTypeEnum.VETERAN_UTENFOR_OFFENTLIG_VEI];
        return _.includes(kjennemerkeTypeForKunUtenforOffentligVeg, kjoretoy.registrering.kjennemerke.kjennemerkeType);
    };
}

const mapStateToProps = (state: IRootState): Partial<IKjoretoydetaljerProps> => ({
    brukerprofil: brukerprofilSelector(state)
});

const Connected = connect<any, any, IKjoretoydetaljerProps>(mapStateToProps)(KjoretoydetaljerMeldinger);
export { Connected as KjoretoydetaljerMeldinger };
