import moment from 'moment';
import qs from 'qs';

export const ISO_8601_DATO_FORMAT = 'YYYY-MM-DD';
export const ISO_8601_DATO_TID_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const STANDARD_DATO_FORMAT = 'DD.MM.YYYY';
export const DATO_TID_FORMAT = 'DD.MM.YY HH:mm';
export const STANDARD_KLOKKESLETT_FORMAT = 'HH:mm';
export const ORG_NUMMER_LENGTH = 9;
export const VALID_ORG_NUMMER = /^[8-9?3][0-9]{8}$/;
export const NI_SIFFER_REGEX = /^\d{9}$/;
export const ELLEVE_SIFFER_REGEX = /^\d{11}$/;
export const NI_ELLER_ELLEVE_SIFFER_REGEX = /^\d{9}$|^\d{11}$/;
export const BOKSTAVER_OG_TALL = /^[A-Za-z0-9]*$/;
export const TALL_FRA_OG_MED_NULL = /^(([1-9][0-9]*)|([0]+))$/;
export const WHITESPACE = /\s/g;
export const WHITESPACE_OG_DASH = /\s|-/g;
export const VIS_MED_MELLOMROM_SPRAKRADETS_REGLER = /\B(?=(\d{3})+(?!\d))/g;

export function formatStoreBokstaverOgUtenWhitespace(value: string): string {
    return (value || '').replace(WHITESPACE, '').toUpperCase();
}

export function formatStoreBokstaverUtenWhitespaceEllerDash(value: string): string {
    return (value || '').replace(WHITESPACE_OG_DASH, '').toUpperCase();
}

export function akFormatDate(value: string | Date | moment.Moment, inputFormat: string = ISO_8601_DATO_FORMAT, outputFormat: string = STANDARD_DATO_FORMAT): string {
    if (!value) {
        return '';
    }

    let dato: moment.Moment;

    if (moment.isMoment(value)) {
        dato = value;
    } else if (moment.isDate(value)) {
        dato = moment(value);
    } else {
        dato = moment(value, inputFormat, true);
    }

    if (!dato.isValid()) {
        return moment.isMoment(value) ? value.toISOString() : value.toString();
    }

    return dato.format(outputFormat);
}

export function akParseUrlParams(search: string): qs.ParsedQs {
    return qs.parse(search, {ignoreQueryPrefix: true});
}
