/* eslint-disable max-lines */
import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { IAkConfirmNavigationProps, IKundeAdresse, IKunderMap, IMerknad, IMessageDescriptor, IVilkar } from 'svv-tk-akr-common-frontend';
import {
    AdressePanel, AkConfirmNavigation, AkDrosjePanel, AkFremdriftsindikator, AkKontrollistePanel, AkLoading, AkOrdrenummer, AktorGodkjenningstatus, AktorType, AkVektarsavgiftPanel, AkVilkarPanel,
    GodkjenningUtfortType, KjoretoyInfoPanel, Panel, softHyphen, VilkarStatus, VilkarstatusSaksdokument
} from 'svv-tk-akr-common-frontend';

import { KjennemerkeAlleredeIBrukModal } from '../../components/modal/kjennemerke-allerede-i-bruk-modal';
import type { FremdriftsindikatorStatus } from '../../constants';
import { FREMDRIFTSSTATUS_TEKSTER } from '../../constants';
import { AvsluttSakIndikator, KjoringensArt, MeldingType, RegelmerknaderKoder, RegelmerknaderType, Saksstatus, SaksType, Vilkarsnavn } from '../../models/kodeverk';
import type {
    Brukerprofil, IAktor, IArkivdokument, IGrunnlagVektarsavgift, IKjennemerkeserierOgTyper, IKjoretoy, IKjoringensArtListe, IKunde, IMelding, INyttKjennemerke, IRegistreringInformasjon, ISak,
    IVilkarstatus, NavigateType, RootStateType
} from '../../models/types';
import { BrukerRegler, ForstegangsregistreringRegler, ForstegangsregistreringValidator, Merknader } from '../../regler';
import { TilhengervektRegler } from '../../regler/tilhengervekt-regler';
import {
    avbrytForstegangsregistrering, deleteForstegangsregistreringArkivdokument, editForstegangsregistreringArkivdokument, endreSakTilGodkjenningRegistrering, fjernAlleMeldinger, gaTilBetalingSkatt,
    getKunde, hentForstegangsregistrering, hentKjennemerkeserierOgTyper, hentKjoringensArtListe, kontrollerVilkar, nyMelding, resetKjennemerkeserierOgTyper, resetSak, submitForstegangsregistrering,
    submitForstegangsregistreringOgLastOppDokument
} from '../../state/actions';
import { fjernForsikringsKnytningMellomEierOgKjoretoy } from '../../state/actions/forsikring';
import { vilkarstatusMerknaderOgSakMerknaderSelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import {
    aktorerHarGodkjent, erHovedenhetInnlogget, harApenSakAvType, harByttetAktor, harEndretKjoringensArt, leggTilDataFraKundePaAktor, sakErAvluttetAvbrutt, skalVisesIKontrollpanel,
    utledDefaultKjennemerkeserie, utledFremdriftsstatus, utledObligatoriskeDokumenter, utledOgSettDrosjeloyvenummer, utledTilhengerinfo, utledVisningsdokumenterTilKontrollisteFraArkivdokumenter,
    withRouter
} from '../../utils';
import type { ILeasingtakerOgUnderenhet } from '../felles-registrering';
import { LeasingtakerUnderenhetConnected, OppdaterKjennemerkeCallSourceComponent, RegistreringKjennemerkePanelConnected } from '../felles-registrering';
import DokumenterPanel from '../felles/dokumenter-panel';
import { KjoretoyBrukConnected } from '../kjoretoy-bruk';
import { ForstegangsregistreringAktorerConnected, ForstegangsregistreringKnapperadConnected, ForstegangsregistreringMeldingerConnected, ForstegangsregistreringStatusbarConnected } from './';

interface IForstegangsregistreringStateProps {
    sak?: ISak<IRegistreringInformasjon>;
    kjoretoy?: IKjoretoy;
    vilkarstatus?: IVilkarstatus;
    isLoading?: boolean;
    lesemodus: boolean;
    melder: IAktor;
    kunder?: IKunderMap;
    kjennemerkeserierOgTyper?: IKjennemerkeserierOgTyper;
    vektarsavgiftUrl: string;
    isFlateeier: boolean;
    vilkarstatusMerknaderOgSakMerknader: IMerknad[];
    kjoringensArtListe?: IKjoringensArtListe;
    gyldigeForhandlereSvalbard: string[];
    brukerprofil: Brukerprofil;
}

interface IForstegangsregistreringDispatchProps {
    getForstegangsregistreringStatus?: (sak: ISak<IRegistreringInformasjon>) => any;
    get?: (sakId: string) => Promise<any>;
    reset?: () => void;
    avbryt?: (navigate: NavigateType, sakId: string, kuid: string, saksinformasjon: IRegistreringInformasjon) => void;
    hentAktor?: (orgFnr: string, aktorType?: AktorType, kuid?: string) => Promise<IKunde>;
    hentKjennemerkeserierOgTyper?: (kuid: string, kjoringensArt: string) => Promise<any>;
    hentVilkar: (saksinformasjon: IRegistreringInformasjon) => void;
    fjernForsikringsKnytningMellomEierOgKjoretoy?: (kuid: string, saksinformasjon: Partial<IRegistreringInformasjon>) => void;
    submit?: (navigate: NavigateType, saksinformasjon: IRegistreringInformasjon, avsluttSakIndikator: AvsluttSakIndikator) => any;
    gaTilBetaling: (betalingsreferanse: string) => void;
    hentKjoringensArtListe: (kuid: string, kundeId: string) => void;
    fjernAlleMeldinger?: () => void;
    deleteForstegangsregistreringArkivdokument?: (sakId: string, dokumentId: number) => Promise<any>;
    editForstegangsregistreringArkivdokument?: (sakId: string, dokument: IArkivdokument) => Promise<any>;
    nyMelding?: (melding: IMelding) => Promise<any>;
    sendTilSaksbEllerTrekkTilbake?: (saksinformasjon: IRegistreringInformasjon, avsluttSakIndikator: AvsluttSakIndikator) => any;
    resetKjennemerkeserierOgTyper: () => void;
    submitOgLastOppDokument: (navigate: NavigateType, saksinformasjon: IRegistreringInformasjon, avsluttSakIndikator: AvsluttSakIndikator, fileWithMeta: IArkivdokument)
        => Promise<ISak<IRegistreringInformasjon>>;
}

interface IForstegangsregistreringState {
    submitted: boolean;
    submittedType?: AvsluttSakIndikator;
    validationErrors: string[];
    fremdriftsindikator: FremdriftsindikatorStatus;
    saksinformasjon: Partial<IRegistreringInformasjon>;
    vilkar: IVilkar[];
    skalHaCustomConfirmNavigation: boolean;
}

type PropsType = IForstegangsregistreringStateProps & IForstegangsregistreringDispatchProps & IAkConfirmNavigationProps & WithRouterProps;

class Forstegangsregistrering extends Component<PropsType, IForstegangsregistreringState> {

    public constructor(props: PropsType) {
        super(props);

        const sak = this.props.sak;
        const saksinformasjon = {
            melder: this.props.melder,
            nyEier: { aktorType: AktorType.NY_EIER },
            saksType: SaksType.FORSTEGANGSREGISTRERING,
            kjennemerke: {} as INyttKjennemerke,
            vognkortsaksinformasjon: {
                forsendelsesadresse: null
            },
            ...(sak?.saksinformasjon || {})
        };
        const vilkar = ForstegangsregistreringRegler.utledVilkar(sak, this.props.vilkarstatus, saksinformasjon);
        this.state = {
            submitted: false,
            submittedType: null,
            validationErrors: [],
            saksinformasjon,
            vilkar,
            fremdriftsindikator: utledFremdriftsstatus(sak),
            skalHaCustomConfirmNavigation: this.skalHaCustomConfirmNavigationModal(vilkar)
        };
    }

    private skalHaCustomConfirmNavigationModal = (vilkarer: IVilkar[]): boolean => {
        return _.find(vilkarer, vilkar => vilkar.navn === Vilkarsnavn.DOKUMENTASJON)?.status === VilkarStatus.MA_GODKJENNES;
    };

    public componentDidMount(): void {
        const sakId = this.props.params.sakId;
        this.props.reset();

        if (sakId) {
            this.props.get(sakId);
            this.props.hentAktor(this.props.melder.organisasjonsnummer);
        } else if (harApenSakAvType(SaksType.FORSTEGANGSREGISTRERING, this.props.kjoretoy)) {
            this.props.navigate(`/kjoretoy/${this.props.kjoretoy.kuid}`);
        } else {
            this.props.hentAktor(this.props.melder.organisasjonsnummer, AktorType.MELDER, this.props.kjoretoy.kuid);
        }
    }

    public componentDidUpdate(prevProps: PropsType, prevState: IForstegangsregistreringState): void {

        const newState = {} as IForstegangsregistreringState;
        const saksinformasjon = this.state.saksinformasjon;
        const kunder = this.props.kunder;
        const { nyEier, nyMedeier } = this.state.saksinformasjon;
        const prevNyEier = prevState.saksinformasjon.nyEier;
        const prevNyMedeier = prevState.saksinformasjon.nyMedeier;

        const fjernetEierEllerMedeier = (prevNyEier !== null && nyEier === null) || (prevNyMedeier !== null && nyMedeier === null);
        const endretEierEllerMedeier = !_.isEqual(prevNyEier?.kundeId, nyEier?.kundeId) || !_.isEqual(prevNyMedeier?.kundeId, nyMedeier?.kundeId);

        const finnesNyEierOgNyMedeierIkkeMyndigMerknad = Merknader.exists(this.props.sak.merknader, { kode: RegelmerknaderKoder.NY_EIER_OG_NY_MEDEIER_IKKE_MYNDIG });
        if (endretEierEllerMedeier && finnesNyEierOgNyMedeierIkkeMyndigMerknad) {
            this.props.reset();
        }

        if (fjernetEierEllerMedeier) {
            this.fjernForsikringsKnytningTilForrigeEierskap();
        }

        if (!this.props.params.sakId && harApenSakAvType(SaksType.FORSTEGANGSREGISTRERING, this.props.kjoretoy)) {
            this.props.navigate(`/kjoretoy/${this.props.kjoretoy.kuid}`);
        }

        if (!_.isEqual(this.props.sak.saksinformasjon, prevProps.sak.saksinformasjon)) {
            newState.saksinformasjon = {
                ...saksinformasjon,
                ...this.props.sak.saksinformasjon,
                leasingtaker: this.props.sak.saksinformasjon.leasingtaker,
                underenhet: this.props.sak.saksinformasjon.underenhet,
                vognkortsaksinformasjon: this.props.sak.saksinformasjon.vognkortsaksinformasjon
            };
        }

        if (this.props.sak.saksinformasjon.nyEier?.kundeId && !prevProps.sak.sakId && this.props.sak.sakId) {
            this.props.hentKjoringensArtListe(this.props.sak.kuid, this.props.sak.saksinformasjon.nyEier.kundeId);
        }

        if (!_.isEqual(this.props.vilkarstatus, prevProps.vilkarstatus)
            || !_.isEqual(this.props.sak, prevProps.sak)
            || !_.isEqual(saksinformasjon, prevState.saksinformasjon)) {
            newState.vilkar = ForstegangsregistreringRegler.utledVilkar(this.props.sak, this.props.vilkarstatus, saksinformasjon);
        }

        if (utledFremdriftsstatus(this.props.sak) !== this.state.fremdriftsindikator && !this.props.isLoading) {
            newState.fremdriftsindikator = utledFremdriftsstatus(this.props.sak);
        }
        if (this.skalOppdatereAktorMedKundedata(saksinformasjon.nyEier)) {
            newState.saksinformasjon = {
                ...(newState.saksinformasjon || saksinformasjon),
                nyEier: leggTilDataFraKundePaAktor(saksinformasjon.nyEier, kunder)
            };
        }

        if (this.skalOppdatereAktorMedKundedata(saksinformasjon.melder)) {
            newState.saksinformasjon = {
                ...(newState.saksinformasjon || saksinformasjon),
                melder: leggTilDataFraKundePaAktor(saksinformasjon.melder, kunder)
            };
        }

        if (this.skalOppdatereAktorMedKundedata(saksinformasjon.nyMedeier)) {
            newState.saksinformasjon = {
                ...(newState.saksinformasjon || saksinformasjon),
                nyMedeier: leggTilDataFraKundePaAktor(saksinformasjon.nyMedeier, kunder)
            };
        }

        if (!this.props.isLoading) {

            if (_.isEmpty(prevProps.kjennemerkeserierOgTyper.error) && !_.isEmpty(this.props.kjennemerkeserierOgTyper.error)) {
                newState.saksinformasjon = {
                    ...(newState.saksinformasjon || saksinformasjon),
                    kjennemerke: {
                        kjennemerkeType: null,
                        kjennemerkeserie: null
                    }
                };
            }

            if (!_.isEqual(prevProps.kjennemerkeserierOgTyper, this.props.kjennemerkeserierOgTyper) && this.props.kjennemerkeserierOgTyper.kjennemerkeTyper.length === 1) {
                const defaultKjennemerkeserie = utledDefaultKjennemerkeserie(this.props.kjennemerkeserierOgTyper);
                const kjennemerkeserieFraState = _.find(this.props.kjennemerkeserierOgTyper.kjennemerkeserier, serie => serie === this.state.saksinformasjon.kjennemerke.kjennemerkeserie);

                this.oppdaterKjennemerke({
                    ...(newState.saksinformasjon?.kjennemerke || saksinformasjon?.kjennemerke),
                    kjennemerkeType: this.props.kjennemerkeserierOgTyper.kjennemerkeTyper[0],
                    kjennemerkeserie: kjennemerkeserieFraState || defaultKjennemerkeserie
                }, OppdaterKjennemerkeCallSourceComponent.FORSTEGANGSREGISTRERING);
            }

        }

        if (!_.isEqual(prevState.saksinformasjon.kjennemerke, this.state.saksinformasjon.kjennemerke)) {
            this.kontrollerVilkar();
        }

        const customConfirmNavigationModal = this.skalHaCustomConfirmNavigationModal(newState.vilkar);
        if (this.state.skalHaCustomConfirmNavigation !== customConfirmNavigationModal) {
            this.setConfirmNavigationKeys(customConfirmNavigationModal);
            this.props.confirmNavigation(true);
            newState.skalHaCustomConfirmNavigation = customConfirmNavigationModal;
        }

        const stateHasChanged: boolean = !_.isEmpty(newState) && (
            !_.isMatch(this.state, newState) ||
            !_.isEqual(this.state.vilkar, newState.vilkar));

        if (stateHasChanged) {
            this.setState(newState);
        }
    }

    public componentWillUnmount(): void {
        this.props.confirmNavigation(false);
        if (this.props.sak && !this.props.sak.sakId) {
            this.fjernForsikringsKnytningTilForrigeEierskap();
        }
        this.props.reset();
        this.props.fjernAlleMeldinger();
        this.props.resetKjennemerkeserierOgTyper();
    }

    public render(): React.ReactElement {
        const { sak, kjoretoy, lesemodus, vilkarstatus } = this.props;
        const { kjoringensArt, neringskode, nyEier, nyMedeier, kjennemerke } = this.state.saksinformasjon;
        const kravTilVektarsavgift = ForstegangsregistreringRegler.erKravTilVektarsavgift(this.props.kjoretoy);

        if (this.props.isLoading && !sak.isLoadingModalOpen) {
            return <AkLoading />;
        }

        return (
            <div className="container row no-gutters mx-auto mb-4">
                <header className="row col-12 align-items-end mb-3 no-gutters">
                    <h1 className="col-6 col-lg-4"><FormattedMessage id={this.utledOverskrift()} values={softHyphen} /></h1>
                    <AkFremdriftsindikator fremdriftsStatusIndex={this.state.fremdriftsindikator} tekstKeys={FREMDRIFTSSTATUS_TEKSTER}
                        fremdriftsindikatorStyleClassName="col-lg-4 col-5" showError={Saksstatus.AVBRUTT === sak.saksstatus} errorKey="sak.oppgavestatus.AVBRUTT" />
                    <div className="ak-knapperad col-auto row ml-auto no-gutters justify-content-end">
                        {this.renderKnapperad()}
                    </div>
                </header>
                <KjennemerkeAlleredeIBrukModal sak={this.props.sak} />
                <ForstegangsregistreringStatusbarConnected sak={sak} saksinformasjon={this.state.saksinformasjon} confirmNavigation={this.props.confirmNavigation} />
                <ForstegangsregistreringMeldingerConnected {...this.state} kjennemerkeSerierOgTyper={this.props.kjennemerkeserierOgTyper} />
                <AkOrdrenummer onChange={this.oppdaterOrdrenummer} value={this.state.saksinformasjon?.ordrenr || sak.ordrenr}
                    cointainerClassName="mb-3" disabled={sakErAvluttetAvbrutt(sak.saksstatus) || this.props.sak.erTilGodkjenning} />

                {(nyEier && typeof nyEier.harGyldigAdresse === 'boolean' && !nyEier.harGyldigAdresse) && !nyEier.ikkeVisKunde &&
                    <AdressePanel onSubmitFn={this.handleKundeadresseSubmit} customPanelClassNames="col-12" customInnholdClassNames="col-6"
                        customContainerClassNames="col-auto" onErrorFn={this.handleKundeadresseError}
                        notEditable={this.props.sak.saksstatus === Saksstatus.AVSLUTTET || this.props.isFlateeier} required={true}
                        kundeadresse={_.get(this.props.sak, 'saksinformasjon.vognkortsaksinformasjon.forsendelsesadresse', null)} />
                }

                <ForstegangsregistreringAktorerConnected lesemodus={lesemodus || this.props.isFlateeier} submitted={this.state.submitted} errors={this.state.validationErrors} kjoretoy={kjoretoy}
                    saksinformasjon={this.state.saksinformasjon} oppdaterAktor={this.oppdaterAktor} />

                <KjoretoyInfoPanel kjoretoy={kjoretoy} />

                {this.aktorHarOrgnrEllerErPerson(nyEier) &&
                    <>
                        <div className="col-12 col-md-6 pr-md-4 mb-3">
                            {sak.sakId && !this.props.isFlateeier &&
                                <Panel panelCssClass="w-100 mb-0 pb-2">
                                    <FormattedMessage id={'sak.overskrift.bruk'} tagName="h2" />
                                    <KjoretoyBrukConnected ugyldigeKjoringensArt={[KjoringensArt.TRAKTOR_MED_BRUKSBEGRENSNING]}
                                        lesemodus={lesemodus || this.isEierUverfisertOgHarGodkjenningsstatusGodkjent()}
                                        neringskode={neringskode}
                                        kjoringensArt={kjoringensArt} errors={this.state.validationErrors}
                                        oppdaterBruk={this.oppdaterBruk} eier={nyEier} erForstegangsregistrering={true} inputContainerClassName="mb-2" />
                                </Panel>
                            }
                            <LeasingtakerUnderenhetConnected submitted={this.state.submitted} saksstatus={sak.saksstatus} nyEier={nyEier} nyMedeier={nyMedeier}
                                saksinformasjon={this.state.saksinformasjon}
                                lesemodus={lesemodus} errors={this.state.validationErrors} oppdaterLeasingtakerOgUnderenhet={this.oppdaterLeasingtakerOgUnderenhet} />
                        </div>
                        {this.skalViseVilkarsPanel() && !this.props.isLoading &&
                            <AkVilkarPanel containerClassName={this.props.sak.sakId ? 'col col-md-6 col-12 mb-auto' : 'col-md-6 col-12 ml-auto'}
                                overskriftOppfylt={{ id: 'sak.vilkar.overskriftOppfylt' }}
                                overskriftIkkeOppfylt={{ id: 'sak.vilkar.overskriftIkkeOppfylt' }} vilkar={this.state.vilkar} error={vilkarstatus.error} loading={vilkarstatus.isLoading}
                                errorRetryFn={this.kontrollerVilkar} rowClickHandler={this.handleVilkarClick} />
                        }
                        {sak.sakId && kravTilVektarsavgift &&
                            <AkVektarsavgiftPanel grunnlagVektarsavgift={this.state.saksinformasjon.grunnlagVektarsavgift} erKravTilVektarsavgift={kravTilVektarsavgift}
                                tilhengerinfo={utledTilhengerinfo(this.props.kjoretoy.godkjenning)} vektarsavgiftUrl={this.props.vektarsavgiftUrl}
                                tilhengervektOverskriderTillattTilhengervekt={TilhengervektRegler
                                    .overskriderOppgittTilhengervektTillattTilhengervekt(this.props.kjoretoy.godkjenning, this.state.saksinformasjon.grunnlagVektarsavgift?.totalvektTilhenger)}
                                oppdaterGrunnlagVektarsavgift={this.oppdaterVektarsavgift} lesemodus={lesemodus || this.props.isFlateeier} customClassNames={'col-12'}
                                submitted={this.state.submitted} />
                        }
                        <AkDrosjePanel
                            panelCssClass="col-12 mt-3"
                            showPanel={this.skalViseDrosjePanel()}
                            drosjeloyvenummer={this.state.saksinformasjon.drosjeloyvenummer}
                            lesemodus={this.props.lesemodus || this.props.isFlateeier}
                            submitted={this.state.submitted}
                            updateDrosjeloyvenummer={this.oppdaterDrosjeloyvenummer} />
                        {this.skalViseKjennemerkePanel(this.state.saksinformasjon) &&
                            <RegistreringKjennemerkePanelConnected
                                kjennemerke={kjennemerke}
                                updateKjennemerke={this.oppdaterKjennemerke}
                                hentKjennemerkeserierOgTyper={this.hentKjennemerkeserierOgTyper}
                                kjoringensArt={kjoringensArt} />
                        }
                        {this.skalViseKontrolliste() && this.renderKontrolliste()}
                        {this.skalViseDokumenterPanel() &&
                            <DokumenterPanel lesemodus={this.props.lesemodus}
                                getSakId={this.getSakId}
                                arkivdokumenter={this.props.sak.arkivdokumenter || []}
                                kunder={this.props.kunder}
                                obligatoriskeDokumenter={utledObligatoriskeDokumenter(this.props.vilkarstatusMerknaderOgSakMerknader)}
                                lastOppFil={this.lagreSakOgLastOppDokument}
                                lastOppFilError={this.viseFilErrormessage}                                endreArkivdokument={this.props.editForstegangsregistreringArkivdokument}
                                slettArkivdokument={this.props.deleteForstegangsregistreringArkivdokument} />
                        }
                    </>
                }
                <div className="ak-knapperad col-auto row ml-auto no-gutters mt-2 mt-md-0 d-md-none">
                    {this.renderKnapperad()}
                </div>
            </div>
        );
    }

    private skalViseKontrolliste(): boolean {
        return !this.props.isFlateeier && _.some(this.props.sak.arkivdokumenter,
            arkDok => _.some(arkDok.saksdokumenter, skalVisesIKontrollpanel));
    }

    private renderKontrolliste = () => {
        const saksdokumenter = utledVisningsdokumenterTilKontrollisteFraArkivdokumenter(this.props.sak.arkivdokumenter);
        return <AkKontrollistePanel dokumenterSomErTilGodkjenning={saksdokumenter} erLesemodus={true} />;
    };

    private skalViseDokumenterPanel(): boolean {
        return this.props.sak.sakId
            && !this.props.isFlateeier
            && this.finnesObligatoriskeDokumenter(this.props.vilkarstatusMerknaderOgSakMerknader);
    }

    private getSakId = () => {
        return Promise.resolve(this.props.sak.sakId);
    };

    private renderKnapperad = () => {
        return (
            <ForstegangsregistreringKnapperadConnected
                oppdaterError={this.oppdaterError} lagreSak={this.lagreSak} endreSak={this.submit} saksinformasjon={this.state.saksinformasjon}
                loading={this.props.isLoading} obligatoriskDokumenterKrav={this.finnesObligatoriskeDokumenterKrav(this.props.sak.merknader)} avbrytSak={this.avbrytSak}
                submitted={this.state.submitted} alleDokumenterErTilVurdering={this.alleDokumenterErTilVurdering()}/>
        );
    };

    private lagreSakOgLastOppDokument = (sakId: string, file: IArkivdokument) => {
        return this.props.submitOgLastOppDokument(this.props.navigate, this.state.saksinformasjon, AvsluttSakIndikator.IKKE_AVSLUTT, file);
    };

    private oppdaterOrdrenummer = (ordrenr: string) => {
        this.props.confirmNavigation(true);
        this.setState({ saksinformasjon: { ...this.state.saksinformasjon, ordrenr } });
    };

    private oppdaterAktor = (aktor: IAktor, slett?: boolean) => {
        if (Merknader.exists(aktor, { type: RegelmerknaderType.KRITISK })) {
            return;
        }
        this.props.confirmNavigation(true);
        this.setState({ submitted: false });
        if (aktor.aktorType === AktorType.MELDER) {
            this.oppdaterMelder(aktor);
        } else if (aktor.aktorType === AktorType.NY_EIER) {
            this.oppdaterNyEier(aktor);
        } else if (aktor.aktorType === AktorType.NY_MEDEIER) {
            this.oppdaterNyMedeier(aktor, slett);
        }
    };

    private oppdaterMelder = (melder: IAktor) => {
        this.setState({ saksinformasjon: { ...this.state.saksinformasjon, melder }, validationErrors: [] });
    };

    private oppdaterNyEier = (nyEier: IAktor) => {
        if (nyEier && !nyEier.kundeId) {
            this.setState({
                saksinformasjon: {
                    ...this.state.saksinformasjon,
                    nyEier,
                    nyMedeier: null,
                    kjoringensArt: null,
                    neringskode: null,
                    neringskodeBeskrivelse: null
                }, validationErrors: []
            });
            return;
        }

        this.oppdaterAktorPaStateOgKontrollerVilkar({ ...this.state.saksinformasjon, nyEier }, harByttetAktor(nyEier, this.state.saksinformasjon.nyEier));
    };

    private oppdaterNyMedeier = (nyMedeier: IAktor, slett) => {
        if (slett) {
            return this.setState({ saksinformasjon: { ...this.state.saksinformasjon, nyMedeier: null }, validationErrors: [] });
        }

        if (nyMedeier.kundeId === this.state.saksinformasjon.nyEier.kundeId) {
            const eiereLikeError = 'forstegangsregistrering.validering.NY_EIER_OG_NY_MEDEIER_KAN_IKKE_VAERE_LIKE';
            return this.oppdaterError([eiereLikeError]);
        }

        this.oppdaterAktorPaStateOgKontrollerVilkar({ ...this.state.saksinformasjon, nyMedeier }, harByttetAktor(nyMedeier, this.state.saksinformasjon.nyMedeier));
    };

    private oppdaterAktorPaStateOgKontrollerVilkar = (saksinformasjon: Partial<IRegistreringInformasjon>, skalKontrollere = true) => {
        this.setState({ saksinformasjon, validationErrors: [] }, () => {
            if (skalKontrollere) {
                this.kontrollerVilkar();
            }
        });
    };

    private oppdaterBruk = (brukPartial: Partial<IRegistreringInformasjon>) => {
        const { kjoringensArt, nyEier } = this.state.saksinformasjon;
        const erKjoringensArtEndret = harEndretKjoringensArt(kjoringensArt, brukPartial.kjoringensArt, nyEier, this.props.kjoretoy, this.props.kjoringensArtListe.innslag);

        if (erKjoringensArtEndret) {
            this.props.confirmNavigation(true);
            utledOgSettDrosjeloyvenummer(brukPartial);
        }

        const kjennemerke = this.skalNullstilleKjmPaState(erKjoringensArtEndret, brukPartial) ? {} as INyttKjennemerke : this.state.saksinformasjon.kjennemerke;

        this.setState({
            saksinformasjon: {
                ...this.state.saksinformasjon,
                ...brukPartial,
                kjennemerke
            },
            validationErrors: [],
            submitted: false
        }, () => {
            if (erKjoringensArtEndret) {
                this.kontrollerVilkar();
            }
        });

    };

    private skalNullstilleKjmPaState = (erKjoringensArtEndret: boolean, brukPartial: Partial<IRegistreringInformasjon>) => {
        return erKjoringensArtEndret && !this.skalViseKjennemerkePanel({ ...this.state.saksinformasjon, ...brukPartial });
    };

    private oppdaterKjennemerke = (kjennemerke: Partial<INyttKjennemerke>, callSource: OppdaterKjennemerkeCallSourceComponent) => {
        const kallesPaFraKjennemerkekomponent = callSource !== OppdaterKjennemerkeCallSourceComponent.FORSTEGANGSREGISTRERING;
        const harByttetKjennemerkeserie = !_.isEqual(this.state.saksinformasjon.kjennemerke.kjennemerkeserie, kjennemerke.kjennemerkeserie);

        const shouldConfirmNavigation = kallesPaFraKjennemerkekomponent && harByttetKjennemerkeserie;

        if (shouldConfirmNavigation) {
            this.props.confirmNavigation(true);
        }

        const saksinformasjon = {
            ...this.state.saksinformasjon,
            kjennemerke: { ...this.state.saksinformasjon.kjennemerke, ...kjennemerke }
        };

        this.setState({
            saksinformasjon,
            vilkar: ForstegangsregistreringRegler.utledVilkar(this.props.sak, this.props.vilkarstatus, this.state.saksinformasjon),
            submitted: false
        });
    };

    private oppdaterVektarsavgift = (grunnlagVektarsavgift: Partial<IGrunnlagVektarsavgift>) => {
        if (!_.isUndefined(this.state.saksinformasjon.grunnlagVektarsavgift) &&
            !_.isEqual(this.state.saksinformasjon.grunnlagVektarsavgift, grunnlagVektarsavgift)) {
            this.props.confirmNavigation(true);
        }

        this.setState({
            saksinformasjon: {
                ...this.state.saksinformasjon,
                grunnlagVektarsavgift: { ...this.state.saksinformasjon.grunnlagVektarsavgift, ...grunnlagVektarsavgift }
            },
            submitted: false
        });
    };

    private oppdaterLeasingtakerOgUnderenhet = (endring: ILeasingtakerOgUnderenhet) => {
        this.setState({
            saksinformasjon: { ...this.state.saksinformasjon, ...endring },
            submitted: false
        });
    };

    private hentKjennemerkeserierOgTyper = (kuid: string, kjoringensArt: string) => {
        return this.props.hentKjennemerkeserierOgTyper(kuid, kjoringensArt);
    };

    private oppdaterError = (errors: string[]) => {
        this.setState({ validationErrors: errors, submitted: true });
    };

    private submit = (avsluttSakIndikator: AvsluttSakIndikator) => {
        let errors: string | string[];
        if (avsluttSakIndikator === AvsluttSakIndikator.AVSLUTT_MED_VEDTAK) {
            errors = ForstegangsregistreringValidator.validerFattVedtak(this.props.sak, this.props.vilkarstatus, this.state.saksinformasjon, this.props.kjoretoy);
        } else if (avsluttSakIndikator === AvsluttSakIndikator.AVSLUTT_UTEN_VEDTAK) {
            errors = ForstegangsregistreringValidator.validerDokumenterTilSaksbehandler(this.props.sak);
        } else {
            errors = ForstegangsregistreringValidator.validerOpprettSak(this.state.saksinformasjon, this.props.kjoretoy);
        }

        this.setState({ validationErrors: errors, submitted: true, submittedType: avsluttSakIndikator });

        if (!errors.length) {
            this.props.confirmNavigation(false);
            if (this.finnesObligatoriskeDokumenterKrav(this.props.sak.merknader)) {
                this.props.sendTilSaksbEllerTrekkTilbake(this.state.saksinformasjon, AvsluttSakIndikator.IKKE_AVSLUTT);
            } else {
                this.props.submit(this.props.navigate, this.state.saksinformasjon, avsluttSakIndikator);
            }
        }
    };

    private lagreSak = () => {
        const errors = ForstegangsregistreringValidator.getErrorsOgValiderAktorer(this.state.saksinformasjon);
        this.setState({ validationErrors: errors, submitted: true });
        if (!errors.length) {
            this.props.confirmNavigation(false);
            this.props.submit(this.props.navigate, this.state.saksinformasjon, AvsluttSakIndikator.IKKE_AVSLUTT);
        }
    };

    private avbrytSak = () => {
        this.props.confirmNavigation(false);
        this.props.avbryt(this.props.navigate, this.props.sak.sakId, this.props.kjoretoy.kuid, this.state.saksinformasjon);
    };

    private fjernForsikringsKnytningTilForrigeEierskap = () => {
        const saksinformasjon = this.state.saksinformasjon;
        if (!this.props.lesemodus && saksinformasjon.nyEier && saksinformasjon.nyEier.kundeId) {
            this.props.fjernForsikringsKnytningMellomEierOgKjoretoy(this.props.kjoretoy.kuid, saksinformasjon);
        }
    };

    private finnesObligatoriskeDokumenter = (merknader: IMerknad[]): boolean => {
        return _.some(merknader, merknad => merknad.obligatoriskeDokumenter?.length > 0);
    };

    private finnesObligatoriskeDokumenterKrav = (merknader: IMerknad[]): boolean => {
        return _.some(merknader, merknad => _.some(merknad.obligatoriskeDokumenter, dokument => !dokument?.innfridd));
    };

    private alleDokumenterErTilVurdering = () => !!this.props.sak.arkivdokumenter?.flatMap(arkDok => arkDok.saksdokumenter).every(dokument => dokument.godkjentStatus === VilkarstatusSaksdokument.TIL_VURDERING);

    private kontrollerVilkar = () => {
        this.props.hentVilkar(this.state.saksinformasjon);
    };

    private handleVilkarClick = (event: React.UIEvent, row: IVilkar) => {
        if (typeof row.navn !== 'string' && row.navn.id.match('avgift')) {
            const avgiftVilkar = _.find(this.props.vilkarstatus.vilkar, { navn: 'AVGIFT' });
            if (avgiftVilkar && avgiftVilkar.tilleggsinformasjon) {
                this.props.gaTilBetaling(avgiftVilkar.tilleggsinformasjon as string);
            }
        }
    };

    private oppdaterDrosjeloyvenummer = (drosjeloyvenummer: string) => {
        if (!_.isUndefined(this.state.saksinformasjon.drosjeloyvenummer)
            && !_.isEqual(this.state.saksinformasjon.drosjeloyvenummer, drosjeloyvenummer)) {
            this.props.confirmNavigation(true);
        }

        this.setState({
            saksinformasjon: { ...this.state.saksinformasjon, drosjeloyvenummer: drosjeloyvenummer },
            submitted: false
        });
    };

    private skalViseDrosjePanel = (): boolean => {
        return this.state.saksinformasjon.kjoringensArt === KjoringensArt.DROSJE_EIER.valueOf() || this.state.saksinformasjon.kjoringensArt === KjoringensArt.SPESINNR_HCP_LP.valueOf();
    };

    private skalViseKjennemerkePanel = (saksinformasjon: IRegistreringInformasjon) =>
        aktorerHarGodkjent([saksinformasjon.nyEier, saksinformasjon.nyMedeier])
        && _.isEmpty(ForstegangsregistreringRegler
            .utledKritiskeMeldingerMaFullforesPaTrafikkstasjon(saksinformasjon, this.props.gyldigeForhandlereSvalbard, this.props.brukerprofil, this.props.kjoretoy))
        && !this.props.lesemodus
        && !this.props.isFlateeier;

    private skalOppdatereAktorMedKundedata = (aktor: IAktor): boolean => {
        return this.harKundeId(aktor) &&
            !this.lasterKundeobjektForAktor(aktor) &&
            this.manglerInformasjonFraKundeobjekt(aktor);
    };

    private harKundeId(aktor: IAktor): boolean {
        return !!aktor?.kundeId;
    }

    private manglerInformasjonFraKundeobjekt(aktor: IAktor) {
        return this.manglerVerdiForGyldigAdresse(aktor) || !this.aktorHarOrgnrEllerErPerson(aktor);
    }

    private manglerVerdiForGyldigAdresse(aktor: IAktor) {
        return aktor.harGyldigAdresse === undefined;
    }

    private lasterKundeobjektForAktor(aktor: IAktor) {
        return (_.get(this.props.kunder, aktor.kundeId) || { isLoading: true }).isLoading;
    }

    private aktorHarOrgnrEllerErPerson = (aktor: IAktor) => {
        return aktor && (!!aktor.organisasjonsnummer || this.props.kunder[aktor.kundeId]?.erPerson);
    };

    private skalViseVilkarsPanel = () => !this.props.kjennemerkeserierOgTyper.isLoading && (!this.props.lesemodus || this.props.sak?.erTilGodkjenning)
        && (this.props.sak.sakId || (this.aktorHarOrgnrEllerErPerson(this.state.saksinformasjon.nyEier) && this.aktorHarOrgnrEllerErPerson(this.state.saksinformasjon.melder)));

    private isEierUverfisertOgHarGodkjenningsstatusGodkjent = () => Merknader.exists(this.props.kjoretoy.merknader, { kode: RegelmerknaderKoder.EIER_UVERIFISERT_KUNDE });

    private setConfirmNavigationKeys = (skalHaCustomKeys: boolean) => {
        if (skalHaCustomKeys) {
            this.props.setCustomConfirmText({ bodyKey: 'forstegangsregistrering.modal.avbrytSakMedDokumenter.beskrivelse' });
        } else {
            this.props.setCustomConfirmText({ bodyKey: undefined });
        }
    };

    private utledOverskrift = (): string => {
        if (this.props.kjoretoy.godkjenning
            && this.props.kjoretoy.godkjenning.forstegangsgodkjenning
            && this.props.kjoretoy.godkjenning.forstegangsgodkjenning.bruktimport) {
            return 'forstegangsregistrering.bruktimport.overskrift';
        }

        return 'forstegangsregistrering.overskrift';
    };

    private handleKundeadresseError = (errors: string[]) => {
        if (!!errors.length) {
            this.oppdaterError(_.concat(this.state.validationErrors, 'generell.validering.tekst.skjemafeil'));
        }
    };

    private handleKundeadresseSubmit = (kundeadresse: IKundeAdresse) => {
        this.setState(prevState => ({
            saksinformasjon: {
                ...prevState.saksinformasjon,
                vognkortsaksinformasjon: {
                    forsendelsesadresse: kundeadresse
                }
            },
            validationErrors: []
        }));
    };

    private viseFilErrormessage = (error: IMessageDescriptor) => {
        return this.props.nyMelding({ meldingIntlId: error.id, meldingType: MeldingType.DANGER });
    };
}

const forhandlerAsAktor = (brukerprofil: Brukerprofil) => {
    const forhandler = brukerprofil.valgtEnhet;
    const organisasjonsnummer = erHovedenhetInnlogget(forhandler) ? forhandler.hovedenhetOrgNummer : forhandler.underenhetOrgNummer;
    const kundeId = erHovedenhetInnlogget(forhandler) ? forhandler.hovedenhetKundeId : forhandler.underenhetKundeId;

    return {
        navn: forhandler.navn,
        kanEndreAktor: false,
        aktorType: AktorType.MELDER,
        organisasjonsnummer,
        kundeId,
        godkjenningsstatus: AktorGodkjenningstatus.GODKJENT,
        godkjenningUtfortAvID: kundeId,
        godkjenningUtfort: GodkjenningUtfortType.GODKJENNING_I_FORHANDLERKANAL
    };
};

const mapStateToProps = (state: RootStateType): IForstegangsregistreringStateProps => ({
    sak: state.sak,
    kjoringensArtListe: state.kjoringensArtListe,
    kjoretoy: state.kjoretoy,
    vilkarstatus: state.vilkarstatus,
    isLoading: state.kjoretoy.isLoading || state.sak.isLoading || state.kjoringensArtListe.isLoading || state.vilkarstatus.isLoading,
    lesemodus: sakErAvluttetAvbrutt(state.sak.saksstatus) || state.sak.erTilGodkjenning,
    melder: forhandlerAsAktor(state.brukerprofil),
    kunder: state.kunder,
    kjennemerkeserierOgTyper: state.kjennemerkeserierOgTyper,
    vektarsavgiftUrl: (state.globals.klientKonfigurasjon.linker || {}).vektarsavgift,
    isFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    vilkarstatusMerknaderOgSakMerknader: vilkarstatusMerknaderOgSakMerknaderSelector(state),
    gyldigeForhandlereSvalbard: state.globals.klientKonfigurasjon.orgNrForhandlereSomKanHandtereSvalbard,
    brukerprofil: state.brukerprofil
});

const mapDispatchToProps = (dispatch): IForstegangsregistreringDispatchProps => ({
    get: (sakId) => dispatch(hentForstegangsregistrering(sakId)),
    hentAktor: (orgFnr, aktorType?: AktorType, kuid?: string) => dispatch(getKunde({ orgFnr }, aktorType, kuid, SaksType.FORSTEGANGSREGISTRERING)),
    reset: () => dispatch(resetSak()),
    avbryt: (navigate: NavigateType, sakId: string, kuid: string, saksinformasjon: IRegistreringInformasjon) => dispatch(avbrytForstegangsregistrering(navigate, sakId, kuid, saksinformasjon)),
    hentKjennemerkeserierOgTyper: (kuid, kjoringensArt) => dispatch(hentKjennemerkeserierOgTyper(kuid, kjoringensArt)),
    hentKjoringensArtListe: (kuid: string, kundeId: string) => dispatch(hentKjoringensArtListe(kuid, kundeId)),
    hentVilkar: (saksinformasjon: IRegistreringInformasjon) => dispatch(kontrollerVilkar(saksinformasjon)),
    fjernForsikringsKnytningMellomEierOgKjoretoy: (kuid, saksinformasjon) => dispatch(fjernForsikringsKnytningMellomEierOgKjoretoy(kuid, saksinformasjon)),
    submit: (navigate: NavigateType, forstegangsregistering, avsluttSakIndikator) => dispatch(submitForstegangsregistrering(navigate, forstegangsregistering, avsluttSakIndikator)),
    gaTilBetaling: (betalingsreferanse: string) => dispatch(gaTilBetalingSkatt(betalingsreferanse)),
    fjernAlleMeldinger: () => dispatch(fjernAlleMeldinger()),
    deleteForstegangsregistreringArkivdokument: (sakId: string, dokumentId: number) => dispatch(deleteForstegangsregistreringArkivdokument(sakId, dokumentId)),
    editForstegangsregistreringArkivdokument: (sakId: string, dokument: IArkivdokument) => dispatch(editForstegangsregistreringArkivdokument(sakId, dokument)),
    nyMelding: (melding: IMelding) => dispatch(nyMelding(melding)),
    sendTilSaksbEllerTrekkTilbake: (forstegangsregistering, avsluttSakIndikator) => dispatch(endreSakTilGodkjenningRegistrering(forstegangsregistering, avsluttSakIndikator)),
    resetKjennemerkeserierOgTyper: () => dispatch(resetKjennemerkeserierOgTyper()),
    submitOgLastOppDokument: (navigate: NavigateType, forstegangsregistering, avsluttSakIndikator, arkivdokument: IArkivdokument) =>
        dispatch(submitForstegangsregistreringOgLastOppDokument(navigate, forstegangsregistering, avsluttSakIndikator, arkivdokument))
});

const ForstegangsregistreringConnected = AkConfirmNavigation(
    compose(connect(mapStateToProps, mapDispatchToProps),
        withRouter)
    (Forstegangsregistrering));

export type { PropsType as ForstegangregistreringPropsType };
export { ForstegangsregistreringConnected, Forstegangsregistrering };
