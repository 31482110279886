import type { IAkConfirmNavigationProps, IMessageDescriptor } from 'svv-tk-akr-common-frontend';
import { AkConfirmNavigation, AkKnapp, AkMeldingboks, AkMeldingboksType } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { Meldingsliste } from '../../components';
import type { Brukerprofil, IEierskifte, IFlyttkjoretoy, IForhandler, IKjoretoyList, IRegistrering, RootStateType } from '../../models/types';
import { kjoretoyListClearAction } from '../../state/actions';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';
import { KjoretoyInformationBulkConnected } from '../eierskifte';
import { FlyttingAvdelingerPanelConnected, FlyttkjoretoyProgressModalConnected } from '../flyttkjoretoy';
import { FlyttBekreftModal } from './flytt-bekreft-modal';

interface IFlyttkjoretoyDispatchProps {
    clearKjoretoyList: () => Promise<any>;
}

interface IFlyttKjoretoyState {
    visBekreftModal: boolean;
    flyttinger: IFlyttkjoretoy[];
    submitted: boolean;
    tilAktor: IForhandler;
    errorListe: any[];
    touched: boolean;
}

interface IFlyttkjoretoyStateProps {
    brukerprofil: Brukerprofil;
    registrering?: IRegistrering;
    eierskifte: IEierskifte;
    kjoretoyList: IKjoretoyList;
    match?: any;
}

class FlyttKjoretoy extends Component<IFlyttKjoretoyState & IAkConfirmNavigationProps & IFlyttkjoretoyStateProps & IFlyttkjoretoyDispatchProps & WithRouterProps> {
    public state: IFlyttKjoretoyState = {
        visBekreftModal: false,
        submitted: false,
        flyttinger: [],
        tilAktor: null,
        errorListe: [],
        touched: false
    };

    public componentWillUnmount(): void {
        this.props.clearKjoretoyList();
    }

    public render(): React.ReactElement {
        return (
            <div className="container row no-gutters mx-auto">
                <header className="row col-12 align-items-end mb-3 no-gutters">
                    <h1 className="col-12 col-lg-5">
                        <FormattedMessage id="flyttkjoretoy.overskrift.hoved" />
                    </h1>
                    {this.renderKnapperad()}
                </header>

                {(!this.props.kjoretoyList.kjoretoy.length || !!this.state.errorListe.length) && (
                    <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mb-4">
                        <Meldingsliste meldinger={this.utledFeilmeldinger()} />
                    </AkMeldingboks>
                )}
                <FlyttingAvdelingerPanelConnected errorListe={this.state.errorListe} touched={this.state.touched} oppdaterAktor={this.oppdaterTilAktor} oppdaterFraAktor={() => {return;}} />

                <KjoretoyInformationBulkConnected canDelete={true} initialAvdeling={this.props.brukerprofil.valgtEnhet} />

                <FlyttkjoretoyProgressModalConnected flyttinger={this.state.flyttinger} submitted={this.state.submitted} />
            </div>
        );
    }

    private renderKnapperad() {
        return (
            <div className="row ak-knapperad ml-auto col-md-6 col-lg-auto col-12">
                <div className="ak-knapperad ml-auto col-md-6 col-lg-auto col-12">
                    <AkKnapp type="ak-tilleggsknapp" action={this.lukk} intlKey={'generell.lukk'} />
                    <AkKnapp type="ak-hovedknapp" action={this.flyttKjoretoyModal} intlKey={'flyttkjoretoy.knapp.flyttKjoretoy'} />
                </div>
                {this.state.visBekreftModal && <FlyttBekreftModal lukkModal={this.closeModal} modalhandling={this.bekreftOgFlytt} tilAktorNavn={this.state.tilAktor.navn} />}
            </div>
        );
    }

    private lukk = () => {
        this.props.navigate('/startside');
    };

    private flyttKjoretoyModal = () => {
        if (!this.state.tilAktor) {
            this.setState({
                errorListe: [{ id: 'flyttkjoretoy.validering.maVelgeAvdeling' }],
                touched: true
            });
            return;
        }

        this.props.confirmNavigation(false);
        this.setState({
            errorListe: [],
            touched: true,
            visBekreftModal: true
        });
    };

    private bekreftOgFlytt = () => {
        this.closeModal();
        const flyttinger = [];

        this.props.kjoretoyList.kjoretoy.forEach((item) => {
            flyttinger.push({
                kjennemerke: item.registrering.kjennemerke.tegnkombinasjon,
                kuid: item.registrering.kuid,
                orgnrEier: this.props.brukerprofil.valgtEnhet.hovedenhetOrgNummer,
                kundeIdEier: this.props.brukerprofil.valgtEnhet.hovedenhetKundeId,
                orgnrNyUnderenhet: this.state.tilAktor.underenhetOrgNummer
            });
        });

        this.setState({ flyttinger: [...flyttinger], submitted: true });
    };

    private utledFeilmeldinger(): IMessageDescriptor[] {
        const feilmeldinger = [] as IMessageDescriptor[];

        if (!this.props.kjoretoyList.kjoretoy.length) {
            feilmeldinger.push({ id: 'flyttkjoretoy.validering.minstEttKjoretoy' });
        }

        return feilmeldinger.concat(this.state.errorListe);
    }

    private closeModal = () => {
        this.setState({ visBekreftModal: false });
    };

    private oppdaterTilAktor = (valgtAvdeling: IForhandler) => {
        this.props.confirmNavigation(true);
        this.setState({ tilAktor: valgtAvdeling, errorListe: [], touched: true });
    };
}

const mapDispatchToProps = (dispatch): IFlyttkjoretoyDispatchProps => ({
    clearKjoretoyList: () => dispatch(kjoretoyListClearAction())
});

const mapStateToProps = (state: RootStateType): IFlyttkjoretoyStateProps => ({
    registrering: state.kjoretoy.registrering,
    brukerprofil: state.brukerprofil,
    eierskifte: state.eierskifte,
    kjoretoyList: state.kjoretoyList
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, AkConfirmNavigation, withRouter);

const FlyttKjoretoyConnected = enhance(FlyttKjoretoy);

export { FlyttKjoretoyConnected };
