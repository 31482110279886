import * as React from 'react';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AkLoading, AkModal, type IKunderMap } from 'svv-tk-akr-common-frontend';
import type { IArkivdokument, IObligatoriskDokument } from '../../../models/types';
import Filtypevelger from './filtypevelger';
import Filvelger from './filvelger';
import ModalKnapper from './modal-knapper';

interface ISaksdokumentModalProps {
    fil: IArkivdokument;
    valgbareTyper?: IObligatoriskDokument[];
    submit: (filMeta: IArkivdokument) => Promise<string>;
    endre: (filMeta: IArkivdokument) => Promise<string>;
    avbryt: () => void;
    kunder?: IKunderMap;
}

const SaksdokumentModal: React.FC<ISaksdokumentModalProps> = ({ fil, valgbareTyper, submit, endre, avbryt, kunder }) => {
    const [saksdokumenter, setSaksdokumenter] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nyFil, setNyFil] = useState<IArkivdokument>();
    const [error, setError] = useState(null);

    React.useEffect(() => {
        const valgbareTyperTyper = valgbareTyper?.map(type => type.saksdokumentType);
        setNyFil(fil);
        setSaksdokumenter(fil?.saksdokumenter?.filter(saksdok => valgbareTyperTyper.includes(saksdok.saksdokumentType)) || []);
    }, [fil, valgbareTyper]);

    const submitFil = () => {
        if (!nyFil?.filnavn || !saksdokumenter?.length) {
            return setError({
                id: 'dokumenterpanel.modal.feil.validering'
            });
        }

        setLoading(true);
        const submitFn = !!nyFil?.id ? endre : submit;

        submitFn({
            ...nyFil,
            saksdokumenter: saksdokumenter.map(dok => ({
                ...dok,
                aktor: Object.keys(dok.aktor ?? {}).length ? dok.aktor : null
            }))
        })
            .catch(() => {
                setError({
                    id: 'dokumenterpanel.modal.feil.opplastingFeilet',
                    values: { errorId: error?.errorId || 'Ukjent' }
                });
            }).finally(() => {
            setLoading(false);
            setError(false);
        });
    };

    return <AkModal title={!nyFil?.id ? 'dokumenterpanel.modal.overskrift.lasteOpp' : 'dokumenterpanel.modal.overskrift.endre'} modalClassName="ak-fil-modal" showModal={!!nyFil} closeModal={avbryt}>
        {loading
         ? <AkLoading />
         : <>
             <Filvelger nyFil={nyFil} setNyFil={setNyFil} />
             <Filtypevelger
                 valgbareTyper={valgbareTyper}
                 saksdokumenter={saksdokumenter ?? []}
                 skalViseFeil={!!error}
                 kunder={kunder}
                 setSaksdokumenter={setSaksdokumenter}
             />
             <ModalKnapper avbryt={avbryt} id={nyFil?.id} submit={submitFil} />
             {!!error && !loading &&
                 <p className="ak-text-error ml-1">
                     <FormattedMessage {...error} />
                 </p>}
         </>}
    </AkModal>;
};

const isEqual = (prev: ISaksdokumentModalProps, next: ISaksdokumentModalProps) => JSON.stringify(prev) === JSON.stringify(next);

export default memo(SaksdokumentModal, isEqual);