export const openFile = (b64string: string, filnavn: string, type = 'application/pdf'): void => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const byteCharacters = atob(b64string);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const newBlob = new Blob([new Uint8Array(byteNumbers)], {type});

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    const TIMEOUT = 100;

    link.href = data;
    link.download = filnavn;
    link.click();
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(data);
    }, TIMEOUT);

};
