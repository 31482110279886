import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface IIndrePanelProps extends React.PropsWithChildren {
    overskrift: string;
    formatterValues?: any;
    innholdCssClass?: string;
    panelCssClass?: string;
}

class IndrePanel extends React.Component<IIndrePanelProps> {
    public render(): React.ReactNode {
        return (
            <div className={`ak-indre-panel ${this.props.panelCssClass || ''}`}>
                <div className="ak-indre-panel-header">
                    <h3 className="ak-indre-panel-overskrift">
                        <FormattedMessage id={this.props.overskrift} values={this.props.formatterValues} />
                    </h3>
                </div>
                <div className={`ak-indre-panel-innhold ${this.props.innholdCssClass || ''}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export { IndrePanel };
