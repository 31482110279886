import { AkKnapp } from 'svv-tk-akr-common-frontend';
import * as React from 'react';
import type { SyntheticEvent } from 'react';
import { Component } from 'react';

import { ArbeidslisteValg } from '../../models/kodeverk';

interface IValgButtonProps {
    valg: ArbeidslisteValg;
    first: boolean;
    handleAction: (valg: ArbeidslisteValg) => Promise<void> | void;
}

class ValgButton extends Component<IValgButtonProps> {

    public state = {
        loading: false
    };

    public render(): React.ReactElement {
        const {valg, first} = this.props;

        return (
            <AkKnapp loading={this.state.loading} intlKey={`sak.valg.${valg}`} title={`sak.valg.${valg}_tooltip`} action={this.handleClick}
                type={
                    valg === ArbeidslisteValg.GODKJENN ? 'ak-suksessknapp' :
                        valg === ArbeidslisteValg.AVVIS ? 'ak-tilleggsknapp' :
                            first ? 'ak-hovedknapp' : 'ak-tilleggsknapp'}
                icon={this.iconForValg(valg)} />
        );
    }

    private handleClick = (event?: SyntheticEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const action = this.props.handleAction(this.props.valg);

        if (action && (typeof action.then === 'function')) {
            this.setState({loading: true}, () => {
                action.then(() => {
                    this.setState({loading: false});
                });
            });
        }
    };
    private iconForValg = (valg: ArbeidslisteValg): string => {
        switch (valg) {
            case ArbeidslisteValg.GODKJENN:
                return 'fa-check';
            case ArbeidslisteValg.MIDLERTIDIG_VOGNKORT:
                return 'fa-print';
            case ArbeidslisteValg.AVVIS:
                return 'fa-times';
            default:
                return '';
        }
    };
}

export type { IValgButtonProps };
export { ValgButton };
