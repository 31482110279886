import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { IAkTextInputResult, IAktorPanelAktor, IAktorPanelSokProps } from 'svv-tk-akr-common-frontend';
import { AkLoading, AktorEndreKnapp, AktorPanelSok } from 'svv-tk-akr-common-frontend';

import type {
    IError,
    IGetKundeMedKundeId,
    IGetKundeMedOrgFnr,
    IKunde,
    IKunder,
    RootStateType
} from '../../models/types';
import {
    getKunde
} from '../../state/actions';
import { ELLEVE_SIFFER_REGEX } from '../../utils';
import { TwoColumnSpan } from './two-column-span';

interface IAktorSignaturkundeProps extends Pick<IAktorPanelSokProps, 'customError'> {
    aktor: IAktorPanelAktor;
    handleAktorChange?: (aktor: IAktorPanelAktor) => void;
    submitted?: boolean;
    skalLeggeTilSignaturPrefiks?: boolean;
}

interface IAktorSignaturkundeStateProps {
    kunder?: IKunder;
}

interface IAktorSignaturkundeDispatchProps {
    hentKunde?: (id: IGetKundeMedKundeId | IGetKundeMedOrgFnr) => Promise<IKunde | IError>;
}

interface IAktorSignaturkundeState {
    inputAktor: string;
    kunde: IKunde;
}

const erHentKundeActionError = (e: any): e is IError => {
    return !!e.errorCode;
};

type PropsTypes = IAktorSignaturkundeProps & IAktorSignaturkundeStateProps & IAktorSignaturkundeDispatchProps;

class AktorSignaturkunde extends React.Component<PropsTypes, IAktorSignaturkundeState> {

    public state = { inputAktor: '', kunde: null };

    public componentDidMount(): void {
        if (this.valgtSignaturkundeIkkeHentet()) {
            this.props.hentKunde({kundeId: this.props.aktor.kundeId});
        }
    }

    public componentDidUpdate(): void {
        if (!this.props.aktor.signaturKundeId) {
            return;
        }
        const kunde = _.get(this.props.kunder, this.props.aktor.signaturKundeId);
        if (!kunde) {
            this.props.hentKunde({kundeId: this.props.aktor.signaturKundeId});
        } else if (!_.isEqual(this.state.kunde, kunde)) {
            this.setState({ kunde });
        }
    }

    public render(): React.ReactNode {
        if (this.valgtSignaturkundeIkkeHentet() || (this.state.kunde && this.state.kunde.isLoading)) {
            return this.renderLoading();
        }
        if (this.props.aktor.signaturKundeId) {
            return this.renderSignaturkundeinfo();
        }

        return this.renderSok();
    }

    private renderSok = () => (
        <>
            <dt><FormattedMessage id={'generell.label.signaturFnr'} tagName="b" /></dt>
            <dd>
                <AktorPanelSok
                    componentClassName=" "
                    inputClassName="ak-input-fast-bredde"
                    input={this.state.inputAktor}
                    onClick={this.handleAktorSubmit}
                    onChange={this.handleSokChange}
                    aktor={{ ...this.props.aktor, ...this.state.kunde }}
                    submitted={this.props.submitted}
                    invalidRegExpMessageKey="kunde.sok.feilFormatFnr"
                    validRegExp={ELLEVE_SIFFER_REGEX}
                    customError={this.props.customError}
                />
            </dd>
        </>
    );

    private renderSignaturkundeinfo = () => {
        const fdatoTittel = this.props.skalLeggeTilSignaturPrefiks ? 'generell.label.fodselsdatoMedSignaturPrefiks' : 'generell.label.signaturFodselsdato';
        const navnTittel = this.props.skalLeggeTilSignaturPrefiks ? 'generell.label.navnMedSignaturPrefiks' : 'generell.label.signaturNavn';

        return (
            <>
                {!this.props.skalLeggeTilSignaturPrefiks && this.renderSignaturTitle()}
                <dt className="align-self-center"><FormattedMessage id={fdatoTittel} tagName="b" /></dt>
                <dd className="align-self-center">
                    <span>{this.state.kunde.fodselsdato}</span>
                    {!this.props.aktor.godkjenningsstatus && !this.props.aktor.panelLesemodus && <AktorEndreKnapp handleAktorEndreKnappKlikk={this.handleAktorEndre} />}
                </dd>
                <dt className="align-self-center"><FormattedMessage id={navnTittel} tagName="b" /></dt>
                <dd className="align-self-center">
                    <span>{this.state.kunde.navn}</span>
                </dd>
            </>
        );
    };

    private renderSignaturTitle() {
        return (
            <TwoColumnSpan>
                <div className="d-block my-2">
                    <h2 className="mb-0"><FormattedMessage id="generell.label.signaturTittel" /></h2>
                </div>
            </TwoColumnSpan>
        );
    }

    private renderLoading = () => (
        <>
            <dt className="align-self-center"><FormattedMessage id="generell.label.signaturFnr" tagName="b" /></dt>
            <dd className="align-self-center"><AkLoading extraClassName="ak-spinner-small col-1 mx-0" /></dd>
        </>
    );

    private handleAktorSubmit = (input: IAkTextInputResult) => {
        if (!input.errors.length && input.value) {
            return this.props.hentKunde({orgFnr: input.value}).then((hentKundeAction) => {
                const kunde = !erHentKundeActionError(hentKundeAction) && this.props.kunder[hentKundeAction.nummer];
                this.props.handleAktorChange({...this.props.aktor, signaturKundeId: kunde.kundeId, notFound: kunde.notFound});
            });
        }
        return Promise.resolve();
    };
    private handleSokChange = (input: IAkTextInputResult) => {
        this.setState({ inputAktor: input.value });
    };

    private handleAktorEndre = () => {
        this.setState({ inputAktor: '', kunde: null });
        this.props.handleAktorChange({ ...this.props.aktor, signaturKundeId: null });
    };

    private valgtSignaturkundeIkkeHentet = () => this.props.aktor.signaturKundeId && !this.state.kunde;
}


const mapStateToProps = (state: RootStateType): IAktorSignaturkundeStateProps => ({
    kunder: state.kunder
});

const mapDispatchToProps = (dispatch): IAktorSignaturkundeDispatchProps => ({
    hentKunde: id => dispatch(getKunde(id))
});

const AktorSignaturkundeConnected = connect(mapStateToProps, mapDispatchToProps)(AktorSignaturkunde);
export { AktorSignaturkundeConnected, AktorSignaturkunde };