import { akFormatDateTime, Panel, STANDARD_DATO_FORMAT, KjennemerkeStatus } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { MidlertidigAvregistreringArsak } from '../../models/kodeverk/midlertidig-avregistrering';

import type { IKjennemerkerStotteregisterStatus, IRegistreringsinformasjon, RootStateType } from '../../models/types';
import { registreringSelector } from '../../state/selectors';

interface IKjoretoydetaljerKjennemerkerProps {
    kjennemerkerStotteregisterStatus: IKjennemerkerStotteregisterStatus;
    registreringsinformasjon: IRegistreringsinformasjon;
}

class KjoretoydetaljerKjennemerker extends Component<IKjoretoydetaljerKjennemerkerProps> {

    public render(): React.ReactElement {
        const { kjennemerkerStotteregisterStatus, registreringsinformasjon } = this.props;
        const avregistrertUtenInnlevering = !!registreringsinformasjon
            && MidlertidigAvregistreringArsak.UTEN_INNLEVERING_KJENNEMERKER.valueOf() === registreringsinformasjon.midlertidigAvregistreringArsak;

        if (!kjennemerkerStotteregisterStatus) {
            return null;
        }

        const antall = {
            totalt: 0,
            tapt: kjennemerkerStotteregisterStatus.antallTapt || 0,
            makulert: kjennemerkerStotteregisterStatus.antallMakulert || 0,
            innlevert: kjennemerkerStotteregisterStatus.antallInnlevert || 0,
            hosEier: kjennemerkerStotteregisterStatus.antallHosEier || 0,
            hosBransje: kjennemerkerStotteregisterStatus.antallHosBransje || 0
        };
        antall.totalt = antall.tapt + antall.makulert + antall.innlevert + antall.hosEier + antall.hosBransje;

        if (!antall.totalt) {
            return null;
        }
        return (
            <Panel overskrift={'kjoretoydetaljer.kjennemerker.overskrift'}>
                <KjennemerkeStatus>
                    <>
                        <span>{antall.tapt}</span>
                        <FormattedMessage id={'kjoretoydetaljer.kjennemerker.label.meldtTapt'} />
                    </>

                    <>
                        <span>{antall.makulert}</span>
                        <FormattedMessage id={'kjoretoydetaljer.kjennemerker.label.makulert'} />
                    </>

                    <>
                        <span>{antall.innlevert}</span>
                        {antall.innlevert ?
                         <p>
                             <FormattedMessage
                                 id={'kjoretoydetaljer.kjennemerker.funnetInnlevertInfo'}
                                 values={{
                                     trafikkstasjon: kjennemerkerStotteregisterStatus.innlevertSted,
                                     fraDato: akFormatDateTime(kjennemerkerStotteregisterStatus.innlevertTidspunkt, undefined, STANDARD_DATO_FORMAT)
                                 }}
                             />
                             {kjennemerkerStotteregisterStatus.oppbevartSted && !avregistrertUtenInnlevering &&
                             <FormattedMessage
                                 id={'kjoretoydetaljer.kjennemerker.oppbevaresTilDato'}
                                 values={{
                                     tilDato: akFormatDateTime(kjennemerkerStotteregisterStatus.utledetOppbevaresTilDato, undefined, STANDARD_DATO_FORMAT)
                                 }}
                             />
                             }
                         </p> :
                         <FormattedMessage id={'kjoretoydetaljer.kjennemerker.label.funnetInnlevert'} />
                        }
                    </>
                    <>
                        <span>{antall.hosEier}</span>
                        <FormattedMessage id={'kjoretoydetaljer.kjennemerker.label.hosEier'}/>
                    </>
                    <>
                        <span>{antall.hosBransje}</span>
                        <FormattedMessage id={'kjoretoydetaljer.kjennemerker.label.hosBransje'}/>
                    </>
                </KjennemerkeStatus>
            </Panel>
        );
    }
}

const mapStateToProps = (state: RootStateType): IKjoretoydetaljerKjennemerkerProps => ({
    kjennemerkerStotteregisterStatus: registreringSelector(state).registreringsinformasjon?.kjennemerkerStotteregisterStatus,
    registreringsinformasjon: registreringSelector(state).registreringsinformasjon
});

const KjoretoydetaljerKjennemerkerConnected = connect(mapStateToProps)(KjoretoydetaljerKjennemerker);

export { KjoretoydetaljerKjennemerkerConnected, KjoretoydetaljerKjennemerker };
