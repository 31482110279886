import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AkFormattedMessage, Skilt } from 'svv-tk-akr-common-frontend';

import { Oppgavestatus, OppgavestatusBar } from '../../components';
import { RegelmerknaderKoder, RegelmerknaderType, SaksType, SamletGodkjenningStatus } from '../../models/kodeverk';
import type { Brukerprofil, IArbeidslisteRad } from '../../models/types';
import { hasAccessToSakstype, Merknader } from '../../regler';
import { DATO_TID_FORMAT } from '../../utils';
import { ArbeidslisteCellSize, ArbeidslisteDokumentstatus, OppgaveValgConnected } from '../startside';

import './arbeidsliste-style';
import { EierLeasingtakerNavn } from './eier-leasingtaker-navn';

const merknader = (rad: IArbeidslisteRad) => {
    if (!rad.merknader.length) {
        return null;
    } else if (Merknader.exists(rad.merknader, {kode: RegelmerknaderKoder.ANTALL_KJENNEMERKER_HOS_BRANSJE_OPPFYLLER_IKKE_KRAV})) {
        return <MerknadAdvarsel intlKey="startside.feil.ANTALL_KJENNEMERKER_HOS_BRANSJE_OPPFYLLER_IKKE_KRAV" />;
    } else if (Merknader.exists(rad.merknader, {kontekst: 'akr.forhandler.midlertidigvognkort'})) {
        return <MerknadAdvarsel intlKey="startside.feil.kanIkkeUtstedes" />;
    } else if (Merknader.exists(rad.merknader, {kode: RegelmerknaderKoder.EIERE_I_EIERSKIFTE_SAMSVARER_IKKE_MED_EIERE_PA_KJORETOY})) {
        return <MerknadAdvarsel intlKey="startside.feil.avbruttSelgerErIkkeEier" />;
    } else if (Merknader.exists(rad.merknader, {type: RegelmerknaderType.KRITISK})) {
        return <MerknadAdvarsel intlKey="startside.feil.sjekkVilkar" />;
    } else if (rad.saksType === SaksType.EIERSKIFTE && rad.samletGodkjenningStatus === SamletGodkjenningStatus.AVVIST) {
        return <MerknadAdvarsel intlKey="startside.feil.avbruttGrunnetVilkar" />;
    }
};

interface IMerknadAdvarselProps {
    intlKey: string;
}

const MerknadAdvarsel: React.FC<IMerknadAdvarselProps> = (props) => (
    <p className="ak-ikon-feil mt-1">
        <i className="fa fa-fw fa-times-circle" aria-hidden="true" />
        <FormattedMessage id={props.intlKey} />
    </p>
);

function oppgaveStatusForSamletGodkjenningStatus(samletGodkjenningStatus: SamletGodkjenningStatus, erForstegangsregistrering: boolean): Oppgavestatus {
    switch (samletGodkjenningStatus) {
        case SamletGodkjenningStatus.VENTER:
        case SamletGodkjenningStatus.PABEGYNT_REGISTRERING:
        case SamletGodkjenningStatus.FULLFOR_PA_TRAFIKKSTASJON:
            return erForstegangsregistrering ? Oppgavestatus.START : Oppgavestatus.IN_PROGRESS;
        case SamletGodkjenningStatus.GODKJENT:
            return erForstegangsregistrering ? Oppgavestatus.IN_PROGRESS : Oppgavestatus.END;
        case SamletGodkjenningStatus.REGISTRERT:
            return Oppgavestatus.END;
        case SamletGodkjenningStatus.AVVIST:
            return Oppgavestatus.ABORTED;
    }
}

interface IRadConfigProps {
    headerIntlKey: string;
    headerAltIntlKey?: string;
    cellSize?: ArbeidslisteCellSize;
    sortProperty?: string;
    excludeLink?: boolean;
    disabled: (rad: IArbeidslisteRad) => boolean;
    content: (
        rad: IArbeidslisteRad,
        openModalForGodkjenEierskifte: (sakid: string) => void,
        openModalForPurring: (sakid: string) => void
    ) => React.ReactNode;
}

enum RadConfigOrder {
    SIST_ENDRET = 0,
    STATUS = 1,
    DOKUMENTASJONSSTATUS = 2,
    KJENNEMERKE = 3,
    KJORETOY = 4,
    ORDRENR = 5,
    GJELDER = 6,
    EIER = 7,
    OPPRETTET_AV = 8,
    KNAPPER = 9
}

const makeRadConfig = (brukerprofil: Brukerprofil, isForstegangreg : boolean, isFlateeier: boolean): IRadConfigProps[] => {
    /* eslint react/display-name: ["off"] */
    return [{
        headerIntlKey: 'startside.arbeidsliste.sistendret',
        cellSize: ArbeidslisteCellSize.SMALL,
        sortProperty: 'sistEndret',
        disabled: (rad) => !hasAccessToSakstype(brukerprofil, rad),
        content: (rad) => <p>{moment(rad.sistEndret).format(DATO_TID_FORMAT)}</p>
    }, {
        headerIntlKey: 'startside.arbeidsliste.status',
        cellSize: ArbeidslisteCellSize.MEDIUM,
        sortProperty: 'samletGodkjenningStatusSorterbar',
        disabled: (rad) => !hasAccessToSakstype(brukerprofil, rad),
        content: (rad) => (
            <OppgavestatusBar
                oppgavestatus={oppgaveStatusForSamletGodkjenningStatus(rad.samletGodkjenningStatus, isForstegangreg)}
                intlId={`sak.oppgavestatus.${rad.samletGodkjenningStatus}`}
                aktorer={rad.sakAktorInfo && rad.sakAktorInfo.aktorer}
                aktorerGodkjent={rad.sakAktorInfo && rad.sakAktorInfo.aktorerGodkjent} />
        )
    }, {
        headerIntlKey: 'startside.arbeidsliste.dokumentStatus.header',
        headerAltIntlKey: 'startside.arbeidsliste.dokumentStatus.header-alt',
        cellSize: isFlateeier ? ArbeidslisteCellSize.HIDDEN : ArbeidslisteCellSize.SMALL,
        sortProperty: 'sortOrderForDokument',
        disabled: (rad) => !hasAccessToSakstype(brukerprofil, rad),
        content: (rad) => <ArbeidslisteDokumentstatus rad={rad.samletDokumentStatus}/>
    }, {
        headerIntlKey: isForstegangreg ? 'startside.arbeidsliste.unr' : 'startside.arbeidsliste.kjennemerke',
        cellSize: isForstegangreg ? ArbeidslisteCellSize.XLARGE : ArbeidslisteCellSize.DEFAULT,
        excludeLink: true,
        sortProperty: isForstegangreg ? 'kjoretoydetaljer.understellsnummer' : 'kjoretoydetaljer.kjennemerke',
        disabled: () => false,
        content: (rad) => (
            <Link className="arbeidsliste-skilt" to={`/kjoretoy/${rad.kuid}`} onClick={e => e.stopPropagation()}>
                <Skilt kjennemerke={rad.kjoretoydetaljer.kjennemerke}
                    understellsnummer={rad.kjoretoydetaljer.understellsnummer}
                    kjennemerketype={rad.kjoretoydetaljer.kjennemerketype} />
            </Link>
        )
    }, {
        headerIntlKey: 'startside.arbeidsliste.kjoretoy',
        cellSize: ArbeidslisteCellSize.MEDIUM,
        sortProperty: 'kjoretoydetaljer.merke',
        disabled: (rad) => !hasAccessToSakstype(brukerprofil, rad),
        content: (rad) => (
            <ul className="ak-liste ak-liste-kompakt pt-2">
                <li><p>{`${rad.kjoretoydetaljer.merke} ${rad.kjoretoydetaljer.handelsbetegnelse}`}</p></li>
                <li><p>{`(${rad.kjoretoydetaljer.registreringAr || moment().get('y')}) ${rad.kjoretoydetaljer.karosserifarge.join(' ')}`}</p></li>
            </ul>
        )
    }, {
        headerIntlKey: 'startside.arbeidsliste.ordrenr',
        cellSize: isForstegangreg ? ArbeidslisteCellSize.SMALL : ArbeidslisteCellSize.HIDDEN,
        sortProperty: 'ordrenr',
        disabled: () => false,
        content: (rad) => <p className="arbeidsliste-text-break">{rad.ordrenr || <FormattedMessage id="startside.arbeidsliste.tomtFelt" />}</p>
    }, {
        headerIntlKey: 'startside.arbeidsliste.gjelder',
        sortProperty: 'gjelder',
        cellSize: isForstegangreg ? ArbeidslisteCellSize.HIDDEN : ArbeidslisteCellSize.MEDIUM,
        disabled: (rad) => !hasAccessToSakstype(brukerprofil, rad),
        content: (rad) => <AkFormattedMessage className="text-break" msg={`startside.arbeidsliste.gjeldertype.${rad.gjelder}`} tagName="p" />
    }, {
        headerIntlKey: (isForstegangreg && isFlateeier) ? 'startside.arbeidsliste.leasingtaker' : 'startside.arbeidsliste.eier',
        cellSize: ArbeidslisteCellSize.XLARGE,
        sortProperty: isFlateeier ? null : 'eiere[0]',
        disabled: (rad) => !hasAccessToSakstype(brukerprofil, rad),
        content: (rad) => <EierLeasingtakerNavn rad={rad} isForstegangreg={isForstegangreg} />
    }, {
        headerIntlKey: 'startside.arbeidsliste.opprettetav',
        cellSize: ArbeidslisteCellSize.MEDIUM,
        sortProperty: 'opprettetAv',
        disabled: () => true,
        content: (rad) => <p>{rad.opprettetAv}</p>
    }, {
        headerIntlKey: 'startside.arbeidsliste.valg',
        cellSize: ArbeidslisteCellSize.XLARGE,
        excludeLink: true,
        disabled: () => false,
        content: (rad, openModalForGodkjenEierskifte, openModalForPurring) => (
            <div className="ak-liste ak-liste-kompakt">
                <OppgaveValgConnected rad={rad} openModalForGodkjenEierskifte={openModalForGodkjenEierskifte}
                    openModalForPurring={openModalForPurring} />
                {merknader(rad)}
            </div>
        )
    }];
};

export type { IRadConfigProps };
export { makeRadConfig, RadConfigOrder };
