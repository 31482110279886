import type { IMessageDescriptor } from 'svv-tk-akr-common-frontend';
import { AktorType } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';

import { EierskifteStatus, RegistreringsstatusKode } from '../../models/kodeverk';
import { MidlertidigAvregistreringArsak } from '../../models/kodeverk/midlertidig-avregistrering';
import type { Brukerprofil, IAktor, IEierskiftevalidering, IKjoretoy, ISak } from '../../models/types';
import { BrukerRegler, EierskifteRegler } from '../../regler';
import { erKjoretoyAvregistrertHosForhandler } from '../../utils';
import { EierskifteModalType } from './modaler';

export const INITIAL_STATE = {
    eierskifte: {
        saksstatus: EierskifteStatus.UNDER_OPPRETTING,
        kjennemerke: '',
        kuid: '',
        aktorer: [],
        sakId: ''
    },
    aktorer: [],
    readingMode: false,
    modalhandling: null,
    modaltype: null,
    eierskiftevalidering: {
        gyldig: true,
        submitted: false,
        merknader: [],
        errorId: null
    }
};

export const utledModaltypeForKjennemerkehandtering = (aktorerFraState: IAktor[], kjoretoy: IKjoretoy, brukerprofil: Brukerprofil): EierskifteModalType => {

    if (!aktorerFraState.length) {
        return null;
    }

    const registreringsstatus = kjoretoy.registrering.registreringsinformasjon.registreringsstatus;
    const avregistreringArsak = kjoretoy.registrering.registreringsinformasjon.midlertidigAvregistreringArsak;
    const nyEier = _.find(aktorerFraState, (aktor: IAktor) => aktor.aktorType === AktorType.NY_EIER);
    const eier = _.find(aktorerFraState, (aktor: IAktor) => aktor.aktorType === AktorType.EIER);
    const innloggetForhandler = brukerprofil.valgtEnhet;

    if (!new BrukerRegler(brukerprofil).erForhandlerEier(kjoretoy.registrering.eier)) {
        if (innloggetForhandler.hovedenhetOrgNummer === nyEier.organisasjonsnummer) {
            if (registreringsstatus === RegistreringsstatusKode.REGISTRERT) {
                return EierskifteModalType.KJENNEMERKEHANDTERING_PAREGISTRERT;
            } else if (eier.forhandler && erKjoretoyAvregistrertHosForhandler(kjoretoy)) {
                return EierskifteModalType.BEKREFTELSE_AVREGISTRERT;
            }  else if (erAvregistreringsArsakUtenInnleveringAvKjennemerker(avregistreringArsak)) {
                return EierskifteModalType.KJENNEMERKEHANDTERING_AVREGISTRERT;
            } else if (eier.forhandler && registreringsstatus === RegistreringsstatusKode.AVREGISTRERT) {
                return EierskifteModalType.BEKREFTELSE_AVREGISTRERT;
            }
        }
    } else {
        if (nyEier.forhandler && erKjoretoyAvregistrertHosForhandler(kjoretoy)) {
            return EierskifteModalType.OVERSEND_KJENNEMERKER;
        } else if (!nyEier.forhandler && registreringsstatus === RegistreringsstatusKode.AVREGISTRERT) {
            return EierskifteModalType.ADVARSEL_AVREGISTRERT;
        } else {
            return EierskifteModalType.BEKREFTELSE_AVMONTERT;
        }
    }
};

export const skalViseModalForKjennemerkehandtering = (aktorer: IAktor[], kjoretoy: IKjoretoy, brukerprofil: Brukerprofil): boolean => {
    const { registreringsstatus } = kjoretoy.registrering.registreringsinformasjon;
    const avregistreringArsak = kjoretoy.registrering.registreringsinformasjon.midlertidigAvregistreringArsak;

    if (new BrukerRegler(brukerprofil).harRolleFlateeier()) {
        return false;
    } else if (new BrukerRegler(brukerprofil).erForhandlerEier(kjoretoy.registrering.eier)) {
        return registreringsstatus !== RegistreringsstatusKode.REGISTRERT;
    } else {
        if (registreringsstatus === RegistreringsstatusKode.AVREGISTRERT && !erAvregistreringsArsakUtenInnleveringAvKjennemerker(avregistreringArsak)) {
            const eier = _.find(aktorer, (aktor: IAktor) => aktor.aktorType === AktorType.EIER);
            return eier.forhandler;
        }

        return true;
    }
};

export const erAlleObligatoriskeDokumenterLastetOpp = (sak: ISak): boolean => {
    const merknader = sak?.merknader;

    if (!EierskifteRegler.finnesObligatoriskeDokumenter(merknader)) {
        return true;
    }

    const arkivdokumenter = sak?.arkivdokumenter;
    let saksdokumenterTyper = [];
    const obligatoriskeDokumenterTyper = [];

    _.forEach(merknader, merknad => merknad.obligatoriskeDokumenter.forEach(dokument => obligatoriskeDokumenterTyper.push(dokument.saksdokumentType)));
    _.forEach(arkivdokumenter, arkivdokument => arkivdokument.saksdokumenter.forEach(dokument => saksdokumenterTyper.push(dokument.saksdokumentType)));
    saksdokumenterTyper = _.uniq(saksdokumenterTyper);

    return _.difference(obligatoriskeDokumenterTyper, saksdokumenterTyper).length === 0;
};

export const utledEkstraKritiskeMerknader = (sak: ISak, eierskifteValidation: IEierskiftevalidering): IMessageDescriptor[] => {
    if (eierskifteValidation.submitted && !erAlleObligatoriskeDokumenterLastetOpp(sak)) {
        return [{ id: 'dokumenterpanel.overskrift.manglerDokumenter' }];
    }
    return [];
};

export const utledInfomeldingerOmUmyndigEier = (sak: ISak, kjoretoy: IKjoretoy, meldinger: IMessageDescriptor[]): void => {

    if (EierskifteRegler.enAktorMenIkkeBeggeErUmyndig(sak.merknader)) {
        if (EierskifteRegler.erLettKjoretoy(kjoretoy)) {
            meldinger.push({ id: 'eierskifte.melding.info.umyndigEierMedMuligEgenSignatur' });
        } else {
            meldinger.push({ id: 'eierskifte.validering.KJOPER_IKKE_MYNDIG' });
        }
    }
};

export const utledEkstraInfomeldinger = (sak: ISak, kjoretoy: IKjoretoy): IMessageDescriptor[] => {
    const meldinger = [];

    utledInfomeldingerOmUmyndigEier(sak, kjoretoy, meldinger);
    return meldinger;
};

const erAvregistreringsArsakUtenInnleveringAvKjennemerker = (avregistreringArsak: string) => {
    return avregistreringArsak === MidlertidigAvregistreringArsak.UTEN_INNLEVERING_KJENNEMERKER.valueOf();
};
