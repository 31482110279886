import * as _ from 'lodash';
import { createSelector } from 'reselect';

import { NERINGSKODER_FOR_KJORINGENS_ART_12_ELLER_13, NERINGSKODER_FOR_KJORINGENS_ART_50, NERINGSKODER_FOR_KJORINGENS_ART_50_ELLER_70 } from '../../constants';
import { KjoringensArt } from '../../models/kodeverk';
import type { IKjoretoy, IKjoringensArt, IKjoringensArtListe, RootStateType } from '../../models/types';
import { RegistreringRegler } from '../../regler';
import { kjoretoySelector } from './kjoretoy-selectors';
import { valgtNeringskodeSelector } from './registrering-selectors';

function kjoringensArtListeSelector(state: RootStateType): IKjoringensArtListe {
    return state.kjoringensArtListe;
}

export const alleKjoringensArtSelector = createSelector(
    kjoringensArtListeSelector,
    (kjoringensArtListe): IKjoringensArt[] => (kjoringensArtListe ? kjoringensArtListe.innslag : [])
);

export const gyldigeKjoringensArtSelector = createSelector(
    kjoretoySelector,
    alleKjoringensArtSelector,
    valgtNeringskodeSelector,
    (kjoretoy, kjoringensArtListe, valgtNeringskode): IKjoringensArt[] =>
        utledGyldigeKjoringensArt(_.filter(kjoringensArtListe, { gyldig: true }), valgtNeringskode, kjoretoy)
);

const utledGyldigeKjoringensArt = (kjoringensArt: IKjoringensArt[], valgtNeringskode: string, kjoretoy: IKjoretoy): IKjoringensArt[] => {
    if (RegistreringRegler.skalKunBrukesUtenforOffentligVeg(kjoretoy)) {
        return _.compact([_.find(kjoringensArt, {name: KjoringensArt.UTENFOR_OFF_VEI})]);
    }

    const arter: IKjoringensArt[] = [];
    const kode = utledKode(valgtNeringskode);

    const egentransport = _.find(kjoringensArt, {name: KjoringensArt.EGENTRANSP_ANNEN});
    if(egentransport){
        arter.push(egentransport);
    }

    if (_.includes(NERINGSKODER_FOR_KJORINGENS_ART_50, kode)) {
        arter.push(_.find(kjoringensArt, {name: KjoringensArt.UTLEIEVOGN}));
    } else if (_.includes(NERINGSKODER_FOR_KJORINGENS_ART_12_ELLER_13, kode)) {
        arter.push(_.find(kjoringensArt, {name: KjoringensArt.FORSVARET}));
    } else if (_.includes(NERINGSKODER_FOR_KJORINGENS_ART_50_ELLER_70, kode)) {
        arter.push(_.find(kjoringensArt, {name: KjoringensArt.UTLEIEVOGN}));
    }

    return _.compact(_.uniqBy(arter, 'name'));
};

const utledKode = (kode: string): string => {
    if (kode) {
        const index = kode.indexOf('.');

        if (index > -1) {
            kode = kode.substring(0, index);
        }
    }

    return kode;
};
