import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type ListMapType = Record<string, any>;

interface IDlTableProps {
    listMap: ListMapType;
    intlLabelKey?: string;
    customDlCss?: string;
}

const resolveValue = (resolvable: any): string | JSX.Element => {
    if (!resolvable) {
        return '';
    }

    if (resolvable.id) {
        return <FormattedMessage id={resolvable.id} values={resolvable.values} />;
    }

    return resolvable;
};

const makeTable = (list: ListMapType, intlKey: string) => {
    const content: React.JSX.Element[] = [];

    Object.keys(list).forEach((key) => {
        content.push(<dt key={`dt-${key}`}><FormattedMessage id={`${intlKey}.${key}`} /></dt>);
        content.push(<dd key={`dd-${key}`}>{resolveValue(list[key])}</dd>);
    });

    return content;
};

const DlTable: React.FC<IDlTableProps & React.PropsWithChildren> = (props) => (
    <dl className={`ak-dl-table ${props.customDlCss || ''}`}>
        {makeTable(props.listMap, props.intlLabelKey)}
        {props.children}
    </dl>
);

export { DlTable };
