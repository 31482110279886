import type { KjennemerkeTypeEnum} from 'svv-tk-akr-common-frontend';
import { AkCopyToClipboard, KjennemerkeKategoriEnum, KjennemerkeSettEnum, Skiltsett } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import type { IGodkjenning, IRegistrering } from '../../models/types';

const LABEL_PREFIX = 'erstatningskjennemerker.label';

interface IErstatningskjennemerkeInfoProps {
    godkjenning: IGodkjenning;
    registrering: IRegistrering;
}

interface IKjennemerkeInfoProps extends IErstatningskjennemerkeInfoProps {
    kjennemerkeType: KjennemerkeTypeEnum;
    kjennemerkeSett: KjennemerkeSettEnum;
}

interface IMerkeInfoProps extends IErstatningskjennemerkeInfoProps {
    kjennemerkeSett: KjennemerkeSettEnum;
}

interface ITekniskInfoProps extends IErstatningskjennemerkeInfoProps {
    kjennemerkeType: KjennemerkeTypeEnum;
}

const KjennemerkeInfo = (props: IKjennemerkeInfoProps): React.ReactElement => {
    const { registrering, godkjenning, kjennemerkeType, kjennemerkeSett } = props;
    const regKjennemerke = registrering?.kjennemerke;
    const tegnkombinasjon = regKjennemerke?.tegnkombinasjon || godkjenning?.understellsnummer;
    const link = `/kjoretoy/${godkjenning.kuid}`;
    return (
        <div className="mr-4 mr-xl-5">
            <Link to={link} className="text-decoration-none row">
                <Skiltsett kjennemerkeType={kjennemerkeType} tegnkombinasjon={tegnkombinasjon} kjennemerkeSett={kjennemerkeSett} kjennemerkekategori={KjennemerkeKategoriEnum.KJORETOY} />
            </Link>
            {<AkCopyToClipboard text={tegnkombinasjon} />}
        </div>
    );
};

const MerkeInfo = (props: IMerkeInfoProps): React.ReactElement => {
    const { godkjenning, kjennemerkeSett, registrering } = props;
    const handelsbetegnelse = godkjenning?.tekniskeData?.handelsbetegnelse;
    const farge = godkjenning?.tekniskeData['rfarge.0.karosserifarge'];
    const merkeCssKlasse = _.isEqual(kjennemerkeSett, KjennemerkeSettEnum.SSSH.toString()) || _.isEqual(kjennemerkeSett, KjennemerkeSettEnum.LSLH.toString()) ? 'ml-5' : '';
    return (
        <div className={merkeCssKlasse}>
            <h2 className="ak-fet">{`${godkjenning.merke} ${handelsbetegnelse}`}</h2>
            <h2>{`(${registrering.registreringsinformasjon.registrertForsteGangAr}) `}{farge}</h2>
            <p>{godkjenning.understellsnummer}</p>
        </div>
    );
};

const TekniskInfo = (props: ITekniskInfoProps): React.ReactElement => (
    <dl className="ak-dl-liste ak-dl-liste-grid-kompakt ml-4">
        {renderKjoretoyinfo(props.godkjenning, props.kjennemerkeType)}
        {renderStatus(props.registrering)}
    </dl>
);

const renderStatus = (registrering: IRegistrering) => (
    <>
        <dt><FormattedMessage id={`${LABEL_PREFIX}.regStatus`} tagName="b" /></dt>
        <dd><FormattedMessage id={`kodeverk.registreringsstatus.${registrering?.registreringsinformasjon?.registreringsstatus}`} /></dd>
    </>
);

const renderKjoretoyinfo = (godkjenning: IGodkjenning, kjennemerkeType: KjennemerkeTypeEnum): React.ReactNode => {
    const {kjennemerkestorrelseForan, kjennemerkestorrelseBak, kjennemerkestorrelseVenstre} = godkjenning?.tekniskeData || {};
    return (
        <>
            <dt className="mb-2"><FormattedMessage id={`${LABEL_PREFIX}.kjoretoyklassifisering`} tagName="b" /></dt>
            <dd><FormattedMessage id={`kodeverk.klassifisering.${godkjenning.tekniskKode}.${godkjenning.tekniskUnderkode}.${godkjenning.avgiftskode}`} /></dd>
            <dt className="mb-2"><FormattedMessage id={`${LABEL_PREFIX}.kjennemerketype`} tagName="b" /></dt>
            <dd><FormattedMessage id={`erstatningskjennemerker.label.kjennemerketyper.${kjennemerkeType}`} /></dd>
            {renderKjmStorrelse('kjennemerkestrl', kjennemerkestorrelseBak, kjennemerkestorrelseForan, kjennemerkestorrelseVenstre)}
        </>
    );
};

const renderKjmStorrelse = (labelId: string, strBak: string, strForan: string, strVenstre?: string): React.ReactNode => (
    <>
        <dt className="mb-2"><FormattedMessage id={`${LABEL_PREFIX}.${labelId}`} tagName="b" /></dt>
        <dd>{utledTekstForErstatningsKjennemerkestorrelse(strBak, strForan, strVenstre)}</dd>
    </>
);

const utledTekstForErstatningsKjennemerkestorrelse = (kjmStorrelseBak: string, kjmStorrelseForan: string, kjmStorrelseVenstre: string): string => {
    if (erMerEnnEttKjennemerkeUtlevert(kjmStorrelseForan, kjmStorrelseVenstre, kjmStorrelseBak)) {
        return `${kjmStorrelseForan || kjmStorrelseVenstre} / ${kjmStorrelseBak}`;
    }
    return kjmStorrelseForan || kjmStorrelseBak || kjmStorrelseVenstre || '';
};

const erMerEnnEttKjennemerkeUtlevert = (kjmStorrelseForan: string, kjmStorrelseVenstre: string, kjmStorrelseBak: string): boolean => !!(kjmStorrelseForan || kjmStorrelseVenstre) && !!kjmStorrelseBak;


export { KjennemerkeInfo, MerkeInfo, TekniskInfo };
