export enum GjelderType {
    EIERSKIFTE_FRA = 'EIERSKIFTE_FRA',
    EIERSKIFTE_TIL = 'EIERSKIFTE_TIL',
    REGISTRERING = 'REGISTRERING'
}

export enum SamletGodkjenningStatus {
    GODKJENT = 'GODKJENT',
    AVVIST = 'AVVIST',
    VENTER = 'VENTER',
    REGISTRERT = 'REGISTRERT',
    PABEGYNT_REGISTRERING = 'PABEGYNT_REGISTRERING',
    FULLFOR_PA_TRAFIKKSTASJON = 'FULLFOR_PA_TRAFIKKSTASJON'
}

export enum Godkjenningstatus {
    GODKJENT = 'GODKJENT',
    AVVIST = 'AVVIST'
}

export enum ArbeidslisteValg {
    GODKJENN = 'GODKJENN',
    AVVIS = 'AVVIS',
    OMREGISTRER = 'OMREGISTRER',
    NYTT_EIERSKIFTE = 'NYTT_EIERSKIFTE',
    AVSLUTT = 'AVSLUTT',
    OPPDATER_BETALING = 'OPPDATER_BETALING',
    MIDLERTIDIG_VOGNKORT = 'MIDLERTIDIG_VOGNKORT',
    OPPDATER_OG_FJERN = 'OPPDATER_OG_FJERN',
    REGISTRER = 'REGISTRER',
    PURRE = 'PURRE'
}

export enum ArbeidslisteSakstypefilter {
    EIERSKIFTE_OG_REGISTRERING = 'EIERSKIFTE_OG_REGISTRERING',
    REGISTRERING = 'REGISTRERING',
    EIERSKIFTE = 'EIERSKIFTE',
    FORSTEGANGSREGISTRERING = 'FORSTEGANGSREGISTRERING'
}
