import type { IAkConfirmNavigationProps} from 'svv-tk-akr-common-frontend';
import { AkConfirmNavigation, AkFremdriftsindikator, AkKnapp, AkMeldingboks, AkMeldingboksType, Panel } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { EierskifteStatus } from '../../models/kodeverk';
import type { Brukerprofil, IAktor, IEierskifte, IEierskiftevalidering, IKjoretoy, IKjoretoyList, IKunder, RootStateType } from '../../models/types';
import { BrukerRegler, EierskifteRegler } from '../../regler';
import { kjoretoyListClearAction } from '../../state/actions';
import { populerNyttEierskifte } from '../../utils';
import { EierskifteAktorerConnected, EierskifterBulkProgressModalConnected, KjoretoyInformationBulkConnected } from '../eierskifte';
import { EierskifteModaler, EierskifteModalType } from './modaler';

interface IEierskifteBulkStateProps {
    brukerprofil?: Brukerprofil;
    eierskifte: IEierskifte;
    kjoretoyList: IKjoretoyList;
    kunder: IKunder;
}

interface IEierskifteBulkDispatchProps {
    clearKjoretoyList: () => Promise<any>;
}

interface IEierskifteBulkState {
    eierskifter?: IEierskifte[];
    aktorer: IAktor[];
    modaltype: EierskifteModalType;
    modalhandling: () => void;
    eierskiftevalidering: IEierskiftevalidering;
}

type EierskifteBulkPropsType = IEierskifteBulkStateProps & IEierskifteBulkDispatchProps & WrappedComponentProps & IAkConfirmNavigationProps;

class EierskifteBulk extends Component<EierskifteBulkPropsType, IEierskifteBulkState> {

    public state = {
        eierskifter: [],
        aktorer: [],
        modaltype: null,
        modalhandling: null,
        eierskiftevalidering: {
            gyldig: true,
            submitted: false,
            merknader: [],
            errorId: null
        }
    };

    public componentDidMount() {
        if (this.props.eierskifte.aktorer.length) {
            this.setState({ aktorer: [...this.props.eierskifte.aktorer] });
        }
    }

    public componentDidUpdate(prevProps: EierskifteBulkPropsType): void {
        if (!_.isEqual(this.props.kjoretoyList, prevProps.kjoretoyList)) {
            if (this.props.kjoretoyList.kjoretoy.length === 1 && !this.state.aktorer.length) {
                const initialKjoretoy = this.props.kjoretoyList.kjoretoy[0];
                this.setState({ aktorer: populerNyttEierskifte(this.props.brukerprofil, initialKjoretoy.registrering, this.props.kunder) });
            }
            if (!this.props.kjoretoyList.kjoretoy.length) {
                this.setState({ aktorer: [] });
            }
        }
    }

    public componentWillUnmount() {
        this.props.clearKjoretoyList();
    }

    public render() {
        const eierskifterTekst = !this.props.kjoretoyList.kjoretoy.length ? 'eierskifterUtenKjoretoy' : this.isForhandlerEier() ? 'eierskifterSelge' : 'eierskifterKjope';

        return (
            <div className="container row no-gutters mx-auto">
                <header className="row col-12 align-items-end mb-3 no-gutters">
                    <h1 className="col-12 col-lg-5">
                        <FormattedMessage id={`eierskifte.overskrift.${eierskifterTekst}`} />
                    </h1>
                    <AkFremdriftsindikator fremdriftsStatusIndex={EierskifteRegler.getProgressStatusEierskifte({ saksstatus: EierskifteStatus.UNDER_OPPRETTING } as IEierskifte)}
                        tekstKeys={EierskifteRegler.getProgressStatusEierskifteTekster()} fremdriftsindikatorStyleClassName="col-lg-3 col-5" />
                    {this.hovedknapper()}
                </header>

                {!this.state.eierskiftevalidering.gyldig && this.state.eierskiftevalidering.submitted &&
                <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mb-4">
                    {!!this.state.eierskiftevalidering.merknader?.length &&
                        <ul className="ak-melding-liste">
                            {this.state.eierskiftevalidering.merknader.map((melding, index) => (
                                <li key={`melding-${index}`}>
                                    <FormattedMessage id={`eierskifte.validering.${melding.kode}`} />
                                </li>
                            ))}
                        </ul>
                    }
                </AkMeldingboks>
                }
                {!!this.state.aktorer.length ? (
                        <EierskifteAktorerConnected  lesemodus={false} aktorer={this.state.aktorer} submitted={this.state.eierskiftevalidering.submitted}
                            oppdaterAktorer={this.oppdaterAktorer} slettAktor={this.slettAktor} />
                    ) : (
                        <Panel overskrift={'eierskifte.overskrift.eiere'} panelCssClass="col-12 ak-panel-transparent ak-med-tabell">
                            <div className="py-2"><FormattedMessage id={'eierskifte.label.flereEierskifterUtenKjoretoy'} tagName="h3" /></div>
                        </Panel>
                )}
                <KjoretoyInformationBulkConnected canDelete={this.props.kjoretoyList.kjoretoy.length > 1} />

                <EierskifteModaler modalType={this.state.modaltype} lukkModal={this.closeModal} modalhandling={this.state.modalhandling} />

                <EierskifterBulkProgressModalConnected eierskifter={this.state.eierskifter}
                    submitted={this.state.eierskiftevalidering.submitted && this.state.eierskiftevalidering.gyldig} />
            </div>

        );
    }

    private oppdaterAktorer = (aktorer: IAktor[]) => {
        this.setState({
            aktorer,
            eierskiftevalidering: {
                merknader: [],
                gyldig: true,
                submitted: true
            }
        });
    };

    private slettAktor = (aktor: IAktor) => {
        const aktorer = _.filter(this.state.aktorer, (eksistrendeAktor: IAktor) => {
            return eksistrendeAktor.aktorType !== aktor.aktorType;
        });

        this.props.confirmNavigation(true);
        this.setState({
            aktorer,
            eierskiftevalidering: {
                merknader: [],
                gyldig: true,
                submitted: true
            }
        });
    };

    private openGodkjenningModal = () => {
        this.props.confirmNavigation(false);
        const merknader = EierskifteRegler.validerEierskifte({ aktorer: this.state.aktorer }, this.props.brukerprofil.bruker);

        this.setState({
            modaltype: EierskifteModalType.BULK_GODKJENN,
            modalhandling: merknader.length ? null : this.sendTilGodkjenning,
            eierskiftevalidering: {
                gyldig: !merknader.length,
                merknader: merknader,
                submitted: true
            }
        });
    };

    private openModalForLukk = () => {
        this.props.confirmNavigation(false);
        return this.setState({
            modaltype: EierskifteModalType.BULK_LUKK
        });
    };

    private hovedknapper = () => (
        <div className="ak-knapperad ml-auto col-md-6 col-lg-auto col-12">
            <AkKnapp type="ak-tilleggsknapp" action={this.openModalForLukk} intlKey={'generell.lukk'} />
            {!!this.props.kjoretoyList.kjoretoy.length && <AkKnapp type="ak-hovedknapp"
                disabled={!this.state.eierskiftevalidering.gyldig}
                action={this.openGodkjenningModal}
                intlKey={'eierskifte.knapp.sendTilGodkjenning'} />
            }
        </div>
    );

    private sendTilGodkjenning = () => {
        this.props.confirmNavigation(false);
        this.closeModal();

        const eierskifter = [];
        const validKjoretoy = _.filter(this.props.kjoretoyList.kjoretoy, (kjoretoy: IKjoretoy) => {
            return EierskifteRegler.erGyldigKjoretoyForEierskifte(kjoretoy);
        });

        validKjoretoy.forEach((kjoretoy: IKjoretoy) => {
            eierskifter.push({
                kjennemerke: kjoretoy.kjennemerke,
                kuid: kjoretoy.registrering.kuid,
                aktorer: [...this.state.aktorer]
            });
        });

        this.setState({
            eierskiftevalidering: { submitted: true, gyldig: !!eierskifter.length },
            eierskifter: [...eierskifter]
        });
    };

    private closeModal = () => {
        this.props.confirmNavigation(false);
        this.setState({
            modaltype: null,
            modalhandling: null
        });
    };

    private isForhandlerEier = (): boolean => {
        const initialKjoretoy = this.props.kjoretoyList.kjoretoy[0];
        const initialKjoretoyRegistrering = initialKjoretoy.registrering;
        return new BrukerRegler(this.props.brukerprofil).erForhandlerEier(initialKjoretoyRegistrering.eier);
    };
}

const mapStateToProps = (state: RootStateType): IEierskifteBulkStateProps => ({
    brukerprofil: state.brukerprofil,
    eierskifte: state.eierskifte,
    kjoretoyList: state.kjoretoyList,
    kunder: state.kunder
});

const mapDispatchToProps = (dispatch): IEierskifteBulkDispatchProps => ({
    clearKjoretoyList: () => dispatch(kjoretoyListClearAction())
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    AkConfirmNavigation
);

const EierskifteBulkConnected = enhance(EierskifteBulk);

export { EierskifteBulkConnected };
