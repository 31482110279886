import * as React from 'react';
import type { WrappedComponentProps} from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AkKnapp } from 'svv-tk-akr-common-frontend';
import type { IRootState } from '../../models/types';

interface IFooterProps {
    versjon: string;
    erProd: boolean;
}

const Footer: React.FC<WrappedComponentProps & IFooterProps> = (props) => {

    return (
        <footer className="ak-footer row no-gutters justify-content-center align-items-center">
            <p className="col-auto px-3">
                <FormattedMessage id="footer.melding" />
            </p>
            <a className="col-auto px-3 ak-footer-link align-items-center" target="_top"
                href={`mailto:${props.intl.formatMessage({id: 'footer.vegvesen.epost'})}`}>
                <FormattedMessage id="footer.vegvesen.epost.melding" />
                <FormattedMessage id="footer.vegvesen.epost" />
            </a>
            <p className="col-auto px-3">
                <FormattedMessage id="footer.vegvesen.telefon" />
            </p>
            {!!props.versjon &&
                <p className="col-12">
                    <small>{`Versjon: ${props.versjon}`}</small>
                </p>
            }
            {!props.erProd &&
                <Link to={"/funksjonsbrytere"}>
                    <AkKnapp>
                        <FormattedMessage id="footer.vegvesen.funksjonsbrytere" />
                    </AkKnapp>
                </Link>
            }
        </footer>
    );
};

const mapDispatchToProps = () => ({
});

const mapStateToProps = (store: Pick<IRootState, 'globals'>) => ({
   versjon: store.globals.klientKonfigurasjon.versjon,
   erProd: store.globals.klientKonfigurasjon.erProd
});

const FooterConnected = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Footer));

export { FooterConnected };
