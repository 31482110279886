import { KjennemerkeActionKey } from '../../constants';
import type {IKjennemerkeserie, IKjennemerkeserierOgTyper} from '../../models/types';
import type { KjennemerkeserieActionType, KjennemerkeserierOgTyperActionType } from '../actions';

const initialState: IKjennemerkeserierOgTyper = {
    kjennemerkeserier: [],
    kjennemerkeTyper: [],
    isLoading: false,
    error: null
};

const kjennemerkeserieState: IKjennemerkeserie = {
    kjennemerkeserie: null,
    isLoading: false,
    error: null
};

export function kjennemerkeserierOgTyper(state: IKjennemerkeserierOgTyper = initialState, action: KjennemerkeserierOgTyperActionType): IKjennemerkeserierOgTyper {
    switch (action.type) {
        case KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_SUCCESS:
            return { ...state,
                kjennemerkeserier: action.kjennemerkeserierOgTyper.kjennemerkeserier || [],
                kjennemerkeTyper: action.kjennemerkeserierOgTyper.kjennemerkeTyper || [],
                merknader: action.kjennemerkeserierOgTyper.merknader,
                isLoading: false
            };
        case KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_ERROR:
            return { ...initialState, error: action.error };
        case KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_RESET:
            return{...initialState};
        case KjennemerkeActionKey.KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_LOADING:
            return {...state, isLoading: action.isLoading, error: null, notFound: false};
        default:
            return state;
    }
}
export function kjennemerkeserie(state: IKjennemerkeserie = kjennemerkeserieState, action: KjennemerkeserieActionType): IKjennemerkeserie {

    switch (action.type) {
        case KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_SUCCESS:
            return {...state,
                kjennemerkeserie: action.kjennemerkeserie.kjennemerkeserie || null,
                isLoading: false
            };
        case KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_ERROR:
            return { ...kjennemerkeserieState, error: action.error};

        case KjennemerkeActionKey.KJENNEMERKE_SERIE_FETCH_DATA_LOADING:
            return {...kjennemerkeserieState};

        default:
            return state;
    }
}

