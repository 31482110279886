import { AkKnapp, AkLoading, AkTextInputWithError, AktorType } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { IAkTextInputWithErrorState } from 'svv-tk-akr-common-frontend/dist/components/form-elements/ak-text-input-with-error';

import { RegistreringKundeType } from '../../constants';
import { Kunde } from '../../models';
import type { Brukerprofil, IError, IGetKundeMedKundeId, IGetKundeMedOrgFnr, IKjoretoy, IKunde, IKunder, IMerknad, RootStateType } from '../../models/types';
import { BrukerRegler, RegistreringRegler } from '../../regler';
import { getKunde } from '../../state/actions';
import { kjoretoySelector } from '../../state/selectors';
import { NI_ELLER_ELLEVE_SIFFER_REGEX, NI_SIFFER_REGEX, STANDARD_DATO_FORMAT } from '../../utils';

interface IKundeDispatchProps {
    getKunde?: (nummer: IGetKundeMedKundeId | IGetKundeMedOrgFnr) => any;
}

interface IKundeStateProps {
    kunder?: IKunder;
    kuid?: string;
    eier?: IKunde;
    kjoretoy?: IKjoretoy;
    brukerprofil: Brukerprofil;
}

interface IKundePanelProps {
    kundeProp: Kunde;
    slettKunde: (kunde: Kunde) => void;
    oppdaterKunde: (nummer: string, kunde: Kunde) => void;
    endreKunde: (kunde: Kunde) => void;
    touched?: boolean;
    getKunde?: (nummer: IGetKundeMedKundeId | IGetKundeMedOrgFnr, kundeType: RegistreringKundeType | AktorType, kuid: string) => any;
}

interface IKundePanelState {
    nummer: string;
    kundeError?: IError;
    merknader?: IMerknad;
    isLoading: boolean;
    inputValidationError?: boolean;
}

class KundePanel extends Component<IKundePanelProps & IKundeDispatchProps & IKundeStateProps, IKundePanelState> {

    public state = {
        nummer: this.props.kundeProp.organisasjonsnummer || this.props.kundeProp.getKundeId() || '',
        kundeError: null,
        isLoading: false,
        inputValidationError: false,
        merknader: null
    };

    public componentDidMount(): void {
        if (this.props.kundeProp.organisasjonsnummer || this.props.kundeProp.getKundeId()) {
            this.sendInnAktorNummer();
        }
    }

    public leggTilSlettKnapp = (): React.ReactElement => {
        return (
            <div className="ml-auto">
                <button className="fa fa-trash-alt ak-knapp ak-ikon-knapp-transparent" onClick={this.slettKunde} />
            </div>
        );
    };

    public leggTilKundeInfo = (kunde: Kunde): React.ReactElement => {
        const erOrg = kunde.erOrganisasjon();
        const erFnr = this.state.nummer.toString().match(/^\d{11}$/);
        return (
            <div className="col-12">
                <dl className="ak-dl-liste ak-dl-liste-kompakt row col">
                    <dt className="col-3 text-truncate" key="organisasjonsnummer-text">
                        <FormattedMessage id={`registrering.label.${erOrg ? 'organisasjon' : (erFnr ? 'fodselsnummer' : 'fodselsdato')}`} />
                    </dt>
                    <dd className="col-9" key="organisasjonsnummer-navn">
                        <span>{erOrg ? kunde.organisasjonsnummer : (erFnr ? this.state.nummer : moment(kunde.fodselsdato).format(STANDARD_DATO_FORMAT))}</span>
                        {this.kanEndres(kunde) &&
                        <AkKnapp type="ak-link-knapp ak-ikon-endre ml-2" intlKey={'registrering.endreKundeKnapp'} action={this.endreKunde} />
                        }
                    </dd>
                    <dt className="col-3"><FormattedMessage id={'registrering.label.navn'} /></dt>
                    <dd className="col-9">{kunde.navn}</dd>
                </dl>
            </div>
        );
    };

    public leggTilInputFeltForKundeSok = (kunde: Kunde): React.ReactElement => {
        const regExForKundeInput = kunde.erUnderenhet() ? NI_SIFFER_REGEX : NI_ELLER_ELLEVE_SIFFER_REGEX;
        const valideringsMeldingForUgyldigRegEx: string = kunde.erUnderenhet() ? 'registrering.validering.9siffer' : 'registrering.validering.9eller11siffer';

        return (
            <form className="col-12 row no-gutters align-items-baseline" onSubmit={this.sendInnAktorNummer}>
                <div className="col-3 text-truncate ak-fet">
                    <FormattedMessage id={`registrering.label.${kunde.erUnderenhet() ? 'organisasjonsnummer' : 'organisasjonsnummerOgFodselsnummer'}`} />
                </div>
                <div className="col-9 row flex-nowrap">
                    <AkTextInputWithError touched={this.props.touched}
                        stripWhitespace={true}
                        onChange={this.inputOnChange}
                        validRegExp={regExForKundeInput}
                        invalidRegExpMessageKey={valideringsMeldingForUgyldigRegEx}
                        initialValue={kunde.organisasjonsnummer || this.state.nummer} propertyKey="nummer" />
                    <button className="ak-knapp ak-hovedknapp" type="submit">
                        <i className="fa fa-angle-right" />
                    </button>
                </div>
            </form>
        );
    };

    public render(): React.ReactElement {
        const kunde = this.props.kundeProp;
        const isLoading = this.state.isLoading;
        const kanSlettes = !isLoading && (kunde.erEiertype(RegistreringKundeType.NY_LEASINGTAKER) || (kunde.erUnderenhet() && new BrukerRegler(this.props.brukerprofil).erForhandlerSisteEier(this.props.kjoretoy)));
        const skalViseKundeInfo = !isLoading && kunde.navn;
        const skalViseInputfeltForKundeSok = !isLoading && !kunde.navn && kunde.erNyEierType();

        return (
            <div className="row ak-panel-lysegra ml-1 mb-3">
                <div className="col-12 row no-gutters">
                    <h2><FormattedMessage id={`registrering.overskrift.${(kunde.type || '').toLowerCase()}`} /></h2>
                    {isLoading && <AkLoading />}
                    {kanSlettes && this.leggTilSlettKnapp()}
                    {skalViseKundeInfo && this.leggTilKundeInfo(kunde)}
                    {skalViseInputfeltForKundeSok && this.leggTilInputFeltForKundeSok(kunde)}
                    {this.getKundeErrorMessage()}
                    {this.getKundeMerknaderErrorMessage()}
                </div>
            </div>
        );
    }

    private kanEndres = (kunde: Kunde): boolean => {
        return kunde.navn && (kunde.erEiertype(RegistreringKundeType.NY_LEASINGTAKER) || (kunde.erUnderenhet() && new BrukerRegler(this.props.brukerprofil).erForhandlerSisteEier(this.props.kjoretoy)));
    };

    private sendInnAktorNummer = (event?) => {
        if (event) {
            event.preventDefault();
        }

        if (!this.state.nummer || this.state.inputValidationError) {
            return;
        }

        this.setState({ isLoading: true });
        const kundeProp = this.props.kundeProp;
        const kundeType = this.props.kundeProp.erLeasingtaker() ? AktorType.NY_EIER : this.props.kundeProp.type;

        const kundePromise = kundeProp.getKundeId()
            ? this.props.getKunde({ kundeId: kundeProp.getKundeId() }, kundeType, this.props.kuid)
            : this.props.getKunde({ orgFnr: this.state.nummer }, kundeType, this.props.kuid);

        kundePromise.then(() => {
            const kunde = this.props.kunder[this.state.nummer];
            const eierSomKunde = this.props.kunder[this.props.eier.kundeId];
            if (kunde && kunde.error) {
                this.setState({ kundeError: kunde.error, isLoading: false });
                return;
            } else if (kunde && kunde.notFound) {
                this.setState({ kundeError: { errorCode: 'ikkeFunnet' } as IError, isLoading: false });
                return;
            } else if (kunde && kunde.merknader.length && !this.props.kundeProp.erLeasingtaker()) {
                this.setState({ merknader: { kode: kunde.merknader[0].kode } as IMerknad, isLoading: false });
                return;
            } else if (kunde && kunde.merknader.length
                && this.props.kundeProp.erLeasingtaker()
                && kunde.merknader.filter(m => RegistreringRegler.erReglerbruddForLeasingtaker(m)).length) {
                this.setState({ isLoading: false });
                return;
            } else if (eierSomKunde && this.props.kundeProp.erUnderenhet() && !this.erSammeJuridiskeEier(kunde, eierSomKunde.juridiskEnhetNummer)) {
                this.setState({ merknader: { kode: 'NY_UNDERENHET_IKKE_AVDELING_AV_EIER' } as IMerknad, isLoading: false });
                return;
            }

            const aktor = _.omit(kunde, ['isLoading', 'error', 'notFound']);
            const nyAktor = new Kunde({ ...aktor, type: this.props.kundeProp.type });
            this.props.oppdaterKunde(this.state.nummer, nyAktor);
            this.setState({ kundeError: null, isLoading: false, merknader: null });
        });
    };

    private erSammeJuridiskeEier = (kunde: IKunde, juridiskEier: string): boolean => {
        return kunde.juridiskEnhetNummer === juridiskEier;
    };

    private getKundeErrorMessage() {
        const error = this.state.kundeError;

        if (error && RegistreringRegler.erSlettetOrganisasjonLeasingtaker(error.errorCode, this.props.kundeProp.type)) {
            return (
                <p className="ak-text-error">
                    <FormattedMessage id="registrering.validering.leasingtakerUgyldigStatus" />
                </p>
            );
        }

        if (error) {
            return (
                <p className="ak-text-error">
                    <FormattedMessage
                        id={`feilhandtering.kunde.${error.errorId ? 'error' : error.errorCode}`}
                        values={{ errorId: <span className="text-nowrap">{error.errorId}</span> }} />
                </p>
            );
        }

        return null;
    }

    private getKundeMerknaderErrorMessage() {
        const kunde = this.props.kunder[this.state.nummer] || {} as IKunde;

        if (kunde.merknader && this.props.kundeProp.erLeasingtaker()) {
            const meldinger = kunde.merknader.map((merknad, index) => {
                if (RegistreringRegler.erReglerbruddForLeasingtaker(merknad)) {
                    const melding = !!kunde.organisasjonsnummer
                        ? 'registrering.validering.leasingtaker.ugyldigStatus'
                        : `registrering.validering.leasingtaker.${kunde.merknader[0].kode}`;

                    return <FormattedMessage key={`melding-${index}`} id={melding} />;
                }
            });

            return (
                <p className="ak-text-error">
                    {meldinger}
                </p>
            );
        }

        if (this.state.merknader && !this.props.kundeProp.erLeasingtaker()) {
            return (
                <p className="ak-text-error">
                    <FormattedMessage id={`registrering.validering.${this.state.merknader.kode}`} />
                </p>
            );
        }

        return null;
    }

    private inputOnChange = (input: IAkTextInputWithErrorState) => {
        if (input.value && input.propertyKey === 'nummer') {
            this.setState({ nummer: input.value, inputValidationError: false, kundeError: null, merknader: null });
            return;
        } else if (input.value && !input.errors.length) {
            const kunde = this.props.kundeProp;
            kunde[input.propertyKey] = input.value;
            this.props.oppdaterKunde(kunde.kundeId, kunde);
        } else if (input.errors.length > 0) {
            this.setState({ inputValidationError: true, kundeError: null });
            return;
        }
        this.setState({ inputValidationError: false, kundeError: null });
    };

    private slettKunde = () => {
        this.props.slettKunde(this.props.kundeProp);
    };

    private endreKunde = () => {
        this.props.endreKunde(new Kunde({ type: this.props.kundeProp.type }));
    };
}

const mapStateToProps = (state: RootStateType): IKundeStateProps => ({
    kunder: state.kunder,
    kuid: state.kjoretoy.registrering.kuid,
    eier: state.kjoretoy.registrering.eier,
    kjoretoy: kjoretoySelector(state),
    brukerprofil: state.brukerprofil
});

const mapDispatchToProps = (dispatch): IKundeDispatchProps => ({
    getKunde: (nummer: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktorType?: RegistreringKundeType | AktorType, kuid?: string) => dispatch(getKunde(nummer, aktorType, kuid))
});

const KundePanelConnected = connect(mapStateToProps, mapDispatchToProps)(KundePanel);

export { KundePanelConnected, KundePanel };
