import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Panel } from 'svv-tk-akr-common-frontend';
import type { IPkkFrist } from '../../models/types';
import { akFormatDate } from '../../utils/akr-format';

const KjoretoydetaljerPkk: React.FC<{pkkFrist: IPkkFrist}> = (props) => (

    <Panel overskrift={'kjoretoydetaljer.pkk.overskrift'} panelCssClass="ak-med-tabell">
        <dl className="ak-dl-table">
            <dt><FormattedMessage id="kjoretoydetaljer.pkk.label.pkkSistGodkjent" /></dt>
            <dd>
                {akFormatDate(props.pkkFrist.pkkSistGodkjent)}
            </dd>
            <dt><FormattedMessage id="kjoretoydetaljer.pkk.label.pkkFristForGodkjenning" /></dt>
            <dd>
                {akFormatDate(props.pkkFrist.pkkFristForGodkjenning)}
            </dd>
        </dl>
    </Panel>
);

export { KjoretoydetaljerPkk };
