import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Saksstatus } from '../../models/kodeverk';

import type { IRegistreringInformasjon, IRegistreringSak, ISak, IVilkarstatus, RootStateType } from '../../models/types';
import { kontrollerVilkar } from '../../state/actions';
import { erRegistreringAvsluttet, harForsoktFatteVedtak, harPopulertNySak } from './registrering';

interface VilkarstatusHenterProps {
    skalHenteVilkarstatus: boolean;
}

interface VilkarstatusHenterDispatchProps {
    hentVilkarstatus: (saksinformasjon: Partial<IRegistreringInformasjon>) => Promise<IVilkarstatus>;
}

interface VilkarstatusHenterStateProps {
    sakResponse: ISak<IRegistreringInformasjon>;
    registreringSakRequest: IRegistreringSak;
}

type PropsType = React.PropsWithChildren<VilkarstatusHenterProps & VilkarstatusHenterStateProps & VilkarstatusHenterDispatchProps>;

class VilkarstatusHenter extends React.Component<PropsType & React.PropsWithChildren, unknown> {

    public componentDidUpdate(prevProps: Readonly<PropsType>) {
        if (this.props.skalHenteVilkarstatus && (this.harEndretDrosjeloyvenummer(prevProps) || this.harEndretKjennemerke(prevProps) || this.harEndretKjoringensArt(prevProps))) {
            this.props.hentVilkarstatus(this.props.registreringSakRequest);
        }

        if (harPopulertNySak(prevProps, this.props)) {
            this.props.hentVilkarstatus(this.props.registreringSakRequest);
        }

        if (!prevProps.registreringSakRequest?.saksType && !!this.props.registreringSakRequest?.saksType && !erRegistreringAvsluttet(this.props.sakResponse)) {
            this.props.hentVilkarstatus(this.props.registreringSakRequest);
        }

        if (harForsoktFatteVedtak(prevProps, this.props) && this.props.sakResponse.saksstatus === Saksstatus.APEN) {
            this.props.hentVilkarstatus(this.props.registreringSakRequest);
        }
    }

    private harEndretDrosjeloyvenummer(prevProps: PropsType) {
        return prevProps.registreringSakRequest.drosjeloyvenummer !== this.props.registreringSakRequest.drosjeloyvenummer;
    }

    private harEndretKjennemerke(prevProps: PropsType) {
        return !_.isEqual(prevProps.registreringSakRequest.kjennemerke, this.props.registreringSakRequest.kjennemerke);
    }

    private harEndretKjoringensArt(prevProps: PropsType) {
        return !_.isEqual(prevProps.registreringSakRequest.kjoringensArt, this.props.registreringSakRequest.kjoringensArt);
    }

    public render() {
        return this.props.children;
    }
}

const mapStateToProps = (state: RootStateType): VilkarstatusHenterStateProps => ({
    registreringSakRequest: state.registreringSak,
    sakResponse: state.sak
});

const mapDispatchToProps = (dispatch): VilkarstatusHenterDispatchProps => ({
    hentVilkarstatus: (saksinformasjon) => dispatch(kontrollerVilkar(saksinformasjon))
});
const VilkarstatusHenterConnected = connect<VilkarstatusHenterStateProps, VilkarstatusHenterDispatchProps, VilkarstatusHenterProps>(mapStateToProps, mapDispatchToProps)(VilkarstatusHenter);

export { VilkarstatusHenterConnected };