import { AkLoading } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Kunde } from '../../models';
import type { IError, IGetKundeMedKundeId, IGetKundeMedOrgFnr, IKunder, RootStateType } from '../../models/types';
import { getKunde } from '../../state/actions';
import { STANDARD_DATO_FORMAT } from '../../utils';

interface IKundeReadOnlyDispatchProps {
    getKunde?: (nummer: IGetKundeMedKundeId | IGetKundeMedOrgFnr) => any;
}

interface IKundeReadOnlyPanelProps {
    kunde: Kunde;
}

interface IKundeReadOnlyStateProps {
    kunder?: IKunder;
}

class KundePanelReadOnly extends Component<IKundeReadOnlyPanelProps & IKundeReadOnlyDispatchProps & IKundeReadOnlyStateProps> {

    public state = {
        kunde: null,
        kundeError: null,
        isLoading: false
    };

    public componentDidMount(): void {
        this.getKunde();
    }

    public render(): React.ReactElement {
        const isLoading = this.state.isLoading;

        return (
            <div className="row ak-panel-lysegra ml-1 mb-3">
                <div className="col-12 row no-gutters">
                    {this.state.kunde && <h2><FormattedMessage id={`registrering.overskrift.${(this.props.kunde.type || '').toLowerCase()}`} /></h2>}
                    {this.state.kunde && this.leggTilKundeInfo(this.state.kunde)}
                    {isLoading && <AkLoading />}
                    {this.getKundeErrorMessage()}
                </div>
            </div>
        );
    }

    private leggTilKundeInfo = (kunde: Kunde) => {
        const isOrg = kunde.erOrganisasjon();
        return (
            <div className="col-12">
                <dl className="ak-dl-liste ak-dl-liste-kompakt row col">
                    <dt className="col-3 text-truncate" key="organisasjonsnummer-text">
                        <FormattedMessage id={`registrering.label.${isOrg ? 'organisasjon' : 'fodselsdato'}`} />
                    </dt>
                    <dd className="col-9" key="organisasjonsnummer-navn">
                        <span data-key="organisasjon-nummer">{isOrg ? kunde.organisasjonsnummer : moment(kunde.fodselsdato).format(STANDARD_DATO_FORMAT)}</span>
                    </dd>
                    <dt className="col-3">
                        <FormattedMessage id={'registrering.label.navn'}/>
                    </dt>
                    <dd className="col-9">
                        <span data-key="organisasjon-navn">{kunde.navn}</span>
                    </dd>
                </dl>
            </div>
        );
    };

    private getKunde = () => {
        let kundePromise;

        if (this.props.kunde.kundeId) {
            kundePromise = this.props.getKunde({ kundeId: this.props.kunde.kundeId });
        } else {
            kundePromise = this.props.getKunde({ orgFnr: this.props.kunde.organisasjonsnummer });
        }

        kundePromise.then(() => {
            let kunde;

            if (this.props.kunder) {
                if (this.props.kunde.kundeId) {
                    kunde = this.props.kunder[this.props.kunde.kundeId];
                } else {
                    kunde = this.props.kunder[this.props.kunde.organisasjonsnummer];
                }
            } else {
                kunde = this.props.kunde;
            }

            if (kunde.error) {
                this.setState({ kundeError: kunde.error, isLoading: false });
                return;
            } else if (kunde.notFound) {
                this.setState({ kundeError: { errorCode: 'ikkeFunnet' } as IError, isLoading: false });
                return;
            }

            const aktor = _.omit(kunde, ['isLoading', 'error', 'notFound']);
            const nyAktor = new Kunde({ ...aktor, type: this.props.kunde.type });
            this.setState({ kunde: nyAktor });
        });
    };

    private getKundeErrorMessage() {
        const error = this.state.kundeError;
        if (error) {
            return (
                <p className="ak-text-error col">
                    <FormattedMessage
                        id={`feilhandtering.kunde.${error.errorId ? 'error' : error.errorCode}`}
                        values={{ errorId: <span className="text-nowrap">{error.errorId}</span> }} />
                </p>
            );
        }

        return null;
    }
}

const mapDispatchToProps = (dispatch): IKundeReadOnlyDispatchProps => ({
    getKunde: (nummer: IGetKundeMedKundeId | IGetKundeMedOrgFnr) => dispatch(getKunde(nummer))
});

const mapStateToProps = (state: RootStateType): IKundeReadOnlyStateProps => ({
    kunder: state.kunder
});

const KundePanelReadOnlyConnected = connect(mapStateToProps, mapDispatchToProps)(KundePanelReadOnly);

export { KundePanelReadOnlyConnected, KundePanelReadOnly };
