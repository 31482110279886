import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import type { IArbeidslisteRad } from '../../models/types';
import { urlForArbeidslisteRad } from '../../utils';
import { ArbeidslisteCardContentMedium } from './arbeidsliste-card-content-medium';
import { ArbeidslisteCardContentSmall } from './arbeidsliste-card-content-small';
import type { IRadConfigProps } from './arbeidsliste-config';

import './arbeidsliste-style';

interface IArbeidslisteCardProps {
    rad: IArbeidslisteRad;
    radConfig: IRadConfigProps[];
    nyRad: boolean;
    isForstegangsregistrering: boolean;
    openModalForGodkjennEierskifte?: (sakId: string) => void;
    openModalForPurring?: (sakId: string) => void;
}

const Card = styled.li`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 0.4rem;
    padding: 0.6rem;
    border: 2px solid transparent;
    
    &:hover {
      cursor: pointer;
      background-color: #e6f0f4;
    }
    
    &:focus {
      outline: none;
      border: 2px solid #ff9600;
    }
`;

/**
 * Her rendres det alltid to komponenter og visningen bestemmes av CSS og media-queries
 * basert på skjermbredde
 */
const ArbeidslisteCard: React.FC<IArbeidslisteCardProps> = props => {
    const navigate = useNavigate();

    const rowInteractionDependencies = [props.rad.kuid, props.rad.saksType, props.rad.sakId];

    const handleOnRowClick = React.useCallback(() => {
        navigate(urlForArbeidslisteRad(props.rad));
    }, rowInteractionDependencies);

    const handleOnKeyUp = React.useCallback((e: React.KeyboardEvent<HTMLLIElement>) => {
        if (e.key === 'Enter') {
            handleOnRowClick();
        }
    }, rowInteractionDependencies);

    return (
        <Card onClick={handleOnRowClick} onKeyUp={handleOnKeyUp} tabIndex={0}>
            <ArbeidslisteCardContentMedium {...props} className="arbeidsliste-card-content-medium" />
            <ArbeidslisteCardContentSmall {...props} className="arbeidsliste-card-content-small" />
        </Card>
    );
};

export { ArbeidslisteCard, Card };
