import * as React from 'react';
import type { MouseEvent, ReactNode } from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class TextTruncate extends Component<{text: string}, {expanded: boolean}> {

    public constructor(props: {text: string}) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    public render(): ReactNode {
        return (
            <div className="ak-toggle-ellipsis">
                <div className={this.state.expanded ? '' : 'text-truncate'}>
                    {this.props.text}
                </div>
                <a href="#" onClick={this.handleClick}>
                    <FormattedMessage id={this.state.expanded ? 'generell.visMindre' : 'generell.visMer'} />
                </a>
            </div>
        );
    }

    private handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        this.setState({...this.state, expanded: !this.state.expanded});
    };
}

export { TextTruncate };
