import { KundeActionKey } from '../../constants';
import type { IKunde, IKunder } from '../../models/types';
import { formaterKundedata } from '../../utils';
import type { KundeActionType } from '../actions';

const initialState: IKunde = {
    isLoading: false,
    notFound: false,
    error: null,
    merknader: null
};

export function kunder(state: IKunder = {} as IKunder, action: KundeActionType): IKunder {
    switch (action.type) {
        case KundeActionKey.KUNDE_LOADING:
            if (action.isLoading) {
                return resetKunde(state, action, {isLoading: action.isLoading, error: null});
            }
            return updateKunde(state, action, {isLoading: action.isLoading});
        case KundeActionKey.KUNDE_NOT_FOUND:
            return resetKunde(state, action, {notFound: true});
        case KundeActionKey.KUNDE_NOT_SHOW:
            return resetKunde(state, action, { ...action.kunde });
        case KundeActionKey.KUNDE_HAS_ERROR:
            return resetKunde(state, action, {error: action.error});
        case KundeActionKey.KUNDE_REMOVE:
            const nyState = {...state};
            const kunde = nyState[action.nummer];
            if (kunde) {
                delete nyState[kunde.kundeId];
                delete nyState[action.nummer];
            }
            return nyState;
        case KundeActionKey.KUNDE_FETCH_DATA_SUCCESS:
            return updateKunde(state, action, {...action.kunde, isLoading: false});
        case KundeActionKey.KUNDE_FORHANDLER:
            return updateKunde(state, action, {forhandler: action.forhandler});
        default:
            return state;
    }
}

const updateKunde = (state: IKunder, action: KundeActionType, payload: Partial<IKunde>): IKunder => {
    const existingKunde = state[action.nummer];
    const kunde = !!existingKunde ? {...existingKunde, ...payload} : {...initialState, ...payload};
    return nyKundeState(state, action.nummer, formaterKundedata(kunde));
};

const resetKunde = (state: IKunder, action: KundeActionType, payload: Partial<IKunde>): IKunder => {
    const kunde = {...initialState, ...payload};
    return nyKundeState(state, action.nummer, formaterKundedata(kunde));
};

const nyKundeState = (state: IKunder, nummer: string, kunde: IKunde): IKunder => {
    if (nummer === kunde.kundeId || !kunde.kundeId) {
        if (kunde.organisasjonsnummer && nummer !== kunde.organisasjonsnummer) {
            return {...state, [nummer]: kunde, [kunde.organisasjonsnummer]: kunde};
        } else {
            return {...state, [nummer]: kunde};
        }
    } else {
        return {...state, [nummer]: kunde, [kunde.kundeId]: kunde};
    }
};
