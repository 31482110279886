import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import type { IntlShape, WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AkTextTruncate, Panel } from 'svv-tk-akr-common-frontend';
import { DlTable } from '../../components';
import type { IGodkjenning, IKjennemerke, IKjoretoy, IKjoringensArt, IKunde, IRegistrering, ITekniskeData, RootStateType } from '../../models/types';
import { KjoretoyRegler } from '../../regler';
import { kjoretoySelector } from '../../state/selectors';
import { formatStoreBokstaverUtenWhitespaceEllerDash, getFargeFraTekniskeData } from '../../utils';

interface IKjoretoydetaljerGenereltProps {
    godkjenning: IGodkjenning;
    registrering: IRegistrering;
    kjennemerke: string;
    kjoretoy?: IKjoretoy;
}

interface IKjoretoydetaljerGenereltState {
    expanded: boolean;
    kjoretoy?: IKjoretoy;
}

const TRUNCATE_TEXT_LIMIT = 25;

class KjoretoydetaljerGenerelt extends Component<IKjoretoydetaljerGenereltProps & WrappedComponentProps, IKjoretoydetaljerGenereltState> {
    public state = {
        expanded: false
    };

    public render(): React.ReactElement {
        const { godkjenning, registrering, intl } = this.props;
        const tegnkombinasjon = registrering.kjennemerke?.tegnkombinasjon?.replace(' ', '');

        return (
            <Panel
                overskrift={'kjoretoydetaljer.generelt.overskrift'}
                panelCssClass="ak-med-tabell ak-panel-transparent"
                innholdCssClass="row no-gutters">
                <DlTable
                    customDlCss="col-12 col-md-6"
                    intlLabelKey={'kjoretoydetaljer.generelt.label'}
                    listMap={this.extractGenereltLeftColumnData(registrering, godkjenning, intl)}
                />
                <div className="col-12 col-md-6">
                    <DlTable
                        intlLabelKey={'kjoretoydetaljer.generelt.label'}
                        listMap={this.extractGenereltRightColumnData(registrering, godkjenning, intl)}
                    />
                    <ul className="ak-liste my-2 ml-2">
                        <li>{registrering.kjennemerke && <ExternalLink intlKey="kjoretoydetaljer" kjennemerke={tegnkombinasjon} intl={intl} />}</li>
                        <li>{registrering.kjennemerke && <ExternalLink intlKey="bronnoysund" kjennemerke={tegnkombinasjon} intl={intl} />}</li>
                    </ul>
                </div>
            </Panel>
        );
    }

    private extractGenereltLeftColumnData = (registrering: IRegistrering, godkjenning: IGodkjenning, intl: IntlShape): Record<string, any> => {
        const {
            eier = {} as IKunde,
            medeier = {} as IKunde,
            leietaker = '',
            underenhet = '',
            kjennemerke = {} as IKjennemerke,
            personligKjennemerke = {} as IKjennemerke
        } = registrering || {};

        const {
            tekniskeData = {} as ITekniskeData,
            merke = '',
            tekniskKode = '',
            tekniskUnderkode = '',
            avgiftskode = ''
        } = godkjenning || {};

        let klassifisering = '';

        const { kjennemerkestorrelseForan, kjennemerkestorrelseBak, kjennemerkestorrelseVenstre, handelsbetegnelse = '' } = tekniskeData;

        if (tekniskKode && tekniskUnderkode && avgiftskode) {
            klassifisering = intl.formatMessage({ id: `kodeverk.klassifisering.${tekniskKode}.${tekniskUnderkode}.${avgiftskode}` });
        }

        const farge = getFargeFraTekniskeData(tekniskeData);

        const eiere: {
            [key: string]: string
        } = {
            eier: eier.navn,
            medeier: medeier.navn
        };

        if (leietaker) {
            eiere.leietaker = leietaker.navn;
        }

        if (underenhet) {
            eiere.underenhet = underenhet.navn;
        }

        if (new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering()) {
            const forsteKjennemerkeTekst = kjennemerkestorrelseForan ? kjennemerkestorrelseForan : kjennemerkestorrelseVenstre || '';
            const genereltLeftColumnData: {
                [key: string]: string
            } = {
                ...eiere,
                merkeoghandelsbetegnelse: `${merke} ${handelsbetegnelse}`,
                farge,
                klassifisering: this.utledKjoretoyklassifisering(tekniskUnderkode, tekniskKode, klassifisering),
                kjennemerkefarge: intl.formatMessage({ id: `kodeverk.kjennemerketype.${kjennemerke.kjennemerkeType}.farge` }),
                kjennemerkestorrelse: kjennemerkestorrelseForan || kjennemerkestorrelseBak || kjennemerkestorrelseVenstre ? `${forsteKjennemerkeTekst} / ${kjennemerkestorrelseBak || ''}` : ''
            };

            const tidligereKjennemerke = this.utledTidligereKjennemerke(this.props.kjennemerke, kjennemerke.tegnkombinasjon, personligKjennemerke.tegnkombinasjon);

            if (tidligereKjennemerke) {
                genereltLeftColumnData.tidligereKjennemerke = tidligereKjennemerke;
            }

            return genereltLeftColumnData;
        }

        return {
            merkeoghandelsbetegnelse: `${merke} ${handelsbetegnelse}`,
            farge,
            klassifisering: this.utledKjoretoyklassifisering(tekniskUnderkode, tekniskKode, klassifisering),
            kjennemerkestorrelse: kjennemerkestorrelseForan || kjennemerkestorrelseBak ? `${kjennemerkestorrelseForan || ''} / ${kjennemerkestorrelseBak || ''}` : ''
        };
    };

    private utledKjoretoyklassifisering(tekniskUnderkode: string, tekniskKode: string, klassifisering: string) {
        return tekniskUnderkode === 'INGENKODE' ?
               `${tekniskKode} ${klassifisering}` :
               `${tekniskKode} ${tekniskUnderkode} ${klassifisering}`;
    }

    private extractGenereltRightColumnData = (registrering: IRegistrering, godkjenning: IGodkjenning, intl: IntlShape): Record<string, any> => {
        const {
            bruksinformasjon = {
                kjoringensArt: {} as IKjoringensArt
            }
        } = registrering;

        const {
            forstegangsgodkjenning = {
                bruktimport: false,
                oppbygdMedAvgiftsfritak: false,
                godkjenningId: ''
            },
            understellsnummer = '',
            typegodkjenningsnummer = ''
        } = godkjenning;

        const bruktimport = intl.formatMessage({ id: forstegangsgodkjenning.bruktimport ? 'generell.ja' : 'generell.nei' });
        const avgiftsfritak = intl.formatMessage({ id: forstegangsgodkjenning.oppbygdMedAvgiftsfritak ? 'generell.ja' : 'generell.nei' });

        const genereltRightColumnData: {
            [key: string]: any
        } = {
            understellsnummer,
            bruktimport,
            avgiftsfritak
        };

        if (new KjoretoyRegler(this.props.kjoretoy).harKjoretoyRegistrering()) {
            genereltRightColumnData.kjoringensArt = <AkTextTruncate truncateTextLengthTrigger={TRUNCATE_TEXT_LIMIT}
                text={bruksinformasjon.kjoringensArt.tekst} expanded={this.state.expanded} onExpandFn={this.onExpand} />;
        } else {
            genereltRightColumnData.typegodkjenningsnr = typegodkjenningsnummer;
        }

        return genereltRightColumnData;
    };

    private utledTidligereKjennemerke = (sokeparameterOrig: string, kjennemerkeOrig: string, personligKjennemerkeOrig: string): string => {
        const [sokeparameter, kjennemerke, personligKjennemerke] = _.map([sokeparameterOrig, kjennemerkeOrig, personligKjennemerkeOrig], formatStoreBokstaverUtenWhitespaceEllerDash);
        if (!sokeparameter || sokeparameter === kjennemerke || sokeparameter === personligKjennemerke) {
            return undefined;
        } else {
            const [, bokstaver, nummer] = sokeparameter.match(/^([A-Z]{1,2})(\d+)$/) || [null, null, null];
            if (!(bokstaver && nummer)) {
                return sokeparameter;
            } else {
                const skilletegn = bokstaver.length === 1 ? '-' : ' ';
                const [, , restenAvNummeret] = nummer.match(/^(0+)(\d+)$/) || [null, null, null];
                if (!restenAvNummeret) {
                    return `${bokstaver}${skilletegn}${nummer}`;
                } else {
                    const nyKombo = `${bokstaver}${skilletegn}${restenAvNummeret}`;
                    return nyKombo !== kjennemerkeOrig ? nyKombo : undefined;
                }
            }
        }
    };

    private onExpand = () => {
        this.setState(prevState => ({ expanded: !prevState.expanded }));
    };
}

const ExternalLink: React.FC<{intlKey: string; kjennemerke: string} & WrappedComponentProps> = (props) => (
    <a target="_blank" title={props.intl.formatMessage({ id: `kjoretoydetaljer.link.${props.intlKey}.tekst` })} rel="noopener noreferrer"
        href={props.intl.formatMessage({ id: `kjoretoydetaljer.link.${props.intlKey}.url` }, { kjennemerke: (props.kjennemerke || '') })}>
        <FormattedMessage id={`kjoretoydetaljer.link.${props.intlKey}.tekst`} />
    </a>
);

const mapStateToProps = (state: RootStateType) => ({
    kjoretoy: kjoretoySelector(state)
});

const KjoretoydetaljerGenereltConnected = compose(
    connect<any, any, IKjoretoydetaljerGenereltProps>(mapStateToProps),
    injectIntl
)
(KjoretoydetaljerGenerelt);
export { KjoretoydetaljerGenereltConnected };
