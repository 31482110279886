import { AkKnapp } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface IEierskifteKnapperadProps {
    lesemodus: boolean;
    apneModalForLukk: () => any;
    validerOgSendTilGodkjenning: () => any;
    eierskiftevalidering: boolean;
    sakErTilGodkjenning: boolean;
    sakMaGodkjennesHosSaksbehandler: boolean;
    endreSakErTilGodkjenningHosSaksbehandler: () => void;
}

const EierskifteKnapperad: React.FC<IEierskifteKnapperadProps> = (props) => {
    function renderHovedknapp() {
        if (props.sakMaGodkjennesHosSaksbehandler) {
            return <AkKnapp type="ak-hovedknapp" disabled={!props.eierskiftevalidering} action={props.endreSakErTilGodkjenningHosSaksbehandler}
                intlKey={props.sakErTilGodkjenning ? 'generell.knapp.trekkTilbake' : 'generell.knapp.sendTilSaksbehandler'} />;
        } else if (!props.lesemodus) {
            return <AkKnapp type="ak-hovedknapp" disabled={!props.eierskiftevalidering}
                action={props.validerOgSendTilGodkjenning} intlKey="eierskifte.knapp.sendTilGodkjenning" />;
        }
    }

    function renderLukknapp() {
        return props.lesemodus && !props.sakMaGodkjennesHosSaksbehandler ?
               <Link className="ak-knapp ak-hovedknapp" to="/startside"><FormattedMessage id="generell.lukk" /></Link> :
               <AkKnapp type="ak-tilleggsknapp" action={props.apneModalForLukk} intlKey="generell.lukk" />;
    }

    return (
        <>
            {renderLukknapp()}
            {renderHovedknapp()}
        </>
    );
};

export { EierskifteKnapperad };