export enum Personstatus {
    BOSATT = 'BOSATT',
    UTFLYTTET = 'UTFLYTTET',
    UTVANDRET = 'UTVANDRET',
    FORSVUNNET = 'FORSVUNNET',
    DOD = 'DOD',
    UTGATT_FODSELSNUMMER = 'UTGATT_FODSELSNUMMER',
    FODSELSREGISTRERT = 'FODSELSREGISTRERT',
    ANNULLERT_TILGANG = 'ANNULLERT_TILGANG',
    IKKE_OPPGITT = 'IKKE_OPPGITT',
    UREGISTRERT_PERSON = 'UREGISTRERT_PERSON',
    UKJENT_STATUS = 'UKJENT_STATUS'
}
