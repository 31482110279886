import * as React from 'react';
import styled from 'styled-components';

import type { IArbeidslisteRad } from '../../models/types';
import { CardCell } from './arbeidsliste-card-cell';
import type { IRadConfigProps} from './arbeidsliste-config';
import { RadConfigOrder } from './arbeidsliste-config';

interface IArbeidslisteCardContentMediumProps {
    rad: IArbeidslisteRad;
    radConfig: IRadConfigProps[];
    openModalForGodkjennEierskifte?: (sakId: string) => void;
    openModalForPurring?: (sakId: string) => void;
    isForstegangsregistrering: boolean;
    className?: string;
}

const Container = styled.div`
  position: relative;
`;

const FirstRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 120px 1fr 1fr 2fr;
  margin-bottom: 0.75rem;
`;

const SecondRow = styled.div<{ $isForstegangsregistrering: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${ props => props.$isForstegangsregistrering ? '180px 2fr 1fr 2fr' : '120px 1fr 1fr 2fr' };
  margin-bottom: 0.5rem;
`;

const ThirdRow = (props: React.PropsWithChildren) => (<div>{props.children}</div>);

const ArbeidslisteCardContentMedium: React.FC<IArbeidslisteCardContentMediumProps> = props => {
    const { className, rad, radConfig, openModalForGodkjennEierskifte, openModalForPurring, isForstegangsregistrering } = props;
    const commonProps = { rad, openModalForGodkjennEierskifte, openModalForPurring };

    return (
        <Container className={className}>
            <FirstRow>
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.SIST_ENDRET]} />
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.STATUS]} />
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.DOKUMENTASJONSSTATUS]} />
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.KNAPPER]} className="align-items-end" hasLabel={false} />
            </FirstRow>
            <SecondRow $isForstegangsregistrering={isForstegangsregistrering}>
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.KJENNEMERKE]} hasLabel={false} />
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.KJORETOY]} hasLabel={false} />
                { !isForstegangsregistrering && <CardCell {...commonProps} config={radConfig[RadConfigOrder.GJELDER]} className="ak-fet" hasLabel={false} /> }
                { isForstegangsregistrering && <CardCell {...commonProps} config={radConfig[RadConfigOrder.ORDRENR]} /> }
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.EIER]} />
            </SecondRow>
            <ThirdRow>
                <CardCell {...commonProps} config={radConfig[RadConfigOrder.OPPRETTET_AV]} />
            </ThirdRow>
        </Container>
    );

};

export { ArbeidslisteCardContentMedium };
