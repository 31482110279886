import * as _ from 'lodash';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { AkrConfig, SakActionKey, SakerActionKey } from '../../../constants';
import { MeldingType } from '../../../models/kodeverk';
import type { IArkivdokument, IError, IFinnSakerFilter, IMerknad, ISak, ISakMinimalResponse, ISaksinformasjon, RootStateType, ThunkResult } from '../../../models/types';
import { createRestResource } from '../../store';
import { uploadForstegangsregistreringArkivdokument } from '../forstegangsregistrering-arkivdokumenter';
import type { GlobalErrorActionType } from '../globals';
import { addGlobalErrors } from '../globals';
import { nyMelding, visTekniskFeil } from '../melding-bar';
import { isError } from '../registreringer-arkivdokumenter';

export type SakActionType =
    ISakIsLoadingAction
    | ISakLoadingModalAction
    | ISakRequestSuccessAction
    | ISakRequestErrorAction
    | ISakerRemoveSuccessAction
    | ISakResetAction
    | ISakUpdateMerknaderAction
    | ISakDokumentErrorAction;

export interface ISakIsLoadingAction {
    type: SakActionKey.SAK_IS_LOADING;
    isLoading: boolean;
}

interface ISakLoadingModalAction {
    type: SakActionKey.SAK_LOADING_MODAL;
    isOpen: boolean;
}

interface ISakRequestSuccessAction {
    type: SakActionKey.SAK_REQUEST_SUCCESS;
    response: ISak<any>;
}

interface ISakerRemoveSuccessAction {
    type: SakActionKey.SAKER_REMOVE_SUCCESS;
    response: ISak<any>;
}

interface ISakRequestErrorAction {
    type: SakActionKey.SAK_REQUEST_ERROR;
    error: IError;
}

interface ISakUpdateMerknaderAction {
    type: SakActionKey.SAK_UPDATE_MERKNADER;
    merknader: IMerknad[];
    isLoading: boolean;
}

interface ISakResetAction {
    type: SakActionKey.SAK_RESET;
}

interface ISakDokumentErrorAction {
    type: SakActionKey.SAK_DOCUMENT_ERROR;
    error: IError;
}

export type SakerActionType =
    ISakerLoadingAction
    | ISakerFetchDataSuccessAction;

export interface ISakerLoadingAction {
    type: SakerActionKey.SAKER_IS_LOADING;
    isLoading: boolean;
    query: IFinnSakerFilter;
}

export interface ISakerFetchDataSuccessAction {
    type: SakerActionKey.SAKER_FETCH_DATA_SUCCESS;
    query: IFinnSakerFilter;
    saker: ISakMinimalResponse;
}

export const sakerIsLoadingAction = (isLoading: boolean, query: IFinnSakerFilter): ISakerLoadingAction => ({type: SakerActionKey.SAKER_IS_LOADING, isLoading, query});
export const sakerFetchDataSuccessAction = (query: IFinnSakerFilter, saker: ISakMinimalResponse): ISakerFetchDataSuccessAction => ({type: SakerActionKey.SAKER_FETCH_DATA_SUCCESS, query, saker});

export const sakIsLoadingAction = (isLoading: boolean): ISakIsLoadingAction => ({ type: SakActionKey.SAK_IS_LOADING, isLoading });
export const sakLoadingModalAction = (isOpen: boolean): ISakLoadingModalAction => ({ type: SakActionKey.SAK_LOADING_MODAL, isOpen });
export const sakRequestSuccessAction = (response: ISak<any>): ISakRequestSuccessAction => ({ type: SakActionKey.SAK_REQUEST_SUCCESS, response });
export const sakerRemoveSuccessAction = (response: ISak<any>): ISakerRemoveSuccessAction => ({ type: SakActionKey.SAKER_REMOVE_SUCCESS, response });
export const sakRequestErrorAction = (error: IError): ISakRequestErrorAction => ({ type: SakActionKey.SAK_REQUEST_ERROR, error });
export const sakUpdateMerknaderAction = (merknader: IMerknad[], isLoading: boolean): ISakUpdateMerknaderAction => ({type: SakActionKey.SAK_UPDATE_MERKNADER, merknader, isLoading});
const sakResetAction = (): ISakResetAction => ({ type: SakActionKey.SAK_RESET });
const sakDocumentErrorAction = (error: IError): ISakDokumentErrorAction => ({type: SakActionKey.SAK_DOCUMENT_ERROR, error});

const api = createRestResource(AkrConfig.SAKER_RESOURCE_URL);

const SYMANTEC_UGYLDIG_FIL = 'akr.integration.app.00028';
const SYMANTEC_ER_UTILGJENGELIG = 'akr.integration.app.00029';
const UGYLDIG_FILFORMAT = 'akr.integration.app.00030';
const MAKS_ANTALL_ARKIVDOKUMENTER_OVERSKREDET = 'akr.sak.app.00041';

const handleEndreEllerAbrytSakResponse = <T extends ISaksinformasjon>(dispatch: ThunkDispatch<RootStateType, undefined, SakActionType>, response: ISak<T> & IError): ISak<T> => {
    if (isError(response) && response.errorId) {
        dispatch(sakRequestErrorAction(response));
        dispatch(visTekniskFeil(response));
        dispatch(addGlobalErrors(response));
        return {
            error: response
        };
    }
    dispatch(sakRequestSuccessAction(response));
    return response;
};

export const endreSak = <T extends ISaksinformasjon>(sak: ISak<T>, shouldLoadInModal = false): ThunkAction<Promise<ISak<T> & IError>, RootStateType, undefined, SakActionType> => dispatch => {
    dispatch(shouldLoadInModal ? sakLoadingModalAction(true) : sakIsLoadingAction(true));
    return api.put(sak, sak.sakId)
        .then((response: ISak<T> & IError) => {
            return handleEndreEllerAbrytSakResponse(dispatch, response);
        });
};

export const endreSakUtenGlobalErrors  = <T extends ISaksinformasjon>(sak: ISak<T>, shouldLoadInModal = false): ThunkResult<Promise<ISak<T>>, SakActionType> => {
    return endreSakUtenGlobalErrorsMedFilopplasting(sak, shouldLoadInModal, null);
};

export const endreSakUtenGlobalErrorsMedFilopplasting = <T extends ISaksinformasjon>(sak: ISak<T>, shouldLoadInModal: boolean, arkivdokument: IArkivdokument): ThunkAction<Promise<ISak<T>>,
    RootStateType, undefined, SakActionType> => dispatch => {
    dispatch(shouldLoadInModal ? sakLoadingModalAction(true) : sakIsLoadingAction(true));
    return api.put(sak, sak.sakId)
        .then((response: ISak<T> & IError) => {
            if (response.errorId) {
                return dispatch(sakRequestErrorAction(response));
            }

            if (arkivdokument != null) {
                dispatch(sakLoadingModalAction(false));
                return dispatch(uploadForstegangsregistreringArkivdokument(sak.sakId, arkivdokument));
            }

            dispatch(sakRequestSuccessAction(response));
            return response;
        });
};

export const opprettSak = <T extends ISaksinformasjon>(sak: ISak<T>, shouldLoadInModal = false): ThunkAction<Promise<ISak<T>>, RootStateType, undefined, SakActionType> => dispatch => {
    dispatch(shouldLoadInModal ? sakLoadingModalAction(true) : sakIsLoadingAction(true));
    return api.post(sak)
        .then((response: ISak<T> & IError) => {
            if (response.errorId) {
                dispatch(sakRequestErrorAction(response));
                dispatch(visTekniskFeil(response));
                return response;
            }

            dispatch(sakRequestSuccessAction(response));
            return response;
        });
};

export const hentSak = <T extends ISaksinformasjon>(sakId: string): ThunkAction<Promise<ISak<T> | void>, RootStateType, undefined, SakActionType | GlobalErrorActionType> => dispatch => {
    dispatch(sakIsLoadingAction(true));
    return api.get(sakId)
        .then((response: ISak<T> & IError) => {
            if (!response || response.errorId) {
                dispatch(sakRequestErrorAction(response));
                return dispatch(addGlobalErrors(response));
            }

            dispatch(sakRequestSuccessAction(response));
            return response;
        });
};

export const hentSaker = (filter: IFinnSakerFilter): ThunkAction<Promise<ISakMinimalResponse | void>, RootStateType, undefined, SakActionType | SakerActionType> => dispatch => {
    dispatch(sakerIsLoadingAction(true, filter));

    return api.get(null, _.omitBy({...filter}, _.isNil), `${AkrConfig.SAKER_RESOURCE_URL}/hent`)
        .then((sakerResponse: ISakMinimalResponse) => {
            if (!sakerResponse || isError(sakerResponse)) {
                return dispatch(addGlobalErrors(sakerResponse as IError));
            }
            dispatch(sakerFetchDataSuccessAction(filter, sakerResponse));
            return sakerResponse;
        });
};

export const hentSakPromise = <T extends ISaksinformasjon>(sakId: string): Promise<ISak<T> | void> => api.get(sakId);

export const avbrytSak = <T extends ISaksinformasjon>(sakId: string): ThunkAction<Promise<ISak<T>>, RootStateType, undefined, SakActionType> => dispatch => {
    dispatch(sakIsLoadingAction(true));
    return api.remove(sakId)
        .then((response: ISak<T> & IError) => {
            return handleEndreEllerAbrytSakResponse(dispatch, response);
        });
};

export const resetSak = (): ThunkAction<void, RootStateType, undefined, SakActionType> => dispatch => {
    dispatch(sakResetAction());
};

const arkivdokumentApi = createRestResource(AkrConfig.ARKIVDOKUMENTER_RESOURCE_URL);

export const uploadDocument = (sakId: string, fileWithMeta: IArkivdokument): ThunkAction<Promise<any>, RootStateType, undefined, ISakDokumentErrorAction> => dispatch => {
    const form = new FormData();
    form.append('fil', fileWithMeta.fil, fileWithMeta.filnavn);
    form.append('arkivdokument', new Blob([JSON.stringify(_.omit(fileWithMeta, 'fil'))], {type: 'application/json'}));
    return arkivdokumentApi.upload(form, {sakId}).then((response) => {
        if (isError(response)) {
            dispatch(sakDocumentErrorAction(response));

            if (response.errorResponseCause?.errorCode === SYMANTEC_UGYLDIG_FIL) {
                dispatch(nyMelding({meldingIntlId: 'dokumenterpanel.feilmeldinger.skadligFil', meldingType: MeldingType.DANGER}));
            } else if (response.errorResponseCause?.errorCode === MAKS_ANTALL_ARKIVDOKUMENTER_OVERSKREDET) {
                dispatch(nyMelding({meldingIntlId: 'dokumenterpanel.feilmeldinger.maksimaltAntallFiler', meldingType: MeldingType.DANGER}));
            } else if (response.errorResponseCause?.errorCode === SYMANTEC_ER_UTILGJENGELIG) {
                dispatch(nyMelding({meldingIntlId: 'dokumenterpanel.feilmeldinger.virussjekkUtilgjengelig', meldingType: MeldingType.DANGER}));
            } else if (response.errorResponseCause?.errorCode === UGYLDIG_FILFORMAT) {
                dispatch(nyMelding({meldingIntlId: 'dokumenterpanel.feilmeldinger.filStottesIkke', meldingType: MeldingType.DANGER}));
            } else {
                dispatch(nyMelding({meldingIntlId: 'registrering.validering.dokumentasjonsstatusFeilet', meldingType: MeldingType.DANGER}));
            }
        }
        return response;
    });
};

export const deleteDocument = (sakId: string, dokumentId: number): Promise<any> => {
    return arkivdokumentApi.remove({id: dokumentId, sakId});
};

export const editDocument = (sakId: string, dokument: IArkivdokument): Promise<IArkivdokument> => {
    return arkivdokumentApi.put({...dokument, mimeType: null}, {id: dokument.id, sakId}) as Promise<IArkivdokument>;
};

export const hentDokumentasjonsstatus = <T extends ISaksinformasjon>(sak: ISak<T>) => (): Promise<IMerknad[]> => {
    return api.post(sak, null, null, AkrConfig.DOKUMENTASJONSSTATUS_RESOURCE_URL);
};
