import type {
    IAktorPanelFormProps,
    IAktorPanelAktor
} from 'svv-tk-akr-common-frontend';
import {
    AktorKundeinfo,
    AktorKontaktinfo,
    GodkjenningUtfortType
} from 'svv-tk-akr-common-frontend';

import * as React from 'react';

import { AktorSignatur } from '../../components';
import { harSoktOppAktor } from '../../utils';
import { AktorSignaturkundeConnected } from './aktor-signaturkunde';
import { VALID_TLF_MASKERINGS_REGEX } from "../../regler";

interface IAktorInfoProps {
    signaturtyper: GodkjenningUtfortType[];
    kanVelgeSignaturtype: boolean;
    visForhandsutfyltValg?: boolean;
    erSendtTilGodkjenning?: boolean;
}

type PropsType = IAktorPanelFormProps & IAktorInfoProps;

const skalViseSignaturKunde = (aktor: IAktorPanelAktor) => aktor.godkjenningUtfort === GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA && !!aktor.organisasjonsnummer;


const AktorInfo: React.FC<PropsType> = (props) => {
    if (harSoktOppAktor(props.aktor)) {
        return (
            <>
                <AktorKundeinfo aktor={props.aktor} handleAktorEndreKnappKlikk={props.handleAktorEndreKnappKlikk} />
                {props.kanVelgeSignaturtype &&
                    <AktorSignatur godkjenningUtfortTyper={props.signaturtyper} handleChange={props.handleAktorOppdatering} aktor={props.aktor} erSendtTilGodkjenning={props.erSendtTilGodkjenning} />}
                {props.aktor.visKontaktinfo &&
                    <AktorKontaktinfo aktor={props.aktor} validRegExp={VALID_TLF_MASKERINGS_REGEX} oppdaterAktor={props.handleAktorOppdatering} visForhandsutfyltValg={props.visForhandsutfyltValg} />}
                {skalViseSignaturKunde(props.aktor) && <AktorSignaturkundeConnected aktor={props.aktor} handleAktorChange={props.handleAktorOppdatering} />}
            </>
        );
    }

    return null;
};


export { AktorInfo };
