import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { AkLoading } from 'svv-tk-akr-common-frontend';

import type { IError, IMerknad, NavigateType, RootStateType } from '../../models/types';
import { kjoretoyGetDataAndClearRegSakAction } from '../../state/actions';
import { kuidSelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';

interface ISokStateProps {
    error: IError;
    kuid: string;
    merknader: IMerknad[];
    notFound: boolean;
}

interface ISokDispatchProps {
    finnKjoretoy: (navigate: NavigateType, q: string) => Promise<any>;
}

type PropsType = ISokStateProps & ISokDispatchProps & WithRouterProps;

class Sok extends React.Component<PropsType> {

    public componentDidMount() {
        const sokinput = _.toString(qs.parse(this.props.location.search.substring(1)).q) || '';
        this.props.finnKjoretoy(this.props.navigate, sokinput);
    }

    public componentDidUpdate() {
        if (this.props.notFound) {
            this.props.navigate('/feil/ikkefunnet');
        } else if (this.props.error) {
            this.props.navigate(`/feil/${this.props.error.errorCode}`);
        }
    }

    public render() {
        return <AkLoading />;
    }
}

const mapStateToProps = (state: RootStateType): ISokStateProps => ({
    error: state.kjoretoy.error,
    kuid: kuidSelector(state),
    merknader: state.kjoretoy.merknader,
    notFound: state.kjoretoy.notFound
});
const mapDispatchToProps = (dispatch): ISokDispatchProps => ({
    finnKjoretoy: (navigate: NavigateType, q: string) => dispatch(kjoretoyGetDataAndClearRegSakAction(navigate, q))
});

export const SokConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter)
(Sok);
