import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import { ActionKey } from '../../constants';
import type { IError, RootStateType } from '../../models/types';
import type { BrukerprofilActionType, OversettelserActionType} from '../actions';
import { brukerprofilGetData, oversettelserGetData } from '../actions';

export interface GlobalErrorActionType extends Action {
    error: IError;
}

export interface IHovedmenyToggleEkspandertAction extends Action {
    seksjonEkspandert: string;
}

export function hovedmenySeksjonEkspandert(seksjon: string): IHovedmenyToggleEkspandertAction {
    return {
        type: ActionKey.HOVEDMENY_SEKSJON_EKSPANDERT,
        seksjonEkspandert: seksjon
    };
}

export function globalErrors(type: string, error: IError): GlobalErrorActionType {
    return {
        type,
        error
    };
}

export function resetGlobalErrors(): ThunkAction<void, RootStateType, undefined, GlobalErrorActionType> {
    return dispatch => {
        dispatch(globalErrors(ActionKey.RESET_ERROR, null));
    };
}

export function addGlobalErrors(error: IError): ThunkAction<void, RootStateType, undefined, IHovedmenyToggleEkspandertAction | GlobalErrorActionType> {
    return dispatch => {
        dispatch(hovedmenySeksjonEkspandert(''));
        dispatch(globalErrors(ActionKey.ADD_ERROR, error));
    };
}

export function initSession(): ThunkAction<Promise<BrukerprofilActionType>, RootStateType, undefined, OversettelserActionType | BrukerprofilActionType> {
    return dispatch => {
        dispatch(oversettelserGetData('nb'));
        return dispatch(brukerprofilGetData());
    };
}
