import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import type { IMessageDescriptor, IVilkar} from 'svv-tk-akr-common-frontend';
import { AkMeldingboks, AkMeldingboksType, VilkarStatus } from 'svv-tk-akr-common-frontend';

import { Meldingsliste } from '../../components';
import { Saksstatus, Vilkarsnavn } from '../../models/kodeverk';
import type {IError, IKjoretoy, ISak, IVilkarstatus, RootStateType} from '../../models/types';
import { KjoretoyRegler, RegistreringRegler } from '../../regler';
import { brukerprofilSelector, kjoretoySelector, vilkarstatusSelector } from '../../state/selectors';

interface IRegistreringInfoMeldingerProps {
    registreringValidation: {
        submitted?: boolean;
        errors?: string[];
        valid?: boolean;
        errorId?: IError;
    };
    kjoretoy: IKjoretoy;
    vilkar?: IVilkar[];
    vilkarstatus?: IVilkarstatus,
    sak?: ISak,
    saksstatus?: Saksstatus;
}

class RegistreringInfoMeldinger extends React.Component<IRegistreringInfoMeldingerProps> {

    public render(): React.ReactElement {
        const bruksforbudFeilmeldinger = new KjoretoyRegler(this.props.kjoretoy).utledBruksforbudmeldinger();
        const feilmeldinger = RegistreringRegler.utledFeilmeldinger(this.props.kjoretoy.merknader);
        const infoOmKjoretoyet = RegistreringRegler.utledInfomeldinger(this.props.kjoretoy, this.props.sak, this.props.vilkarstatus);

        _.remove(infoOmKjoretoyet, (infoMelding) =>
            (this.props.saksstatus === Saksstatus.AVSLUTTET && _.includes(infoMelding.id, 'kjoretoydetaljer.meldinger.KJORETOY_LAREVOGN')
                || _.includes(bruksforbudFeilmeldinger.map(melding => melding.id), infoMelding.id)));

        const registreringValidation = this.props.registreringValidation;
        const skalViseInfomeldinger = !!infoOmKjoretoyet.length;
        const skalViseFeilmeldinger = registreringValidation && !registreringValidation.valid && registreringValidation.submitted
            && !_.isEmpty(registreringValidation.errors) || !_.isEmpty(bruksforbudFeilmeldinger) || !_.isEmpty(feilmeldinger);

        const erVilkarForDokumentasjonGodkjent = _.some(this.props.vilkar, vilkar =>
            vilkar.navn === Vilkarsnavn.DOKUMENTASJON && vilkar.status === VilkarStatus.OPPFYLT);

        if (!skalViseInfomeldinger && !skalViseFeilmeldinger) {
            return null;
        }

        return (
            <>
                {skalViseFeilmeldinger && this.renderFeilmelding(_.concat(this.convertMessages(this.props.registreringValidation.errors), bruksforbudFeilmeldinger, feilmeldinger))}
                {!erVilkarForDokumentasjonGodkjent && skalViseInfomeldinger && this.renderInfomelding(infoOmKjoretoyet)}
            </>
        );
    }

    private renderInfomelding = (infoOmKjoretoyet: IMessageDescriptor[]) => (
        <AkMeldingboks meldingType={AkMeldingboksType.INFO} customClassName="mt-3">
            <Meldingsliste meldinger={infoOmKjoretoyet} />
        </AkMeldingboks>
    );

    private renderFeilmelding = (errors: IMessageDescriptor[]) => (
        !!this.props.registreringValidation.errors && <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mb-3 mt-3" messageKey={errors} />
    );

    private convertMessages = (meldinger: string[]): IMessageDescriptor[] => (
        _.chain(meldinger)
            .map(merknad => ({id: merknad}))
            .uniqBy('id')
            .value()
    );

}

const mapStateToProps = (state: RootStateType) => ({
    kjoretoy: kjoretoySelector(state),
    brukerprofil: brukerprofilSelector(state),
    vilkarstatus: vilkarstatusSelector(state)
});

const RegistreringInfoMeldingerConnected = connect(mapStateToProps)(RegistreringInfoMeldinger);
export { RegistreringInfoMeldingerConnected };
