import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import type { IAkConfirmNavigationProps } from 'svv-tk-akr-common-frontend';
import { AkConfirmNavigation } from 'svv-tk-akr-common-frontend';

import { SaksType } from '../../models/kodeverk';
import type { IKjoretoy, RootStateType } from '../../models/types';
import { resetSak } from '../../state/actions/sak';
import { kanBehandleRegistreringSelector, kanStarteOmregistreringssakSelector, kanStarteParegistreringssakSelector, kuidSelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';
import { RegistreringConnected } from './registrering';

interface IRegistreringWrapperStateProps {
    kuid: string;
    kanStarteOmregistreringssak: boolean;
    kanStarteParegistreringssak: boolean;
    kanBehandleRegistrering: boolean;
    kjoretoy?: IKjoretoy;
    sakId?: string;
}

interface IRegistreringWrapperDispatchProps {
    resetSak: () => Promise<any>;
}

export type PropType = IRegistreringWrapperStateProps & IRegistreringWrapperDispatchProps & IAkConfirmNavigationProps & WithRouterProps;

class RegistreringWrapper extends React.Component<PropType> {

    public state = {};

    public componentDidUpdate(): void {
        if (!this.props.params.sakId) {
            this.props.confirmNavigation(false);
        }
    }

    public componentDidMount(): void {
        if (!!this.props.sakId && this.props.sakId !== this.props.params.sakId) {
            this.props.resetSak();
        }
    }

    public componentWillUnmount(): void {
        this.props.confirmNavigation(false);
    }

    public render(): React.JSX.Element {
        if (this.kanViseRegistrering() && (!this.harApenRegSakSomHindrerStart())) {
            return (
                <>
                    <RegistreringConnected sakId={this.props.params.sakId} />
                </>
            );
        }

        return <Navigate to={`/kjoretoy/${this.props.kuid}`} />;
    }

    private kanViseRegistrering(): boolean {
        const kanStarteSak = this.props.kanStarteOmregistreringssak || this.props.kanStarteParegistreringssak;
        return (this.props.params.sakId || kanStarteSak) && this.props.kanBehandleRegistrering;
    }

    private harApenRegSakSomHindrerStart() {
        const registreringssakstyper = [SaksType.REGISTRERING_SAMME_EIER, SaksType.REGISTRERING_NY_EIER];
        const harApenRegSak = _.some(this.props.kjoretoy.apneSaker, sak => _.includes(registreringssakstyper, sak.saksType));
        return harApenRegSak && !this.props.params.sakId;
    }
}

const mapStateToProps = (state: RootStateType): IRegistreringWrapperStateProps => ({
    kuid: kuidSelector(state),
    kanStarteOmregistreringssak: kanStarteOmregistreringssakSelector(state),
    kanStarteParegistreringssak: kanStarteParegistreringssakSelector(state),
    kanBehandleRegistrering: kanBehandleRegistreringSelector(state),
    kjoretoy: state.kjoretoy,
    sakId: state.sak.sakId
});

const mapDispatchToProps = (dispatch): IRegistreringWrapperDispatchProps => ({
    resetSak: () => dispatch(resetSak())
});

const RegistreringWrapperConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    AkConfirmNavigation,
    withRouter)
(RegistreringWrapper);

export { RegistreringWrapper, RegistreringWrapperConnected };
