import * as _ from 'lodash';

import type { IBaseApiResponseObject, IMerknad } from '../models/types';

export class Merknader {

    /**
     * Find merknader using a partial merknad or a user defined filter. Note: If suppling a partial merknad with "kontekst", this will
     * find all merknader where the string is a substring of the merknads "kontekst". Example:
     * A find with partial merknad {kontekts: 'lol'} will match the following merknader:
     * {kode: 'A', kontekst: 'lol'}</li>
     * {kode: 'A', kontekst: 'lol.A'}</li>
     * {kode: 'A', kontekst: 'A.B.lol.D'}</li>
     *
     * @param source Either a IBaseApiResponseObject which can contain merknader, or an array of merknader
     * @param filter Either a partial merknad, or a user defined filter
     * @returns An array of merknader that match the given filter
     */
    public static find(source: IBaseApiResponseObject | IMerknad[], filter: Partial<IMerknad> | ((merknad: IMerknad) => boolean)): IMerknad[] {
        if (source) {
            if (this.isBaseApiResponse(source)) {
                return this.findMerknaderInternal(source.merknader, filter);
            } else {
                return this.findMerknaderInternal(source, filter);
            }
        }

        return [];
    }

    /**
     * Returns true if there exists one or more merknader that matches the given filter, otherwise false. See {@link find}.
     *
     * @param source Either a IBaseApiResponseObject which can contain merknader, or an array of merknader
     * @param filter Either a partial merknad, or a user defined filter
     * @returns true if there exists one or more merknader that matches the given filter, otherwise false
     */
    public static exists(source: IBaseApiResponseObject | IMerknad[], filter: Partial<IMerknad> | ((merknad: IMerknad) => boolean)): boolean {
        return !!this.find(source, filter).length;
    }

    public static allExists(source: IMerknad[] = [], superset: IMerknad[]): boolean {
        return source.length && _.differenceWith(source, superset, _.isEqual).length === 0;
    }

    public static anyExists(source: IMerknad[] = [], superset: IMerknad[]): boolean {
        return source.length && _.intersectionWith(source, superset, (a: IMerknad, b: IMerknad) => {
            return a.kode === b.kode && a.kontekst === b.kontekst;
        }).length > 0;
    }

    public static anyMatch(source: IMerknad | IMerknad[] = [], criteria: Partial<IMerknad>[]): boolean {
        return source instanceof Array
            ? source.length && _.intersectionWith(source, criteria, _.isMatch).length > 0
            : _.some(criteria, this.filterForMerknad(source));
    }

    public static inContext(merknad: IMerknad, context: string): boolean {
        return merknad?.kontekst && merknad.kontekst.indexOf(context) !== -1;
    }

    public static filterForPartial(partial: Partial<IMerknad>): (merknad: IMerknad) => boolean {
        return merknad => (!partial.kode || merknad?.kode === partial.kode)
            && (!partial.kontekst || this.inContext(merknad, partial.kontekst))
            && (!partial.type || merknad.type === partial.type);
    }

    public static filterForMerknad(merknad: IMerknad): (partial: Partial<IMerknad>) => boolean {
        return partial => this.filterForPartial(partial)(merknad);
    }

    private static findMerknaderInternal(merknader: IMerknad[], filter: Partial<IMerknad> | ((merknad: IMerknad) => boolean)): IMerknad[] {
        if (this.isPartialIMerknad(filter)) {
            return _.filter(merknader, this.filterForPartial(filter));
        } else {
            return _.filter(merknader, filter);
        }
    }

    private static isBaseApiResponse(source: IBaseApiResponseObject | IMerknad[]): source is IBaseApiResponseObject {
        return (source as IBaseApiResponseObject).merknader !== undefined;
    }

    private static isPartialIMerknad(filter: Partial<IMerknad> | ((merknad: IMerknad) => boolean)): filter is Partial<IMerknad> {
        const partial = (filter as Partial<IMerknad>);
        return partial.kode !== undefined || partial.kontekst !== undefined;
    }
}
