import type { ThunkAction } from 'redux-thunk';
import { MeldingType, SaksType } from '../../models/kodeverk';
import type { IErstatningskjennemerkerSaksinformasjon, ISak, RootStateType } from '../../models/types';
import { KjennemerkeRegler } from '../../regler';
import type { IMeldingBarNyMeldingAction} from './melding-bar';
import { nyMelding } from './melding-bar';
import type { SakActionType } from './sak';
import { opprettSak } from './sak';

export const opprettErstatningskjennemerkerSak = (kuid: string):
    ThunkAction<Promise<void>, RootStateType, undefined, SakActionType | IMeldingBarNyMeldingAction> => (dispatch) => {
    return dispatch(opprettSak({kuid, saksType: SaksType.ERSTATNINGSKJENNEMERKER}, false))
        .then((sak: ISak<IErstatningskjennemerkerSaksinformasjon>) => {
            if (!KjennemerkeRegler.erKjennemerkekravTilErstatningskjennemerkerOppfylt(sak.merknader)) {
                return dispatch(nyMelding({
                    meldingIntlId: 'erstatningskjennemerker.melding.kjennemerkerIkkeInnlevert',
                    meldingType: MeldingType.ERROR
                }));
            }

            if (!KjennemerkeRegler.erKjoretoyKravTilErstatningskjennemerkeOppfylt(sak.merknader)) {
                return dispatch(nyMelding({
                    meldingIntlId: 'erstatningskjennemerker.melding.kravIkkeOppfylt',
                    meldingType: MeldingType.ERROR
                }));
            }

            if (KjennemerkeRegler.manglerBestillerForsendelsesadresse(sak.merknader)) {
                return dispatch(nyMelding({
                    meldingIntlId: 'erstatningskjennemerker.melding.kravIkkeOppfylt',
                    meldingType: MeldingType.ERROR
                }));
            }

            return dispatch(nyMelding({
                meldingIntlId: 'kjoretoydetaljer.meldinger.kjennemerkerBestilt',
                meldingType: MeldingType.SUCCESS
            }));
        });
};