import { MeldingBarActionKey } from '../../constants/action-keys';
import type { IMeldingBar } from '../../models/types';
import type { MeldingBarActionType } from '../actions';

const initialState: IMeldingBar = {
    meldinger: []
};

export function meldingBar(state: IMeldingBar = initialState, action: MeldingBarActionType): IMeldingBar {
    switch (action.type) {
        case MeldingBarActionKey.MELDING_BAR_NY_MELDING:
            return {...state, meldinger: [...state.meldinger, action.melding]};
        case MeldingBarActionKey.MELDING_BAR_FJERN_MELDING:
            return {...state, meldinger: state.meldinger.filter(melding => melding.meldingId !== action.meldingId)};
        default:
            return state;
    }
}
