import { connect } from 'react-redux';
import type { IMessageDescriptor } from 'svv-tk-akr-common-frontend';
import { AkMeldingboks, AkMeldingboksType } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Meldingsliste } from '../../components';
import { Feilkoder } from '../../constants';
import type { IError, IKjoretoy, IMerknad, RootStateType } from '../../models/types';
import { EierskifteRegler, KjoretoyRegler } from '../../regler';
import { kjoretoySelector } from '../../state/selectors';

interface IEierskifteInfomeldingerProps {
    ekstraInfomeldinger?: IMessageDescriptor[];
    ekstraVarselmeldinger?: IMessageDescriptor[];
    eierskifteValidation: {
        submitted?: boolean;
        merknader?: IMerknad[];
        valid?: boolean;
        errorId?: IError;
    };
    kjoretoy: IKjoretoy;
}

class EierskifteInfoMeldinger extends React.Component<IEierskifteInfomeldingerProps> {
    public render(): React.ReactNode {
        const infoOmKjoretoyet = this.getInfoMessages();
        const skalViseInfomeldingOmKjoretoyet = !!infoOmKjoretoyet.length;
        const harOvrigeInfomeldinger = !!this.props.ekstraInfomeldinger?.length;
        const harOvrigeVarselmeldinger = !!this.props.ekstraVarselmeldinger?.length;
        const valideringsmeldinger = _.uniqBy(_.map(this.props.eierskifteValidation.merknader || [], EierskifteRegler.meldingFraMerknad), 'kode');
        const skalViseValideringsfeil = !this.props.eierskifteValidation.valid && this.props.eierskifteValidation.submitted && valideringsmeldinger.length > 0;
        const kritiskeMeldinger = this.getKritiskeMeldinger();
        const skalViseKritiskMelding = !!kritiskeMeldinger.length;

        if (!skalViseInfomeldingOmKjoretoyet && !skalViseValideringsfeil && !harOvrigeInfomeldinger) {
            return null;
        }

        return (
            <>
                {skalViseValideringsfeil && (
                    <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mb-4">
                        <Meldingsliste meldinger={valideringsmeldinger} />
                        {this.getErrorMessage() && <li>{this.getErrorMessage()}</li>}
                    </AkMeldingboks>
                )}
                {skalViseInfomeldingOmKjoretoyet && (
                    <AkMeldingboks customClassName="mb-4">
                        <Meldingsliste meldinger={infoOmKjoretoyet} />
                    </AkMeldingboks>
                )}
                {harOvrigeInfomeldinger && (
                    <AkMeldingboks customClassName="mb-4">
                        <Meldingsliste meldinger={this.props.ekstraInfomeldinger} />
                    </AkMeldingboks>
                )}
                {harOvrigeVarselmeldinger && (
                    <AkMeldingboks customClassName="mb-4" meldingType={AkMeldingboksType.ERROR}>
                        <Meldingsliste meldinger={this.props.ekstraVarselmeldinger} />
                    </AkMeldingboks>
                )}
                {skalViseKritiskMelding && (
                    <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mb-4">
                        <Meldingsliste meldinger={kritiskeMeldinger} />
                    </AkMeldingboks>
                )}
            </>
        );
    }

    private getErrorMessage = () => {
        const error = this.props.eierskifteValidation.errorId;
        if (error) {
            error.errorId = error.errorCode !== Feilkoder.EIERSKIFTE_ALLEREDE_GODKJENT.valueOf() ? error.errorId : null;
            return (
                <FormattedMessage
                    id={`eierskifte.validering.${error.errorId ? 'error' : error.errorCode}`}
                    values={{errorId: <span className="text-nowrap">{error.errorId}</span>}}/>
            );
        }

        return null;
    };

    private getInfoMessages = () => {
        const infomeldinger = [];
        const kjoretoyRegler = new KjoretoyRegler(this.props.kjoretoy);
        infomeldinger.push(...kjoretoyRegler.utledInfomeldinger());

        const manglendeTekniskeData = kjoretoyRegler.manglerTekniskeData();
        if (manglendeTekniskeData) {
            infomeldinger.push({ id: 'eierskifte.melding.info.manglertekniskedata', values: { felter: manglendeTekniskeData } });
        }

        if (kjoretoyRegler.erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt()) {
            infomeldinger.push({id: 'eierskifte.melding.info.KJORINGENS_ART_LOYVEPLIKTIG'});
        }

        return infomeldinger;
    };

    private getKritiskeMeldinger = () => {
        const kritiskeMeldinger = [];
        kritiskeMeldinger.push(...EierskifteRegler.utledKritiskeMeldinger(this.props.kjoretoy));
        return kritiskeMeldinger;
    };
}

const mapStateToProps = (state: RootStateType) => ({
    kjoretoy: kjoretoySelector(state)
});

const EierskifteInfoMeldingerConnected = connect(mapStateToProps)(EierskifteInfoMeldinger);
export { EierskifteInfoMeldingerConnected };
