import { ForhandlereActionKey } from '../../constants/action-keys';
import { AkrConfig } from '../../constants/akr-config';
import type { IForhandler, ThunkResult } from '../../models/types';
import { createRestResource } from '../store';
import type { IOtherAction } from './oversettelser';

export type ForhandlerActionType = IForhandlerLoadingAction | IForhandlerFetchAvdelingerSuccessAction | IOtherAction;

export interface IForhandlerLoadingAction {
    type: ForhandlereActionKey.FORHANDLER_LOADING;
    isLoading: boolean;
}

export interface IForhandlerFetchAvdelingerSuccessAction {
    type: ForhandlereActionKey.FORHANDLER_FETCH_DATA_SUCCESS;
    forhandlere: IForhandler[];
}

const forhandlereIsLoading = (isLoading: boolean): IForhandlerLoadingAction => ({type: ForhandlereActionKey.FORHANDLER_LOADING, isLoading});
const forhandlereFetchSuccess = (forhandlere: IForhandler[]): IForhandlerFetchAvdelingerSuccessAction => ({type: ForhandlereActionKey.FORHANDLER_FETCH_DATA_SUCCESS, forhandlere});

const api = createRestResource(AkrConfig.ORGANISASJONER_RESOURCE_URL);

export function getForhandlereMedSammeHovedenhet(): ThunkResult<Promise<ForhandlerActionType>, ForhandlerActionType> {
    return (dispatch) => {
        dispatch(forhandlereIsLoading(true));
        return api
            .get()
            .then(response => {
                return dispatch(forhandlereFetchSuccess(response));
            });
    };
}
