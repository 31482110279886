import type { NavigateType } from '../models/types';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/** Løsning fra https://whereisthemouse.com/how-to-use-withrouter-hoc-in-react-router-v6-with-typescript (utvidet med match)*/

/** @deprecated Use `React Router hooks` instead */
export interface WithRouterProps {
    location: Partial<Location> & Pick<Location, 'pathname'>;
    params: Record<string, string>;
    navigate: NavigateType;
}

/** @deprecated Use `React Router hooks` instead. Rewrite Component to a HOC  */
export const withRouter = <Props extends WithRouterProps>(
    Component: React.ComponentType<Props>
) => {
    return function useWithRouter(props: Omit<Props, keyof WithRouterProps>): React.JSX.Element {
        const location = useLocation();
        const params = useParams();
        const navigate = useNavigate();
        return (
            <Component
                {...(props as Props)}
                location={location}
                params={params}
                navigate={navigate}
            />
        );
    };
};
