import * as _ from 'lodash';
import type { Action } from 'redux';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { AkrConfig, BrukerprofilActionKey } from '../../constants';
import type { Brukerprofil, IError, RootStateType } from '../../models/types';
import { createRestResource } from '../store';
import { klientkonfigurasjonGetData } from './';
import { addGlobalErrors } from './globals';

export type BrukerprofilActionType =
    | IBrukerprofilHasServerErrorAction
    | IBrukerprofilHasErrorAction
    | IBrukerprofilIsLoadingAction
    | IBrukerprofilFetchDataSuccess
    | IBrukerprofilManglerRoller
    | IBrukerprofilOtherAction;

export interface IBrukerprofilHasErrorAction {
    type: BrukerprofilActionKey.BRUKERPROFIL_HAS_ERROR;
    hasError: boolean;
}

export interface IBrukerprofilHasServerErrorAction {
    type: BrukerprofilActionKey.BRUKERPROFIL_HAS_SERVER_ERROR;
    error: IError;
}

export interface IBrukerprofilIsLoadingAction {
    type: BrukerprofilActionKey.BRUKERPROFIL_IS_LOADING;
    isLoading: boolean;
}

export interface IBrukerprofilManglerRoller {
    type: BrukerprofilActionKey.BRUKERPROFIL_MANGLER_ROLLER;
    brukerprofil: Brukerprofil;
}

export interface IBrukerprofilFetchDataSuccess {
    type: BrukerprofilActionKey.BRUKERPROFIL_FETCH_DATA_SUCCESS;
    brukerprofil: Brukerprofil;
}

export interface IBrukerprofilOtherAction {
    type: BrukerprofilActionKey.OTHER_ACTION;
}


export const brukerprofilHasError = (bool: boolean): IBrukerprofilHasErrorAction => ({type: BrukerprofilActionKey.BRUKERPROFIL_HAS_ERROR, hasError: bool});
export const brukerprofilHasServerError = (error: IError): IBrukerprofilHasServerErrorAction => ({type: BrukerprofilActionKey.BRUKERPROFIL_HAS_SERVER_ERROR, error});
export const brukerprofilIsLoading = (isLoading: boolean): IBrukerprofilIsLoadingAction => ({type: BrukerprofilActionKey.BRUKERPROFIL_IS_LOADING, isLoading});
export const brukerprofilManglerRoller = (brukerprofil: Brukerprofil): IBrukerprofilManglerRoller => ({
    type: BrukerprofilActionKey.BRUKERPROFIL_MANGLER_ROLLER,
    brukerprofil
});
export const brukerprofilFetchDataSuccess = (brukerprofil: Brukerprofil): IBrukerprofilFetchDataSuccess => ({
    type: BrukerprofilActionKey.BRUKERPROFIL_FETCH_DATA_SUCCESS,
    brukerprofil
});

const api = createRestResource(AkrConfig.BRUKERPROFIL_RESOURCE_URL);

function handleBrukerprofilUpdate(promise: Promise<Brukerprofil & IError>, dispatch: ThunkDispatch<RootStateType, undefined, BrukerprofilActionType>,
                                  getState: () => RootStateType):
    Promise<BrukerprofilActionType> {
    dispatch(brukerprofilIsLoading(true));
    return promise
        .then(response => {
            if (response.errorCode) {
                dispatch(addGlobalErrors(response));
                return dispatch(brukerprofilHasServerError(response));
            } else if (!_.isEmpty(response.valgtEnhet) && response.bruker.roller.length === 0) {
                return dispatch(brukerprofilManglerRoller(response));
            }

            return dispatch(brukerprofilFetchDataSuccess(response));
        })
        .then(() => {
            if (getState().brukerprofil.valgtEnhet) {
                return dispatch(klientkonfigurasjonGetData())
                    .then(() => dispatch(brukerprofilIsLoading(false)));
            }

            return dispatch(brukerprofilIsLoading(false));
        })
        .catch((err) => {
            dispatch(addGlobalErrors(err.response || err));
            dispatch(brukerprofilIsLoading(false));
            return dispatch(brukerprofilHasError(true));
        });
}

export function brukerprofilGetData(): ThunkAction<Promise<BrukerprofilActionType>, RootStateType, undefined, Action> {
    return (dispatch, getState) => {
        return handleBrukerprofilUpdate(api.get(), dispatch, getState);
    };
}
