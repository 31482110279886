export enum Adressestatus {
    VANLIG_BOSATT = 'VANLIG_BOSATT',
    UTENRIKS = 'UTENRIKS',
    MILITAR = 'MILITAR',
    SVALBARD = 'SVALBARD',
    KLIENTADRESSE = 'KLIENTADRESSE', // kode 4
    UTEN_FAST_BOPEL = 'UTEN_FAST_BOPEL',
    SPERRET_STRENGT_FORTROLIG = 'SPERRET_STRENGT_FORTROLIG', // kode 6
    SPERRET_FORTROLIG = 'SPERRET_FORTROLIG', // kode 7
    PENDLER = 'PENDLER',
    SPERRET_TRAFIKKSTASJON = 'SPERRET_TRAFIKKSTASJON'
}
