import * as _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import type {
    IAktorPanelAktor} from 'svv-tk-akr-common-frontend';
import {
    AkKnapp,
    AktorPanel,
    AktorPanelForm,
    AktorType,
    GodkjenningUtfortType,
    Panel,
    RegelmerknaderType,
    utledAktor,
    ValgtKontaktform
} from 'svv-tk-akr-common-frontend';

import {AKTOR_VALIDER_PREFIX} from '../../constants';
import {SaksType} from '../../models/kodeverk';
import type {IAktor, IError, IKjoretoy, IKunde, IKunder, IRegistreringInformasjon, ISak, RootStateType} from '../../models/types';
import {ForstegangsregistreringRegler} from '../../regler';
import {getKunde} from '../../state/actions';
import {erAktorMyndig, utledLesemodus} from '../../utils';
import {AktorInfo} from '../felles/aktor-info';

interface IForstegangsregistreringAktorerProps {
    lesemodus: boolean;
    submitted?: boolean;
    saksinformasjon: Partial<IRegistreringInformasjon>;
    oppdaterAktor: (aktor: IAktor, slett?: boolean) => void;
    kjoretoy: IKjoretoy;
    errors?: string[];
}

interface IForstegangsregistreringAktorerStateProps {
    kunder?: IKunder;
    sak?: ISak;
}

interface IForstegangsregistreringAktorerDispatchProps {
    hentAktor?: (orgFnr: string, aktortype: AktorType, kuid: string) => Promise<IKunde | IError>;
}

interface IValgtKontaktformMemory {
    valgtKontaktform: ValgtKontaktform;
    stale: boolean;
}

interface IForstegangsregistreringAktorerState {
    melder: IAktorPanelAktor;
    nyEier: IAktorPanelAktor;
    nyMedeier: IAktorPanelAktor;
    valgtKontaktformMemory?: {
        [AktorType.NY_EIER]?: IValgtKontaktformMemory;
        [AktorType.NY_MEDEIER]?: IValgtKontaktformMemory;
    };
}

type PropsType = IForstegangsregistreringAktorerProps & IForstegangsregistreringAktorerStateProps & IForstegangsregistreringAktorerDispatchProps;

const erAktorMyndigUtvidet = (aktor: IAktor | IKunde): boolean => aktor && aktor.erPerson && erAktorMyndig(aktor);

const skalViseKontaktinfo = (aktor: IAktor, kjoretoy: IKjoretoy): boolean => {
    return (
        aktor?.godkjenningUtfort !== GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA &&
        (!!aktor?.organisasjonsnummer || erAktorMyndigUtvidet(aktor) || ForstegangsregistreringRegler.erLettKjoretoy(kjoretoy))
    );
};

class ForstegangsregistreringAktorer extends React.Component<PropsType, IForstegangsregistreringAktorerState> {
    public static getDerivedStateFromProps(nextProps: PropsType): Partial<IForstegangsregistreringAktorerState> {
        const lesemodus = utledLesemodus(nextProps.sak.saksstatus);
        const { melder, nyEier, nyMedeier } = nextProps.saksinformasjon;

        return {
            melder: {
                ...melder,
                customError: _.find(nextProps.errors, (error) => error === 'forstegangsregistrering.valider.manglerMelder'),
                ...lesemodus,
                kanEndreAktor: false,
                visKontaktinfo: true
            },
            nyEier: {
                ...nyEier,
                customError: _.find(nextProps.errors, (error) => error === 'forstegangsregistrering.valider.manglerNyEier'),
                ...lesemodus,
                visKontaktinfo: skalViseKontaktinfo(nyEier, nextProps.kjoretoy)
            },
            nyMedeier: nyMedeier && {
                ...nyMedeier,
                customError: _.find(
                    nextProps.errors,
                    (error) => error === 'forstegangsregistrering.valider.manglerNyMedeier' || error === 'forstegangsregistrering.validering.NY_EIER_OG_NY_MEDEIER_KAN_IKKE_VAERE_LIKE'
                ),
                ...lesemodus,
                visKontaktinfo: skalViseKontaktinfo(nyMedeier, nextProps.kjoretoy)
            }
        };
    }

    public state: IForstegangsregistreringAktorerState = {
        nyEier: null,
        melder: null,
        nyMedeier: null,
        valgtKontaktformMemory: {
            [AktorType.NY_EIER]: { stale: false, valgtKontaktform: null },
            [AktorType.NY_MEDEIER]: { stale: false, valgtKontaktform: null }
        }
    };

    public componentDidUpdate(prevProps: Readonly<PropsType>): void {
        const aktorList: IAktor[] = [this.props.saksinformasjon.nyEier, this.props.saksinformasjon.nyMedeier];
        this.handterOmHarEndretTilSignaturSamtykkeSkjema(aktorList);
        this.handterOmHarEndretTilSignerISelvbetjeningFraSignaturSamtykkeSkjema(prevProps, aktorList);
    }

    private handterOmHarEndretTilSignaturSamtykkeSkjema(aktorList: IAktor[]) {
        aktorList.forEach((aktor) => {
            if (aktor && aktor?.godkjenningUtfort === GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA && aktor.valgtKontaktform !== ValgtKontaktform.IKKE_SEND_VARSEL) {
                const { valgtKontaktform } = { ...aktor };
                this.props.oppdaterAktor({ ...aktor, valgtKontaktform: ValgtKontaktform.IKKE_SEND_VARSEL });
                this.setState({
                    valgtKontaktformMemory: {
                        [aktor.aktorType]: {
                            stale: true,
                            valgtKontaktform: valgtKontaktform
                        }
                    }
                });
            }
        });
    }

    private handterOmHarEndretTilSignerISelvbetjeningFraSignaturSamtykkeSkjema(
        prevProps: Readonly<IForstegangsregistreringAktorerProps & IForstegangsregistreringAktorerStateProps & IForstegangsregistreringAktorerDispatchProps>,
        aktorList: IAktor[]
    ) {
        aktorList.forEach((aktor) => {
            if (!!aktor?.aktorType && (!!prevProps.saksinformasjon?.nyEier?.godkjenningUtfort || !!prevProps.saksinformasjon?.nyMedeier?.godkjenningUtfort))  {
                const valgtEiertype = aktor.aktorType === AktorType.NY_EIER ? this.props.saksinformasjon.nyEier : this.props.saksinformasjon.nyMedeier;
                const prevGodkjenningUtfort = aktor.aktorType === AktorType.NY_EIER ? prevProps.saksinformasjon.nyEier?.godkjenningUtfort : prevProps.saksinformasjon.nyMedeier?.godkjenningUtfort;
                const { godkjenningUtfort } = valgtEiertype;
                const harEndretTilIkkeSignert = prevGodkjenningUtfort === GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA && godkjenningUtfort === GodkjenningUtfortType.IKKE_SIGNERT;
                if (harEndretTilIkkeSignert && this.state.valgtKontaktformMemory[aktor.aktorType]?.stale) {
                    const eierMedForrigeValgteKontaktForm = {
                        ...valgtEiertype,
                        valgtKontaktform: this.state.valgtKontaktformMemory[aktor.aktorType].valgtKontaktform
                    };
                    this.props.oppdaterAktor(eierMedForrigeValgteKontaktForm);
                    this.setState({ valgtKontaktformMemory: { [aktor.aktorType]: { stale: false, valgtKontaktform: undefined } } });
                }
            }
        });
    }

    public render(): React.ReactElement {
        return (
            <Panel overskrift="forstegangsregistrering.aktorer.overskrift" panelCssClass="col-12 ak-panel-transparent ak-med-tabell" innholdCssClass="row no-gutters">
                <AktorPanel
                    panelClassName="col-md col-12 no-gutters"
                    sokInputClassName="ak-input-fast-bredde"
                    sokLabelClassName="col-12 mr-4 mt-1 ak-fet"
                    panelOverskrift={`kodeverk.aktortype.${AktorType.MELDER}`}
                    aktor={this.state.melder}
                    kunder={this.props.kunder}
                    hentAktor={this.hentAktor}
                    submitted={this.props.submitted}
                    valideringTekstPrefix={AKTOR_VALIDER_PREFIX}
                    handleAktorChange={this.handleAktorEndring}
                    skalIkkeViseGodkjenningsstatus={true}
                    visForhandsutfyltValg={true}
                >
                    <AktorPanelForm visForhandsutfyltValg={true} />
                </AktorPanel>
                <div className="col-md col-12 no-gutters pl-md-5">
                    <AktorPanel
                        panelClassName="mb-3"
                        sokInputClassName="ak-input-fast-bredde"
                        sokLabelClassName="col-12 mr-4 mt-1 ak-fet"
                        panelOverskrift={`kodeverk.aktortype.${AktorType.NY_EIER}`}
                        aktor={{ ...this.state.nyEier, ...getCustomErrors(this.state.nyEier) }}
                        kunder={this.props.kunder}
                        hentAktor={this.hentAktor}
                        submitted={this.props.submitted}
                        valideringTekstPrefix={AKTOR_VALIDER_PREFIX}
                        handleAktorChange={this.handleAktorEndring}
                    >
                        {this.utledAktorPanelFormForNyEierEllerMedeier(this.state.nyEier)}
                    </AktorPanel>
                    {this.renderMedeier()}
                </div>
            </Panel>
        );
    }

    private utledAktorPanelFormForNyEierEllerMedeier(aktor: IAktorPanelAktor) {
        const kunde = this.props.kunder ? this.props.kunder[aktor.kundeId] : null;
        const kanVelgeSignaturtype = !!aktor?.organisasjonsnummer
            || !!erAktorMyndigUtvidet(kunde)
            || !!(!erAktorMyndigUtvidet(kunde) && ForstegangsregistreringRegler.erLettKjoretoy(this.props.kjoretoy));

        return (
            <AktorInfo
                signaturtyper={ForstegangsregistreringRegler.utledGyldigeSignaturtyper()}
                kanVelgeSignaturtype={kanVelgeSignaturtype}
                handleAktorOppdatering={this.handleAktorEndring}
                visForhandsutfyltValg={true}
                erSendtTilGodkjenning={this.props.sak.erTilGodkjenning}
            />
        );
    }

    private handleAktorEndring = (aktor: IAktor, slett = false) => {
        return aktor.aktorType === AktorType.NY_MEDEIER ? this.props.oppdaterAktor(aktor, slett) : this.props.oppdaterAktor(aktor);
    };

    private renderMedeier = () => {
        const { nyEier, nyMedeier } = this.state;
        const lesemodus = utledLesemodus(this.props.sak.saksstatus);

        if (nyEier && !nyEier.kundeId) {
            return null;
        }

        if (!!nyMedeier) {
            return (
                <AktorPanel
                    panelClassName="mb-3"
                    sokInputClassName="ak-input-fast-bredde"
                    panelOverskrift={`kodeverk.aktortype.${AktorType.NY_MEDEIER}`}
                    aktor={{ ...nyMedeier, ...getCustomErrors(nyMedeier) }}
                    kunder={this.props.kunder}
                    hentAktor={this.hentAktor}
                    submitted={this.props.submitted}
                    valideringTekstPrefix={AKTOR_VALIDER_PREFIX}
                    handleAktorChange={this.handleAktorEndring}
                >
                    {this.utledAktorPanelFormForNyEierEllerMedeier(nyMedeier)}
                </AktorPanel>
            );
        }

        return !lesemodus.panelLesemodus && <AkKnapp action={this.handleLeggTilKnapp} intlKey={'sak.knapp.leggTilNyMedeier'} type="ak-link-knapp ak-ikon-legg-til ml-2" />;
    };

    private hentAktor = (orgFnr: string, aktorType: AktorType) => {
        return this.props.hentAktor(orgFnr, aktorType, this.props.kjoretoy.kuid).then(() => {
            const kunde = _.get(this.props.kunder, orgFnr);
            if (!_.find(kunde && kunde.merknader, { type: RegelmerknaderType.KRITISK })) {
                return this.handleAktorEndring(utledAktor({ aktorType }, kunde, true));
            }
        });
    };

    private handleLeggTilKnapp = () => {
        this.handleAktorEndring({ aktorType: AktorType.NY_MEDEIER });
    };
}

function getCustomErrors(aktor: IAktorPanelAktor & Pick<IKunde, 'ikkeVisKunde'>): Pick<IAktorPanelAktor, 'customError'> | null {
    if (aktor.ikkeVisKunde) {
        return { customError: 'forstegangsregistrering.aktorer.informasjonIkkeTilgjengeligIKanal' };
    }
    return null;
}

const mapStateToProps = (state: RootStateType): IForstegangsregistreringAktorerStateProps => ({
    kunder: state.kunder,
    sak: state.sak
});

const mapDispatchToProps = (dispatch): IForstegangsregistreringAktorerDispatchProps => ({
    hentAktor: (orgFnr, aktortype, kuid) => dispatch(getKunde({ orgFnr }, aktortype, kuid, SaksType.FORSTEGANGSREGISTRERING))
});

const ForstegangsregistreringAktorerConnected = connect(mapStateToProps, mapDispatchToProps)(ForstegangsregistreringAktorer);
export { ForstegangsregistreringAktorerConnected, ForstegangsregistreringAktorer };
