import type { IntlConfig } from 'react-intl';
import type { ThunkAction } from 'redux-thunk';

import { AkrConfig, OversettelserActionKey } from '../../constants';
import type { IError, RootStateType } from '../../models/types';
import { createRestResource } from '../store';
import { isError } from './registreringer-arkivdokumenter';

export type OversettelserActionType =
    | IOversettelserHasErrorAction
    | IOversettelserHasServerErrorAction
    | IOversettelserIsLoadingAction
    | IOversettelserFetchDataSuccessAction
    | IOtherAction;

export interface IOversettelserHasErrorAction {
    type: OversettelserActionKey.OVERSETTELSER_HAS_ERROR;
    hasError: boolean;
}

export interface IOversettelserHasServerErrorAction {
    type: OversettelserActionKey.OVERSETTELSER_HAS_SERVER_ERROR;
    error: IError;
}

export interface IOversettelserIsLoadingAction {
    type: OversettelserActionKey.OVERSETTELSER_IS_LOADING;
    isLoading: boolean;
}

export interface IOversettelserFetchDataSuccessAction {
    type: OversettelserActionKey.OVERSETTELSER_FETCH_DATA_SUCCESS;
    intlConfig: Partial<IntlConfig>;
}

export interface IOtherAction {
    type: OversettelserActionKey.OTHER_ACTION;
}

export const oversettelserHasError = (hasError: boolean): IOversettelserHasErrorAction => ({type: OversettelserActionKey.OVERSETTELSER_HAS_ERROR, hasError});
export const oversettelserHasServerError = (error: IError): IOversettelserHasServerErrorAction => ({type: OversettelserActionKey.OVERSETTELSER_HAS_SERVER_ERROR, error});
export const oversettelserIsLoading = (isLoading: boolean): IOversettelserIsLoadingAction => ({type: OversettelserActionKey.OVERSETTELSER_IS_LOADING, isLoading});
export const oversettelserFetchDataSuccess = (intlConfig: Partial<IntlConfig>): IOversettelserFetchDataSuccessAction => ({type: OversettelserActionKey.OVERSETTELSER_FETCH_DATA_SUCCESS, intlConfig});

const api = createRestResource(AkrConfig.OVERSETTELSER_RESOURCE_URL);

export function oversettelserGetData(lang: string = 'nb'): ThunkAction<void, RootStateType, undefined, OversettelserActionType> {
    const maalform = lang || 'nb';

    return dispatch => {
        dispatch(oversettelserIsLoading(true));
        return api.get(maalform)
            .then((response: Record<string, string> | IError) => {
                dispatch(oversettelserIsLoading(false));

                if (isError(response) && response.errorCode) {
                    return dispatch(oversettelserHasServerError(response));
                }

                dispatch(oversettelserFetchDataSuccess({locale: maalform, messages: response[maalform]}));
            });
    };
}
