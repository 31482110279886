import * as _ from 'lodash';
import type { ThunkAction } from 'redux-thunk';
import type { IArkivdokument } from 'svv-tk-akr-common-frontend';
import { GodkjenningUtfortType } from 'svv-tk-akr-common-frontend';

import { Feilkoder, NY_SAK_VISNINGSTID_MS } from '../../constants';
import { AvsluttSakIndikator, MeldingType, Saksstatus, SaksType } from '../../models/kodeverk';
import type { IAktor, IArbeidslisteRad, IError, IKjoretoy, IKjoringensArtListe, IRegistreringInformasjon, ISak, IVilkarstatus, NavigateType, RootStateType, ThunkResult } from '../../models/types';
import { ForstegangsregistreringRegler } from '../../regler';
import {
    aktorErIkkeMyndigOgInneholderKjoperIkkeMyndigMerknad, errorChainContainsErrorCode, sakErAvluttetAvbrutt, utledNeringskodebeskrivelse, utledValgtKjoringensArt, utledValgtNeringskode
} from '../../utils';
import { sakSelector } from '../selectors';
import { fjernSak } from './arbeidsliste';
import { fjernForsikringsKnytningMellomEierOgKjoretoy } from './forsikring';
import { endreDokumentSendTilSaksbehandlingForstegansregistrering } from './forstegangsregistrering-arkivdokumenter';
import { hentKjennemerkeserierOgTyper } from './kjennemerkeserier-typer-action';
import { kjoretoyGetDataFromKuidAction } from './kjoretoy';
import { populateKunderStoreWithAktorer } from './kunde';
import type { IMeldingBarNyMeldingAction} from './melding-bar';
import { nyMelding } from './melding-bar';
import type { RegistreringerActionType } from './registreringer';
import type { SakActionType } from './sak';
import { avbrytSak, endreSak, endreSakUtenGlobalErrorsMedFilopplasting, hentSak, opprettSak } from './sak';
import type { IKontrollerVilkarSuccessAction} from './vilkar-action';
import { kontrollerVilkar } from './vilkar-action';

export const submitForstegangsregistrering = (navigate: NavigateType, saksinformasjon: IRegistreringInformasjon, avsluttSakIndikator: AvsluttSakIndikator): ThunkResult<void, SakActionType> => {
    return submitForstegangsregistreringOgLastOppDokument(navigate, saksinformasjon, avsluttSakIndikator, null);
};

export const submitForstegangsregistreringOgLastOppDokument =
    (
        navigate: NavigateType,
        saksinformasjon: IRegistreringInformasjon,
        avsluttSakIndikator: AvsluttSakIndikator,
        arkivdokument: IArkivdokument
    ): ThunkAction<void, RootStateType, undefined, RegistreringerActionType> =>
        (dispatch, getState) => {
            return dispatch(getState().sak.sakId ? endreRegistrering(saksinformasjon, avsluttSakIndikator, arkivdokument) : opprettRegistrering(navigate, saksinformasjon, avsluttSakIndikator));
        };

export const hentForstegangsregistrering =
    (sakId: string): ThunkAction<Promise<void | IKontrollerVilkarSuccessAction | Partial<IVilkarstatus>>, RootStateType, undefined, SakActionType> =>
        (dispatch, getState) => {
            return dispatch(hentSak(sakId)).then(() => {
                const { sak, kjoretoy } = getState();
                if (sak && sak.saksinformasjon) {
                    const { melder, nyEier, nyMedeier } = sak.saksinformasjon;
                    const aktorer = _.reject([melder, nyEier, nyMedeier], _.isNil);

                    // Hentes for å vise navn på signerte dokumenter, i de fleste tilfeller vil signatur være fra aktorer
                    const kunderFraDokumenter = getKundeIderFraSaksdokumenter(sak.arkivdokumenter);
                    const kunderSomSkalHentes = _.unionBy([...aktorer, ...kunderFraDokumenter], 'kundeId');

                    return dispatch(populateKunderStoreWithAktorer(kunderSomSkalHentes, kjoretoy.kuid, SaksType.FORSTEGANGSREGISTRERING)).then(() => {
                        if (!sakErAvluttetAvbrutt(sak.saksstatus)) {
                            return dispatch(kontrollerVilkar(sak.saksinformasjon));
                        }
                    });
                }
            });
        };

export const avbrytForstegangsregistrering =
    (navigate: NavigateType, sakId: string, kuid: string, saksinformasjon: IRegistreringInformasjon): ThunkAction<Promise<IArbeidslisteRad | ISak<any> | void>, RootStateType, undefined, SakActionType> =>
        (dispatch) => {
            dispatch(fjernForsikringsKnytningMellomEierOgKjoretoy(kuid, saksinformasjon));
            return dispatch(avbrytSak(sakId)).then((sak) => {
                if (sakHarFeil(sak)) {
                    return dispatch(
                        nyMelding({
                            meldingIntlId: 'forstegangsregistrering.melding.ikkeAvbrutt',
                            meldingType: MeldingType.ERROR
                        })
                    );
                }
                return dispatch(fjernSak(sak)).then(() => {
                    navigate(`/startside?fane=${SaksType.FORSTEGANGSREGISTRERING}`);
                    return dispatch(
                        nyMelding({
                            meldingIntlId: 'sak.melding.avbrutt',
                            meldingType: MeldingType.SUCCESS
                        })
                    );
                });
            });
        };

const opprettRegistrering =
    (
        navigate: NavigateType,
        saksinformasjon: IRegistreringInformasjon,
        avsluttSakIndikator: AvsluttSakIndikator
    ): ThunkAction<Promise<void>, RootStateType, undefined, SakActionType | IMeldingBarNyMeldingAction> =>
        (dispatch, getState) => {
            const kjoringensArtListe = getState().kjoringensArtListe;
            return dispatch(opprettSak(createOpprett(kjoringensArtListe, saksinformasjon, avsluttSakIndikator, getState().kjoretoy), true)).then((sak) => {
                if (sakHarFeil(sak)) {
                    if (isApenSakError(sak as IError)) {
                        return navigate(`/kjoretoy/${getState().kjoretoy.kuid}/`, { replace: true });
                    }
                    return dispatch(
                        nyMelding({
                            meldingIntlId: 'forstegangsregistrering.melding.ikkeOpprettet',
                            meldingType: MeldingType.DANGER
                        })
                    );
                }
                navigate(`/kjoretoy/${sak.kuid}/forstegangsregistrering/${sak.sakId}`, { replace: true });
                dispatch(kjoretoyGetDataFromKuidAction(sak.kuid));
                return dispatch(
                    nyMelding({
                        meldingIntlId: 'sak.melding.opprett',
                        meldingType: MeldingType.SUCCESS,
                        varighetMs: NY_SAK_VISNINGSTID_MS
                    })
                );
            });
        };

export const endreSakTilGodkjenningRegistrering =
    (
        saksinformasjon: IRegistreringInformasjon,
        avsluttSakIndikator: AvsluttSakIndikator
    ): ThunkAction<Promise<ISak<IRegistreringInformasjon> | void>, RootStateType, undefined, RegistreringerActionType> =>
        (dispatch, getState) => {
            const sak = sakSelector(getState());

            return dispatch(endreSak(createSakTilGodkjenning(saksinformasjon, avsluttSakIndikator, sak), true)).then((sakResponse) => {
                if (sakHarFeil(sakResponse)) {
                    dispatch(kontrollerVilkar(sak.saksinformasjon));
                    return dispatch(
                        nyMelding({
                            meldingIntlId: utledFeilmelding(sakResponse, avsluttSakIndikator),
                            meldingType: MeldingType.DANGER
                        })
                    );
                } else {
                    dispatch(kontrollerVilkar(sakResponse.saksinformasjon));
                    const erTilSaksbehandlerGodkjenning = sak.erTilGodkjenning;
                    if (!erTilSaksbehandlerGodkjenning) {
                        dispatch(endreDokumentSendTilSaksbehandlingForstegansregistrering(sakResponse.sakId, sakResponse.arkivdokumenter));
                    }
                    return dispatch(
                        nyMelding({
                            meldingIntlId: !erTilSaksbehandlerGodkjenning
                                           ? 'forstegangsregistrering.melding.sendtTilSaksbForGodkjenning'
                                           : 'forstegangsregistrering.melding.trekketTilbakeFraSaksbGodkjenning',
                            meldingType: MeldingType.SUCCESS
                        })
                    );
                }
            });
        };

const endreRegistrering =
    (
        saksinformasjon: IRegistreringInformasjon,
        avsluttSakIndikator: AvsluttSakIndikator,
        arkivdokument: IArkivdokument
    ): ThunkAction<void, RootStateType, undefined, RegistreringerActionType | IMeldingBarNyMeldingAction> =>
        (dispatch, getState) => {
            return dispatch(endreSakUtenGlobalErrorsMedFilopplasting(createEndre(saksinformasjon, avsluttSakIndikator, getState().sak), true, arkivdokument)).then((sak) => {
                if (sak) {
                    if (sakHarFeil(sak)) {
                        if (erIngenKjennemerkePaLager(sak)) {
                            dispatch(hentKjennemerkeserierOgTyper(getState().kjoretoy.kuid, saksinformasjon.kjoringensArt));
                        }

                        dispatch(kontrollerVilkar(saksinformasjon));

                        return dispatch(
                            nyMelding({
                                meldingIntlId: utledFeilmelding(sak, avsluttSakIndikator),
                                meldingType: MeldingType.DANGER
                            })
                        );
                    } else if (sak.saksstatus === Saksstatus.AVSLUTTET) {
                        dispatch(kjoretoyGetDataFromKuidAction(sak.kuid));
                        return dispatch(
                            nyMelding({
                                meldingIntlId: 'sak.melding.fullfort',
                                meldingType: MeldingType.SUCCESS
                            })
                        );
                    } else {
                        return dispatch(
                            nyMelding({
                                meldingIntlId: 'sak.melding.lagre',
                                meldingType: MeldingType.SUCCESS
                            })
                        );
                    }
                }
            });
        };

const createOpprett = (kjoringensArtListe: IKjoringensArtListe, saksinformasjon: IRegistreringInformasjon, avsluttSakIndikator: AvsluttSakIndikator, kjoretoy: IKjoretoy) => {
    const { nyEier, nyMedeier } = saksinformasjon;
    const kjoringensArt = utledValgtKjoringensArt(saksinformasjon.kjoringensArt, nyEier, kjoretoy, kjoringensArtListe.innslag);
    const neringskoder = nyEier?.neringskoder;
    const neringskode = utledValgtNeringskode(saksinformasjon.neringskode, neringskoder);
    const neringskodeBeskrivelse = utledNeringskodebeskrivelse(neringskode, neringskoder);
    const bruktimportinfo = kjoretoy.godkjenning.forstegangsgodkjenning.bruktimport;
    const saksType = SaksType.FORSTEGANGSREGISTRERING;
    const nyEierOmIkkeMyndig = utledAktorOmIkkeMyndig(nyEier, kjoretoy);
    const nyMedeierOmIkkeMyndig = utledAktorOmIkkeMyndig(nyMedeier, kjoretoy);
    return {
        saksType,
        kuid: kjoretoy.kuid,
        avsluttSakIndikator,
        ordrenr: saksinformasjon.ordrenr,
        saksinformasjon: {
            ...saksinformasjon,
            kjoringensArt,
            neringskode,
            neringskodeBeskrivelse,
            bruktimportinfo,
            saksType,
            nyEier: nyEierOmIkkeMyndig,
            nyMedeier: nyMedeierOmIkkeMyndig
        }
    } as ISak<IRegistreringInformasjon>;
};

const createSakTilGodkjenning = (saksinformasjon: IRegistreringInformasjon, avsluttSakIndikator: AvsluttSakIndikator, sak: ISak<IRegistreringInformasjon>) => {
    const saksType = SaksType.FORSTEGANGSREGISTRERING;

    return {
        ...sak,
        erTilGodkjenning: !sak.erTilGodkjenning,
        avsluttSakIndikator,
        trekkTilbake: sak.erTilGodkjenning,
        saksinformasjon: {
            ...saksinformasjon,
            saksType
        }
    } as ISak<IRegistreringInformasjon>;
};

const createEndre = (saksinformasjon: IRegistreringInformasjon, avsluttSakIndikator: AvsluttSakIndikator, sak: ISak<IRegistreringInformasjon>) => {
    return {
        ...sak,
        ordrenr: saksinformasjon.ordrenr,
        avsluttSakIndikator,
        saksinformasjon: {
            ...saksinformasjon,
            saksType: SaksType.FORSTEGANGSREGISTRERING
        }
    } as ISak<IRegistreringInformasjon>;
};

const utledFeilmelding = (sak: ISak<IRegistreringInformasjon>, avsluttSakIndikator: AvsluttSakIndikator) => {
    if (kallTilTFFfeilet(sak.error)) {
        return 'sak.vilkar.feilmeldinger.forsikring';
    }

    if (isTrekkeTilbakeError(sak.error)) {
        return 'feilhandtering.kode.akr.forhandler.app.00013';
    }
    return avsluttSakIndikator === AvsluttSakIndikator.AVSLUTT_MED_VEDTAK ? 'sak.melding.ikkeRegistrert' : 'sak.melding.ikkeLagret';
};

const erIngenKjennemerkePaLager = (sakResponse: ISak<IRegistreringInformasjon>): boolean => sakResponse.error.errorResponseCause?.errorCode === Feilkoder.INGEN_KJENNEMERKER_PA_LAGER.valueOf();

const isTrekkeTilbakeError = (error: IError) => error && error.errorMessage && error.errorCode === Feilkoder.SAK_KAN_IKKE_TREKKES_TILBAKE.valueOf();

const isApenSakError = (error: IError): boolean => !!(errorChainContainsErrorCode(error, Feilkoder.APEN_SAK) || errorChainContainsErrorCode(error, Feilkoder.KJORETOY_REGISTRERT));

const sakHarFeil = (sakResponse): boolean => !!sakResponse.errorId || !sakResponse.sakId || !_.isEmpty(sakResponse.feil);

const kallTilTFFfeilet = (error: IError) => error && error.errorResponseCause && error.errorMessage && error.errorResponseCause.errorMessage.search('TFF') !== -1;

const getKundeIderFraSaksdokumenter = (dokumenter: IArkivdokument[]) => {
    const kunderFraDokumenter = _.flatMap(dokumenter, (dokument) => _.flatMap(dokument.saksdokumenter, (saksdokument) => (saksdokument?.aktor?.kundeId ? saksdokument.aktor : null)));

    return _.reject(kunderFraDokumenter, _.isNil);
};

const utledAktorOmIkkeMyndig = (aktor: IAktor, kjoretoy: IKjoretoy) => {
    return aktor?.erPerson && aktorErIkkeMyndigOgInneholderKjoperIkkeMyndigMerknad(aktor) && !ForstegangsregistreringRegler.erLettKjoretoy(kjoretoy)
           ? { ...aktor, godkjenningUtfort: GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA }
           : aktor;
};
