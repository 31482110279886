import * as _ from 'lodash';
import { createSelector } from 'reselect';

import { RegelmerknaderKoder, Saksstatus } from '../../models/kodeverk';
import type { IRegistreringInformasjon, IRegistreringSak, IRootState, ISak, IVilkarstatus} from '../../models/types';
import { KjoretoyRegler, Merknader, RegistreringRegler } from '../../regler';

import {
    brukerprofilSelector,
    gjeldendeEierSelector, gjeldendeMedeierSelector, harRolleForstegangsregistreringSelector, kjoretoyMerknaderSelector, kjoretoySelector, registreringSelector, registrertEierSelector
} from './.';

export function registreringSakSelector(state: Pick<IRootState, 'registreringSak'>): IRegistreringSak {
    return state.registreringSak;
}

export function sakSelector(state: Pick<IRootState, 'sak'>): ISak<IRegistreringInformasjon> {
    return state.sak;
}

export function vilkarSelector(state: Pick<IRootState, 'vilkarstatus'>): IVilkarstatus {
    return state.vilkarstatus;
}

export const sakMerknaderSelector = createSelector(
    sakSelector,
    (sak) => (sak.merknader)
);

export const avgiftsstatusSelector = createSelector(
    registreringSakSelector,
    (sak) => (sak.avgiftsstatus)
);

export const erKjennemerkerUtilgjengeligeSelector = createSelector(
    kjoretoySelector,
    (kjoretoy) => (RegistreringRegler.erKjennemerkerUtilgjengelige(kjoretoy))
);

export const kanStarteOmregistreringssakSelector = createSelector(
    kjoretoySelector,
    kjoretoyMerknaderSelector,
    registrertEierSelector,
    (kjoretoy, merknader, registrertEier) => (RegistreringRegler.kanStarteOmregistreringssak(kjoretoy, merknader, registrertEier))
);

export const kjoretoyHarFortollingSelector = createSelector(
    kjoretoySelector,
    kjoretoyMerknaderSelector,
    (kjoretoy, merknader) => (new KjoretoyRegler(kjoretoy).harKjoretoyFortolling(merknader))
);

export const kanFatteVedtakForstegangsregistreringSelector = createSelector(
    kjoretoySelector,
    sakMerknaderSelector,
    (kjoretoy, merknader) => (new KjoretoyRegler(kjoretoy).kanFatteVedtakForstegangsregistrering(merknader))
);

export const kjoretoyStjaletSelector = createSelector(
    kjoretoySelector,
    kjoretoyMerknaderSelector,
    (kjoretoy, merknader) => (new KjoretoyRegler(kjoretoy).erKjoretoyStjalet(merknader))
);

export const harRegistreringsBegrensingerSelector = createSelector(
    kjoretoySelector,
    (kjoretoy) => {
        return Merknader.exists(kjoretoy, {kode: RegelmerknaderKoder.KAN_IKKE_REGISTRERES_I_NORGE})
            || Merknader.exists(kjoretoy, {kode: RegelmerknaderKoder.TYPEGODKJENNING_IKKE_LENGER_GYLDIG});
    }
);

export const kanStarteForstegangsregistreringssakSelector = createSelector(
    kjoretoyHarFortollingSelector,
    kjoretoyStjaletSelector,
    harRolleForstegangsregistreringSelector,
    (kjoretoyHarFortolling, kjoretoyErStjalet, hasForstegangsregistreringRolle) => (kjoretoyHarFortolling && !kjoretoyErStjalet && hasForstegangsregistreringRolle)
);

export const kanStarteOgBehandleForstegangsregistreringSelector = createSelector(
    kjoretoySelector,
    registreringSelector,
    kanStarteForstegangsregistreringssakSelector,
    harRegistreringsBegrensingerSelector,
    (kjoretoy, registrering, kanStarteForstegangsregistreringssak, harRegistreringsBegrensinger) => (_.isEmpty(registrering)
        && !Merknader.exists(kjoretoy.merknader, { kode: RegelmerknaderKoder.BRUKER_HAR_IKKE_RETTIGHETER })
        && !new KjoretoyRegler(kjoretoy).harApenDagsprovekjennemerkesak()
        && !new KjoretoyRegler(kjoretoy).erGjenstandForEtterregistrering()
        && !harRegistreringsBegrensinger
        && kanStarteForstegangsregistreringssak)
);

export const kanStarteParegistreringssakSelector = createSelector(
    kjoretoySelector,
    kjoretoyMerknaderSelector,
    registrertEierSelector,
    (kjoretoy, merknader, registrerteier) => (RegistreringRegler.kanStarteParegistreringssak(kjoretoy, merknader, registrerteier))
);

export const erKravOmVektarsavgiftSelector = createSelector(
    kjoretoyMerknaderSelector,
    kanStarteOmregistreringssakSelector,
    (merknader) => (RegistreringRegler.erKravOmVektarsavgift(undefined, merknader))
);

export const kanBehandleRegistreringSelector = createSelector(
    kjoretoySelector,
    brukerprofilSelector,
    (kjoretoy, brukerprofil) => (RegistreringRegler.kanBehandleRegistrering(brukerprofil, kjoretoy))
);

export const vilkarForRegistreringSelector = createSelector(
    kjoretoySelector,
    vilkarSelector,
    registreringSakSelector,
    (kjoretoy, vilkarstatus, registreringSak) => (RegistreringRegler.vilkarForRegistrering(kjoretoy, vilkarstatus, registreringSak))
);

export const statusForRegistreringSelector = createSelector(
    kjoretoySelector,
    vilkarSelector,
    sakSelector,
    registreringSakSelector,
    kanStarteOmregistreringssakSelector,
    (kjoretoy, vilkarstatus, sak, registreringSak, kanStarteOmregistreringssak) =>
        (RegistreringRegler.utledRegistreringStatus(kjoretoy, sak, vilkarstatus, registreringSak, kanStarteOmregistreringssak))
);

export const fullforPaTrafikkstasjonGrunnlagSelector = createSelector(
    kjoretoySelector,
    registreringSakSelector,
    vilkarSelector,
    (kjoretoy, sak, vilkarstatus) => (RegistreringRegler.utledGrunnlagForAFullforeRegistreringPaTrafikkstasjon(kjoretoy, sak, vilkarstatus))
);

export const merknaderForRegistreringSelector = createSelector(
    kjoretoySelector,
    registreringSakSelector,
    (kjoretoy, sak) => (RegistreringRegler.utledMerknaderForRegistrering(kjoretoy, sak))
);

export const erNyEierOpplostTvangsavvikletSlettetSelector = createSelector(
    gjeldendeEierSelector,
    gjeldendeMedeierSelector,
    (eier, medeier): boolean => {
        return Merknader.exists(eier, {kode: RegelmerknaderKoder.NY_EIER_STATUS_SLETTET})
            || Merknader.exists(medeier, {kode: RegelmerknaderKoder.NY_EIER_STATUS_SLETTET});
    }
);

export const erNyEierKonkursSelector = createSelector(
    gjeldendeEierSelector,
    gjeldendeMedeierSelector,
    (eier, medeier): boolean => {
        return Merknader.exists(eier, {kode: RegelmerknaderKoder.NY_EIER_STATUS_KONKURS})
            || Merknader.exists(medeier, {kode: RegelmerknaderKoder.NY_EIER_STATUS_KONKURS});
    }
);

export const erNyEierDodSelector = createSelector(
    gjeldendeEierSelector,
    gjeldendeMedeierSelector,
    (eier, medeier): boolean => {
        return Merknader.exists(eier, {kode: RegelmerknaderKoder.KJOPER_MELDT_DOD})
            || Merknader.exists(medeier, {kode: RegelmerknaderKoder.KJOPER_MELDT_DOD});
    }
);

export const kanHenteMidlertidigVognkortSelector = createSelector(
    kjoretoySelector,
    (kjoretoy): boolean => {
        return !Merknader.exists(kjoretoy, {kode: RegelmerknaderKoder.KJORETOY_IKKE_NYLIG_REGISTRERT})
            && !Merknader.exists(kjoretoy, {kode: RegelmerknaderKoder.KJORETOY_EIERSKIFTE_FUNNET});
    }
);

export const valgtNeringskodeSelector = createSelector(
    registreringSakSelector,
    (sak): string | null => (sak.neringskode)
);

export const erLesemodusSelector = createSelector(
    registreringSakSelector,
    (sak) => (sak && (sak.saksstatus === Saksstatus.AVSLUTTET || sak.saksstatus === Saksstatus.AVBRUTT))
);

export const vilkarstatusMerknaderOgSakMerknaderSelector = createSelector(
    sakSelector,
    vilkarSelector,
    (sak, vilkarstatus) => {
        return _.uniqBy(_.concat(sak.merknader, vilkarstatus.merknader), 'kode');
    }
);
