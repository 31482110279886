import * as _ from 'lodash';
import * as React from 'react';
import type { ReactNode } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import type { WithRouterProps} from '../../utils';
import { withRouter } from '../../utils';
import { compose } from '@reduxjs/toolkit';
import { akReactKeyGen } from 'svv-tk-akr-common-frontend';

import { ArbeidslisteFeilhandtering } from '../../components/arbeidsliste';
import { NY_SAK_VISNINGSTID_MS } from '../../constants';
import { ArbeidslisteSakstypefilter } from '../../models/kodeverk';
import { Varseltype } from '../../models/kodeverk/varseltype';
import type { Brukerprofil, IArbeidsliste, IArbeidslisteRad, NavigateType, RootStateType } from '../../models/types';
import type { ArbeidslisteDokumentasjonsstatusDispatchProps, ArbeidslisteDokumentasjonsstatusStateProps } from '../../models/types/arbeidsliste-dokumentasjonsstatus';
import { BrukerRegler } from '../../regler';
import { arbeidsListeNySak, finnDokumentasjonsstatus, sortArbeidsliste } from '../../state/actions';
import { brukerprofilSelector, filtrertForstegangsregistreringradeSelector } from '../../state/selectors';
import { ArbeidslisteCard } from './arbeidsliste-card';
import { ArbeidslisteHeaderCell } from './arbeidsliste-cells';
import type { IRadConfigProps} from './arbeidsliste-config';
import { makeRadConfig } from './arbeidsliste-config';
import { ArbeidslisteFilterbarConnected } from './arbeidsliste-filterbar';
import { ArbeidslisteRow } from './arbeidsliste-row';

import './arbeidsliste-style';
import { PurreAktorModalConnected } from './purre-aktorer-modal';

interface IArbeidslisteForstegangsregistreringerStateProps extends ArbeidslisteDokumentasjonsstatusStateProps {
    arbeidsliste: IArbeidsliste;
    innloggetForhandlerNavn: string;
    nySakIder: string[];
    brukerprofil: Brukerprofil;
}

interface IArbeidslisteForstegangsregistreringerDispatchProps extends ArbeidslisteDokumentasjonsstatusDispatchProps {
    arbeidslisteClearNySakIder: (navigate: NavigateType) => any;
    sorterArbeidsliste: (sortProp: string) => void;
}

interface IArbeidslisteForstegangsregistreringerState {
    viewHeight: number;
    offsetTop: number;
    showPurringModal?: boolean;
    purringSakId: string;
    radConfig: IRadConfigProps[];
}

type PropsType = IArbeidslisteForstegangsregistreringerStateProps & IArbeidslisteForstegangsregistreringerDispatchProps & WithRouterProps;

class ArbeidslisteForstegangsregistreringer extends Component<PropsType, IArbeidslisteForstegangsregistreringerState> {

    public state = {
        viewHeight: 0,
        offsetTop: 0,
        purringSakId: '',
        showPurringModal: false,
        radConfig: []
    };

    private config = {
        // Endres denne så må man endre på ak-arbeidsliste-row-empty scss klassen
        ROW_HEIGHT: 57,
        OVERSCAN_BOTTOM: 472,
        OVERSCAN_TOP: 472,
        START_VIRTUAL_SCROLL_ON_COUNT: 50,
        DEBOUNCE_MS: 150,
        DEFAULT_TABLE_OFFSET_TOP: 250
    };

    private handleResize = _.throttle(() => this.setState({ viewHeight: window.innerHeight }), this.config.DEBOUNCE_MS);

    private handleScroll = _.throttle(() => {
        const tableOffsetTop = (document.querySelector('.ak-table-body')[0] || {}).offsetTop || this.config.DEFAULT_TABLE_OFFSET_TOP;
        this.setState({ offsetTop: window.pageYOffset - tableOffsetTop });
    }, this.config.DEBOUNCE_MS);

    public componentDidMount() {
        this.setState({
            viewHeight: window.innerHeight,
            radConfig: makeRadConfig(this.props.brukerprofil, true, this.props.erFlateeier)
        });
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    }

    public componentDidUpdate() {
        if (this.props.nySakIder.length && !this.props.arbeidsliste.isLoading) {
            setTimeout(() => this.props.arbeidslisteClearNySakIder(this.props.navigate), NY_SAK_VISNINGSTID_MS);
        }

        if (this.props.skalHenteDokumentasjonsstatus && !this.props.erFlateeier && this.props.erDokumentasjonsstatusEnabled) {
            this.props.finnDokumentasjonsstatus(this.props.arbeidsliste.rader);
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    public render() {
        const { rader } = this.props.arbeidsliste;

        return (
            <div className="ak-fixed-lg-container">
                <ArbeidslisteFilterbarConnected sakstype={ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING} />
                <div className="ak-fixed-container-margin ak-div-table arbeidsliste-large">
                    <div className="ak-table-header ak-table-row">
                        {this.state.radConfig.map(this.headerCellMapper)}
                    </div>
                    <div className="ak-table-body">
                        {!!rader.length && rader.map(this.rowMapper)}
                        <ArbeidslisteFeilhandtering liste={this.props.arbeidsliste} />
                    </div>
                </div>
                <ul className="arbeidsliste-card-list">
                    {!!rader.length && rader.map(this.arbeidslisteCardMapper)}
                </ul>
                <PurreAktorModalConnected showModal={this.state.showPurringModal} closeModal={this.closeModalForPurring} sakId={this.state.purringSakId}
                    varseltype={Varseltype.PURRING_FORSTEGANGSREGISTRERING} />
                <div className="ak-fixed-lg-container-dropdown-pad" />
            </div>
        );
    }

    private openModalForPurring = (sakId: string) => {
        this.setState({
            showPurringModal: true,
            purringSakId: sakId
        });
    };

    private closeModalForPurring = () => {
        this.setState({
            showPurringModal: false,
            purringSakId: ''
        });
    };

    private headerCellMapper = (radConfig: IRadConfigProps): ReactNode => {
        return (
            <ArbeidslisteHeaderCell
                key={radConfig.headerIntlKey}
                intlKey={radConfig.headerIntlKey}
                size={radConfig.cellSize}
                sortProp={radConfig.sortProperty}
                currentSortProp={this.props.arbeidsliste.sortProp}
                currentSortDir={this.props.arbeidsliste.sortDir}
                sort={this.props.sorterArbeidsliste}
            />
        );
    };

    private arbeidslisteCardMapper = (rad: IArbeidslisteRad, index: number) => {
        return this.isElementInView(index)
               ? (
                   <ArbeidslisteCard key={akReactKeyGen(rad)} rad={rad} radConfig={this.state.radConfig}
                       nyRad={_.includes(this.props.nySakIder, rad.sakId)}
                       isForstegangsregistrering={true}
                       openModalForPurring={this.openModalForPurring} />
               ) : <div key={this.rowKey(rad)} className="ak-table-row ak-arbeidsliste-row-empty" />;
    };

    private rowMapper = (rad: IArbeidslisteRad, index: number): ReactNode => {
        const { nySakIder } = this.props;

        return this.isElementInView(index)
               ? (
                   <ArbeidslisteRow
                       radConfig={this.state.radConfig}
                       key={this.rowKey(rad)}
                       rad={rad}
                       nyRad={_.includes(nySakIder, rad.sakId)}
                       openModalForPurring={this.openModalForPurring} />
               ) : <div key={this.rowKey(rad)} className="ak-table-row ak-arbeidsliste-row-empty" />;
    };

    private rowKey = (rad: IArbeidslisteRad): string => (rad.sakId + (rad.sakAktorInfo && rad.sakAktorInfo.aktorType));

    private isElementInView = (index: number) => {
        if (this.props.arbeidsliste.rader.length < this.config.START_VIRTUAL_SCROLL_ON_COUNT) {
            return true;
        }

        const pos = (index + 1) * this.config.ROW_HEIGHT;
        return pos > this.state.offsetTop - this.config.OVERSCAN_TOP && pos < this.state.offsetTop + this.state.viewHeight + this.config.OVERSCAN_BOTTOM;
    };
}

const mapStateToProps = (state: RootStateType): IArbeidslisteForstegangsregistreringerStateProps => ({
    arbeidsliste: filtrertForstegangsregistreringradeSelector(state),
    nySakIder: state.arbeidsliste.nySakIder,
    innloggetForhandlerNavn: state.brukerprofil.valgtEnhet.navn,
    brukerprofil: brukerprofilSelector(state),
    erFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    filter: state.arbeidsliste.filter,
    erDokumentasjonsstatusEnabled: state.globals.klientKonfigurasjon.dokumentstatus.enabled,
    skalHenteDokumentasjonsstatus: state.arbeidsliste.skalHenteDokumentasjonsstatus
});

const mapDispatchToProps = (dispatch): IArbeidslisteForstegangsregistreringerDispatchProps => ({
    arbeidslisteClearNySakIder: (navigate: NavigateType) => dispatch(arbeidsListeNySak(navigate, [], null)),
    sorterArbeidsliste: (sortProp: string) => dispatch(sortArbeidsliste(sortProp, ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING)),
    finnDokumentasjonsstatus: (arbeidslisteRader) => dispatch(finnDokumentasjonsstatus(arbeidslisteRader))
});

const ArbeidslisteForstegangsregistreringerConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ArbeidslisteForstegangsregistreringer);

export { ArbeidslisteForstegangsregistreringerConnected };
