export enum ActionKey {
    HOVEDMENY_SEKSJON_EKSPANDERT = 'HOVEDMENY_SEKSJON_EKSPANDERT',
    ADD_ERROR = 'ADD_ERROR',
    RESET_ERROR = 'RESET_ERROR',
    SET_SKIP_CONFIRM_NAVIGATION = 'SET_SKIP_CONFIRM_NAVIGATION'
}

export enum BrukerprofilActionKey {
    BRUKERPROFIL_HAS_SERVER_ERROR = 'BRUKERPROFIL_HAS_SERVER_ERROR',
    BRUKERPROFIL_IS_LOADING = 'BRUKERPROFIL_IS_LOADING',
    BRUKERPROFIL_FETCH_DATA_SUCCESS = 'BRUKERPROFIL_FETCH_DATA_SUCCESS',
    BRUKERPROFIL_HAS_ERROR = 'BRUKERPROFIL_HAS_ERROR',
    BRUKERPROFIL_ARBEIDSSTED_OPPDATERT = 'BRUKERPROFIL_ARBEIDSSTED_OPPDATERT',
    BRUKERPROFIL_MANGLER_ROLLER = 'BRUKERPROFIL_MANGLER_ROLLER',
    OTHER_ACTION = '__any_other_action__',
}

export enum SystemvarslerActionKey {
    SYSTEMVARSLER_IS_LOADING = 'SYSTEMVARSLER_IS_LOADING',
    SYSTEMVARSLER_FETCH_DATA_SUCCSESS = 'SYSTEMVARSLER_FETCH_DATA_SUCCSESS',
    SYSTEMVARSLER_FETCH_DATA_ERROR = 'SYSTEMVARSLER_FETCH_DATA_ERROR',
    OTHER_ACTION = '__any_other_action__'
}

export enum OversettelserActionKey {
    OVERSETTELSER_FETCH_DATA_SUCCESS = 'OVERSETTELSER_FETCH_DATA_SUCCESS',
    OVERSETTELSER_HAS_ERROR = 'OVERSETTELSER_HAS_ERROR',
    OVERSETTELSER_HAS_SERVER_ERROR = 'OVERSETTELSER_HAS_SERVER_ERROR',
    OVERSETTELSER_IS_LOADING = 'OVERSETTELSER_IS_LOADING',
    OTHER_ACTION = '__any_other_action__'
}

export enum OppslagActionKey {
    OPPSLAG_IS_LOADING = 'OPPSLAG_IS_LOADING',
    OPPSLAG_HAS_SERVER_ERROR = 'OPPSLAG_HAS_SERVER_ERROR',
    OPPSLAG_HAS_ERROR = 'OPPSLAG_HAS_ERROR',
    OPPSLAG_FETCH_DATA_SUCCESS = 'OPPSLAG_FETCH_DATA_SUCCESS',
    OPPSLAG_RESET_SOKE_FELT = 'OPPSLAG_RESET_SOKE_FELT',
    OPPSLAG_OPPDATER_SOKE_FELT = 'OPPSLAG_OPPDATER_SOKE_FELT',
    OTHER_ACTION = '__any_other_action__'
}

export enum KjoretoyActionKey {
    KJORETOY_CLEAR_STATE = 'KJORETOY_CLEAR_STATE',
    KJORETOY_LOADING = 'KJORETOY_LOADING',
    KJORETOY_MERKNADER_LOADING = 'KJORETOY_MERKNADER_LOADING',
    KJORETOY_NOT_FOUND = 'KJORETOY_NOT_FOUND',
    KJORETOY_NOT_SHOW = 'KJORETOY_NOT_SHOW',
    KJORETOY_HAS_ERROR = 'KJORETOY_HAS_ERROR',
    KJORETOY_LIST_HAS_ERROR = 'KJORETOY_LIST_HAS_ERROR',
    KJORETOY_FETCH_DATA_SUCCESS = 'KJORETOY_FETCH_DATA_SUCCESS',
    KJORETOY_MED_MERKNADER_FETCH_DATA_SUCCESS = 'KJORETOY_MED_MERKNADER_FETCH_DATA_SUCCESS',
    KJORETOY_LIST_CLEAR = 'KJORETOY_LIST_CLEAR',
    KJORETOY_LIST_CLEAR_FAILED = 'KJORETOY_LIST_CLEAR_FAILED',
    KJORETOY_LIST_REMOVE = 'KJORETOY_LIST_REMOVE',
    KJORETOY_LIST_ADD = 'KJORETOY_LIST_ADD',
    KJORETOY_LIST_IS_LOADING = 'KJORETOY_LIST_IS_LOADING',
    OTHER_ACTION = '__any_other_action__'
}

export enum EierskifterActionKey {
    EIERSKIFTER_GET_LOADING = 'EIERSKIFTER_GET_LOADING',
    EIERSKIFTER_GET_HAS_ERROR = 'EIERSKIFTER_GET_HAS_ERROR',
    EIERSKIFTER_GET_DATA_SUCCESS = 'EIERSKIFTER_GET_DATA_SUCCESS',
    EIERSKIFTER_GET_NOT_FOUND = 'EIERSKIFTER_NOT_FOUND',
    EIERSKIFTER_CREATE_SUCCESS = 'EIERSKIFTER_CREATE_SUCCESS',
    EIERSKIFTER_UPDATE_SUCCESS = 'EIERSKIFTER_UPDATE_SUCCESS',
    EIERSKIFTER_UPDATE_HAS_ERROR = 'EIERSKIFTER_UPDATE_HAS_ERROR',
    EIERSKIFTER_UPDATE_LOADING = 'EIERSKIFTER_UPDATE_LOADING',
    EIERSKIFTER_LOADING = 'EIERSKIFTER_LOADING',
    EIERSKIFTER_HAS_ERROR = 'EIERSKIFTER_HAS_ERROR',
    EIERSKIFTER_GODKJENN_SUCCESS = 'EIERSKIFTER_GODKJENN_SUCCESS',
    EIERSKIFTER_AVVIS_SUCCESS = 'EIERSKIFTER_AVVIS_SUCCESS',
    EIERSKIFTER_AVBRYT_SUCCESS = 'EIERSKIFTER_AVBRYT_SUCCESS',
    OTHER_ACTION = '__any_other_action__'
}

export enum EierskifteArkivdokumentActionKey {
    EIERSKIFTE_ARKIVDOKUMENT_ADD = 'EIERSKIFTE_ARKIVDOKUMENT_ADD',
    EIERSKIFTE_ARKIVDOKUMENT_DELETE = 'EIERSKIFTE_ARKIVDOKUMENT_DELETE',
    EIERSKIFTE_ARKIVDOKUMENT_EDIT = 'EIERSKIFTE_ARKIVDOKUMENT_EDIT',
    EIERSKIFTE_ARKIVDOKUMENT_LOADING = 'EIERSKIFTE_ARKIVDOKUMENT_LOADING',
    EIERSKIFTE_ARKIVDOKUMENTER_ERROR = 'EIERSKIFTE_ARKIVDOKUMENT_ERROR',
    EIERSKIFTE_ARKIVDOKUMENTER_SUCCESS = 'EIERSKIFTE_ARKIVDOKUMENTER_SUCCESS',
    OTHER_ACTION = '__any_other_action__'
}

export enum KundeActionKey {
    HENT_KUNDE_START = 'HENT_KUNDE_START',
    KUNDE_LOADING = 'KUNDE_LOADING',
    KUNDE_NOT_FOUND = 'KUNDE_NOT_FOUND',
    KUNDE_NOT_SHOW = 'KUNDE_NOT_SHOW',
    KUNDE_HAS_ERROR = 'KUNDE_HAS_ERROR',
    KUNDE_FETCH_DATA_SUCCESS = 'KUNDE_FETCH_DATA_SUCCESS',
    KUNDE_FORHANDLER = 'KUNDE_FORHANDLER',
    KUNDE_REMOVE = 'KUNDE_REMOVE',
    KUNDE_FETCH_AVDELINGER_SUCCESS = 'KUNDE_FETCH_AVDELINGER_SUCCESS',
    OTHER_ACTION = '__any_other_action__'
}

export enum ForhandlereActionKey {
    FORHANDLER_LOADING = 'FORHANDLER_LOADING',
    FORHANDLER_NOT_FOUND = 'FORHANDLER_NOT_FOUND',
    FORHANDLER_HAS_ERROR = 'FORHANDLER_HAS_ERROR',
    FORHANDLER_FETCH_DATA_SUCCESS = 'FORHANDLER_FETCH_DATA_SUCCESS',
    OTHER_ACTION = '__any_other_action__'
}

export enum ArbeidslisteActionKey {
    ARBEIDSLISTE_LOADING = 'ARBEIDSLISTE_LOADING',
    ARBEIDSLISTE_FETCH_DATA_SUCCESS = 'ARBEIDSLISTE_FETCH_DATA_SUCCESS',
    ARBEIDSLISTE_CLEAR_ALL = 'ARBEIDSLISTE_CLEAR_ALL',
    ARBEIDSLISTE_CLEAR_EIERSKIFTER_OG_REGISTRERINGER = 'ARBEIDSLISTE_CLEAR_EIERSKIFTER_OG_REGISTRERINGER',
    ARBEIDSLISTE_CLEAR_FORSTEGANGSREGISTRERINGER = 'ARBEIDSLISTE_CLEAR_FORSTEGANGSREGISTRERINGER',
    ARBEIDSLISTE_FETCH_DATA_ERROR = 'ARBEIDSLISTE_FETCH_DATA_ERROR',
    ARBEIDSLISTE_NY_SAK = 'ARBEIDSLISTE_NY_SAK',
    ARBEIDSLISTE_SAK_FJERNET = 'ARBEIDSLISTE_SAK_FJERNET',
    ARBEIDSLISTE_SAK_GODKJENT = 'ARBEIDSLISTE_SAK_GODKJENT',
    ARBEIDSLISTE_UPDATE_RAD = 'ARBEIDSLISTE_UPDATE_RAD',
    ARBEIDSLISTE_UPDATE_FORSTEGANGSREGISTRERING_RAD = 'ARBEIDSLISTE_UPDATE_FORSTEGANGSREGISTRERING_RAD',
    ARBEIDSLISTE_UPDATE_EIERSKIFTE_OG_REGISTRERING_RAD ='ARBEIDSLISTE_UPDATE_EIERSKIFTE_OG_REGISTRERING_RAD',
    ARBEIDSLISTE_SORTER = 'ARBEIDSLISTE_SORTER',
    ARBEIDSLISTE_NY_MERKNADER = 'ARBEIDSLISTE_NY_MERKNADER',
    ARBEIDSLISTE_HENT_REGISTRERINGSSTATUSKODE = 'ARBEIDSLISTE_HENT_REGISTRERINGSSTATUSKODE',
    ARBEIDSLISTE_HENT_ARKIVDOKUMENT_LOADING = 'ARBEIDSLISTE_HENT_ARKIVDOKUMENT_LOADING',
    ARBEIDSLISTE_HENT_ARKIVDOKUMENT_SUCCESS = 'ARBEIDSLISTE_HENT_ARKIVDOKUMENT_SUCCESS',
    ARBEIDSLISTE_UPDATE_FILTER = 'ARBEIDSLISTE_UPDATE_FILTER',
    OTHER_ACTION = '__any_other_action__'
}

export enum KlientkonfigurasjonActionKey {
    KLIENTKONFIGURASJON_IS_LOADING = 'KLIENTKONFIGURASJON_IS_LOADING',
    KLIENTKONFIGURASJON_HAS_ERROR = 'KLIENTKONFIGURASJON_HAS_ERROR',
    KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS = 'KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS',
    OTHER_ACTION = '__any_other_action__'
}

export enum MeldingBarActionKey {
    MELDING_BAR_NY_MELDING = 'MELDING_BAR_NY_MELDING',
    MELDING_BAR_FJERN_MELDING = 'MELDING_BAR_FJERN_MELDING',
    MELDING_BAR_FJERN_MELDINGER = 'MELDING_BAR_FJERN_MELDINGER',
    OTHER_ACTION = '__any_other_action__'
}

export enum RegistreringerActionKey {
    REGISTRERINGER_CLEAR_STATE = 'REGISTRERINGER_CLEAR_STATE',
    REGISTRERINGER_AVBRYT_SUCCESS = 'REGISTRERINGER_AVBRYT_SUCCESS',
    REGISTRERINGER_CREATE_SUCCESS = 'REGISTRERINGER_CREATE_SUCCESS',
    REGISTRERINGER_HAS_ERROR = 'REGISTRERINGER_HAS_ERROR',
    REGISTRERINGER_VEDTAK_HAS_ERROR = 'REGISTRERINGER_VEDTAK_HAS_ERROR',
    REGISTRERINGER_LOADING = 'REGISTRERINGER_LOADING',
    REGISTRERINGER_GET_SUCCESS = 'REGISTRERINGER_GET_SUCCESS',
    REGISTRERINGER_UPDATE_SUCCESS = 'REGISTRERINGER_UPDATE_SUCCESS',
    REGISTRERINGER_OPPDATER_SKJEMA = 'REGISTRERINGER_OPPDATER_SKJEMA',
    REGISTRERINGER_OPPDATER_TILHENGERPANEL = 'REGISTRERINGER_OPPDATER_TILHENGERPANEL',
    REGISTRERINGER_POPULER_NY_SAK = 'REGISTRERINGER_POPULER_NY_SAK',
    OTHER_ACTION = '__any_other_action__'
}

export enum RegistreringArkivdokumentActionKey {
    REGISTRERING_ARKIVDOKUMENT_ADD = 'REGISTRERING_ARKIVDOKUMENT_ADD',
    REGISTRERING_ARKIVDOKUMENT_EDIT = 'REGISTRERING_ARKIVDOKUMENT_EDIT',
    REGISTRERING_ARKIVDOKUMENT_DELETE = 'REGISTRERING_ARKIVDOKUMENT_DELETE',
    REGISTRERING_DOK_STATUS = 'REGISTRERING_DOK_STATUS',
    REGISTRERING_DOK_LOADING = 'REGISTRERING_DOK_LOADING',
    REGISTRERING_DOK_STATUS_HAS_ERROR = 'REGISTRERING_DOK_STATUS_HAS_ERROR',
    OTHER_ACTION = '__any_other_action__'
}

export enum EgneKjoretoyActionKey {
    HENT_EGNE_KJORETOY_KUNDE_START = 'HENT_EGNE_KJORETOY_KUNDE_START',
    HENT_EGNE_KJORETOY_SUCCESS = 'HENT_EGNE_KJORETOY_SUCCESS',
    HENT_EGNE_KJORETOY_ERROR = 'HENT_EGNE_KJORETOY_ERROR',
    OTHER_ACTION = '__any_other_action__'
}

export enum KjoringensArtListeActionKey {
    KJORINGENS_ART_LISTE_IS_LOADING = 'KJORINGENS_ART_LISTE_IS_LOADING',
    KJORINGENS_ART_LISTE_FETCH_DATA_SUCCESS = 'KJORINGENS_ART_LISTE_FETCH_DATA_SUCCESS',
    KJORINGENS_ART_LISTE_HAS_ERROR = 'KJORINGENS_ART_LISTE_HAS_ERROR',
    OTHER_ACTION = '__any_other_action__'
}

export enum AvgiftsstatusActionKey {
    UTLED_AVGIFTSSTATUS_START = 'UTLED_AVGIFTSSTATUS_START',
    UTLED_AVGIFTSSTATUS_SUCCESS = 'UTLED_AVGIFTSSTATUS_SUCCESS',
    UTLED_AVGIFTSSTATUS_ERROR = 'UTLED_AVGIFTSSTATUS_ERROR',
    OTHER_ACTION = '__any_other_action__'
}

export enum ForstegangsregistreringActionKey {
    FORSTEGANGSREGISTRERING_UPDATE = 'FORSTEGANGSREGISTRERING_UPDATE',
    FORSTEGANGSREGISTRERING_RESET = 'FORSTEGANGSREGISTRERING_RESET'
}

export enum SakActionKey {
    SAK_IS_LOADING = 'SAK_IS_LOADING',
    SAK_LOADING_MODAL = 'SAK_LOADING_MODAL',
    SAK_REQUEST_SUCCESS = 'SAK_REQUEST_SUCCESS',
    SAK_REQUEST_ERROR = 'SAK_REQUEST_ERROR',
    SAKER_REMOVE_SUCCESS = 'SAKER_REMOVE_SUCCESS',
    SAK_RESET = 'SAK_RESET',
    SAK_UPDATE_MERKNADER = 'SAK_UPDATE_MERKNADER',
    SAK_DOCUMENT_ERROR = 'SAK_DOCUMENT_ERROR',
    OTHER_ACTION = '__any_other_action__'
}

export enum SakerActionKey {
    SAKER_IS_LOADING = 'SAKER_IS_LOADING',
    SAKER_FETCH_DATA_SUCCESS = 'SAKER_FETCH_DATA_SUCCESS'
}

export enum KjennemerkeActionKey {
    KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_SUCCESS = 'KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_SUCCESS',
    KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_LOADING = 'KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_LOADING',
    KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_ERROR = 'KJENNEMERKE_SERIER_OG_TYPER_FETCH_DATA_ERROR',
    KJENNEMERKE_SERIER_OG_TYPER_RESET = 'KJENNEMERKE_SERIER_OG_TYPER_RESET',
    KJENNEMERKE_SERIE_FETCH_DATA_SUCCESS = 'KJENNEMERKE_SERIE_FETCH_DATA_SUCCESS',
    KJENNEMERKE_SERIE_FETCH_DATA_LOADING = 'KJENNEMERKE_SERIE_FETCH_DATA_LOADING',
    KJENNEMERKE_SERIE_FETCH_DATA_ERROR = 'KJENNEMERKE_SERIE_FETCH_DATA_ERROR'
}

export enum KontrollerVilkarActionKey {
    KONTROLLER_VILKAR_SUCCESS = 'KONTROLLER_VILKAR_SUCCESS',
    KONTROLLER_VILKAR_ERROR = 'KONTROLLER_VILKAR_ERROR',
    KONTROLLER_VILKAR_LOADING = 'KONTROLLER_VILKAR_LOADING',
    KONTROLLER_VILKAR_RESET = 'KONTROLLER_VILKAR_RESET',
    KONTROLLER_VILKAR_UPDATE_WAITING = 'KONTROLLER_VILKAR_UPDATE_WAITING'
}

export enum MidlertidigVognkortActionKey {
    MIDLERTIDIG_VOGNKORT_LOADING = 'MIDLERTIDIG_VOGNKORT_LOADING',
    MIDLERTIDIG_VOGNKORT_SUCCESS = 'MIDLERTIDIG_VOGNKORT_SUCCESS',
    MIDLERTIDIG_VOGNKORT_ERROR = 'MIDLERTIDIG_VOGNKORT_ERROR',
    MIDLERTIDIG_VOGNKORT_RESET = 'MIDLERTIDIG_VOGNKORT_RESET'
}

export enum ForstegangsregistreringArkivdokumentActionKey {
    FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_ADD = 'FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_ADD',
    FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_DELETE = 'FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_DELETE',
    FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_EDIT = 'FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_EDIT',
    FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_LOADING = 'FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_LOADING',
    FORSTEGANGSREGISTRERING_ARKIVDOKUMENTER_ERROR = 'FORSTEGANGSREGISTRERING_ARKIVDOKUMENT_ERROR',
    FORSTEGANGSREGISTRERING_ARKIVDOKUMENTER_SUCCESS = 'FORSTEGANGSREGISTRERING_ARKIVDOKUMENTER_SUCCESS',
    OTHER_ACTION = '__any_other_action__'

}

export enum LeasingtakerEndreActionKey {
    LEASINGTAKER_HAS_ERROR = 'LEASINGTAKER_HAS_ERROR'
}

export enum GrunnlagVektarsavgiftActionKey {
    GRUNNLAG_VEKTARSAVGIFT_SUCCESS = 'GRUNNLAG_VEKTARSAVGIFT_SUCCESS',
    GRUNNLAG_VEKTARSAVGIFT_LOADING = 'GRUNNLAG_VEKTARSAVGIFT_LOADING',
    GRUNNLAG_VEKTARSAVGIFT_ERROR = 'GRUNNLAG_VEKTARSAVGIFT_ERROR',
    GRUNNLAG_VEKTARSAVGIFT_RESET = 'GRUNNLAG_VEKTARSAVGIFT_RESET'
}
