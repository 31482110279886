import * as React from 'react';
import { Component } from 'react';
import type { WrappedComponentProps} from 'react-intl';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import type { IKunde, IRegistreringSak } from '../../models/types';
import { oppdaterRegistreringSkjema } from '../../state/actions';
import { erLesemodusSelector, gjeldendeEierSelector, registreringSakSelector } from '../../state/selectors';
import type { ILeasingtakerOgUnderenhet} from '../felles-registrering';
import { LeasingtakerUnderenhetConnected } from '../felles-registrering';

interface IRegistreringLeasingtakerOgUnderenhetStateProps {
    erLesemodus?: boolean;
    eier?: IKunde;
    registreringSak?: IRegistreringSak;
}

interface IRegistreringLeasingtakerOgUnderenhetDispatchProps {
    oppdaterRegistreringSkjema: (sak: Partial<IRegistreringSak>) => Promise<any>;
}

interface IRegistreringLeasingtakerUnderenhetProps {
    validationErrors: string[];
    submitted: boolean;
}

type PropsType = IRegistreringLeasingtakerUnderenhetProps & IRegistreringLeasingtakerOgUnderenhetStateProps & IRegistreringLeasingtakerOgUnderenhetDispatchProps & WrappedComponentProps;

class RegistreringLeasingtakerOgUnderenhet extends Component<PropsType> {

    public render(): React.ReactElement {
        const sak = this.props.registreringSak;

        if (!sak || !sak.saksstatus) {
            return null;
        }

        return (
            <LeasingtakerUnderenhetConnected submitted={this.props.submitted} saksstatus={sak.saksstatus} nyEier={this.props.eier} saksinformasjon={sak}
                lesemodus={this.props.erLesemodus} errors={this.props.validationErrors} oppdaterLeasingtakerOgUnderenhet={this.oppdaterLeasingtakerOgUnderenhet} />
        );
    }

    private oppdaterLeasingtakerOgUnderenhet = (endring: ILeasingtakerOgUnderenhet) => {
        this.props.oppdaterRegistreringSkjema({ ...endring });
    };
}

const mapStateToProps = (state) => ({
    erLesemodus: erLesemodusSelector(state),
    eier: gjeldendeEierSelector(state),
    registreringSak: registreringSakSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
    oppdaterRegistreringSkjema: (sak: Partial<IRegistreringSak>) => (dispatch(oppdaterRegistreringSkjema(sak)))
});

const RegistreringLeasingtakerOgUnderenhetConnected = connect(mapStateToProps, mapDispatchToProps)(injectIntl(RegistreringLeasingtakerOgUnderenhet));

export { RegistreringLeasingtakerOgUnderenhetConnected, RegistreringLeasingtakerOgUnderenhet };
