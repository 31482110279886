import { Panel } from 'svv-tk-akr-common-frontend';
import * as React from 'react';
import type { IntlShape, WrappedComponentProps } from 'react-intl';
import { DlTable } from '../../components';
import type { IKjoretoy, IRegistrering } from '../../models/types';
import { KjoretoyRegler } from '../../regler';
import { akFormatDate } from '../../utils';

interface IKjoretoydetaljerRegistreringProps {
    registrering: IRegistrering;
    kjoretoy: IKjoretoy;
}

const extractRegistreringLeftColumnData = (registrering: IRegistrering, intl: IntlShape): Record<string, any> => {
    const {
        registrertForsteGang = '',
        registrertForsteGangAr = '',
        registrertForsteGangNorge = '',
        registrertForsteGangEier = '',
        paregistrertInneverendeEier = false
    } = registrering.registreringsinformasjon;

    const registrertForsteGangEierTekst
        = paregistrertInneverendeEier ? akFormatDate(registrertForsteGangEier) : intl.formatMessage({id: 'kjoretoydetaljer.meldinger.venterPaOmregistrering'});

    return {
        registrertForsteGang: akFormatDate(registrertForsteGang || registrertForsteGangAr),
        registrertForsteGangNorge: akFormatDate(registrertForsteGangNorge),
        registrertForsteGangEier: registrertForsteGangEierTekst
    };
};

const extractRegistreringRightColumnData = (kjoretoy: IKjoretoy, registrering: IRegistrering): Record<string, any> => {
    const {
        registreringsstatusBeskrivelse = 'UKJENT',
        registreringsstatusDato = '',
        avregistrertSidenDato = ''
    } = registrering.registreringsinformasjon;

    const kjennemerkeinformasjon = new KjoretoyRegler(kjoretoy).utledKjennemerkeinformasjon();
    const regdato = avregistrertSidenDato || registreringsstatusDato;

    const rightColumnData: {[key: string]: any} = {
        registreringsstatus: `${registreringsstatusBeskrivelse} ${akFormatDate(regdato)}`
    };

    if (kjennemerkeinformasjon) {
        rightColumnData.kjennemerkeinformasjon = kjennemerkeinformasjon;
    }

    return rightColumnData;
};

const KjoretoydetaljerRegistrering: React.FC<IKjoretoydetaljerRegistreringProps & WrappedComponentProps> = (props) => {
    if (!props.registrering?.registreringsinformasjon) {
        return null;
    }

    return (
        <Panel overskrift={'kjoretoydetaljer.registrering.overskrift'} panelCssClass="ak-med-tabell ak-panel-transparent" innholdCssClass="row no-gutters">
            <DlTable customDlCss="col-12 col-md-6" intlLabelKey={'kjoretoydetaljer.registrering.label'}
                listMap={extractRegistreringLeftColumnData(props.registrering, props.intl)} />
            <DlTable customDlCss="col-12 col-md-6" intlLabelKey={'kjoretoydetaljer.registrering.label'}
                listMap={extractRegistreringRightColumnData(props.kjoretoy, props.registrering)} />
        </Panel>
    );
};

export { KjoretoydetaljerRegistrering };
