import * as _ from 'lodash';

import { KjoretoyActionKey, MidlertidigVognkortActionKey } from '../../constants/action-keys';
import type { IKjoretoy, IKjoretoyList, IMidlertidigVognkort } from '../../models/types';
import type { KjoretoyActionType, KjoretoyListActionType, MidlertidigVognkortActionType } from '../actions';

const kjoretoyInitialState: IKjoretoy = {
    registrering: {},
    isLoading: false,
    notFound: false,
    error: null,
    merknader: [],
    apneEierskifter: []
};

const kjoretoyListIntitialState: IKjoretoyList = {
    isLoading: false,
    kjoretoy: [],
    failedKjoretoy: null
};

const midlertidigVognkortInitialState: IMidlertidigVognkort = {
    merknader: [],
    midlertidigVognkort: '',
    error: null,
    feil: null,
    isLoading: false,
    notFound: false
};

export function kjoretoy(state: IKjoretoy = kjoretoyInitialState, action: KjoretoyActionType): IKjoretoy {
    switch (action.type) {
        case KjoretoyActionKey.KJORETOY_CLEAR_STATE:
            return {...kjoretoyInitialState};
        case KjoretoyActionKey.KJORETOY_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: action.isLoading, error: null, notFound: false};
            }
            return {...state, isLoading: action.isLoading, notFound: false};
        case KjoretoyActionKey.KJORETOY_NOT_FOUND:
            return {...kjoretoyInitialState, notFound: true};
        case KjoretoyActionKey.KJORETOY_NOT_SHOW:
            return {...kjoretoyInitialState, ...action.kjoretoy};
        case KjoretoyActionKey.KJORETOY_HAS_ERROR:
            return {...kjoretoyInitialState, error: action.error};
        case KjoretoyActionKey.KJORETOY_FETCH_DATA_SUCCESS:
            return {...kjoretoyInitialState, ...action.kjoretoy, error: null, notFound: false};
        default:
            return state;
    }
}

export function kjoretoyList(state = kjoretoyListIntitialState, action: KjoretoyListActionType): IKjoretoyList {
    switch (action.type) {
        case KjoretoyActionKey.KJORETOY_LIST_IS_LOADING:
            return {...state, isLoading: action.isLoading, failedKjoretoy: action.isLoading ? null : state.failedKjoretoy};
        case KjoretoyActionKey.KJORETOY_LIST_CLEAR_FAILED:
            return {...state, failedKjoretoy: null};
        case KjoretoyActionKey.KJORETOY_LIST_CLEAR:
            return kjoretoyListIntitialState;
        case KjoretoyActionKey.KJORETOY_LIST_REMOVE:
            return {...state, kjoretoy: filterKjoretoyList(state, action.kjennemerke, action?.kjennemerkeIRegistrering)};
        case KjoretoyActionKey.KJORETOY_LIST_ADD:
            return {...state, kjoretoy: [action.response, ...state.kjoretoy]};
        case KjoretoyActionKey.KJORETOY_LIST_HAS_ERROR:
            return {...state, failedKjoretoy: action.error};
        default:
            return state;
    }
}

export function midlertidigVognkort(state = midlertidigVognkortInitialState, action: MidlertidigVognkortActionType): IMidlertidigVognkort {
    switch (action.type) {
        case MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_LOADING:
            return {...state, isLoading: action.isLoading};
        case MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_ERROR:
            return {...state, error: action.error};
        case MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_SUCCESS:
            return {...midlertidigVognkortInitialState, ...action.vognkort};
        case MidlertidigVognkortActionKey.MIDLERTIDIG_VOGNKORT_RESET:
            return midlertidigVognkortInitialState;
        default:
            return state;
    }
}

const filterKjoretoyList = (state: IKjoretoyList, kjennemerke: string, kjennemerkeIRegistrering?: boolean) => {
    if (kjennemerkeIRegistrering) {
        return _.reject(state.kjoretoy, {registrering:
                {
                    kjennemerke: {tegnkombinasjon: kjennemerke}}
        });
    }

    return _.reject(state.kjoretoy, {kjennemerke: kjennemerke.replace(' ', '')});
};
