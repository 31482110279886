import { VilkarStatus } from 'svv-tk-akr-common-frontend';
import { ArbeidslisteValg, GjelderType, SaksType, SamletGodkjenningStatus } from '../models/kodeverk';
import type { Brukerprofil, IArbeidslisteRad } from '../models/types';
import { BrukerRegler } from './bruker-regler';

export function finnValgForArbeidslisterad(brukerprofil: Brukerprofil, arbeidslisteRad: IArbeidslisteRad): ArbeidslisteValg[] {
    const valg: ArbeidslisteValg[] = [];
    const brukerRegler = new BrukerRegler(brukerprofil);
    const isFlaateeier = brukerRegler.harRolleFlateeier();

    if (isFlaateeier && arbeidslisteRad.saksType === SaksType.FORSTEGANGSREGISTRERING) {
        return finnValgForFlateeierForstegangsregistrering(arbeidslisteRad);
    }
    if (isFlaateeier && arbeidslisteRad.saksType === SaksType.EIERSKIFTE) {
        return finnValgForFlateeierEierskifte(brukerprofil, arbeidslisteRad);
    }

    if (arbeidslisteRad.saksType === SaksType.EIERSKIFTE) {
        finnValgForEierskifte(brukerprofil, arbeidslisteRad, valg);
    } else if ([SaksType.REGISTRERING_NY_EIER, SaksType.REGISTRERING_SAMME_EIER, SaksType.FORSTEGANGSREGISTRERING].indexOf(arbeidslisteRad.saksType) >= 0) {
        finnValgForRegistrering(brukerprofil, arbeidslisteRad, valg);
    }

    return valg;
}

function finnValgForRegistrering(brukerprofil: Brukerprofil, arbeidslisteRad: IArbeidslisteRad, valg: ArbeidslisteValg[]): void {
    const dokStatusMaGodkjennesEllerMangler = VilkarStatus.MA_GODKJENNES === arbeidslisteRad.samletDokumentStatus || VilkarStatus.IKKE_OPPFYLT === arbeidslisteRad.samletDokumentStatus;

    switch (arbeidslisteRad.samletGodkjenningStatus) {
        case SamletGodkjenningStatus.PABEGYNT_REGISTRERING:
            if (hasAccessToSakstype(brukerprofil, arbeidslisteRad) && !dokStatusMaGodkjennesEllerMangler) {
                valg.push(ArbeidslisteValg.OPPDATER_BETALING);
            }
            break;
        case SamletGodkjenningStatus.REGISTRERT:
            valg.push(ArbeidslisteValg.MIDLERTIDIG_VOGNKORT);
            valg.push(ArbeidslisteValg.AVSLUTT);
            break;
        case SamletGodkjenningStatus.GODKJENT:
            if (!dokStatusMaGodkjennesEllerMangler) {
                valg.push(ArbeidslisteValg.REGISTRER);
            }
            break;
        case SamletGodkjenningStatus.AVVIST:
            valg.push(ArbeidslisteValg.AVSLUTT);
            break;
        case SamletGodkjenningStatus.VENTER:
            valg.push(ArbeidslisteValg.PURRE);
            break;
    }
}

function finnValgForEierskifte(brukerprofil: Brukerprofil, arbeidslisteRad: IArbeidslisteRad, valg: ArbeidslisteValg[]): void {
    const dokStatusMaGodkjennesEllerMangler = VilkarStatus.MA_GODKJENNES === arbeidslisteRad.samletDokumentStatus || VilkarStatus.IKKE_OPPFYLT === arbeidslisteRad.samletDokumentStatus;
    const brukerRegler = new BrukerRegler(brukerprofil);
    switch (arbeidslisteRad.samletGodkjenningStatus) {
        case SamletGodkjenningStatus.VENTER:
            if (hasAccessToSakstype(brukerprofil, arbeidslisteRad) && !arbeidslisteRad.sakAktorInfo.godkjenningsstatus) {
                valg.push(ArbeidslisteValg.GODKJENN);
                valg.push(ArbeidslisteValg.AVVIS);
            }
            valg.push(ArbeidslisteValg.PURRE);
            break;
        case SamletGodkjenningStatus.AVVIST:
            if (hasAccessToSakstype(brukerprofil, arbeidslisteRad)) {
                valg.push(ArbeidslisteValg.AVSLUTT);
                valg.push(ArbeidslisteValg.NYTT_EIERSKIFTE);
            }
            break;
        case SamletGodkjenningStatus.GODKJENT:
            if (dokStatusMaGodkjennesEllerMangler) {
                break;
            } else if (arbeidslisteRad.gjelder === GjelderType.EIERSKIFTE_FRA) {
                if (hasAccessToSakstype(brukerprofil, arbeidslisteRad)) {
                    valg.push(ArbeidslisteValg.NYTT_EIERSKIFTE);
                }
                if (brukerRegler.harRolleOmreg()) {
                    valg.push(ArbeidslisteValg.OMREGISTRER);
                    valg.push(ArbeidslisteValg.AVSLUTT);
                }
            } else {
                if (brukerRegler.harRolleOmreg()) {
                    valg.push(ArbeidslisteValg.OMREGISTRER);
                    valg.push(ArbeidslisteValg.AVSLUTT);
                }
            }
            break;
    }
}

export function finnValgForFlateeierForstegangsregistrering(arbeidslisteRad: IArbeidslisteRad): ArbeidslisteValg[] {
    if (arbeidslisteRad.samletGodkjenningStatus === SamletGodkjenningStatus.VENTER && arbeidslisteRad.venterPaGodkjenningFraInnloggetEnhet) {
        return [ArbeidslisteValg.GODKJENN, ArbeidslisteValg.AVVIS];
    }

    return [];
}

export function finnValgForFlateeierEierskifte(brukerprofil: Brukerprofil, arbeidslisteRad: IArbeidslisteRad): ArbeidslisteValg[] {
    const brukerId = brukerprofil.bruker.brukerId;
    // opprettetAv og brukerId blir ikke satt for Altinn brukere
    const sakErOpprettetIForhandlerKanal = arbeidslisteRad.opprettetAv !== '';
    const eierskifteInitiertAvInnloggetAltinnBruker = sakErOpprettetIForhandlerKanal && !brukerId;
    const eierskifteInitiertAvInnloggetBruker = sakErOpprettetIForhandlerKanal && arbeidslisteRad.opprettetAv === brukerId;

    if (arbeidslisteRad.samletGodkjenningStatus === SamletGodkjenningStatus.GODKJENT || arbeidslisteRad.samletGodkjenningStatus === SamletGodkjenningStatus.AVVIST) {
        return [ArbeidslisteValg.AVSLUTT];
    }

    if (arbeidslisteRad.samletGodkjenningStatus === SamletGodkjenningStatus.VENTER) {
        if (arbeidslisteRad.venterPaGodkjenningFraInnloggetEnhet) {
            return [ArbeidslisteValg.GODKJENN, ArbeidslisteValg.AVVIS];
        }
        if (eierskifteInitiertAvInnloggetBruker || eierskifteInitiertAvInnloggetAltinnBruker) {
            return [ArbeidslisteValg.PURRE];
        }
    }

    return [];
}

export function hasAccessToSakstype(brukerprofil: Brukerprofil, rad: IArbeidslisteRad): boolean {
    const brukerRegler = new BrukerRegler(brukerprofil);
    switch (rad.saksType) {
        case SaksType.FORSTEGANGSREGISTRERING:
            return brukerRegler.harRolleForTilgangTilForstegangsregistrering();
        case SaksType.EIERSKIFTE:
            return brukerRegler.harRolleEierskifte() || brukerRegler.harRolleFlateeier();
        case SaksType.REGISTRERING_NY_EIER:
        case SaksType.REGISTRERING_SAMME_EIER:
            return brukerRegler.harRolleOmreg();
        default:
            return false;
    }
}
