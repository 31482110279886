import { EierskifterActionKey } from '../../constants';
import type { IEierskifte, IEierskifter } from '../../models/types';
import type { EierskifterActionType } from '../actions';

const eierskifterInitialState: IEierskifter = {
    isLoading: false,
    notFound: false,
    eierskifter: [],
    kjennemerke: null,
    error: null
};

const eierskifteInitialState: IEierskifte = {
    kjennemerke: null,
    kuid: null,
    aktorer: []
};

export function eierskifter(state: IEierskifter = eierskifterInitialState, action: EierskifterActionType): IEierskifter {
    switch (action.type) {
        case EierskifterActionKey.EIERSKIFTER_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: action.isLoading, error: null};
            }

            return {...state, isLoading: action.isLoading};
        case EierskifterActionKey.EIERSKIFTER_HAS_ERROR:
            return {...eierskifterInitialState, error: action.error};
        default:
            return state;
    }
}

export function eierskifte(state: IEierskifte = eierskifteInitialState, action: EierskifterActionType): IEierskifte {
    switch (action.type) {
        case EierskifterActionKey.EIERSKIFTER_GET_LOADING:
            if (action.isLoading) {
                return {...state, isLoading: action.isLoading, error: null};
            }

            return {...state, isLoading: action.isLoading};
        case EierskifterActionKey.EIERSKIFTER_HAS_ERROR:
            return {...state, error: action.error, isLoading: false};
        case EierskifterActionKey.EIERSKIFTER_GET_HAS_ERROR:
            return {...eierskifteInitialState, error: action.error, isLoading: false};
        case EierskifterActionKey.EIERSKIFTER_GET_DATA_SUCCESS:
            return {...eierskifteInitialState, ...action.eierskifte};
        case EierskifterActionKey.EIERSKIFTER_CREATE_SUCCESS:
            if (action.eierskifte.merknader && action.eierskifte.merknader.length) {
                return {...state, merknader: action.eierskifte.merknader, isLoading: false};
            } else {
                return {...eierskifteInitialState, ...action.eierskifte, isLoading: false};
            }
        case EierskifterActionKey.EIERSKIFTER_UPDATE_SUCCESS:
            return {...eierskifteInitialState, ...action.eierskifte};
        case EierskifterActionKey.EIERSKIFTER_GODKJENN_SUCCESS:
            return {...state, isLoading: false};
        default:
            return state;
    }
}
