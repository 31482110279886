import { GrunnlagVektarsavgiftActionKey } from '../../constants';
import type { IGrunnlagVektarsavgift } from '../../models/types';
import type { GrunnlagVektarsavgiftType } from '../actions';

const grunnlagVektarsavgiftInitialState: IGrunnlagVektarsavgift = {
    skalTrekkeTilhenger: null,
    antallAkslerTilhenger: null,
    totalvektTilhenger: null,
    isLoading: false
};

export function grunnlagVektarsavgift(state = grunnlagVektarsavgiftInitialState, action: GrunnlagVektarsavgiftType): IGrunnlagVektarsavgift {
    switch (action.type) {
        case GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_SUCCESS:
            return { ...state, ...action.grunnlag};
        case GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_LOADING:
            return { ...state, isLoading: action.isLoading};
        case GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_ERROR:
            return { ...state, error: action.error};
        case GrunnlagVektarsavgiftActionKey.GRUNNLAG_VEKTARSAVGIFT_RESET:
            return { ...state, ...grunnlagVektarsavgiftInitialState };
        default:
            return state;
    }
}
