import type { IError } from '../models/types';

export function errorChainContainsErrorCode(error: IError, errorCode: string): IError {
    let currentErrorCause = error;
    while (currentErrorCause) {
        if (currentErrorCause.errorCode === errorCode) {
            return currentErrorCause;
        }

        currentErrorCause = currentErrorCause.errorResponseCause;
    }

    return null;
}

export function getFirstChainedError(error: IError): IError {
    let currentErrorCause = error;
    while (!!currentErrorCause.errorResponseCause) {
        currentErrorCause = currentErrorCause.errorResponseCause;
    }

    return currentErrorCause;
}