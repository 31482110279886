import * as React from 'react';
import { Component } from 'react';

interface IRoundedProgressBarProps {
    bigText?: string;
    smallText?: string;
    totalCount?: number;
    completedCount?: number;
    percentage?: any;
    size?: AK_ROUNDED_PROGRESS_SIZE;
    extraClassName?: string;
}

export enum AK_ROUNDED_PROGRESS_SIZE {
    SMALL = 60,
    LARGE = 120
}

class RoundedProgressBar extends Component<IRoundedProgressBarProps> {

    private config = {
        strokeWidth: 7,
        size: this.props.size || AK_ROUNDED_PROGRESS_SIZE.LARGE,
        className: '',
        classes: {
            root: 'ak-rounded-progressbar-svg',
            trail: 'ak-rounded-progressbar-trail',
            path: 'ak-rounded-progressbar-path',
            bigText: 'ak-rounded-progressbar-text ak-rounded-progressbar-text-heading',
            smallText: 'ak-rounded-progressbar-text',
            background: 'ak-rounded-progressbar-background'
        },
        background: false,
        backgroundPadding: null,
        initialAnimation: false
    };

    public render(): React.ReactNode {
        const pathDescription = this.getPathDescription();
        const size = this.config.size;
        const OFFSET_Y = 10;

        return (
            <div className={`ak-rounded-progressbar ${this.props.extraClassName || ''}`} style={{height: `${size * 2}px`}}>
                <svg className={this.config.classes.root} viewBox={`0 0 ${size * 2} ${size * 2}`}>
                    <path className={this.config.classes.trail} d={pathDescription} strokeWidth={this.config.strokeWidth} fillOpacity={0} />

                    <path className={this.config.classes.path} d={pathDescription}
                        strokeWidth={this.config.strokeWidth} fillOpacity={0}
                        style={this.getProgressStyle()} />

                    {!!this.props.bigText &&
                        <text className={this.config.classes.bigText} x={size} y={size - OFFSET_Y}>
                            {this.props.bigText}
                        </text>
                    }
                    {!!this.props.completedCount && !!this.props.totalCount &&
                        <text className={this.config.classes.bigText} x={size} y={size - OFFSET_Y}>
                            {`${this.props.completedCount}/${this.props.totalCount}`}
                        </text>
                    }
                    {!!this.props.smallText &&
                        <text className={this.config.classes.smallText} x={size} y={size + OFFSET_Y + 5}>
                            {this.props.smallText}
                        </text>
                    }
                </svg>

            </div>
        );
    }

    private getPathDescription() {
        const radius = this.getPathRadius();
        const size = this.config.size;
        return `M ${size},${size} m 0,-${radius} a ${radius},${radius} 0 1 1 0,${2 * radius} a ${radius},${radius} 0 1 1 0,-${2 * radius}`;
    }

    private getProgressStyle() {
        const fullPercentage = 100;
        const diameter = Math.PI * 2 * this.getPathRadius();
        const percentage = this.props.percentage || ((this.props.completedCount * fullPercentage) / this.props.totalCount);
        const truncatedPercentage = Math.min(Math.max(percentage, 0), fullPercentage);

        return {
            strokeDasharray: `${diameter}px ${diameter}px`,
            strokeDashoffset: `${((fullPercentage - truncatedPercentage) / fullPercentage * diameter)}px`
        };
    }

    private getPathRadius() {
        return this.config.size - (this.config.strokeWidth / 2);
    }
}

export { RoundedProgressBar };
