/* eslint-disable max-lines */
import type { IAkConfirmNavigationProps } from 'svv-tk-akr-common-frontend';
import { AkConfirmNavigation, AkKnapp } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { connect } from 'react-redux';
import type { WithRouterProps} from '../../utils';
import { withRouter } from '../../utils';
import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { RegistreringStatus } from '../../constants';
import { AvsluttSakIndikator, Kanal, KjoringensArt, Saksstatus } from '../../models/kodeverk';
import type { Brukerprofil, IFullforPaTrafikkstasjonGrunnlag, IKjoretoy, IKjoringensArt, IKunde, IRegistreringSak, IVilkarstatus, RootStateType } from '../../models/types';
import {KjoretoyRegler, RegistreringRegler} from '../../regler';
import { fjernRegistreringArbeidsliste } from '../../state/actions';
import {
    alleKjoringensArtSelector, brukerprofilSelector, fullforPaTrafikkstasjonGrunnlagSelector, gjeldendeEierSelector, kjoretoySelector, registreringSakSelector, sakSelector,
    statusForRegistreringSelector,
    vilkarForRegistreringSelector
} from '../../state/selectors';

interface IRegistreringKnapperadProps {
    opprettSak: (avsluttSakIndikator: AvsluttSakIndikator) => Promise<any>;
    sendTilSaksbehandler: () => void;
    erLesemodus: boolean;
    kanRendreKnappForAvbrytRegistrering: boolean;
    oppdater: () => void;
    oppdaterOgRegistrer: () => void;
    openModalForAvbrytRegistrering: () => void;
    lukk: () => void;
    omregistrering: boolean;
    finnesObligatoriskeDokumenterSomKanLastesOpp: boolean;
    kanViseInformasjon: boolean;
}

interface IRegistreringKnapperadStateProps {
    sak: IRegistreringSak;
    status: RegistreringStatus;
    fullforPaTrafikkstasjonGrunnlag?: IFullforPaTrafikkstasjonGrunnlag;
    eier: IKunde;
    vilkarstatus: IVilkarstatus;
    erTilGodkjenning: boolean;
    sakId: string;
    kjoretoy?: IKjoretoy;
    brukerprofil?: Brukerprofil,
    alleKjoringensArt: IKjoringensArt[],
    sendTilSaksbehandlingDisablet: boolean,
    alleObliDokErOppfylt: boolean

}

interface IRegistreringKnapperadDispatchProps {
    fjernRegistreringArbeidsliste: (sakId: string) => Promise<any>;
}

type RegistreringKnapperadProps = IRegistreringKnapperadStateProps
    & IRegistreringKnapperadDispatchProps
    & IRegistreringKnapperadProps
    & IAkConfirmNavigationProps
    & WithRouterProps;

class RegistreringKnapperad extends React.Component<RegistreringKnapperadProps> {

    public render(): React.ReactElement {
        return (
            <>
                <AkKnapp type={`ak-knapp ${this.props.erLesemodus ? 'ak-hovedknapp' : 'ak-tilleggsknapp'}`}
                         action={this.props.lukk} intlKey={`generell.knapp.${!!this.props.sakId ? 'lukk' : 'avbryt'}`}/>
                {this.props.sakId && !this.props.erLesemodus &&
                    <AkKnapp type="ak-knapp ak-tilleggsknapp" action={this.oppdater} intlKey={'generell.knapp.lagre'}/>}
                {!this.props.sakId && this.harEndretKjoringensArt() &&
                    <AkKnapp type="ak-knapp ak-hovedknapp" action={this.opprettSak} intlKey={'generell.knapp.lagre'}/>}
                {this.props.kanRendreKnappForAvbrytRegistrering &&
                    <AkKnapp type="ak-knapp ak-tilleggsknapp" action={this.props.openModalForAvbrytRegistrering}
                             intlKey={'generell.knapp.avbrytRegistrering'} disabled={this.props.erLesemodus}/>}
                {this.props.kanViseInformasjon && this.utledOgRenderKnapperadVariant()}
            </>
        );
    }

    private utledOgRenderKnapperadVariant() {
        switch (this.props.status) {
            case RegistreringStatus.OPPDATER:
                return this.renderStartetBetaling();
            case RegistreringStatus.REGISTRER:
                return this.renderRegistrerKjoretoy();
            case RegistreringStatus.VARSLER:
                return this.renderVarsler();
            default:
                return null;
        }
    }

    // RegistreringStatus.OPPDATER -> utestående avgifter, sakResponse opprettet
    private renderStartetBetaling = () => {
        let oppdaterKnappKey = this.props.omregistrering ? 'registrering.knapp.oppdaterOgOmregistrer' : 'registrering.knapp.oppdaterOgRegistrer';
        let oppdaterAction = this.oppdaterOgRegistrer;

        if (this.props.sak.internSaksbehandlingPakrevet) {
            oppdaterKnappKey = 'registrering.knapp.oppdaterVilkar';
            oppdaterAction = this.oppdater;
        }

        return (
            <>
                {this.renderAvbrytOmregistreringKnapp()}
                <AkKnapp
                    type={'ak-knapp ak-hovedknapp'}
                    icon={'fa-refresh px-2'}
                    action={oppdaterAction}
                    disabled={this.props.erLesemodus || this.props.vilkarstatus.isLoading}
                    intlKey={oppdaterKnappKey} />
            </>
        );
    };

    // RegistreringStatus.REGISTRER -> Alle vilkår oppfylt
    private renderRegistrerKjoretoy = () => {
        if (this.props.fullforPaTrafikkstasjonGrunnlag.erFullforPaTrafikkstasjon) {
            return this.renderFullforPaTrafikkstasjon();
        }

        // Rendrer ikke statusbar for REGISTRER i tilfeller hvor det finnes merknader som hindrer registrering
        // Spesialhåndering for blant annet registreringer med dokumentkrav, eks begravelsesbil
        if (RegistreringRegler.harMerknadSomKreverFullforingPaTrafikkstasjon(this.props.kjoretoy)) {
            return null;
        }

        const omregistreringDisablet =
            this.props.erLesemodus
            || (RegistreringRegler.nyEierErInnloggetForhandlerSinHovedenhet(this.props.brukerprofil, this.props.eier)
            && RegistreringRegler.nyUnderenhetUlikInnloggetAvdeling(this.props.brukerprofil, this.props.sak.underenhet))
        || !_.isEmpty(new KjoretoyRegler(this.props.kjoretoy).utledBruksforbudmeldinger());

        if (this.obliDokMaGodkjennes()) {
            return this.renderKnapperForRegistrering(omregistreringDisablet);
        }

        return (
            <>
                {this.renderKnapperForRegistrering(omregistreringDisablet)}
            </>
        );
    };

    // RegistreringStatus.VARSLER -> Kan ikke starte sakResponse
    private renderVarsler = () => {
        return (
            <>
                {this.props.fullforPaTrafikkstasjonGrunnlag.erFullforPaTrafikkstasjon && this.renderFullforPaTrafikkstasjon()}
                {RegistreringRegler.erDrosjeLoyvenummerUgyldig(this.props.sak) && this.renderAvbrytOmregistreringKnapp()}
                {this.props.finnesObligatoriskeDokumenterSomKanLastesOpp && !this.props.alleObliDokErOppfylt && this.renderSendTilSaksbKnapp()}
            </>
        );
    };

    private obliDokMaGodkjennes = (): boolean => {
        return this.props.finnesObligatoriskeDokumenterSomKanLastesOpp && !this.props.alleObliDokErOppfylt;
    };

    private renderKnapperForRegistrering = (omregistreringDisablet: boolean) => {
        const sakOpprettetAutomatisk = this.erSakOpprettetAutomatisk();
        const kanLasteOppDokumenter = this.props.finnesObligatoriskeDokumenterSomKanLastesOpp;
        const alleObliDokErOppfylt = this.props.alleObliDokErOppfylt;
        return (
            <>
                {(omregistreringDisablet || sakOpprettetAutomatisk ||
                    (kanLasteOppDokumenter && !this.props.erTilGodkjenning)) && this.renderAvbrytOmregistreringKnapp()}
                {(kanLasteOppDokumenter && !alleObliDokErOppfylt) ? this.renderSendTilSaksbKnapp() :
                    this.renderRegistreringsKnapp(omregistreringDisablet)}
            </>
        );
    };

    private messageKeyFragment = () => (this.props.omregistrering ? 'omregistreringMelding' : 'paregistreringMelding');

    private renderRegistreringsKnapp = (omregistreringDisablet: boolean) => {
        return (
            <AkKnapp
                type={'ak-knapp ak-hovedknapp'}
                action={this.registrer}
                intlKey={`registrering.${this.messageKeyFragment()}.registrerKnapp`}
                disabled={omregistreringDisablet}
            />
        );
    };

    private registrer = () => {
        this.props.opprettSak(AvsluttSakIndikator.AVSLUTT_MED_VEDTAK);
    };

    private opprettSak = () => {
        this.props.opprettSak(AvsluttSakIndikator.IKKE_AVSLUTT);
    };

    private renderSendTilSaksbKnapp = () => {
        const sendtTilSaksbehandlerStatus = this.props.erTilGodkjenning;
        const omregistreringDisablet = RegistreringRegler.nyEierErInnloggetForhandlerSinHovedenhet(this.props.brukerprofil, this.props.eier) && RegistreringRegler.nyUnderenhetUlikInnloggetAvdeling(this.props.brukerprofil, this.props.sak.underenhet);
        const sendTilSaksbehandlingDisablet = this.props.sendTilSaksbehandlingDisablet;

        return (
            <AkKnapp type="ak-hovedknapp"
                intlKey={sendtTilSaksbehandlerStatus ? 'generell.knapp.trekkTilbake' : 'generell.knapp.sendTilSaksbehandler'}
                action={this.props.sendTilSaksbehandler}
                disabled={omregistreringDisablet || (!sendtTilSaksbehandlerStatus && sendTilSaksbehandlingDisablet) || this.props.vilkarstatus.isLoading}
            />
        );
    };

    private renderFullforPaTrafikkstasjon = () => {
        return (
            <>
                {this.sakOpprettet() &&
                <AkKnapp
                    type="ak-knapp ak-hovedknapp"
                    icon="fa-refresh px-2"
                    action={this.oppdaterOgFjern}
                    intlKey="registrering.knapp.oppdaterOgLukk"
                    disabled={this.props.erLesemodus || this.props.vilkarstatus.isLoading}
                />
                }
            </>
        );
    };

    private sakOpprettet = () => (this.props.sak && this.props.sak.sakId);

    private renderAvbrytOmregistreringKnapp = () => {
        const avbrytKnappKey = 'registrering.knapp.avbrytOmregistrering';
        return (this.props.sak.sakId || this.props.sakId) && this.props.omregistrering && !this.props.kanRendreKnappForAvbrytRegistrering && (
            <AkKnapp
                type={'ak-knapp ak-tilleggsknapp'}
                action={this.props.openModalForAvbrytRegistrering}
                intlKey={avbrytKnappKey}
                disabled={this.props.erLesemodus || this.props.vilkarstatus.isLoading}
            />
        );
    };

    private oppdaterOgRegistrer = () => {
        this.props.oppdaterOgRegistrer();
    };

    private oppdater = () => {
        this.props.oppdater();
    };

    private oppdaterOgFjern = () => {
        this.props.fjernRegistreringArbeidsliste(this.props.sak.sakId).then(() => {
            if (this.props.sak.saksstatus === Saksstatus.AVSLUTTET) {
                this.props.confirmNavigation(false);
                this.props.navigate('/startside');
            }
        });
    };

    private erSakOpprettetAutomatisk(): boolean {
        return this.props.sak.opprettetIKanal === Kanal.AUTOMATISK;
    }

    private harEndretKjoringensArt() {
        const defaultKjoringensArt = RegistreringRegler.utledDefaultKjoringensArt(this.props.eier, this.props.sak.saksType, this.props.alleKjoringensArt, this.props.kjoretoy);
        return KjoringensArt[this.props.sak.kjoringensArt] !== defaultKjoringensArt;
    }
}

const mapStateToProps = (state: RootStateType): IRegistreringKnapperadStateProps => ({
    sak: registreringSakSelector(state),
    status: statusForRegistreringSelector(state),
    fullforPaTrafikkstasjonGrunnlag: fullforPaTrafikkstasjonGrunnlagSelector(state),
    eier: gjeldendeEierSelector(state),
    vilkarstatus: state.vilkarstatus,
    erTilGodkjenning: state.sak.erTilGodkjenning,
    sakId: sakSelector(state).sakId,
    kjoretoy: kjoretoySelector(state),
    brukerprofil: brukerprofilSelector(state),
    alleKjoringensArt: alleKjoringensArtSelector(state),
    sendTilSaksbehandlingDisablet:  RegistreringRegler.enEllerFlerObliDokErIkkeOpplastet(vilkarForRegistreringSelector(state)),
    alleObliDokErOppfylt: RegistreringRegler.alleObliDokErOppfylt(state)
});

const mapDispatchToProps = (dispatch): IRegistreringKnapperadDispatchProps => ({
    fjernRegistreringArbeidsliste: (sakId: string) => dispatch(fjernRegistreringArbeidsliste(sakId))
});

const RegistreringKnapperadConnected = compose(
    connect<IRegistreringKnapperadStateProps, IRegistreringKnapperadDispatchProps, IRegistreringKnapperadProps>(mapStateToProps, mapDispatchToProps),
    AkConfirmNavigation,
    withRouter
)(RegistreringKnapperad);

export { RegistreringKnapperadConnected, RegistreringKnapperad };
