import * as _ from 'lodash';
import type { Action } from 'redux';
import { combineReducers } from 'redux';

import { ActionKey } from '../../constants';
import type { IKlientKonfigurasjonState } from '../../models/types';
import type { IHovedmenyToggleEkspandertAction } from '../../state/actions';

export function hovedmenyEkspandert(state = '', action: Action | IHovedmenyToggleEkspandertAction): string {
    if (action.type === ActionKey.HOVEDMENY_SEKSJON_EKSPANDERT.valueOf()) {
        return (action as IHovedmenyToggleEkspandertAction).seksjonEkspandert;
    }
    return state;
}

export const globals = combineReducers({
    globalErrors: (state = [], action) => {
        switch (action.type) {
            case 'ADD_ERROR':
                return _.concat(state, [action.error]);
            case ActionKey.RESET_ERROR:
                return [];
            default:
                return state;
        }
    },
    hovedmeny: (state = {expanded: ''}, action) => {
        if (action.type === ActionKey.HOVEDMENY_SEKSJON_EKSPANDERT) {
            return {expanded: action.seksjonEkspandert};
        }

        return state;
    },
    klientKonfigurasjon: (state: IKlientKonfigurasjonState = {linker: {}, isLoading: true}, action) => {
        switch (action.type) {
            case 'KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS':
                return {...state, ...action.konfigurasjon};
            case 'KLIENTKONFIGURASJON_IS_LOADING':
                return {...state, isLoading: action.isLoading};
            default:
                return state;
        }
    }
});
