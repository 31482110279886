import { ArbeidslisteSakstypefilter, SaksType } from '../models/kodeverk';
import type { IArbeidslisteRad } from '../models/types';

export const urlForArbeidslisteRad = (rad: IArbeidslisteRad): string => `/kjoretoy/${rad.kuid}/${urlSegmentForSakstype(rad.saksType)}/${rad.sakId}`;

export const nyEierskifteSakUrl = (rad: IArbeidslisteRad): string => `/kjoretoy/${rad.kuid}/${urlSegmentForSakstype(SaksType.EIERSKIFTE)}`;

export const nyOmregistreringSakUrl = (rad: IArbeidslisteRad): string => `/kjoretoy/${rad.kuid}/${urlSegmentForSakstype(SaksType.REGISTRERING_NY_EIER)}`;

export const forstegangsregSakUrl = (rad: IArbeidslisteRad): string => `/kjoretoy/${rad.kuid}/${urlSegmentForSakstype(SaksType.FORSTEGANGSREGISTRERING)}/${rad.sakId}`;

const urlSegmentForSakstype = (sakstype: SaksType): string => {
    switch (sakstype) {
        case SaksType.FORSTEGANGSREGISTRERING:
            return SaksType.FORSTEGANGSREGISTRERING.toLowerCase();
        case SaksType.EIERSKIFTE:
            return SaksType.EIERSKIFTE.toLowerCase();
        case SaksType.REGISTRERING_NY_EIER:
        case SaksType.REGISTRERING_SAMME_EIER:
            return 'registrering';
        default:
            return '';
    }
};

export const convertSakstypeToSakstypefilter = (sakstype: SaksType): ArbeidslisteSakstypefilter => {
    if (sakstype === SaksType.FORSTEGANGSREGISTRERING) {
        return ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING;
    }

    if (sakstype === SaksType.EIERSKIFTE) {
        return ArbeidslisteSakstypefilter.EIERSKIFTE;
    }

    if (sakstype) {
        return ArbeidslisteSakstypefilter.REGISTRERING;
    }

    return null;
};
