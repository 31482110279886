import * as _ from 'lodash';
import { useSelector } from 'react-redux';

import { RegistreringsstatusKode } from '../../models/kodeverk';
import type { IRootState } from '../../models/types';

interface IFlyttmodaltekst {
    bodytekst1: string;
    visBodytekst2: boolean;
}

export const useFlyttmodaltekst = (): IFlyttmodaltekst => {

    const bodytekst1 = useSelector((state: IRootState) => state.kjoretoyList.kjoretoy.length > 1 ?
                                                          'flyttkjoretoy.modal.flyttFlereKjoretoyBodyTekst1' :
                                                          'flyttkjoretoy.modal.flyttKjoretoyBodyTekst1');
    const visBodytekst2 = useSelector((state: IRootState) => _.some(state.kjoretoyList.kjoretoy, kjoretoy => {
        return kjoretoy.registrering.registreringsinformasjon.registreringsstatus !== RegistreringsstatusKode.REGISTRERT;
    }));

    return {
        bodytekst1,
        visBodytekst2
    };
};
