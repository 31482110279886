import * as _ from 'lodash';
import { SakActionKey, SakerActionKey } from '../../constants';
import type { ISak, ISakListe } from '../../models/types';
import { getFirstChainedError } from '../../utils';
import type { SakActionType, SakerActionType } from '../actions';

const sakInitialState: ISak<any> = {
    sakId: null,
    kuid: null,
    imid: null,
    opprettetDato: null,
    sistEndret: null,
    saksType: null,
    saksstatus: null,
    opprettetIKanal: null,
    avsluttetAvbruttArsak: null,
    vedtakFattet: null,
    internSaksbehandlingPakrevet: false,
    saksinformasjon: {},
    merknader: [],
    isLoading: false,
    error: null,
    feil: null,
    erTilGodkjenning: false,
    isLoadingModalOpen: false
};

const sakListeInitialState: ISakListe = {
    query: null,
    saker: []
};

export function sak(state = sakInitialState, action: SakActionType): ISak<any> {
    switch (action.type) {
        case SakActionKey.SAK_IS_LOADING:
        case SakActionKey.SAK_LOADING_MODAL:
        case SakActionKey.SAK_REQUEST_SUCCESS:
        case SakActionKey.SAK_REQUEST_ERROR:
        case SakActionKey.SAK_RESET:
        case SakActionKey.SAKER_REMOVE_SUCCESS:
        case SakActionKey.SAK_DOCUMENT_ERROR:
            return sakReducer(state, action);
        default:
            return state;
    }
}

export function sakListe(state: ISakListe = sakListeInitialState, action: SakerActionType): ISakListe {
    switch (action.type) {
        case SakerActionKey.SAKER_IS_LOADING:
            return {
                ...state, isLoading: action.isLoading, query: action.query
            };
        case SakerActionKey.SAKER_FETCH_DATA_SUCCESS:
            if (_.isEqual(action.query, state.query)) {
                return {...state, isLoading: false, query: action.query, saker: action.saker.content};
            }
            return state;
        default:
            return state;
    }
}

const sakReducer = (state: ISak<any>, action: SakActionType): ISak<any> => {
    switch (action.type) {
        case SakActionKey.SAK_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case SakActionKey.SAK_LOADING_MODAL:
            return { ...state, isLoadingModalOpen: action.isOpen };
        case SakActionKey.SAK_REQUEST_SUCCESS:
        case SakActionKey.SAKER_REMOVE_SUCCESS:
            return {...sakInitialState, ...action.response};
        case SakActionKey.SAK_REQUEST_ERROR:
            return {...state, error: getFirstChainedError(action.error), isLoading: false, isLoadingModalOpen: false};
        case SakActionKey.SAK_DOCUMENT_ERROR:
            return {...state, error: action.error, isLoading: false};
        case SakActionKey.SAK_RESET:
            return {...sakInitialState};
        default:
            return state;
    }
};
