export enum AkrConfig {
    EIERSKIFTER_RESOURCE_URL = '/eierskifter',
    EIERSKIFTER_GODKJENN_RESOURCE_URL = '/godkjenn',
    EIERSKIFTER_AVVIS_RESOURCE_URL = '/avvis',
    EIERSKIFTER_AVBRYT_RESOURCE_URL = '/avbryt',
    ENDRE_ER_TIL_GODKJENNING_URL = '/er-til-godkjenning',
    OPPSLAG_RESOURCE_URL = '/oppslag',
    KJORETOY_RESOURCE_URL = '/kjoretoy',
    KJORETOY_MIDLERTIDIGVOGNKORT_RESOURCE_URL = '/kjoretoy/<%= kuid %>/midlertidigvognkort',
    KJORETOY_REGISTRERINGSSTATUS_RESOURCE_URL = '/kjoretoy/<%= kuid %>/registreringsstatus',
    BRUKERPROFIL_RESOURCE_URL = '/sikkerhet/brukerprofil',
    BRUKERPROFIL_AVDELING_RESOURCE_URL = '/sikkerhet/brukerprofil/akr-forhandler',
    OVERSETTELSER_RESOURCE_URL = '/oversettelser',
    KUNDE_RESOURCE_URL = '/kunder',
    KUNDE_KJORETOY_RESOURCE_URL = '/bruker/kjoretoy',
    KLIENTKONFIGURASJON_RESOURCE_URL = '/klientkonfigurasjon',
    SYSTEMVARSLER_RESOURCE_URL = '/systemvarsler',
    ARBEIDSLISTE_RESOURCE_URL = '/arbeidslisterader',
    ARBEIDSLISTE_DOKUMENTASJONSSTATUS_RESOURCE_URL = '/arbeidslisterader/dokumentasjonsstatus',
    ORGANISASJONER_RESOURCE_URL = '/organisasjoner',
    FORSIKRING_FJERN_KNYTNING_URL = '/forsikring/<%= kuid %>/fjernKnytning',
    SAKER_RESOURCE_URL = '/saker',
    ARKIVDOKUMENTER_RESOURCE_URL = '/saker/<%= sakId %>/arkivdokumenter',
    ARKIVDOKUMENTER_DOWNLOAD_RESOURCE_URL = 'api/saker/<%= sakId %>/arkivdokumenter/<%= dokumentId %>',
    DOKUMENTASJONSSTATUS_RESOURCE_URL = '/saker/dokumentasjonsstatus',
    AKTOR_URL = '/aktor',
    AKTOR_GODKJENN_URL = '/aktor/godkjenn',
    REGISTRERINGSAKER_RESOURCE_URL = '/registreringsaker',
    FLYTTKJORETOY_RESOURCE_URL = '/flytting',
    KJORINGENS_ART_LISTE_RESOURCE_URL = '/kjoringensart',
    AVGIFTSSTATUS_RESOURCE_URL = '/avgifter/avgiftsstatus',
    SKATTSESJON_RESOURCE_URL = '/avgifter/selvbetjeningssesjon',
    KJENNEMERKE_SERIER_OG_TYPER_RESOURCE_URL = '/kjennemerke/serierogtyper',
    KJENNEMERKESERIE_RESOURCE_URL = '/kjennemerke/serie',
    KONTROLLER_VILKAR_URL = '/vilkar/status',
    VARSEL_URL = '/varsel',
    FEILMELDINGSLOGGER_ERROR = '/feilmeldingslogger',
    GRUNNLAG_VEKTARSAVGIFT_RESOURCE_URL = '/grunnlagvektarsavgift/stotteregister',
    FUNKSJONSBRYTERE_RESOURCE_URL = '/funksjonsbrytere/all',
    FUNKSJONSBRYTER_RESOURCE_URL = '/funksjonsbrytere',
}

export const NY_SAK_VISNINGSTID_MS = 8000;
export const DEFAULT_MELDING_VISNINGSTID_MS = 8000;
export const NY_SAK_BULK_VISNINGSTID_MS = 16000;
export const KJENNEMERKEMELDING_IKKE_PA_LAGER_VISNINGSTID_MS = 16000;
export const SYSTEMVARSLER_STORAGE_KEY = 'KRYSSEDE_MELDINGER';
