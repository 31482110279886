import { AkrConfig } from '../../constants';
import { MeldingType } from '../../models/kodeverk';
import type { IRegistreringInformasjon, ThunkResult } from '../../models/types';
import { createRestResource } from '../store';
import type { MeldingBarActionType} from './melding-bar';
import { nyMelding } from './melding-bar';

const api = createRestResource(AkrConfig.FORSIKRING_FJERN_KNYTNING_URL);

export function fjernForsikringsKnytningMellomEierOgKjoretoy(kuid: string, saksinformasjon: IRegistreringInformasjon): ThunkResult<Promise<void>, MeldingBarActionType> {
    return dispatch => {
        const eierKundeId = saksinformasjon.nyEier.kundeId;
        const medeierKundeId = !!saksinformasjon.nyMedeier ? saksinformasjon.nyMedeier.kundeId : '';
        const requestParams: any = {eierKundeId, medeierKundeId};
        return api.get({kuid}, requestParams)
            .then(response => {
                if (response.errorId && response.errorId !== 'ingen.id') {
                    dispatch(nyMelding({meldingIntlId: 'registrering.validering.fjernKnytningFeilet', meldingType: MeldingType.DANGER}));
                }
            });
    };
}
