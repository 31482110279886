/* eslint-disable max-lines */
import { AkKnapp, AkMeldingboks, AkMeldingboksType, VilkarStatus } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import styled from 'styled-components';

import { RegistreringStatus } from '../../constants';
import { AvsluttSakIndikator, VilkarType } from '../../models/kodeverk';
import type { Brukerprofil, IFullforPaTrafikkstasjonGrunnlag, IKjoretoy, IKunde, IMerknad, IRegistreringSak, IVilkar, IVilkarstatus, RootStateType } from '../../models/types';
import { RegistreringRegler } from '../../regler';
import { gaTilBetalingSkatt } from '../../state/actions';
import {
    brukerprofilSelector,
    erKravOmVektarsavgiftSelector, erNyEierDodSelector, erNyEierKonkursSelector, erNyEierOpplostTvangsavvikletSlettetSelector, fullforPaTrafikkstasjonGrunnlagSelector, gjeldendeEierSelector,
    kanHenteMidlertidigVognkortSelector, kjoretoySelector, merknaderForRegistreringSelector, registreringSakSelector, statusForRegistreringSelector, tillattTotalvektSelector,
    vilkarForRegistreringSelector
} from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';
import * as _ from 'lodash';

interface IRegistreringStatusMeldingerProps {
    opprettSak: (avsluttSakIndikator?: AvsluttSakIndikator) => Promise<any>;
    hentMidlertidigVognkort: () => void;
    erLesemodus: boolean;
    omregistrering: boolean;
    submitted?: boolean;
}

interface IRegistreringStatusMeldingerState {
    sakIkkeFullfort: boolean;
}

interface IRegistreringStatusMeldingerStateProps {
    kjoretoy: IKjoretoy;
    brukerprofil: Brukerprofil;
    sak: IRegistreringSak;
    status: RegistreringStatus;
    fullforPaTrafikkstasjonGrunnlag?: IFullforPaTrafikkstasjonGrunnlag;
    varselsmerknader: IMerknad[];
    tillattTotalvekt: number;
    eier: IKunde;
    erNyEierOpplostTvangsavvikletSlettet: boolean;
    erNyEierKonkurs: boolean;
    erNyEierDod: boolean;
    erKravOmVektarsavgift: boolean;
    kanHenteMidlertidigVognkort: boolean;
    vilkarstatus: IVilkarstatus;
    vilkar: IVilkar[];
    erTilGodkjenning: boolean;
    sakId: string;
}

interface IRegistreringStatusMeldingerDispatchProps {
    gaTilBetalingSkatt: (betalingsreferanse: string) => Promise<any>;
}

type RegistreringStatusMeldingerProps = IRegistreringStatusMeldingerStateProps
    & IRegistreringStatusMeldingerDispatchProps
    & IRegistreringStatusMeldingerProps
    & WithRouterProps

class RegistreringStatusMeldinger extends React.Component<RegistreringStatusMeldingerProps, IRegistreringStatusMeldingerState> {

    public state = {
        sakIkkeFullfort: false
    };

    private buttonWrapperClassNames = 'col justify-content-end row mr-2 align-items-center ak-knapperad mb-0';

    public render(): React.ReactElement {
        if (this.props.erNyEierOpplostTvangsavvikletSlettet) {
            return this.renderNegativStatus();
        } else if (this.props.erNyEierKonkurs) {
            return this.renderKonkursStatus();
        } else if (this.props.erNyEierDod) {
            return this.renderDodStatus();
        } else if (this.props.vilkarstatus.isLoading) {
            return null;
        }

        switch (this.props.status) {
            case RegistreringStatus.GA_TIL_BETALING:
                return this.renderGaTilBetaling();
            case RegistreringStatus.OPPDATER:
                return this.renderStartetBetaling();
            case RegistreringStatus.REGISTRER:
                return this.renderRegistrerKjoretoy();
            case RegistreringStatus.REGISTRERT:
                return this.renderSakAvsluttet();
            case RegistreringStatus.VARSLER:
                return this.renderVarsler();
            default:
                return null;
        }
    }

    private messageKeyFragment = () => (this.props.omregistrering ? 'omregistreringMelding' : 'paregistreringMelding');

    // RegistreringStatus.GA_TIL_BETALING -> utestående avgifter, sakResponse ikke opprettet
    private renderGaTilBetaling = () => {
        return (
            <AkMeldingboks>
                <div className="row">
                    <div className="col-9">
                        <p className="ak-melding-tekst">
                            <FormattedMessage id={'registrering.melding.tilBetalingLinje1'} />
                        </p>
                        <p className="ak-melding-tekst">
                            <FormattedMessage id={'registrering.melding.tilBetalingLinje2'} />
                        </p>
                        {this.renderFullforPaTrafikkstasjonMeldinger()}
                    </div>
                    <div className={this.buttonWrapperClassNames}>
                        <AkKnapp
                            type={'ak-knapp ak-hovedknapp'}
                            action={this.opprettSak}
                            intlKey={'registrering.melding.tilSkatteetatenKnapp'}
                            disabled={this.props.erLesemodus}
                        />
                    </div>
                </div>
            </AkMeldingboks>
        );
    };

    // RegistreringStatus.OPPDATER -> utestående avgifter, sakResponse opprettet
    private renderStartetBetaling = () => {
        return (
            <AkMeldingboks>
                <div className="row align-items-center">
                    <div className="col-6">
                        <p className="ak-melding-tekst">
                            <FormattedMessage id={'registrering.melding.initiertBetalingLinje1'} />
                        </p>
                        {this.renderFullforPaTrafikkstasjonMeldinger()}
                    </div>
                    <div className={this.buttonWrapperClassNames}>
                        <AkKnapp
                            type={'ak-hovedknapp'}
                            action={this.gaTilSkatt}
                            intlKey={'registrering.melding.tilSkatteetatenKnapp'}
                            disabled={this.props.erLesemodus}
                        />
                    </div>
                </div>
            </AkMeldingboks>
        );
    };

    private gaTilSkatt = () => {
        const avgiftVilkar: IVilkar = _.find(this.props.vilkarstatus.vilkar, { navn: VilkarType.AVGIFT });
        this.props.gaTilBetalingSkatt(avgiftVilkar.tilleggsinformasjon as string);
    };

    // RegistreringStatus.REGISTRER -> Alle vilkår oppfylt eller send til saksb.
    private renderRegistrerKjoretoy = () => {
        if (this.props.fullforPaTrafikkstasjonGrunnlag.erFullforPaTrafikkstasjon) {
            return this.renderFullforPaTrafikkstasjon();
        }

        // Rendrer ikke statusbar for REGISTRER i tilfeller hvor det finnes merknader som hindrer registrering
        // Spesialhåndering for blant annet registreringer med dokumentkrav, eks begravelsesbil
        if (RegistreringRegler.harMerknadSomKreverFullforingPaTrafikkstasjon(this.props.kjoretoy) || !this.isVilkarOppfylt()) {
            return RegistreringRegler.harMerknadForKjennemerkerBestilt(this.props.kjoretoy) && this.renderMeldingForErtstatningskjennemerkerBestilt();
        }

        const omregistreringDisablet = RegistreringRegler.nyEierErInnloggetForhandlerSinHovedenhet(this.props.brukerprofil, this.props.eier) && RegistreringRegler.nyUnderenhetUlikInnloggetAvdeling(this.props.brukerprofil, this.props.sak.underenhet);

        return (
            <>
                {this.renderVarselsmeldinger(RegistreringRegler.finnKritiskeSaksmerknader(this.props.sak?.merknader, this.props.sak?.kjoringensArt))}
                <AkMeldingboks meldingType={AkMeldingboksType.INFO}>
                    <div className="row align-items-center">
                        <div className="col-9">
                            {this.renderOversteLinjeForRegistrer()}
                            {this.props.omregistrering && this.renderMeldingForOmregistrering(omregistreringDisablet)}
                        </div>
                    </div>
                </AkMeldingboks>
            </>
        );
    };

    // RegistreringStatus.REGISTRERT -> Sak avsluttet
    private renderSakAvsluttet = () => {
        const hentMidlertidigVognkortKnapp = this.props.kanHenteMidlertidigVognkort && (
            <AkKnapp type={'ak-knapp ak-hovedknapp'} action={this.hentMidlertidigVognkort} intlKey={'kjoretoydetaljer.knapp.midlertidigVognkort'} />
        );

        return (
            <AkMeldingboks meldingType={AkMeldingboksType.INFO}>
                <div className="row">
                    {
                        this.props.omregistrering
                            ?
                            <div className="col-9">
                                <h3 className="ak-melding-overskrift">
                                    <FormattedMessage id={'registrering.melding.omregistrertTittel'} />
                                </h3>
                                <p className="ak-melding-tekst">
                                    <FormattedMessage id={'registrering.melding.omregistrertLinje1'} />
                                </p>
                            </div>
                            :
                            <div className="col-9">
                                <h3 className="ak-melding-overskrift">
                                    <FormattedMessage id={'registrering.melding.paregistrertTittel'} />
                                </h3>
                                <p className="ak-melding-tekst">
                                    <FormattedMessage id={'registrering.melding.paregistrertLinje1'} />
                                </p>
                            </div>
                    }
                    <div className={this.buttonWrapperClassNames}>
                        {hentMidlertidigVognkortKnapp}
                    </div>
                </div>
            </AkMeldingboks>
        );
    };

    // RegistreringStatus.VARSLER -> Kan ikke starte sakResponse
    private renderVarsler = () => {
        return (
            <>
                {this.props.fullforPaTrafikkstasjonGrunnlag.erFullforPaTrafikkstasjon && this.renderFullforPaTrafikkstasjon()}
                {this.renderVarselsmeldinger(this.props.varselsmerknader)}
                {!this.props.submitted && RegistreringRegler.erDrosjeLoyvenummerUgyldig(this.props.sak) && this.renderSkjemaFeilmelding()}
            </>
        );
    };

    private renderOversteLinjeForRegistrer = () => {
        if (this.props.erKravOmVektarsavgift) {
            return (
                <p className="ak-melding-tekst">
                    <FormattedMessage id="registrering.melding.registrerKjoretoyLinjeTotalvekt" values={{ totalvekt: this.props.tillattTotalvekt }} />
                </p>
            );
        } else {
            return (
                <h3 className="ak-melding-overskrift mb-0">
                    <FormattedMessage id={`registrering.${this.messageKeyFragment()}.vilkarOppfylt`} />
                </h3>
            );
        }
    };

    private renderMeldingForOmregistrering = (omregistreringDisablet: boolean) => {
        const statusTekst = `registrering.omregistreringMelding.${omregistreringDisablet ? 'registreringMaAvbrytes' : 'registrerKjoretoyLinje1'}`;
        return (
            <p className="ak-melding-tekst">
                <FormattedMessage id={statusTekst}/>
            </p>
        );
    };

    private renderMeldingForErtstatningskjennemerkerBestilt = () => {
        return (
            <div className="mt-3 col-12">
                <AkMeldingboks meldingType={AkMeldingboksType.INFO}>
                    <div className="row align-items-center">
                        <div className="col-10">
                            <h2><FormattedMessage id="registrering.overskrift.registreringKanIkkeGjennomfores" /></h2>
                            <p className="ak-melding-tekst">
                                <FormattedMessage id="registrering.melding.ERSTATNINGSKJENNEMERKE_ALLEREDE_I_BESTILLING"/>
                            </p>
                        </div>
                    </div>
                </AkMeldingboks>
            </div>
        );
    };

    private renderFullforPaTrafikkstasjon = () => {
        const sakIkkeFullfortTekst = this.state.sakIkkeFullfort && (
            <p className="ak-input-error-text mb-0">
                <FormattedMessage id="registrering.melding.sakIkkeFullfort" tagName="small" />
            </p>
        );

        const innlevertMeldinger = this.props.fullforPaTrafikkstasjonGrunnlag.meldinger;

        return (
            <>
                <AkMeldingboks meldingType={AkMeldingboksType.INFO}>
                    <div className="row align-items-center">
                        <div className="col-10">
                            <h2><FormattedMessage id="registrering.overskrift.fullfor_pa_trafikkstasjon" /></h2>
                            {this.props.fullforPaTrafikkstasjonGrunnlag.merknader.map((merknad, indeks) => (
                                <p key={`melding-${merknad.kode}-${indeks}`} className="ak-melding-tekst">
                                    <FormattedMessage id={`registrering.melding.${merknad.kode}`} />
                                </p>
                            ))}
                            {innlevertMeldinger.map((melding, indeks) => (
                                <p key={`melding-${melding.id}-${indeks}`} className={'ak-melding-tekst}'}>
                                    <FormattedMessage id={melding.id} values={melding.values} />
                                </p>
                            ))}
                            {this.sakOpprettet() &&
                                <p className="ak-melding-tekst">
                                    <FormattedMessage id="registrering.melding.fjerneVedTrykk" />
                                </p>
                            }
                        </div>
                        {this.sakOpprettet() &&
                        <div className={this.buttonWrapperClassNames}>
                            {sakIkkeFullfortTekst}
                        </div>
                        }
                    </div>
                </AkMeldingboks>
                {RegistreringRegler.harMerknadForKjennemerkerBestilt(this.props.kjoretoy) && this.renderMeldingForErtstatningskjennemerkerBestilt()}
            </>
        );
    };

    private renderVarselsmeldinger = (merknader: IMerknad[]) => (
        <>
            {!!merknader?.length &&
                <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mt-3 mb-3">
                    <div className="row">
                        <div className="col-9">
                            <p className="ak-melding-overskrift">
                                <FormattedMessage id={`registrering.${this.messageKeyFragment()}.kanIkkeRegistrereTittel`} />
                            </p>
                            <BulletMeldingListe className="ak-melding-liste">
                                {merknader.map((merknad, index) => (
                                    <li key={`melding-${index}`}>
                                        <FormattedMessage id={`registrering.melding.${merknad.kode}`} />
                                    </li>
                                ))}
                            </BulletMeldingListe>
                        </div>
                    </div>
                </AkMeldingboks>
            }
        </>
    );

    private renderSkjemaFeilmelding = () => (
        <div className="col-12">
            <AkMeldingboks meldingType={AkMeldingboksType.ERROR} customClassName="mt-3" messageKey={'registrering.validering.skjemafeil'} />
        </div>
    );

    private sakOpprettet = () => (this.props.sak && this.props.sak.sakId);

    private renderFullforPaTrafikkstasjonMeldinger = () => (
        <>
            {this.props.fullforPaTrafikkstasjonGrunnlag.merknader.map((merknad, indeks) => (
                <p key={`melding-${merknad.kode}-${indeks}`} className="ak-melding-tekst">
                    <FormattedMessage id={`registrering.melding.${merknad.kode}`} />
                </p>
            ))}
            {this.props.fullforPaTrafikkstasjonGrunnlag.meldinger.map((melding, indeks) => (
                <p key={`melding-${melding.id}-${indeks}`} className="ak-melding-tekst">
                    <FormattedMessage id={melding.id} values={melding.values} />
                </p>
            ))}
        </>
    );

    private renderNegativStatus = () => {
        return (
            <AkMeldingboks meldingType={AkMeldingboksType.ERROR}>
                <FormattedMessage id={'registrering.melding.NY_EIER_STATUS_SLETTET'} />
            </AkMeldingboks>
        );
    };

    private renderKonkursStatus = () => {
        return (
            <AkMeldingboks meldingType={AkMeldingboksType.ERROR}>
                <FormattedMessage id={'registrering.melding.EIER_STATUS_KONKURS'} />
            </AkMeldingboks>
        );
    };

    private renderDodStatus = () => {
        return (
            <AkMeldingboks meldingType={AkMeldingboksType.ERROR}>
                <FormattedMessage id={'registrering.melding.KJOPER_MELDT_DOD'} />
            </AkMeldingboks>
        );
    };

    private opprettSak = () => {
        this.props.opprettSak(AvsluttSakIndikator.IKKE_AVSLUTT);
    };

    private hentMidlertidigVognkort = () => {
        this.props.hentMidlertidigVognkort();
    };

    private isVilkarOppfylt = () => _.every(this.props.vilkar, vilkar => vilkar.status === VilkarStatus.OPPFYLT);
}

const duplicatedClassSelectorForHigherSpecificity = '&.ak-melding-liste&.ak-melding-liste';

const BulletMeldingListe = styled.ul`
    .ak-meldingboks ${duplicatedClassSelectorForHigherSpecificity} li {
        list-style-type: disc;
    }
`;

const mapStateToProps = (state: RootStateType): IRegistreringStatusMeldingerStateProps => ({
    kjoretoy: kjoretoySelector(state),
    brukerprofil: brukerprofilSelector(state),
    sak: registreringSakSelector(state),
    status: statusForRegistreringSelector(state),
    fullforPaTrafikkstasjonGrunnlag: fullforPaTrafikkstasjonGrunnlagSelector(state),
    varselsmerknader: merknaderForRegistreringSelector(state),
    tillattTotalvekt: tillattTotalvektSelector(state),
    erNyEierOpplostTvangsavvikletSlettet: erNyEierOpplostTvangsavvikletSlettetSelector(state),
    eier: gjeldendeEierSelector(state),
    erNyEierKonkurs: erNyEierKonkursSelector(state),
    erNyEierDod: erNyEierDodSelector(state),
    erKravOmVektarsavgift: erKravOmVektarsavgiftSelector(state),
    vilkarstatus: state.vilkarstatus,
    vilkar: vilkarForRegistreringSelector(state),
    kanHenteMidlertidigVognkort: kanHenteMidlertidigVognkortSelector(state),
    erTilGodkjenning: state.sak.erTilGodkjenning,
    sakId: state.sak.sakId
});

const mapDispatchToProps = (dispatch): IRegistreringStatusMeldingerDispatchProps => ({
    gaTilBetalingSkatt: (betalingsreferanse: string) => dispatch(gaTilBetalingSkatt(betalingsreferanse, false))
});

const RegistreringStatusMeldingerConnected = compose(
    connect<IRegistreringStatusMeldingerStateProps, IRegistreringStatusMeldingerDispatchProps, IRegistreringStatusMeldingerProps>(mapStateToProps, mapDispatchToProps),
    withRouter
)(RegistreringStatusMeldinger);

export { RegistreringStatusMeldingerConnected, RegistreringStatusMeldinger };
