import * as _ from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AkLoading, Panel } from 'svv-tk-akr-common-frontend';

import { SelectInputConnected } from '../../components';
import { KodeType } from '../../models/kodeverk';
import type { Brukerprofil, IForhandler, IForhandlereResponse, IKjoretoy, IKunde, IKunder, IRegistrering, RootStateType } from '../../models/types';
import { getForhandlereMedSammeHovedenhet } from '../../state/actions';
import { ORG_NUMMER_LENGTH } from '../../utils';

interface IAvdelingDispatchProps {
    getForhandlerMedSammeHovedenhet: () => Promise<any>;
}

interface IAvdelingPanelProps {
    oppdaterAktor: (aktor: IForhandler) => void;
    oppdaterFraAktor: (aktor: IForhandler) => void;
    touched: boolean;
    errorListe: any[];
}

interface IAvdelingStateProps {
    brukerprofil: Brukerprofil;
    kunder?: IKunder;
    forhandlere: IForhandlereResponse;
    kjoretoy?: IKjoretoy[];
}

interface IAvdelingState {
    avdelinger: KodeType[];
    fraAktor: IForhandler;
    placeholderKey: string;
}

class FlyttingAvdelingerPanel extends Component<IAvdelingPanelProps & IAvdelingStateProps & IAvdelingDispatchProps, IAvdelingState> {
    public state: IAvdelingState = {
        avdelinger: [],
        fraAktor: {
            hovedenhetOrgNummer: '',
            underenhetOrgNummer: ''
        } as IForhandler,
        placeholderKey: 'flyttkjoretoy.generelt.velgAvdeling'
    };

    public componentDidMount() {
        if (this.props.kjoretoy.length && !this.state.fraAktor.hovedenhetOrgNummer) {
            this.finnAvdelingerMedSammeHovedenhet(this.props.kjoretoy[0].registrering);
        }
    }

    public componentDidUpdate(prevProps: Readonly<IAvdelingPanelProps & IAvdelingStateProps & IAvdelingDispatchProps>) {
        if (!prevProps.kjoretoy.length && this.props.kjoretoy.length) {
            this.finnAvdelingerMedSammeHovedenhet(this.props.kjoretoy[0].registrering);
        }
    }

    public render() {
        return (
            <Panel overskrift={'flyttkjoretoy.overskrift.avdelinger'} panelCssClass="col-12 ak-panel-transparent ak-med-tabell">
                <div className="row flex-lg-nowrap no-gutters">
                    <div className="row ak-indre-panel no-gutters mb-3 col-12 col-lg">
                        <div className="col-1 text-center">
                            <i className={'ak-kunde-type-ikon fa fa-building'} aria-hidden="true" />
                        </div>
                        <div className="col-11 row no-gutters">
                            <FormattedMessage tagName="h2" id={'flyttkjoretoy.overskrift.fraAvdeling'} />
                            {!!this.props.kjoretoy.length && (
                                <p className="col-12">
                                    {this.props.kjoretoy[0].registrering.underenhet ? this.props.kjoretoy[0].registrering.underenhet.navn : this.props.kjoretoy[0].registrering.eier.navn}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="ak-panel-separator col-lg-1 col-12 row justify-content-center no-gutters">
                        <i className="fa fa-chevron-right d-none d-lg-flex align-self-center" />
                        <i className="fa fa-chevron-down d-flex d-lg-none" />
                    </div>

                    <div className="row ak-indre-panel no-gutters mb-3 col-12 col-lg">
                        <div className="col-1 text-center">
                            <i className={'ak-kunde-type-ikon fa fa-building'} aria-hidden="true" />
                        </div>
                        <div className="col-11 row no-gutters">
                            <FormattedMessage tagName="h2" id={'flyttkjoretoy.overskrift.tilAvdeling'} />

                            <div className="col-12 pt-3">
                                {this.props.forhandlere.isLoading ? (
                                    <AkLoading />
                                ) : (
                                    <SelectInputConnected
                                        options={this.state.avdelinger}
                                        errors={this.props.errorListe}
                                        touched={this.props.touched}
                                        updateSelectedOption={this.oppdaterValgtAvdeling}
                                        placeholderKey={this.state.avdelinger.length !== 1 && this.state.placeholderKey}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        );
    }

    private oppdaterValgtAvdeling = (avdeling: string) => {
        this.props.oppdaterAktor(_.find(this.props.forhandlere.forhandlere, { navn: avdeling }));
    };

    private finnAvdelingerMedSammeHovedenhet(registrering: IRegistrering) {
        const avdelingId = this.props.brukerprofil.valgtEnhet.avdelingId;
        let avdelinger = [];

        const underenhetOrgnr = (registrering.underenhet || ({} as IKunde)).organisasjonsnummer;
        this.props.getForhandlerMedSammeHovedenhet().then(() => {
            /* eslint @typescript-eslint/prefer-string-starts-ends-with: "warn" */
            if (avdelingId.slice(ORG_NUMMER_LENGTH) !== '000000000') {
                if (!underenhetOrgnr || underenhetOrgnr === this.props.brukerprofil.valgtEnhet.hovedenhetOrgNummer) {
                    avdelinger.push(this.props.brukerprofil.valgtEnhet);
                    this.oppdaterValgtAvdeling(this.props.brukerprofil.valgtEnhet.navn);
                } else {
                    avdelinger = _.filter(this.props.forhandlere.forhandlere, (forhandler: IForhandler) => {
                        return forhandler.avdelingId !== this.props.brukerprofil.valgtEnhet.avdelingId;
                    });
                }
            } else {
                avdelinger = _.filter(this.props.forhandlere.forhandlere, (forhandler: IForhandler) => {
                    if (!underenhetOrgnr) {
                        return forhandler.avdelingId.slice(ORG_NUMMER_LENGTH) !== '000000000';
                    } else {
                        return forhandler.avdelingId.slice(ORG_NUMMER_LENGTH) !== underenhetOrgnr;
                    }
                });
            }

            if (avdelinger.length === 1) {
                this.oppdaterValgtAvdeling(avdelinger[0].navn);
            }

            const fraForhandler = this.getFraForhandler(underenhetOrgnr);
            this.setState(
                {
                    fraAktor: fraForhandler,
                    avdelinger: _.chain(avdelinger)
                        .map((avdeling) => {
                            return new KodeType({ kode: avdeling.organisasjonsnummer, beskrivelse: avdeling.navn });
                        })
                        .orderBy(['beskrivelse'])
                        .value()
                },
                () => this.props.oppdaterFraAktor(fraForhandler)
            );
        });
    }

    private getFraForhandler(underenhetOrgnr: string) {
        if (!this.props.kjoretoy.length) {
            return;
        }

        return _.find(this.props.forhandlere.forhandlere, (forhandler: IForhandler) => {
            if (!underenhetOrgnr || underenhetOrgnr === this.props.brukerprofil.valgtEnhet.hovedenhetOrgNummer) {
                return forhandler.underenhetOrgNummer === '000000000';
            } else {
                return forhandler.underenhetOrgNummer === underenhetOrgnr;
            }
        });
    }
}

const mapStateToProps = (state: RootStateType): IAvdelingStateProps => ({
    forhandlere: state.forhandlere,
    brukerprofil: state.brukerprofil,
    kjoretoy: state.kjoretoyList.kjoretoy
});

const mapDispatchToProps = (dispatch): IAvdelingDispatchProps => ({
    getForhandlerMedSammeHovedenhet: () => dispatch(getForhandlereMedSammeHovedenhet())
});

const FlyttingAvdelingerPanelConnected = connect(mapStateToProps, mapDispatchToProps)(FlyttingAvdelingerPanel);

export { FlyttingAvdelingerPanelConnected };
