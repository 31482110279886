import * as _ from 'lodash';

import { AvgiftsstatusActionKey, RegistreringArkivdokumentActionKey, RegistreringerActionKey } from '../../constants';
import type { IAvgiftsstatusResultat, IRegistreringSak } from '../../models/types';
import type { AvgiftsstatusActionType, RegistreringArkivDokActionType, RegistreringerActionType } from '../actions';

const avgiftsstatusInitialState: IAvgiftsstatusResultat = {
    error: null,
    isLoading: false,
    notFound: false,
    harUtestaendeAvgifter: null,
    urlTilAvgiftsportalen: null
};

const registreringSakInitialState: IRegistreringSak = {
    imid: null,
    kuid: null,
    saksadresseId: null,
    leasingtaker: null,
    underenhet: null,
    kjoringensArt: null,
    neringskode: null,
    avgiftsstatus: avgiftsstatusInitialState,
    arkivdokumenter: [],
    avsluttSakIndikator: undefined
};

export function registreringSak(state: IRegistreringSak = registreringSakInitialState, action: RegistreringerActionType | RegistreringArkivDokActionType): IRegistreringSak {
    switch (action.type) {
        case RegistreringerActionKey.REGISTRERINGER_CREATE_SUCCESS:
        case RegistreringerActionKey.REGISTRERINGER_GET_SUCCESS:
        case RegistreringerActionKey.REGISTRERINGER_UPDATE_SUCCESS:
        case RegistreringerActionKey.REGISTRERINGER_POPULER_NY_SAK:
            return { ...registreringSakInitialState, ...action.registreringSak };
        case RegistreringerActionKey.REGISTRERINGER_CLEAR_STATE:
            return { ...registreringSakInitialState };
        case RegistreringerActionKey.REGISTRERINGER_OPPDATER_SKJEMA:
            return { ...state, ...action.registreringSak };
        case RegistreringerActionKey.REGISTRERINGER_OPPDATER_TILHENGERPANEL:
            return { ...state, ...action.registreringSak };
        case AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_START:
        case AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_SUCCESS:
        case AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_ERROR:
            return avgiftsstatus(state, action);
        case RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_ADD:
        case RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_EDIT:
        case RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_DELETE:
        case RegistreringArkivdokumentActionKey.REGISTRERING_DOK_STATUS:
        case RegistreringArkivdokumentActionKey.REGISTRERING_DOK_STATUS_HAS_ERROR:
        case RegistreringArkivdokumentActionKey.OTHER_ACTION:
            return registreringArkivdokumentReducer(state, action);
        default:
            return state;
    }
}

function avgiftsstatus(state: IRegistreringSak, action: AvgiftsstatusActionType): IRegistreringSak {
    switch (action.type) {
        case AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_START:
            return { ...state, avgiftsstatus: { ...avgiftsstatusInitialState, isLoading: true } };
        case AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_SUCCESS:
            return { ...state, avgiftsstatus: { ...avgiftsstatusInitialState, ...action.avgiftsstatus } };
        case AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_ERROR:
            return { ...state, avgiftsstatus: { ...avgiftsstatusInitialState, error: action.error } };
        default:
            return state;
    }
}

function registreringArkivdokumentReducer(state: IRegistreringSak, action: RegistreringArkivDokActionType) {
    switch (action.type) {
        case RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_ADD:
            return {...state, arkivdokumenter: [...state.arkivdokumenter, action.arkivdokument], dokumentasjonsstatusFeilmelding: {}};
        case RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_EDIT:
            const otherDocuments = _.reject(state.arkivdokumenter, {id: action.arkivdokument.id});
            return {...state, arkivdokumenter: [...otherDocuments, action.arkivdokument], dokumentasjonsstatusFeilmelding: {}};
        case RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_DELETE:
            const filteredList = _.reject(state.arkivdokumenter, {id: action.id});
            return {...state, arkivdokumenter: filteredList, dokumentasjonsstatusFeilmelding: {}};
        case RegistreringArkivdokumentActionKey.REGISTRERING_DOK_STATUS:
            return {...state, obligatoriskeDokumenter: action.obligatoriskeDokumenter, dokumentasjonsstatusIsLoading: action.isLoading, dokumentasjonsstatusFeilmelding: {}};
        case RegistreringArkivdokumentActionKey.REGISTRERING_DOK_STATUS_HAS_ERROR:
            return {...state, dokumentasjonsstatusIsLoading: false, dokumentasjonsstatusFeilmelding: action.error};
        case RegistreringArkivdokumentActionKey.OTHER_ACTION:
        default:
            return state;
    }
}
