import type { IAktorPanelAktor, GodkjenningUtfortType } from 'svv-tk-akr-common-frontend';
import { AkSelectInput, AktorGodkjenningstatus } from 'svv-tk-akr-common-frontend';

import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';


interface IAktorSignaturProps {
    godkjenningUtfortTyper: GodkjenningUtfortType[];
    aktor?: IAktorPanelAktor;
    handleChange?: (aktor: IAktorPanelAktor) => void;
    submitted?: boolean;
    inputClassName?: string;
    erSendtTilGodkjenning? :boolean;
}

const AktorSignatur: React.FC<IAktorSignaturProps> = props => {

    const handleChange = (signeringstype: GodkjenningUtfortType) => {
        props.handleChange({
            ...props.aktor,
            godkjenningUtfort: signeringstype,
            signaturKundeId: null
        });
    };

    const erAvvistISelvbetjening = () => !props.aktor.godkjenningUtfort && props.aktor.godkjenningsstatus === AktorGodkjenningstatus.AVVIST;

    const disabledHvisGodkjentEllerSendtTilGodkjenning = (props.aktor.godkjenningsstatus === AktorGodkjenningstatus.GODKJENT) || props.erSendtTilGodkjenning;
    return (
        <>
            <dt className="align-self-center"><FormattedMessage id="generell.label.signatur" tagName="b" /></dt>
            <dd className={classNames(props.inputClassName, {'align-self-center': erAvvistISelvbetjening()})}>
                {erAvvistISelvbetjening() ? <FormattedMessage id="eierskifte.label.avvist" /> :
                 <AkSelectInput
                     autoFocus={true}
                     componentClassNames="px-0 mx-0"
                     intialSelectedValue={props.aktor.godkjenningUtfort}
                     options={mapGodkjenningUtfortTyperToSelectOptions(props.godkjenningUtfortTyper)}
                     handleSelectChange={handleChange}
                     errors={utledErrors(props)}
                     disabled={disabledHvisGodkjentEllerSendtTilGodkjenning}
                 />
                }
            </dd>
        </>
    );

};

const mapGodkjenningUtfortTyperToSelectOptions = (godkjenningUtfortTyper: GodkjenningUtfortType[]) => {
    return godkjenningUtfortTyper.map(type => ({
        value: type,
        descriptionId: `kodeverk.godkjenningUtfortType.${type}`
    }));
};

const utledErrors = (props) => {
    if (props.submitted && props.aktor.kanEndreAktor && !props.aktor.godkjenningUtfort) {
        return ['forstegangsregistrering.valider.manglerSignaturtype'];
    }
};

export { AktorSignatur };
