import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { AkDroparea, AkVilkarPanel, type IKunderMap, type IMessageDescriptor, Panel } from 'svv-tk-akr-common-frontend';
import type { IArkivdokument, IObligatoriskDokument } from '../../models/types';
import DokumentModal from './dokumenter-panel/dokument-modal';
import DokumentTabell from './dokumenter-panel/dokument-tabell';
import { getIntlKeyValueForDokument, utledVilkarStatus } from './dokumenter-panel/dokumenter-panel-util';

interface DokumenterPanelProps {
    lastOppFilError?: (error: IMessageDescriptor) => Promise<any>;
    getSakId: () => Promise<string>;
    slettArkivdokument?: (sakId: string, dokumentId: number) => Promise<any>;
    endreArkivdokument?: (sakId: string, dokument: IArkivdokument) => Promise<any>;
    lastOppFil?: (sakId: string, file: IArkivdokument) => Promise<any>;
    obligatoriskeDokumenter?: IObligatoriskDokument[];
    kunder: IKunderMap;
    lesemodus: boolean;
    arkivdokumenter: IArkivdokument[];
}

const innholdCssClass = classNames(
    'row',
    'no-gutters',
    'flex-column flex-sm-row'
);

const panelCss = classNames(
    'px-0',
    'mt-3 col-12'
);

const ERRORMESSAGE_MAXSIZE = 'generell.filer.validering.maxSize';
const ERRORMESSAGE_FEIL = 'generell.filer.validering.feil';
const ERRORMESSAGE_ENDELSE = 'generell.filer.validering.endelse';
const ERRORMESSAGE_TYPE = 'generell.filer.validering.type';

const DokumenterPanel: React.FC<DokumenterPanelProps> = (props) => {
    const { lastOppFilError, lesemodus, arkivdokumenter, ...modalProps } = props;
    const { kunder, obligatoriskeDokumenter } = props;
    const intl = useIntl();

    const [aktiveFiler, setAktiveFiler] = useState<IArkivdokument[]>([]);
    const [filSomSlettes, setFilSomSlettes] = useState<IArkivdokument | null>(null);

    const vilkarPanelContainerCss = classNames(
        'align-self-stretch',
        { 'col-md-6': lesemodus },
        { col: !lesemodus }
    );

    const utledVilkar = (obligDok, arkDok) => {
        const saksdokumenter = arkDok.flatMap(arkivdokument => arkivdokument.saksdokumenter);

        return obligDok.map(oblDok => ({
            ...utledVilkarStatus(oblDok, saksdokumenter),
            navn: getIntlKeyValueForDokument(oblDok, true, kunder, intl)
        }));
    };

    const utledFeilmelding = (error: IMessageDescriptor) => {
        if (error.id === ERRORMESSAGE_MAXSIZE) {
            return lastOppFilError({ id: 'dokumenterpanel.feilmeldinger.maksimaltTillattFilstorrelse', values: error.values });
        }
        if ([ERRORMESSAGE_ENDELSE, ERRORMESSAGE_FEIL, ERRORMESSAGE_TYPE].includes(error.id)) {
            return lastOppFilError({ id: 'dokumenterpanel.feilmeldinger.filStottesIkke' });
        }
        return lastOppFilError({ id: 'dokumenterpanel.feilmeldinger.generell' });
    };

    const onDropFileInput = (filer: File[]) => setAktiveFiler(filer.map(fil => ({ filnavn: fil.name, fil })));

    return <Panel overskrift="dokumenterpanel.overskrift.hoved" panelCssClass={panelCss} innholdCssClass={innholdCssClass}>
        <AkVilkarPanel
            containerClassName={vilkarPanelContainerCss}
            vilkar={utledVilkar(obligatoriskeDokumenter, arkivdokumenter)}
            visSomVilkarsliste={true}
            overskriftOppfylt="dokumenterpanel.overskrift.harDokumenter"
            overskriftIkkeOppfylt="dokumenterpanel.overskrift.manglerDokumenter"
            overskriftMaGodkjennes="dokumenterpanel.overskrift.maGodkjennes"
            overskriftIngenRelevanteVilkar="dokumenterpanel.overskrift.ingenPakrevdeDokumenter"
            tekstIngenRelevanteVilkar="dokumenterpanel.kropp.ingenPakrevdeDokumenter"
        />

        {!lesemodus &&
            <>
                <DokumentModal aktiveFiler={aktiveFiler}
                    setAktiveFiler={setAktiveFiler} setFilSomSlettes={setFilSomSlettes} filSomSlettes={filSomSlettes} {...modalProps} />
                <AkDroparea onDrop={onDropFileInput} onClick={() => setAktiveFiler([{} as IArkivdokument])}
                    fileErrorMessage={utledFeilmelding} areaClassNames="col ml-md-3" />
            </>
        }
        <DokumentTabell setFilSomSlettes={setFilSomSlettes} setAktiveFiler={setAktiveFiler} kunder={kunder}
            lesemodus={lesemodus} arkivdokumenter={arkivdokumenter} obligatoriskeDokumenter={obligatoriskeDokumenter} />
    </Panel>;
};

export default DokumenterPanel;