import classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import type { WrappedComponentProps} from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';

import type { KodeType } from '../../models/kodeverk';

interface ISelectInputState {
    value: string;
    propertyKey?: string;
}

interface ISelectInputProps {
    options: KodeType[];
    name?: string;
    disabled?: boolean;
    narrow?: boolean;
    placeholderKey?: string;
    updateSelectedOption: (option: string) => void;
    onChange?: (state: ISelectInputState) => void;
    onBlur?: (state: ISelectInputState) => void;
    touched?: boolean;
    errors?: any[];
    initialValue?: string;
}

class SelectInput extends Component<ISelectInputProps & WrappedComponentProps> {

    public state = {
        touched: false
    };

    public render() {
        const {name, disabled} = this.props;
        const errorMessages = this.props.errors;
        const shouldDisplayErrors = () => {
            if (this.props.errors) {
                return errorMessages.length ? (this.state.touched || this.props.touched) : false;
            } else {
                return false;
            }
        };
        const narrow = this.props.narrow;
        const dlClassNames = classNames('ak-dl-liste ak-dl-liste-kompakt ak-input-dropdown-wrapper', {'row col-7': !narrow});
        const ddClassNames = classNames('pr-0', {col: !narrow}, {'col-auto': narrow});
        const nameClassNames = classNames('my-2 mx-2', {'col-2': !narrow}, {'pr-0 col-auto': narrow});

        const dropdownLabelClassNames = classNames('ak-dropdown-label ml-4 mr-4',
            {'ak-dropdown-label-disabled': this.props.disabled},
            {'ak-input-error': shouldDisplayErrors()});

        return (
            <dl className={dlClassNames}>
                {this.props.name && <dt className={nameClassNames}><FormattedMessage id={name} />:</dt>}
                <dd className={ddClassNames}>
                    <label className={dropdownLabelClassNames}>
                        <select onChange={this.handleSelectChange}
                            disabled={disabled}
                            defaultValue={this.utledDefaultSelectValue()}
                            className="ak-input-dropdown col-3">
                            {this.mapOptions()}
                        </select>
                    </label>
                    {shouldDisplayErrors() &&
                    <p className="ak-input-error-text ml-4 mr-4">
                        {errorMessages.map((error) => {
                            return <FormattedMessage key={error.id || error} id={error.id || error} values={error.values || {}} />;
                        })}
                    </p>
                    }
                </dd>
            </dl>
        );
    }

    private mapOptions = () => {
        const initialValue = this.props.initialValue;
        return (
            <>
                {this.props.placeholderKey && !initialValue &&
                <option key="defaultOption" value={null} hidden={true} disabled={true}>
                    {this.props.intl.formatMessage({id: this.props.placeholderKey})}
                </option>
                }
                {this.props.options.map((option, index) => this.optionMapper(option, index))}
            </>
        );
    };

    private optionMapper = (option: KodeType, index: number) => {
        return (
            <option key={index} value={option.getKode()}>
                {option.getBeskrivelse()}
            </option>
        );
    };

    private handleSelectChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.updateSelectedOption(evt.target.value);
    };

    private utledDefaultSelectValue(): string {
        if (this.props.initialValue) {
            return this.props.initialValue;
        } else if (this.props.placeholderKey) {
            return this.props.intl.formatMessage({id: this.props.placeholderKey});
        }
        return null;
    }
}

const SelectInputConnected = injectIntl(SelectInput);

export { SelectInputConnected };
