import { AkKnapp, AkModal, AkModalType } from 'svv-tk-akr-common-frontend';
import * as React from 'react';

interface IFullforRegistreringModalProps {
    showModal: boolean;
    title: string;
    bodyTextKey: string;
    fullfor: () => void;
    close: () => void;
    isLoading?: boolean;
}

const FullforRegistreringModal: React.FC<IFullforRegistreringModalProps> = (props) => {
    const {fullfor, bodyTextKey, isLoading, close, showModal, title} = props;

    if (!showModal) {
        return null;
    }

    return (
        <AkModal
            showModal={showModal}
            title={title}
            bodyTextKey={bodyTextKey}
            modalType={AkModalType.WARN}
            closeModal={close}>
            <div className="ak-knapperad row justify-content-end no-gutters">
                <AkKnapp type="ak-knapp ak-hovedknapp" action={fullfor} intlKey="generell.modal.fullfor.bekreft" loading={isLoading} />
                <AkKnapp type="ak-knapp ak-tilleggsknapp" action={close} intlKey="generell.modal.fullfor.angre" disabled={isLoading} />
            </div>
        </AkModal>
    );
};

export { FullforRegistreringModal };
