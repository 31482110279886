import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import * as React from 'react';
import type { ReactNode } from 'react';
import { Component } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AkKnapp, AkModal, AkModalType, akReactKeyGen } from 'svv-tk-akr-common-frontend';

import { ArbeidslisteFeilhandtering } from '../../components/arbeidsliste';
import { NY_SAK_VISNINGSTID_MS } from '../../constants';
import { ArbeidslisteSakstypefilter } from '../../models/kodeverk';
import { Varseltype } from '../../models/kodeverk/varseltype';
import type { Brukerprofil, IArbeidsliste, IArbeidslisteFilter, IArbeidslisteRad, NavigateType, RootStateType } from '../../models/types';
import type { ArbeidslisteDokumentasjonsstatusDispatchProps, ArbeidslisteDokumentasjonsstatusStateProps } from '../../models/types/arbeidsliste-dokumentasjonsstatus';
import { BrukerRegler } from '../../regler';
import { arbeidslisteFetchEierskifterOgRegistreringer, arbeidsListeNySak, arbeidslisteUpdateFilterAction, finnDokumentasjonsstatus, godkjennEierskifte, sortArbeidsliste } from '../../state/actions';
import { brukerprofilSelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';
import type { IRadConfigProps} from '../startside';
import { ArbeidslisteHeaderCell, ArbeidslisteRow, PurreAktorModalConnected } from '../startside';
import { ArbeidslisteCard } from './arbeidsliste-card';
import { makeRadConfig } from './arbeidsliste-config';
import { ArbeidslisteFilterbarConnected } from './arbeidsliste-filterbar';

import './arbeidsliste-style';

interface IArbeidslisteProps {
    dager: string;
}

interface IArbeidslisteStateProps extends ArbeidslisteDokumentasjonsstatusStateProps {
    arbeidsliste: IArbeidsliste;
    innloggetForhandlerNavn: string;
    godkjennEierskifteIsLoading: boolean;
    nySakIder: string[];
    brukerprofil: Brukerprofil;
}

interface IArbeidslisteDispatchProps extends ArbeidslisteDokumentasjonsstatusDispatchProps {
    arbeidslisteClearNySakIder: (navigate: NavigateType) => any;
    godkjennEierskifte: (sakId: string) => Promise<void>;
    sorterArbeidsliste: (sortProp: string) => void;
    arbeidslisteFetchEierskifterOgRegistreringer: (dager: string, withClear: boolean) => void;
    updaterFilterIStore: (filter: IArbeidslisteFilter) => void;
}

interface IArbeidslisteComponentState {
    viewHeight: number;
    offsetTop: number;
    godkjennEierskifteSakId: string;
    showGodkjenningModal?: boolean;
    showPurringModal?: boolean;
    purringSakId: string;
}

type PropsType = IArbeidslisteProps & IArbeidslisteStateProps & IArbeidslisteDispatchProps & WrappedComponentProps & WithRouterProps;

class ArbeidslisteEierskifterOgRegistreringer extends Component<PropsType, IArbeidslisteComponentState> {

    public state: IArbeidslisteComponentState = {
        viewHeight: 0,
        offsetTop: 0,
        showGodkjenningModal: false,
        godkjennEierskifteSakId: '',
        showPurringModal: false,
        purringSakId: null
    };

    private radConfig = makeRadConfig(this.props.brukerprofil,false, this.props.erFlateeier);
    private config = {
        // Endres denne så må man endre på ak-arbeidsliste-row-empty scss klassen
        ROW_HEIGHT: 57,
        OVERSCAN_BOTTOM: 472,
        OVERSCAN_TOP: 472,
        START_VIRTUAL_SCROLL_ON_COUNT: 50,
        DEBOUNCE_MS: 150,
        DEFAULT_TABLE_OFFSET_TOP: 250
    };

    private handleResize = _.throttle(() => this.setState({ viewHeight: window.innerHeight }), this.config.DEBOUNCE_MS);

    private handleScroll = _.throttle(() => {
        const tableOffsetTop = (document.querySelector('.ak-table-body')[0] || {}).offsetTop || this.config.DEFAULT_TABLE_OFFSET_TOP;
        this.setState({ offsetTop: window.pageYOffset - tableOffsetTop });
    }, this.config.DEBOUNCE_MS);

    public componentDidMount() {
        this.setState({ viewHeight: window.innerHeight });
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        if (this.props.erFlateeier) {
            this.props.sorterArbeidsliste('sistEndret');
        }
    }

    public componentDidUpdate() {
        const { isLoading } = this.props.arbeidsliste;

        if (this.props.nySakIder.length && !isLoading) {
            setTimeout(() => this.props.arbeidslisteClearNySakIder(this.props.navigate), NY_SAK_VISNINGSTID_MS);
        }

        if (this.props.skalHenteDokumentasjonsstatus && !this.props.erFlateeier && this.props.erDokumentasjonsstatusEnabled) {
            this.props.finnDokumentasjonsstatus(this.props.arbeidsliste.rader);
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    public render(): ReactNode {
        const { rader } = this.props.arbeidsliste;
        const sakstype = this.props.erFlateeier ? ArbeidslisteSakstypefilter.EIERSKIFTE : ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING;

        return (
            <div className="ak-fixed-lg-container">
                <ArbeidslisteFilterbarConnected sakstype={sakstype} />
                <div className="ak-div-table arbeidsliste-large">
                    <div className="ak-table-header ak-table-row">
                        {this.radConfig.map(this.headerCellMapper)}
                    </div>
                    <div className="ak-table-body">
                        {!!rader.length && rader.map(this.rowMapper)}
                        <ArbeidslisteFeilhandtering liste={this.props.arbeidsliste} />
                    </div>
                </div>
                <ul className="arbeidsliste-card-list">
                    {!!rader.length && rader.map(this.arbeidslisteCardMapper)}
                </ul>
                <AkModal showModal={this.state.showGodkjenningModal} title="eierskifte.modal.bekreftTitle" modalType={AkModalType.INFO} closeModal={this.closeModalForGodkjennEierskifte}>
                    {this.kjennemerkehandteringModalTekst()}
                    <div className="ak-knapperad row">
                        <AkKnapp key="godkjenn" loading={this.props.godkjennEierskifteIsLoading}
                            type="ak-knapp ak-hovedknapp" action={this.godkjennEierskifte} intlKey={'eierskifte.modal.bekreftEierskifte'} />
                        <AkKnapp key="lukk" type="ak-knapp ak-tilleggsknapp" action={this.closeModalForGodkjennEierskifte} intlKey={'generell.avbryt'} />
                    </div>
                </AkModal>
                <PurreAktorModalConnected showModal={this.state.showPurringModal} closeModal={this.closeModalForPurring} sakId={this.state.purringSakId}
                    varseltype={Varseltype.PURRING_EIERSKIFTE} />
                <div className="ak-fixed-lg-container-dropdown-pad" />
            </div>
        );
    }

    private openModalForGodkjennEierskifte = (sakId: string) => {
        this.setState({
            godkjennEierskifteSakId: sakId,
            showGodkjenningModal: true
        });
    };

    private closeModalForGodkjennEierskifte = () => {
        this.setState({
            godkjennEierskifteSakId: '',
            showGodkjenningModal: false
        });
    };

    private openModalForPurring = (sakId: string) => {
        this.setState({
            showPurringModal: true,
            purringSakId: sakId
        });
    };

    private closeModalForPurring = () => {
        this.setState({
            showPurringModal: false,
            purringSakId: null
        });
    };

    private godkjennEierskifte = () => {
        this.props.godkjennEierskifte(this.state.godkjennEierskifteSakId).then(() => {
            this.props.arbeidslisteFetchEierskifterOgRegistreringer(this.props.dager, true);
            this.closeModalForGodkjennEierskifte();
        });
    };

    private kjennemerkehandteringModalTekst = () => {
        const messages = [];
        messages.push(
            this.formaterGodkjenningModaltekst('bekreftelseBody', this.props.innloggetForhandlerNavn),
            this.formaterGodkjenningModaltekstPunkt(['bekreftelseAvmontering', 'bekreftelseOppbevaring']),
            this.formaterGodkjenningModaltekst('bruddPaAvtaleFlereEierskifter', null)
        );

        return messages.map((message, index) => <div key={index}>{message}</div>);
    };

    private formaterGodkjenningModaltekst = (id: string, value: string) => {
        return <p><FormattedMessage id={`startside.modal.${id}`} values={{ eier: value || '' }} /></p>;
    };

    private formaterGodkjenningModaltekstPunkt = (id: string[]) => {
        return (
            <ul>
                {id.map((item) => {
                    return <li key={item}><FormattedMessage id={`startside.modal.${item}`} /></li>;
                })}
            </ul>
        );
    };

    private headerCellMapper = (radConfig: IRadConfigProps): ReactNode => {
        return (
            <ArbeidslisteHeaderCell
                key={radConfig.headerIntlKey}
                intlKey={radConfig.headerIntlKey}
                size={radConfig.cellSize}
                sortProp={radConfig.sortProperty}
                currentSortProp={this.props.arbeidsliste.sortProp}
                currentSortDir={this.props.arbeidsliste.sortDir}
                sort={this.props.sorterArbeidsliste}
            />
        );
    };

    private rowMapper = (rad: IArbeidslisteRad, index: number): ReactNode => {
        const { nySakIder } = this.props;

        return this.isElementInView(index)
               ? (
                   <ArbeidslisteRow key={this.rowKey(rad)} rad={rad}
                       radConfig={this.radConfig}
                       nyRad={_.includes(nySakIder, rad.sakId)}
                       openModalForGodkjennEierskifte={this.openModalForGodkjennEierskifte}
                       openModalForPurring={this.openModalForPurring} />
               ) : <div key={this.rowKey(rad)} className="ak-table-row ak-arbeidsliste-row-empty" />;
    };

    private arbeidslisteCardMapper = (rad: IArbeidslisteRad, index: number) => {
        return this.isElementInView(index)
               ? (
                   <ArbeidslisteCard key={akReactKeyGen(rad)} rad={rad} radConfig={this.radConfig}
                       nyRad={_.includes(this.props.nySakIder, rad.sakId)}
                       isForstegangsregistrering={false}
                       openModalForGodkjennEierskifte={this.openModalForGodkjennEierskifte}
                       openModalForPurring={this.openModalForPurring} />
               ) : <div key={this.rowKey(rad)} className="ak-table-row ak-arbeidsliste-row-empty" />;
    };

    private rowKey = (rad: IArbeidslisteRad): string => (rad.sakId + (rad.sakAktorInfo && rad.sakAktorInfo.aktorType));
    private isElementInView = (index: number) => {
        if (this.props.arbeidsliste.rader.length < this.config.START_VIRTUAL_SCROLL_ON_COUNT) {
            return true;
        }

        const pos = (index + 1) * this.config.ROW_HEIGHT;
        return pos > this.state.offsetTop - this.config.OVERSCAN_TOP && pos < this.state.offsetTop + this.state.viewHeight + this.config.OVERSCAN_BOTTOM;
    };
}

const mapStateToProps = (state: RootStateType): IArbeidslisteStateProps => ({
    arbeidsliste: state.arbeidsliste.eierskifterOgRegistreringer,
    nySakIder: state.arbeidsliste.nySakIder,
    innloggetForhandlerNavn: state.brukerprofil.valgtEnhet.navn,
    godkjennEierskifteIsLoading: state.eierskifte.isLoading,
    erFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    brukerprofil: brukerprofilSelector(state),
    filter: state.arbeidsliste.filter,
    erDokumentasjonsstatusEnabled: state.globals.klientKonfigurasjon.dokumentstatus.enabled,
    skalHenteDokumentasjonsstatus: state.arbeidsliste.skalHenteDokumentasjonsstatus
});

const mapDispatchToProps = (dispatch): IArbeidslisteDispatchProps => ({
    godkjennEierskifte: (sakId: string) => dispatch(godkjennEierskifte(sakId)),
    arbeidslisteClearNySakIder: (navigate: NavigateType) => dispatch(arbeidsListeNySak(navigate, [], null)),
    sorterArbeidsliste: (sortProp: string) => dispatch(sortArbeidsliste(sortProp, ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING)),
    arbeidslisteFetchEierskifterOgRegistreringer: (dager: string, withClear: boolean) =>
        dispatch(arbeidslisteFetchEierskifterOgRegistreringer(dager, withClear)),
    updaterFilterIStore: filter => dispatch(arbeidslisteUpdateFilterAction(filter)),
    finnDokumentasjonsstatus: (arbeidslisteRader) => dispatch(finnDokumentasjonsstatus(arbeidslisteRader))
});

const ArbeidslisteEierskifterOgRegistreringerConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withRouter
)(ArbeidslisteEierskifterOgRegistreringer);

export { ArbeidslisteEierskifterOgRegistreringerConnected };
