export enum Brukerrolle {
    FREG = 'FREG',
    KJSCM = 'KJSCM',
    EIERSKIFTE = 'EIERSKIFTE',
    OMREG = 'OMREG',
    AVSTEM = 'AVSTEM',
    FORSTEGANGSREGISTRERING = 'FORSTEGANGSREGISTRERING',
    KJORESEDDEL = 'KJORESEDDEL',
    FLATEEIER = 'FLATEEIER'
}
