import * as React from 'react';
import type { SyntheticEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'styled-components';
import { AkColumn, akFormatDateTime, AkKnapp, AkTable, type IKunderMap, Kanal, SortOrder } from 'svv-tk-akr-common-frontend';
import type { IArkivdokument, IObligatoriskDokument } from '../../../models/types';
import { getIntlKeyValueForDokument } from './dokumenter-panel-util';

interface IArkivdokumentTabellProps {
    lesemodus: boolean;
    arkivdokumenter: IArkivdokument[];
    obligatoriskeDokumenter: IObligatoriskDokument[];
    kunder: IKunderMap;
    setFilSomSlettes: (arkivdokument: IArkivdokument) => void;
    setAktiveFiler: (arkivdokument: IArkivdokument[]) => void;
}

const DokumentTabell: React.FC<IArkivdokumentTabellProps> = ({ lesemodus, arkivdokumenter, obligatoriskeDokumenter, kunder, setFilSomSlettes, setAktiveFiler }: IArkivdokumentTabellProps) => {
    const intl = useIntl();

    const opprettetAv = (arkivdokument: IArkivdokument) => lastetOppAvSbh(arkivdokument)
                                                           ? <FormattedMessage id="dokumenterpanel.kropp.saksbehandler" />
                                                           : <>{arkivdokument.opprettetAv || ''}</>;

    const dokumentInnhold = (arkivdokument: IArkivdokument) => (
        <ul className="ak-liste">
            {arkivdokument.saksdokumenter.map(
                (saksdok, index) => (
                    <FormattedMessage tagName="li" key={index} {...getIntlKeyValueForDokument(saksdok, false, kunder, intl)}
                    />
                ))}
        </ul>
    );

    const tabellradKnapper = (arkivdokument: IArkivdokument) => (
        !lesemodus && !lastetOppAvSbh(arkivdokument) &&
        <>
            <AkKnapp intlKeyTooltip="dokumenterpanel.knapper.endre" type="ak-ikon-knapp-transparent" icon="fa-pencil-alt" customClassName="px-1"
                value={arkivdokument.id} action={(e) => setAktiveFiler([finnDok(e)])} />
            <AkKnapp intlKeyTooltip="dokumenterpanel.knapper.slett" type="ak-ikon-knapp-transparent" icon="fa-trash" customClassName="px-1"
                action={(e) => setFilSomSlettes(finnDok(e))} value={arkivdokument.id} />
        </>
    );

    const finnDok = (evt: SyntheticEvent<HTMLButtonElement>) => arkivdokumenter.find(dok => dok.id === Number(evt.currentTarget.value));

    if (lesemodus && !arkivdokumenter?.length && !obligatoriskeDokumenter?.length) {
        return <p className="mt-1 col-12 ak-fet"><FormattedMessage id="dokumenterpanel.melding.ingenDokumenter" /></p>;
    }

    return !!arkivdokumenter?.length &&
        <AkTable gridTemplate={gridTemplate} rows={arkivdokumenter} keyGenerator={(doc) => doc.id}
            defaultSorting={{ property: ['opprettetDato'], order: [SortOrder.DESCENDING] }}>
            <AkColumn header={{ id: 'dokumenterpanel.kropp.tidspunkt' }} value={tidspunkt} sorting={{ property: ['opprettetDato'] }} />
            <AkColumn header={{ id: 'dokumenterpanel.kropp.opprettetAv' }} value={opprettetAv} sorting={{ property: ['opprettetAv'] }} />
            <AkColumn header={{ id: 'dokumenterpanel.kropp.filnavn' }} value={filnavn} sorting={{ property: ['filnavn'] }} />
            <AkColumn header={{ id: 'dokumenterpanel.kropp.inneholder' }} value={dokumentInnhold} />
            <AkColumn value={tabellradKnapper} />
        </AkTable>;
};

const gridTemplate = css`
  grid-template-columns:
            minmax(150px, 1fr)
            minmax(140px, 1fr)
            minmax(200px, 2fr)
            minmax(150px, 2fr)  
            minmax(100px, 1fr);
`;

const tidspunkt = (arkivdokument: IArkivdokument) => <>{akFormatDateTime(arkivdokument.opprettetDato) || ''}</>;

const lastetOppAvSbh = (arkivdokument) => arkivdokument.saksdokumenter.some(saksdok => saksdok.lastetOppFraKanal === Kanal.SAKSBEHANDLER);

const filnavn = (arkivdokument: IArkivdokument) => {
    if (lastetOppAvSbh(arkivdokument)) {
        return <FormattedMessage id="dokumenterpanel.kropp.lastetOppAvSaksbehandler" />;
    }

    if (!arkivdokument.filnavn) {
        return null;
    }

    const filePath = `${window.env.serviceUrl}/saker/${arkivdokument.sakId}/arkivdokumenter/${arkivdokument.id}`;
    return <a href={filePath} download={arkivdokument.filnavn} title={arkivdokument.filnavn} target="_blank" rel="noopener noreferrer">{arkivdokument.filnavn}</a>;
};

export default DokumentTabell;