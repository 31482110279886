import { compose } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { matchPath, Navigate, Route, Routes } from 'react-router-dom';
import type { ThunkDispatch } from 'redux-thunk';
import type { ISystemvarsler } from 'svv-tk-akr-common-frontend';
import { AkLoading, AkLoadingModal, AkrErrorBoundary } from 'svv-tk-akr-common-frontend';
import AktiveTogglesPage from 'svv-tk-akr-common-frontend/dist/components/aktive-toggles/aktive-toggles-page';
import { MeldingBarConnected } from '../../components/melding-bar';
import { AkrConfig } from '../../constants';

import type { Brukerprofil, IKlientKonfigurasjonState, ISak, RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';
import type { BrukerprofilActionType, OversettelserActionType} from '../../state/actions';
import { brukerprofilGetData, getSystemvarsler, initSession, logError, oversettelserGetData } from '../../state/actions';
import { createRestResource } from '../../state/store';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';
import { IkkeFunnet, InternFeilConnected } from '../app';
import { EierskifteBulkConnected } from '../eierskifte';
import { FlyttKjoretoyConnected } from '../flyttkjoretoy';
import { FooterConnected } from '../footer';
import { Header } from '../header/header';
import { KjoretoyConnected } from '../kjoretoy';
import { KjoretoylisteConnected } from '../kjoretoyliste';
import { OppslagConnected } from '../oppslag';
import { SokConnected } from '../sok';
import { StartsideConnected } from '../startside';
import { SystemvarslerConnected } from '../systemvarsler';

interface IAppStateProps {
    brukerprofil: Brukerprofil;
    globalErrors: any;
    klientKonfigurasjon?: IKlientKonfigurasjonState;
    kanSokeOppKjoretoy: boolean;
    harRolleEierskifte: boolean;
    systemvarsler: ISystemvarsler;
    sak: ISak<any>;
}

interface IAppDispatchProps {
    hentOversettelser: (lang: string) => void;
    hentBrukerprofil: () => Promise<BrukerprofilActionType>;
    initSession: () => Promise<BrukerprofilActionType>;
    hentSystemvarsler: () => void;
    logError: (errorMessage: string) => Promise<string>;
}

interface IAppState {
    loading: boolean;
    systemvarselInterval?: any;
}

type PropsType = IAppStateProps & IAppDispatchProps & WrappedComponentProps & WithRouterProps;

const errorPagePath = '/feil/:type?';

class App extends React.Component<PropsType, IAppState> {

    public static getDerivedStateFromProps(nextProps: PropsType, prevState: IAppState) {
        const { klientKonfigurasjon } = nextProps;

        if (prevState.loading && App.erGlobaleAvhengigheterLastet(nextProps)) {
            let systemvarselInterval = prevState.systemvarselInterval;
            if (klientKonfigurasjon.hentSystemVarslerIntervallMs && !systemvarselInterval) {
                nextProps.hentSystemvarsler();
                systemvarselInterval = setInterval(nextProps.hentSystemvarsler, klientKonfigurasjon.hentSystemVarslerIntervallMs);
            }

            return { loading: false, systemvarselInterval };
        }

        return null;
    }

    private static erGlobaleAvhengigheterLastet = (newProps: PropsType) => {
        const { intl, klientKonfigurasjon } = newProps;
        return !!intl.messages && !!klientKonfigurasjon && !klientKonfigurasjon.isLoading && !!klientKonfigurasjon.merknader;
    };

    public state: IAppState = {
        loading: true
    };

    public componentDidMount() {
        if (!this.props.brukerprofil.valgtEnhet) {
            this.props.initSession();
        }
    }

    public componentWillUnmount() {
        if (this.state.systemvarselInterval) {
            clearInterval(this.state.systemvarselInterval);
        }
    }

    public componentDidUpdate(prevProps: PropsType) {
        if (!_.isEqual(prevProps.systemvarsler, this.props.systemvarsler) && !!this.props.systemvarsler.error) {
            this.setState({
                systemvarselInterval: null
            });
            clearInterval(this.state.systemvarselInterval);
        }

        if (this.props.globalErrors.length &&
            !matchPath({path: errorPagePath}, this.props.location.pathname) &&
            !matchPath({path: errorPagePath}, prevProps.location.pathname)) {
            this.props.navigate('/feil/generell');
        }
    }

    public render() {
        return (
            <div className="app-root">
                <div className="app-main">
                    <Header />
                    <MeldingBarConnected />
                    <div className={'main-content'}>{this.mainContainer()}</div>
                </div>
                <FooterConnected />
            </div>
        );
    }

    private mainContainer = () => {
        if (this.state.loading) {
            if (!!this.props.brukerprofil.valgtEnhet) {
                return <AkLoading />;
            } else {
                return null;
            }
        }

        if (this.props.brukerprofil.hasError) {
            return <Navigate to="/feil/generell" />;
        }

        return (
            <AkrErrorBoundary location={this.props.location} logError={this.props.logError} headerKey="feilhandtering.generell.overskrift"
                errorMessageKey="feilhandtering.kode.ukjent.kode" gotoLink="/startside" gotoLinkKey="feilhandtering.generell.link.gatil">
                <Routes>

                    <Route path="/systemvarsler" element={this.harValgtEnhet() ? <SystemvarslerConnected /> : null} />
                    <Route path="/sok" element={this.harValgtEnhet() ? <SokConnected /> : null} />
                    <Route path="/startside" element={this.harValgtEnhet() ? <StartsideConnected /> : null} />
                    <Route path="/kjoretoy/eierskifter" element={this.harValgtEnhet() ?
                                                                 (this.kanEierskifte() ? <EierskifteBulkConnected /> : <Navigate to="/ikkefunnet" />) : null} />
                    <Route path="/kjoretoy/:kuid/*" element={this.harValgtEnhet() ? <KjoretoyConnected /> : null} />

                    <Route path="/oppslag" element={this.harValgtEnhet() ?
                                                    (this.kanViseKjoretoyliste() ? <OppslagConnected /> : <Navigate to="/ikkefunnet" />) : null} />
                    <Route path="/flytting" element={this.harValgtEnhet() ?
                                                     (this.kanEierskifte() ? <FlyttKjoretoyConnected /> : <Navigate to="/ikkefunnet" />) : null} />
                    <Route path="/kjoretoyliste" element={this.harValgtEnhet() ?
                                                          (this.kanViseKjoretoyliste() ? <KjoretoylisteConnected /> : <Navigate to="/ikkefunnet" />) : null} />

                    <Route path="/ikkefunnet" element={<IkkeFunnet />} />
                    <Route path="/" element={<Navigate to="/startside" />} />
                    <Route path={errorPagePath} element={<InternFeilConnected updateParent={this.updateFromChild} />} />
                    <Route path='*' element={<Navigate to="/ikkefunnet" />} />
                    {process.env.NODE_ENV !== 'test' && !this.props.klientKonfigurasjon?.erProd &&
                        <Route path="/funksjonsbrytere" element={<AktiveTogglesPage api={createRestResource(AkrConfig.FUNKSJONSBRYTERE_RESOURCE_URL)}/>} />
                    }
                </Routes>
                <AkLoadingModal isOpen={this.props.sak.isLoadingModalOpen} />
            </AkrErrorBoundary>
        );
    };

    private kanEierskifte = () => this.props.harRolleEierskifte;
    private kanViseKjoretoyliste = () => this.props.kanSokeOppKjoretoy;

    private harValgtEnhet = () => this.props.brukerprofil.valgtEnhet;

    private updateFromChild = () => {
        this.props.hentOversettelser('nb');
        this.props.hentBrukerprofil();
        this.forceUpdate();
    };

}

const mapStateToProps = (state: RootStateType): IAppStateProps => ({
    brukerprofil: state.brukerprofil,
    globalErrors: state.globals.globalErrors,
    klientKonfigurasjon: state.globals.klientKonfigurasjon,
    kanSokeOppKjoretoy: BrukerRegler.adapter(state).harRolleForSok(),
    harRolleEierskifte: BrukerRegler.adapter(state).harRolleEierskifte(),
    systemvarsler: state.systemvarsler,
    sak: state.sak
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootStateType, undefined, OversettelserActionType | BrukerprofilActionType>): IAppDispatchProps => ({
    hentOversettelser: (dialekt) => dispatch(oversettelserGetData(dialekt)),
    hentBrukerprofil: () => dispatch(brukerprofilGetData()),
    initSession: () => dispatch(initSession()),
    hentSystemvarsler: () => dispatch(getSystemvarsler()),
    logError: (errorMessage: string) => dispatch(logError(errorMessage))
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withRouter
);

export const AppConnected = enhance(App);
