import { AkKnapp, AkModal, AkModalType } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Feilkoder } from '../../constants';
import type { IRegistreringInformasjon, ISak } from '../../models/types';

interface IKjennemerkeAlleredeIBrukProps {
    sak?: ISak<IRegistreringInformasjon>;
}

interface IKjennemerkeAlleredeIBrukState {
    visAlleredeIBrukModal?: boolean;
}

class KjennemerkeAlleredeIBrukModal extends React.Component<IKjennemerkeAlleredeIBrukProps & IKjennemerkeAlleredeIBrukState> {

    public state = {
        visAlleredeIBrukModal: false
    };

    public componentDidUpdate(prevProps: IKjennemerkeAlleredeIBrukProps): void {
        if (!_.isEqual(this.props.sak?.error, prevProps.sak?.error) && this.erKjennemerkeAlleredeIbruk()) {
            this.visKjennemerkeIBrukModal();
        }
    }

    public render(): React.ReactNode {
        if (!this.props.sak?.error) {
            return null;
        }

        return (
            <>
                <AkModal showModal={this.state.visAlleredeIBrukModal}
                    title="sak.modal.kjennemerkeAlleredeIBruk.tittel"
                    modalType={AkModalType.INFO}>
                    <div className="pb-3 row no-gutters flex-nowrap align-items-center">
                        <p>
                            {this.renderBeskrivelseMelding()}
                        </p>
                    </div>
                    <div className="ak-knapperad row no-gutters text-left">
                        <AkKnapp type="ak-hovedknapp" action={this.lukkKjennemerkeIBrukModal} intlKey={'sak.modal.kjennemerkeAlleredeIBruk.bekreft'} />
                    </div>
                </AkModal>
            </>
        );
    }

    private renderBeskrivelseMelding = () => {
        return (
            <>
                <FormattedMessage id={'sak.modal.kjennemerkeAlleredeIBruk.beskrivelse'}
                    values={{tegnkombinasjonMedMelding: this.props.sak?.error?.errorMessage}} />
            </>
        );
    };

    private lukkKjennemerkeIBrukModal = () => {
        return this.setState({visAlleredeIBrukModal: false});

    };

    private visKjennemerkeIBrukModal = () => {
        return this.setState({visAlleredeIBrukModal: true});
    };

    private erKjennemerkeAlleredeIbruk = (): boolean => {
        return this.props.sak?.error?.errorCode === Feilkoder.TEGNKOMBINASJONEN_FINNES_ALLEREDE.valueOf();
    };

}

export { KjennemerkeAlleredeIBrukModal };