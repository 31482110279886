import type { IAktorPanelAktor} from 'svv-tk-akr-common-frontend';
import { AkFormattedMessage, AkKnapp, AkLoading, AkModal, AkModalType, AktorKontaktinfo, ValgtKontaktform, VALID_EMAIL_REGEX, VALID_TLF_REGEX } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import * as React from 'react';
import type { WrappedComponentProps} from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import type { Varseltype } from '../../models/kodeverk/varseltype';
import type { IAktor, IError, IKunde, IKunder, RootStateType } from '../../models/types';
import { purre } from '../../state/actions';
import { hentAktorer } from '../../state/actions/sak/aktor-actions';

interface IPurreAktorModalProps {
    showModal?: boolean;
    closeModal?: () => void;
    sakId: string;
    varseltype?: Varseltype;
}

interface IPurreAktorModalStateProps {
    kunder?: IKunder;
}

interface IPurreAktorModalDispatchProps {
    hentAktorer?: (sakId: string) => Promise<IAktor[]>;
    purreAktor?: (sakId: string, aktor: IAktor, varseltype: Varseltype) => void;
}

interface IPurreState {
    aktorer: IAktor[];
    hasLoaded: boolean;
    error: IError;
    submitted: boolean;
    isSending: boolean;
}

type PurreAktorModalPropsType = IPurreAktorModalProps & IPurreAktorModalStateProps & IPurreAktorModalDispatchProps & WrappedComponentProps;

class PurreAktorModal extends React.Component<PurreAktorModalPropsType, IPurreState> {
    public state = {
        aktorer: [],
        hasLoaded: false,
        error: null,
        submitted: false,
        isSending: false
    };

    public componentDidUpdate(prevProps: IPurreAktorModalProps): void {
        if (this.props.sakId && this.props.sakId !== prevProps.sakId) {
            this.props.hentAktorer(this.props.sakId).then((response: IAktor[] & IError) => {
                if (response.errorId) {
                    return this.setState({
                        error: response,
                        hasLoaded: true
                    });
                }
                this.setState({
                    aktorer: response,
                    hasLoaded: true
                });
            });
        }
    }

    public render = () => {
        return (
            <AkModal showModal={this.props.showModal} title="sak.purreModal.title" modalType={AkModalType.WARN} closeModal={this.props.closeModal}>
                {!this.state.hasLoaded && <AkLoading />}
                {!!this.state.error && <FormattedMessage id={'feilhandtering.errorId'} values={{ errorId: this.state.error?.errorId }} />}
                {this.state.hasLoaded && !this.state.error && (
                    <>
                        <AkFormattedMessage msg={'sak.purreModal.tekst'} className="mb-3" tagName="p" />
                        {this.indrePaneler()}
                        <div className="ak-knapperad row">
                            <AkKnapp key="send" type="ak-knapp ak-hovedknapp" action={this.purrePaAktorer} intlKey={'generell.knapp.send'} loading={this.state.isSending} />
                            <AkKnapp key="avbryt" type="ak-knapp ak-tilleggsknapp" action={this.props.closeModal} intlKey={'generell.knapp.avbryt'} />
                        </div>
                    </>
                )}
            </AkModal>
        );
    };

    private indrePaneler = () => {
        const aktorerFiltered = _.reject(this.state.aktorer, (aktor: IAktor) => !!aktor.godkjenningsstatus);

        return aktorerFiltered.map((aktor: IAktor) => {
            if (aktorerFiltered.length === 1 && aktor.valgtKontaktform === ValgtKontaktform.IKKE_SEND_VARSEL) {
                aktor.valgtKontaktform = ValgtKontaktform.MOBIL;
            }
            const kunde: IKunde = _.get(this.props.kunder, aktor.kundeId);
            const key: string = aktor.kundeId + aktor.aktorType;
            return (
                <div className="ak-panel ak-indre-panel p-4" key={key}>
                    <h2>
                        <FormattedMessage id={`kodeverk.aktor_navn.${aktor.aktorType}`} values={{ navn: kunde?.navn }} />
                    </h2>
                    <dl className="ak-aktor-dl-grid">
                        <AktorKontaktinfo
                            aktor={aktor}
                            oppdaterAktor={this.oppdaterAktorForPurring}
                            maSendeVarsel={aktorerFiltered.length === 1}
                            validerUtfylt={this.state.submitted}
                            visForhandsutfyltValg={true}
                        />
                    </dl>
                </div>
            );
        });
    };

    private oppdaterAktorForPurring = (aktor: Partial<IAktorPanelAktor>) => {
        const aktorer = [...this.state.aktorer];
        const index = _.findIndex(aktorer, (findAktor: IAktor) => findAktor.kundeId === aktor.kundeId);
        const stateAktor = aktorer[index];

        stateAktor.valgtKontaktform = aktor.valgtKontaktform;
        stateAktor.mobiltelefon = aktor.mobiltelefon;
        stateAktor.epost = aktor.epost;
        stateAktor.skalBenytteRegistrertKontaktinformasjon = aktor.skalBenytteRegistrertKontaktinformasjon;

        aktorer.splice(index, 1, stateAktor);

        this.setState({
            aktorer,
            submitted: false
        });
    };

    private purrePaAktorer = () => {
        this.setState(
            {
                submitted: true,
                isSending: true
            },
            () => {
                const validationFailed = this.state.aktorer.every(
                    (aktor: IAktor) =>
                        !aktor.godkjenningsstatus &&
                        ((aktor.valgtKontaktform === ValgtKontaktform.MOBIL && (!aktor.mobiltelefon || !aktor.mobiltelefon.match(VALID_TLF_REGEX))) ||
                            (aktor.valgtKontaktform === ValgtKontaktform.EPOST && (!aktor.epost || !aktor.epost.match(VALID_EMAIL_REGEX))))
                );
                if (!validationFailed) {
                    const { sakId, varseltype, purreAktor, closeModal } = this.props;
                    this.state.aktorer.forEach((aktor: IAktor) => {
                        if (!aktor.godkjenningsstatus && aktor.valgtKontaktform !== ValgtKontaktform.IKKE_SEND_VARSEL) {
                            purreAktor(sakId, aktor, varseltype);
                        }
                    });
                    closeModal();
                }
                this.setState({ isSending: false });
            }
        );
    };
}

const mapStateToProps = (state: RootStateType): IPurreAktorModalStateProps => ({
    kunder: state.kunder
});

const mapDispatchToProps = (dispatch): IPurreAktorModalDispatchProps => ({
    hentAktorer: (sakId: string) => dispatch(hentAktorer(sakId)),
    purreAktor: (sakId: string, aktor: IAktor, varseltype: Varseltype) => dispatch(purre(sakId, aktor, varseltype))
});

const PurreAktorModalConnected = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PurreAktorModal));

export { PurreAktorModalConnected };
