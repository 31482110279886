import { LeasingtakerEndreActionKey, NY_SAK_VISNINGSTID_MS } from '../../constants';
import { MeldingType, Saksstatus } from '../../models/kodeverk';
import type { IError, IRegistreringInformasjon, IRegistreringSak, ISak, ThunkResult } from '../../models/types';
import type { SakActionType } from '../actions';
import { nyMelding, opprettSak } from '../actions';
import type { AvgiftsstatusActionType } from './avgiftsstatus';

export type LeasingtakerActionType =
    | SakActionType
    | AvgiftsstatusActionType;

interface ILeasingtakerHasErrorAction {
    type: LeasingtakerEndreActionKey.LEASINGTAKER_HAS_ERROR;
    error: IError;
}

const leasingtakerHasError = (error: IError | Record<symbol | number | string, any>): ILeasingtakerHasErrorAction => ({type: LeasingtakerEndreActionKey.LEASINGTAKER_HAS_ERROR, error});

export function opprettLeasingtakerEndreSak(sak: ISak): ThunkResult<Promise<ISak<IRegistreringInformasjon> | IError>, LeasingtakerActionType | ILeasingtakerHasErrorAction> {
    return (dispatch) => {
        return dispatch(opprettSak(createSak(sak), false)).then((sakResponse) => {
            if (sakResponse) {
                if (sakResponse.merknader?.length > 0) {
                    dispatch(leasingtakerHasError(sakResponse));
                    dispatch(nyMelding({
                        meldingIntlId: 'sak.melding.ikkeOpprettet',
                        meldingType: MeldingType.DANGER,
                        varighetMs: NY_SAK_VISNINGSTID_MS
                    }));
                    return sakResponse;
                } else {
                    if (sakResponse.saksstatus === Saksstatus.AVSLUTTET) {
                        dispatch(nyMelding({
                            meldingIntlId: 'sak.melding.avsluttet',
                            meldingType: MeldingType.SUCCESS,
                            varighetMs: NY_SAK_VISNINGSTID_MS
                        }));
                        return sakResponse;
                    }
                }
                dispatch(nyMelding({
                    meldingIntlId: 'sak.melding.ikkeOpprettet',
                    meldingType: MeldingType.DANGER
                }));
                dispatch(leasingtakerHasError({ errorMessage: 'Kunne ikke opprette leasingtaker endre-sak' }));
            }
        });
    };
}

export const createSak = (registreringSak: IRegistreringSak): ISak<IRegistreringInformasjon> => {
    const {
        kuid,
        imid,
        leasingtaker,
        saksType
    } = registreringSak;
    return {
        kuid,
        imid,
        saksType,
        saksinformasjon: {
            saksType,
            leasingtaker
        }
    };
};