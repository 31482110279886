import * as React from 'react';
import styled from 'styled-components';

interface IArbeidslisteCardButtonsProps {
    isOpen: boolean;
    onClose: () => void;
}

const Container = styled.div`
  position: absolute;
  top: -0.6rem;
  left: -0.6rem;
  right: -0.6rem;
  bottom: -0.6rem;
  padding: 0.6rem;
  z-index: 10;
  background-color: rgb(255, 255, 255, 0.95);
  
  &:hover {
    cursor: default;
  }
`;

const ButtonContainer = styled.div`
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
    
    button {
      width: 10rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      height: 2.5rem;
    }
`;

const CloseButton = styled.button`
   position: absolute;
   top: 0.6rem;
   right: 0.6rem;
`;

CloseButton.displayName = 'CloseButton';

const ArbeidslisteCardButtons: React.FC<IArbeidslisteCardButtonsProps & React.PropsWithChildren> = props => {

    if (!props.isOpen) {
        return null;
    }

    const handleOnClick = e => {
        e.stopPropagation();
    };

    return (
        <Container onClick={handleOnClick}>
            <ButtonContainer>
                {props.children}
            </ButtonContainer>
            <CloseButton type="button" className="fa fa-times ak-knapp ak-tilleggsknapp" onClick={props.onClose} />
        </Container>
    );
};

export { ArbeidslisteCardButtons };
