import { AkHeader, AkHeaderLogo } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { useSelector } from 'react-redux';
import type { RootStateType } from '../../models/types';

import { NavigationIcons } from './navigation-icons';
import { NavigationMenu } from './navigation-menu';
import { SearchBar } from './search-bar';
import { UserSelect } from './user-select';

export const Header: React.FC = () => {
    const showNavigationIconsAndSearchBar = useSelector((state: RootStateType) => state.brukerprofil.valgtEnhet);

    return (
        <AkHeader>
            <AkHeaderLogo logoLocation="/" logoLocationIntlDescriptor={{ id: 'toppmeny.link.startside' }}/>
            <NavigationMenu/>
            {showNavigationIconsAndSearchBar &&
                <>
                    <SearchBar/>
                    <NavigationIcons/>
                </>
            }
            <UserSelect/>
        </AkHeader>
    );
};