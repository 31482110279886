import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import './oppgavestatus-style';

interface IOppgavestatusProps {
    oppgavestatus: Oppgavestatus;
    intlId: string;
    aktorer?: number;
    aktorerGodkjent?: number;
    large?: boolean;
}

const OppgavestatusBar: React.FC<IOppgavestatusProps> = props => {
    const {oppgavestatus, aktorer, aktorerGodkjent, large, intlId} = props;
    const statusClass = `ak-progress-${(large ? 'large' : 'small')} ${klasseForStatus(oppgavestatus)} oppgavestatus-mobile-small`;

    return (
        <div className={statusClass}>
            <span><FormattedMessage id={intlId} values={{aktorer, aktorerGodkjent}} /></span>
        </div>
    );
};

const klasseForStatus = (oppgavestatusEnum: Oppgavestatus) => {
    switch (oppgavestatusEnum) {
        case Oppgavestatus.START:
            return 'ak-start-progress';
        case Oppgavestatus.IN_PROGRESS:
            return 'ak-in-progress';
        case Oppgavestatus.END:
            return 'ak-end-progress';
        case Oppgavestatus.ABORTED:
            return 'ak-aborted-progress';
        default:
            return '';
    }
};

enum Oppgavestatus {
    START = 'START',
    IN_PROGRESS = 'IN_PROGRESS',
    ABORTED = 'ABORTED',
    END = 'END'
}

export { OppgavestatusBar, Oppgavestatus };
