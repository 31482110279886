import { KjoringensArtListeActionKey } from '../../constants';
import { AkrConfig } from '../../constants';
import type { IError, IKjoringensArt, ThunkResult } from '../../models/types';
import { createRestResource } from '../store';
import { addGlobalErrors } from './globals';

export type KjoringensArtActionType =
    | IKjoringensArtHasErrorAction
    | IKjoringensArtIsLoadingAction
    | IKjoringensArtFetchDataSuccess
    | IKjoringensArtOtherAction;

export interface IKjoringensArtHasErrorAction {
    type: KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_HAS_ERROR;
    error: IError;
}

export interface IKjoringensArtIsLoadingAction {
    type: KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_IS_LOADING;
    isLoading: boolean;
}

export interface IKjoringensArtFetchDataSuccess {
    type: KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_FETCH_DATA_SUCCESS;
    innslag: IKjoringensArt[];
}

export interface IKjoringensArtOtherAction {
    type: KjoringensArtListeActionKey.OTHER_ACTION;
}

const api = createRestResource(AkrConfig.KJORINGENS_ART_LISTE_RESOURCE_URL);

export const kjoringensartHasError = (error: IError): IKjoringensArtHasErrorAction => ({ type: KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_HAS_ERROR, error });
export const kjoringensartIsLoading = (isLoading: boolean): IKjoringensArtIsLoadingAction => ({ type: KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_IS_LOADING, isLoading });
export const kjoringensartFetchDataSuccess = (kjoringensArt: IKjoringensArt[]): IKjoringensArtFetchDataSuccess => ({
    type: KjoringensArtListeActionKey.KJORINGENS_ART_LISTE_FETCH_DATA_SUCCESS,
    innslag: kjoringensArt
});

export const hentKjoringensArtListe = (kuid?: string, eierKundeId?: string): ThunkResult<Promise<KjoringensArtActionType>, KjoringensArtActionType> => (dispatch) => {
    dispatch(kjoringensartIsLoading(true));
    return api.get(null, { kuid, eierKundeId}).then((response => {
        if (response.errorCode) {
            dispatch(addGlobalErrors(response));
            return dispatch(kjoringensartHasError(response));
        } else {
            dispatch(kjoringensartIsLoading(false));
            return dispatch(kjoringensartFetchDataSuccess(response.content));
        }
    }));
};
