import {VilkarStatus} from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import {FormattedMessage} from 'react-intl';

interface IArbeidslisteDokumentStatusProps {
    rad: VilkarStatus;
}

const ArbeidslisteDokumentstatus: React.FC<IArbeidslisteDokumentStatusProps> = (props) => {
    if (!props.rad || VilkarStatus.IKKE_PAKREVET === props.rad) {
        return <span><FormattedMessage id="startside.arbeidsliste.tomtFelt" /></span>;
    } else if (VilkarStatus.IKKE_OPPFYLT === props.rad) {
        return render('fa-times-circle ak-ikon-feil', 'ikkeOppfylt');
    } else if (VilkarStatus.MA_GODKJENNES === props.rad) {
        return render('ak-ikon-venter-circle', 'maGodkjennes');
    } else {
        return render('fa-check-circle ak-ikon-suksess', 'oppfylt');
    }
};

const render = (ikon: string, overskrift: string) => {
    return (
        <div className="d-inline-flex align-items-center">
            <i className={`fa fa-lg ak-ikon ${ikon}`} aria-hidden="true"/>
            <p className="ak-melding-tekst ml-2 arbeidsliste-card-content-medium">
                <FormattedMessage id={`startside.arbeidsliste.dokumentStatus.${overskrift}`}/>
            </p>
        </div>
    );
};

export {ArbeidslisteDokumentstatus};
