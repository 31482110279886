import { OppslagActionKey } from '../../constants';
import type { IError, IOppslag } from '../../models/types';
import type { OppslagActionType } from '../actions';

const initialState: IOppslag = {
    isLoading: false,
    hasError: false,
    treff: [],
    antallTreff: 0,
    informasjonIkkeTilgjengeligIBransje: false,
    query: '',
    error: null,
    notFound: false
};

export function oppslag(state: IOppslag = initialState, action: OppslagActionType): IOppslag | IError {
    switch (action.type) {
        case OppslagActionKey.OPPSLAG_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case OppslagActionKey.OPPSLAG_FETCH_DATA_SUCCESS:
            return { ...initialState, treff: action.treff, antallTreff: action.antallTreff, informasjonIkkeTilgjengeligIBransje: action.informasjonIkkeTilgjengeligIBransje };
        case OppslagActionKey.OPPSLAG_HAS_ERROR:
            return { ...initialState, hasError: action.hasError };
        case OppslagActionKey.OPPSLAG_HAS_SERVER_ERROR:
            return { ...initialState, hasError: true, error: { ...action.error } };
        case OppslagActionKey.OPPSLAG_RESET_SOKE_FELT:
            return { ...state, query: initialState.query };
        case OppslagActionKey.OPPSLAG_OPPDATER_SOKE_FELT:
            return { ...state, query: action.query };
        default:
            return state;
    }
}
