import { AKRCF_DEFAULT_INTL_MESSAGES } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import type { IntlConfig } from 'react-intl';
import { OversettelserActionKey } from '../../constants';
import type { OversettelserActionType } from '../actions';

import INITIAL_STATE_MESSAGES from '../store/initial-state-messages';

const intlIntitialState: Partial<IntlConfig> = {
    locale: 'nb',
    messages: {...INITIAL_STATE_MESSAGES, ...AKRCF_DEFAULT_INTL_MESSAGES}
};

export function intl(state = intlIntitialState, action: OversettelserActionType): Partial<IntlConfig> {
    if (action.type === OversettelserActionKey.OVERSETTELSER_FETCH_DATA_SUCCESS) {
        const newState = _.merge({}, state);
        return _.merge(newState, { ...action.intlConfig });
    }

    return state;
}
