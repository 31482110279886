export enum RegelmerknaderKontekst {
    AKR_FORHANDLER_EIERSKIFTE = 'akr.forhandler.eierskifte',
    AKR_FORHANDLER_REGISTRERING = 'akr.forhandler.registrering',
    AKR_FORHANDLER_REGISTRERING_ENDRE_KJENNEMERKE = 'akr.regler.registrering.endre.kjennemerke',
    AKR_FORHANDLER_REGISTRERING_SAMME_EIER_OPPRETT = 'akr.forhandler.registreringSammeEier.opprett',
    AKR_FORHANDLER_REGISTRERING_VEDTAK = 'akr.forhandler.registrering.vedtak',
    AKR_REGLER_OMREGISTRERING = 'akr.regler.registrering',
    AKR_REGLER_OMREGISTRERING_OPPRETT = 'akr.regler.registrering.opprett.og.godkjenn.registrering.ny.eier',
    AKR_REGLER_START_REGISTRERING_NY_EIER = 'akr.regler.registrering.start.registrering.ny.eier',
    AKR_REGLER_PAREGISTRERING_OPPRETT = 'akr.regler.registrering.opprett.og.godkjenn.registrering.samme.eier',
    AKR_REGLER_START_REGISTRERING_SAMME_EIER = 'akr.regler.registrering.start.registrering.samme.eier',
    AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_NY_EIER = 'akr.regler.registrering.ma.fullfore.registrering.ny.eier.pa.trafikkstasjon',
    AKR_MA_FULLFORE_VED_TRAFIKKSTASJON_SAMME_EIER = 'akr.regler.registrering.ma.fullfore.registrering.samme.eier.pa.trafikkstasjon',
    AKR_FORHANDLER_KUNDE = 'akr.forhandler.kunde',
    AKR_FORHANDLER_TILGANG = 'akr.forhandler.tilgang',
    KAN_FATTE_VEDTAK_FOR_FORSTEGANGSREGISTRERING = 'akr.regler.registrering.kan.fatte.vedtak.for.forstegangsregistrering',
    AKR_FORHANDLER_START_FORSTEGANGSREGISTRERING = 'akr.regler.registrering.start.forstegangreg',
    AKR_FORHANDLER_START_EIERSKIFTE = 'akr.regler.eierskifte.start.start.eierskifte'
}
