import type { ThunkAction } from 'redux-thunk';
import { MeldingType, SaksType } from '../../models/kodeverk';
import type { IKjoretoy, ISak, ISaksinformasjon, RootStateType } from '../../models/types';
import { kjoretoyGetDataFromKuidAction } from './kjoretoy';
import type { IMeldingBarNyMeldingAction} from './melding-bar';
import { nyMelding } from './melding-bar';
import type { SakActionType } from './sak';
import { opprettSak } from './sak';

export function utleverKjennemerkerUtenRegistreringAction(kjoretoy: IKjoretoy): ThunkAction<void, RootStateType, undefined, SakActionType | IMeldingBarNyMeldingAction> {
    return dispatch => {
        return dispatch(opprettSak(createOpprettSak(kjoretoy), true)).then((sakResponse: ISak<ISaksinformasjon>) => {
            if (!!sakResponse?.sakId) {
                dispatch(nyMelding({meldingIntlId: 'utlevereUtenRegistrering.melding.gjennomfort', meldingType: MeldingType.SUCCESS}));
                dispatch(kjoretoyGetDataFromKuidAction(kjoretoy.kuid));
            } else {
                dispatch(nyMelding({meldingIntlId: 'utlevereUtenRegistrering.melding.feilet', meldingType: MeldingType.DANGER}));
            }
        });
    };
}

const createOpprettSak = (kjoretoy: IKjoretoy) => {
    return {
        kuid: kjoretoy.kuid,
        saksType: SaksType.KJENNEMERKER_UTLEVERE_UTEN_REGISTRERING
    } as ISak<ISaksinformasjon>;
};