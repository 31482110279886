/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import type { Middleware } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import 'moment/locale/nb';
import { createLogger } from 'redux-logger';
import type { IRootState } from '../../models/types';
import * as defaultReducers from './../reducers';

const rootReducer = (state, action) => ({
    ...combineReducers({ ...defaultReducers })(state, action)
});

const devmode: boolean = process.env.NODE_ENV === 'development' && !!(module as any).hot
    || window && new URLSearchParams(window.location.search).has('devmode');

const logger = createLogger({
    predicate: () => devmode,
    collapsed: (getState, action, logEntry) => !logEntry.error,
    duration: false,
    logErrors: true
});

const setupStore = (middleware: Middleware | Middleware[], preloadedState?: Partial<IRootState>) => configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
                serializableCheck: {
                    ignoredPaths: ['intl']
                }
            }
        )
            .concat([logger].concat(middleware)),
    preloadedState
});

const appStore = (preloadedState?: Partial<IRootState>) => setupStore([], preloadedState);

export type { IRootState };
export { appStore, setupStore };
