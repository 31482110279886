import classNames from 'classnames';
import * as React from 'react';
import type { MouseEventHandler, ReactNode } from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { SortType } from '../../models/kodeverk';

interface IArbeidslisteHeaderCellProps {
    intlKey: string;
    size?: ArbeidslisteCellSize;
    sortProp?: string;
    currentSortProp?: string;
    currentSortDir?: SortType;
    sort?: (sortProp: string) => void;
}

interface IArbeidslisteCellProps {
    size?: ArbeidslisteCellSize;
    link?: string;
    disabled?: boolean;
}

enum ArbeidslisteCellSize {
    HIDDEN = 'hidden',
    XSMALL = 'xsmall',
    SMALL = 'small',
    DEFAULT = 'default',
    MEDIUM = 'medium',
    LARGE = 'large',
    XLARGE = 'xlarge'
}

const ArbeidslisteCell: React.FC<IArbeidslisteCellProps & React.PropsWithChildren> = (props) => {
    const {size, link} = props;
    const cellStyle = classNames(
        'ak-table-cell',
        {'ak-table-row-link': !!link},
        size ? `ak-table-cell-${size}` : '',
        {'ak-disabled-cell': props.disabled}
    );

    if (size === ArbeidslisteCellSize.HIDDEN) {
        return null;
    }

    if (link) {
        return (
            <Link to={link} className={cellStyle}>
                {props.children}
            </Link>
        );
    }

    return (
        <div className={cellStyle}>
            {props.children}
        </div>
    );
};

class ArbeidslisteHeaderCell extends Component<IArbeidslisteHeaderCellProps> {

    public render(): ReactNode {
        const {size, sortProp, intlKey} = this.props;
        const headerStyle = classNames('ak-table-cell', size ? `ak-table-cell-${size}` : '');

        if (size === ArbeidslisteCellSize.HIDDEN) {
            return null;
        }

        return (
            <div className={headerStyle}>
                {sortProp ? (
                    <a href="#" onClick={this.sortHeader}>
                        <FormattedMessage id={intlKey} />
                        {this.sortIcon()}
                    </a>
                ) : <FormattedMessage id={intlKey} />}
            </div>
        );
    }

    private sortHeader: MouseEventHandler<HTMLAnchorElement> = (evt) => {
        evt.preventDefault();
        const {sortProp, sort} = this.props;
        sort(sortProp);
    };

    private sortIcon(): ReactNode {
        const {sortProp, currentSortProp, currentSortDir} = this.props;

        if (!currentSortDir || sortProp !== currentSortProp) {
            return null;
        }

        if (currentSortDir === SortType.DESC) {
            return <i className="fa fa-fw fa-lg fa-angle-down" />;
        } else {
            return <i className="fa fa-fw fa-lg fa-angle-up" />;
        }
    }
}

export { ArbeidslisteCellSize, ArbeidslisteCell, ArbeidslisteHeaderCell };
