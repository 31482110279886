import type { ThunkAction } from 'redux-thunk';
import { EierskifteArkivdokumentActionKey } from '../../constants';
import { MeldingType } from '../../models/kodeverk';
import type { IArkivdokument, IError, RootStateType } from '../../models/types';
import { nyMelding } from './melding-bar';
import type { SakActionType} from './sak';
import { deleteDocument, editDocument, hentSak, sakUpdateMerknaderAction, uploadDocument } from './sak';

export type EierskifteArkivdokumenterActionType =
    IEierskifteArkivdokumenterAddAction
    | IEierskifteArkivdokumenterDeleteAction
    | IEierskifteArkivdokumenterEditAction
    | IEierskifteArkivdokumenterLoadingAction
    | IEierskifteArkivdokumenterSuccessAction;

export interface IEierskifteArkivdokumenterAddAction {
    type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENT_ADD;
    arkivdokument: IArkivdokument;
}

export interface IEierskifteArkivdokumenterDeleteAction {
    type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENT_DELETE;
    id: number;
}

export interface IEierskifteArkivdokumenterEditAction {
    type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENT_EDIT;
    arkivdokument: IArkivdokument;
}

export interface IEierskifteArkivdokumenterLoadingAction {
    type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENT_LOADING;
    isLoading: boolean;
}

export interface IEierskifteArkivdokumenterSuccessAction {
    type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENTER_SUCCESS;
}

export const EierskifteArkivdokumenterEditAction = (arkivdokument: IArkivdokument): IEierskifteArkivdokumenterEditAction =>
    ({ type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENT_EDIT, arkivdokument });

export const EierskifteArkivdokumenterDeleteAction = (id: number): IEierskifteArkivdokumenterDeleteAction =>
    ({ type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENT_DELETE, id });

export const EierskifteArkivdokumenterLoadingAction = (): IEierskifteArkivdokumenterLoadingAction =>
    ({ type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENT_LOADING, isLoading: true });

export const EierskifteArkivdokumenterSuccessAction = (): IEierskifteArkivdokumenterSuccessAction =>
    ({ type: EierskifteArkivdokumentActionKey.EIERSKIFTE_ARKIVDOKUMENTER_SUCCESS });

export const lastOppEierskifteArkivdokument = (sakId: string, fileWithMeta: IArkivdokument):
    ThunkAction<Promise<any>, RootStateType, void, EierskifteArkivdokumenterActionType> => (dispatch) => {
    dispatch(EierskifteArkivdokumenterLoadingAction);
    return dispatch(uploadDocument(sakId, fileWithMeta)).then(response => {
        if (isError(response)) {
            return response;
        }
        dispatch(hentSak(sakId));
        return dispatch(EierskifteArkivdokumenterSuccessAction());
    });
};

export const slettEierskifteArkivdokument = (sakId: string, dokumentId: number):
    ThunkAction<void, RootStateType, undefined, EierskifteArkivdokumenterActionType | SakActionType> => {
    return dispatch => {
        dispatch(EierskifteArkivdokumenterLoadingAction());

        return deleteDocument(sakId, dokumentId)
            .then((response) => {
                if (response.errorId) {
                    return dispatch(nyMelding({meldingIntlId: 'registrering.validering.dokumentasjonsstatusFeilet', meldingType: MeldingType.DANGER}));
                }

                dispatch(hentSak(sakId));
                dispatch(sakUpdateMerknaderAction(response.merknader, false));
                return dispatch(EierskifteArkivdokumenterDeleteAction(response));
            });
    };
};

export const endreEierskifteArkivdokument = (sakId: string, dokument: IArkivdokument):
    ThunkAction<void, RootStateType, undefined, SakActionType | EierskifteArkivdokumenterActionType> => {
    return dispatch => {
        dispatch(EierskifteArkivdokumenterLoadingAction());

        return editDocument(sakId, dokument)
            .then((response) => {
                if (isError(response)) {
                    return dispatch(nyMelding({meldingIntlId: 'registrering.validering.dokumentasjonsstatusFeilet', meldingType: MeldingType.DANGER}));
                }

                dispatch(hentSak(sakId));
                dispatch(sakUpdateMerknaderAction(response.merknader, false));
                return dispatch(EierskifteArkivdokumenterEditAction(response));
            });
    };
};


const isError = (response): boolean => !!(response as IError).errorId || !!(response as IError).errorCode;
