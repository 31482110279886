import * as _ from 'lodash';
import type { IMessageDescriptor } from 'svv-tk-akr-common-frontend';
import { GodkjenningUtfortType } from 'svv-tk-akr-common-frontend';

import { KjoringensArt, RegelmerknaderKoder, RegelmerknaderKontekst, RegelmerknaderType, Saksstatus } from '../models/kodeverk';
import type { Brukerprofil, IKjoretoy, IMerknad, IRegistreringInformasjon, ISak, ISaksdokument, IVilkar, IVilkarstatus } from '../models/types';
import { BrukerRegler, Merknader, VilkarRegler } from '../regler';
import { vilkarstatusMerknaderOgSakMerknaderSelector } from '../state/selectors';

import {
    erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt, erKlassifisertSomRallyMedAvgiftsfritak, erKlassifisertSomRallyUtenAvgiftsfritak, erKlassifisertSomTrialEnduro,
    mapeSaksdokumenterStatusTilObligatoriskeDokumenter, utledObligatoriskeDokumenter
} from '../utils';

const MERKNADER_SOM_SKAL_VISES_SOM_MELDING: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_PROTOTYPE, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_LAREVOGN, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_UTRYKNING_DOK, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_BEGRAVELSESBIL, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_KLASSIFISERING_AMBULANSE_DOK, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.DIPLOMAT_DOK, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.NATO_DOK, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_ER_RALLY_MED_AVGIFTSFRITAK, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_ER_RALLY_UTEN_AVGIFTSFRITAK, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_HAR_UNNTAK_TRIAL_ENDURO, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.NY_EIER_OG_NY_MEDEIER_IKKE_MYNDIG, type: RegelmerknaderType.INFORMASJON }
];

const MERKNADER_SOM_SKAL_VISES_UAVHENGIG_AV_FATT_VEDTAK: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.UTENLANDSKE_KJENNEMERKER_IKKE_UTFYLT, type: RegelmerknaderType.KRITISK },
    { kode: RegelmerknaderKoder.UTENLANDSK_VOGNKORT_INFO_MANGLER, type: RegelmerknaderType.KRITISK },
    { kode: RegelmerknaderKoder.UTENLANDSK_VOGNKORT_MANGLER, type: RegelmerknaderType.KRITISK }
];

const MERKNADER_SOM_SKAL_VISES_SOM_MELDING_FRA_VILKARSTATUS_MERKNADER: Partial<IMerknad>[] = [
    { kode: RegelmerknaderKoder.KJORETOY_LAREVOGN, type: RegelmerknaderType.INFORMASJON },
    { kode: RegelmerknaderKoder.KJORETOY_TRAKTOR_MED_BRUKSBEGRENSNING}
];

const MAKS_EGENVEKT_FOR_LETT_TILHENGER = 350;
const TEKNISKE_KODER_FOR_LETT_TILHENGER = ['O1', 'R1', 'TT'];

export class ForstegangsregistreringRegler {

    public static kanBehandleForstegangsregistrering(brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): boolean {

        return _.isEmpty(kjoretoy.registrering)
            && !Merknader.exists(kjoretoy, { kode: RegelmerknaderKoder.BRUKER_HAR_IKKE_RETTIGHETER })
            && new BrukerRegler(brukerprofil).harRolleForTilgangTilForstegangsregistrering();

    }

    public static utledVilkar(sak: ISak<IRegistreringInformasjon>, vilkarstatus: IVilkarstatus, saksinformasjon: Partial<IRegistreringInformasjon>): IVilkar[] {
        const vilkar: IVilkar[] = [];
        const { saksstatus } = sak;
        const obligatoriskeDokumenter = utledObligatoriskeDokumenter(vilkarstatusMerknaderOgSakMerknaderSelector({sak, vilkarstatus})) || [];

        if (!ForstegangsregistreringRegler.erAvsluttetAvbrutt(saksstatus) && (vilkarstatus.vilkar || []).length > 0) {
            _.each(vilkarstatus.vilkar, (v: IVilkar) => VilkarRegler.populerVilkarFraVilkarstatus(v, vilkar, saksinformasjon));
        }

        if (saksstatus === Saksstatus.APEN) {
            if (obligatoriskeDokumenter.length && sak.arkivdokumenter) {
                const saksdokumenter: ISaksdokument[] = _.flatMap(sak.arkivdokumenter.map(arkivdokument => arkivdokument.saksdokumenter));
                const saksdokumenterMappingTilobligatoriskeDokumenter = mapeSaksdokumenterStatusTilObligatoriskeDokumenter(saksdokumenter, obligatoriskeDokumenter);
                vilkar.push(VilkarRegler.utledVilkarForDokumenter(saksdokumenterMappingTilobligatoriskeDokumenter));
            } else {
                vilkar.push(VilkarRegler.utledVilkarForDokumenter(obligatoriskeDokumenter));
            }
            vilkar.push(VilkarRegler.utledVilkarForDrosjeloyvenummer(saksinformasjon.kjoringensArt, saksinformasjon.drosjeloyvenummer, vilkar));
        }

        return _.compact(vilkar);
    }

    public static kanFatteVedtakForstegangsregistrering(merknader: IMerknad[]): boolean {
        return !Merknader.exists(merknader, {
            kontekst: RegelmerknaderKontekst.KAN_FATTE_VEDTAK_FOR_FORSTEGANGSREGISTRERING,
            type: RegelmerknaderType.KRITISK
        });
    }

    public static harKjoretoyFortolling(merknader: IMerknad[]): boolean {
        return !Merknader.exists(merknader, {kode: RegelmerknaderKoder.KJORETOY_MANGLER_FORTOLLING});
    }

    public static erKjoretoyStjalet(merknader: IMerknad[]): boolean {
        return Merknader.exists(merknader, {kode: RegelmerknaderKoder.KJORETOY_STJALET});
    }

    public static erKravTilVektarsavgift = (kjoretoy: IKjoretoy): boolean => {
        return Merknader.exists(kjoretoy.merknader, { kode: RegelmerknaderKoder.KJORETOY_KRAV_OM_VEKTARSAVGIFT });
    };

    public static infomerknadSomSkalVisesSomMelding(merknad: IMerknad): boolean {
        return _.some(MERKNADER_SOM_SKAL_VISES_SOM_MELDING, Merknader.filterForMerknad(merknad));
    }

    public static meldingerSomSkalVisesUavhengigAvFattVedtak(merknad: IMerknad): boolean {
        return _.some(MERKNADER_SOM_SKAL_VISES_UAVHENGIG_AV_FATT_VEDTAK, Merknader.filterForMerknad(merknad));
    }

    public static infomerknadSomSkalVisesSomMeldingFraVilkarstatusMerknader(merknad: IMerknad): boolean {
        return  _.some(MERKNADER_SOM_SKAL_VISES_SOM_MELDING_FRA_VILKARSTATUS_MERKNADER, Merknader.filterForMerknad(merknad));
    }

    public static utledKritiskeMeldingerMaFullforesPaTrafikkstasjon =
        (saksinformasjon: Partial<IRegistreringInformasjon>, gyldigeForhandlereSvalbard: string[], brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): IMessageDescriptor[] => {
        const meldinger: IMessageDescriptor[] = [];

        const valgtKjoringensArt = saksinformasjon.kjoringensArt;

        if (valgtKjoringensArt === KjoringensArt.DIPLOMAT.valueOf()) {
            meldinger.push({id: 'forstegangsregistrering.melding.diplomatKjoringensArt'});
        }

        if (valgtKjoringensArt === KjoringensArt.KUN_SVALBARD.valueOf()
            && !gyldigeForhandlereSvalbard.includes(brukerprofil.valgtEnhet.hovedenhetOrgNummer)
            && !gyldigeForhandlereSvalbard.includes(brukerprofil.valgtEnhet.underenhetOrgNummer)) {
            meldinger.push({id: 'forstegangsregistrering.melding.svalbardKjoringensArt'});
        }

        if (valgtKjoringensArt === KjoringensArt.FORSVARET.valueOf()) {
            meldinger.push({id: 'forstegangsregistrering.melding.forsvaretKjoringensArt'});
        }

        if (erKlassifisertSomRallyMedAvgiftsfritak(kjoretoy) || erKlassifisertSomRallyUtenAvgiftsfritak(kjoretoy) || erKlassifisertSomTrialEnduro(kjoretoy)) {
            meldinger.push({id: 'forstegangsregistrering.melding.motorsportKjoringensArt'});
        }

        return meldinger;
    };

    public static utledUgyldigKjoringensArtFeilmeldinger(kjoringensArt: string, kjoretoy: IKjoretoy): string[] {
        const feilmeldinger: string[] = [];

        if (!kjoringensArt) {
            feilmeldinger.push('sak.validering.kjoringensArtErPakrevet');
        }

        if (!erKlassifisertSomFunksjonshemmedeTransportMedLoyveplikt(kjoretoy) && kjoringensArt === KjoringensArt.SPESINNR_HCP_LP.valueOf()) {
            feilmeldinger.push('forstegangsregistrering.valider.kjoringensArtFunksjonshemmedeTransportManglerTilleggsgodkjenning');
        }
        if ((erKlassifisertSomRallyMedAvgiftsfritak(kjoretoy) || erKlassifisertSomTrialEnduro(kjoretoy)) && kjoringensArt !== KjoringensArt.MOTORSPORT.valueOf()) {
            feilmeldinger.push('forstegangsregistrering.valider.kjoringensArtMotorsportIkkeSatt');
        }
        if (!(erKlassifisertSomRallyMedAvgiftsfritak(kjoretoy) || erKlassifisertSomRallyUtenAvgiftsfritak(kjoretoy) || erKlassifisertSomTrialEnduro(kjoretoy))
            && kjoringensArt === KjoringensArt.MOTORSPORT.valueOf()) {
            feilmeldinger.push('forstegangsregistrering.valider.kjoringensArtMotorsportManglerTekniskGodkjenning');
        }

        return feilmeldinger;
    }

    public static erLettKjoretoy = (kjoretoy: IKjoretoy): boolean => {
        return kjoretoy?.godkjenning?.egenvekt <= MAKS_EGENVEKT_FOR_LETT_TILHENGER && TEKNISKE_KODER_FOR_LETT_TILHENGER.includes(kjoretoy?.godkjenning?.tekniskKode);
    };

    public static utledGyldigeSignaturtyper = (): GodkjenningUtfortType[] => {
        return [GodkjenningUtfortType.IKKE_SIGNERT, GodkjenningUtfortType.SIGNATUR_PA_SAMTYKKESKJEMA];
    };

    private static erAvsluttetAvbrutt = (saksstatus: Saksstatus) => saksstatus === Saksstatus.AVSLUTTET || saksstatus === Saksstatus.AVBRUTT;
}
