import type { Action } from 'redux';
import { AkrConfig } from '../../constants';
import type { IFlyttkjoretoy, ThunkResult } from '../../models/types';
import { createRestResource } from '../store';

const api = createRestResource(AkrConfig.FLYTTKJORETOY_RESOURCE_URL);

export function createFlyttkjoretoy(flyttKjoretoy: IFlyttkjoretoy): ThunkResult<Promise<void>, Action> {
    return () => {
        return api.post(flyttKjoretoy)
            .then(response => {
                if (response.errorCode || !response.sakId) {
                    return Promise.reject(flyttKjoretoy.kjennemerke);
                } else {
                    return Promise.resolve(response.kuid);
                }
            });
    };
}
