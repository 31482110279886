import { EgneKjoretoyActionKey } from '../../constants';
import type { EgneKjoretoyResponse } from '../../models/types';
import type { EgneKjoretoyActionType } from '../actions';

const initialState = {
    isLoading: false,
    notFound: false,
    hasError: null,
    hovedenhetKundeId: null,
    underenhetKundeId: null,
    content: []
} as EgneKjoretoyResponse;

export function egneKjoretoy(state = initialState, action: EgneKjoretoyActionType): EgneKjoretoyResponse {
    switch (action.type) {
        case EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_KUNDE_START:
            return {...state, isLoading: true};
        case EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: action.egneKjoretoy.content,
                totalCount: action.egneKjoretoy.totalCount,
                error: action.egneKjoretoy.error
            };
        case EgneKjoretoyActionKey.HENT_EGNE_KJORETOY_ERROR:
            return {...state, isLoading: false, error: action.error};
        default:
            return state;
    }
}
