import * as _ from 'lodash';
import type { ThunkAction } from 'redux-thunk';

import { RegistreringArkivdokumentActionKey } from '../../constants';
import { AvsluttSakIndikator, MeldingType } from '../../models/kodeverk';
import type { IArkivdokument, IError, IObligatoriskDokument, IRegistreringSak, ISaksdokument, RootStateType, ThunkResult } from '../../models/types';
import { harAvvistDokumentTilKontrollpanel, utledObligatoriskeDokumenter } from '../../utils';
import { utledAvgiftsstatus } from './avgiftsstatus';
import { nyMelding } from './melding-bar';
import type { RegistreringerActionType} from './registreringer';
import { createSak, opprettRegistreringSak } from './registreringer';
import { deleteDocument, editDocument, hentDokumentasjonsstatus, uploadDocument } from './sak';

interface IRegistreringArkivDokLoadingAction {
    type: RegistreringArkivdokumentActionKey.REGISTRERING_DOK_LOADING;
    isLoading: boolean;
}

interface IRegistreringArkivDokAddAction {
    type: RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_ADD;
    arkivdokument: IArkivdokument;
}

interface IRegistreringArkivDokEditAction {
    type: RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_EDIT;
    arkivdokument: IArkivdokument;
}

interface IRegistreringArkivDokDeleteAction {
    type: RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_DELETE;
    id: number;
}

interface IRegistreringDokStatusAction {
    type: RegistreringArkivdokumentActionKey.REGISTRERING_DOK_STATUS;
    obligatoriskeDokumenter: IObligatoriskDokument[];
    isLoading: boolean;
}

interface IRegistreringArkivDokErrorAction {
    type: RegistreringArkivdokumentActionKey.REGISTRERING_DOK_STATUS_HAS_ERROR;
    error: IError;
}

interface IRegistreringerArkivDokOtherAction {
    type: RegistreringArkivdokumentActionKey.OTHER_ACTION;
}

export type RegistreringArkivDokActionType =
    IRegistreringArkivDokLoadingAction
    | IRegistreringArkivDokAddAction
    | IRegistreringArkivDokEditAction
    | IRegistreringArkivDokDeleteAction
    | IRegistreringDokStatusAction
    | IRegistreringArkivDokErrorAction
    | IRegistreringerArkivDokOtherAction;

const registreringArkivDokAddAction = (arkivdokument: IArkivdokument): IRegistreringArkivDokAddAction => ({
    type: RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_ADD,
    arkivdokument
});

const registreringArkivDokEditAction = (arkivdokument: IArkivdokument): IRegistreringArkivDokEditAction => ({
    type: RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_EDIT,
    arkivdokument
});

const registreringArkivDokDeleteAction = (id: number): IRegistreringArkivDokDeleteAction => ({
    type: RegistreringArkivdokumentActionKey.REGISTRERING_ARKIVDOKUMENT_DELETE,
    id
});

const registreringDokStatusAction = (obligatoriskeDokumenter: IObligatoriskDokument[], isLoading: boolean): IRegistreringDokStatusAction => ({
    type: RegistreringArkivdokumentActionKey.REGISTRERING_DOK_STATUS,
    obligatoriskeDokumenter,
    isLoading
});

const registreringDokStatusErrorAction = (error: IError): IRegistreringArkivDokErrorAction => ({
    type: RegistreringArkivdokumentActionKey.REGISTRERING_DOK_STATUS_HAS_ERROR,
    error
});

const registreringArkivDokUnchangedAction = (): IRegistreringerArkivDokOtherAction => ({
    type: RegistreringArkivdokumentActionKey.OTHER_ACTION
});

export const opprettRegistreringSakVedDokumentopplasting = (sak: IRegistreringSak): ThunkResult<Promise<IRegistreringSak | string>,  RegistreringerActionType> => dispatch => {
    return dispatch(opprettRegistreringSak(sak, AvsluttSakIndikator.IKKE_AVSLUTT)).then((opprettetSak) => {
        if (opprettetSak.sakId) {
            dispatch(utledAvgiftsstatus(null));
            return opprettetSak.sakId;
        }

        return Promise.reject();
    });
};

export const hentDokumentasjonsstatusRegistrering = (): ThunkAction<void, RootStateType, void, RegistreringArkivDokActionType> => (dispatch, getState) => {
    const {registreringSak} = getState();
    if (registreringSak.saksType && registreringSak.kjoringensArt) {
        dispatch(registreringDokStatusAction(registreringSak.obligatoriskeDokumenter, true));
        return dispatch(hentDokumentasjonsstatus(createSak(registreringSak, AvsluttSakIndikator.IKKE_AVSLUTT)))
            .then(response => {
                if (isError(response)) {
                    dispatch(nyMelding({meldingIntlId: 'registrering.validering.dokumentasjonsstatusFeilet', meldingType: MeldingType.DANGER}));
                    return dispatch(registreringDokStatusErrorAction(response));
                } else {
                    return dispatch(registreringDokStatusAction(utledObligatoriskeDokumenter(response), false));
                }
            });
    } else {
        return null;
    }
};

export const uploadDocumentRegistrering = (sakId: string, fileWithMeta: IArkivdokument): ThunkAction<Promise<any>, RootStateType, void, RegistreringArkivDokActionType> => (dispatch) => {
    return dispatch(uploadDocument(sakId, fileWithMeta)).then(response => {
            if (!isError(response)) {
                dispatch(registreringArkivDokAddAction(response));
                return dispatch(hentDokumentasjonsstatusRegistrering());
            }
            return response;
        });
};

export function deleteDocumentRegistrering(sakId: string, dokumentId: number): ThunkAction<void, RootStateType, undefined, RegistreringArkivDokActionType> {
    return dispatch => deleteDocument(sakId, dokumentId).then((response) => {
        if (!isError(response)) {
            dispatch(registreringArkivDokDeleteAction(dokumentId));
            dispatch(hentDokumentasjonsstatusRegistrering());
        }
        return response;
    });
}

export function editDocumentRegistrering(sakId: string, dokument: IArkivdokument): ThunkResult<Promise<IArkivdokument>, RegistreringArkivDokActionType> {
    return dispatch => editDocument(sakId, dokument).then((response) => {
        if (!isError(response)) {
            dispatch(registreringArkivDokEditAction(response));
            dispatch(hentDokumentasjonsstatusRegistrering());
        }

        return response;
    });
}

function endreDokumentUtenAtHenteStatus(sakId: string, dokument: IArkivdokument): ThunkResult<Promise<IArkivdokument>, RegistreringArkivDokActionType> {
    return dispatch => editDocument(sakId, dokument).then((response) => {
        if (!isError(response)) {
            dispatch(registreringArkivDokEditAction(response));
        }
        return response;
    });
}

export function endreDokumentSendTilSaksbehandlingRegistrering(sakId: string, arkivdokumenter: IArkivdokument[]): ThunkResult<void, RegistreringArkivDokActionType> {
    return dispatch => {
        _.forEach(arkivdokumenter, arkivdokument => {
            const saksdokumenterFjernetAvvistArsak = [] as ISaksdokument[];
            _.forEach(arkivdokument.saksdokumenter, saksdokument => {
                if (harAvvistDokumentTilKontrollpanel(saksdokument)) {
                    const oppdatertSakdok = {...saksdokument, avvistArsak: null};
                    saksdokumenterFjernetAvvistArsak.push(oppdatertSakdok);
                }
            });
            if (!_.isEmpty(saksdokumenterFjernetAvvistArsak)) {
                const oppdatertAkrivdokument = {
                    ...arkivdokument,
                    saksdokumenter:
                        _.concat(_.reject(arkivdokument.saksdokumenter, (saksdokument: ISaksdokument) => !_.isEmpty(saksdokument.avvistArsak)),
                            saksdokumenterFjernetAvvistArsak)
                };
                return dispatch(endreDokumentUtenAtHenteStatus(sakId, oppdatertAkrivdokument));
            } else {
                return dispatch(registreringArkivDokUnchangedAction());
            }
        });
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isError(response: any): response is IError {
    return !!(response as IError).errorCode || !!(response as IError).errorId;
}
