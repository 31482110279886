import { BrukerprofilActionKey } from '../../constants';
import type { Bruker, Brukerprofil } from '../../models/types';
import type { BrukerprofilActionType } from '../actions';

const initialState: Brukerprofil = {
    bruker: {} as Bruker,
    isLoading: true,
    hasError: false
};

export function brukerprofil(state = initialState, action: BrukerprofilActionType): Brukerprofil {
    switch (action.type) {
        case BrukerprofilActionKey.BRUKERPROFIL_HAS_ERROR:
            return {...state, hasError: action.hasError};
        case BrukerprofilActionKey.BRUKERPROFIL_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case BrukerprofilActionKey.BRUKERPROFIL_HAS_SERVER_ERROR:
            return {hasError: true, ...action.error};
        case BrukerprofilActionKey.BRUKERPROFIL_MANGLER_ROLLER:
            return {...{}, ...action.brukerprofil, hasError: true};
        case BrukerprofilActionKey.BRUKERPROFIL_FETCH_DATA_SUCCESS:
            return {...{ isLoading: state.isLoading }, ...action.brukerprofil};
        default:
            return state;
    }
}
