import {AktorType} from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import type {ThunkAction, ThunkDispatch} from 'redux-thunk';

import type { RegistreringKundeType} from '../../constants';
import {AkrConfig, KundeActionKey} from '../../constants';
import type { SaksType} from '../../models/kodeverk';
import {MeldingType} from '../../models/kodeverk';
import type {
    IAktor,
    IError,
    IGetKundeMedKundeId,
    IGetKundeMedOrgFnr,
    IKunde,
    IKunder,
    IOrganisasjonResponse,
    RootStateType
} from '../../models/types';

import {createRestResource} from '../store';
import type {IMeldingBarNyMeldingAction} from './melding-bar';
import { nyMelding} from './melding-bar';


export type KundeActionType = IHentKundeStartAction | IKundeLoadingAction | IKundeNotFoundAction | IKundeNotShowAction | IKundeHasErrorAction
    | IKundeFetchDataSuccessAction | IKundeForhandlerAction | IKundeRemoveAction | IKundeOtherAction;


export interface IKundeLoadingAction extends IKundeBaseAction {
    type: KundeActionKey.KUNDE_LOADING;
    isLoading: boolean;
}

export interface IKundeNotFoundAction extends IKundeBaseAction {
    type: KundeActionKey.KUNDE_NOT_FOUND;
}

export interface IKundeNotShowAction extends IKundeBaseAction {
    type: KundeActionKey.KUNDE_NOT_SHOW;
    kunde: IKunde;
}

export interface IKundeHasErrorAction extends IKundeBaseAction {
    type: KundeActionKey.KUNDE_HAS_ERROR;
    error: any;
}

export interface IKundeFetchDataSuccessAction extends IKundeBaseAction {
    type: KundeActionKey.KUNDE_FETCH_DATA_SUCCESS;
    kunde: IKunde;
}

export interface IAvdelingerLoadingAction {
    type: KundeActionKey.KUNDE_LOADING;
    isLoading: boolean;
}

export interface IKundeForhandlerAction extends IKundeBaseAction {
    type: KundeActionKey.KUNDE_FORHANDLER;
    forhandler: boolean;
}

export interface IKundeOtherAction extends IKundeBaseAction {
    type: KundeActionKey.OTHER_ACTION;
}

export interface IKundeRemoveAction extends IKundeBaseAction {
    type: KundeActionKey.KUNDE_REMOVE;
}

interface IKundeBaseAction {
    nummer: string;
}

interface IHentKundeStartAction extends IKundeBaseAction {
    type: KundeActionKey.HENT_KUNDE_START;
    isLoading: boolean;
}
const kundeIsLoading = (nummer: string, isLoading: boolean): IKundeLoadingAction => ({type: KundeActionKey.KUNDE_LOADING, nummer, isLoading});
const kundeNotFound = (nummer: string): IKundeNotFoundAction => ({type: KundeActionKey.KUNDE_NOT_FOUND, nummer});
const kundeNotShow = (nummer: string, kunde: IKunde): IKundeNotShowAction => ({type: KundeActionKey.KUNDE_NOT_SHOW, nummer, kunde});
const kundeHasError = (nummer: string, error: IError): IKundeHasErrorAction => ({type: KundeActionKey.KUNDE_HAS_ERROR, nummer, error});
const kundeFetchDataSuccess = (nummer: string, kunde: IKunde): IKundeFetchDataSuccessAction => ({type: KundeActionKey.KUNDE_FETCH_DATA_SUCCESS, nummer, kunde});
const kundeIsForhandler = (nummer: string, forhandler: boolean): IKundeForhandlerAction => ({type: KundeActionKey.KUNDE_FORHANDLER, nummer, forhandler});
export const kundeRemove = (nummer: string): IKundeRemoveAction => ({type: KundeActionKey.KUNDE_REMOVE, nummer});


const orgApi = createRestResource(`${AkrConfig.ORGANISASJONER_RESOURCE_URL}/forhandler`);
const kundeAktorApi = createRestResource(`${AkrConfig.KUNDE_RESOURCE_URL}/aktor`);
const kundeDifiApi = createRestResource(`${AkrConfig.KUNDE_RESOURCE_URL}/difi`);
const kundeApi = createRestResource(AkrConfig.KUNDE_RESOURCE_URL);

export function getKundeMedForhandlerInfo(id: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktortype: AktorType = undefined, kuid: string = undefined):
    ThunkAction<Promise<void | KundeActionType>, RootStateType, undefined, IKundeForhandlerAction> {
    return (dispatch, getState) => {
        if (isGetKundeMedOrgFnr(id) && id.orgFnr.match(/^\d{9}$/) && _.includes([AktorType.NY_EIER, AktorType.EIER], aktortype)) {
            return dispatch(getKunde(id, aktortype, kuid))
                .then((kundeResponse: IKundeFetchDataSuccessAction) => {
                    if (!("error" in kundeResponse) && !!kundeResponse.kunde && shouldGetForhandlerInfo(getState(), id.orgFnr)) {
                        const { juridiskEnhetNummer } = kundeResponse.kunde;
                        return orgApi
                            .get(juridiskEnhetNummer ? juridiskEnhetNummer : id.orgFnr)
                            .then((response: IOrganisasjonResponse) => {
                                return dispatch(kundeIsForhandler(id.orgFnr, response.forhandler));
                            });
                    } else {
                        return Promise.resolve();
                    }
                });
        } else {
            return dispatch(getKundeMedDifiKontaktinformasjon(id, aktortype, kuid));
        }
    };
}

function handleGetKundeResponse(dispatch: ThunkDispatch<RootStateType, undefined, KundeActionType | IMeldingBarNyMeldingAction>, response: IKunde & IError, nummer: string): KundeActionType {
    if (!_.isEmpty(response)) {
        if (response.ikkeVisKunde) {
            return dispatch(kundeNotShow(nummer, response));
        }
        if (response.errorCode) {
            dispatch(nyMelding({
                meldingIntlId: 'feilhandtering.kunde.ikkeSvar',
                meldingType: MeldingType.DANGER
            }));
            return dispatch(kundeHasError(nummer, response));
        } else {
            return dispatch(kundeFetchDataSuccess(nummer, response));
        }
    } else {
        return dispatch(kundeNotFound(nummer));
    }
}

export function getKunde(id: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktortype?: AktorType | RegistreringKundeType, kuid?: string, sakstype?: SaksType):
    ThunkAction<Promise<KundeActionType>, RootStateType, undefined, KundeActionType> {
    const nummer = getNummer(id);
    return (dispatch, getState) => {
        const kunder = getState().kunder;
        if (skalHenteKundeFraState(kunder, nummer)) {
            return Promise.resolve(dispatch(kundeFetchDataSuccess(nummer, _.get(kunder, nummer))));
        }

        dispatch(kundeIsLoading(nummer, true));

        const api = isGetKundeMedOrgFnr(id) ? kundeAktorApi : kundeApi;
        const queryId = isGetKundeMedOrgFnr(id) ? id.orgFnr : id.kundeId;

        return api
            .get(queryId, {aktortype, kuid, sakstype})
            .then(response => {
                return handleGetKundeResponse(dispatch, response, nummer);
            })
            .catch(err => {
                dispatch(kundeIsLoading(nummer, false));
                return dispatch(kundeHasError(nummer, err));
            });
    };
}

export function getKundeMedDifiKontaktinformasjon(id: IGetKundeMedKundeId | IGetKundeMedOrgFnr, aktortype?: AktorType | RegistreringKundeType, kuid?: string, sakstype?: SaksType):
    ThunkAction<Promise<void | IKundeFetchDataSuccessAction | IKundeHasErrorAction>, RootStateType, undefined, KundeActionType> {
    const nummer = getNummer(id);
    return (dispatch, getState) => {
        const kunder = getState().kunder;
        if (skalHenteKundeFraState(kunder, nummer)) {
            return Promise.resolve(dispatch(kundeFetchDataSuccess(nummer, _.get(kunder, nummer))));
        }

        dispatch(kundeIsLoading(nummer, true));

        const api = isGetKundeMedOrgFnr(id) ? kundeAktorApi : kundeDifiApi;
        const queryId = isGetKundeMedOrgFnr(id) ? id.orgFnr : id.kundeId;

        return api
            .get(queryId, {aktortype, kuid, sakstype})
            .then(response => {
                handleGetKundeResponse(dispatch, response, nummer);

            })
            .catch(err => {
                dispatch(kundeIsLoading(nummer, false));
                return dispatch(kundeHasError(nummer, err));
            });
    };
}

export function populateKunderStoreWithAktorer(aktorer: IAktor[], kuid?: string, sakstype?: SaksType): ThunkAction<Promise<any[]>, RootStateType, undefined, undefined> {
    return (dispatch, getState) => {
        const aktorPromises = [];
        _.each(aktorer, (aktor: IAktor) => {
            if (aktor.kundeId && !_.get(getState().kunder.kunderByKundeId, aktor.kundeId)) {
                aktorPromises.push(dispatch(getKunde({ kundeId: aktor.kundeId }, aktor.aktorType, kuid, sakstype)));
            }
        });
        return Promise.all(aktorPromises);
    };
}

const shouldGetForhandlerInfo = (state: RootStateType, id: string): boolean => {
    return !(state.kunder && state.kunder[id] && Object.prototype.hasOwnProperty.call(state.kunder[id], 'forhandler'));
};

const isGetKundeMedOrgFnr = (arg: IGetKundeMedKundeId | IGetKundeMedOrgFnr): arg is IGetKundeMedOrgFnr => {
    return (arg as IGetKundeMedOrgFnr).orgFnr !== undefined;
};

const getNummer = (id: IGetKundeMedKundeId | IGetKundeMedOrgFnr): string => {
    return isGetKundeMedOrgFnr(id) ? id.orgFnr : id.kundeId;
};

const skalHenteKundeFraState = (kunder: IKunder, queryId: string): boolean => {
    const kunde = _.get(kunder, queryId);
    return kunde && !!kunde.navn;
};

