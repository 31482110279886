import { AkrConfig, AvgiftsstatusActionKey } from '../../constants';
import { MeldingType, Saksstatus, SaksType } from '../../models/kodeverk';
import type { IAvgiftsstatusGrunnlag, IAvgiftsstatusResultat, IError, IGodkjenning, IRegistreringInformasjon, IRegistreringSak, ISak, RootStateType, ThunkResult } from '../../models/types';
import { createRestResource } from '../store';
import { nyMelding } from './melding-bar';

const avgiftApi = createRestResource(AkrConfig.AVGIFTSSTATUS_RESOURCE_URL);
const avgiftSkattsesjonApi = createRestResource(AkrConfig.SKATTSESJON_RESOURCE_URL);

export type AvgiftsstatusActionType = IUtledAvgiftsstatusStartAction
    | IUtledAvgiftsstatusSuccessAction
    | IUtledAvgiftsstatusErrorAction;

interface IUtledAvgiftsstatusStartAction {
    type: AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_START;
}

interface IUtledAvgiftsstatusSuccessAction {
    type: AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_SUCCESS;
    avgiftsstatus: IAvgiftsstatusResultat;
}

interface IUtledAvgiftsstatusErrorAction {
    type: AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_ERROR;
    error: IError;
}

const utledAvgiftsstatusStart = (): IUtledAvgiftsstatusStartAction => ({
    type: AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_START
});

const utledAvgiftsstatusSuccess = (avgiftsstatus: IAvgiftsstatusResultat): IUtledAvgiftsstatusSuccessAction => ({
    type: AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_SUCCESS,
    avgiftsstatus
});

const utledAvgiftsstatusError = (error: IError): IUtledAvgiftsstatusErrorAction => ({
    type: AvgiftsstatusActionKey.UTLED_AVGIFTSSTATUS_ERROR,
    error
});

export const gaTilBetalingSkatt = (betalingsreferanse: string, nyFane = true): ThunkResult<Promise<void>, AvgiftsstatusActionType> => {
    return (dispatch) => {
        return avgiftSkattsesjonApi.get(betalingsreferanse, {navback: encodeURIComponent( window.location.href) }).then((response) => {
            if (response.errorCode) {
               return dispatch(nyMelding({meldingIntlId: 'registrering.feilmelding.skattFeilet', meldingType: MeldingType.DANGER}));
            }

            window.open(response.urlTilSelvbetjeningsPortal, nyFane ? '_blank' : '_self');
        });
    };
};

export function utledAvgiftsstatus(callbackUrl: string): ThunkResult<Promise<void>, AvgiftsstatusActionType> {
    return (dispatch, getState) => {
        const {kjoretoy, registreringSak, sak} = getState();
        if (skalUtledeAvgiftsstatus(kjoretoy.godkjenning, registreringSak, sak)) {
            dispatch(utledAvgiftsstatusStart());

            return avgiftApi.post(lagGrunnlag(getState(), callbackUrl))
                .then((response) => {
                    if (response.errorCode) {
                        dispatch(utledAvgiftsstatusError(response));
                        dispatch(nyMelding({meldingIntlId: 'registrering.feilmelding.skattFeilet', meldingType: MeldingType.DANGER}));
                        return;
                    }

                    dispatch(utledAvgiftsstatusSuccess(response));
                });
        } else {
            return Promise.resolve();
        }
    };
}

const lagGrunnlag = (state: RootStateType, callbackUrl: string): IAvgiftsstatusGrunnlag => {
    let hendelsestype = null;

    const sak = state.registreringSak;
    const godkjenning = state.kjoretoy.godkjenning;

    switch (sak.saksType) {
        case SaksType.REGISTRERING_SAMME_EIER:
            hendelsestype = 'REGISTRERING_SAMME_EIER';
            break;
        case SaksType.REGISTRERING_NY_EIER:
            hendelsestype = 'REGISTRERING_NY_EIER';
            break;
    }

    return {
        kuid: godkjenning.kuid,
        kjoringensArt: sak.kjoringensArt,
        foresporselsType: hendelsestype,
        callbackUrl
    };
};

const skalUtledeAvgiftsstatus = (godkjenning: IGodkjenning, registreringSak: IRegistreringSak, sak: ISak<IRegistreringInformasjon>): boolean => (
    sak
    && !sak.isLoading
    && godkjenning
    && registreringSak
    && registreringSak.saksType
    && registreringSak.kjoringensArt
    && registreringSak.saksstatus !== Saksstatus.AVSLUTTET
    && registreringSak.saksstatus !== Saksstatus.AVBRUTT
    && !registreringSak.avgiftsstatus.isLoading
);
