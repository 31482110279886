export enum SaksType {
    EIERSKIFTE = 'EIERSKIFTE',
    FORSTEGANGSREGISTRERING = 'FORSTEGANGSREGISTRERING',
    MIDLERTIDIG_AVREGISTRERING = 'MIDLERTIDIG_AVREGISTRERING',
    REGISTRERING_NY_EIER = 'REGISTRERING_NY_EIER',
    REGISTRERING_SAMME_EIER = 'REGISTRERING_SAMME_EIER',
    DAGSPROVEKJENNEMERKE = 'DAGSPROVEKJENNEMERKE',
    LEASINGTAKER_ENDRE = 'LEASINGTAKER_ENDRE',
    ERSTATNINGSKJENNEMERKER = 'ERSTATNINGSKJENNEMERKER',
    KJENNEMERKER_UTLEVERE_UTEN_REGISTRERING = 'KJENNEMERKER_UTLEVERE_UTEN_REGISTRERING'
}

export enum Saksstatus {
    APEN = 'APEN',
    AVBRUTT = 'AVBRUTT',
    AVSLUTTET = 'AVSLUTTET'
}

export enum VedtakFattet {
    NEGATIVT_VEDTAK = 'NEGATIVT_VEDTAK',
    POSITIVT_VEDTAK = 'POSITIVT_VEDTAK'
}

export enum AvsluttSakIndikator {
    IKKE_AVSLUTT = 'IKKE_AVSLUTT',
    AVSLUTT_UTEN_VEDTAK = 'AVSLUTT_UTEN_VEDTAK',
    AVSLUTT_MED_VEDTAK = 'AVSLUTT_MED_VEDTAK',
    AVBRYT = 'AVBRYT'
}

export enum Vilkarsnavn {
    DROSJELOYVE = 'sak.vilkar.loyvenummer',
    FORSIKRING = 'sak.vilkar.forsikring',
    DOKUMENTASJON = 'sak.vilkar.dokumentasjon',
    ENDRING_AV_KJENNEMERKE = 'sak.vilkar.kjennemerkeEndring',
    PKK = 'sak.vilkar.pkk',
    AVGIFT= 'sak.vilkar.avgift',
    ARKIVREFERANSE = 'sak.vilkar.referanseTilArkiv',
    KJENNERMERKER_INNLEVERT = 'sak.vilkar.kjennemerkeInnlevert',
    PASKILTINGSGEBYR = 'sak.vilkar.paskiltingsgebyr',
    VOGNKORT_DEL_2= 'sak.vilkar.vognkortDel2',
    KJENNEMERKE = 'sak.vilkar.kjennemerke',
    BRUKTIMPORT = 'sak.vilkar.bruktimport'
}

export enum VilkarType {
    AVGIFT = 'AVGIFT',
    FORSIKRING = 'FORSIKRING',
    KJENNEMERKE = 'KJENNEMERKE',
    BRUKTIMPORT = 'BRUKTIMPORT'
}
