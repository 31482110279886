import { stringify } from 'qs';
import * as React from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { AkLoading, AkTab, AkTabsContainer } from 'svv-tk-akr-common-frontend';

import { ArbeidslisteSakstypefilter } from '../../models/kodeverk';
import type { Brukerprofil, IArbeidslisteState, IKlientKonfigurasjonState, RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';
import { akParseUrlParams } from '../../utils';
import { ArbeidslisteEierskifterOgRegistreringerConnected, ArbeidslisteForstegangsregistreringerConnected } from '../startside';

interface IStartsideProps {
    klientKonfigurasjon: IKlientKonfigurasjonState;
    arbeidslister: IArbeidslisteState;
    kanViseEierRegArbeidsliste: boolean;
    kanViseForstegangsregArbeidsliste: boolean;
    erRolleFlateeier: boolean;
    brukerprofil: Brukerprofil;
}

type StartsidePropsType = IStartsideProps;

function Startside(props: StartsidePropsType) {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = akParseUrlParams(location.search);
        const sakstypefilter = props.kanViseEierRegArbeidsliste
                               ? utledEierskifteSakstypefilter()
                               : ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING;
        const selectedFane = queryParams.fane ? stringify(queryParams.fane) : sakstypefilter;
        if (selectedFane !== queryParams['fane']) {
            navigate(`${location.pathname}?fane=${selectedFane}`, { replace: true });
        }
    }, [props.kanViseEierRegArbeidsliste]);

    const utledEierskifteSakstypefilter = () => {
        return props.erRolleFlateeier
               ? ArbeidslisteSakstypefilter.EIERSKIFTE
               : ArbeidslisteSakstypefilter.EIERSKIFTE_OG_REGISTRERING;
    };

    // Return the JSX element to render
    if (props.brukerprofil.isLoading) {
        return <AkLoading />;
    }

    if (!props.kanViseEierRegArbeidsliste && !props.kanViseForstegangsregArbeidsliste) {
        return null;
    }

    const { eierskifterOgRegistreringer, forstegangsregistreringer } = props.arbeidslister;

    const eierskifteSakstypefilter = utledEierskifteSakstypefilter();

    return (
        <div className="container-fluid ak-startside-container">
            <div className="row mt-3">
                <h1 className="col-8 ml-3">
                    <FormattedMessage id="startside.arbeidsliste.tittel" />
                </h1>
            </div>
            <AkTabsContainer pathname="/startside" tabHeaderClassName="pl-3">
                <AkTab
                    name={eierskifteSakstypefilter}
                    disabled={!props.kanViseEierRegArbeidsliste}
                    faneLabelId={`startside.arbeidsliste.fane.${eierskifteSakstypefilter}`}
                    isLoading={eierskifterOgRegistreringer.isLoading}
                    component={ArbeidslisteEierskifterOgRegistreringerConnected}
                    counterCount={(eierskifterOgRegistreringer.rader || []).length}
                />
                <AkTab
                    name={ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING}
                    disabled={!props.kanViseForstegangsregArbeidsliste}
                    faneLabelId={`startside.arbeidsliste.fane.${ArbeidslisteSakstypefilter.FORSTEGANGSREGISTRERING}`}
                    isLoading={forstegangsregistreringer.isLoading}
                    component={ArbeidslisteForstegangsregistreringerConnected}
                    counterCount={(forstegangsregistreringer.rader || []).length}
                    isDefault={!props.kanViseEierRegArbeidsliste}
                />
            </AkTabsContainer>
        </div>
    );
}

const mapStateToProps = (state: RootStateType): IStartsideProps => ({
    klientKonfigurasjon: state.globals.klientKonfigurasjon,
    arbeidslister: state.arbeidsliste,
    kanViseEierRegArbeidsliste: BrukerRegler.adapter(state).harRolleOmregEierskifteEllerFlateeier(),
    kanViseForstegangsregArbeidsliste: BrukerRegler.adapter(state).harRolleForTilgangTilForstegangsregistrering(),
    erRolleFlateeier: BrukerRegler.adapter(state).harRolleFlateeier(),
    brukerprofil: state.brukerprofil
});

const StartsideConnected = connect(mapStateToProps)(Startside);

export { StartsideConnected };
