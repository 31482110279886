import { compose } from '@reduxjs/toolkit';

import * as React from 'react';
import { Component } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AkModal } from 'svv-tk-akr-common-frontend';

import { RoundedProgressBar } from '../../components';
import { NY_SAK_BULK_VISNINGSTID_MS } from '../../constants';
import { MeldingType } from '../../models/kodeverk';
import type { IFlyttkjoretoy, IMelding, MeldingInnhold } from '../../models/types';
import { createFlyttkjoretoy, nyMelding } from '../../state/actions';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';

interface IFlyttkjoretoyProgressModalProps {
    submitted: boolean;
    flyttinger: IFlyttkjoretoy[];
}

interface IFlyttkjoretoyProgressModalState {
    inProgress: boolean;
    completedCount: number;
    totalCount: number;
    failed: string[];
}

interface IFlyttkjoretoyDispatchProps {
    opprettFlyttkjoretoy: (flyttKjoretoy: IFlyttkjoretoy) => Promise<any>;
    nyMelding: (melding: IMelding) => void;
}

type PropsType = IFlyttkjoretoyDispatchProps & IFlyttkjoretoyProgressModalProps & WrappedComponentProps & WithRouterProps;

class FlyttkjoretoyProgressModal extends Component<PropsType, IFlyttkjoretoyProgressModalState> {

    public state = {
        inProgress: false,
        completedCount: 0,
        totalCount: 0,
        failed: []
    };

    public componentDidUpdate(): void {
        if (this.props.submitted && this.state.totalCount !== this.props.flyttinger.length) {
            this.setState({
                inProgress: true,
                totalCount: this.props.flyttinger.length
            }, this.sendTilGodkjenning);
        }
    }

    public render(): React.ReactElement {
        if (this.state.inProgress) {
            return (
                <AkModal modalClassName="ak-modal-width-auto" showModal={this.state.inProgress}>
                    <RoundedProgressBar completedCount={this.state.completedCount} totalCount={this.state.totalCount}
                        smallText={this.props.intl.formatMessage({id: 'flyttkjoretoy.fremdrift.bulkFlytting'})} />
                </AkModal>
            );
        }

        return null;
    }

    private sendTilGodkjenning = () => {
        const eierskifterPromises = [];
        this.props.flyttinger.forEach((flyttKjoretoy: IFlyttkjoretoy) => {
            eierskifterPromises.push(this.createFlyttkjoretoyRequest(flyttKjoretoy));
        });

        Promise.all(eierskifterPromises).then(this.completeBulk);
    };

    private createFlyttkjoretoyRequest = (flyttKjoretoy: IFlyttkjoretoy) => {
        return new Promise<IFlyttkjoretoyProgressModalState>((resolve) => {
            this.props.opprettFlyttkjoretoy(flyttKjoretoy).then(() => {
                this.setState({
                    completedCount: this.state.completedCount + 1
                }, () => resolve(this.state));
            }, (rejected) => {
                this.setState({
                    completedCount: this.state.completedCount + 1,
                    failed: [rejected, ...this.state.failed]
                }, () => resolve(this.state));
            });
        });
    };

    private completeBulk = () => {
        if (this.state.failed.length) {
            const meldingText: MeldingInnhold = {
                type: 'NESTED',
                tekst: "flyttkjoretoy.melding.sendTilGodkjenningBulk.feilet",
                args: this.state.failed
            };

            this.props.nyMelding({
                meldingText,
                meldingType: MeldingType.DANGER,
                meldingId: 'bulkEierskifteFailed'
            });
        }

        const completedCount = this.state.totalCount - this.state.failed.length;
        if (completedCount > 0) {
            const meldingText: MeldingInnhold = {
                type: 'BASIC',
                tekst: "flyttkjoretoy.melding.sendTilGodkjenningBulk.suksess",
                args: {count: completedCount}
            };
            this.props.nyMelding({
                meldingText,
                meldingType: MeldingType.SUCCESS,
                meldingId: 'bulkEierskifteCompleted',
                varighetMs: NY_SAK_BULK_VISNINGSTID_MS
            });
        }

        const TIMEOUT = 750;

        setTimeout(() => {
            this.setState(
                {inProgress: false},
                () => this.props.navigate('/startside')
            );
        }, TIMEOUT);
    };
}

const mapDispatchToProps = (dispatch): IFlyttkjoretoyDispatchProps => ({
    opprettFlyttkjoretoy: (flyttKjoretoy: IFlyttkjoretoy) => dispatch(createFlyttkjoretoy(flyttKjoretoy)),
    nyMelding: (melding: IMelding) => dispatch(nyMelding(melding))
});

const FlyttkjoretoyProgressModalConnected = compose(
    connect<undefined, IFlyttkjoretoyDispatchProps, IFlyttkjoretoyProgressModalProps>(null, mapDispatchToProps),
    injectIntl,
    withRouter
)(FlyttkjoretoyProgressModal);

export { FlyttkjoretoyProgressModalConnected };
