import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AkKnapp, AkLoading, AkModal, AkModalType } from 'svv-tk-akr-common-frontend';
import type { IArkivdokument, IError } from '../../../models/types';

interface ISlettModalProps {
    getSakId: () => Promise<string>;
    slettArkivdokument?: (sakId: string, dokumentId: number) => Promise<any>;
    filSomSlettes: IArkivdokument;
    setFilSomSlettes: (doc: IArkivdokument | null) => void;
}

const SlettModal: React.FC<ISlettModalProps> = ({ getSakId, slettArkivdokument, filSomSlettes, setFilSomSlettes }) => {
    const [loading, setLoading] = useState(false);
    const [slettFilError, setSlettFilError] = useState(false);

    const slettFil = () => {
        setLoading(true);
        getSakId().then(sakId => {
            return slettArkivdokument(sakId, filSomSlettes.id)?.then((response: IError) => {
                setSlettFilError(!!response.errorCode);
                setLoading(false);
                if (!response.errorCode) {
                    setFilSomSlettes(null);
                }
            });
        });
    };

    return <AkModal showModal={!!filSomSlettes} modalType={AkModalType.WARN} closeModal={() => setFilSomSlettes(null)} title="dokumenterpanel.modal.overskrift.sletting">
        {loading
         ? <AkLoading />
         : <div className="ak-knapperad text-left">
             <AkKnapp customClassName="px-4" action={slettFil} intlKey="dokumenterpanel.knapper.ja" />
             <AkKnapp customClassName="px-4" type="ak-tilleggsknapp" action={() => setFilSomSlettes(null)} intlKey="dokumenterpanel.knapper.nei" />
         </div>}
        {slettFilError && <p className="ak-text-error"><FormattedMessage id="dokumenterpanel.feilmeldinger.slettingFeil" /></p>}
    </AkModal>;
};
export default SlettModal;