import type { Avdeling, IAktorPanelAktor, IKunderMap } from 'svv-tk-akr-common-frontend';
import { AktorGodkjenningstatus, AktorType, ValgtKontaktform, utledDefaultkontaktForPerson, utledMobilnummer } from 'svv-tk-akr-common-frontend';
import * as _ from 'lodash';
import moment from 'moment';

import type { Saksstatus } from '../models/kodeverk';
import { RegelmerknaderKoder, RegelmerknaderType } from '../models/kodeverk';
import type { IAktor, IKunde } from '../models/types';
import { Merknader } from '../regler';
import { erHovedenhetInnlogget } from './akr-enhet';

const MYNDIG_ALDER = 18;
const FODSELSDATO_FORMAT = 'YYYY-MM-DD';

export const harByttetAktor = (nyAktor = {} as IAktor, gammelAktor = {} as IAktor): boolean => {
    if (!nyAktor || !nyAktor.kundeId) {
        return false;
    }
    return nyAktor.kundeId !== gammelAktor.kundeId;
};

export const aktorerHarGodkjent = (aktorer: IAktor[]): boolean => {
    const aktorerIkkeNull = _.compact(aktorer);

    return _.every(aktorerIkkeNull, (aktor) => aktor.godkjenningsstatus === AktorGodkjenningstatus.GODKJENT);
};

export const harSoktOppAktor = (aktor: IAktorPanelAktor): boolean => aktor?.kundeId && (!_.find(aktor.merknader, { type: RegelmerknaderType.KRITISK }) || aktor.panelLesemodus);

export const erUnderenhet = (aktor: IAktor): boolean => aktor?.aktorType === AktorType.UNDERENHET || aktor?.aktorType === AktorType.NY_UNDERENHET;

export const erMedeier = (aktor: IAktor): boolean => aktor?.aktorType === AktorType.MEDEIER || aktor?.aktorType === AktorType.NY_MEDEIER;

export const erAktorMyndig = (aktor: IAktor | IKunde): boolean => moment().diff(moment(aktor.fodselsdato, FODSELSDATO_FORMAT), 'years') >= MYNDIG_ALDER;

export const erKjoper = (aktor: IAktor): boolean => aktor?.aktorType === AktorType.NY_EIER || aktor?.aktorType === AktorType.NY_MEDEIER;

export const finnesKjoperIkkeMyndigMerknadIAktor = (aktor: IAktor): boolean => Merknader.exists(aktor.merknader, { kode: RegelmerknaderKoder.KJOPER_IKKE_MYNDIG });

export const aktorErIkkeMyndigOgInneholderKjoperIkkeMyndigMerknad = (aktor: IAktor): boolean => !erAktorMyndig(aktor) && finnesKjoperIkkeMyndigMerknadIAktor(aktor);

interface ILesemodus {
    panelLesemodus: boolean;
    kanEndreAktor: boolean;
}

export const utledLesemodus = (saksstatus: Saksstatus): ILesemodus => {
    if (saksstatus) {
        return { panelLesemodus: true, kanEndreAktor: false };
    }
    return { panelLesemodus: false, kanEndreAktor: true };
};

export const isInnloggetBrukerIAktorliste = (valgtEnhet: Avdeling, aktorer: IAktor[]): boolean => {
    const enhetKundeId = erHovedenhetInnlogget(valgtEnhet) ? valgtEnhet.hovedenhetKundeId : valgtEnhet.underenhetKundeId;
    return _.some(aktorer, (aktor) => aktor?.kundeId === enhetKundeId);
};

export const getKundeNavn = (kundeId: string, kunder: IKunderMap): string => {
    return kunder[kundeId]?.navn || '';
};

export const formaterKundedata = (kunde: Partial<IKunde>): Partial<IKunde> => {
    return {
        ...kunde,
        mobiltelefon: utledMobilnummer(kunde.mobiltelefon) || kunde.mobiltelefon || ''
    };
};

export function utledSkalBenytteRegistrertKontaktinformasjon(aktor: IAktorPanelAktor): boolean {
    const valgtKontaktform = utledDefaultkontaktForPerson(aktor);
    const visForhandsutfyltMobil = !!aktor.mobiltelefon;
    const visForhandsutfyltEpost = !!aktor.epost;
    return (valgtKontaktform === ValgtKontaktform.EPOST && visForhandsutfyltEpost) || (valgtKontaktform === ValgtKontaktform.MOBIL && visForhandsutfyltMobil);
}
