import { RegelmerknaderKoder, RegelmerknaderKontekst } from '../models/kodeverk';
import type { Brukerprofil, IKjoretoy } from '../models/types';
import { BrukerRegler } from './bruker-regler';
import { KjoretoyRegler } from './kjoretoy-regler';
import { Merknader } from './merknader';

export class FlyttingRegler  {

    public static kanBehandleFlytting(brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): boolean {
        return !new BrukerRegler(brukerprofil).erEierUnderenhet(kjoretoy)
            && this.kanKjoretoyFlyttesIngenApneSaker(kjoretoy)
            && !Merknader.exists(kjoretoy, {
                kode: RegelmerknaderKoder.AVDELING_HAR_IKKE_RETTIGHETER,
                kontekst: RegelmerknaderKontekst.AKR_FORHANDLER_EIERSKIFTE
            });
    }

    public static kanKjoretoyFlyttesIngenApneSaker(kjoretoy: IKjoretoy): boolean {
        return !Merknader.anyExists(kjoretoy.merknader, [{
            kode: RegelmerknaderKoder.FLYTTING_KAN_IKKE_STARTES_PGA_APEN_REGISTRERING,
            kontekst: 'akr.forhandler.flytting'
        }, {
            kode: RegelmerknaderKoder.FLYTTING_KAN_IKKE_STARTES_PGA_APENT_EIERSKIFTE,
            kontekst: 'akr.forhandler.flytting'
        }]);
    }

    public static kjoretoyKanFlyttes(brukerprofil: Brukerprofil, kjoretoy: IKjoretoy): boolean {
        return new KjoretoyRegler(kjoretoy).harKjoretoyRegistrering()
            && new BrukerRegler(brukerprofil).harRolleEierskifte()
            && !Merknader.anyExists(kjoretoy.merknader, [{
            kode: RegelmerknaderKoder.FLYTTING_KAN_IKKE_STARTES,
            kontekst: 'akr.forhandler.flytting'
        }, {
            kode: RegelmerknaderKoder.FLYTTING_IKKE_MULIG,
            kontekst: 'akr.forhandler.flytting'
        }]);
    }
}
