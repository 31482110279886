import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AkNavigationMenu, AkNavItemExternal, AkNavItemInternal, useNavigationController } from 'svv-tk-akr-common-frontend';
import type { RootStateType } from '../../models/types';
import { BrukerRegler } from '../../regler';

import { NavigationIcons } from './navigation-icons';
import { SearchBar } from './search-bar';
import { UserSelect } from './user-select';

function getValgtEnhetId(state: RootStateType) {
    return state.brukerprofil.valgtEnhet?.underenhetOrgNummer !== '000000000' ? state.brukerprofil.valgtEnhet?.underenhetOrgNummer : state.brukerprofil.valgtEnhet?.hovedenhetOrgNummer;
}

export const NavigationMenu: React.FC = () => {

    const isWorkplacesOpen = useSelector((state: RootStateType) => state.hovedmenyEkspandert === 'brukerprofil');
    const harRolleForSok = useSelector((state: RootStateType) => BrukerRegler.adapter(state).harRolleForSok());
    const hasRolleFlateeier = useSelector((state: RootStateType) => BrukerRegler.adapter(state).harRolleFlateeier());

    const hasKjennemerkeRoller = useSelector((state: RootStateType) => BrukerRegler.adapter(state).harKjennemerkeroller());

    const valgtEnhetId = useSelector((state: RootStateType) => getValgtEnhetId(state));
    const kjoreseddelLenke = useSelector((state: RootStateType) => state.globals.klientKonfigurasjon?.linker ? `${state.globals.klientKonfigurasjon?.linker.kjoreseddel}?enhetId=${valgtEnhetId}` : '');
    const kjennemerkeLenke = useSelector((state: RootStateType) => state.globals.klientKonfigurasjon?.linker ? `${state.globals.klientKonfigurasjon?.linker.kjennemerke}?enhetId=${valgtEnhetId}` : '');

    const showSystemvarselNavItem = useSelector((state: RootStateType) => state.systemvarsler.systemvarsler.length > 0);

    const brukerprofil = useSelector((state: RootStateType) => state.brukerprofil);
    const navigationController = useNavigationController(brukerprofil);

    const calculateNavigationMenuBreakpoint = (): number => {
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        // logo, brukerveiledning-ikon og avdelingsvelger
        const initialHeaderElementWidth = 500;
        let breakpoint = initialHeaderElementWidth;

        if (showSystemvarselNavItem) {
            // systemvarsel-ikon
            breakpoint = breakpoint + 50;
        }

        if (harRolleForSok) {
            // kjøretøyliste-lenke + søkefelt
            breakpoint = breakpoint + 524;
        }

        if (!hasRolleFlateeier) {
            // kjøreseddel-lenke
            breakpoint = breakpoint + 140;
        }

        if (hasKjennemerkeRoller) {
            // kjennemerke-lenke
            breakpoint = breakpoint + 150;
        }

        return breakpoint > initialHeaderElementWidth ? breakpoint : undefined;
        /* eslint-enable @typescript-eslint/no-magic-numbers */
    };

    return (
        <AkNavigationMenu orderLastAtBreakpoint={calculateNavigationMenuBreakpoint()} softClose={isWorkplacesOpen} {...navigationController}>
            {navigationController.showNavItems &&
                <>
                    {harRolleForSok &&
                        <SearchBar />
                    }

                    <AkNavItemInternal to="/" tooltip="toppmeny.link.startside">
                        <FormattedMessage id="toppmeny.link.startside" />
                    </AkNavItemInternal>
                    {harRolleForSok &&
                        <AkNavItemInternal to="/kjoretoyliste" tooltip="toppmeny.link.kjoretoyliste">
                            <FormattedMessage id="toppmeny.link.kjoretoyliste" />
                        </AkNavItemInternal>
                    }
                    {!hasRolleFlateeier &&
                        <AkNavItemExternal to={kjoreseddelLenke} tooltip="toppmeny.link.kjoreseddel" newTabOrWindow={true}>
                            <FormattedMessage id="toppmeny.link.kjoreseddel" />
                        </AkNavItemExternal>
                    }
                    {hasKjennemerkeRoller &&
                        <AkNavItemExternal to={kjennemerkeLenke} tooltip="toppmeny.link.kjennemerke">
                            <FormattedMessage id="toppmeny.link.kjennemerke" />
                        </AkNavItemExternal>
                    }

                    <NavigationIcons />
                </>
            }
            <UserSelect />
        </AkNavigationMenu>
    );
};
