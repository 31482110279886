import {AkNavItemIconExternal, AkNavItemIconInternal, AkNavItemIconsGroup} from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import {useSelector} from 'react-redux';
import type {RootStateType} from '../../models/types';
import {Brukerrolle} from '../../models/kodeverk';

export const NavigationIcons: React.FC = () => {
    const showSystemvarselNavItem = useSelector((state: RootStateType) => state.systemvarsler.systemvarsler.length > 0);
    const brukerveiledningLink = useSelector((state: RootStateType) => state.globals.klientKonfigurasjon.linker ? state.globals.klientKonfigurasjon.linker.brukerveiledning: undefined);
    const kjennemerkeLink = useSelector((state: RootStateType) => state.globals.klientKonfigurasjon.linker ? state.globals.klientKonfigurasjon.linker.kjennemerkeKlient: undefined);
    const showInnstillingerNavItem = useSelector((state: RootStateType) => state.brukerprofil.bruker.roller.includes(Brukerrolle.KJSCM));

    if (!showSystemvarselNavItem && !brukerveiledningLink) {
        return null;
    }

    return (
        <AkNavItemIconsGroup>
            {showInnstillingerNavItem &&
                <AkNavItemIconExternal icon="cog" to={`${kjennemerkeLink}#/innstillinger`} tooltip="startside.innstillinger.tooltip" newTabOrWindow={true} />
            }
            {showSystemvarselNavItem &&
                <AkNavItemIconInternal icon="bell" to="/systemvarsler" tooltip="startside.systemvarsler.tooltip"/>
            }
            {brukerveiledningLink &&
                <AkNavItemIconExternal icon="question-circle" to={brukerveiledningLink} tooltip="startside.brukerveiledning.tooltip" newTabOrWindow={true} />
            }
        </AkNavItemIconsGroup>
    );
};