import { compose } from '@reduxjs/toolkit';

import * as _ from 'lodash';
import qs from 'qs';
import * as React from 'react';
import type { ReactNode } from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import type { ThunkDispatch } from 'redux-thunk';
import { css } from 'styled-components';
import type { IAkLoadingProps, RowClickHandler} from 'svv-tk-akr-common-frontend';
import { AkColumn, AkKnapp, AkLoading, AkTable, Skilt } from 'svv-tk-akr-common-frontend';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';

import { IngenTreff } from '../../components';
import type { IOppslag, IOppslagTreff, RootStateType } from '../../models/types';
import type { OppslagActionType } from '../../state/actions';
import { gjorOppslag } from '../../state/actions';

interface IOppslagStateProps {
    oppslag: IOppslag;
}

interface IOppslagDispatchProps {
    gjorOppslag: (query: string, kunUregistrerte?: boolean) => Promise<any>;
}

interface IOppslagState {
    query: string;
    kunUregistrerte: boolean;
    visLoadingTekst: boolean;
}

type PropsType = IOppslagStateProps & IOppslagDispatchProps & WithRouterProps;

const MAKS_ANTALL_TREFF = 50;
const MS_FOR_LOADING_TEXT = 1000;

const rowGridTemplate = css`
    grid-template-columns:
        minmax(150px, 1fr)
        minmax(170px, 1fr)
        minmax(150px, 2fr)
        minmax(200px, 3fr)
        minmax(100px, 1fr)
        minmax(100px, 1fr)
        minmax(175px, 1fr)
        minmax(150px, 1fr);
`;

class Oppslag extends Component<PropsType, IOppslagState> {

    public static getDerivedStateFromProps(nextProps: PropsType, prevState: IOppslagState): IOppslagState {
        const params = qs.parse(nextProps.location.search.substring(1));
        return { ...prevState, query: _.toString(params.q) || '', kunUregistrerte: params.kunUregistrerte === 'true' };
    }

    private static utledKuid = (treff: IOppslagTreff) => (treff.kuid);

    private static utledKjennemerke = (treff: IOppslagTreff): ReactNode => (treff.kjennemerke && <Skilt kjennemerke={treff.kjennemerke} kjennemerketype={treff.kjennemerketype} />);

    private static utledUnderstellsnummer = (treff: IOppslagTreff) => <p title={treff.understellsnummer}>{treff.understellsnummer}</p>;

    private static utledMerke = (treff: IOppslagTreff) => <p title={treff.merke || ''}>{treff.merke}</p>;

    private static utledHandelsbetegnelse = (treff: IOppslagTreff) => <p>{treff.handelsbetegnelse}</p>;

    private static utledRegistreringAr = (treff: IOppslagTreff) => <p title={treff.registreringsar}>{treff.registreringsar}</p>;

    private static utledKarroserifarge = (treff: IOppslagTreff) => <p title={treff.karosserifarge}>{treff.karosserifarge}</p>;

    private static utledKjoretoyklassifisering = (treff: IOppslagTreff) => <p>{treff.kjoretoyklassifisering}</p>;

    private static utledRegistreringsstatus = (treff: IOppslagTreff): ReactNode => (
        (
            <p>
                <FormattedMessage id={`kodeverk.registreringsstatus.${treff.registreringsstatus || 'UREGISTRERT'}`} />
            </p>
        )
    );

    public state: IOppslagState = {
        query: '',
        kunUregistrerte: false,
        visLoadingTekst: false
    };

    public componentDidMount() {
        if (!this.props.oppslag.isLoading) {
            this.loadData();
        }
        setTimeout(() => this.setState({ visLoadingTekst: true }), MS_FOR_LOADING_TEXT);
    }

    public render(): ReactNode {
        const oppslag = this.props.oppslag;

        if (oppslag.isLoading) {
            const loadingProps: IAkLoadingProps = {
                displayTextKeyLarge: this.state.visLoadingTekst && 'generell.loading.henter-flere-kjoretoy',
                displayTextKey: this.state.visLoadingTekst && 'generell.loading.kan-ta-tid'
            };
            return <AkLoading isLarge={true} {...loadingProps} />;
        }

        if (oppslag.antallTreff === 0) {
            return <IngenTreff informasjonIkkeTilgjengeligIBransje={this.props.oppslag.informasjonIkkeTilgjengeligIBransje} />;
        }

        if (oppslag.antallTreff === 1) {
            return <Navigate to={`/kjoretoy/${oppslag.treff[0].kuid}?q=${this.state.query}`} />;
        }

        return (
            <div className="container">
                <FormattedMessage id="oppslag.overskrift" tagName="h1" />
                {this.forklaring()}
                <AkTable gridTemplate={rowGridTemplate} rows={this.props.oppslag.treff} keyGenerator={Oppslag.utledKuid} rowClickHandler={this.rowClickHandler}
                    defaultSorting={{property: ['kjennemerke']}}>
                    <AkColumn header={{id: 'oppslag.treff.kjennemerke'}} value={Oppslag.utledKjennemerke} sorting={{property: ['kjennemerke']}}/>
                    <AkColumn header={{id: 'oppslag.treff.understellsnummer'}} value={Oppslag.utledUnderstellsnummer} sorting={{property: ['understellsnummer']}}/>
                    <AkColumn header={{id: 'oppslag.treff.merke'}} value={Oppslag.utledMerke} sorting={{property: ['merke']}}/>
                    <AkColumn header={{id: 'oppslag.treff.handelsbetegnelse'}} value={Oppslag.utledHandelsbetegnelse} sorting={{property: ['handelsbetegnelse']}}/>
                    <AkColumn header={{id: 'oppslag.treff.registreringsar'}} value={Oppslag.utledRegistreringAr} sorting={{property: ['registreringsar']}}/>
                    <AkColumn header={{id: 'oppslag.treff.karosserifarge'}} value={Oppslag.utledKarroserifarge} sorting={{property: ['karosserifarge']}}/>
                    <AkColumn header={{id: 'oppslag.treff.kjoretoyklassifisering'}} value={Oppslag.utledKjoretoyklassifisering} sorting={{property: ['kjoretoyklassifisering']}}/>
                    <AkColumn header={{id: 'oppslag.treff.registreringsstatus'}} value={Oppslag.utledRegistreringsstatus} sorting={{property: ['registreringsstatus']}}/>
                </AkTable>
            </div>
        );
    }

    private forklaring(): ReactNode {
        const { kunUregistrerte } = this.state;
        const flereEnnMaksTreff = this.props.oppslag.antallTreff > MAKS_ANTALL_TREFF;
        const visSokUreg = flereEnnMaksTreff && !kunUregistrerte;

        return (
            <p>
                {flereEnnMaksTreff
                 ? <FormattedMessage id="oppslag.flereEnnMaksTreff" values={{ maksAntallTreff: MAKS_ANTALL_TREFF }} />
                 : <FormattedMessage id="oppslag.merEnnEttTreff" />
                }
                {visSokUreg && <FormattedMessage id="oppslag.viserAlle" values={{ sokeknapp: this.sokUregistrerteKnapp() }} />}
                {kunUregistrerte && <FormattedMessage id="oppslag.viserKunUregistrerte" values={{ sokeknapp: this.sokAlleKnapp() }} />}
            </p>
        );
    }

    private sokAlleKnapp = () => <AkKnapp type="ak-link-knapp" intlKey="oppslag.sokAlle" action={this.sokAlle} />;

    private sokUregistrerteKnapp = () => <AkKnapp type="ak-link-knapp" intlKey="oppslag.sokUregistrerte" action={this.sokUregistrerte} />;

    private rowClickHandler: RowClickHandler<IOppslagTreff> = (event, treff) => {
        event.preventDefault();
        this.props.navigate(`/kjoretoy/${treff.kuid}?q=${this.state.query}`);
    };

    private loadData(): void {
        const { query } = this.state;
        if (query) {
            this.props.gjorOppslag(query);
        }
    }

    private sokUregistrerte = (): void => {
        const { query } = this.state;
        this.props.gjorOppslag(query, true);
        this.props.navigate(`/oppslag?q=${query}&kunUregistrerte=true`);
    };

    private sokAlle = (): void => {
        const { query } = this.state;
        this.props.gjorOppslag(query, false);
        this.props.navigate(`/oppslag?q=${query}&kunUregistrerte=false`);
    };
}

const mapStateToProps = (state: RootStateType): IOppslagStateProps => ({
    oppslag: state.oppslag
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootStateType, undefined, OppslagActionType>): IOppslagDispatchProps => ({
    gjorOppslag: (query, kunUregistrerte) => dispatch(gjorOppslag(query, kunUregistrerte))
});

const OppslagConnected = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter)
(Oppslag);

export { OppslagConnected };
